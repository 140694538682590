import styled from "styled-components";

export const MarketInfoShare = styled.div`
  display: block;
  width: 1px;
  height: 32px;
  background: rgba(255, 255, 255, 0.05);
  margin: 0 1.5rem;
`;

export const MarketInfoItemStyled = styled.div`
  width: fit-content;
  white-space: nowrap;

  @media (max-width: 576px) {
    &:last-child {
      padding-left: 0;
    }
  }

  & .title {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }

  & .value {
    font-size: 1rem;
  }

  & .value,
  & .title {
    line-height: 16px;
  }

  &:first-child {
    padding-left: 0;
  }
`;
