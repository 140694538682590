import React from "react";

import { formatterToNull } from "helpers/valuesFormatters";

const VolumesViewStatus = ({
  receivedAmount,
  soldAmount,
  unsoldAmount,
  asset,
  assessedValue,
  investedAsset,
}) => {
  return (
    <>
      {+receivedAmount ? (
        <div className='d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between mt-2 align-items-lg-center'>
          <p className='font-sm mb-0 text-white-35'>Received</p>
          <div className='d-flex align-items-center'>
            <p className='mb-0 text-white font-weight-500'>{receivedAmount}</p>
            <p className='mb-0 text-white-35 font-weight-300 ml-2'>
              {investedAsset}
            </p>
          </div>
        </div>
      ) : null}
      {+soldAmount ? (
        <div className='d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between align-items-lg-center mt-2'>
          <p className='font-sm mb-0 text-white-35 font-weight-300'>Sold</p>
          <div className='d-flex align-items-center'>
            <p className='mb-0 text-white font-weight-500'>
              {formatterToNull(soldAmount)}
            </p>
            <p className='mb-0 text-white-35 font-weight-300 ml-2'>{asset}</p>
          </div>
        </div>
      ) : null}
      {+unsoldAmount ? (
        <div className='d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between align-items-lg-center mt-2'>
          <p className='font-sm mb-0 text-white-35 font-weight-300'>Unsold</p>
          <div className='d-flex align-items-center'>
            <p className='mb-0 text-white font-weight-500'>
              {formatterToNull(unsoldAmount)}
            </p>
            <p className='mb-0 text-white-35 font-weight-300 ml-2'>{asset}</p>
          </div>
        </div>
      ) : null}
      {+assessedValue ? (
        <div className='d-flex align-items-center justify-content-start justify-content-md-end'>
          <p className='mb-0 text-white font-xs font-weight-500'>
            ~ {formatterToNull(assessedValue)}
          </p>
          <p className='mb-0 text-white-35 font-xs font-weight-300 ml-2'>
            {investedAsset}
          </p>
        </div>
      ) : null}
    </>
  );
};

export default VolumesViewStatus;
