import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from "shared/ui/buttons/Button";
import PaginationPanel from "shared/ui/PaginationPanel";
import { Credential, FiltersCredentialsList, ListPreloader } from "./compounds";
import {
  CreateCredentialsModal,
  EmptyDataList,
  EmptyFiltersListingState,
} from "shared/ui";
import useRequestCancellation from "hooks/useRequestCancellation";
import usePagination from "hooks/usePagination";
import {
  fetchCredentials,
  clearCredentialsData,
} from "store/credentials/actions";

const CredentialsList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { handlePaginate } = usePagination("/credentials", true);
  const { useAbortableEffect } = useRequestCancellation();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    data: { subscription },
    balanceHidden,
  } = useSelector(state => state.me);
  const error = useSelector(state => state.credentials.list.error);
  const loading = useSelector(state => state.credentials.list.loading);
  const { items, total, page } = useSelector(
    state => state.credentials.list.data,
  );

  useAbortableEffect(
    signal => {
      dispatch(fetchCredentials(history.location.search, signal));
      return () => {
        dispatch(clearCredentialsData());
      };
    },
    [dispatch, history.location.search],
    true,
  );

  useEffect(() => {
    window.analytics.page("/credentials");
  }, []);

  const renderEmptyStates = () => {
    if (loading) return <ListPreloader />;
    if (history.location.search?.includes("filter") && !items?.length)
      return <EmptyFiltersListingState />;
    if (!items?.length || error) {
      return (
        <EmptyDataList
          title='No connected exchanges yet'
          description='Please connect some to see them displayed here.'
          buttonText='Connect exchange'
          onHandleClick={setIsModalOpen}
        />
      );
    }
  };

  return (
    <>
      <div className='list-page-header d-flex align-items-center justify-content-between mt-4 mb-3'>
        <h1 className='mb-0 text-white font-weight-500'>Credentials</h1>
        {!history.location.search?.includes("filter") ? (
          <Button
            color='blue'
            onClick={() => setIsModalOpen(true)}
            className='m-0 font-weight-500'
            disabled={isModalOpen}
          >
            Connect exchange
          </Button>
        ) : null}
      </div>
      <FiltersCredentialsList />
      {renderEmptyStates()}
      {items?.map((credential, index) => (
        <Credential
          id={credential.id}
          key={credential.id}
          data={credential}
          subscription={subscription}
          index={index}
          balanceHidden={balanceHidden}
        />
      ))}
      <PaginationPanel
        activePage={page}
        totalItemsCount={total}
        onChange={page => handlePaginate(page)}
      />
      <CreateCredentialsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export default CredentialsList;
