import React from "react";
import classNames from "classnames";
import { Col, Label } from "reactstrap";

import { NumberInput, SelectInput } from "shared/ui";

const IndicatorSetting = ({ currentConfiguration, setFieldValue, errors }) => {
  const { settings = [] } = currentConfiguration || {};

  if (settings?.length) {
    return (
      <div className='d-flex flex-wrap'>
        {settings?.map(input => (
          <Col
            key={input.label}
            md={6}
            lg={4}
            className='d-flex flex-column justify-content-between p-1 mb-2'
          >
            <div className='d-flex flex-row align-items-center text-nowrap mb-1'>
              <Label className='mr-1 mb-0 text-white-35 font-weight-400'>
                {input.label}
              </Label>
            </div>

            {input?.options ? (
              <SelectInput
                name={input.code}
                defaultValue={{
                  value: input.default,
                  label: input.default,
                }}
                isSearchable={false}
                hasError={errors.settings && errors.settings[input.code]}
                options={input?.options?.map(i => ({
                  value: i,
                  label: i,
                }))}
                style={{ background: "#222435 !important" }}
                onChange={({ value }) => {
                  setFieldValue({
                    target: {
                      name: "settings",
                      value: { [input.code]: value },
                    },
                  });
                }}
              />
            ) : (
              <>
                {input.type === "int" && (
                  <NumberInput
                    className={classNames("form-control", {
                      "has-error":
                        errors.settings && errors.settings[input.code],
                    })}
                    max={input.validation?.max}
                    type={input.type}
                    defaultValue={input.default}
                    onChange={e => {
                      const value = e;
                      setFieldValue({
                        target: {
                          name: "settings",
                          value: { [input.code]: value },
                        },
                      });
                    }}
                    onBlur={e => {
                      const value = e.target.value;
                      if (value < input.validation?.min) {
                        setFieldValue({
                          target: {
                            name: "settings",
                            value: { [input.code]: input.validation?.min },
                          },
                        });
                      }
                    }}
                  />
                )}
                {input.type === "float" && (
                  <input
                    className={classNames("form-control", {
                      "has-error":
                        errors.settings && errors.settings[input.code],
                    })}
                    type={input.type}
                    pattern={"^\\d+(?:(\\.\\d+)?)$"}
                    defaultValue={input.default}
                    onChange={e => {
                      setFieldValue({
                        target: {
                          name: "settings",
                          value: { [input.code]: e.target.value },
                        },
                      });
                    }}
                  />
                )}
              </>
            )}
          </Col>
        ))}
      </div>
    );
  }
};

export default IndicatorSetting;
