import { customStyles } from "./styles";

export const mergeStyles = styles => {
  const mergedStyles = Object.entries(customStyles).map(
    ([componentName, componentStyle]) => {
      const mergedStyle = (provided, state) => {
        const prevStyle = componentStyle(provided, state);
        const newStyle = styles[componentName]?.(provided, state);

        return {
          ...prevStyle,
          ...newStyle,
        };
      };

      return [componentName, mergedStyle];
    },
  );

  return Object.fromEntries(mergedStyles);
};

export const getColor = (isDisabled, color, isDark) => {
  if (isDisabled) return "rgba(255, 255, 255, 0.35)";
  if (color) return color;
  return isDark ? "#FFFFFF" : "#333333";
};

export const getCursor = isDisabled => {
  if (isDisabled) return "default";
  return "pointer";
};

export const getIsChecked = (selectedValues, value) =>
  selectedValues.some?.(v => v === value || v.value === value);
