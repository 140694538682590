import React from "react";
import { CardFooter } from "reactstrap";
import PropTypes from "prop-types";

import Button from "shared/ui/buttons/Button";

const CreateSignalFooter = ({
  isSubmitting,
  handleToggleModal,
  setIsSubmitted,
  isHasChannels,
}) => {
  return (
    <CardFooter className='p-0 mt-4'>
      <div className='d-flex justify-content-between mt-5'>
        <Button type='button' onClick={handleToggleModal} outline>
          Cancel
        </Button>
        <Button
          loading={isSubmitting}
          disabled={isSubmitting || isHasChannels}
          color='blue'
          type='submit'
          onClick={() => setIsSubmitted(true)}
        >
          Create
        </Button>
      </div>
    </CardFooter>
  );
};

CreateSignalFooter.propTypes = {
  isSubmitting: PropTypes.bool,
  handleToggleModal: PropTypes.func,
  setIsSubmitted: PropTypes.func,
  isHasChannels: PropTypes.bool,
};

export default CreateSignalFooter;
