import React, { useState } from "react";
import styled from "styled-components";
import { Tooltip } from "reactstrap";

import { ReactComponent as GeneticBot } from "assets/icons/bot/genetic-bot.svg";
import { ReactComponent as CustomBot } from "assets/icons/bot/custom-bot.svg";
import { ReactComponent as TemplateBot } from "assets/icons/bot/template-bot.svg";
import { ReactComponent as Ellipse } from "assets/icons/general/ellipse.svg";

const BotNameWithIcon = ({ bot = {}, fontClassName = "h2", withTooltip }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className='d-flex align-items-center w-100'>
      <span className='d-inline-block'>
        {bot?.type === "genetic" && <GeneticBot id={bot?.type + bot?.id} />}
        {bot?.type === "custom" && (
          <CustomBot width='24px' id={bot?.type + bot?.id} />
        )}
        {bot?.type === "template" && (
          <TemplateBot width='24px' id={bot?.type + bot?.id} />
        )}
      </span>
      {withTooltip ? (
        <Tooltip
          placement='top'
          isOpen={tooltipOpen}
          target={bot?.type + bot?.id}
          toggle={toggle}
        >
          <span className='text-capitalize'>{`${bot.type} bot`}</span>
        </Tooltip>
      ) : null}
      <BotNameWithIcon.Name
        className={`text-nowrap font-weight-500 ${fontClassName} ml-2 my-0 text-white`}
      >
        {bot.name}
      </BotNameWithIcon.Name>
      <span className='d-inline-block mb-1'>
        <BotNameWithIcon.BotEllipse status={bot?.status} className='my-auto' />
      </span>
    </div>
  );
};

BotNameWithIcon.BotEllipse = styled(Ellipse)`
  width: 8px;
  height: 8px;
  margin-left: 6px;

  & circle {
    fill: ${props =>
      props.status === "running" ? "#5FD3BC" : "rgba(255, 255, 255, 0.35);"};
  }
`;

BotNameWithIcon.Name = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default BotNameWithIcon;
