import { combineReducers } from "redux";

import withDataLoading from "store/withDataLoading";
import { CREATE_BACKTEST, DELETE_BACKTEST } from "./constants";

const initialState = { data: {}, loading: true, error: false };

const list = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_BACKTEST:
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.items ? [...state.data.items, payload] : [],
        },
      };

    case DELETE_BACKTEST:
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.items
            ? state.data.items.filter(i => i.id !== payload)
            : [],
        },
      };

    default:
      return state;
  }
};

export default combineReducers({
  list: withDataLoading(list, "BACKTESTING_LIST"),
  item: withDataLoading(null, "BACKTESTING_ITEM"),
});
