import styled from "styled-components";

export const BadgeSignalStyled = styled.div`
  border-radius: 4px;
  padding: 5px 8px;
  line-height: 16px;
  display: inline-block;

  &.buy {
    background: #3fcbb0;

    p {
      color: #1d1e2a;
    }
  }

  &.sell {
    background: #ed5f5f;

    p {
      color: #ffffff;
    }
  }

  &.none {
    border: 1px solid rgba(255, 255, 255, 0.1);

    p {
      color: rgba(255, 255, 255, 0.35);
    }
  }

  &.both {
    background: #f2994a;

    p {
      color: #2a2c42;
    }
  }
`;
