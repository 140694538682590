export const GET_NOTIFICATIONS_CODES_SUCCESS =
  "GET_NOTIFICATIONS_CODES_SUCCESS";
export const GET_NOTIFICATIONS_CODES_LOADING =
  "GET_NOTIFICATIONS_CODES_LOADING";
export const GET_NOTIFICATIONS_CODES_FAILURE =
  "GET_NOTIFICATIONS_CODES_FAILURE";
export const TOGGLE_NOTIFICATION = "TOGGLE_NOTIFICATION";
export const TOGGLE_NOTIFICATION_LOADING = "TOGGLE_NOTIFICATION_LOADING";
export const ENABLE_ALL_NOTIFICATIONS = "ENABLE_ALL_NOTIFICATIONS";
export const ENABLE_ALL_NOTIFICATIONS_LOADING =
  "ENABLE_ALL_NOTIFICATIONS_LOADING";
export const DISABLE_ALL_NOTIFICATIONS_LOADING =
  "DISABLE_ALL_NOTIFICATIONS_LOADING";
export const DISABLE_ALL_NOTIFICATIONS = "DISABLE_ALL_NOTIFICATIONS";
