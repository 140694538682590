import React from "react";

import ActiveDealsList from "./dealsHistory/DealsHistory";
import RunningDeals from "./runningDeals/RunningDeals";

const DealsTab = ({ id, loadingPage }) => (
  <>
    <RunningDeals botId={id} loadingPage={loadingPage} />
    <ActiveDealsList id={id} loadingPage={loadingPage} />
  </>
);

export default DealsTab;
