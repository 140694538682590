import styled from "styled-components";

export const StyledBreadcrumbsNav = styled.nav`
  svg {
    margin: 0 16px;
  }

  li {
    :last-child {
      svg {
        display: none !important;
      }
    }
  }
`;

export const StyledBreadcrumbsLink = styled.p`
  max-width: 150px;
  color: ${({ path }) => (path ? "rgba(255,255,255, 0.35)" : "#fff")};

  &:hover {
    color: #fff;
    cursor: ${({ path }) => (path ? "pointer" : "default")};
    transition: 0.2s;
  }
`;
