import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import classnames from "classnames";
import { UncontrolledAlert } from "reactstrap";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import qs from "qs";
import { GOOGLE_OAUTH_CLIENT_ID } from "env-create-react-app";

import { handleResponse } from "helpers/errorsValidator";
import { googleAuth, register } from "store/auth/actions";
import StyledGoogleButton from "./compounds/StyledGoogleButton";
import { Checkbox } from "shared/ui";
import { Button } from "shared/ui/buttons";

const RegisterSchema = Yup.object().shape({
  name: Yup.string().required("This field is required."),
  username: Yup.string()
    .email("Please enter a valid email address.")
    .required("This field is required."),
  password: Yup.string()
    .required("This field is required.")
    .min(8, "Password must be 8 characters and more"),
  repeatPassword: Yup.string()
    .required("This field is required.")
    .min(8, "Password must be 8 characters and more")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const initialValues = {
  name: "",
  username: "",
  password: "",
  repeatPassword: "",
  subscribe: false,
  ref: "",
};

const RegisterForm = ({ setGoogleLoading }) => {
  const [googleError, setGoogleError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const params = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const referralCode = params?.ref || localStorage.getItem("registerReferral");
  const responseGoogleSuccess = useCallback(
    async response => {
      setGoogleLoading(true);
      const error = await dispatch(
        googleAuth({
          ...response,
          ...(referralCode ? { ref: referralCode } : {}),
        }),
      );
      if (error) {
        setGoogleLoading(false);
        setGoogleError(error.message);
      } else {
        localStorage.removeItem("registerReferral");
        history.push("/");
      }
    },
    [setGoogleError, history, dispatch, setGoogleLoading, referralCode],
  );

  const responseGoogleFailure = useCallback(
    response => {
      setGoogleLoading(false);
      setGoogleError(response.details);
    },
    [setGoogleError, setGoogleLoading],
  );

  return (
    <Formik
      initialValues={{ ...initialValues }}
      isInitialValid={RegisterSchema.isValidSync(initialValues)}
      validationSchema={RegisterSchema}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        const token = await executeRecaptcha("registration");
        if (!token) {
          setSubmitting(false);
          setErrors({ message: "GoogleReCaptcha token is not valid" });
          return;
        }
        const { subscribe, ...rest } = values;
        const error = await dispatch(
          register({
            ...rest,
            subscribe,
            ...(referralCode ? { ref: referralCode } : {}),
            token,
          }),
        );
        if (error) {
          setSubmitting(false);
          handleResponse(error, setErrors);
        } else {
          localStorage.removeItem("registerReferral");
          localStorage.setItem("isOnboardingFinished", false);
          history.push("/");
        }
      }}
    >
      {({ errors, isSubmitting, values, setFieldValue }) => (
        <Form>
          {googleError && (
            <UncontrolledAlert color='danger' fade={false}>
              {googleError}
            </UncontrolledAlert>
          )}
          {errors.message && (
            <UncontrolledAlert color='danger' fade={false}>
              {errors.message}
            </UncontrolledAlert>
          )}
          <div className='mb-2'>
            <label htmlFor='name' className='text-white-75 font-ms'>
              Your name
            </label>
            <Field
              id='name'
              className={classnames("form-control", {
                "has-error": submitted && (errors.name || errors.name),
              })}
              name='name'
              disabled={isSubmitting}
              placeholder='Enter your name'
            />
            {submitted && errors.name ? (
              <p className='text-danger font-sm'>{errors.name}</p>
            ) : null}
          </div>
          <div className={submitted && errors.username ? "mb-1" : "mb-3"}>
            <div className='mb-3'>
              <label htmlFor='email' className='text-white-75 font-ms'>
                Email
              </label>
              <Field
                id='email'
                className={classnames("form-control", {
                  "has-error": submitted && (errors.username || errors.message),
                })}
                type='email'
                name='username'
                disabled={isSubmitting}
                placeholder='Enter your email'
              />
              {submitted && errors.username ? (
                <p className='text-danger font-sm'>{errors.username}</p>
              ) : null}
            </div>
          </div>
          <div className='mb-3'>
            <label htmlFor='password' className='text-white-75 font-ms'>
              Password
            </label>
            <Field
              id='password'
              type='password'
              name='password'
              disabled={isSubmitting}
              className={classnames("form-control", {
                "has-error": submitted && errors.password,
              })}
              placeholder='8 characters and more'
            />
            {submitted && errors.password ? (
              <p className='text-danger font-sm'>{errors.password}</p>
            ) : null}
          </div>
          <div className='mb-3'>
            <label htmlFor='repeatPassword' className='text-white-75 font-ms'>
              Repeat Password
            </label>
            <Field
              id='repeatPassword'
              type='password'
              name='repeatPassword'
              disabled={isSubmitting}
              className={classnames("form-control", {
                "has-error":
                  submitted && (errors.repeatPassword || errors.repeatPassword),
              })}
              placeholder='8 characters and more'
            />
            {submitted && errors.repeatPassword ? (
              <p className='text-danger font-sm'>{errors.repeatPassword}</p>
            ) : null}
          </div>
          <Checkbox
            checked={values.subscribe}
            disabled={isSubmitting}
            name='subscribe'
            onChange={() => setFieldValue("subscribe", !values.subscribe)}
            labelRight={
              <p className='text-white-75 mb-0 ml-2'>
                Subscribe to the Darkbot Newsletter
              </p>
            }
          />
          <Button
            className='w-100 mt-5'
            loading={isSubmitting}
            type='submit'
            onClick={() => setSubmitted(true)}
            color='blue'
          >
            Register
          </Button>
          <p className='mt-3 mb-5 font-sm text-center text-white-35'>
            {'By clicking "Register" you agree to'}{" "}
            <a
              href='https://darkbot.io/terms-of-use'
              className='text-info'
              target='_blank'
              rel='noreferrer'
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href='https://darkbot.io/privacy-policy'
              className='text-info'
              target='_blank'
              rel='noreferrer'
            >
              Privacy Policy
            </a>
          </p>
          <RegisterForm.Separator className='mt-4 mb-4'>
            <p className='mb-0 ml-2 mr-2'>Or</p>
          </RegisterForm.Separator>
          <StyledGoogleButton
            clientId={GOOGLE_OAUTH_CLIENT_ID}
            buttonText='Google'
            onSuccess={responseGoogleSuccess}
            onFailure={responseGoogleFailure}
            cookiePolicy={"single_host_origin"}
            className='w-100 justify-content-center text-white'
          />
          <p className='mt-3 text-center'>
            <span className='text-white-35'>Already have an account? </span>
            <Link to='/login' className='text-info'>
              Sign in
            </Link>
          </p>
        </Form>
      )}
    </Formik>
  );
};

RegisterForm.Separator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: #fff;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #6c757d;
  }
  &::before {
    margin-right: 0.25em;
  }
  &::after {
    margin-left: 0.25em;
  }
`;

export default RegisterForm;
