import styled from "styled-components";

export const StyledAddIndicatorsButton = styled.div`
  background: #242638;
  color: #5e6392;
  height: 50px;
  border: 1px dashed var(--dusk-steel);
  border-radius: 0.5rem;
  transition: all 0.2s ease-in-out;

  svg {
    path {
      fill: #5e6392;
    }
  }
  :hover {
    background: var(--dark-slate);
    border: 1px solid #4a4e73;
    color: #7d81ab;
  }
`;

export const StyledIndicatorFormWrapper = styled.form`
  background: var(--BG-2nd-block);
  display: flex;
  padding: 1.25rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  border-radius: 0.5rem;

  @media (max-width: 768px) {
    gap: 1rem;
  }

  span {
    cursor: pointer;
  }

  .line {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    width: 1px;
  }

  i {
    color: #ffffffbf !important;
    cursor: pointer;
  }

  .form-danger {
    position: relative;
    border-radius: 6px;
    font-size: 14px;
    background: rgba(108, 117, 125, 0.04) !important;
    border: 1px solid #ed5f5f;
  }

  .form-danger.form-control {
    background: #222435;
    :focus {
      background: #222435;
    }
  }

  .form-success {
    position: relative;
    font-size: 14px;
    background: #2e3148 !important;
    color: var(--white);
    border-radius: 6px;
  }

  .form-success.form-control {
    background: #222435;
    :focus {
      background: #222435;
    }
  }
`;
