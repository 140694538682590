import React from "react";
import styled from "styled-components";
import { ReactComponent as Empty } from "assets/icons/general/empty-state-planet2.svg";

const TechnicalLogsEmptyState = () => {
  return (
    <TechnicalLogsEmptyState.Wrapper>
      <Empty />
      <TechnicalLogsEmptyState.Title>
        No tech logs at the moment
      </TechnicalLogsEmptyState.Title>
      <TechnicalLogsEmptyState.Description>
        As the deal runs, all details about trade requests and deal stages will
        be displayed here.
      </TechnicalLogsEmptyState.Description>
    </TechnicalLogsEmptyState.Wrapper>
  );
};

TechnicalLogsEmptyState.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

TechnicalLogsEmptyState.Title = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin: 40px 0 8px 0;
  color: #ffffff !important;
`;

TechnicalLogsEmptyState.Description = styled.p`
  color: rgba(255, 255, 255, 0.75) !important;
  margin-bottom: 82px;
`;

export default TechnicalLogsEmptyState;
