import React, { useState } from "react";
import styled from "styled-components";
import { Col, Label, FormGroup } from "reactstrap";

import { MARTINGALE_FIELDS } from "./tradeSettingsConfiguration";
import MartingaleInfo from "./modals/MartingaleInfo";
import DeviationInfo from "./modals/DeviationInfo";
import { FormGroupWithSymbols, NumberInput } from "shared/ui";

const MartingaleForm = ({ submitted, errors, values, setFieldValue }) => {
  const [isMartingaleModalOpen, setIsMartingaleModalOpen] = useState(false);
  const [isDeviationModalOpen, setIsDeviationModalOpen] = useState(false);

  const handleOpenModal = (e, value) => {
    e.preventDefault();
    if (value === "martingalePercent") setIsMartingaleModalOpen(true);
    if (value === "deviation") setIsDeviationModalOpen(true);
  };

  return (
    <MartingaleForm.Wrapper
      data-testid='martingale-form-wrapper'
      err={Object?.keys(errors)?.length}
      submitted={submitted}
      className='d-flex flex-column flex-md-row'
    >
      {MARTINGALE_FIELDS.map(input => (
        <Col
          md='4'
          xs='12'
          key={input.value}
          className='position-relative px-0 px-md-2'
        >
          <Label className='text-white-75 font-weight-400 text-nowrap mb-0 mt-2 mt-md-0 mb-md-2'>
            {input.label}{" "}
            {input.value !== "maxTradeRequests" ? (
              <a
                href='/#'
                onClick={e => handleOpenModal(e, input.value)}
                className='text-info'
              >
                Info
              </a>
            ) : null}
          </Label>
          {input.type === "int" ? (
            <FormGroup className='mb-0'>
              <NumberInput
                value={values[input.value]}
                name={input.value}
                className={`mb-0 form-control text-white ${
                  submitted && errors[input.value] ? "has-error" : ""
                }`}
                type={input.type}
                onChange={value => setFieldValue(`${input.value}`, value)}
                autoComplete='off'
                max={100}
              />
            </FormGroup>
          ) : (
            <FormGroupWithSymbols symbolRight='%'>
              <NumberInput
                value={values[input.value]}
                name={input.value}
                className={`mb-0 form-control text-white ${
                  submitted && errors[input.value] ? "has-error" : ""
                }`}
                type={input.type}
                onChange={value => setFieldValue(`${input.value}`, value)}
                autoComplete='off'
              />
            </FormGroupWithSymbols>
          )}
          {submitted &&
          (errors[input.value] || errors[`tradeSettings.${input.value}`]) ? (
            <p className='mb-2 mt-1 font-sm text-danger position-absolute'>
              {errors[input.value]}
            </p>
          ) : null}
        </Col>
      ))}
      <MartingaleInfo
        isOpen={isMartingaleModalOpen}
        setIsOpen={setIsMartingaleModalOpen}
      />
      <DeviationInfo
        isOpen={isDeviationModalOpen}
        setIsOpen={setIsDeviationModalOpen}
      />
    </MartingaleForm.Wrapper>
  );
};

MartingaleForm.Wrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: ${({ err, submitted }) => (err && submitted ? "24px" : "0")};

  .col-4 {
    padding: 0 !important;
    &:nth-child(2) {
      padding: 0 8px !important;
    }
  }
`;

export default MartingaleForm;
