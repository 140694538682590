import React from "react";

import { ReactComponent as RequiredSignal } from "assets/icons/bot/required-signal.svg";
import { ReactComponent as BuyLabelIcon } from "assets/icons/property/Buy.svg";
import { ReactComponent as BothLabelIcon } from "assets/icons/property/BuySellLabel.svg";
import { ReactComponent as SellLabelIcon } from "assets/icons/property/Sell.svg";
import { StyledIndicator } from "./index.styles";

const IndicatorList = ({ indicators, indicatorLabels }) => {
  if (indicators?.length) {
    return indicators.map(item => (
      <StyledIndicator required={item.required} key={item.id}>
        <div className='d-flex flex-row w-100 align-items-center justify-content-between'>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
              {item.type === "sell" && (
                <SellLabelIcon width={18} height={18} className='mr-2' />
              )}
              {item.type === "buy" && (
                <BuyLabelIcon width={18} height={18} className='mr-2' />
              )}
              {item.type === "both" && (
                <BothLabelIcon width={18} height={18} className='mr-2' />
              )}
            </div>
            <p className='mr-1 mb-0 mt-0 text-white font-sm'>
              {indicatorLabels[item?.code] || ""}
            </p>
          </div>
          {item.required && <RequiredSignal width={24} height={24} />}
        </div>
      </StyledIndicator>
    ));
  }
};

export default IndicatorList;
