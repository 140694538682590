import { Line } from "react-chartjs-2";

import { chartOptions } from "charts/options";

const ReferralStatisticsChart = ({ labels, statisticsData, maxAmount }) => {
  const chartData = {
    config: {
      labels: labels?.map(label => {
        const [day, month, year] = label.split(".");
        return new Date(`${year}-${month}-${day}`);
      }),
      datasets: [
        {
          label: true,
          data: statisticsData,
          radius: 0,
          fill: false,
          tension: 0.4,
          borderColor: "#1D8CF8",
        },
      ],
    },
    options: {
      ...chartOptions,
      interaction: {
        intersect: false,
        mode: "index",
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: "#f5f5f5",
          fontColor: "rgba(0, 0, 0, 0.5)",
          titleColor: "rgba(0, 0, 0, 0.5)",
          bodyColor: "rgba(0, 0, 0, 1)",
          labelColor: "rgba(0, 0, 0, 1)",
          color: "rgba(0, 0, 0, 0.5)",
          displayColors: false,
          callbacks: {
            label: function (context) {
              const label = labels[context.dataIndex];
              return label ? `${label}: ${context.formattedValue}` : "";
            },
            title: function () {
              return null;
            },
          },
        },
      },
      layout: {
        padding: {
          top: 10,
        },
      },
      scales: {
        y: {
          max: maxAmount,
          min: 1,
          ticks: {
            display: true,
            stepSize: 1,
          },
          grid: {
            display: true,
          },
        },
        x: {
          type: "time",
          time: {
            unit: "day",
            displayFormats: {
              day: "DD.MM",
            },
          },
          ticks: {
            source: "labels",
            maxTicksLimit: labels?.length,
          },
          grid: {
            display: true,
            color: "#27334F",
            borderColor: "transparent",
          },
        },
      },
    },
  };

  return (
    <div className='chart-area mx-4' style={{ height: "260px" }}>
      <Line data={chartData?.config} options={chartData?.options} />
    </div>
  );
};
export default ReferralStatisticsChart;
