import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Label } from "reactstrap";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import qs from "qs";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { createCredential, fetchCredentials } from "store/credentials/actions";
import { handleResponse } from "helpers/errorsValidator";
import { fetchDashboard } from "store/dashboard/actions";
import { addSnackBar } from "store/snackbar/actions";
import { getInvestmentsList } from "store/investment/actions";
import IpInfoBlock from "./IpInfoBlock";
import ExchangesSelect from "shared/ui/ExchangesSelect";
import ErrorAlert from "shared/ui/ErrorAlert";
import { Button } from "shared/ui/buttons";

const CONNECT_SCHEMA = Yup.object().shape({
  apiKey: Yup.string().required(
    "Check if the API key is correct and try again",
  ),
  secret: Yup.string().required(
    "Check if the secret key is correct and try again",
  ),
  exchange: Yup.string().required("This field is required."),
  comment: Yup.string().test(
    "len",
    "This value is too long. It should have 140 characters or less.",
    val => {
      if (!val) return true;
      return val.length === 0 || val.length <= 140;
    },
  ),
});

const CreateCredentialsForm = ({ setIsModalOpen }) => {
  const [submitted, setSubmitted] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [ips, setIps] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const redirectToExchangeSite = () => window.open(websiteUrl, "_blank");

  async function handleSubmit(values, { setSubmitting, setErrors }) {
    const reg = /\n+/g;
    const filteredValues = {
      ...values,
      apiKey: values.apiKey?.replaceAll(" ", ""),
      secret: values.secret?.replaceAll(" ", "")?.replaceAll(reg, ""),
    };
    setSubmitted(false);
    setSubmitting(true);
    const res = await dispatch(createCredential(filteredValues));

    if (res?.errors) {
      setErrorMessage(res.message);
      handleResponse(res, setErrors);
    } else {
      dispatch(addSnackBar("success", res.message));
      setIsModalOpen(false);
    }
    setSubmitted(true);
    setSubmitting(false);
    if (!res?.errors && history.location.pathname === "/") {
      dispatch(fetchDashboard());
    }
    if (!res?.errors && history.location.pathname === "/credentials") {
      dispatch(fetchCredentials());
    }
    if (!res?.errors && history.location.pathname === "/bots/create") {
      const queryString = qs.stringify({ filter: { balance: { gt: 0 } } });
      dispatch(getInvestmentsList(`?${queryString}`));
      dispatch(fetchCredentials());
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        apiKey: "",
        secret: "",
        comment: "",
        exchange: "",
      }}
      validationSchema={CONNECT_SCHEMA}
      onSubmit={handleSubmit}
    >
      {({ errors, isSubmitting, setFieldValue, values }) => (
        <Form className='w-100' autoComplete='off'>
          <div className='mb-3'>
            <ExchangesSelect
              withAllOption={false}
              name='exchange'
              placeholder='Exchange'
              isSearchable={false}
              hasError={submitted && errors.exchange}
              value={values.exchange}
              onChange={({ value, website, ips }) => {
                setFieldValue("exchange", value);
                setWebsiteUrl(website);
                setIps(ips);
              }}
              width='100%'
              withAllExchanges
            />
          </div>
          <IpInfoBlock exchange={values.exchange} ips={ips} />
          <div className='mb-3'>
            <div className='d-flex align-items-center justify-content-between'>
              <Label className='text-white-75 mb-1'>API Key</Label>
              {values.exchange && (
                <CreateCredentialsForm.Link
                  onClick={() => redirectToExchangeSite(values)}
                >
                  Generate API Key
                </CreateCredentialsForm.Link>
              )}
            </div>
            <Field
              placeholder='Type in...'
              type='text'
              name='apiKey'
              className={classnames("form-control", {
                "has-error": submitted && errors.apiKey,
              })}
            />
            {submitted && errors?.apiKey && (
              <p className='text-danger mb-3 font-sm font-weight-300'>
                {errors.apiKey}
              </p>
            )}
          </div>
          <div className='mb-3'>
            <Label className='text-white-75 mb-1'>Secret Key</Label>
            <Field
              placeholder='Type in...'
              type='text'
              name='secret'
              component='textarea'
              className={classnames("form-control", {
                "has-error": submitted && errors.secret,
              })}
            />
            {submitted && errors?.secret && (
              <p className='text-danger font-sm mb-3 font-weight-300'>
                {errors.secret}
              </p>
            )}
          </div>
          <div className='mb-3'>
            <Label className='text-white-75 mb-1'>Comment (optional)</Label>
            <Field
              placeholder='Type in...'
              type='text'
              name='comment'
              className={classnames("form-control", {
                "has-error": submitted && errors?.comment?.length,
              })}
            />
            {submitted && errors?.comment && (
              <p className='text-danger font-sm mb-3 font-weight-300'>
                Comment length must not exceed 140 symbols
              </p>
            )}
          </div>
          <ErrorAlert
            fade={false}
            toggle={() => setErrorMessage("")}
            isOpen={!!errorMessage}
          >
            {errorMessage}
          </ErrorAlert>
          <div className='d-flex mt-4 w-100 justify-content-between'>
            <Button
              color='gray'
              onClick={() => setIsModalOpen(false)}
              className='pull-right'
            >
              Cancel
            </Button>
            <Button
              color='blue'
              loading={isSubmitting}
              disabled={submitted && Object.keys(errors).length}
              onClick={() => setSubmitted(true)}
              type='submit'
              className='pull-right'
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

CreateCredentialsForm.Link = styled.p`
  color: rgba(47, 128, 237, 1) !important;
  cursor: pointer;
  font-size: 0.875rem;

  &:hover {
    color: rgba(47, 128, 237, 0.5) !important;
    text-decoration: underline;
  }
`;

export default CreateCredentialsForm;
