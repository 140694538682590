import React from "react";
import * as Sentry from "@sentry/react";

import FallBack from "shared/ui/FallBack";
import { SpotWalletWidget } from "./components";
import StatisticsBalance from "./components/StatisticsBalance";

const BalancesSection = ({ data, loadingPage, id }) => {
  return (
    <>
      <Sentry.ErrorBoundary fallback={<FallBack />}>
        <StatisticsBalance id={id} currency='BTC' loadingPage={loadingPage} />
      </Sentry.ErrorBoundary>
      <Sentry.ErrorBoundary fallback={<FallBack />}>
        <SpotWalletWidget
          id={id}
          estimated={data?.estimated}
          btc={data?.balance?.btc}
          loadingPage={loadingPage}
        />
      </Sentry.ErrorBoundary>
    </>
  );
};

export default BalancesSection;
