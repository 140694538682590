import React from "react";
import styled from "styled-components";
import { Card } from "reactstrap";

import SpotWalletPreloader from "./SpotWalletPreloader";
import { handleHideBalance } from "shared/helpers/handleHideBalance";

const SpotWalletWidgetBody = ({
  balanceHidden,
  balances,
  amount,
  currency,
  btc,
  loading,
}) => {
  if (loading || !amount || !currency || !btc) return <SpotWalletPreloader />;

  return (
    <SpotWalletWidgetBody.Card className='d-flex flex-column flex-lg-row'>
      <SpotWalletWidgetBody.Wrapper className='mb-0'>
        <div
          className='d-flex flex-fill px-3 px-lg-4'
          style={{ padding: "12px 24px" }}
        >
          <h2 className='h1 mb-0 text-white align-self-center'>
            {balances} Coins
          </h2>
        </div>
      </SpotWalletWidgetBody.Wrapper>
      <SpotWalletWidgetBody.Wrapper className='mb-0 mx-0 mx-lg-2'>
        <div
          className='d-flex justify-content-start'
          style={{ padding: "12px 16px" }}
        >
          <div className='d-flex align-items-start flex-column'>
            <p className='font-weight-300 font-sm text-white-75 mb-0'>Total</p>
            <div className='d-flex mt-1'>
              <h2 className=' mb-0 text-white font-weight-700'>
                {handleHideBalance(balanceHidden, btc)}
              </h2>
              <h2 className='font-weight-300 text-white-35 mb-0 ml-1'>BTC</h2>
            </div>
          </div>
        </div>
      </SpotWalletWidgetBody.Wrapper>
      <SpotWalletWidgetBody.Wrapper className='mb-0'>
        <div
          className='d-flex justify-content-start'
          style={{ padding: "12px 16px" }}
        >
          <div className='d-flex align-items-start flex-column'>
            <p className='font-weight-300 text-white-75 mb-0'>Estimated</p>
            <div className='d-flex mt-1'>
              <h2 className='mb-0 text-white font-weight-700'>
                {handleHideBalance(balanceHidden, amount)}
              </h2>
              <h2 className='font-weight-300 text-white-35 mb-0 ml-1'>
                {currency}
              </h2>
            </div>
          </div>
        </div>
      </SpotWalletWidgetBody.Wrapper>
    </SpotWalletWidgetBody.Card>
  );
};

SpotWalletWidgetBody.Card = styled(Card)`
  margin-bottom: 2rem !important;

  @media (max-width: 992px) {
    background: #363a54 !important;
  }
`;

SpotWalletWidgetBody.Wrapper = styled(Card)`
  margin-bottom: 0px;
  background: #363a54 !important;
  & h2 {
    font-size: 19px;
  }
  & p {
    font-size: 14px;
    line-height: normal;
  }
`;

export default SpotWalletWidgetBody;
