import React from "react";

import { ParameterDisplay } from "shared/ui";

const SignalParameters = ({ signal }) => {
  return (
    <div className='d-flex d-md-none justify-content-between flex-wrap mt-4'>
      <ParameterDisplay
        title='SL'
        amount={signal?.stopLoss}
        classNameTitle='text-white-75'
        className='col-3 p-0'
      />
      <ParameterDisplay
        title='BF'
        amount={signal?.buyFrom}
        classNameTitle='text-white-75'
        className='col-3 p-0'
      />
      <ParameterDisplay
        title='BT'
        amount={signal?.buyTo}
        classNameTitle='text-white-75'
        className='col-3 p-0'
      />
      {signal?.targets?.map((item, index) => {
        return (
          <ParameterDisplay
            key={item.id}
            title={`T${index + 1}`}
            amount={item?.rate}
            classNameTitle='text-white-75'
            className={`col-3 ${index ? "mt-2" : ""} p-0`}
          />
        );
      })}
    </div>
  );
};

export default SignalParameters;
