import React from "react";
import { FormGroup } from "reactstrap";
import { DelayInput } from "react-delay-input";

import SelectInput from "../SelectInput";

const MarketBotsFilters = ({ handleFilter, filters }) => {
  return (
    <div className='d-flex flex-column flex-md-row mb-4 mt-2 p-3 p-md-0'>
      <FormGroup className='ml-md-4 mb-2 mb-md-0 w-100'>
        <SelectInput
          isClearable
          placeholder='Bot type'
          name='botType'
          onChange={option => {
            if (option) return handleFilter({ type: { eq: option.value } });
            return handleFilter({ type: { eq: {} } });
          }}
          options={[
            { value: "custom", label: "Custom", color: "#5F9EF1" },
            { value: "genetic", label: "Genetic", color: "#937AFF" },
          ]}
        />
      </FormGroup>
      <FormGroup className='w-100 mb-2 mb-md-0 mx-md-2'>
        <DelayInput
          autoComplete='off'
          delayTimeout={700}
          minLength={1}
          type='text'
          name='name'
          className='form-control'
          placeholder='Name'
          value={filters?.name?.like ?? ""}
          onChange={({ target }) =>
            handleFilter({ name: { like: target.value } })
          }
        />
      </FormGroup>
      <FormGroup className='w-100 mr-md-4 mb-2 mb-md-0'>
        <SelectInput
          isClearable
          placeholder='Status'
          name='status'
          onChange={option => {
            if (option) return handleFilter({ status: { eq: option.value } });
            return handleFilter({ status: { eq: {} } });
          }}
          options={[
            { value: "running", label: "Enable", color: "#3FCBB0" },
            {
              value: "stopped",
              label: "Disable",
              color: "rgba(255, 255, 255, 0.35)",
            },
          ]}
        />
      </FormGroup>
    </div>
  );
};

export default MarketBotsFilters;
