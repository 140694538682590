import React from "react";
import styled from "styled-components";
import { FormGroup } from "reactstrap";

import { ExchangesSelect, RefreshButton, SelectInput } from "shared/ui";

const TickersFilters = ({
  setExchange,
  exchange,
  assets,
  asset,
  setAsset,
  isLoading,
  isRefreshing,
  setSearch,
  search,
  setIsOpen,
  refresh,
}) => {
  return (
    <TickersFilters.Wrapper className='d-flex flex-column flex-lg-row justify-content-between align-items-lg-center px-3'>
      <div className='d-flex flex-column flex-column-reverse flex-sm-row align-content-center justify-content-between'>
        <div className='d-flex align-content-center'>
          <FormGroup className='filter-select border-0 mr-2'>
            <ExchangesSelect
              withAllExchanges
              isDark={false}
              onChange={({ value }) => setExchange(value)}
              value={exchange}
            />
          </FormGroup>
          <FormGroup className='filter-select border-0'>
            <SelectInput
              isLoading={isLoading}
              isDark={false}
              options={assets.map(asset => ({
                value: asset,
                label: asset,
                icon: {
                  type: "cryptocurrency",
                  size: "xs",
                  code: asset,
                },
              }))}
              value={{
                value: asset,
                label: asset,
                icon: {
                  type: "cryptocurrency",
                  size: "xs",
                  code: asset,
                },
              }}
              onChange={({ value }) => setAsset(value)}
              type='quote'
            />
          </FormGroup>
        </div>
        <p
          className='d-flex d-lg-none mb-0 pt-1 text-dark cursor-pointer font-weight-500 mb-3 mb-sm-0'
          onClick={() => setIsOpen(false)}
        >
          Close
        </p>
      </div>
      <FormGroup className='d-flex position-relative ml-lg-4 w-100'>
        <Input
          autoComplete='off'
          type='text'
          name='search'
          className='form-control light'
          placeholder='Search an asset'
          value={search}
          onChange={({ target }) => setSearch(target.value)}
        />
        <RefreshButton
          height={38}
          onHandleRefresh={refresh}
          loading={isRefreshing}
        />
      </FormGroup>
    </TickersFilters.Wrapper>
  );
};

TickersFilters.Wrapper = styled.div`
  @media (max-width: 576px) {
    .filter-select {
      width: 100% !important;
    }
  }

  .filter-select {
    position: relative;
    border: 1px solid rgba(29, 30, 42, 0.2);
    border-radius: 6px;
    width: 210px;

    .arrow {
      position: absolute;
      border: solid black;
      border-width: 0 1.5px 1.5px 0;
      display: inline-block;
      padding: 3px;
      top: 37%;
      right: 4%;
    }

    .down {
      width: 6px;
      height: 3px;
      transform: rotate(45deg);
    }
  }
`;

const Input = styled.input`
  position: relative;
  background: rgba(29, 30, 42, 0.06) !important;
  color: rgba(34, 42, 66, 0.7);
  font-size: 14px !important;
  width: 100%;
`;

export default TickersFilters;
