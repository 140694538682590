import React, { useCallback, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "reactstrap";
import { DelayInput } from "react-delay-input";
import styled from "styled-components";
import qs from "qs";

import { ReactComponent as SpotWalletIcon } from "assets/icons/credential/spot-wallet-Icon.svg";
import { ReactComponent as Search } from "assets/icons/general/search-black.svg";
import { dynamicRgbaColors } from "charts/helpers";
import { fetchBalances } from "store/credentials/actions";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import { selectBalances } from "store/credentials/selectors";
import SpotWalletWidgetBody from "./components/SpotWalletWidgetBody";
import ExchangeTableModal from "views/credentials/compounds/ExchangeBallances/components/ExchangeTableModal";
import { ColorfulBar, FormGroupWithSymbols, PaginationPanel } from "shared/ui";
import { LookupCoins } from "shared/utils";

const SpotWalletWidget = ({ estimated, id, request, btc, loadingPage }) => {
  const dispatch = useDispatch();
  const { balanceHidden } = useSelector(state => state.me);
  const { data, loading } = useSelector(selectBalances);

  const itemsWithPercentCount = data?.items?.reduce(
    (acc, item) => (item.percent ? acc + 1 : acc + 0),
    0,
  );

  const { handlePaginate, handleSorting } = useFiltersWithRequest(
    data?.request,
    fetchBalances,
    id,
  );

  const handleSearch = useCallback(value => {
    const filter = qs.stringify({
      sort: request?.sort || [],
      filter: { asset: { like: value } },
      limit: 100,
    });

    dispatch(fetchBalances(id, `?${filter}`));
  }, []);

  useLayoutEffect(() => {
    const filter = qs.stringify({
      sort: { by: "percent", order: "DESC" },
      limit: 10,
    });
    dispatch(fetchBalances(id, `?${filter}`));
  }, [dispatch, id]);

  return (
    <SpotWalletWidget.Card className='d-flex flex-column pt-0'>
      <SpotWalletWidget.Header className='px-4 pt-3 mb-0'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex flex-sm-row align-items-sm-center w-100'>
            <div className='d-flex align-items-center text-nowrap'>
              <SpotWalletIcon />
              <p className='mb-0 ml-2 mr-4 text-white font-weight-500'>
                Spot wallet
              </p>
            </div>
          </div>
          <div className='mt-3 mt-sm-0 searchInput'>
            <FormGroupWithSymbols symbolLeft={<Search className='search' />}>
              <DelayInput
                autoComplete='off'
                delayTimeout={700}
                type='string'
                name='search'
                className='form-control w-100'
                placeholder='Search coins'
                onChange={({ target }) => handleSearch(target.value)}
              />
            </FormGroupWithSymbols>
          </div>
        </div>
        <SpotWalletWidget.Wrapper className='color-bar mt-4 mb-3'>
          {!loading &&
            data?.items?.map((item, index) => {
              if (item.asset.includes("1000")) return "A" + item.asset;
              if (!item.percent) return null;
              return (
                <ColorfulBar
                  key={item?.asset}
                  title={item?.asset}
                  value={item?.percent}
                  itemsLength={data?.items?.length}
                  itemsWithValueLength={itemsWithPercentCount}
                  index={index}
                  tooltipText={<LookupCoins coin={item?.asset} />}
                  withSpacing
                  color={dynamicRgbaColors()}
                />
              );
            })}
        </SpotWalletWidget.Wrapper>
        <SpotWalletWidgetBody
          balances={data?.items?.length}
          amount={`${estimated?.amount || 0}`}
          currency={estimated?.currency}
          btc={btc}
          loading={loadingPage || loading}
          balanceHidden={balanceHidden}
        />
      </SpotWalletWidget.Header>
      <ExchangeTableModal
        items={data?.items}
        request={data?.request}
        handleSorting={handleSorting}
        balanceHidden={balanceHidden}
      />
      <div className={`${data?.total < 5 ? "mt-0" : "mt-3"}`}>
        <PaginationPanel
          activePage={data?.page}
          totalItemsCount={data?.total}
          onChange={page => handlePaginate(page)}
        />
      </div>
    </SpotWalletWidget.Card>
  );
};

SpotWalletWidget.Header = styled(Card)`
  background: #2a2c42 !important;
`;

SpotWalletWidget.Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

SpotWalletWidget.Card = styled(Card)`
  .search {
    path {
      stroke: rgba(255, 255, 255, 0.35);
    }
  }
`;

export default SpotWalletWidget;
