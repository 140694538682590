import React from "react";
import { CardHeader } from "reactstrap";
import styled from "styled-components";

import { ReactComponent as Ellipse } from "assets/icons/general/ellipse.svg";
import { Icon } from "shared/ui";

const BotHeader = ({ bot = {} }) => {
  return (
    <BotHeader.StyledCardHeader error={bot.error} className='p-4'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex align-items-center w-75'>
          <span className='d-inline-block'>
            <Icon size='botSize' code={`bot/${bot?.type}-bot`} type='icons' />
          </span>
          <BotHeader.Name className='text-nowrap font-weight-500 h2 mb-0 ml-3 my-auto text-white'>
            {bot.name}
          </BotHeader.Name>
          <span className='d-inline-block'>
            <BotHeader.BotEllipse status={bot?.status} className='my-auto' />
          </span>
        </div>
        <div className='d-flex align-items-center'>
          <Icon
            type='cryptocurrency'
            size='botSize'
            code={bot?.investment?.asset}
            style={{ position: "relative", left: "12%", width: "34px" }}
          />
          <Icon
            type='exchanges'
            size='botSize'
            code={bot?.investment?.credentials?.exchange}
          />
        </div>
      </div>
    </BotHeader.StyledCardHeader>
  );
};

BotHeader.StyledCardHeader = styled(CardHeader)`
  background: #2e3148 !important;
  border-radius: 8px 8px 0 0 !important;
  border-bottom: ${props =>
    props.error ? "2px solid #FF8D72" : "2px solid transparent"} !important;
`;

BotHeader.BotEllipse = styled(Ellipse)`
  width: 8px;
  height: 8px;
  margin-left: 6px;

  & circle {
    fill: ${props => (props.status === "running" ? "#5FD3BC" : "#777988")};
  }
`;

BotHeader.Name = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default BotHeader;
