import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ReactComponent as DropdownIndicatorIcon } from "assets/icons/dashboard/dropdownIndicator.svg";

const ChevronIcon = ({ size, color, colorHover, rotated, onClick }) => (
  <StyledChevronIcon
    size={size}
    color={color}
    colorHover={colorHover}
    rotated={rotated}
    onClick={onClick}
  />
);

const StyledChevronIcon = styled(DropdownIndicatorIcon)`
  width: ${({ size }) => size || "24px"};
  height: ${({ size }) => size || "24px"};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  transform: ${({ rotated }) => (rotated ? "rotate(-180deg)" : "none")};

  path {
    stroke: ${({ color }) => color || "var(--white-35)"};
    transition: all 0.2s ease-in-out;
  }

  &:hover path {
    stroke: ${({ colorHover }) => colorHover || "var(--white)"};
  }
`;

ChevronIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  rotated: PropTypes.bool,
  onClick: PropTypes.func,
};

ChevronIcon.defaultProps = {
  size: "24px",
  color: "var(--white-35)",
  rotated: false,
  onClick: null,
};

export default ChevronIcon;
