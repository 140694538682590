import styled from "styled-components";

export const ParameterAmountStyled = styled.p`
  margin-bottom: 0;
  color: ${({ textColor, amount }) =>
    textColor
      ? textColor
      : amount > 0
        ? "rgba(255, 255, 255)"
        : "rgba(255, 255, 255, 0.35)"} !important;
  font-weight: 500;
  font-size: 0.875rem;
  white-space: nowrap;
`;

export const ParameterCurrencyStyled = styled.span`
  margin: 0 0 0 4px;
  color: rgba(255, 255, 255, 0.35) !important;
  font-weight: 300;
  font-size: 0.875rem;
`;
