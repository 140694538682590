import React from "react";
import _ from "lodash";
import { CardBody, Card, CardHeader } from "reactstrap";
import PropTypes from "prop-types";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const ChannelsListPreload = ({ count = 1, isDashboard }) => {
  return _.times(count, e => (
    <Card
      key={e}
      className={`overflow-hidden ${isDashboard ? "pt-4" : "p-4"} mb-4`}
    >
      <CardHeader className='p-0 mb-4'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-items-center w-75'>
            <SkeletonPreloader borderRadius='4px' height={56} width={56} />
            <div className='d-flex flex-column ml-3 py-2'>
              <SkeletonPreloader height={17} width={143} />
              <SkeletonPreloader height={17} width={143} />
            </div>
          </div>
          <SkeletonPreloader borderRadius={"4px"} height={24} width={24} />
        </div>
      </CardHeader>
      <CardBody className='p-0'>
        <div className='d-flex justify-content-between align-items-center'>
          <SkeletonPreloader height={12} width={183} />
          <SkeletonPreloader height={12} width={83} />
        </div>
        <div className='d-flex justify-content-between align-items-center my-2'>
          <SkeletonPreloader height={12} width={183} />
          <SkeletonPreloader height={12} width={83} />
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <SkeletonPreloader height={12} width={183} />
          <SkeletonPreloader height={12} width={83} />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-3'>
          <SkeletonPreloader height={12} width={183} />
          <SkeletonPreloader height={12} width={83} />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-2'>
          <SkeletonPreloader height={8} />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-2'>
          <SkeletonPreloader height={12} width={183} />
          <SkeletonPreloader height={12} width={83} />
        </div>
      </CardBody>
      {!isDashboard && (
        <div className='mt-5'>
          <SkeletonPreloader borderRadius='6px' height={40} width={111} />
        </div>
      )}
    </Card>
  ));
};

SkeletonPreloader.propTypes = {
  count: PropTypes.number,
};

export default ChannelsListPreload;
