import styled from "styled-components";

export const StyledBotTypes = styled.div`
  gap: 12px;

  & .type-item {
    padding: 1.25rem 1.5rem;
    cursor: pointer;
    min-height: 166px;
    min-width: 160px;
    border: 2px solid #363a54;
    background: #2c2f44;
    border-radius: 10px;
    transition: all 0.3s;

    @media (max-width: 768px) {
      min-height: 78px;
    }

    &:hover {
      border: 2px solid var(--dusk-steel);
      background: #363a54;

      & svg {
        path {
          fill: #4a4e73;
        }
      }
    }

    &.active {
      border: 2px solid var(--info);
      background: #193e6f;
      box-shadow: 0px 4px 28px 0px rgba(6, 64, 126, 0.4);

      & svg {
        path {
          fill: #19519b;
        }

        & rect:first-child,
        & rect:nth-child(2) {
          fill: #19519b;
        }

        & rect {
          fill: #1a6edf;
          stroke: #193e6f;
        }
      }
    }

    & svg {
      position: absolute;
      right: 14.5px;
      top: 14.5px;

      &.template-icon {
        top: 2px;
        right: 12px;
      }
    }
  }
`;
