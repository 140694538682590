import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Bot from "./Bot";

const BotsListContent = ({ items }) => {
  return (
    <BotsListContent.BotsWrapper>
      {items?.map((bot, index) => (
        <Link to={`/bots/${bot.id}`} key={bot.id}>
          <Bot bot={bot} index={index} />
        </Link>
      ))}
    </BotsListContent.BotsWrapper>
  );
};

BotsListContent.BotsWrapper = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;

  @media (min-width: 578px) and (max-width: 1199.98px) {
    grid-template-columns: 48% 48%;
  }

  @media (max-width: 577.98px) {
    display: flex;
    flex-direction: column;
  }
`;

export default BotsListContent;
