export const dealsListSelector = state => state.deals.list;
export const selectDealsIndicators = state => state.deals.indicators;
export const selectDealsTrades = state => state.deals.trades;
export const selectDealsTradesRequests = state => state.deals.requests;
export const selectDealsOrders = state => state.deals.orders;
export const selectDealExchange = state =>
  state.deals.item.data?.bot?.investment?.credentials?.exchange;
export const selectOpenTradeRequest = state => state.deals.openTradeRequest;
export const selectHistoryTradeRequest = state =>
  state.deals.historyTradeRequest;
export const selectMarketInformation = state => state.deals.marketInformation;
export const selectRunningDeals = state => state.deals.running;
export const selectHistoryDeals = state => state.deals.history;
