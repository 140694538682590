import React, { useState } from "react";
import { CardHeader, Tooltip } from "reactstrap";
import classnames from "classnames";
import { useDispatch } from "react-redux";

import { ReactComponent as Celo } from "assets/icons/bot/celo.svg";
import { updateMarketConfig } from "store/deals/actions";
import { addSnackBar } from "store/snackbar/actions";
import { Checkbox } from "shared/ui";

const ActiveMarketsHeader = ({
  loadingPage,
  loading,
  data,
  botId,
  botType,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const dispatch = useDispatch();

  const toggleShow = () => setIsShow(!isShow);

  const handleChange = async () => {
    setIsLoading(true);
    const qsParams = {
      all: data?.metadata?.allMarkets ? false : true,
      enabled: [],
      disabled: [],
    };
    const res = await dispatch(updateMarketConfig(botId, qsParams));
    if (!res.errors) {
      dispatch(addSnackBar("success", res.message));
    } else {
      dispatch(addSnackBar("error", res.message));
    }
    setIsLoading(false);
  };

  return (
    <CardHeader className='d-flex p-4'>
      <div className='d-flex align-items-center'>
        <Celo />
        <p className='mb-0 ml-2 font-weight-500 text-white text-nowrap'>
          {`${data?.total || ""} Active markets`}
        </p>
      </div>
      {botType !== "genetic" ? (
        <div id='allMarkets' className='d-flex align-items-center ml-3 '>
          <Checkbox
            onChange={handleChange}
            checked={data?.metadata?.allMarkets}
            disabled={loadingPage || loading || isLoading}
            labelRight={
              <p
                className={classnames("mb-0 ml-2 text-white", {
                  "opacity-3": loadingPage || loading,
                })}
              >
                Enable all
              </p>
            }
          />
          <Tooltip
            target='allMarkets'
            placement='top-end'
            isOpen={isShow}
            toggle={toggleShow}
          >
            Enable at least 1 market for the bot to continue trading
          </Tooltip>
        </div>
      ) : null}
    </CardHeader>
  );
};

export default ActiveMarketsHeader;
