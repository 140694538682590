import React, { useId } from "react";
import styled from "styled-components";

const ReferralRow = ({
  data,
  handleMouseEnter,
  handleMouseLeave,
  hoverCurrentBg,
}) => {
  const id = useId();
  const labels = data?.labels;
  const colors = data?.backgroundColors;
  const items = data?.data;

  return (
    <div className='d-flex justify-content-between w-100 mx-3 my-4'>
      <div className='d-flex flex-column w-100'>
        {colors?.map((color, index) => (
          <ReferralRow.Wrapper
            key={id}
            className='d-flex justify-content-between p-3'
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            hoverCurrentBg={hoverCurrentBg}
          >
            <div key={id} className='d-flex align-items-center'>
              <ReferralRow.Color
                className='mr-1'
                style={{ background: color }}
              />
              <p className='text-white font-sm font-weight-500 mb-0'>
                {labels[index]}
              </p>
            </div>
            <p key={id} className='text-white-35 font-sm font-weight-300'>
              {items[index]}
            </p>
          </ReferralRow.Wrapper>
        ))}
      </div>
    </div>
  );
};

ReferralRow.Wrapper = styled.div`
  &:hover {
    border-radius: 8px;
    background: #2e3148;
  }
`;

ReferralRow.Color = styled.span`
  width: 14px;
  height: 14px;
  border-radius: 16px;
`;

export default ReferralRow;
