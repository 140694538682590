import React from "react";
import { FormGroup } from "reactstrap";
import { DelayInput } from "react-delay-input";
import styled from "styled-components";

import { SIDE_OPTIONS, STATUS_OPTIONS } from "models/deal/enum/tables";
import { RefreshButton, SelectInput } from "shared/ui";

const MarketFilters = ({
  tableView,
  handleFilter,
  loadingOrders,
  loadingTrades,
  sideFilter,
  setSideFilter,
  currentCredId,
  statusFilter,
  setStatusFilter,
  handleRefresh,
}) => {
  return (
    <MarketFilters.Wrapper className='d-flex align-items-md-center ml-auto mr-0'>
      <FormGroup className='mb-0 my-2 my-sm-0 mx-sm-2 side'>
        <SelectInput
          isClearable
          placeholder='Side'
          name='type'
          options={SIDE_OPTIONS}
          onChange={option => {
            setSideFilter(option?.value);
            handleFilter({ type: { eq: option?.value } });
          }}
          isDisabled={loadingOrders || loadingTrades || !currentCredId}
          value={SIDE_OPTIONS?.filter(item => item.value === sideFilter)}
        />
      </FormGroup>

      {tableView === "orders" && (
        <FormGroup className='mb-0 mr-2 status'>
          <SelectInput
            isClearable
            placeholder='Status'
            name='status'
            options={STATUS_OPTIONS}
            value={STATUS_OPTIONS?.filter(item => item.value === statusFilter)}
            onChange={option => {
              setStatusFilter(option?.value);
              handleFilter({ status: { eq: option?.value } });
            }}
            isDisabled={loadingOrders || loadingTrades || !currentCredId}
          />
        </FormGroup>
      )}
      <FormGroup className='mb-0 orderId'>
        <DelayInput
          autoComplete='off'
          delayTimeout={700}
          minLength={1}
          type='text'
          name='id'
          className='form-control'
          placeholder='Order'
          onChange={({ target }) =>
            handleFilter({
              orderNumber: { like: target.value },
            })
          }
          disabled={loadingOrders || loadingTrades || !currentCredId}
        />
      </FormGroup>
      <RefreshButton
        className='ml-3'
        onHandleRefresh={handleRefresh}
        width={16}
        height={16}
        withHoverBg={false}
        loading={loadingOrders || loadingTrades}
      />
    </MarketFilters.Wrapper>
  );
};

MarketFilters.Wrapper = styled.div`
  @media (max-width: 768px) {
    .orderId,
    .status,
    .side {
      width: 100% !important;
    }
  }

  .orderId,
  .status {
    width: 145px;
  }
  .side {
    width: 88px;
  }
`;

export default MarketFilters;
