import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { addSnackBar } from "store/snackbar/actions";
import empty from "assets/icons/general/empty-state-planet2.svg";

import {
  EmptyRequestsCardStyled,
  EmptyRequestsDescriptionStyled,
  EmptyRequestsTitleStyled,
} from "./index.styled";
import { Button } from "shared/ui/buttons";

const EmptyRequestsList = ({
  title,
  beforeDescription,
  afterDescription,
  id,
  onHandleRefresh,
  onHandleForceStart,
  isStartOrRun,
}) => {
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();

  const [emptyTitle, emptyFilterTitle] = title;
  const [emptybeforeDescription, emptyFilterbeforeDescription] =
    beforeDescription;
  const [emptyafterDescription, emptyFilterafterDescription] = afterDescription;

  const handleForceRun = async () => {
    setLoad(true);
    const res = await dispatch(onHandleForceStart(id));
    if (!res?.errors) {
      dispatch(addSnackBar("success", res.message));
      setLoad(false);
      onHandleRefresh();
    }
    if (res?.errors) {
      dispatch(addSnackBar("error", res.message));
      return setLoad(false);
    }
  };

  return (
    <EmptyRequestsCardStyled>
      <img src={empty} alt='empty' />
      <EmptyRequestsTitleStyled>
        {isStartOrRun ? emptyTitle : emptyFilterTitle}
      </EmptyRequestsTitleStyled>
      <EmptyRequestsDescriptionStyled>
        {isStartOrRun ? emptybeforeDescription : emptyFilterbeforeDescription}
        <span className='ml-1 mr-1 font-weight-500 text-success'>BUY</span>
        or
        <span className='ml-1 mr-1 font-weight-500 text-danger'>SELL</span>
        {isStartOrRun ? emptyafterDescription : emptyFilterafterDescription}
      </EmptyRequestsDescriptionStyled>
      {isStartOrRun ? (
        <Button
          disabled={load}
          color='success'
          onClick={handleForceRun}
          loading={load}
          className='text-dark'
          size='middle'
        >
          Force start
        </Button>
      ) : null}
    </EmptyRequestsCardStyled>
  );
};

export default EmptyRequestsList;
