import React from "react";
import PropTypes from "prop-types";

import ActiveSignalsTab from "./Signals/ActiveSignals/ActiveSignalsTab";
import CompletedSignalsTab from "./Signals/CompletedSignals/CompletedSignalsTab";

const ChannelSignalsTables = ({ channel, loadingPage }) => (
  <>
    <ActiveSignalsTab
      id={channel?.id}
      loadingPage={loadingPage}
      owner={channel?.owner}
      subscribed={channel?.subscribed}
      logo={channel?.logos?.small}
      name={channel?.name}
      totalSubscribers={channel?.totalSubscribers}
    />
    <CompletedSignalsTab
      id={channel?.id}
      loadingPage={loadingPage}
      owner={channel?.owner}
      subscribed={channel?.subscribed}
    />
  </>
);

ChannelSignalsTables.propTypes = {
  loadingPage: PropTypes.bool,
};

export default ChannelSignalsTables;
