import React, { useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, CardBody } from "reactstrap";
import qs from "qs";

import { getBacktests } from "store/bots/actions";
import { ReactComponent as Icon } from "assets/icons/general/backtest.svg";
import BacktestsTable from "./BacktestsTable";
import BacktestPreload from "./BacktestPreload";
import CreateBacktestModal from "./CreateBacktestModal";
import PaginationPanel from "shared/ui/PaginationPanel";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import { Button } from "shared/ui/buttons";

const BacktestWidget = ({ botId, loadingPage }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const initLoading = useRef(true);

  const dispatch = useDispatch();

  const backtestsData = useSelector(state => state.bots.backtests.data);
  const loading = useSelector(state => state.bots.backtests.loading);

  const { handleSorting, handlePaginate } = useFiltersWithRequest(
    backtestsData.request,
    getBacktests,
  );

  useLayoutEffect(() => {
    const queryString = qs.stringify({
      filter: { "backtest.bot": { eq: botId } },
      limit: 10,
    });
    dispatch(getBacktests(`?${queryString}`));

    setTimeout(() => {
      initLoading.current = false;
    }, 100);
  }, [dispatch, botId]);

  const handleToggleModal = () => setIsModalOpen(prev => !prev);

  if ((loading || loadingPage) && initLoading.current) {
    return (
      <Card className='mb-4 overflow-hidden'>
        <BacktestPreload renderHeader />
      </Card>
    );
  }

  return (
    <>
      <Card className='mb-4 p-0 overflow-hidden'>
        <CardHeader className='d-flex align-items-center justify-content-between py-3 pl-4 pr-3'>
          <div className='d-flex align-items-center'>
            <Icon />
            <p className='mb-0 ml-2 text-white font-weight-500'>Backtest</p>
          </div>
          <Button color='blue' onClick={() => setIsModalOpen(true)}>
            Run a new backtest
          </Button>
        </CardHeader>
        {(loading || loadingPage) && !initLoading.current ? (
          <BacktestPreload renderHeader={false} />
        ) : (
          <>
            <CardBody className={`p-0 ${backtestsData.total > 10 && "mb-3"}`}>
              <BacktestsTable
                items={backtestsData.items}
                request={backtestsData.request}
                handleSorting={handleSorting}
              />
            </CardBody>
            <PaginationPanel
              activePage={backtestsData.page}
              totalItemsCount={backtestsData.total}
              onChange={page => handlePaginate(page)}
            />
          </>
        )}
      </Card>
      <CreateBacktestModal
        handleToggleModal={handleToggleModal}
        isModalOpen={isModalOpen}
        botId={botId}
      />
    </>
  );
};

export default BacktestWidget;
