import React from "react";
import styled from "styled-components";
import { DelayInput } from "react-delay-input";
import { CardBody, Card } from "reactstrap";
import qs from "qs";
import { useHistory } from "react-router-dom";

import { beforeToday } from "helpers/DatepickerValidation";
import FiltersPreloader from "../compounds/FiltersPreloader";
import { SIDE_OPTIONS, STATUS_OPTIONS } from "models/deal/enum/tables";
import { DateField, SelectInput } from "shared/ui";

const Filters = ({
  location,
  credentialId,
  markets,
  setEmptyFilter,
  loading,
}) => {
  const history = useHistory();
  const { filter, sort } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const handleFilter = value => {
    setEmptyFilter({ filter: { ...filter, ...value } });
    history.push({
      pathname: `/credentials/${credentialId}/orders`,
      search: qs.stringify({
        filter: { ...filter, ...value },
        sort: { ...sort },
      }),
    });
  };

  const isManualRequest = e => {
    const isManualFilter = {
      isManual: e.target.checked ? { neq: e.target.checked } : {},
    };

    history.push({
      pathname: `/credentials/${credentialId}/orders`,
      search: qs.stringify({
        filter: { ...filter, ...isManualFilter },
        sort: { ...sort },
      }),
    });
  };

  if (loading) return <FiltersPreloader fieldsAmount={6} />;

  return (
    <Card className='mb-4' style={{ borderRadius: "8px" }}>
      <CardBody className='p-4'>
        <div className='d-flex flex-column flex-md-row justify-content-between'>
          <SelectInput
            isClearable
            className='w-100 mr-0 mr-md-3 mb-2 mb-md-0'
            placeholder='Side'
            name='type'
            onChange={option => handleFilter({ type: { eq: option?.value } })}
            value={SIDE_OPTIONS.find(o => o.value === filter?.type?.eq)}
            options={SIDE_OPTIONS}
          />
          <DelayInput
            className='form-control w-100 mr-0 mr-md-3 mb-2 mb-md-0'
            delayTimeout={700}
            minLength={1}
            type='text'
            name='id'
            placeholder='Order ID'
            value={filter && filter.orderNumber ? filter.orderNumber.like : ""}
            onChange={({ target }) =>
              handleFilter({ orderNumber: { like: target.value } })
            }
          />
          <SelectInput
            isClearable
            className='w-100 mr-0 mr-md-3 mb-2 mb-md-0'
            placeholder='Market'
            name='market'
            isMulti
            value={filter?.market?.in?.map(market => ({
              label: market,
              value: market,
              icon: {
                type: "cryptocurrency",
                size: "selectSize",
                code: market.split("-")[0],
              },
            }))}
            onChange={values => {
              handleFilter({
                market: { in: values ? values.map(e => e.value) : "" },
              });
            }}
            options={
              markets?.data?.length &&
              markets?.data?.map(market => {
                return {
                  label: market,
                  value: market,
                  color: "#FFFFFF",
                  icon: {
                    type: "cryptocurrency",
                    size: "smallSize",
                    code: market.split("-")[0],
                  },
                };
              })
            }
          />
          <SelectInput
            isClearable
            className='w-100 mr-0 mr-md-3 mb-2 mb-md-0'
            placeholder='Status'
            name='status'
            onChange={option => handleFilter({ status: { eq: option?.value } })}
            value={STATUS_OPTIONS.find(o => o.value === filter?.status?.eq)}
            options={STATUS_OPTIONS}
          />
          <DateField
            className='w-100 mr-0 mr-md-2 mb-2 mb-md-0'
            onChange={value => {
              handleFilter({
                createdAt: {
                  gte: value,
                },
              });
            }}
            isValidDate={beforeToday}
            dateFormat='YYYY-MM-DD HH:mm:ss'
            inputProps={{
              style: {
                background: "#2E3148",
                color: "#FFFFFF",
                padding: "11px",
              },
              readOnly: true,
              className: "form-control",
              name: "createdAt",
              placeholder: "Start date",
              value: filter?.createdAt?.gte || "",
            }}
          />
          <DateField
            className='w-100 mr-0 mr-md-3 mb-2 mb-md-0'
            onChange={value => {
              handleFilter({
                createdAt: {
                  gte: filter?.createdAt?.gte,
                  lte: value,
                },
              });
            }}
            dateFormat='YYYY-MM-DD HH:mm:ss'
            formatValue='YYYY-MM-DD HH:mm:ss'
            isValidDate={current =>
              current.isAfter(filter?.createdAt?.gte) && beforeToday(current)
            }
            inputProps={{
              style: {
                background: "#2E3148",
                color: "#FFFFFF",
                padding: "11px",
              },
              readOnly: true,
              className: "form-control",
              name: "createdAt",
              placeholder: "End date",
              value: filter?.createdAt?.lte || "",
            }}
          />
          <Filters.Label className='checkbox mb-0 w-100 text-nowrap'>
            <input
              type='checkbox'
              className='mr-2'
              onClick={event => isManualRequest(event)}
            />
            <span className='text-white'>Show manual orders</span>
          </Filters.Label>
        </div>
      </CardBody>
    </Card>
  );
};

Filters.Calendar = styled.div`
  position: absolute;
  top: 20%;
  right: 4%;

  svg {
    path {
      fill: #a3a3a3;
    }
  }
`;

Filters.Label = styled.label`
  display: flex;
  align-items: center;
  background: #393b53;
  border-radius: 8px;
  border: none;
  color: #ffffff;
  position: relative;
  height: 38px;
  cursor: pointer;

  span {
    color: #34495e;
    padding: 0.6rem 0.6rem 0.6rem 0;
  }

  input {
    height: 16px;
    width: 16px;
    margin: 0 0 0 11px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid #4c5075;
    border-radius: 2px;
    outline: none;
    transition-duration: 0.3s;
    background-color: white;
  }

  input:checked {
    border: 1px solid #4c5075;
    background-color: #3fcbb0;
  }

  input:checked + span::before {
    content: "\\2713";
    display: block;
    text-align: center;
    color: #1d1e2a;
    position: absolute;
    left: 0.9rem;
  }
`;

export default Filters;
