import React from "react";

import { IndicatorForm } from "./components";
import useIndicatorSettings from "shared/entities/bot/model/useIndicatorSettings";

const IndicatorSettingsBlock = ({
  isEdit,
  indicators = {},
  isShowCreationBlock,
  toogleEdit,
}) => {
  const {
    formValues,
    configurations,
    currentConfiguration,
    handleChange,
    onHandleResetChart,
    handleSubmit,
    setSelectedMarket,
    selectedMarket,
    errors,
    isValid,
    subscription,
    wizardMarkets,
    submitted,
  } = useIndicatorSettings(isEdit, indicators, toogleEdit);

  return (
    <IndicatorForm
      submitted={submitted}
      formValues={formValues}
      configurations={configurations}
      wizardMarkets={wizardMarkets}
      currentConfiguration={currentConfiguration}
      setFieldValue={handleChange}
      onHandleResetChart={onHandleResetChart}
      isShowCreationBlock={isShowCreationBlock}
      isEdit={isEdit}
      subscription={subscription?.type}
      setSelectedMarket={setSelectedMarket}
      toogleEdit={toogleEdit}
      handleSubmit={handleSubmit}
      selectedMarket={selectedMarket}
      errors={errors}
      isValid={isValid}
    />
  );
};

export default IndicatorSettingsBlock;
