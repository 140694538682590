export const GET_BACKTESTING_LOADING = "GET_BACKTESTING_LOADING";
export const GET_BACKTESTING_SUCCESS = "GET_BACKTESTING_SUCCESS";
export const GET_BACKTESTING_FAILURE = "GET_BACKTESTING_FAILURE";
export const CREATE_BACKTEST = "CREATE_BACKTEST";
export const GET_BACKTEST_LOADING = "GET_BACKTEST_LOADING";
export const GET_BACKTEST_SUCCESS = "GET_BACKTEST_SUCCESS";
export const GET_BACKTEST_FAILURE = "GET_BACKTEST_FAILURE";
export const RESET_BACKTESTING_LIST = "RESET_BACKTESTING_LIST";
export const RESET_BACKTESTING_ITEM = "RESET_BACKTESTING_ITEM";
export const DELETE_BACKTEST = "DELETE_BACKTEST";
