export const CREATE_SIGNAL = "CREATE_SIGNAL";
export const CREATE_ACTIVE_SIGNAL = "CREATE_ACTIVE_SIGNAL";
export const GET_ACTIVE_SIGNALS_SUCCESS = "GET_ACTIVE_SIGNALS_SUCCESS";
export const GET_ACTIVE_SIGNALS_ERROR = "GET_ACTIVE_SIGNALS_ERROR";
export const GET_COMPLETED_SIGNALS_SUCCESS = "GET_COMPLETED_SIGNALS_SUCCESS";
export const GET_COMPLETED_SIGNALS_ERROR = "GET_COMPLETED_SIGNALS_ERROR";
export const RESET_CHANNELS = "RESET_CHANNELS";
export const SUBSCRIBE_CHANNEL_SIGNAL = "SUBSCRIBE_CHANNEL_SIGNAL";
export const ACCEPT_SIGNAL = "ACCEPT_SIGNAL";
export const ACCEPT_SIGNAL_ITEM = "ACCEPT_SIGNAL_ITEM";
export const ACCEPT_SIGNAL_ACTIVE = "ACCEPT_SIGNAL_ACTIVE";
