import React, { useLayoutEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { selectMarketInformation } from "store/deals/selectors";
import { fetchMarketInformation } from "store/deals/actions";
import { LineWithError } from "shared/ui";
import {
  MarketInformationPreloader,
  MarketInformHeader,
  MarketInformItem,
} from "./ui";

const MarketInformation = ({ base, quote, loadingPage, exchange }) => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(selectMarketInformation);

  useLayoutEffect(() => {
    if (exchange) {
      dispatch(fetchMarketInformation(exchange, base, quote));
    }
  }, [exchange, base, quote, dispatch]);

  if (loadingPage || loading) return <MarketInformationPreloader />;

  return (
    <Card className='p-0 mb-4'>
      <MarketInformHeader active={data?.active} />
      {!data.active && (
        <LineWithError
          errorMessage={`Market is ${data?.base}-${data?.quote} inactive. Please check back later.`}
          radius='0'
        />
      )}
      <CardBody
        className={`d-flex flex-wrap px-4 pb-4 ${
          !data?.active ? "pt-4" : "pt-0"
        } justify-content-between`}
      >
        <MarketInformItem
          title='Minimum amount'
          value={data?.minAmount}
          currency={quote}
        />

        <MarketInformItem
          title='Minimum quantity'
          value={data?.minQuantity}
          currency={base}
        />

        <MarketInformItem title='Price scale' value={data?.priceScale} />

        <MarketInformItem title='Amount scale' value={data?.amountScale} />

        <MarketInformItem title='Quantity scal' value={data?.quantityScale} />
      </CardBody>
    </Card>
  );
};

export default React.memo(MarketInformation);
