import classNames from "classnames";
import { Field } from "formik";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { formatterToNull } from "helpers/valuesFormatters";
import { ReactComponent as Trash } from "assets/icons/trash-red-icon.svg";

const TargetSignal = ({
  label,
  type,
  name,
  targetPercent,
  placeholder,
  isSubmitted,
  error,
  removeTarget,
  index,
}) => {
  return (
    <>
      <TargetSignal.Wrap className='d-flex align-items-center'>
        <div className='d-flex align-items-center p-0 w-100 position-relative'>
          <TargetSignal.Label className='text-white font-weight-400 font-md p-2'>
            {label}
          </TargetSignal.Label>
          <TargetSignal.Field
            type={type}
            name={name}
            placeholder={placeholder}
            className={classNames(
              "form-control",
              {
                "has-error": isSubmitted && error,
              },
              "mb-0 rounded-right text-white font-weight-500 font-md",
            )}
          />
          <span className='text-white-35 font-weight-400 font-md p-2 position-absolute right-0'>
            {formatterToNull(targetPercent).toFixed(2)}%
          </span>
        </div>
        {index !== 0 && (
          <TargetSignal.Trash
            onClick={() => removeTarget(index)}
            className='mx-3 p-0'
          />
        )}
      </TargetSignal.Wrap>
      {isSubmitted && error && (
        <p className='text-danger font-sm font-weight-300 m-0'>{error}</p>
      )}
    </>
  );
};

TargetSignal.Wrap = styled.div`
  margin-bottom: 0.375rem !important;
`;

TargetSignal.Label = styled.span`
  position: absolute;
  border-right: 1px solid #44486a;
`;

TargetSignal.Field = styled(Field)`
  &.form-control {
    padding: 0.6875rem 3rem !important;
  }
`;

TargetSignal.Trash = styled(Trash)`
  cursor: pointer;

  &:hover {
    transform: scale(0.9);
  }
`;

TargetSignal.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  targetPercent: PropTypes.number,
  placeholder: PropTypes.string,
  isSubmitted: PropTypes.bool,
  errors: PropTypes.string,
  removeTarget: PropTypes.func,
  index: PropTypes.number,
};

export default TargetSignal;
