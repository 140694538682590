import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";

import { CredentialItem, CustomScrollbars, LineWithError } from "shared/ui";
import PortfolioTable from "./components/PortfolioTable";

const Credential = ({ data, subscription, id, index, balanceHidden }) => {
  return (
    <Credential.Card className='mb-3'>
      <Link to={`/credentials/${id}`}>
        <CredentialItem
          data={data}
          index={index}
          balanceHidden={balanceHidden}
        />
      </Link>
      <LineWithError errorMessage={data?.error} radius='0' />
      {!data?.error ? (
        <CustomScrollbars
          autoHideTimeout={500}
          autoHideDuration={500}
          autoHeight
          autoHeightMax={Number.MAX_VALUE}
        >
          <PortfolioTable
            credentialId={id}
            portfolio={data.portfolio}
            currency={data.currency}
            balance={data.balance}
            connected={data.connected}
            id={data.id}
            subscription={subscription}
            exchangeIndex={index}
            autoRebalancing={data?.rebalance}
            status={data.status}
            balanceHidden={balanceHidden}
          />
        </CustomScrollbars>
      ) : (
        <PortfolioTable
          credentialId={id}
          portfolio={data.portfolio}
          currency={data.currency}
          balance={data.balance}
          connected={data.connected}
          id={data.id}
          subscription={subscription}
          exchangeIndex={index}
          autoRebalancing={data?.rebalance}
          status={data.status}
        />
      )}
    </Credential.Card>
  );
};

Credential.Card = styled(Card)`
  cursor: pointer;
  border-radius: 8px !important;
  overflow: hidden;
`;

export default Credential;
