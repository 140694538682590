import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import _ from "lodash";
import React from "react";
import styled from "styled-components";

const RenderTabs = ({
  arrTabs,
  currentTab,
  setPagView,
  loading,
  childrenPreload,
  shouldExclude,
}) => {
  if (loading) {
    return (
      <>
        <RenderTabs.Page className='justify-content-center justify-content-sm-start'>
          {_.times(arrTabs.length, e => (
            <div className='d-flex flex-column'>
              <SkeletonPreloader key={e} height={11} width={66} />
              <SkeletonPreloader
                height={3}
                width={66}
                className={`${e === 0 ? "" : "d-none"} mt-2`}
              />
            </div>
          ))}
        </RenderTabs.Page>
        {childrenPreload}
      </>
    );
  }
  return (
    <RenderTabs.Page className='justify-content-center justify-content-sm-start'>
      {arrTabs.map(page => {
        if (typeof shouldExclude === "function" && shouldExclude(page))
          return null;
        return (
          <div key={page} data-testid={page}>
            <RenderTabs.Tab
              current={currentTab === page}
              onClick={() => setPagView(page)}
            >
              {page}
            </RenderTabs.Tab>
            <span className={`${currentTab === page ? "active" : ""}`} />
          </div>
        );
      })}
    </RenderTabs.Page>
  );
};

RenderTabs.Page = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  gap: 1.5rem;

  div {
    span {
      &.active {
        display: block;
        border-bottom: 3px solid var(--info);
        border-radius: 2px;
      }
    }
  }
`;

RenderTabs.Tab = styled.p.attrs(props => ({
  style: {
    color: props.current ? "var(--white)" : "rgba(255, 255, 255, 0.35)",
  },
}))`
  white-space: nowrap;
  cursor: pointer;
  margin-bottom: 8px;
  font-weight: 500;
  text-transform: capitalize;

  &:hover {
    color: #fff;
  }
`;

export default RenderTabs;
