import React from "react";
import { StyledCandleConditionWrap } from "../../index.styles";
import { SkeletonPreloader } from "shared/ui";

const PreloaderCandleCondition = () => {
  return (
    <StyledCandleConditionWrap className='d-flex flex-column flex-md-row align-items-md-center mb-2'>
      <div className='d-flex'>
        <SkeletonPreloader width={109} height={32} />
        <SkeletonPreloader
          width={60}
          height={20}
          className='text-white font-weight-500 font-sm mb-0 ml-3 ml-md-4 my-auto'
        />
        <SkeletonPreloader
          width={40}
          height={20}
          className='text-white-35 font-weight-400 font-sm text-nowrap mb-0 ml-3 ml-md-4 my-auto'
        />
      </div>
      <div className='d-flex ml-md-auto mr-0 mt-3 mt-md-0'>
        <SkeletonPreloader width={200} height={32} />
        <div className='mx-3'>
          <SkeletonPreloader width={32} height={32} />
        </div>
        <div className='ml-0'>
          <SkeletonPreloader width={32} height={32} />
        </div>
      </div>
    </StyledCandleConditionWrap>
  );
};

export default PreloaderCandleCondition;
