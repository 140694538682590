import React from "react";
import { CardBody } from "reactstrap";
import classNames from "classnames";

import {
  formatterToNull,
  formatValue,
  setColorClass,
} from "helpers/valuesFormatters";
import BotSettingsWidgetPreloader from "./preloaders/BotSettingsWidgetPreloader";
import Criterias from "./Criterias";
import BotMarket from "./BotMarket";

const BotSettingsBody = ({ data = {}, loading, withCriterias }) => {
  const conditionsValues = [
    data.volume,
    data.minimumTrades,
    data.marketDirection,
    data.minimumDailyChange,
    data.maximumDailyChange,
    data.markPriceDifference,
  ];
  const showConditions = conditionsValues?.some(item => item);

  if (loading) return <BotSettingsWidgetPreloader />;

  return (
    <CardBody className='pt-3 pb-4 px-4'>
      <div className='d-flex flex-column'>
        <div className='d-flex align-items-center justify-content-between'>
          <p className='font-sm text-whit-75 mb-0'>Type</p>
          <p className='mb-0 font-weight-500 text-white text-capitalize'>
            {data?.type}
          </p>
        </div>
        <div className='d-flex justify-content-between mt-2'>
          <p className='font-sm text-whit-75 mb-0'>Period</p>
          <p className='mb-0 font-weight-500 text-white'>
            {`${formatterToNull(data.period)} minutes`}
          </p>
        </div>
        <div className='d-flex justify-content-between mt-2'>
          <p className='font-sm text-white-75 mb-0'>Market</p>
          <BotMarket data={data} />
        </div>
        {showConditions ? (
          <p className='font-md font-weight-500 mb-0 text-white pt-4'>
            Conditions
          </p>
        ) : null}
        {data.volume ? (
          <div className='d-flex flex-md-column flex-lg-row align-items-lg-center text-sm-nowrap justify-content-between mt-2 text-nowrap'>
            <div className='d-flex flex-row align-items-center'>
              <p className='font-sm text-whit-75 mb-0'>
                Minimum market volume 24h
              </p>
            </div>
            <div className='d-flex align-items-center'>
              <p
                className={`mb-0 font-weight-500 ${data.volume > 0 ? "text-white" : "text-white opacity-35"}`}
              >
                {formatterToNull(data.volume)}
              </p>
              <p className='mb-0 font-weight-300 ml-1 text-white opacity-35'>
                {data.investment?.asset || ""}
              </p>
            </div>
          </div>
        ) : null}
        {data.minimumTrades ? (
          <div className='d-flex flex-md-column flex-lg-row align-items-lg-center text-sm-nowrap justify-content-between mt-2'>
            <div className='d-flex flex-row align-items-center'>
              <p className='font-sm text-whit-75 mb-0'>
                Minimum market trades 24h
              </p>
            </div>
            <p
              className={`mb-0 font-weight-500 ${data.minimumTrades > 0 ? "text-white" : "text-white opacity-35"}`}
            >
              {formatterToNull(data.minimumTrades)}
            </p>
          </div>
        ) : null}
        {data.marketDirection ? (
          <div className='d-flex flex-md-column flex-lg-row align-items-lg-center text-sm-nowrap justify-content-between mt-2'>
            <p className='font-sm text-whit-75 mb-0'>Trend</p>
            <p
              className={classNames("mb-0 font-weight-500 text-capitalize", {
                "text-danger": data.marketDirection === "bear",
                "text-success": data.marketDirection === "bull",
              })}
            >
              {data.marketDirection}
            </p>
          </div>
        ) : null}
        {data.minimumDailyChange ? (
          <div className='d-flex flex-md-column flex-lg-row align-items-lg-center text-sm-nowrap justify-content-between mt-2'>
            <p className='font-sm text-whit-75 mb-0'>Minimum 24h change</p>
            <p
              className={`mb-0 font-weight-500 ${setColorClass(data.minimumDailyChange)}`}
            >
              {`${formatterToNull(formatValue(data.minimumDailyChange))}%`}
            </p>
          </div>
        ) : null}
        {data.maximumDailyChange ? (
          <div className='d-flex flex-md-column flex-lg-row align-items-lg-center text-sm-nowrap justify-content-between mt-2'>
            <p className='font-sm text-whit-75 mb-0'>Maximum 24h change</p>
            <p
              className={`mb-0 font-weight-500 ${setColorClass(data.maximumDailyChange)}`}
            >
              {`${formatterToNull(formatValue(data.maximumDailyChange))}%`}
            </p>
          </div>
        ) : null}
        {data.markPriceDifference ? (
          <div className='d-flex flex-md-column flex-lg-row align-items-lg-center text-sm-nowrap justify-content-between mt-2'>
            <p className='font-sm text-whit-75 mb-0'>Mark price</p>
            <p className='mb-0 font-weight-500 text-white'>
              {`${formatterToNull(formatValue(data.markPriceDifference))}%`}
            </p>
          </div>
        ) : null}
        {data.spreadPercent ? (
          <div className='d-flex flex-md-column flex-lg-row align-items-lg-center text-sm-nowrap justify-content-between mt-2'>
            <p className='font-sm text-whit-75 mb-0'>Bid-Ask spread</p>
            <p className='mb-0 font-weight-500 text-white'>
              {`${formatterToNull(data.spreadPercent)}%`}
            </p>
          </div>
        ) : null}
        {withCriterias &&
        data?.criterion?.length &&
        data?.type === "genetic" ? (
          <Criterias criterias={data?.criterion} className='mt-4' />
        ) : null}
      </div>
    </CardBody>
  );
};

export default BotSettingsBody;
