import React from "react";

import { ReactComponent as TrashIcon } from "assets/icons/general/trash-indicator-icon.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/general/settings-indicator-icon.svg";

const SettingsTrashIcons = ({
  classNameWrap,
  onSettingsClick,
  onDeleteClick,
  isSettingsDisabled,
  isEdition,
}) => {
  return (
    <div className={classNameWrap}>
      <div
        className='settings-indicator-icon mr-3'
        onClick={!isSettingsDisabled && !isEdition ? onSettingsClick : null}
      >
        <SettingsIcon />
      </div>
      <div className='trash-indicator-icon ml-0' onClick={onDeleteClick}>
        <TrashIcon />
      </div>
    </div>
  );
};

export default SettingsTrashIcons;
