import React from "react";

import { DealOpenTradeFlag } from "../../Deal/index.styles";

const DealOpenTradeTitle = () => (
  <div className='d-flex align-items-center'>
    <DealOpenTradeFlag data-testid='deal-open-trade-icon-flag' />
    <p
      data-testid='deal-open-trade-title'
      className='mb-0 ml-2 text-white font-weight-500 text-nowrap'
    >
      Open trade request
    </p>
  </div>
);

export default DealOpenTradeTitle;
