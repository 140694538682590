import React from "react";
import { FormGroup } from "reactstrap";
import { DelayInput } from "react-delay-input";

const RequestsFiltersTrades = ({ tradesRequests, handleFilter }) => {
  return (
    <FormGroup className='mb-0'>
      <DelayInput
        autocomplete='off'
        delayTimeout={700}
        minLength={1}
        type='text'
        name='id'
        className='form-control'
        placeholder='Order ID'
        value={tradesRequests?.request?.filter?.orderNumber?.like}
        onChange={({ target }) =>
          handleFilter({
            orderNumber: { like: target.value },
          })
        }
      />
    </FormGroup>
  );
};

export default RequestsFiltersTrades;
