import React from "react";
import { Card } from "reactstrap";
import PropTypes from "prop-types";

import ChannelDefaultWidgetHeader from "../reusable/ChannelDefaultWidgetHeader";
import { ProfitStatistics } from "views/channels/channelPropTypes";
import ChannelStatisticsBody from "shared/ui/ChannelWidget/ChannelStatisticsBody";
import ChannelWidgetPreload from "views/channels/preloaders/ChannelWidgetPreload";

const ChannelStatisticsWidget = ({ loading, average, total, month }) => {
  if (loading) return <ChannelWidgetPreload count={3} />;
  return (
    <Card className='p-4'>
      <ChannelDefaultWidgetHeader
        title='Statistics'
        isWidget
        className='mb-4'
      />
      <ChannelStatisticsBody average={average} total={total} month={month} />
    </Card>
  );
};

ChannelStatisticsWidget.propType = {
  loading: PropTypes.string,
  ...ProfitStatistics,
};

export default ChannelStatisticsWidget;
