import moment from "moment";

export const candlestickChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: "time",
      time: {
        unit: "minute",
        tooltipFormat: "YYYY-MM-DD HH:mm:ss",
      },
      ticks: {
        source: "auto",
      },
    },
    y: {
      position: "right",
    },
  },
  plugins: {
    tooltip: {
      mode: "index",
      intersect: false,
    },
    legend: {
      display: false,
    },
    zoom: {
      pan: {
        enabled: true,
        mode: "xy",
      },
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
        mode: "xy",
      },
    },
  },
};

export const getChartData = (candles, signals) => {
  if (!candles || !signals) {
    return null;
  }

  return {
    datasets: [
      {
        label: "Price",
        data: Object.keys(candles)?.map(key => {
          const candle = candles[key];
          return {
            x: moment(candle.startTime, "YYYY-MM-DD HH:mm:ss"),
            o: candle.open,
            h: candle.high,
            l: candle.low,
            c: candle.close,
            signal: signals[key],
          };
        }),
        borderColor: "black",
        backgroundColor: context => {
          const dataPoint = context.raw;
          return dataPoint.signal === "buy"
            ? "rgba(0, 255, 0, 0.5)"
            : dataPoint.signal === "sell"
              ? "rgba(255, 0, 0, 0.5)"
              : "rgba(0, 0, 0, 0)";
        },
        borderWidth: 1,
        barThickness: 7,
      },
    ],
  };
};

export const getAnnotations = (candles, signals) =>
  Object.keys(candles)
    ?.map(key => {
      const candle = candles[key];
      const signal = signals[key];
      const isBuy = signal === "buy";
      if (signal) {
        return {
          type: "point",
          xValue: moment(candle.startTime, "YYYY-MM-DD HH:mm:ss"),
          yValue: isBuy ? candle.high : candle.low,
          pointStyle: isBuy
            ? createArrowCanvas("green", "up")
            : createArrowCanvas("red"),
          radius: 5,
          yAdjust: isBuy ? -10 : 10,
        };
      }

      return null;
    })
    .filter(annotation => annotation !== null);

function createArrowCanvas(color, direction, size = 24) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = size;
  canvas.height = size;

  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.arc(size / 2, size / 2, size / 2 - 5, 0, 2 * Math.PI);
  ctx.fill();

  ctx.fillStyle = "white";
  ctx.beginPath();
  if (direction === "up") {
    ctx.moveTo(size / 2, size / 4);
    ctx.lineTo(size / 2 + size / 10, size / 2);
    ctx.lineTo(size / 2 + size / 20, size / 2);
    ctx.lineTo(size / 2 + size / 20, (size * 3) / 4);
    ctx.lineTo(size / 2 - size / 20, (size * 3) / 4);
    ctx.lineTo(size / 2 - size / 20, size / 2);
    ctx.lineTo(size / 2 - size / 10, size / 2);
  } else {
    ctx.moveTo(size / 2, (size * 3) / 4);
    ctx.lineTo(size / 2 + size / 10, size / 2);
    ctx.lineTo(size / 2 + size / 20, size / 2);
    ctx.lineTo(size / 2 + size / 20, size / 4);
    ctx.lineTo(size / 2 - size / 20, size / 4);
    ctx.lineTo(size / 2 - size / 20, size / 2);
    ctx.lineTo(size / 2 - size / 10, size / 2);
  }
  ctx.closePath();
  ctx.fill();

  return canvas;
}
