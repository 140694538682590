import React, { useState } from "react";
import styled from "styled-components";
import { Tooltip } from "reactstrap";

import { ReactComponent as HelpIcon } from "assets/icons/general/Help.svg";

const CurrencyListBar = ({
  balance,
  elementsColors,
  handleMouseEnter,
  hadnleMouseLeave,
  index,
  hoverCurrentBg,
}) => {
  const { available, asset, percent, estimated } = balance;
  const { amount, currency } = estimated;
  const balanceText = available.amount + " " + asset;
  const totalString = ` ~ ${amount ? amount : "0"} ${currency}`;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <CurrencyListBar.BarElement
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={hadnleMouseLeave}
      className='d-flex align-items-center py-2 px-4'
      key={asset}
      hoverCurrentBg={hoverCurrentBg}
      index={index}
    >
      <CurrencyListBar.BulletElement
        style={{ backgroundColor: `${elementsColors[index]}` }}
      />
      <div className='d-flex text-nowrap w-100'>
        <div className='w-75 d-flex align-items-center'>
          <CurrencyListBar.Amount
            hoverCurrentBg={hoverCurrentBg}
            index={index}
            className='mr-1 text-truncate'
          >
            {balanceText}
          </CurrencyListBar.Amount>
          <HelpIcon
            id={"TooltipExample" + index}
            aria-label={`${totalString} ${asset}`}
          />
        </div>
        <span className='font-sm d-block w-25 text-right'>
          {Number(percent).toFixed(2)}%
        </span>
        <Tooltip
          placement='top'
          isOpen={tooltipOpen}
          target={"TooltipExample" + index}
          toggle={toggle}
        >
          <span>{asset}</span>
          <span>{totalString}</span>
        </Tooltip>
      </div>
    </CurrencyListBar.BarElement>
  );
};

CurrencyListBar.BarElement = styled.div`
  cursor: pointer;
  color: ${props =>
    props.hoverCurrentBg === props.index ? "#FFFFFF" : "#A3A3A3"} !important;
  background: ${props =>
    props.hoverCurrentBg === props.index ? "#2E3148" : "none"};

  &:hover {
    opacity: 1;
    background: #2e3148;
    p {
      color: #ffffff !important;
    }
  }
`;

CurrencyListBar.Amount = styled.p`
  margin: 0 0 0 8px;
  font-size: 0.875rem;
  color: ${props =>
    props.hoverCurrentBg === props.index ? "#FFFFFF" : "#A3A3A3"} !important;
  &:hover {
    color: #ffffff !important;
  }
`;

CurrencyListBar.BulletElement = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;

export default CurrencyListBar;
