import React from "react";
import styled from "styled-components";
import { CircularProgressbar } from "react-circular-progressbar";

import EmptyDashboardWidgets from "../EmptyDashboadWidgets";
import { ReactComponent as EmptyAssetAllocation } from "assets/icons/general/EmptyAssetAllocation.svg";

const AllocationEmpty = ({ filteredAssets, assets, hideEmpty, content }) => {
  if ((filteredAssets.length > 0 || assets.length > 0) && hideEmpty) {
    return (
      <EmptyDashboardWidgets
        Img={EmptyAssetAllocation}
        title={content.title}
        descriptionBefore={content.descriptionBefore}
      />
    );
  }
  if ((filteredAssets.length > 0 || assets.length > 0) && !hideEmpty) {
    return (
      <AllocationEmpty.EmptyBar value={0} strokeWidth={15} heigth='70px' />
    );
  }
};

AllocationEmpty.EmptyBar = styled(CircularProgressbar)`
  stroke: #ffffff;
  opacity: 0.1;
  stroke-linecap: butt;
  height: 190px;
  margin-bottom: 24px;
`;

export default AllocationEmpty;
