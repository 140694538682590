export const TABLE_HEAD = [
  { name: "invested", label: "Invested", isSortable: true },
  { name: "purchased", label: "Purchased", isSortable: true },
  { name: "returns", label: "Returns", isSortable: true },
  { name: "lifetime", label: "Durartion", isSortable: true },
];

export const EMPTY_SEARCH_RESULT_TEXT = {
  title: "No results for your search",
  desc: "We have not found anything by your request, please adjust your filters.",
};

export const EMPTY_STATE_TEXT = {
  title: "No deals in this backtest yet",
  desc: "You’ll see them displayed here as soon as this backtest will have some deals completed.",
};
