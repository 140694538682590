import { combineReducers } from "redux";

import withDataLoading from "store/withDataLoading";
import {
  EDIT_CANDLE,
  RESET_CANDLE,
  SET_CANDLE_NATURE,
  SET_CANDLE_PATTERN,
  SET_CANDLE_PERCENT,
  SET_CANDLE_SIGNAL,
} from "./constants";

const initialState = {
  loading: false,
  data: {
    signal: "sell",
    nature: "bearish",
    pattern: null,
  },
  error: null,
};

const item = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case EDIT_CANDLE:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };
    case SET_CANDLE_NATURE:
      return {
        ...state,
        data: {
          ...state.data,
          nature: payload,
        },
      };
    case SET_CANDLE_SIGNAL:
      return {
        ...state,
        data: {
          ...state.data,
          signal: payload,
        },
      };
    case SET_CANDLE_PATTERN:
      return {
        ...state,
        data: {
          ...state.data,
          pattern: payload.code,
        },
      };
    case SET_CANDLE_PERCENT:
      return {
        ...state,
        data: {
          ...state.data,
          percent: payload,
        },
      };

    case RESET_CANDLE:
      return initialState;
    default:
      return state;
  }
};

export default combineReducers({
  candlePatterns: withDataLoading(null, "CANDLE_PATTERNS"),
  item: withDataLoading(item, "CHANNEL"),
});
