import React from "react";
import { useHistory } from "react-router-dom";

import ResultsDropdownItem from "./ResultsDropdownItem";
import ResultListHeader from "./ResultListHeader";
import { ReactComponent as InvestmentSearch } from "assets/icons/investments/investmentSearch.svg";
import { ReactComponent as Clock } from "assets/icons/general/clock.svg";
import { ReactComponent as Ellipse } from "assets/icons/general/ellipse.svg";
import { Icon } from "shared/ui";
import { BadgeWithIcon } from "shared/ui/badges";

const InvestmentsResult = ({ investmentsResult, onSetModalSearch }) => {
  const history = useHistory();

  if (!investmentsResult.length) return null;

  return (
    <div className='d-flex flex-column mb-4'>
      <ResultListHeader className='d-flex p-2 mb-2'>
        <InvestmentSearch />
        <p className='font-sm  ml-2 my-auto'>Investments</p>
      </ResultListHeader>
      {investmentsResult.map(item => (
        <ResultsDropdownItem
          className='h-auto py-1 mb-3 d-flex flex-column flex-md-row justify-content-between align-items-md-center pr-2 pl-2 mt-1 mb-md-2'
          key={item.id}
          onClick={() => {
            onSetModalSearch(false);
            history.push(`/investments/${item.id}`);
          }}
        >
          <div className='d-flex'>
            <BadgeWithIcon
              text={item.metadata.exchange}
              className='font-sm'
              iconCode={item.metadata.exchange}
              iconSize='smallSize'
              isDark={false}
            />
            <div className='d-flex align-items-center text-nowrap ml-3'>
              <Icon
                code={item.metadata.asset}
                size='thumb'
                type='cryptocurrency'
              />
              <p
                className='mb-0 ml-2 font-weight-500'
                style={{ color: "#333333" }}
              >
                {item.metadata.asset}
              </p>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-center mt-md-0'>
            <p className='mb-0 font-weight-500 font-md text-dark mr-2'>
              {item.info?.split(" ")[0]}{" "}
              <span className='text-dark-35 font-md font-weight-300'>
                {item.info?.split(" ")[1]}
              </span>
            </p>
            {item.metadata.canTrade ? <Ellipse /> : <Clock />}
          </div>
        </ResultsDropdownItem>
      ))}
    </div>
  );
};

export default InvestmentsResult;
