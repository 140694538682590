import React from "react";
import styled from "styled-components";
import { Card } from "reactstrap";

import { formatterToNull } from "helpers/valuesFormatters";

const TransactionWidget = ({ total, daily, transactionType }) => {
  return (
    <>
      <TransactionWidget.Wrapper>
        <div className='d-flex justify-content-start'>
          <div className='d-flex flex-column align-items-start'>
            <TransactionWidget.Value>
              Total {transactionType}
            </TransactionWidget.Value>
            <TransactionWidget.Total total={total}>
              {formatterToNull(total)}
            </TransactionWidget.Total>
          </div>
        </div>
      </TransactionWidget.Wrapper>
      <TransactionWidget.Wrapper>
        <div>
          <div className='d-flex align-items-start flex-column'>
            <TransactionWidget.Value>
              24h {transactionType}
            </TransactionWidget.Value>
            <TransactionWidget.Total total={daily}>
              {formatterToNull(daily)}
            </TransactionWidget.Total>
          </div>
        </div>
      </TransactionWidget.Wrapper>
    </>
  );
};

TransactionWidget.Wrapper = styled(Card)`
  border-radius: 8px !important;
  margin-right: 8px;
  padding: 0.75rem 1rem;
  svg {
    path {
      stroke: var(--info);
    }
  }
`;

TransactionWidget.Value = styled.p`
  color: var(--white-75, rgba(255, 255, 255, 0.75)) !important;
  margin-bottom: 4px;
  font-weight: 300;
  font-size: 0.875rem;
`;

TransactionWidget.Total = styled.p`
  font-weight: 700;
  font-size: 19px;
  margin-bottom: 0;
  color: ${props =>
    props.total > 0 ? "#FFFFFF" : "rgba(255, 255, 255, 0.35)"} !important;
`;

export default TransactionWidget;
