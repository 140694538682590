import styled from "styled-components";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const StyledPreloader = styled(SkeletonPreloader)`
  background-image: linear-gradient(
    90deg,
    #535462 0%,
    rgba(83, 84, 98, 0) 100%
  ) !important;
  background-color: rgba(83, 84, 98, 10%) !important;
`;

export default StyledPreloader;
