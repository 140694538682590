import {
  DELETE_NOTIFICATION,
  REMOVE_DELETED_NOTIFICATION,
  LOAD_MORE_NOTIFICATIONS,
  DELETE_ALL_NOTIFICATION,
  DELETE_NOTIFICATION_LOADING,
} from "./constants";

export default function notifications(
  state = {
    data: [],
    loading: false,
    deleteLoading: false,
    error: false,
  },
  action,
) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_MORE_NOTIFICATIONS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          items: [...state.data.items, ...payload],
        },
      };

    case DELETE_NOTIFICATION_LOADING:
      return {
        ...state,
        deleteLoading: true,
      };

    case DELETE_NOTIFICATION: {
      const filteredNotifications = state.data.items.filter(
        notification => notification.id !== payload,
      );
      const newState = {
        ...state,
        deleteLoading: false,
        data: {
          items: [...filteredNotifications],
        },
      };
      return newState;
    }

    case DELETE_ALL_NOTIFICATION:
      return {
        ...state,
        data: {},
        loading: false,
      };

    case REMOVE_DELETED_NOTIFICATION:
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.filter(notification => payload !== notification.id),
        },
      };

    default:
      return state;
  }
}
