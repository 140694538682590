import React from "react";
import { CardBody } from "reactstrap";

import {
  StyledStatisticsCardHeader,
  StyledStatisticsChartCard,
} from "../index.styles";
import { SkeletonPreloader } from "shared/ui";

const StatisticsBalancePreloader = () => {
  return (
    <StyledStatisticsChartCard>
      <CardBody className='p-4'>
        <div className='d-flex align-items-center mb-4'>
          <SkeletonPreloader circle={true} height={19} width={19} />
          <SkeletonPreloader height={14} width={140} className='ml-2' />
        </div>
        <StyledStatisticsCardHeader className='h-100' />
      </CardBody>
    </StyledStatisticsChartCard>
  );
};

export default StatisticsBalancePreloader;
