import React from "react";

import { Button } from "shared/ui/buttons";

const BotsListHeader = ({ handleToCreateBot, isNotFiltered }) => {
  return (
    <div className='list-page-header d-flex align-items-center mt-4 justify-content-between'>
      <h1 className='mb-0 text-white font-weight-500'>Bots</h1>
      {isNotFiltered && (
        <Button color='blue' className='m-0' onClick={handleToCreateBot}>
          Create new bot
        </Button>
      )}
    </div>
  );
};

export default BotsListHeader;
