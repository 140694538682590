import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import { Card } from "reactstrap";

import { STATISTICS_TRADES_TABLE_HEAD } from "models/deal/enum/tables";
import { getTrades } from "store/deals/actions";
import { selectDealsTrades, selectDealExchange } from "store/deals/selectors";
import { fetchTradeHistory } from "store/credentials/actions";
import { ReactComponent as Empty } from "assets/icons/general/empty-state-planet2.svg";
import TradesTableItem from "./TradesTableItem";
import TableHeader from "./TableHeader";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import {
  CustomScrollbars,
  EmptyStateCustom,
  PaginationPanel,
  SortTableHead,
  TablePreloader,
} from "shared/ui";
import { StyledTable } from "shared/styled/StyledTable";

const TradesHistory = ({ id, credId, market, loadingPage }) => {
  const [dealOnly, setDealOnly] = useState(true);
  const [tradesData, setTradesData] = useState({});
  const [tradesLoading, setTradesLoading] = useState(true);
  const wrapperRef = useRef();

  const dispatch = useDispatch();

  const { data, loading } = useSelector(selectDealsTrades);
  const exchange = useSelector(selectDealExchange);

  const trades = useSelector(state => state.credentials.tradeHistory.data);
  const loadingTrades = useSelector(
    state => state.credentials.tradeHistory.loading,
  );

  const requestFunc = dealOnly
    ? { func: getTrades, param: id }
    : { func: fetchTradeHistory, param: credId };

  const { handleFilter, handleSorting, handlePaginate } = useFiltersWithRequest(
    tradesData?.request,
    requestFunc.func,
    requestFunc.param,
  );

  useLayoutEffect(() => {
    const filters = qs.stringify({
      sort: { by: "createdAt", order: "DESC" },
    });
    dispatch(getTrades(id, `?${filters}`));
  }, [dispatch, id]);

  useLayoutEffect(() => {
    if (dealOnly) {
      setTradesData(data);
      return setTradesLoading(loading);
    }
    setTradesData(trades);
    setTradesLoading(loadingTrades);
  }, [data, loading, dealOnly, trades, loadingTrades]);

  useEffect(() => {
    if (loadingPage) setDealOnly(true);
  }, [loadingPage]);

  const handleDealOnly = check => {
    if (check) {
      const filters = qs.stringify({
        filter: { ...tradesData?.request?.filter, market: {} },
        sort: { by: "createdAt", order: "DESC" },
      });
      return dispatch(getTrades(id, `?${filters}`));
    }

    const filterAllOrders = qs.stringify({
      filter: { ...tradesData?.request?.filter, market: { eq: market?.pair } },
    });
    dispatch(fetchTradeHistory(credId, `?${filterAllOrders}`));
  };

  const handleRefresh = () => {
    const filters = qs.stringify({
      filter: tradesData?.request?.filter,
      sort: tradesData?.request?.sort,
    });
    if (dealOnly) return dispatch(getTrades(id, `?${filters}`));
    dispatch(fetchTradeHistory(credId, `?${filters}`));
  };

  const renderEmptyState = (title, desc) => {
    return (
      <EmptyStateCustom
        height={364}
        Img={Empty}
        title={title}
        description={desc}
        className='px-4'
        backgroundColor='var(--dark-slate)'
      />
    );
  };

  if (
    !tradesData?.items?.length &&
    !tradesLoading &&
    !Object.keys(tradesData?.request?.filter || {})?.length
  ) {
    return renderEmptyState("You have no trades at the moment", "");
  }

  const getStatisticsTableHeadItems = () => {
    if (exchange !== "poloniex") {
      return STATISTICS_TRADES_TABLE_HEAD.filter(
        item => item.name !== "matchRole" && item.name !== "tradeType",
      );
    }

    return STATISTICS_TRADES_TABLE_HEAD;
  };

  return (
    <div ref={wrapperRef}>
      <Card className='mb-4'>
        <TableHeader
          loadingPage={loadingPage}
          itemsLoading={tradesLoading}
          total={tradesData?.total}
          filter={tradesData?.request?.filter}
          handleRefresh={handleRefresh}
          handleFilter={handleFilter}
          dealOnly={dealOnly}
          setDealOnly={setDealOnly}
          handleDealOnly={handleDealOnly}
          title='Trades'
        />
        {(tradesLoading || loadingPage) && (
          <div className='overflow-hidden'>
            <TablePreloader
              rowTimes={5}
              tdHeight={14}
              tdWidth={[32, 85, 39, 53, 77, 77, 52, 105, 81, 73, 93]}
            />
          </div>
        )}
        {!tradesData?.items?.length &&
          !tradesLoading &&
          !loadingPage &&
          renderEmptyState(
            "No results for your search",
            "Please adjust your filters, we have not found anything by your request.",
          )}
        {tradesData?.items?.length > 0 && !tradesLoading && !loadingPage && (
          <CustomScrollbars
            autoHideTimeout={500}
            autoHideDuration={500}
            autoHeight
            autoHeightMax={Number.MAX_VALUE}
          >
            <StyledTable className='mb-0'>
              <>
                <SortTableHead
                  handleSorting={handleSorting}
                  sort={tradesData?.request?.sort}
                  theadItems={getStatisticsTableHeadItems()}
                />

                <tbody className='font-sm'>
                  {tradesData?.items?.map(trade => (
                    <TradesTableItem
                      trade={trade}
                      key={trade.id}
                      exchange={exchange}
                    />
                  ))}
                </tbody>
              </>
            </StyledTable>
          </CustomScrollbars>
        )}
        {!loadingPage && (
          <div className={tradesData?.total < 10 ? "" : "pt-3"}>
            <PaginationPanel
              activePage={tradesData?.page}
              totalItemsCount={tradesData?.total}
              onChange={page => handlePaginate(page)}
              elementRef={wrapperRef}
            />
          </div>
        )}
      </Card>
    </div>
  );
};

export default TradesHistory;
