import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "reactstrap";
import qs from "qs";
import PropTypes from "prop-types";

import Error from "shared/ui/state/Error";
import PaginationPanel from "shared/ui/PaginationPanel";
import SignalsTableHeader from "../reusable/SignalsTableHeader";
import SignalsBody from "../reusable/SignalsBody";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import CreateSignalModal from "views/channels/modals/CreateSignalModal";
import { ACTIVE_SIGNALS_HEAD } from "models/signal/tables";
import { getSignalsActive } from "store/signals/action";
import {
  getErrorActive,
  getLoadingActive,
  signalsActive,
} from "store/signals/selectors";
import { stringOrNull } from "views/channels/channelPropTypes";
import useRequestCancellation from "hooks/useRequestCancellation";

const ActiveSignalsTab = ({
  loadingPage,
  id,
  owner,
  subscribed,
  logo,
  name,
  totalSubscribers,
}) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState();

  const { items, page, total, request } = useSelector(signalsActive);
  const loading = useSelector(getLoadingActive);
  const error = useSelector(getErrorActive);
  const { handleSorting, handlePaginate } = useFiltersWithRequest(
    request,
    getSignalsActive,
  );
  const { useAbortableEffect } = useRequestCancellation();

  const handleToggleModal = () => setIsModalOpen(prev => !prev);

  useAbortableEffect(
    signal => {
      if (id) {
        const queryString = qs.stringify({
          filter: {
            status: {
              eq: "open",
            },
            channel: {
              eq: id,
            },
          },
          limit: 4,
        });
        dispatch(getSignalsActive(`?${queryString}`, signal));
      }
    },
    [dispatch, id, subscribed],
  );

  if (error) return <Error error={error} />;
  return (
    <>
      <Card className='pt-3'>
        <div ref={wrapperRef}>
          <SignalsTableHeader
            loadingPage={loadingPage}
            loading={loading}
            withButton={owner}
            handleCreateItem={handleToggleModal}
            title='Active Signals'
          />
          <SignalsBody
            loadingPage={loadingPage}
            loading={loading}
            items={items}
            owner={owner}
            sort={request?.sort}
            handleSorting={handleSorting}
            theadItems={ACTIVE_SIGNALS_HEAD}
            subscribed={subscribed}
          />
          {!loading && (
            <div className={`${total < 5 ? "mt-0" : "mt-3"}`}>
              <PaginationPanel
                totalPagesView={4}
                activePage={page}
                totalItemsCount={total}
                onChange={handlePaginate}
                elementRef={wrapperRef}
              />
            </div>
          )}
        </div>
      </Card>
      <CreateSignalModal
        handleToggleModal={handleToggleModal}
        isModalOpen={isModalOpen}
        channelId={id}
        logo={logo}
        name={name}
        totalSubscribers={totalSubscribers}
        isActiveTable
      />
    </>
  );
};

ActiveSignalsTab.propType = {
  loadingPage: PropTypes.bool,
  id: PropTypes.string,
  owner: PropTypes.bool,
  logo: stringOrNull,
  name: PropTypes.string,
  totalSubscribers: PropTypes.number,
};

export default ActiveSignalsTab;
