import React from "react";
import { ErrorBoundary } from "@sentry/react";

import FallBack from "shared/ui/FallBack";
import { CREDENTIALS_TABS } from "../models";
import { RenderTabs } from "shared/ui";
import PortfolioPreloaders from "./views/portfolio/components/PortfolioPreloaders";
import {
  BalancesSection,
  PortfolioSection,
  RebalanceHistory,
  StatisticsSection,
} from "./views";

const PagesViewCredentials = ({
  data,
  loadingPage,
  id,
  location,
  pageView,
  setPagView,
  isRebalanceLoading,
  balanceHidden,
}) => {
  return (
    <>
      <RenderTabs
        arrTabs={CREDENTIALS_TABS}
        setPagView={setPagView}
        currentTab={pageView}
        loading={loadingPage}
      >
        <PortfolioPreloaders />
      </RenderTabs>
      {pageView === "Main portfolio" && (
        <ErrorBoundary fallback={<FallBack />}>
          <PortfolioSection
            id={id}
            location={location}
            rebalancingDifference={data.rebalancingDifference}
            rebalance={data.rebalance}
            loadingPage={loadingPage}
            balanceHidden={balanceHidden}
          />
        </ErrorBoundary>
      )}
      {pageView === "Balances" && (
        <ErrorBoundary fallback={<FallBack />}>
          <BalancesSection data={data} loadingPage={loadingPage} id={id} />
        </ErrorBoundary>
      )}
      {pageView === "Statistics" && (
        <ErrorBoundary fallback={<FallBack />}>
          <StatisticsSection data={data} loadingPage={loadingPage} id={id} />
        </ErrorBoundary>
      )}
      {pageView === "Rebalance History" && (
        <ErrorBoundary fallback={<FallBack />}>
          <RebalanceHistory
            id={id}
            loadingPage={loadingPage}
            isRebalanceLoading={isRebalanceLoading}
          />
        </ErrorBoundary>
      )}
    </>
  );
};

export default PagesViewCredentials;
