import { cryptoSymbol } from "crypto-symbol";
const { nameLookup } = cryptoSymbol({ "Tether USDT": "USDT" });

const customCoinsNames = {
  PAX: "Paxos Standard",
  REPV2: "AUGUR",
  FCT2: "FirmaChain",
  BCHSV: "BCHSV",
  SSG: "Surviving Soldiers",
  TRXBULL: "3X Long TRX Token",
  BCHC: "BitCherry",
  LUMI: "Lumi Credits",
  FREN: "Fren",
  XRPBEAR: "3x Short XRP Token",
  DICE: "Klaydice",
  LIVE: "TRONbetLive",
  PRINTS: "FingerprintsDAO",
  WLUNA: "WLUNA",
  ETHS: "ETHPoS (IOU)",
  ADABEAR: "3X Short Cardano Token",
  XLMBEAR: "3X Short Stellar",
  EOSBEAR: "3x Short EOS Token",
  XLMBULL: "3X Long Stellar Token",
  ERSDL: "unFederalReserve",
  IBVOL: "Inverse Bitcoin Volatility Token",
  MQST: "MonsterQuest",
  ZLOT: "zLOT Finance",
  ONEINCH: "1inch Network",
  TRXBEAR: "3X Short TRX Token",
  BSVBEAR: "3x Short Bitcoin SV Token",
  COOL: "COOL",
  XFLR: "XFLR",
  DC: "Dogechain",
  LINKBEAR: "3X Short Chainlink Token",
  ETHW: "ETHPoW (IOU)",
  BSVBULL: "3x Long Bitcoin SV Token",
  ACH1: "ACH1",
  PERX: "PeerEx",
};

const LookupCoins = ({ coin }) => {
  const name = nameLookup(coin, { exact: true });

  if (name) {
    return name;
  }

  if (!name) {
    for (const key in customCoinsNames) {
      if (key === coin) {
        return customCoinsNames[key];
      }
    }
  }
};

export default LookupCoins;
