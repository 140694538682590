export const STATISTICS_TABLE_HEAD = [
  { name: "", label: "Market", isSortable: false },
  { name: "totalIn", label: "Total in", isSortable: true },
  { name: "totalOut", label: "Total out", isSortable: true },
  { name: "totalOrders", label: "Total orders", isSortable: true },
  { name: "totalTrades", label: "Total trades", isSortable: true },
  { name: "totalFeeBuy", label: "Fee buy", isSortable: true },
  { name: "totalFeeSell", label: "Fee sell", isSortable: true },
  { name: "sumTotalSell", label: "Sum total sell", isSortable: true },
  { name: "sumTotalBuy", label: "Sum total buy", isSortable: true },
  { name: "sumAmountSell", label: "Amount sell", isSortable: true },
  { name: "sumAmountBuy", label: "Amount buy", isSortable: true },
];

export const TRADE_HISTORY_TABLE_HEAD = [
  { name: "type", label: "Side", isSortable: true },
  { name: "orderNumber", label: "Order", isSortable: true },
  { name: "tradeId", label: "Trade", isSortable: true },
  { name: "amount", label: "Amount", isSortable: true },
  { name: "rate", label: "Price", isSortable: true },
  { name: "quantity", label: "Total", isSortable: true },
  { name: "feeAmount", label: "Fee Amount", isSortable: true },
  { name: "feePercent", label: "Fee", isSortable: false },
  { name: "date", label: "Time", isSortable: true },
];

export const ORDERS_TABLE_HEAD = [
  { name: "type", label: "Side", isSortable: true },
  { name: "orderNumber", label: "Order", isSortable: true },
  { name: "amount", label: "Amount", isSortable: true },
  { name: "rate", label: "Price", isSortable: true },
  { name: "total", label: "Total", isSortable: false },
  { name: "fee", label: "Fee Amount", isSortable: true },
  { name: "createdAt", label: "Time", isSortable: true },
];

export const EXCHANGE_BALANCES_TABLE_HEAD = [
  { name: "asset", label: "Coin", isSortable: true },
  { name: "available", label: "Available", isSortable: true },
  { name: "hold", label: "Hold", isSortable: true },
  { name: "btcValue", label: "Estimated", isSortable: true },
];
