import { combineReducers } from "redux";

import { RESET_SELECTED_MARKETS, SET_SELECTED_MARKETS } from "./constants";
import withDataLoading from "store/withDataLoading";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const marketsSelect = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RESET_SELECTED_MARKETS:
      return initialState;
    case SET_SELECTED_MARKETS:
      return {
        ...state,
        data: [...state.data, ...payload],
      };

    default:
      return state;
  }
};

export default combineReducers({
  marketsSelect: withDataLoading(marketsSelect, "CHANNELS"),
  markets: withDataLoading(null, "MARKETS"),
});
