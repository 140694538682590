import { CardHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import qs from "qs";

import MyCredentialsTablePreloader from "./MyCredentialsTablePreloader";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import {
  fetchMyCredentials,
  getBalance,
  resetCredential,
  resetCredentialBots,
  resetMyCredentials,
  setCurrentCredential,
} from "store/credentials/actions";
import { EmptyData } from "../../../states/EmptyData";
import {
  CreateCredentialsModal,
  CredentialItem,
  PaginationPanel,
} from "shared/ui";
import { selectMyCredentials } from "store/credentials/selectors";
import {
  MyCredentialsStyledCard,
  MyCredentialsStyledItems,
} from "./MyCredentialsTable.styles";
import { ReactComponent as MyCredentialsDashboard } from "assets/icons/credential/my-credentials.svg";
import useRequestCancellation from "hooks/useRequestCancellation";

const MyCredentialsTable = ({
  exchangeMarketLoading,
  tableRef,
  quote,
  base,
  currentExchange,
  id,
  setIsbotsDontLoad,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const { data, loading } = useSelector(selectMyCredentials);

  const { useAbortableEffect } = useRequestCancellation();
  const { handlePaginate } = useFiltersWithRequest(
    data?.request,
    fetchMyCredentials,
  );

  useAbortableEffect(
    signal => {
      const filter = qs.stringify({
        filter: {
          exchange: {
            eq: currentExchange,
          },
        },
        limit: 2,
        sort: { order: "DESC", by: "btcBalance" },
      });
      dispatch(fetchMyCredentials(`?${filter}`, signal));
    },
    [currentExchange, dispatch],
  );

  const toggleCurrentCredetial = credential => {
    if (id === credential?.id) {
      dispatch(resetMyCredentials());
      dispatch(resetCredentialBots());
      return dispatch(resetCredential());
    }
    setIsbotsDontLoad(true);
    return dispatch(setCurrentCredential(credential));
  };

  useAbortableEffect(
    signal => {
      if (id) {
        const filters = qs.stringify({
          filter: {
            asset: {
              in: [quote, base],
            },
          },
        });
        dispatch(getBalance(id, `?${filters}`, signal));
      }
    },
    [id, dispatch, quote, base],
  );

  if (exchangeMarketLoading || loading) return <MyCredentialsTablePreloader />;

  return (
    <MyCredentialsStyledCard>
      <CardHeader className='d-flex align-items-center pt-4 px-4'>
        <MyCredentialsDashboard />
        <p className='mb-0 ml-2 text-white font-weight-500 text-nowrap'>
          My credentials
        </p>
      </CardHeader>
      {data?.items?.length ? (
        <MyCredentialsStyledItems className='p-4'>
          {data?.items?.map(item => (
            <CredentialItem
              key={item.id}
              isActive={item.id === id}
              data={item}
              index={item.id}
              handleClick={() => toggleCurrentCredetial(item)}
            />
          ))}
        </MyCredentialsStyledItems>
      ) : (
        <EmptyData
          title='You have no credentials at the moment'
          description='Please create some to see more detailed info by spot market'
          buttonText='Connect my account'
          onHandleClick={() => setIsModalOpen(prevState => !prevState)}
        />
      )}
      <div className={data?.total > 10 ? "mt-3" : "mt-0"}>
        <PaginationPanel
          activePage={data?.page}
          totalItemsCount={data?.total}
          totalPagesView={2}
          onChange={page => handlePaginate(page)}
          className='m-0'
          elementRef={tableRef}
          isCircle
        />
      </div>
      <CreateCredentialsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </MyCredentialsStyledCard>
  );
};

export default MyCredentialsTable;
