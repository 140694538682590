import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import classnames from "classnames";

import { ReactComponent as BotsDark } from "assets/icons/bot/BotsDark.svg";
import { ReactComponent as Ellipse } from "assets/icons/general/ellipse.svg";
import ResultsDropdownItem from "./ResultsDropdownItem";
import ResultListHeader from "./ResultListHeader";
import { Icon } from "shared/ui";
import { BadgeWithIcon } from "shared/ui/badges";

const BotsResults = ({ botsResult, onSetModalSearch }) => {
  const history = useHistory();
  if (!botsResult.length) return null;

  return (
    <div className='d-flex flex-column mb-4'>
      <ResultListHeader className='d-flex p-2 mb-2'>
        <BotsDark />
        <p className='font-sm ml-2 my-auto'>Bots</p>
      </ResultListHeader>
      {botsResult.map(item => (
        <ResultsDropdownItem
          className='d-flex flex-column flex-md-row justify-content-between align-items-md-center pr-2 pl-2 mt-1 mb-md-1 mb-3'
          key={item.id}
          onClick={() => {
            onSetModalSearch(false);
            history.push(`/bots/${item.id}`);
          }}
        >
          <div className='d-flex mb-1 mb-md-0'>
            <BadgeWithIcon
              text={item.metadata.exchange}
              className='font-sm'
              iconCode={item.metadata.exchange}
              iconSize='smallSize'
              isDark={false}
            />
            <div className='d-flex ml-3'>
              <Icon
                size='thumb'
                code={`${item?.metadata?.type}-bot`}
                type='icons'
                className='mr-2'
              />
              <p className='text-dark my-auto font-sm font-weight-500 text-truncate text-title'>
                {item.title}
              </p>
              <BotsResults.BotEllipse
                status={item.metadata.status}
                className='my-auto ml-1'
              />
            </div>
          </div>
          <div className='d-flex font-xs justify-content-between'>
            <p className='text-dark-35 m-0 mr-3'>
              {`Created at ${item.metadata.createdAt}`}
            </p>
            <p
              className={classnames("m-0 text-capitalize font-weight-500", {
                "text-success": item.metadata.status === "running",
                "text-dark-75": item.metadata.status === "stopped",
              })}
            >
              {item.metadata.status}
            </p>
          </div>
        </ResultsDropdownItem>
      ))}
    </div>
  );
};

BotsResults.BotEllipse = styled(Ellipse)`
  width: 6px;
  height: 6px;

  & circle {
    fill: ${props =>
      props.status === "running" ? "#5FD3BC" : "rgba(29, 30, 42, 0.35)"};
  }
`;

export default BotsResults;
