import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, Tooltip } from "reactstrap";
import classnames from "classnames";

import { ReactComponent as Rocket } from "assets/icons/general/rocket-outline.svg";
import { ReactComponent as Help } from "assets/icons/general/Help.svg";
import { fetchConditions, resetConditions } from "store/deals/actions";
import { formatterToNull } from "helpers/valuesFormatters";
import IndicatorsPreloader from "../../ui/preloaders/IndicatorsPreloader";
import { NAMES } from "models/deal/enum/conditions/name";
import { CONDITIONS_TABLE } from "models/deal/enum/tables";
import { StyledTable } from "shared/styled/StyledTable";
import CustomScrollbars from "shared/ui/CustomScrollbars";
import SortTableHead from "shared/ui/SortTableHead";

const Conditions = ({ id }) => {
  const [tooltip, setTooltip] = useState(false);
  const toggle = () => setTooltip(!tooltip);

  const dispatch = useDispatch();

  const data = useSelector(state => state.deals.conditions.data);
  const loading = useSelector(state => state.deals.conditions.loading);

  useLayoutEffect(() => {
    dispatch(fetchConditions(id));
    return () => dispatch(resetConditions());
  }, [dispatch, id]);

  if (loading) return <IndicatorsPreloader />;

  if (!data?.items?.length) return;

  return (
    <Card className='mb-4 p-0 overflow-hidden'>
      <CardHeader className='d-flex align-items-center px-4 pt-4 pb-3'>
        <Rocket />
        <p className='mb-0 ml-2 mr-1 text-white font-weight-500'>
          Starting conditions
        </p>
        <Help id='conditions' />

        <Tooltip
          placement='top'
          isOpen={tooltip}
          target='conditions'
          toggle={toggle}
        >
          Deal was created with following market conditions
        </Tooltip>
      </CardHeader>
      <CustomScrollbars
        autoHideTimeout={500}
        autoHideDuration={500}
        autoHeight
        autoHeightMax={Number.MAX_VALUE}
      >
        <StyledTable className='mb-0' paddingsThead='1rem'>
          <SortTableHead theadItems={CONDITIONS_TABLE} />
          <tbody className='font-sm'>
            {data.items?.map(condition => (
              <tr key={condition.id}>
                <td className='w-50'>
                  <p className='text-white mb-0 font-weight-500'>
                    {NAMES[condition.code]}
                  </p>
                </td>

                <td>
                  <StyledConditionValue
                    className={classnames(
                      "mb-0 font-weight-500 text-nowrap text-capitalize",
                      {
                        "text-success": condition.metadata.required === "bull",
                        "text-danger": condition.metadata.required === "bear",
                        "text-white":
                          condition.metadata.required !== "bull" &&
                          condition.metadata.required !== "bear",
                      },
                    )}
                  >
                    {formatterToNull(condition.metadata.required)}
                  </StyledConditionValue>
                </td>
                <td>
                  <StyledConditionValue
                    className={classnames(
                      "mb-0 font-weight-500 text-nowrap text-capitalize ",
                      {
                        "text-success": condition.actual === "bull",
                        "text-danger": condition.actual === "bear",
                        "text-white":
                          condition.actual !== "bull" &&
                          condition.actual !== "bear",
                      },
                    )}
                  >
                    {condition.actual || "-"}
                  </StyledConditionValue>
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </CustomScrollbars>
    </Card>
  );
};

const StyledConditionValue = styled.p`
  max-width: 200px;
`;

export default Conditions;
