import { createSelector } from "reselect";

export const rebalanceHistorySelector = state =>
  state.credentials.rebalanceHistory;

export const hideEmptyAssetsSelector = state =>
  state.credentials.investmentsFilters.hideEmptyAssets;

export const selectBalance = state => state.credentials.balance;

export const selectBalances = state => state.credentials.balances;

export const selectMyCredentialsBots = state =>
  state.credentials.myCredentialsBots;

export const selectCredential = state => state.credentials.item;

export const selectMyCredentials = state => state.credentials.myCredentials;

export const selectCredentialStatistics = state => state.credentials.statistics;

export const selectCredentialTradeHistor = state =>
  state.credentials.tradeHistory;
export const selectCredentialOpenOrders = state => state.credentials.openOrders;

const balances = state => state.credentials.verified.balances;
const portfolio = state => state.credentials.verified.portfolio;
const credentialsInvestments = state => state.credentials.investments.data;
export const selectCredentials = state => state.credentials.list;
export const selectStatisticsBalance = state =>
  state.credentials.statisticsBalance;

export const credentialsInvestmentsSelector = coin =>
  createSelector([credentialsInvestments], (credentialsInvestments = []) => {
    return credentialsInvestments.filter(c => c.asset !== coin);
  });

export const profitSelector = coin =>
  createSelector([balances], (balances = []) => {
    return balances
      .filter(b => b.soldFor === coin)
      .map(b => b.profit)
      .reduce((a, b) => a + b, 0)
      .toFixed(5);
  });

export const estimatedProfitSelector = coin =>
  createSelector([balances], (balances = []) => {
    return balances
      .filter(b => b.soldFor === coin)
      .map(b => b.estimatedProfit)
      .reduce((a, b) => a + b, 0)
      .toFixed(5);
  });

export const soldAtlCoinsSelector = coin =>
  createSelector([balances], (balances = []) => {
    return balances.filter(b => b.soldFor === coin);
  });

export const altCoinsCountSelector = coin =>
  createSelector(
    [balances],
    (balances = []) => balances.filter(b => b.soldFor === coin).length,
  );

export const altCoinsListSelector = () =>
  createSelector([balances], (balances = []) => {
    return balances
      .filter(b => b.soldFor)
      .reduce(
        (prev, curr) => ({
          ...prev,
          [curr.soldFor]: balances.filter(b => b.soldFor === curr.soldFor),
        }),
        {},
      );
  });

export const enbledInvestmentsSelector = () =>
  createSelector([portfolio], (portfolio = []) => {
    return portfolio.filter(p => p.enabled);
  });

export const sumOfAllocationSelector = () =>
  createSelector([portfolio], (portfolio = []) => {
    return portfolio
      .map(p => p.allocation)
      .reduce((a, b) => Number(a) + Number(b), 0);
  });
