import React from "react";
import styled from "styled-components";

import { formatterToNull } from "helpers/valuesFormatters";
import RefreshTickers from "./RefreshTickers";
import { Icon, PercentBadge, SkeletonPreloader } from "shared/ui";

const Ticker = ({
  currentTicker,
  exchange,
  error,
  isLoading,
  refresh,
  onClick,
  ...props
}) => {
  if (isLoading) {
    return (
      <Ticker.Preloader
        style={{ width: "236px" }}
        className='d-flex align-items-center justify-content-start'
      >
        <SkeletonPreloader width={20} height={20} circle className='mr-2' />
        <SkeletonPreloader width={119} height={13} />
      </Ticker.Preloader>
    );
  }

  if (error || !currentTicker)
    return (
      <RefreshTickers
        onHandleRefresh={refresh}
        loading={isLoading}
        onClick={onClick}
      />
    );

  return (
    <Ticker.TickersWidget
      {...props}
      className='d-flex justify-content-center align-items-center font-sm'
      onClick={onClick}
    >
      <Icon
        type='exchanges'
        size='thumb'
        code={exchange}
        className='mr-1 my-0'
      />
      <Ticker.Title {...props} className='mb-0 font-weight-500 mx-1 mb-0'>
        {formatterToNull(currentTicker?.close)}
      </Ticker.Title>
      <Ticker.Currency {...props} className='mr-2 mb-0 font-weight-300'>
        {`${currentTicker?.market?.base} / ${currentTicker?.market?.quote}`}
      </Ticker.Currency>
      <PercentBadge value={currentTicker?.dailyChange} />
    </Ticker.TickersWidget>
  );
};

Ticker.TickersWidget = styled.div`
  border-radius: 8px;
  cursor: pointer;
  padding: 10px 16px;
  background: ${props => (props.isOpen ? "var(--white)" : "#242534")};
  :hover {
    transition: all 0.2s ease-in;
    background: ${props => (props.isOpen ? "#E6E6EA" : "var(--dark-slate)")};
  }
`;

Ticker.Title = styled.span`
  color: ${props => (props.isOpen ? "#1D1E2A" : "var(--white)")};
`;

Ticker.Currency = styled.span`
  color: ${props => (props.isOpen ? "#1D1E2A" : "var(--white)")};
  opacity: 35%;
`;

Ticker.Preloader = styled.div`
  border-radius: 6px;
  cursor: pointer;
  padding: 6px 18px;
  background: #242534;
  height: 40px;
`;

export default Ticker;
