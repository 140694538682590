import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { CardBody, Card } from "reactstrap";
import queryString from "qs";

import { fetchRequestLog } from "store/credentials/actions";
import PaginationPanel from "shared/ui/PaginationPanel";
import HelpIcon from "shared/ui/HelpIcon";
import { RequestLogFilters, RequestLogTable } from "./compounds";

const RequestLog = ({
  match,
  fetchRequestLog,
  requestLog: { items = [], total, page },
  location,
}) => {
  const [displayHelp, setDisplayHelp] = useState(false);
  const history = useHistory();
  const search = queryString.parse(location.search);

  useEffect(() => {
    fetchRequestLog(match.params.id, search.page || 1, search);
  }, [location.search, fetchRequestLog, match.params.id, search]);

  const handlePaginate = page => {
    history.push({
      pathname: `/credentials/${match.params.id}/request-log`,
      search: queryString.stringify({ ...search, page }),
    });
  };

  useEffect(() => {
    window.analytics.page("/credentials/:id/request-log");
  }, []);

  return (
    <>
      <h1>
        Request log <HelpIcon onClick={() => setDisplayHelp(!displayHelp)} />
      </h1>
      <Card>
        <CardBody>
          <RequestLogFilters location={location} id={match.params.id} />
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <RequestLogTable data={items} />
        </CardBody>
      </Card>
      <PaginationPanel
        activePage={page}
        totalItemsCount={total}
        onChange={page => handlePaginate(page)}
      />
    </>
  );
};

const mapStateToProps = state => ({
  requestLog: state.credentials.requestLog,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchRequestLog }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RequestLog);
