import React from "react";
import { CardFooter } from "reactstrap";

const FeaturesList = ({ features = [], isPremium }) => {
  return (
    <CardFooter>
      <ul className='m-0 p-0'>
        {features.map((feature, index, arr) => (
          <li
            className={`text-white font-weight-500${index + 1 === arr.length && isPremium ? " mb-0" : " mb-3"}`}
            key={feature}
          >
            <i className='tim-icons icon-check-2 text-success mr-2' />
            {feature}
          </li>
        ))}
        {!isPremium ? (
          <li className='text-white font-weight-500'>
            <i className='tim-icons icon-simple-remove text-danger mr-2' />
            Machine learning
          </li>
        ) : null}
      </ul>
    </CardFooter>
  );
};

export default FeaturesList;
