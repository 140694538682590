import styled from "styled-components";

export const StyledBadgeWithIcon = styled.div`
  background: ${props => (props.isDark ? "#363A54" : "rgba(29, 30, 42, 0.05)")};
  display: flex;
  justify-content: center;
  border-radius: 100px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  position: relative;

  p {
    line-height: 1.17;
    color: ${props => (props.isDark ? "#fff" : "#1D1E2A")};
    font-weight: 500;
    text-transform: capitalize;
  }
`;
