import React from "react";
import { Card, CardBody } from "reactstrap";
import classNames from "classnames";

import { ReactComponent as StatisticIcon } from "assets/icons/general/statisticIcon.svg";
import StatisticWidgetPreloader from "../preloaders/StatisticWidgetPreloader";
import { replaceDurations } from "helpers/replaceDurations";
import { formatterToNull } from "helpers/valuesFormatters";

const StatisticWidget = ({ data, loading }) => {
  if (loading) return <StatisticWidgetPreloader />;

  return (
    <Card className='mb-4'>
      <CardBody className='p-4'>
        <div className='d-flex align-items-center mb-3'>
          <StatisticIcon />
          <p className='mb-0 text-white ml-2 font-weight-500'>Statistics</p>
        </div>

        <div className='d-flex flex-md-column flex-lg-row align-items-lg-center justify-content-between text-nowrap'>
          <p className='mb-0 text-white-75 font-sm'>Orders count</p>
          <p className='mb-0 font-weight-500 text-white'>
            {formatterToNull(data?.ordersCount)}
          </p>
        </div>

        <div className='d-flex flex-md-column flex-lg-row align-items-lg-center justify-content-between mt-2 text-nowrap'>
          <p className='mb-0 text-white-75 font-sm'>Trades count</p>
          <p className='font-weight-500 text-white'>
            {formatterToNull(data?.tradesCount)}
          </p>
        </div>

        {data?.forceStarted && (
          <div className='d-flex flex-md-column flex-lg-row align-items-lg-center justify-content-between mt-2 text-nowrap'>
            <p className='mb-0 text-white-75 font-sm'>Force started</p>
            <p
              className={classNames("text-capitalize font-weight-500", {
                "text-success": data?.forceStarted,
                "text-danger": !data?.forceStarted,
              })}
            >
              {data?.forceStarted.toString()}
            </p>
          </div>
        )}
        {data?.forceCompleted && (
          <div className='d-flex flex-md-column flex-lg-row align-items-lg-center justify-content-between mt-2 text-nowrap'>
            <p className='mb-0 text-white-75 font-sm'>Force completed</p>
            <p
              className={classNames("text-capitalize font-weight-500", {
                "text-success": data?.forceCompleted,
                "text-danger": !data?.forceCompleted,
              })}
            >
              {data?.forceCompleted.toString()}
            </p>
          </div>
        )}
        {data?.avgBuyPrice > 0 && (
          <div className='d-flex flex-md-column flex-lg-row align-items-lg-center justify-content-between mt-2 text-nowrap'>
            <p className='mb-0 text-white-75 font-sm'>Average buy price</p>
            <p className='font-weight-500 text-white'>{data?.avgBuyPrice}</p>
          </div>
        )}
        {data?.avgSellPrice > 0 && (
          <div className='d-flex flex-md-column flex-lg-row align-items-lg-center justify-content-between mt-2 text-nowrap'>
            <p className='mb-0 text-white-75 font-sm'>Average sell price</p>
            <p className='font-weight-500 text-white'>{data?.avgSellPrice}</p>
          </div>
        )}
        {data?.duration && (
          <div className='d-flex flex-md-column flex-lg-row align-items-lg-center justify-content-between mt-2 text-nowrap'>
            <p className='mb-0 text-white-75 font-sm'>Duration</p>
            <p className='font-weight-500 text-white'>
              {replaceDurations(data?.duration)}
            </p>
          </div>
        )}
        {data?.status === "completed" || data?.status === "cancelled" ? (
          <div className='d-flex flex-md-column flex-lg-row align-items-lg-center justify-content-between mt-2'>
            <p className='mb-0 text-white-75 font-sm'>
              {data?.status === "completed" ? "Completed at" : "Canceled at"}
            </p>
            <p className='font-weight-500 text-white'>
              {data?.status === "completed"
                ? data?.completedAt
                : data?.cancelledAt}
            </p>
          </div>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default StatisticWidget;
