import { useHistory } from "react-router-dom";

export const useRedirect = () => {
  const history = useHistory();

  const handleRedirect = (event, path) => {
    if (event.button === 0) {
      history.push(path);
    } else if (event.button === 1) {
      window.open(path, "_blank");
    }
  };

  return handleRedirect;
};
