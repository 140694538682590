import React, { useLayoutEffect, useState } from "react";
import classnames from "classnames";
import { UncontrolledAlert } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";

import { recoverPassword, resetPassword } from "store/auth/actions";
import FormDescription from "./compounds/FormDescription";
import { handleResponse } from "helpers/errorsValidator";
import { Button } from "shared/ui/buttons";

const hideEmail = (email = "") => {
  const [username, domain] = email.split("@");
  if (username && domain) {
    const hiddenUsername =
      username.length > 2
        ? username.substring(0, 2) +
          "*".repeat(username.length - 5) +
          username.slice(-3)
        : username;
    const hiddenEmail = `${hiddenUsername}@${domain}`;
    return hiddenEmail;
  } else {
    return email;
  }
};

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("This field is required.")
    .min(8, "Password must be 8 characters or more"),
  verify: Yup.string()
    .required("This field is required.")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
  secret: Yup.string().required("This field is required."),
});

const ChangePassword = ({ recoverPassword, resetPassword }) => {
  const history = useHistory();
  const location = useLocation();
  const [submitted, setSubmitted] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [timer, setTimer] = useState(0);
  const [timerIntervalId, setTimerIntervalId] = useState(null);
  const [isLinkActive, setLinkActive] = useState(true);

  const handleResendClick = e => {
    e.preventDefault();
    resetPassword({ email: location.state?.email });
    setTimer(30);
    setLinkActive(false);

    if (timerIntervalId) {
      clearInterval(timerIntervalId);
    }

    const newTimerIntervalId = setInterval(() => {
      setTimer(prevTimer => prevTimer - 1);
    }, 1000);
    setTimerIntervalId(newTimerIntervalId);
    setTimeout(() => {
      clearInterval(newTimerIntervalId);
      setLinkActive(true);
    }, 30000);
  };

  const handlNextStep = errors => {
    setSubmitted(true);
    if (!errors.secret) setNextStep(true);
  };

  useLayoutEffect(() => {
    if (nextStep) setSubmitted(false);
  }, [nextStep]);

  return (
    <div className='content h-75 d-flex flex-column justify-content-center mt-4'>
      <Formik
        initialValues={{
          password: "",
          verify: "",
          secret: "",
        }}
        onSubmit={async (values, formik) => {
          formik.setSubmitting(true);
          const err = await recoverPassword(values);
          if (err) {
            formik.setSubmitting(false);
            formik.setErrors({ message: err.message });
            handleResponse(err, formik.setErrors);
          } else {
            history.push("/success");
          }
        }}
        validationSchema={ChangePasswordSchema}
      >
        {({ errors, isSubmitting }) => (
          <Form>
            {nextStep ? (
              <>
                <FormDescription
                  title='Set a new password'
                  text='Change your password for enhanced
                  security and account protection.'
                />
                {errors.message && (
                  <UncontrolledAlert color='danger' fade={false}>
                    {errors.message}
                  </UncontrolledAlert>
                )}
                <div className='mb-3 mt-4'>
                  <label className='text-white-75' htmlFor='password'>
                    New password
                  </label>
                  <Field
                    id='password'
                    type='password'
                    name='password'
                    className={classnames("form-control ", {
                      "has-error": submitted && errors.password,
                    })}
                    placeholder='8 characters and more'
                  />
                  <div className='w-100'>
                    {submitted && errors.password ? (
                      <p className='text-danger'>{errors.password}</p>
                    ) : null}
                  </div>
                </div>
                <div className='mb-5'>
                  <label className='text-white-75' htmlFor='verify'>
                    Repeat new password
                  </label>
                  <Field
                    id='verify'
                    type='password'
                    name='verify'
                    className={classnames("form-control", {
                      "has-error": submitted && errors.verify,
                    })}
                    placeholder='8 characters and more'
                  />
                  <div className='w-100'>
                    {submitted && errors.verify ? (
                      <p className='text-danger'>{errors.verify}</p>
                    ) : null}
                  </div>
                </div>
                <Button
                  className='w-100'
                  type='submit'
                  onClick={() => setSubmitted(true)}
                  color='blue'
                  loading={isSubmitting}
                >
                  Reset password
                </Button>
              </>
            ) : (
              <div className='content content d-flex flex-column justify-content-center'>
                <FormDescription
                  title='Check your inbox'
                  text={
                    <>
                      {"We've emailed you password reset instructions at "}
                      <span className='font-weight-500 text-white'>
                        {hideEmail(location.state?.email)} .
                      </span>{" "}
                      Please copy the secret code from email to reset your
                      password.
                    </>
                  }
                />
                <div className='my-5'>
                  <label className='text-white-75' htmlFor='secret'>
                    Code
                  </label>
                  <Field
                    id='secret'
                    type='text'
                    name='secret'
                    placeholder='Enter code from email'
                    className={classnames("form-control mb-2", {
                      "has-error": submitted && errors.secret,
                    })}
                  />
                  <div className='d-flex justify-content-end'>
                    {submitted && errors.secret ? (
                      <p className='text-danger'>{errors.secret}</p>
                    ) : null}
                    <p className='font-sm mr-1'>
                      Didn&apos;t receive the mail?
                    </p>
                    {isLinkActive ? (
                      <a
                        href='/#'
                        onClick={handleResendClick}
                        className='text-info font-sm my-0 p-0 mr-1 font-weight-500'
                      >
                        Click to resend
                      </a>
                    ) : (
                      <p className='text-white-50 font-sm my-0 p-0 mr-1 font-weight-500'>
                        Click to resend
                      </p>
                    )}
                    {timer > 0 && <p className='font-sm'> in {timer}s</p>}
                  </div>
                </div>
                <Button
                  className='w-100'
                  onClick={() => handlNextStep(errors)}
                  color='blue'
                >
                  Next
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ recoverPassword, resetPassword }, dispatch);

export default connect(null, mapDispatchToProps)(ChangePassword);
