import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Modal } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import styled from "styled-components";

import { fetchTradeHistory } from "store/credentials/actions";
import HeaderOrderModal from "./HeaderOrderModal";
import TableOrderModal from "./TableOrderModal";
import TableOrderModalLoader from "./TableOrderModalLoader";
import { Icon } from "shared/ui";
import { Button } from "shared/ui/buttons";

const OrderModal = ({
  setIsModalOpen,
  isModalOpen,
  orderId,
  credentialId,
  openOrders = [],
  currentOrderNumber,
  exchange,
}) => {
  const [sortOrder, setSortOrder] = useState("desc");
  const dispatch = useDispatch();

  const filter = qs.stringify({
    filter: { order: { eq: orderId } },
  });

  function handleSort(value) {
    if (value) {
      const filters = qs.stringify({
        filter: { order: { eq: orderId } },
        sort: { by: value, order: sortOrder === "desc" ? "asc" : "desc" },
        page: 1,
      });
      dispatch(fetchTradeHistory(credentialId, `?${filters}`));
    }
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  }

  const currentOrder = openOrders.filter(
    order => order.orderNumber === currentOrderNumber,
  );

  const trades = useSelector(state => state.credentials.tradeHistory.data);
  const tradesLoading = useSelector(
    state => state.credentials.tradeHistory.loading,
  );

  function isToggle() {
    setIsModalOpen(!isModalOpen);
  }

  useEffect(() => {
    dispatch(fetchTradeHistory(credentialId, `?${filter}`));
  }, [credentialId, filter, dispatch]);

  return (
    <OrderModal.Modal
      isOpen={isModalOpen}
      toggle={isToggle}
      modalClassName='modal-black'
    >
      <OrderModal.Card>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <Icon size='thumb' type='exchanges' code={exchange} />
            <OrderModal.Title className='font-weight-500 text-white ml-3 mr-1'>
              Order ID
            </OrderModal.Title>
            <OrderModal.Title className='font-weight-300 text-white-35'>
              {currentOrderNumber}
            </OrderModal.Title>
          </div>
          <Button color='gray' cancel={true} onClick={isToggle} />
        </div>

        <OrderModal.CardHeader>
          <HeaderOrderModal currentOrder={currentOrder[0]} />
        </OrderModal.CardHeader>

        <p className='mb-2 mt-4 text-white'>Trades</p>

        <OrderModal.CardBody>
          {tradesLoading ? (
            <TableOrderModalLoader />
          ) : (
            <TableOrderModal
              trades={trades}
              handleSort={handleSort}
              sortOrder={sortOrder}
              sort={trades?.request?.sort?.by}
              tradesLoading={tradesLoading}
            />
          )}
        </OrderModal.CardBody>
      </OrderModal.Card>
    </OrderModal.Modal>
  );
};

OrderModal.Modal = styled(Modal)`
  @media (min-width: 576px) {
    max-width: fit-content !important;
    margin: 1rem auto !important;
  }
  .modal-content {
    background: #2e3148 !important;
    border-radius: 8px;
    width: 1200px;
  }
  .pointer {
    cursor: pointer;
  }
`;

OrderModal.Card = styled(Card)`
  background: #2e3148 !important;
  margin: 0 !important;
  padding: 24px !important;
`;

OrderModal.CardHeader = styled(CardHeader)`
  background: rgba(39, 41, 60, 1) !important;
  margin: 24px 0 0 0 !important;
  padding: 16px !important;
  border-radius: 8px;
`;

OrderModal.CardBody = styled(CardBody)`
  background: rgba(39, 41, 60, 1) !important;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 8px !important;
  overflow: hidden;
`;

OrderModal.Title = styled.p`
  margin-bottom: 0;
  font-size: 18px;
`;

export default OrderModal;
