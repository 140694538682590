import React from "react";

const Copy = () => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.6667 5.25H6.41667C5.77233 5.25 5.25 5.77233 5.25 6.41667V11.6667C5.25 12.311 5.77233 12.8333 6.41667 12.8333H11.6667C12.311 12.8333 12.8333 12.311 12.8333 11.6667V6.41667C12.8333 5.77233 12.311 5.25 11.6667 5.25Z'
      stroke='#A3A3A3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.91669 8.74984H2.33335C2.02393 8.74984 1.72719 8.62692 1.5084 8.40813C1.2896 8.18934 1.16669 7.89259 1.16669 7.58317V2.33317C1.16669 2.02375 1.2896 1.72701 1.5084 1.50821C1.72719 1.28942 2.02393 1.1665 2.33335 1.1665H7.58335C7.89277 1.1665 8.18952 1.28942 8.40831 1.50821C8.6271 1.72701 8.75002 2.02375 8.75002 2.33317V2.9165'
      stroke='#A3A3A3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Copy;
