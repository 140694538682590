import React from "react";
import styled from "styled-components";

import { formatValueWithAsset, setColorClass } from "helpers/valuesFormatters";
import SortingTable from "../SortingTable";
import ToggleSwitch from "../ToggleSwitch";

const BotMarketsTable = ({ statistics = [], onToggleMarket }) => {
  return (
    <BotMarketsTable.Card>
      <SortingTable
        saveOrder={true}
        thead={[
          { text: "Asset" },
          {
            text: "Success deals",
            notSortable: true,
          },
          {
            text: "Losing deal",
            notSortable: true,
          },
          {
            text: "Daily profit",
            notSortable: true,
          },
          {
            text: "Total profit",
            notSortable: true,
          },
          {
            text: "Action",
            notSortable: true,
          },
        ]}
        tbody={statistics.map(item => ({
          data: [
            {
              value: item.asset,
              icon: {
                type: "cryptocurrency",
                size: "xsmall",
                code: item.asset,
              },
            },
            {
              value: item.successDeals,
              text: item.successDeals,
            },
            {
              value: item.losingDeals,
              text: item.losingDeals,
            },
            {
              value: `${item.dailyProfit.amount} ${item.dailyProfit.currency}`,
              color: setColorClass(item.dailyProfit.amount),
              text: formatValueWithAsset(
                item.dailyProfit.amount,
                item.dailyProfit.currency,
              ),
            },
            {
              value: `${item.totalProfit.amount} ${item.totalProfit.currency}`,
              color: setColorClass(item.totalProfit.amount),
              text: formatValueWithAsset(
                item.totalProfit.amount,
                item.totalProfit.currency,
              ),
            },
            {
              value: item.enabled,
              text: (
                <ToggleSwitch
                  onChange={() => onToggleMarket(item.asset, item.enabled)}
                  checked={item.enabled}
                  height={18}
                  width={45}
                  onColor='#26366b'
                  offColor='#1c284d'
                  onHandleColor='#3fcbb0'
                  offHandleColor='#ff8d72'
                  className='my-auto'
                />
              ),
            },
          ],
        }))}
      />
    </BotMarketsTable.Card>
  );
};

BotMarketsTable.Card = styled.div`
  background: #2e3148;
  border-radius: 4px;
`;

export default BotMarketsTable;
