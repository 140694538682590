import React from "react";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const MyAgreementsPreloader = () => (
  <>
    <SkeletonPreloader circle height={20} width={20} className='mr-1' />
    <SkeletonPreloader height={20} width={120} className='ml-2' />
    <SkeletonPreloader height={35} width={80} className='ml-auto mr-0' />
  </>
);

export default MyAgreementsPreloader;
