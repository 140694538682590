import * as Yup from "yup";

export const validationSchemaCreateSignal = Yup.object().shape({
  channel: Yup.string().required("This field is required"),
  market: Yup.object().shape({
    base: Yup.string()
      .matches(/^[a-zA-Z]+$/, "Only A-Z letters")
      .required("This field is required"),
    quote: Yup.string()
      .matches(/^[a-zA-Z]+$/, "Only A-Z letters")
      .required("This field is required"),
  }),
  exchange: Yup.string().required("This field is required"),
  buyFrom: Yup.number().required("This field is required"),
  buyTo: Yup.number().required("This field is required"),
  stopLoss: Yup.number().required("This field is required"),
  targets: Yup.array().of(
    Yup.object().shape({
      rate: Yup.number()
        .typeError("Must be a number")
        .required("This field is required"),
    }),
  ),
});
