import React, { useState } from "react";
import { Card, CardBody, Tooltip } from "reactstrap";

import { ReactComponent as Volumes } from "assets/icons/general/Balances.svg";
import VolumesWidgetPreloader from "../preloaders/VolumesWidgetPreloader";
import VolumesViewStatus from "./VolumesViewStatus";
import VolumesWidgetFooter from "./VolumesWidgetFooter";
import VolumesWidgetBody from "./VolumesWidgetBody";
import { PercentBadge } from "shared/ui";

const VolumesWidget = ({
  investedAmount,
  investedAsset,
  percent,
  purchasedAmount,
  purchasedAsset,
  avgBuyPrice,
  avgSellPrice,
  sold,
  unsold,
  received,
  profit,
  loading,
  status,
  assessedValue,
}) => {
  const [tooltip, setTooltip] = useState(false);
  const toggle = () => setTooltip(!tooltip);

  if (loading) return <VolumesWidgetPreloader />;

  return (
    <Card className='mb-4'>
      <CardBody className='p-4'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-items-center'>
            <Volumes />
            <p className='mb-0 ml-2 font-weight-500 text-white'>Volumes</p>
          </div>
          <div id='percent' className='cursor-pointer'>
            <PercentBadge
              value={investedAmount}
              isColorSpectrum={true}
              isDefault={false}
            />
          </div>
          <Tooltip
            target='percent'
            placement='top'
            isOpen={tooltip}
            toggle={toggle}
          >
            Invested
          </Tooltip>
        </div>
        <VolumesWidgetBody
          investedAmount={investedAmount}
          investedAsset={investedAsset}
          purchasedAmount={purchasedAmount}
          purchasedAsset={purchasedAsset}
        />
        <VolumesViewStatus
          receivedAmount={received}
          asset={purchasedAsset}
          soldAmount={sold}
          avgBuyPrice={avgBuyPrice}
          avgSellPrice={avgSellPrice}
          unsoldAmount={unsold}
          assessedValue={assessedValue}
          investedAsset={investedAsset}
        />
      </CardBody>
      {status === "completed" ? (
        <VolumesWidgetFooter
          status={status}
          amount={profit}
          currency={investedAsset}
          percent={percent}
        />
      ) : null}
    </Card>
  );
};

export default VolumesWidget;
