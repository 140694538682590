import React, { useState, useEffect, useCallback } from "react";
import classnames from "classnames";
import { Col, Table } from "reactstrap";
import styled from "styled-components";

import tickersNotFound from "assets/img/error/tickers-notfound.webp";
import tickersBeError from "assets/img/error/tickers-error.webp";
import LookupCoins from "../../utils/LookupCoins";
import { ReactComponent as SortUp } from "assets/icons/general/ArrowUp.svg";
import { ReactComponent as SortDown } from "assets/icons/general/ArrowDown.svg";
import CustomScrollbars from "../CustomScrollbars";
import Icon from "../Icon";

const SortingTable = ({ tbody, thead, error, ...props }) => {
  const [bodyData, setBodyData] = useState(tbody);
  const [column, setColumn] = useState({ name: -1, order: "" });
  const [columnKey, setColumnKey] = useState("");

  const sortDesc = useCallback((bodyData, key) => {
    bodyData.sort((a, b) => {
      if (a.data[key] && b.data[key] && a.data[key].value > b.data[key].value) {
        return -1;
      }
      if (a.data[key] && b.data[key] && a.data[key].value < b.data[key].value) {
        return 1;
      }
      return 0;
    });
  }, []);

  const sortAsc = useCallback((bodyData, key) => {
    bodyData.sort((a, b) => {
      if (a.data[key] && b.data[key] && a.data[key].value > b.data[key].value) {
        return 1;
      }
      if (a.data[key] && b.data[key] && a.data[key].value < b.data[key].value) {
        return -1;
      }
      return 0;
    });
  }, []);

  useEffect(() => {
    if (columnKey >= 0 && column.order === "asc") sortAsc(tbody, columnKey);
    if (columnKey >= 0 && column.order === "desc") sortDesc(tbody, columnKey);
    setColumn({ name: columnKey, order: column.order });
    setBodyData(tbody);
  }, [tbody, column.order, columnKey, sortAsc, sortDesc, setColumn]);

  const sortTable = useCallback(
    key => {
      let order = "";
      if (
        (column.name === key && column.order === "asc") ||
        column.name !== key
      ) {
        order = "desc";
        sortDesc(bodyData, key);
      }
      if (column.name === key && column.order === "desc") {
        order = "asc";
        sortAsc(bodyData, key);
      }
      setColumn({ name: key, order });
    },
    [bodyData, column, sortAsc, sortDesc],
  );

  if (error) {
    return (
      <Table.State>
        <img src={tickersBeError} alt='' />
        <h3>Oops, some issues on our end...</h3>
        <p className='font-sm'>Please try again or come a bit later</p>
      </Table.State>
    );
  }

  if (tbody.length === 0) {
    return (
      <Table.State>
        <img src={tickersNotFound} alt='' />
        <h3>No results for your search</h3>
        <p className='font-sm'>Please try adjusting your filters</p>
      </Table.State>
    );
  }

  return (
    <CustomScrollbars
      autoHeight
      autoHeightMin={200}
      autoHeightMax='60vh'
      thumbMinSize={63}
      isDark={false}
      autoHideTimeout={200}
      autoHideDuration={600}
    >
      <SortingTable.Wrapper className='position-relative' {...props}>
        {tbody.length > 0 ? (
          <div className='header-table position-relative d-flex flex-row align-items-center pb-2'>
            {thead.map((prop, key) => {
              return (
                <Col
                  className={classnames(
                    `${prop.className || ""} header-column text-nowrap d-flex flex-row align-items-center py-0 font-xs font-weight-300`,
                    {
                      headerSortDown:
                        key === column.name && column.order === "asc",
                    },
                    {
                      headerSortUp:
                        key === column.name && column.order === "desc",
                    },
                  )}
                  key={`key-is-${key}`}
                  onClick={() => {
                    if (!prop.notSortable) {
                      sortTable(key);
                      setColumnKey(key);
                    }
                  }}
                >
                  <p className='cursor-pointer header-column__title mb-0 font-weight-300 font-xs'>
                    {prop.text}
                  </p>
                  <div className='d-flex justify-content-between ml-2'>
                    <SortingTable.SortUp
                      name={key}
                      col={columnKey}
                      order={column.order}
                    >
                      <SortUp />
                    </SortingTable.SortUp>
                    <SortingTable.SortDown
                      name={key}
                      col={columnKey}
                      order={column.order}
                    >
                      <SortDown />
                    </SortingTable.SortDown>
                  </div>
                </Col>
              );
            })}
          </div>
        ) : null}
        {bodyData.map((prop, key) => {
          return (
            <SortingTable.Row
              className='w-100 text-dark footer-row position-relative d-flex flex-row align-items-center py-2'
              key={key}
              style={prop.onClickHandler ? { cursor: "pointer" } : {}}
              onClick={e => {
                prop.onClickHandler
                  ? prop.onClickHandler()
                  : e.preventDefault();
              }}
            >
              {prop.data.map((data, k) => {
                return (
                  <Col
                    className={`${data.className || ""} footer-column d-flex flex-row align-items-center py-0 text-nowrap`}
                    key={`key-is-${k}`}
                  >
                    {data.icon ? (
                      <div className='d-flex'>
                        <Icon.Title className={`my-auto ${data.color || ""}`}>
                          {data.icon && (
                            <Icon
                              className='mr-2'
                              code={data.text.split("_")[0]}
                              type='cryptocurrency'
                              size='thumb'
                            />
                          )}
                          <div className='d-flex flex-column w-75'>
                            <div className='icon-title__text'>
                              <Icon.Paragraph className='mr-1'>
                                {data.text.split("_")[0]}
                              </Icon.Paragraph>
                              <span className='text-muted'>/</span>
                              <span className='text-muted ml-1 font-weight-normal'>
                                {data.text.split("_")[1]}
                              </span>
                            </div>
                            <div className='d-flex'>
                              <Icon.Paragraph className='text-truncate font-weight-light'>
                                <span className='opacity-35'>
                                  <LookupCoins
                                    coin={
                                      data.icon.code.toUpperCase().split("_")[0]
                                    }
                                    className
                                  />
                                </span>
                              </Icon.Paragraph>
                            </div>
                          </div>
                        </Icon.Title>
                      </div>
                    ) : (
                      <div style={data?.style}>
                        <span
                          className={`my-auto ${data.color || ""} font-weight-500`}
                        >
                          {data.text || data.value}
                        </span>
                        <span className='ml-1' style={{ color: "#1D1E2A59" }}>
                          {data.asset}
                        </span>
                      </div>
                    )}
                  </Col>
                );
              })}
            </SortingTable.Row>
          );
        })}
      </SortingTable.Wrapper>
    </CustomScrollbars>
  );
};

SortingTable.Wrapper = styled.table`
  width: 100%;

  .header-column,
  .footer-column {
    &__title {
      color: rgba(29, 30, 42, 0.35) !important;

      :hover {
        color: rgba(29, 30, 42, 0.75) !important;
      }
    }

    :first-child {
      margin-right: 20px;
    }
    :nth-child(2),
    :nth-child(3),
    :nth-child(4),
    :nth-child(5),
    :nth-child(6),
    :nth-child(7),
    :nth-child(8) {
      margin: 0 20px !important;
    }

    :nth-child(9) {
      margin: 0 40px !important;
    }

    :nth-child(10) {
      margin-right: 10px !important;
    }
  }

  .footer-row,
  .header-table {
    &:before {
      height: 1px;
      content: "";
      background-color: #e6e6ea;
      display: block;
      bottom: 0;
      width: 100%;
      position: absolute;
    }
  }

  .header-table {
    cursor: pointer;
  }

  .footer-table {
    max-height: 810px;
    min-height: 50px;
  }
`;

SortingTable.Row = styled.div`
  &:hover {
    background: #f7f7f8 !important;
  }
`;

Table.State = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px;

  h3 {
    color: var(--darker);
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  p {
    color: rgba(29, 30, 42, 0.75);
    font-weight: 300;
    font-size: 16px;
  }
`;

Icon.Title = styled.div`
  display: flex;
  align-items: center;
  width: 160px;
  height: 42px;

  .icon-title__text {
    display: flex;
    align-items: center;
  }
`;

Icon.Paragraph = styled.p`
  margin-bottom: 0;
  white-space: nowrap;
  font-weight: 500;
  color: #1d1e2a;
`;

SortingTable.SortUp = styled.div`
  svg {
    path {
      fill: ${props =>
        props.name === props.col && props.order === "asc"
          ? "rgba(29, 30, 42, 0.75)"
          : "rgba(29, 30, 42, 0.15)"};
      stroke: ${props =>
        props.name === props.col && props.order === "asc"
          ? "rgba(29, 30, 42, 0.75)"
          : "rgba(29, 30, 42, 0.15)"};
    }
    rect {
      stroke: ${props =>
        props.name === props.col && props.order === "asc"
          ? "rgba(29, 30, 42, 0.75)"
          : "rgba(29, 30, 42, 0.15)"};
    }
  }
`;

SortingTable.SortDown = styled.div`
  svg {
    path {
      fill: ${props =>
        props.name === props.col && props.order === "desc"
          ? "rgba(29, 30, 42, 0.75)"
          : "rgba(29, 30, 42, 0.15)"};
      stroke: ${props =>
        props.name === props.col && props.order === "desc"
          ? "rgba(29, 30, 42, 0.75)"
          : "rgba(29, 30, 42, 0.15)"};
    }
    rect {
      stroke: ${props =>
        props.name === props.col && props.order === "desc"
          ? "rgba(29, 30, 42, 0.75)"
          : "rgba(29, 30, 42, 0.15)"};
    }
  }
`;

export default SortingTable;
