import React from "react";
import styled from "styled-components";
import { times } from "lodash";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import { StyledTable } from "shared/styled/StyledTable";

const TemplateConfigurationPreloader = () => {
  return (
    <StyledTable>
      <thead>
        <tr>
          {times(2, e => (
            <th key={e}>
              <SkeletonPreloader height={10} width={58} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {times(10, e => (
          <tr key={e}>
            <td>
              <div className='d-flex'>
                <StyledRadioButton className='my-auto' />
                <SkeletonPreloader circle height={24} width={24} />
                <SkeletonPreloader height={16} width={62} className='ml-2' />
              </div>
            </td>
            <td>
              <SkeletonPreloader height={12} width={394} />
            </td>
            <td>
              <StyledButton />
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

const StyledButton = styled.div`
  width: 103px;
  height: 40px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 6px;
`;

const StyledRadioButton = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid #3b3c4d;
  border-radius: 50%;
  margin-right: 0.75rem;
`;

export default TemplateConfigurationPreloader;
