import React from "react";

import { formatterToNull, setColorClass } from "helpers/valuesFormatters";
import { numberOrNull, stringOrNull } from "views/channels/channelPropTypes";
import { ParameterDisplay, PercentBadge } from "shared/ui";

const MyAgreementsBody = ({
  quote,
  base,
  invested,
  purchased,
  received,
  sold,
  unsold,
  profit,
  profitPercent,
  isList,
}) => (
  <div
    className={` ${isList || "justify-content-between"} d-flex mt-4 flex-wrap`}
  >
    <ParameterDisplay
      title='Invested'
      amount={invested}
      coin={quote}
      className='col-6 p-0 col-md-4 col-xl-2'
      classNameTitle='text-white-75 font-weight-500'
    />
    <ParameterDisplay
      title='Purchased'
      amount={purchased}
      coin={base}
      className='col-6 p-0 col-md-4 col-xl-2'
      classNameTitle='text-white-75 font-weight-500'
    />
    <ParameterDisplay
      title='Received'
      amount={received}
      coin={quote}
      className='col-6 mt-2 p-0 col-md-4 mt-md-0 col-xl-2'
      classNameTitle='text-white-75 font-weight-500'
    />
    <ParameterDisplay
      title='Sold'
      amount={sold}
      coin={base}
      className='col-6 mt-2 p-0 col-md-4 col-xl-2 mt-xl-0'
      classNameTitle='text-white-75 font-weight-500'
    />
    <ParameterDisplay
      title='Unsold'
      amount={unsold}
      coin={base}
      className='col-6 mt-2 p-0 col-md-4 col-xl-2 mt-xl-0'
      classNameTitle='text-white-75 font-weight-500'
      textColor='rgba(255, 255, 255)'
    />
    {isList && (
      <div className='col-6 mt-2 p-0 col-md-4 col-xl-2 mt-xl-0'>
        <p className='mb-0 text-white-75'>
          Profit <PercentBadge value={profitPercent} showZeroValue />
        </p>
        <p className={`${setColorClass(profit)} mb-0 font-weight-500`}>
          {formatterToNull(profit)}{" "}
          <span className='text-white-35 font-weight-300 '>{quote}</span>
        </p>
      </div>
    )}
  </div>
);

MyAgreementsBody.propTypes = {
  quote: stringOrNull,
  invested: numberOrNull,
  purchased: numberOrNull,
  received: numberOrNull,
  sold: numberOrNull,
  unsold: numberOrNull,
  profit: numberOrNull,
  profitPercent: numberOrNull,
};

export default MyAgreementsBody;
