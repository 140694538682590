import React from "react";
import Slider from "rc-slider";

const RealRange = ({
  values,
  realPercent,
  index,
  setFieldValue,
  isEdited,
  enabled,
}) => {
  const onChange = e => {
    if (!isEdited || !enabled) return;

    setFieldValue(`credentials[${index}]`, {
      ...values.credentials[index],
      realPercent: e,
      counter: e - realPercent,
    });
  };

  return (
    <Slider
      min={0}
      max={100}
      step={1}
      defaultValue={realPercent}
      value={isEdited ? values.credentials[index]?.realPercent : realPercent}
      railStyle={{
        backgroundColor: "#1D1E2A",
        height: 8,
        borderRadius: "50px",
      }}
      trackStyle={{
        background: "#848EFA",
        height: 8,
        borderRadius: "50px 0 0 50px",
      }}
      handleStyle={{
        display: isEdited && enabled ? "block" : "none",
        border: "none",
        boxShadow: "none",
        backgroundColor: "#5E6392",
        opacity: 1,
        width: 16,
        height: 16,
        zIndex: 2,
      }}
      onChange={onChange}
    />
  );
};

export default RealRange;
