import React from "react";
import { Table } from "reactstrap";

const HistoryTable = ({ data }) => (
  <Table>
    <thead>
      <tr>
        <th>Date</th>
        <th>Type</th>
        <th>TXID ID</th>
        <th>Amount</th>
        <th>Address</th>
        <th>Status</th>
        <th>Comment</th>
      </tr>
    </thead>
    <tbody>
      {data &&
        data.map(item => (
          <tr key={item.uuid}>
            <td>{item.createdAt}</td>
            <td>{item.type}</td>
            <td>{item.txid}</td>
            <td>{item.amount}</td>
            <td>{item.address}</td>
            <td>{item.status}</td>
            <td>{item.comment}</td>
          </tr>
        ))}
    </tbody>
  </Table>
);

export default HistoryTable;
