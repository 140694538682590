import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, Modal } from "reactstrap";
import CreateChannelSubscription from "../forms/CreateChannelSubscription";
import styled from "styled-components";
import { numberOrNull } from "../channelPropTypes";

const ChannelSubscription = ({
  isModalOpen,
  handleToggleModal,
  channelId,
  handleRefresh,
  maxximun,
  volume,
  accept,
}) => {
  return (
    <ChannelSubscription.Modal
      toggle={handleToggleModal}
      isOpen={isModalOpen}
      modalClassName='modal-black'
    >
      <Card className='mb-0'>
        <CardHeader className='p-0 mb-4'>
          <h3 className='h1 modal-title text-white mb-0'>
            Subscription settings
          </h3>
        </CardHeader>
        <CreateChannelSubscription
          channelId={channelId}
          handleToggleModal={handleToggleModal}
          handleRefresh={handleRefresh}
          maxximun={maxximun}
          volume={volume}
          accept={accept}
        />
      </Card>
    </ChannelSubscription.Modal>
  );
};

ChannelSubscription.Modal = styled(Modal)`
  .modal-content {
    padding: 2rem;
  }

  .modal-content,
  .card {
    border-radius: 1rem !important;
  }
`;

ChannelSubscription.propTypes = {
  isModalOpen: PropTypes.bool,
  handleToggleModal: PropTypes.func,
  channelId: PropTypes.string,
  handleRefresh: PropTypes.func,
  maxximun: numberOrNull,
  volume: PropTypes.number,
  accept: PropTypes.string,
};

export default ChannelSubscription;
