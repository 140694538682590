import React from "react";
import { Card } from "reactstrap";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const BalancePreloader = () => (
  <Card className='p-4 mb-4' data-testid='balance-preloader'>
    <div className='d-flex align-items-center mb-2'>
      <SkeletonPreloader circle={true} height={22} width={22} />
      <SkeletonPreloader height={16} width={111} className='ml-2' />
    </div>
    <div className='d-flex justify-content-between'>
      <div>
        <SkeletonPreloader
          count={4}
          height={12}
          width={99}
          className='d-flex flex-column  mt-3'
        />
      </div>
      <div>
        <SkeletonPreloader
          count={4}
          width={76}
          height={12}
          className='d-flex flex-column mt-3'
        />
      </div>
    </div>
  </Card>
);

export default BalancePreloader;
