import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getReferralStatistics, getUsersReferrals } from "store/user/actions";
import { REFERRAL_CHART_COLORS } from "shared/models/user/refferalSystem";
import { selectMe } from "store/user/selectors";
import ReferralData from "./ui/ReferralData";
import ReferralEmptyState from "views/user/compounds/ReferralEmptyState";
import ReferralSystemPreloader from "views/user/preloaders/ReferralSystemPreloader";

const ReferralSystem = () => {
  const [data, setData] = useState();
  const [chartData, setChartData] = useState(null);
  const [hoverCurrentBg, setHoverCurrentBg] = useState(-1);
  const [hoverredElemId, setHoverredElemId] = useState(null);

  const dispatch = useDispatch();

  const {
    statisticsLoading,
    loading,
    data: { referralCode, referral, statistics },
  } = useSelector(selectMe);

  useEffect(() => {
    dispatch(getReferralStatistics());
  }, [dispatch]);

  useEffect(() => {
    if (statistics) {
      const backgroundColors = [
        REFERRAL_CHART_COLORS.confirmed,
        REFERRAL_CHART_COLORS.unconfirmed,
      ];
      const unconfirmed = statistics?.total - statistics?.confirmed;
      const data = [statistics?.confirmed, unconfirmed];
      const index = data.findIndex(item => item);
      const labels = Object.keys(statistics?.statistics?.total).slice(-7);
      const statisticsData = Object.values(statistics?.statistics?.total).slice(
        -7,
      );

      const maxAmount = statistics?.total + 1;
      setData({ labels, statisticsData, maxAmount });
      setChartData({
        labels: ["Confirmed", "Unconfirmed"],
        data,
        backgroundColors,
      });
      setHoverredElemId(index);
    }
  }, [dispatch, statistics]);

  useEffect(() => {
    if (referralCode) {
      dispatch(getUsersReferrals());
    }
  }, [dispatch, referralCode]);

  if (!referralCode && !loading && !statisticsLoading)
    return <ReferralEmptyState />;

  if (!referralCode || statisticsLoading || loading || !referral)
    return <ReferralSystemPreloader />;
  return (
    <ReferralData
      referral={referral}
      referralCode={referralCode}
      chartData={chartData}
      setHoverCurrentBg={setHoverCurrentBg}
      hoverCurrentBg={hoverCurrentBg}
      setHoverredElemId={setHoverredElemId}
      hoverredElemId={hoverredElemId}
      statisticsData={data?.statisticsData}
      labels={data?.labels}
      maxAmount={data?.maxAmount}
      total={referral?.total || statistics?.total}
    />
  );
};

export default ReferralSystem;
