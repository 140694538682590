import React, { useState } from "react";
import PropTypes from "prop-types";
import { CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";

import CreateSignalFooter from "../compounds/CreateSignalFooter";
import CurrentExchange from "../compounds/CurrentExchange";
import ExchangeInputBlock from "../compounds/ExchangeInputBlock";
import ParameterInputBlock from "../compounds/ParameterInputBlock";
import TargetInputBlock from "../compounds/TargetInputBlock";
import { addSnackBar } from "store/snackbar/actions";
import { createSignal } from "store/signals/action";
import { selectChannels } from "store/channels/selectors";
import ChannelLabel from "../compounds/ChannelLabel";
import { stringOrNull } from "../channelPropTypes";
import { ChannelsSelect, ErrorAlert, InfoReminder } from "shared/ui";
import { validationSchemaCreateSignal } from "shared/models/channels/schema";

const CreateSignalForm = ({
  channelId,
  handleToggleModal,
  isActiveTable,
  logo,
  name,
  totalSubscribers,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loading,
    data: { total },
  } = useSelector(selectChannels);

  const isChannelsList = history?.location?.pathname?.includes("channels");
  const disabledForm = !isChannelsList && !total;

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    const signalData = {
      ...values,
      deadline: null,
      trailing: 4,
      comment: null,
    };

    const res = await dispatch(
      createSignal(values?.channel, signalData, isActiveTable),
    );

    if (!res.errors) {
      handleToggleModal();
      return dispatch(addSnackBar("success", res.message));
    }
    setErrorMessage(res.message);
    setErrors(res.message);
    setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchemaCreateSignal}
      initialValues={{
        channel: channelId,
        market: {
          base: "",
          quote: "",
        },
        exchange: "",
        buyFrom: "",
        buyTo: "",
        stopLoss: "",
        targets: [
          {
            rate: "",
          },
        ],
      }}
      onSubmit={handleSubmit}
    >
      {({ errors, isSubmitting, setFieldValue, values }) => (
        <Form className='w-100' autoComplete='off'>
          <CardBody className='p-0'>
            {isChannelsList ? (
              <ChannelLabel
                logo={logo}
                name={name}
                totalSubscribers={totalSubscribers}
              />
            ) : (
              <>
                {!total && !loading && (
                  <InfoReminder
                    className='my-3'
                    text='In order to create a signal, you need to create your first channel'
                  />
                )}
                <ChannelsSelect isSubmitted={isSubmitted} />
              </>
            )}

            <ExchangeInputBlock
              isHasChannels={disabledForm}
              isSubmitted={isSubmitted}
              errors={errors}
            />
            {total || loading || isChannelsList ? (
              <CurrentExchange
                selectedExchange={values?.exchange}
                base={values?.market?.base}
                quote={values?.market?.quote}
              />
            ) : null}
            <ParameterInputBlock
              isHasChannels={disabledForm}
              isSubmitted={isSubmitted}
              errors={errors}
              setFieldValue={setFieldValue}
              values={values}
            />
            <TargetInputBlock
              isHasChannels={disabledForm}
              targets={values?.targets}
              buyTo={values?.buyTo}
              errors={errors}
              isSubmitted={isSubmitted}
            />
            <ErrorAlert
              toggle={() => setErrorMessage("")}
              isOpen={!!errorMessage}
              className='mt-3'
            >
              {errorMessage}
            </ErrorAlert>
          </CardBody>
          <CreateSignalFooter
            isHasChannels={disabledForm}
            handleToggleModal={handleToggleModal}
            isSubmitting={isSubmitting}
            setIsSubmitted={setIsSubmitted}
          />
        </Form>
      )}
    </Formik>
  );
};

CreateSignalForm.propType = {
  handleOnSubmit: PropTypes.func,
  isActiveTable: PropTypes.bool,
  logo: stringOrNull,
  name: PropTypes.string,
  totalSubscribers: PropTypes.number,
};

export default CreateSignalForm;
