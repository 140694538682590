import styled from "styled-components";

import StyledSubCard from "./StyledSubCard";

const StyledIconWrapper = styled(StyledSubCard)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.625rem;
  height: 3.625rem;
`;

export default StyledIconWrapper;
