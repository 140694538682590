import React from "react";
import { Card, CardBody } from "reactstrap";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import { ReactComponent as ChartPreloader } from "assets/icons/general/balance-chart-preload.svg";
import _ from "lodash";

const AssetWidgetPreloader = () => {
  return (
    <Card style={{ padding: "24px 24px 0 24px" }}>
      <div className='d-flex align-items-center mb-4'>
        <SkeletonPreloader circle={true} height={18} width={18} />
        <SkeletonPreloader height={12} width={111} className='ml-2' />
      </div>
      <ChartPreloader width='100%' />
      <CardBody>
        {_.times(2, e => (
          <div
            className='d-flex align-items-center justify-content-between mb-4'
            key={e}
          >
            <div className='d-flex align-items-center'>
              <SkeletonPreloader circle={true} height={24} width={24} />
              <SkeletonPreloader height={10} width={55} className='ml-2' />
            </div>
            <div
              className='d-flex flex-column align-items-end pr-2'
              style={{ borderRight: "2px solid #535462" }}
            >
              <SkeletonPreloader height={10} width={120} />
              <SkeletonPreloader height={10} width={93} className='mt-2' />
            </div>
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

export default AssetWidgetPreloader;
