import React from "react";

import OverviewTab from "./OverviewTab";
import AdminTab from "./AdminTab";
import OverviewPreloader from "../preloaders/OverviewPreloader";
import { CHANNEL_VIEW } from "../mocks";
import { RenderTabs } from "shared/ui";

const PagesView = ({ loadingPage, data, pageView, setPagView }) => {
  if (loadingPage) return <OverviewPreloader />;

  if (data?.owner) {
    return (
      <>
        <RenderTabs
          arrTabs={CHANNEL_VIEW}
          setPagView={setPagView}
          currentTab={pageView}
        />
        {pageView === "overview" && (
          <OverviewTab channel={data} loadingPage={loadingPage} />
        )}
        {pageView === "admin" && <AdminTab channel={data} />}
      </>
    );
  }
  return <OverviewTab channel={data} loadingPage={loadingPage} />;
};

export default PagesView;
