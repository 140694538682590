import React from "react";
import { useSelector } from "react-redux";

import {
  botsSelector,
  credentialsSelector,
  investmentsSelector,
  dealsSelector,
  channelsSelector,
  signalsSelector,
  agreementsSelector,
} from "store/dashboard/selectors";
import BotsResults from "./compounds/BotsResults";
import DealsResults from "./compounds/DealsResults";
import CredentialsResults from "./compounds/CredentialsResults";
import InvestmentsResult from "./compounds/InvestmentsResult";
import ChannelsResults from "./compounds/ChannelsResults";
import SignalsResults from "./compounds/SignalsResults";
import AgreementsResults from "./compounds/AgreementsResults";

const SearchResults = ({ onSetModalSearch }) => {
  const botsResult = useSelector(botsSelector);
  const credentialsResult = useSelector(credentialsSelector);
  const investmentsResult = useSelector(investmentsSelector);
  const dealsResult = useSelector(dealsSelector);
  const channelsResult = useSelector(channelsSelector);
  const signalsResult = useSelector(signalsSelector);
  const agreementsResult = useSelector(agreementsSelector);

  return (
    <>
      <BotsResults
        onSetModalSearch={onSetModalSearch}
        botsResult={botsResult}
      />
      <DealsResults
        onSetModalSearch={onSetModalSearch}
        dealsResult={dealsResult}
      />
      <CredentialsResults
        onSetModalSearch={onSetModalSearch}
        credentialsResult={credentialsResult}
      />
      <InvestmentsResult
        onSetModalSearch={onSetModalSearch}
        investmentsResult={investmentsResult}
      />
      <ChannelsResults
        onSetModalSearch={onSetModalSearch}
        channelsResult={channelsResult}
      />
      <SignalsResults
        onSetModalSearch={onSetModalSearch}
        signalsResult={signalsResult}
      />
      <AgreementsResults
        onSetModalSearch={onSetModalSearch}
        agreementsResult={agreementsResult}
      />
    </>
  );
};

export default SearchResults;
