import classNames from "classnames";
import React from "react";
import styled from "styled-components";

import { firstLetterUpper } from "helpers/valuesFormatters";

const TradeRequestGoal = ({ goal, className = "mr-3" }) => {
  if (goal) {
    return (
      <TradeRequestGoal.Label
        className={classNames(`font-xs text-nowrap ${className}`, {
          investment: goal === "investment",
          target: goal === "target",
          stopLoss: goal === "stop_loss",
          expire: goal === "expire",
          manualSell: goal === "manual_sell",
        })}
      >
        {firstLetterUpper(goal).replace(/_/g, " ")}
      </TradeRequestGoal.Label>
    );
  }
};

TradeRequestGoal.Label = styled.span`
  border-radius: 2px;
  padding: 0.25rem 0.375rem;
  color: var(--white);

  &.investment {
    background: rgba(47, 128, 237, 0.2);
  }

  &.target {
    background: rgba(63, 203, 176, 0.2);
  }

  &.stopLoss {
    background: rgba(237, 95, 95, 0.2);
  }

  &.expire {
    background: rgba(255, 255, 255, 0.2);
  }

  &.manualSell {
    background: rgba(219, 83, 199, 0.2);
  }
`;

export default TradeRequestGoal;
