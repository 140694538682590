import React, { useState } from "react";
import { capitalize } from "lodash";
import { Tooltip } from "reactstrap";

import IdWithCopy from "shared/ui/IdWithCopy";
import { setColorClass } from "helpers/valuesFormatters";
import { ReactComponent as Fork } from "assets/icons/credential/fork.svg";
import { ReactComponent as DailyUp } from "assets/icons/credential/dailyUp.svg";
import { ReactComponent as ForkOff } from "assets/icons/credential/forkOff.svg";
import { ReactComponent as DailyDown } from "assets/icons/credential/dailyDown.svg";

import {
  CredentialItemAmountStyled,
  CredentialItemCardHeaderStyled,
  CredentialItemDailyStyled,
  CredentialItemHeaderStyled,
  CredentialItemIconWrapperStyled,
  CredentialItemStatusTextStyled,
  CredentialItemStatusWrapperStyled,
} from "./index.styled";
import Icon from "../Icon";
import { handleHideBalance } from "shared/helpers/handleHideBalance";

const CredentialItem = ({
  data,
  index,
  isActive,
  handleClick,
  balanceHidden,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipOpenBalance, setTooltipOpenBalance] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const toggleBalance = () => setTooltipOpenBalance(!tooltipOpenBalance);

  const renderDailyIcon = daily => {
    if (daily > 0) return <DailyUp className='mr-2' />;
    if (daily < 0) return <DailyDown className='mr-2' />;
    return null;
  };

  return (
    <CredentialItemCardHeaderStyled isActive={isActive} onClick={handleClick}>
      <div className='d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between'>
        <div className='d-flex overflow-hidden mr-4'>
          <div className='d-flex position-relative'>
            <Icon
              type='exchanges'
              size='xs'
              code={data.exchange}
              className='mr-3 my-auto'
            />
            <CredentialItemIconWrapperStyled className='connect-icon'>
              {data.connected && data.status !== "pending_delete" ? (
                <Fork width='100%' height='100%' />
              ) : (
                <ForkOff width='100%' height='100%' />
              )}
            </CredentialItemIconWrapperStyled>
          </div>
          <CredentialItemHeaderStyled>
            <div className='d-flex align-items-baseline text-center pt-1 pb-1'>
              <h2 className='mb-0 text-white font-weight-500'>
                {capitalize(data.exchange)}
              </h2>
              {data.status === "pending_delete" && (
                <CredentialItemStatusWrapperStyled className='d-none d-md-flex ml-2'>
                  <CredentialItemStatusTextStyled>
                    Pending delete
                  </CredentialItemStatusTextStyled>
                </CredentialItemStatusWrapperStyled>
              )}
              <p className='d-none font-sm d-md-block ml-2 mb-0 text-white-35'>
                {data.comment}
              </p>
            </div>
            <IdWithCopy className='d-none d-md-flex' id={data.id} />
          </CredentialItemHeaderStyled>
        </div>
        <p className='d-flex d-md-none mt-2 mb-3 font-sm'>
          {data.status === "pending_delete" && (
            <CredentialItemStatusWrapperStyled className='mr-2'>
              <CredentialItemStatusTextStyled>
                Pending delete
              </CredentialItemStatusTextStyled>
            </CredentialItemStatusWrapperStyled>
          )}
          {data.comment}
        </p>
        <CredentialItemAmountStyled
          isTransparent={data.status === "pending_delete"}
          className='d-flex align-items-start align-items-md-end flex-column text-nowrap'
        >
          <div className='d-flex align-items-center'>
            <CredentialItemDailyStyled
              id={`dailyRevenue-${index}`}
              daily={data.dailyRevenueBtcPercent}
            >
              {renderDailyIcon(data.dailyRevenueBtcPercent)}
              <p
                className={`mb-0 ${setColorClass(
                  data.dailyRevenueBtcPercent,
                  "text-white-35",
                )}`}
              >
                {`${
                  data?.dailyRevenueBtcPercent?.toString()?.replace("-", "") ||
                  "0.00"
                } %`}
              </p>
              <Tooltip
                placement='top'
                isOpen={tooltipOpen}
                target={`dailyRevenue-${index}`}
                toggle={toggle}
              >
                24h change
              </Tooltip>
            </CredentialItemDailyStyled>
            <p
              className='font-weight-500 text-white mb-0 font-md'
              id={`balance-${index}`}
            >
              {`${handleHideBalance(balanceHidden, data?.balance?.btc || "0")} BTC`}
            </p>
            {!data.connected && (
              <Tooltip
                placement='top'
                isOpen={tooltipOpenBalance}
                target={`balance-${index}`}
                toggle={toggleBalance}
              >
                Total balance can be outdated
              </Tooltip>
            )}
          </div>
          <p className='text-white-35 mb-0 font-sm mt-2 mt-md-0'>
            {`${handleHideBalance(balanceHidden, data.estimated.amount || "0.00000000")} ${
              data.estimated.currency
            }`}
          </p>
        </CredentialItemAmountStyled>
      </div>
    </CredentialItemCardHeaderStyled>
  );
};

export default CredentialItem;
