import React from "react";
import { useDispatch } from "react-redux";
import { Range } from "rc-slider";
import { minMaxSteps } from "./SlidersStyling/minMaxDailyStyling";
import { setMinMaxChange } from "store/bots/wizard/actions";

const MinMaxRange = ({
  minimumDailyChange,
  maximumDailyChange,
  validation,
}) => {
  const dispatch = useDispatch();
  const stepsStyle = minMaxSteps(minimumDailyChange, maximumDailyChange);

  const setHandleBgcLeft = () => {
    if (!minimumDailyChange) return "#9C9FBF";
    if (minimumDailyChange < 0) return "#ED5F5F";
    return "#0AAF95";
  };

  const setHandleBgcRight = () => {
    if (!maximumDailyChange) return "#9C9FBF";
    if (maximumDailyChange > 0) return "#0AAF95";
    return "#ED5F5F";
  };

  const splitRange = (start, end) => {
    const total = 100;
    const range = end - start;
    const firstHalf = Math.abs(start) / range;
    const secondHalf = (end - Math.abs(end)) / range;

    const firstPercent = (firstHalf * total).toFixed(2);
    const secondPercent = (secondHalf * total).toFixed(2);

    if (start > 0) return "#0AAF95";
    if (end < 0) return "#ED5F5F";

    return `linear-gradient(90deg,  #ED5F5F ${firstPercent}%, #0AAF95 ${secondPercent}%)`;
  };

  return (
    <Range
      min={-100}
      max={100}
      step={1}
      startPoint={[0, 0]}
      onChange={percent => dispatch(setMinMaxChange(percent))}
      value={[minimumDailyChange, maximumDailyChange]}
      railStyle={{
        backgroundColor: "#363A54",
        height: 8,
      }}
      trackStyle={[
        {
          background: splitRange(minimumDailyChange, maximumDailyChange),
          display: validation ? "none" : "block",
          height: 8,
        },
      ]}
      handleStyle={[
        {
          display: validation ? "none" : "block",
          border: "none",
          boxShadow: "none",
          backgroundColor: setHandleBgcLeft(),
          opacity: 1,
          width: 22,
          height: 22,
          bottom: "-50%",
        },
        {
          display: validation ? "none" : "block",
          border: "none",
          boxShadow: "none",
          backgroundColor: setHandleBgcRight(),
          opacity: 1,
          width: 22,
          height: 22,
          bottom: "-50%",
        },
      ]}
      marks={{ ...stepsStyle }}
      dotStyle={{
        border: "none",
        borderRadius: 2,
        height: "10px",
        margin: "0 0 -3px -2px",
        width: "4px",
        background: "var(--dark-slate)",
      }}
    />
  );
};

export default MinMaxRange;
