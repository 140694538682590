import React from "react";
import styled from "styled-components";

import { ReactComponent as Arrow } from "assets/icons/trending-up.svg";
import { setColorClass, formatValue } from "helpers/valuesFormatters";
import { investedPercentColors } from "helpers/investedPercentColors";

const PercentBadge = ({
  isDefault = true,
  value = 0,
  showZeroValue,
  zeroDarkTheme = true,
  isColorSpectrum,
  isUnsignedVal,
  ...props
}) => {
  const getPercent = value => {
    const fixedValue = value.toFixed(2) || 0;
    return `${isUnsignedVal || isColorSpectrum ? fixedValue : formatValue(fixedValue)} %`;
  };

  const getColorStyles = () => {
    if (isColorSpectrum) return `${investedPercentColors(value)} default`;
    return setColorClass(value);
  };

  if (!showZeroValue && !+value) return null;

  return (
    <StyledPercent
      className={`${showZeroValue && !+value ? "showZero" : " "} my-auto text-nowrap d-inline-block ${isDefault ? "small " : "regular "}${getColorStyles()}`}
      zeroDarkTheme={zeroDarkTheme}
      {...props}
    >
      {!isDefault && !isColorSpectrum && value ? <Arrow role='img' /> : null}
      <span className={isDefault || isColorSpectrum ? "" : "pl-2"}>
        {getPercent(+value)}
      </span>
    </StyledPercent>
  );
};

const StyledPercent = styled.span`
  font-size: 0.85rem;
  border-radius: 4px;
  line-height: 1rem;

  &.showZero {
    background: ${({ zeroDarkTheme }) =>
      zeroDarkTheme
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(29, 30, 42, 0.05)"} !important;
    color: ${({ zeroDarkTheme }) =>
      zeroDarkTheme
        ? "rgba(255, 255, 255, 0.35)"
        : "rgba(29, 30, 42, 1)"} !important;
  }

  &.default {
    background: rgba(255, 255, 255, 0.05) !important;
  }

  &.text-success {
    background: rgba(63, 203, 176, 0.1);
  }

  &.text-danger {
    background: rgba(237, 95, 95, 0.1);

    & svg {
      transform: rotate(180deg);

      & path {
        stroke: #ed5f5f;
      }
    }
  }

  &.regular {
    padding: 4px 8px;
  }

  &.small {
    padding: 4px 8px;
  }
`;

export default PercentBadge;
