import React from "react";

const MarketInformItem = ({ title, value, currency }) => {
  return (
    <div className='col-6 p-0 col-md-2' data-testid={title}>
      <p className='mb-1 text-white-35 font-weight-300 font-sm'>{title}</p>
      <div className='d-flex align-items-center'>
        {value?.toString() ? (
          <p className='mb-0 text-white font-weight-500 text-capitalize'>
            {value}
          </p>
        ) : (
          <p className='text-white-35'>-</p>
        )}
        {value?.toString() && currency ? (
          <p className='mb-0 ml-1 text-white-35 font-weight-300'>{currency}</p>
        ) : null}
      </div>
    </div>
  );
};

export default MarketInformItem;
