export const UPDATE_INVESTMENT_SETTINGS = "UPDATE_INVESTMENT_SETTINGS";
export const RESET_INVESTMENT = "RESET_INVESTMENT";
export const RESET_INVESTMENTS = "RESET_INVESTMENTS";
export const RESET_BOTS_STATISTICS = "RESET_BOTS_STATISTICS";
export const INVESTMENT_STATISTICS = "INVESTMENT_STATISTICS";
export const REBALANCE_TOGGLE_LOADING = "REBALANCE_TOGGLE_LOADING";
export const REBALANCE_TOGGLE_SUCCESS = "REBALANCE_TOGGLE_SUCCESS";
export const REBALANCE_TOGGLE_ERROR = "REBALANCE_TOGGLE_ERROR";
export const SET_CURRENT_CREDENTIAL = "SET_CURRENT_CREDENTIAL";
export const RESET_CREDETIAL = "RESET_CREDETIAL";
