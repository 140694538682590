import React from "react";
import styled from "styled-components";
import classNames from "classnames";

import { STEPS } from "../utils";

const StepsHeader = ({ currentStep, wizard = {} }) => {
  return (
    <StyledWarpper>
      {STEPS.map((step, index) => (
        <StyledStep
          key={step.name}
          className={classNames("d-flex flex-column justify-content-between", {
            active: currentStep === step.name,
            completed: wizard?.[step.name],
          })}
        >
          <div className='d-flex align-items-center justify-content-center justify-content-md-start pb-md-4 m-0 text-white font-weight-500 h-100'>
            <span className='step-number d-flex justify-content-center align-items-center mr-md-3'>
              {index + 1}
            </span>
            <span className='d-none d-md-inline'>{step.label}</span>
          </div>
          <span className='bottom-line w-100 d-none d-md-flex' />
        </StyledStep>
      ))}
      <p className='d-block d-md-none text-white font-weight-500 text-center m-0'>
        {STEPS.find(step => step.name === currentStep)?.label}
      </p>
    </StyledWarpper>
  );
};

const StyledWarpper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 1.25rem 1.5rem;
  gap: 1rem;

  @media (max-width: 768px) {
    &:last-child p {
      grid-column: span 4;
    }
  }
`;

const StyledStep = styled.span`
  font-size: 1.25rem !important;

  & .bottom-line {
    height: 4px;
    border-radius: 4px;
  }

  & .step-number {
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  & .step-number,
  & .bottom-line {
    background-color: #193e6f;
  }

  &.active {
    & .step-number,
    & .bottom-line {
      background-color: #3598fa;
    }
  }

  &.completed {
    & .step-number,
    & .bottom-line {
      background-color: #1fac46;
    }
  }
`;

export default StepsHeader;
