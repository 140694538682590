import React from "react";

import { times } from "lodash";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import IndicatorsPreloader from "shared/ui/BotSettingsWidget/preloaders/IndicatorsPreloader";

const TradeSettingsPreloader = () => {
  return (
    <>
      {times(4, e => (
        <div className='d-flex flex-column' key={e}>
          <div className='d-flex justify-content-between mb-3'>
            <SkeletonPreloader height={9} width={57} />
            <SkeletonPreloader height={12} width={121} />
          </div>
          <div className='d-flex justify-content-between mb-3'>
            <SkeletonPreloader height={9} width={81} />
            <SkeletonPreloader height={12} width={121} />
          </div>
        </div>
      ))}
      <SkeletonPreloader height={12} width={77} className='mb-3 mt-5' />
      <div className='d-flex justify-content-between mb-3'>
        <SkeletonPreloader height={9} width={57} />
        <SkeletonPreloader height={12} width={121} />
      </div>
      <div className='d-flex justify-content-between mb-3'>
        <SkeletonPreloader height={9} width={81} />
        <SkeletonPreloader height={12} width={121} />
      </div>
      <div className='d-flex justify-content-between mb-3'>
        <SkeletonPreloader height={9} width={57} />
        <SkeletonPreloader height={12} width={121} />
      </div>
      <IndicatorsPreloader />
    </>
  );
};

export default TradeSettingsPreloader;
