import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { createBot, editBot } from "store/bots/actions";
import { addSnackBar } from "store/snackbar/actions";
import { handleResponse } from "helpers/errorsValidator";
import { resetWizardIndicators } from "store/strategies/actions";

export const useCreateBotSubmit = ({
  wizardMarkets,
  botType,
  indicators,
  setRedirectPath,
  setAlertOpen,
  setIsSubmit,
  botId,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const filteredIndicators =
    indicators.map(indicator => ({
      code: indicator.code,
      type: indicator.type,
      settings: indicator.settings,
      required: indicator.required,
    })) || [];

  const handleAfterEditRedirect = () => {
    if (location?.state?.deal)
      return history.push(`/deals/${location?.state?.deal}`);
    if (location?.state?.investment)
      return history.push(`/investments/${location?.state?.investment}`);
    if (location?.state?.backtest)
      return history.push(`/backtests/${location?.state?.backtest}`);
    if (location?.state?.genetic)
      return history.push(`/genetic-algorithm/${location?.state?.genetic}`);
    history.push(`/bots/${botId}`);
  };

  const handleAfterCreateRedirect = botId => {
    setAlertOpen(true);
    setRedirectPath(`/bots/${botId}`);
  };

  const handleSubmitBot = async (values, formik) => {
    const customResErrors = res => {
      formik.setSubmitting(false);
      handleResponse(res, formik.setErrors);
      setIsSubmit(false);
      dispatch(addSnackBar("errors", res.message));
    };
    const { tradeSettings, ...wizardInitData } = wizardMarkets;
    const initBody = {
      ...wizardInitData,
      type: botType,
      minimumTrades: Number(wizardMarkets.minimumTrades) || null,
      volume: Number(wizardMarkets.volume) || null,
      spreadPercent: Number(wizardMarkets.spreadPercent) || null,
      markPriceDifference: Number(wizardMarkets.markPriceDifference) || null,
      investment: wizardMarkets.investment.id,
      strategy: values?.strategy,
    };
    setIsSubmit(true);
    formik.setSubmitting(true);

    const body = {
      ...initBody,
      ...(botType === "custom"
        ? {
            tradeSettings: {
              ...values,
              indicators: filteredIndicators,
              candleConditions: tradeSettings?.candleConditions,
            },
          }
        : null),
    };

    const res = isEdit
      ? await dispatch(editBot(botId, body))
      : await dispatch(createBot(body));

    if (res.errors) {
      customResErrors(res);
    } else {
      if (isEdit) {
        formik.setStatus({ message: res.message });
        handleAfterEditRedirect();
      } else {
        handleAfterCreateRedirect(res.item.id);
        formik.setStatus({ message: res.message });
      }
    }

    resetWizardIndicators();
    formik.setSubmitting(false);
  };

  return { handleSubmitBot, filteredIndicators, handleAfterEditRedirect };
};
