import React, { useLayoutEffect } from "react";
import { Card } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as BarChart } from "assets/icons/general/barChart.svg";
import Indicator from "../../ui/Indicator";
import IndicatorsPreloader from "../../ui/preloaders/IndicatorsPreloader";
import { fetchIndicators } from "store/deals/actions";
import { selectDealsIndicators } from "store/deals/selectors";

const Indicators = ({ id, status }) => {
  const dispatch = useDispatch();

  const { data, loading } = useSelector(selectDealsIndicators);

  useLayoutEffect(() => {
    dispatch(fetchIndicators(id));
  }, [dispatch, id]);

  if (loading) return <IndicatorsPreloader />;

  if (!data?.items?.length) return;

  if (status === "ready" || status === "running")
    return (
      <Card className='mb-4 pt-4 pb-0'>
        <div className='d-flex mb-4 px-4' style={{ lineHeight: "19px" }}>
          <BarChart className='my-auto mr-2' />
          <p className='font-weight-500 text-white m-0'>
            Indicators and provided signals
          </p>
        </div>
        {data?.items?.map(indicator => (
          <Indicator key={indicator.id} indicator={indicator} />
        ))}
      </Card>
    );
};

export default Indicators;
