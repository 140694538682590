import React from "react";
import styled from "styled-components";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import { Card, CardBody } from "reactstrap";
import { times } from "lodash";

import DefaultHeaderPreloader from "./DefaultHeaderPreloader";
import MarketHeaderPreloader from "./MarketHeaderPreloader";

const BotSettingsWidgetPreloader = ({ defaultHeader }) => {
  return (
    <BotSettingsWidgetPreloader.Card className='p-0'>
      {defaultHeader ? <DefaultHeaderPreloader /> : <MarketHeaderPreloader />}
      <BotSettingsWidgetPreloader.CardBody className='d-flex flex-column p-4 pt-3'>
        {times(4, e => (
          <div key={e}>
            <BotSettingsWidgetPreloader.Box>
              <SkeletonPreloader height={9} width={57} />
              <SkeletonPreloader height={12} width={121} />
            </BotSettingsWidgetPreloader.Box>
            <BotSettingsWidgetPreloader.Box>
              <SkeletonPreloader height={9} width={81} />
              <SkeletonPreloader height={12} width={121} />
            </BotSettingsWidgetPreloader.Box>
          </div>
        ))}
        <BotSettingsWidgetPreloader.Box className='d-flex mt-4 w-100 flex-row justify-content-between'>
          <SkeletonPreloader height={9} width={57} />
          <div className='d-flex flex-row align-items-center'>
            {times(10, e => (
              <BotSettingsWidgetPreloader.Criterion key={e} />
            ))}
          </div>
        </BotSettingsWidgetPreloader.Box>
      </BotSettingsWidgetPreloader.CardBody>
    </BotSettingsWidgetPreloader.Card>
  );
};

BotSettingsWidgetPreloader.Card = styled(Card)`
  border-radius: 8px;
`;

BotSettingsWidgetPreloader.CardBody = styled(CardBody)`
  background: var(--dark-slate) !important;
`;

BotSettingsWidgetPreloader.Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`;

BotSettingsWidgetPreloader.Criterion = styled.div`
  width: 9px;
  height: 14px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 1px;
  margin-right: 2px;
  :last-child {
    margin-right: 0;
  }
`;

export default BotSettingsWidgetPreloader;
