import React from "react";
import styled from "styled-components";
import { CardBody } from "reactstrap";

import TechnicalLog from "./TechnicalLog";
import PaginationPanel from "shared/ui/PaginationPanel";

const TechnicalLogsListModal = ({ data, handlePaginate }) => {
  return (
    <>
      <TechnicalLogsListModal.CardBody>
        {data.items?.map(log => {
          return (
            <div key={log.id} className='mb-3 mb-lg-0'>
              <TechnicalLog log={log} />

              <TechnicalLogsListModal.Share className='d-none d-lg-block'>
                <TechnicalLogsListModal.Line />
              </TechnicalLogsListModal.Share>
            </div>
          );
        })}
      </TechnicalLogsListModal.CardBody>
      <div className={data?.total > 13 ? "pt-3" : ""}>
        <PaginationPanel
          activePage={data?.page}
          totalItemsCount={data?.total}
          onChange={page => handlePaginate(page)}
          totalPagesView={14}
        />
      </div>
    </>
  );
};

TechnicalLogsListModal.CardBody = styled(CardBody)`
  margin: 24px 0 0 0 !important;
  padding: 0 !important;

  div:last-child div span {
    display: none;
  }
`;

TechnicalLogsListModal.Share = styled.div`
  margin: 4px 0 !important;
  padding-left: 23.25%;
`;

TechnicalLogsListModal.Line = styled.span`
  display: block;
  height: 6px;
  width: 2px;
  background: rgba(58, 61, 90, 1);
`;

export default TechnicalLogsListModal;
