import React, { useState } from "react";
import { Card } from "reactstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import Button from "shared/ui/buttons/Button";
import MyAgreementsBody from "./MyAgreementsBody";
import MyAgreementsHeader from "./MyAgreementsHeader";
import MyAgreementsPreloader from "../preloaders/MyAgreementsPreloader";
import { acceptSignal } from "store/signals/action";
import { addSnackBar } from "store/snackbar/actions";
import { ReactComponent as TickSquare } from "assets/icons/checkbox/tick.svg";

const MyAgreements = ({ signalId, agreements, loading, status }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleAcceptSignal = async signalId => {
    setIsSubmitted(true);
    const res = await dispatch(acceptSignal(signalId));
    if (!res.errors) {
      setIsSubmitted(false);
      return dispatch(addSnackBar("success", res.message));
    }
    setIsSubmitted(false);
    return dispatch(addSnackBar("error", res.message));
  };

  if (loading) {
    return (
      <MyAgreements.WrapEmpty className='flex-row align-items-center justify-content-between py-3 px-4 mb-2'>
        <MyAgreementsPreloader />
      </MyAgreements.WrapEmpty>
    );
  }

  if (!agreements?.length) {
    return (
      <MyAgreements.WrapEmpty className='flex-row align-items-center justify-content-between py-3 px-4 mb-2'>
        <TickSquare className='mr-1' />
        <p className='mb-0 font-weight-500 text-white-35 ml-2 mr-auto'>
          No agreements
        </p>
        {status !== "completed" ? (
          <Button
            color='blue'
            className='px-3 py-2 ml-auto mr-0'
            loading={isSubmitted}
            disabled={isSubmitted}
            onClick={() => handleAcceptSignal(signalId)}
          >
            Accept
          </Button>
        ) : null}
      </MyAgreements.WrapEmpty>
    );
  }

  return agreements?.map((agreement, index) => (
    <MyAgreements.Wrap
      className={`pb-3 px-4 ${index === agreements?.length - 1 ? "mb-2" : "mb-0"}`}
      isLast={index === agreements?.length - 1}
      key={agreement?.id}
      onClick={() => history.push(`/agreements/${agreement?.id}`)}
      onMouseDown={e =>
        e.button === 1 && window.open(`/agreements/${agreement?.id}`, "_blank")
      }
    >
      <MyAgreementsHeader
        acceptedAt={agreement?.createdAt}
        status={agreement?.status}
        isList
      />
      <MyAgreementsBody
        quote={agreement?.market?.quote}
        base={agreement?.market?.base}
        invested={agreement?.invested}
        purchased={agreement?.purchased}
        received={agreement?.received}
        sold={agreement?.sold}
        unsold={agreement?.unsold}
        profit={agreement?.profit}
        profitPercent={agreement?.profitPercent}
        isList
      />
    </MyAgreements.Wrap>
  ));
};

MyAgreements.WrapEmpty = styled(Card)`
  background: #2e3148 !important;
  padding-top: 1.25rem;

  &.card {
    border-radius: 0 0 0.5rem 0.5rem !important;
  }
`;

MyAgreements.Wrap = styled(Card)`
  background: #2e3148 !important;
  padding-top: 1.25rem;
  cursor: pointer;

  &.card {
    border-radius: ${props =>
      props.isLast ? "0 0 0.5rem 0.5rem" : 0} !important;
  }

  &:hover {
    background: #363a54 !important;
  }
`;

MyAgreements.propTypes = {
  loading: PropTypes.bool,
  status: PropTypes.string,
};

export default MyAgreements;
