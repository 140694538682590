import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Tooltip } from "reactstrap";

import { ReactComponent as Ellipse } from "assets/icons/general/ellipse.svg";
import { ReactComponent as Clock } from "assets/icons/general/clock.svg";
import InvestmentWidgetPreloader from "views/bots/preloaders/InvestmnetnWidgetPreloader";
import LookupCoins from "../../utils/LookupCoins";
import { formatterToNull } from "helpers/valuesFormatters";
import { investedPercentColors } from "helpers/investedPercentColors";
import DealsProgress from "./DealsProgress";
import PercentBadge from "../PercentBadge";
import LineWithError from "../LineWithError";
import Icon from "../Icon";

const Investment = ({
  investment = {},
  runningDeals,
  loading,
  showDealsCount,
}) => {
  const [isShowingTooltip, setIsShowingTooltip] = useState(false);

  const toggleDailyRevenue = () => setIsShowingTooltip(prev => !prev);

  if (loading) return <InvestmentWidgetPreloader />;

  return (
    <Link to={`/investments/${investment.id}`}>
      <Investment.Card>
        <Investment.Header className='d-flex align-items-center px-4 py-3'>
          <div className='d-flex align-items-center position-relative'>
            <Icon
              type='cryptocurrency'
              size='xxs'
              code={investment.asset}
              className='mr-3 my-auto'
            />
            <Investment.IconWrapper className='borderStatus'>
              {investment.canTrade ? (
                <Ellipse data-testid='ellipse' />
              ) : (
                <Clock data-testid='clock' />
              )}
            </Investment.IconWrapper>
          </div>
          <div className='d-flex justify-content-between w-100 overflow-hidden'>
            <p className='h3 mb-0 text-white'>
              {investment.asset}{" "}
              <span className='text-white-35 font-weight-300'>
                / <LookupCoins coin={investment.asset} />
              </span>
            </p>
            <PercentBadge
              showZeroValue
              value={investment.dailyRevenuePercent}
              id='investment-daily-revenue'
              data-testid='dailyRevenue'
            />
            {investment.dailyRevenuePercent ? (
              <Tooltip
                target='investment-daily-revenue'
                placement='top'
                isOpen={isShowingTooltip}
                toggle={toggleDailyRevenue}
                className='text-nowrap'
                role='tooltip'
              >
                Daily revenue
              </Tooltip>
            ) : null}
          </div>
        </Investment.Header>

        <LineWithError radius='0' errorMessage={investment.error} />

        <CardBody className='pt-3 px-4 pb-4'>
          <div
            className='d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between
            align-items-sm-center align-items-md-start align-items-lg-center'
          >
            <Investment.Title>Available</Investment.Title>
            <div className='d-flex align-items-center'>
              <Investment.Amount>
                {formatterToNull(investment.balance?.available?.total)}
              </Investment.Amount>
              <Investment.Currency>
                {investment.balance?.available?.currency}
              </Investment.Currency>
            </div>
          </div>

          <div
            className='d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between
            align-items-sm-center align-items-md-start align-items-lg-center mt-2 text-nowrap'
          >
            <Investment.Title>Invested</Investment.Title>
            <div className='d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-lg-center'>
              <span
                className={`${investedPercentColors(investment.balance?.invested?.percent)} pr-2`}
              >
                {`${investment.balance?.invested?.percent || 0} % `}
              </span>
              <div className='d-flex'>
                <Investment.Amount>
                  {formatterToNull(investment.balance?.invested?.amount)}
                </Investment.Amount>
                <Investment.Currency>
                  {investment.balance?.invested?.currency}
                </Investment.Currency>
              </div>
            </div>
          </div>
          {investment?.balance?.onOrders?.total > 0 && (
            <div
              className='d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between
              align-items-sm-center align-items-md-start align-items-lg-center mt-2'
            >
              <Investment.Title>On orders</Investment.Title>
              <div className='d-flex align-items-center'>
                <Investment.Amount>
                  {formatterToNull(investment.balance?.onOrders?.total)}
                </Investment.Amount>
                <Investment.Currency>
                  {investment.balance?.onOrders?.currency}
                </Investment.Currency>
              </div>
            </div>
          )}
          {investment?.balance?.hold?.total > 0 && (
            <div
              className='d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between
              align-items-sm-center align-items-md-start align-items-lg-center mt-2'
            >
              <Investment.Title>Hold</Investment.Title>
              <div className='d-flex align-items-center'>
                <Investment.Amount>
                  {formatterToNull(investment.balance?.hold?.total)}
                </Investment.Amount>
                <Investment.Currency>
                  {investment.balance?.hold?.currency}
                </Investment.Currency>
              </div>
            </div>
          )}

          <div
            className='d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between
            align-items-sm-center align-items-md-start align-items-lg-center mt-2'
          >
            <Investment.Title>Estimated</Investment.Title>
            <div className='d-flex align-items-center'>
              <Investment.Amount>
                {formatterToNull(investment.balance?.estimated?.total)}
              </Investment.Amount>
              <Investment.Currency>
                {investment.balance?.estimated?.currency}
              </Investment.Currency>
            </div>
          </div>
          {showDealsCount ? (
            <DealsProgress
              runningDeals={runningDeals}
              maxDeals={investment.maxDeals}
            />
          ) : null}
        </CardBody>
      </Investment.Card>
    </Link>
  );
};

Investment.Card = styled(Card)`
  cursor: pointer;
  margin-bottom: 24px !important;

  &:hover {
    background-color: rgba(46, 49, 72, 1) !important;

    .borderStatus {
      svg {
        border: 3px solid #363a54;
        background: #363a54;
      }
    }

    .card-header {
      background: #363a54 !important;
    }
  }
`;

Investment.Header = styled(CardHeader)`
  background: #2e3148 !important;
  border-radius: 8px 8px 0 0 !important;
  cursor: pointer;
`;

Investment.Title = styled.p`
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.75) !important;
  font-weight: 300;
  font-size: 0.875rem;
`;

Investment.Amount = styled.p`
  margin-bottom: 0;
  color: #ffffff !important;
  font-size: 16px;
  font-weight: 500;
`;

Investment.Currency = styled.p`
  margin-bottom: 0;
  margin-left: 4px;
  color: rgba(255, 255, 255, 0.35) !important;
  font-size: 16px;
  font-weight: 300;
`;

Investment.IconWrapper = styled.div`
  svg {
    position: absolute;
    top: 55% !important;
    right: 20% !important;
    background: #2e3148;
    border: 3px solid #2e3148;
    border-radius: 100px;
    box-sizing: initial;

    path {
      stroke: rgba(255, 255, 255, 0.35);
      stroke-opacity: 1;
    }
  }
`;

export default Investment;
