import React from "react";
import { Formik } from "formik";

import RenderBotTab from "../wizard/Configuration/RenderBotTab";
import TradeSettingsPreloader from "../wizard/Configuration/TradeSettingsPreloader";
import useCalculatedSignals from "hooks/useCalculatedISignals";
import { handleBotSchema } from "./editBotConfiguration";
import EditBotHeader from "./EditBotHeader";
import SelectBotType from "../wizard/Configuration/SelectBotType";
import { Button } from "shared/ui/buttons";
import { useCreateBotSubmit } from "shared/entities/bot/model/useCreateBotSubmit";
import { useEditBotContext } from "context/bot/EditBotContext";

const EditBotSettings = ({ setIsSubmit, isSubmit }) => {
  const {
    botData,
    loading,
    botType,
    setBotType,
    initialValues,
    currentPlan,
    submitted,
    setSubmitted,
    indicators,
    isOpenSettings,
    wizardMarkets,
  } = useEditBotContext();
  const {
    handleSubmitBot,
    filteredIndicators,
    handleAfterEditRedirect: handleRedirect,
  } = useCreateBotSubmit({
    wizardMarkets: wizardMarkets,
    botType: botType,
    indicators: [...(botData?.tradeSettings?.indicators || []), ...indicators],
    setIsSubmit: setIsSubmit,
    botId: botData?.id,
    isEdit: true,
  });

  const { buySignals, sellSignals } = useCalculatedSignals(
    botData,
    filteredIndicators,
  );

  if (loading && !isSubmit) return <TradeSettingsPreloader />;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={handleBotSchema(botType, buySignals, sellSignals)}
      onSubmit={handleSubmitBot}
    >
      {({ isSubmitting, handleSubmit, values }) => (
        <>
          <EditBotHeader />
          <div className='p-sm'>
            <SelectBotType setBotType={setBotType} botType={botType} />
            <RenderBotTab
              isEdit
              buySignals={buySignals}
              sellSignals={sellSignals}
              botData={botData}
              loadingWizard={loading}
              submitted={submitted}
              setSubmitted={setSubmitted}
              botIndicators={botData?.tradeSettings?.indicators || []}
              editSelectedInvestment={wizardMarkets?.investment}
              editPeriod={wizardMarkets?.period}
              type={botType}
              currentPlan={currentPlan}
              candleConditions={wizardMarkets?.tradeSettings?.candleConditions}
            />
            <div className='d-flex flex-column flex-sm-row justify-content-between py-4'>
              <Button color='gray' outline onClick={handleRedirect}>
                Cancel
              </Button>
              <Button
                color='blue'
                className='mt-2 mt-sm-0'
                loading={isSubmitting}
                disabled={
                  (currentPlan?.type !== "premium" && botType === "genetic") ||
                  isOpenSettings
                }
                onClick={() => {
                  setSubmitted(true);
                  handleSubmit(values);
                }}
                type='submit'
              >
                Save changes
              </Button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default EditBotSettings;
