import React, { useLayoutEffect, useRef } from "react";
import { Card } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getGeneticStrategiesTable } from "store/bots/actions";
import { geneticStrategiesTable } from "store/bots/selectors";

import PaginationPanel from "shared/ui/PaginationPanel";
import GSPreloader from "./GSPreloader";
import GSHeader from "./GSHeader";
import GSTable from "./GSTable";
import GSEmpty from "./GSEmpty";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";

const GeneticStrategies = ({ botId }) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef();

  const { data, loading } = useSelector(geneticStrategiesTable);

  const { handleFilter, handleSorting, handlePaginate } = useFiltersWithRequest(
    data?.request,
    getGeneticStrategiesTable,
    botId,
  );

  useLayoutEffect(() => {
    dispatch(getGeneticStrategiesTable(botId));
  }, [botId, dispatch]);

  if (!data?.items && !loading && !data?.request?.filter) {
    return (
      <Card>
        <GSEmpty
          title='Looks like there are no strategies yet '
          description="Don't worry though, hang tight and wait for them to be executed automatically"
        />
      </Card>
    );
  }

  return (
    <Card className='mb-4'>
      <div ref={wrapperRef}>
        <GSHeader
          botId={botId}
          handleFilter={handleFilter}
          request={data?.request}
          loading={loading}
        />
        {loading ? (
          <GSPreloader withTable />
        ) : (
          <GSTable
            items={data?.items}
            request={data?.request}
            handleSort={handleSorting}
          />
        )}
        <div className={data.total > 10 ? "mt-3" : ""}>
          <PaginationPanel
            activePage={data.page}
            totalItemsCount={data.total}
            onChange={page => handlePaginate(page)}
            className='m-0'
            elementRef={wrapperRef}
          />
        </div>
      </div>
    </Card>
  );
};

export default GeneticStrategies;
