import React, { useMemo, useState } from "react";

import { formatPluralSingular } from "helpers/valuesFormatters";
import { ChevronIcon, IndicatorList, MinSignalDisplay } from "shared/ui";
import CollapsibleContainer from "shared/styled/CollapsibleContainer";

const BotIndicators = ({
  indicators = [],
  indicatorName,
  minSellSignals,
  minBuySignals,
}) => {
  const [showIndicators, setShowIndicators] = useState(true);

  const indicatorLabels = useMemo(() => {
    return indicatorName?.reduce((acc, indicator) => {
      acc[indicator?.value] = indicator?.label;
      return acc;
    }, {});
  }, [indicatorName]);

  const toggleShowIndicators = () => setShowIndicators(prev => !prev);

  if (!indicators?.length) return null;
  return (
    <>
      <div
        data-testid='bot-indicators-title'
        className='d-flex justify-content-between mt-4 mb-2'
      >
        <p className='mb-0 text-white font-weight-500'>
          {formatPluralSingular(indicators?.length, "Indicator", "Indicators")}
        </p>
        <ChevronIcon rotated={showIndicators} onClick={toggleShowIndicators} />
      </div>
      <CollapsibleContainer
        data-testid='indicator-list'
        isOpen={showIndicators}
      >
        <MinSignalDisplay type='BUY' signals={minBuySignals} />
        <MinSignalDisplay type='SELL' signals={minSellSignals} />
        <IndicatorList
          indicators={indicators}
          indicatorLabels={indicatorLabels}
        />
      </CollapsibleContainer>
    </>
  );
};

export default BotIndicators;
