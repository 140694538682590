import React, { useState } from "react";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import classNames from "classnames";
import { Card, CardHeader, CardBody } from "reactstrap";
import { useSelector } from "react-redux";

import { balanceHistory } from "charts";
import EmptyDashboardWidgets from "../compounds/EmptyDashboadWidgets";
import { selectWizard } from "store/user/selectors";
import { fetchBalanceHistory } from "store/dashboard/actions";
import { CHART_GROUP_BUTTONS } from "charts/enum";
import EmptyChart from "assets/img/chart/EmptyChart.png";
import { ReactComponent as BalanceIcon } from "assets/icons/general/orders.svg";
import { ReactComponent as Empty } from "assets/icons/general/greenPlanet.svg";
import { ReactComponent as DashboardBalanceChartPreload } from "assets/icons/general/dashboard-balance-chart-preload.svg";
import { ButtonsGroup, SkeletonPreloader } from "shared/ui";

const BalanceHistory = ({
  balanceHistoryData = {},
  loading,
  currency = "",
  content,
}) => {
  const [period, setPeriod] = useState("day");
  const loadingChart = useSelector(state => state.dashboard.loadingChart);
  const { credentials = false } = useSelector(selectWizard);

  const renderEmptyState = (totalDataChart, balanceHistoryData) => {
    if (
      credentials &&
      !totalDataChart &&
      !Object.keys(balanceHistoryData)?.length
    ) {
      return (
        <EmptyDashboardWidgets
          isNoDataToShow
          title={content.emptyState.isNoData.title}
          descriptionBefore={content.emptyState.isNoData.descriptionBefore}
        />
      );
    }

    if (!totalDataChart && !Object.keys(balanceHistoryData)?.length) {
      return (
        <EmptyDashboardWidgets
          Img={Empty}
          title={content.emptyState.connectExchange.title}
          descriptionBefore={
            content.emptyState.connectExchange.descriptionBefore
          }
          link={content.emptyState.connectExchange.link}
          descriptionAfter={content.emptyState.connectExchange.descriptionAfter}
        />
      );
    }
  };

  if (loading) {
    return (
      <BalanceHistory.ChartCard className='p-4'>
        <div className='d-flex align-items-center mb-4'>
          <SkeletonPreloader circle={true} height={18} width={18} />
          <SkeletonPreloader height={12} width={111} className='ml-2' />
        </div>
        <DashboardBalanceChartPreload width='100%' height='100%' />
      </BalanceHistory.ChartCard>
    );
  }

  return (
    <BalanceHistory.ChartCard
      className={classNames("card-chart", {
        empty: !(
          loading ||
          Object.keys(balanceHistoryData).length ||
          credentials.length
        ),
      })}
    >
      <CardHeader className='d-flex flex-column flex-md-row justify-content-between px-4 py-3 '>
        <div className='d-flex align-items-center mb-2 mb-md-0'>
          <BalanceHistory.Icon>
            <BalanceIcon />
          </BalanceHistory.Icon>
          <p className='ml-2 mb-0 text-white font-weight-500 text-nowrap'>
            Balance history
          </p>
        </div>
        {Object.keys(balanceHistoryData)?.length ? (
          <ButtonsGroup
            group={CHART_GROUP_BUTTONS}
            current={period}
            setCurrent={setPeriod}
            callFunc={fetchBalanceHistory}
            disable={loadingChart}
          />
        ) : null}
      </CardHeader>
      <CardBody className='pt-3 pb-3 px-3'>
        {loadingChart ? (
          <DashboardBalanceChartPreload width='100%' height='100%' />
        ) : (
          <div className='chart-area h-100'>
            {Object.values(balanceHistoryData)?.length ? (
              <Line
                data={balanceHistory(balanceHistoryData, period, currency).data}
                options={
                  balanceHistory(balanceHistoryData, period, currency).options
                }
              />
            ) : (
              renderEmptyState(
                Object.values(balanceHistoryData)?.length,
                balanceHistoryData,
              )
            )}
          </div>
        )}
      </CardBody>
    </BalanceHistory.ChartCard>
  );
};

BalanceHistory.ChartCard = styled(Card)`
  padding: 0;
  margin-bottom: 24px !important;
  height: 338px;

  &.empty {
    background-image: url(${EmptyChart}) !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
`;

BalanceHistory.PreloaderContainer = styled.div`
  min-height: 262px;
`;

BalanceHistory.Icon = styled.div`
  svg {
    path {
      stroke: var(--info);
    }
  }
`;

export default BalanceHistory;
