import React from "react";

import useExchangeWS from "exchanges/useExchangeWS";
import {
  LastTrades,
  MarketInformation,
  OrderBook,
  TradingWithTicker,
} from "shared/ui";

const MarketTab = ({ data, marketInformation, loadingPage }) => {
  const {
    orderBook,
    isWebsocketOpen,
    setGroup,
    group,
    depth,
    handleSetDepth,
    trades,
    ticker,
  } = useExchangeWS(
    data?.bot?.investment?.credentials?.exchange,
    ["orderBook", "ticker", "trades"],
    [`${data?.assets?.base}_${data?.assets?.quote}`],
  );

  return (
    <>
      <MarketInformation
        loadingPage={loadingPage}
        quote={data?.market?.quote}
        base={data?.market?.base}
      />
      <TradingWithTicker
        ticker={ticker[0]}
        loadingPage={loadingPage || trades.isLoading}
        base={data?.assets?.base}
        quote={data?.assets?.quote}
        marketInformation={marketInformation}
        exchange={data?.bot?.investment?.credentials?.exchange}
        interval={data?.bot?.period}
      />

      <OrderBook
        orderBookAsks={orderBook.asks}
        orderBookBids={orderBook.bids}
        spread={orderBook.spread}
        spreadPercent={orderBook.spreadPercent}
        isWebsocketOpen={isWebsocketOpen}
        handleSetDepth={handleSetDepth}
        depth={depth}
        base={data?.market?.base}
        quote={data?.market?.quote}
        setGroup={setGroup}
        group={group}
        loadingPage={loadingPage}
        withSpread
      />

      <LastTrades
        lastTrade={trades.data}
        isTradesLoading={trades.isLoading}
        ticker={ticker[0]}
        market={data?.market}
        quantity
        loadingPage={loadingPage}
      />
    </>
  );
};

export default MarketTab;
