import { useState } from "react";
import { useDispatch } from "react-redux";
import qs from "qs";

const useFiltersWithRequest = (request = {}, callFunc, funcParams) => {
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();

  const handleFilter = value => {
    const filter = { ...(request.filter || {}), ...value };
    setFilters(filter);
    const filters = qs.stringify({
      filter,
      sort: request.sort,
      page: 1,
      limit: request.limit || 10,
    });
    if (funcParams) return dispatch(callFunc(funcParams, `?${filters}`));
    dispatch(callFunc(`?${filters}`));
  };

  const handleSorting = value => {
    const filters = qs.stringify({
      filter: request.filter,
      sort: value,
      limit: request.limit || 10,
    });
    if (funcParams) return dispatch(callFunc(funcParams, `?${filters}`));
    dispatch(callFunc(`?${filters}`));
  };

  const handlePaginate = page => {
    const filters = qs.stringify({
      filter: request.filter,
      sort: request.sort,
      page: page,
      limit: request.limit || 10,
    });
    if (funcParams) return dispatch(callFunc(funcParams, `?${filters}`));
    dispatch(callFunc(`?${filters}`));
  };

  return {
    handleFilter,
    handleSorting,
    handlePaginate,
    filters,
  };
};

export default useFiltersWithRequest;
