import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ReactComponent as Warning } from "assets/icons/warning-triangle-icon.svg";
import { addSnackBar } from "store/snackbar/actions";
import { ConfirmationDeleteModal, ConfirmationModal } from "./ui";
import { Button } from "shared/ui/buttons";
import { DangerZonePreloader, ToggleCard } from "shared/ui";

const DangerZone = ({
  id,
  loading,
  actions,
  content,
  status,
  isDelete,
  onHandleRefresh,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fieldValue, setFieldValue] = useState("");
  const [correctError, setCorrectError] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [load, setLoad] = useState(false);
  const [modalType, setModalType] = useState("");

  if (loading) return <DangerZonePreloader />;

  const handleComplete = async (id, modalType) => {
    setLoad(true);

    const res = await dispatch(content.actions[modalType].actionFunction(id));

    if (!res.errors) {
      dispatch(addSnackBar("success", res.message));
      setLoad(false);
      setModalIsOpen(false);
      onHandleRefresh();
    } else {
      dispatch(addSnackBar("error", res.message));
      setLoad(false);
      setModalIsOpen(false);
    }
  };

  const handleDelete = async () => {
    setLoad(true);
    setIsSubmitted(true);
    if (fieldValue.toUpperCase() === "DELETE") {
      const res = await dispatch(content.actions.delete.actionFunction(id));
      setCorrectError("");
      setLoad(false);
      if (res?.errors) setErrorMessage(res.message);
      if (!res?.errors) {
        dispatch(addSnackBar("success", res.data.message));
        history.push(content.actions.delete.pathOnSucceess);
      }
    } else {
      setCorrectError(content.errorMessage);
      setErrorMessage(null);
      setLoad(false);
    }
  };

  const handleClose = () => {
    setModalIsOpen(!modalIsOpen);
    setCorrectError("");
    setErrorMessage(null);
    setLoad(false);
    setFieldValue("");
  };

  const handleChange = ({ target }) => {
    if (!target?.value) return setCorrectError(content.errorMessage);
    setCorrectError("");
    setFieldValue(target?.value);
  };

  const handleOpenModal = type => {
    setModalIsOpen(true);
    setModalType(type);
  };

  return (
    <>
      <ToggleCard
        header={
          <div className='d-flex align-items-center'>
            <Warning />
            <p
              data-testid='danger-zone-title'
              className='mb-0 text-white ml-3 font-weight-500'
            >
              {content.title}
            </p>
          </div>
        }
      >
        <p className='mb-0 font-sm' data-testid='danger-zone-subtitle'>
          {content.subtitle}
        </p>
        {isDelete ? (
          <div className='d-flex justify-content-end mt-3'>
            <Button
              data-testid='danger-zone-btn-delete'
              color='red'
              onClick={() => setModalIsOpen(!modalIsOpen)}
            >
              Delete
            </Button>
          </div>
        ) : (
          <DangerZone.Force className='d-flex flex-wrap mt-3'>
            {Object.keys(content.actions).map(action => (
              <Button
                key={`danger-zone-btn-${action}`}
                data-testid={`danger-zone-btn-${action}`}
                color={action === "closed" ? "success" : "red"}
                disabled={
                  (status ? status !== action : !actions?.[action]) ||
                  modalIsOpen
                }
                onClick={() => handleOpenModal(action)}
              >
                {content.actions[action].name}
              </Button>
            ))}
          </DangerZone.Force>
        )}
      </ToggleCard>
      {isDelete ? (
        <ConfirmationDeleteModal
          modalIsOpen={modalIsOpen}
          load={load}
          content={content}
          handleDelete={handleDelete}
          handleClose={handleClose}
          handleChange={handleChange}
          errorMessage={errorMessage}
          isSubmitted={isSubmitted}
          correctError={correctError}
          setErrorMessage={setErrorMessage}
        />
      ) : (
        <ConfirmationModal
          id={id}
          modalIsOpen={modalIsOpen}
          closeModal={handleClose}
          load={load}
          handleComplete={handleComplete}
          modalType={modalType}
          content={content}
        />
      )}
    </>
  );
};

DangerZone.Force = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  > :nth-child(1),
  > :nth-child(2) {
    flex: 1 1 calc(50% - 0.5rem);
  }

  > :nth-child(3) {
    flex: 1 1 100%;
  }
`;

export default DangerZone;
