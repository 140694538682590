import classNames from "classnames";
import React from "react";
import styled from "styled-components";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

import { formatterToNull } from "helpers/valuesFormatters";
import { ReactComponent as Trash } from "assets/icons/trash-red-icon.svg";
import { NumberInput } from "shared/ui";

const TargetSignal = ({
  label,
  name,
  targetPercent,
  placeholder,
  isSubmitted,
  errors,
  arrayHelpers,
  index,
  isHasChannels,
}) => {
  const { setFieldValue, values } = useFormikContext();

  return (
    <>
      <TargetSignal.Wrap className='d-flex align-items-center'>
        <div className='d-flex align-items-center p-0 col-10 col-md-11'>
          <TargetSignal.Label className='text-white font-weight-400 font-md p-2'>
            {label}
          </TargetSignal.Label>
          <TargetSignal.Field
            disabled={isHasChannels}
            value={values?.targets?.[index].rate}
            name={name}
            placeholder={placeholder}
            onChange={e => setFieldValue(`targets.${[index]}.rate`, e)}
            className={classNames(
              "form-control",
              {
                "has-error": isSubmitted && errors?.targets?.[index],
              },
              "mb-0 rounded-right text-white font-weight-500 font-md",
            )}
          />
          <span className='text-white-35 font-weight-400 font-md p-2 position-absolute right-0'>
            {formatterToNull(targetPercent).toFixed(2)}%
          </span>
        </div>
        {index !== 0 && (
          <TargetSignal.Trash
            onClick={() => arrayHelpers.remove(index)}
            className='mx-1 p-0 col-2 col-md-1'
          />
        )}
      </TargetSignal.Wrap>
      {isSubmitted && errors?.targets?.[index]?.rate && (
        <p className='text-danger font-sm font-weight-300 m-0'>
          {errors?.targets?.[index]?.rate}
        </p>
      )}
    </>
  );
};

TargetSignal.Wrap = styled.div`
  margin-bottom: 0.375rem !important;
`;

TargetSignal.Label = styled.span`
  position: absolute;
  border-right: 1px solid #44486a;
`;

TargetSignal.Field = styled(NumberInput)`
  &.form-control {
    padding: 0.6875rem 3rem !important;
  }
`;

TargetSignal.Trash = styled(Trash)`
  cursor: pointer;

  &:hover {
    transform: scale(0.9);
  }
`;

TargetSignal.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  targetPercent: PropTypes.number,
  placeholder: PropTypes.string,
  isSubmitted: PropTypes.bool,
  isHasChannels: PropTypes.bool,
  errors: PropTypes.shape({
    buyFrom: PropTypes.string,
    buyTo: PropTypes.string,
    stopLoss: PropTypes.string,
    targets: PropTypes.arrayOf(PropTypes.string),
  }),
  arrayHelpers: PropTypes.shape({
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    insert: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }),
  index: PropTypes.number,
};

export default TargetSignal;
