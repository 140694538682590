import styled from "styled-components";

export const StyledAddButton = styled.button`
  cursor: pointer;
  height: 50px;
  border-radius: 0.5rem;
  background: ${props =>
    props.bgButton ? `var(--${props.bgButton})` : "transparent"} !important;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &.gray-outline {
    border: 1px dashed var(--white-10) !important;
  }

  svg {
    path {
      stroke: var(--backgrounds-and-shades-5);
      stroke-opacity: 1;
      transition: stroke 0.3s ease;
    }
  }

  ${props =>
    props.addHover &&
    `
    &:hover {
      background-color: var(--white-10) !important;
      transition: background-color 0.3s ease; /* Додаємо плавну зміну фону */

      span {
        color: var(--info) !important;
        transition: color 0.3s ease; /* Плавна зміна кольору тексту */
      }

      svg {
        path {
          stroke: var(--info);
          stroke-opacity: 1;
        }
      }
    }
  `}
`;
