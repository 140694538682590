import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, every } from "lodash";
import { Row, Col } from "reactstrap";

import CriteriasSection from "./CriteriasSection";
import { selectGA } from "store/bots/selectors";
import {
  getCriteriasInitialState,
  getCofigInitialState,
} from "../../bots/utils";
import GAConfigForm from "./GAConfigForm";
import { updateGeneticAlgorithm } from "store/bots/actions";
import { addSnackBar } from "store/snackbar/actions";
import { ErrorAlert, InfoNotification } from "shared/ui";
import { Button } from "shared/ui/buttons";

const INFO_TEXT =
  "Important Reminder: Modifying these settings will have an impact on all existing genetic strategies. Please keep this in mind before making any changes.";

const GAsettingsForm = ({ setIsModalOpen, bot = {}, criterion }) => {
  const geneticStatistic = useSelector(selectGA);
  const [criteriasValues, setCriteriasValues] = useState(
    getCriteriasInitialState(criterion),
  );
  const [configValues, setConfigValues] = useState(getCofigInitialState(bot));
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (configValues.generationSize >= 5 && errors.generationSize)
      setErrors(prev => ({
        ...prev,
        generationSize: "",
      }));
    if (configValues.populationFilter && errors.populationFilter)
      setErrors(prev => ({
        ...prev,
        populationFilter: "",
      }));
    if (configValues.generationSize < 5)
      setErrors(prev => ({
        ...prev,
        generationSize: "the value should be greater than 5",
      }));
    if (configValues.mutationProbability && errors.mutationProbability)
      setErrors(prev => ({
        ...prev,
        mutationProbability: "",
      }));
    if (!configValues.populationFilter)
      setErrors(prev => ({
        ...prev,
        populationFilter: "the value should be greater than 0",
      }));
    if (!configValues.mutationProbability)
      setErrors(prev => ({
        ...prev,
        mutationProbability: "This value should be greater 0",
      }));
  }, [
    configValues,
    errors.generationSize,
    errors.populationFilter,
    errors.mutationProbability,
  ]);

  const handleToggleCriteria = code => {
    setCriteriasValues(prev =>
      prev.map(item => {
        if (item.code === code) return { ...item, enabled: !item.enabled };
        return item;
      }),
    );
  };

  const handleChangePower = (code, value) => {
    setCriteriasValues(prev =>
      prev.map(item => {
        if (item.code === code) return { ...item, power: value };
        return item;
      }),
    );
  };

  const handleSubmit = async () => {
    if (!every(errors, isEmpty)) return;
    setIsLoading(true);
    const reqBody = {
      criterion: criteriasValues
        .filter(c => c.enabled && c.power)
        .map(criteria => {
          const { rest } = criteria;
          return rest;
        }),
      ...configValues,
    };
    const res = await dispatch(updateGeneticAlgorithm(bot.id, reqBody));
    if (res.errors) {
      setErrorMessage(res.message);
    } else {
      dispatch(addSnackBar("success", res.message));
      setIsModalOpen(false);
    }
    setIsLoading(false);
  };

  const handleSetConfigValues = (name, value) =>
    setConfigValues(prev => ({
      ...prev,
      [name]: value,
    }));

  return (
    <>
      <Row>
        <Col xs='12'>
          <InfoNotification text={INFO_TEXT} className='mb-4' />
        </Col>
        <Col xs='12' md='6' className='pt-2'>
          <CriteriasSection
            criteriasValues={criteriasValues}
            handleToggleCriteria={handleToggleCriteria}
            handleChangePower={handleChangePower}
          />
        </Col>
        <Col xs='12' md='6' className='pt-2'>
          <GAConfigForm
            handleSetConfigValues={handleSetConfigValues}
            values={configValues}
            generation={geneticStatistic.generation}
            errors={errors}
          />
        </Col>
      </Row>
      <ErrorAlert isOpen={!!errorMessage} toggle={() => setErrorMessage("")}>
        {errorMessage}
      </ErrorAlert>
      <div className='d-flex w-100 justify-content-end mt-3'>
        <div className='d-flex'>
          <Button
            color='gray'
            type='button'
            outline
            onClick={() => setIsModalOpen(false)}
            className='w-100 mr-2'
          >
            Cancel
          </Button>
          <Button
            color='blue'
            className='w-100'
            onClick={handleSubmit}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default GAsettingsForm;
