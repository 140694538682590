import React from "react";
import { CardBody, Col, Card } from "reactstrap";
import _ from "lodash";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const RoundsLoading = () => {
  return _.times(6, e => (
    <Card key={e} className='my-1 mx-0'>
      <CardBody className='p-4'>
        <div className='d-flex flex-column flex-md-row align-items-center'>
          <div className='d-flex'>
            <Col xs='2' md='1'>
              <SkeletonPreloader height={24} width={24} circle={true} />
            </Col>
            <Col xs='3' md='1'>
              <SkeletonPreloader height={24} width={48} borderRadius='6px' />
            </Col>
          </div>
          <div className='d-flex flex-wrap'>
            <Col md='2' xs='6'>
              <SkeletonPreloader height={14} width={95} />
            </Col>
            <Col md='2' xs='6'>
              <SkeletonPreloader height={14} width={95} />
            </Col>
            <Col md='2' xs='6'>
              <SkeletonPreloader height={14} width={95} />
            </Col>
            <Col md='2' xs='6'>
              <SkeletonPreloader height={12} width={95} />
            </Col>
          </div>
        </div>
      </CardBody>
    </Card>
  ));
};

export default RoundsLoading;
