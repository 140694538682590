import { get } from "lodash";
import {
  DELETE_NOTIFICATION,
  REMOVE_DELETED_NOTIFICATION,
  GET_NOTIFICATIONS_LOADING,
  GET_NOTIFICATIONS_FAILURE,
  LOAD_MORE_NOTIFICATIONS,
  DELETE_ALL_NOTIFICATION,
  RESET_NOTIFICATIONS,
  DELETE_NOTIFICATION_LOADING,
} from "./constants";
import createAction from "store/thunkFactory";

export const fetchNotifications = filters =>
  createAction("NOTIFICATIONS", fetch => {
    return fetch.get(`/app/notifications?${filters}`);
  });

export const loadMoreNotifications =
  (offset, filter) => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.get(
        `/app/notifications?${filter ? filter + "&" : ""}offset=${offset}`,
      );
      dispatch({ type: LOAD_MORE_NOTIFICATIONS, payload: res.data.items });
      return res.data;
    } catch (e) {
      dispatch({ type: GET_NOTIFICATIONS_FAILURE, payload: e.message });
    }
  };

export const deleteNotification = id => async (dispatch, getState, fetch) => {
  try {
    dispatch({ type: DELETE_NOTIFICATION_LOADING });
    await fetch.delete(`/app/notifications/${id}`);
    dispatch({ type: DELETE_NOTIFICATION, payload: id });
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const deleteAllNotifications =
  () => async (dispatch, getState, fetch) => {
    try {
      dispatch({ type: GET_NOTIFICATIONS_LOADING });
      const res = await fetch.delete("/app/notifications?all=true");
      dispatch({ type: DELETE_ALL_NOTIFICATION, payload: res.data });
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const viewAllNotifications = () => async (dispatch, getState, fetch) => {
  try {
    await fetch.patch("/app/notifications/view/all");
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const removeDeletedNotification = id => {
  return { type: REMOVE_DELETED_NOTIFICATION, payload: id };
};

export const resetNotifications = () => ({ type: RESET_NOTIFICATIONS });
