import React, { useEffect, useState } from "react";
import {
  getCurrentImageSrc,
  initialStrategyImage,
} from "views/strategies/utils/defaultImages";
import styled from "styled-components";

const StrategyImage = ({ strategy, height }) => {
  const [strategyImage, setStrategyImage] = useState(initialStrategyImage);

  useEffect(() => {
    if (strategy.images) {
      setStrategyImage(getCurrentImageSrc(strategy));
    }
  }, [strategy]);

  return <StrategyImage.Img image={strategyImage.image} height={height} />;
};

StrategyImage.Img = styled.div`
  background-image: ${props => `url(${props.image});`}
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${props => `${props.height || "105px"};`}
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

export default StrategyImage;
