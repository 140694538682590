import React from "react";
import styled from "styled-components";
import { components } from "react-select";

const ValueContainer = ({ children, ...props }) => {
  const [values, input] = children;

  return (
    <components.ValueContainer className='py-0 pr-0' {...props}>
      {values || ""}
      {input}
    </components.ValueContainer>
  );
};

ValueContainer.Badge = styled.span`
  background: var(--dusk-steel);
  padding: 2px 8px;
  border-radius: 32px;
  margin-left: 0.2rem;
`;

export default ValueContainer;
