import React from "react";
import { CardHeader } from "reactstrap";

import { ReactComponent as BotsIcon } from "assets/icons/general/receiptsearch.svg";
import { RefreshButton } from "shared/ui";

const BotsWidgetHeader = ({ loading, onHandleRefresh }) => {
  return (
    <CardHeader className='px-4 pt-4 mb-4 d-flex justify-content-between rounded-0'>
      <div className='d-flex align-items-center'>
        <BotsIcon />
        <p className='text-white font-weight-500 ml-2 mb-0'>Bots</p>
      </div>
      <div className='d-none d-md-block'>
        <RefreshButton loading={loading} onHandleRefresh={onHandleRefresh} />
      </div>
    </CardHeader>
  );
};

export default BotsWidgetHeader;
