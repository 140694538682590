import React from "react";
import styled from "styled-components";

import CheckMark from "assets/icons/checkbox/CheckMark.svg";
import Minus from "assets/icons/checkbox/minus.svg";

const Checkbox = ({
  labelLeft,
  labelRight,
  checked,
  disabled,
  withMinus,
  type = "checkbox",
  ...props
}) => {
  return (
    <Wrapper
      className={`d-flex align-items-center position-relative mb-0 ${withMinus ? "unchecked" : ""}`}
      disabled={disabled}
    >
      {labelLeft || null}
      <input checked={checked} type={type} {...props} disabled={disabled} />
      {labelRight || null}
    </Wrapper>
  );
};

const Wrapper = styled.label`
  & input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
    background-color: #2e3148;
    border: ${props =>
      props.checked ? "1px solid #363A54" : "1px solid #4A4E73"};

    &.has-error,
    &.has-error:hover {
      border: 1px solid #ed5f5f;
    }

    &:hover {
      background: ${props =>
        props.disabled ? "inherit" : "var(--dark-slate)"};
      border: 1px solid #4a4e73;
    }

    &:checked {
      background-color: var(--info);
      background-image: url(${CheckMark}) !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }

  &.unchecked {
    & input[type="checkbox"]:not(:checked) {
      background-color: #ed5f5f;
      background-image: url(${Minus});
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  & input[type="radio"] {
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.125rem;
    height: 1.125rem;
    border: 1px solid #4a4e73;
    border-radius: 50%;
    cursor: pointer;
    position: relative;

    &:checked {
      border: 1px solid var(--info);

      &::after {
        content: "";
        display: flex;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background-color: var(--info);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;

export default Checkbox;
