import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { CardBody } from "reactstrap";
import { FieldArray, Form, Formik } from "formik";
import PropTypes from "prop-types";

import { createSignal } from "store/signals/action";
import { signalItem } from "store/signals/selectors";
import { addSnackBar } from "store/snackbar/actions";
import TargetSignal from "../compounds/TargetSignal";
import { ReactComponent as AddIcon } from "assets/icons/add-icon-border-none.svg";
import CreateTargetFooter from "../compounds/CreateTargetFooter";
import { calcTargetPercent } from "views/channels/utils";
import { ErrorAlert } from "shared/ui";
import { Button } from "shared/ui/buttons";

const validationSchema = Yup.object().shape({
  targets: Yup.array().of(
    Yup.number()
      .required("This field is required")
      .typeError("Must be a number"),
  ),
});

const CreateTargetsForm = ({ handleToggleModal }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const { data } = useSelector(signalItem);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    const signalData = {
      ...data,
      targets: values?.targets?.map(target => ({
        rate: target,
      })),
    };

    const res = await dispatch(createSignal(data?.channel?.id, signalData));

    if (!res.errors) {
      handleToggleModal();
      return dispatch(addSnackBar("success", res.message));
    }
    setErrorMessage(res.message);
    setErrors(res.message);
    setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        targets: [
          {
            rate: "",
          },
        ],
      }}
      onSubmit={handleSubmit}
    >
      {({ errors, isSubmitting, values }) => (
        <Form className='w-100' autoComplete='off'>
          <CardBody className='p-0'>
            <p className='font-weight-500 mb-2'>Targets</p>
            <FieldArray name='targets'>
              {arrayHelpers => (
                <>
                  {values?.targets && values?.targets?.length > 0
                    ? values?.targets?.map((_, index) => (
                        <TargetSignal
                          key={index}
                          label={`T${data?.targets?.length + index + 1}`}
                          type='number'
                          name={`targets[${index}]`}
                          isSubmitted={isSubmitted}
                          error={errors?.targets?.[index]}
                          removeTarget={arrayHelpers.remove}
                          index={index}
                          targetPercent={calcTargetPercent(
                            data?.buyTo,
                            values?.targets[index],
                          )}
                        />
                      ))
                    : null}
                  {values?.targets?.length < 5 - data?.targets?.length && (
                    <Button
                      type='button'
                      outline
                      className='col-10 col-md-11'
                      onClick={() => arrayHelpers.push("")}
                    >
                      <AddIcon />
                      <span className='font-md text-white-35 font-weight-400 ml-2'>
                        Add more
                      </span>
                    </Button>
                  )}
                  <ErrorAlert
                    toggle={() => setErrorMessage("")}
                    isOpen={!!errorMessage}
                    className='mt-3'
                  >
                    {errorMessage}
                  </ErrorAlert>
                  <CreateTargetFooter
                    isSubmitting={isSubmitting}
                    handleToggleModal={handleToggleModal}
                    setIsSubmitted={setIsSubmitted}
                  />
                </>
              )}
            </FieldArray>
          </CardBody>
        </Form>
      )}
    </Formik>
  );
};

CreateTargetsForm.propTypes = {
  handleToggleModal: PropTypes.func,
};

export default CreateTargetsForm;
