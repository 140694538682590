import React from "react";
import styled from "styled-components";

import OpenOrderHeader from "./OpenOrderHeader";
import OpenOrderBody from "./OpenOrderBody";

const OpenOrder = ({ openOrderRequest, className }) => {
  if (!openOrderRequest) return;

  return (
    <>
      <OpenOrder.Line />
      <OpenOrderHeader
        orderNumber={openOrderRequest?.orderNumber}
        className={className}
      />
      <OpenOrderBody
        className={className}
        createdAt={openOrderRequest?.order?.createdAt}
        tradesCount={openOrderRequest?.order?.tradesCount}
        amount={openOrderRequest?.order?.amount}
        quantity={openOrderRequest?.order?.quantity}
        filledPercent={openOrderRequest?.order?.filledPercent}
        price={openOrderRequest?.order?.rate}
      />
    </>
  );
};

OpenOrder.Line = styled.hr`
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background: #27293d;
  border: none;
  padding: 0;
  margin: 0;
`;

export default OpenOrder;
