import React from "react";
import styled from "styled-components";
import { FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { setPeriod } from "store/bots/wizard/actions";
import { SelectInput } from "shared/ui";

const CandleStickPeriodSelect = props => {
  const periods = useSelector(state => state.exchanges.periods);
  const dispatch = useDispatch();

  const periodsChart = periods?.map(item => ({
    value: Number(item),
    label: item,
  }));
  const periodValue = props?.period
    ? { label: props.period, value: props.period }
    : { label: "30", value: 30 };

  return (
    <div className='d-flex flex-column flex-md-row align-items-md-center'>
      <p className='mb-1 mb-md-0 mr-3 font-sm text-white-75'>
        Candlestick chart period
      </p>
      <StyledFormGroup className='markets-input mb-0'>
        <SelectInput
          placeholder=''
          value={periodValue}
          isClearable={false}
          isSearchable={false}
          options={periodsChart}
          onChange={({ value }) => dispatch(setPeriod(value))}
          name='period'
          {...props}
        />
      </StyledFormGroup>
    </div>
  );
};

const StyledFormGroup = styled(FormGroup)`
  width: 180px;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export default CandleStickPeriodSelect;
