import React, { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";

import { wizardIndicatorsSelector } from "store/strategies/selectors";
import { selectCurrentPlan } from "store/user/selectors";
import { handleInitCreateValues } from "views/bots/EditBot/editBotConfiguration";

const ConfigurationContext = createContext();

export const useConfigurationContext = () => {
  return useContext(ConfigurationContext);
};

export const ConfigurationProvider = ({ children }) => {
  const [botType, setBotType] = useState("genetic");
  const [submitted, setSubmitted] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const [redirectPath, setRedirectPath] = useState("/");

  const initialValues = handleInitCreateValues(null, setBotType)?.[botType];
  const wizardMarkets = useSelector(state => state.botWizard);
  const indicators = useSelector(state => wizardIndicatorsSelector(state));
  const currentPlan = useSelector(selectCurrentPlan);

  return (
    <ConfigurationContext.Provider
      value={{
        botType,
        setBotType,
        submitted,
        setSubmitted,
        alertOpen,
        setAlertOpen,
        redirectPath,
        setRedirectPath,
        currentPlan,
        initialValues,
        wizardMarkets,
        indicators,
        setIsOpenSettings,
        isOpenSettings,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};
