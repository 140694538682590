import React from "react";
import { Label } from "reactstrap";
import classnames from "classnames";
import { ErrorAlert } from "shared/ui";

const ConnectExchangeForm = ({
  submitted,
  errors,
  values,
  handleSetValue,
  errorMessage,
  setErrorMessage,
  websiteLink,
}) => {
  return (
    <form className='w-100' autoComplete='off'>
      <div className='mb-3'>
        <div className='d-flex align-items-center justify-content-between'>
          <Label className='text-dark-75 mb-1'>API Key</Label>
          <a
            href={websiteLink}
            target='_blank'
            rel='noopener noreferrer'
            className='text-nowrap text-info hover-underline mb-1 font-sm'
          >
            Generate API Key
          </a>
        </div>
        <input
          placeholder='Type in...'
          type='text'
          name='apiKey'
          value={values.apiKey}
          onChange={({ target }) => handleSetValue("apiKey", target.value)}
          className={classnames("form-control light", {
            "has-error": submitted && errors.apiKey,
          })}
        />
        {submitted && errors?.apiKey ? (
          <p className='text-danger mb-3 font-sm font-weight-300'>
            {errors.apiKey}
          </p>
        ) : null}
      </div>
      <div className='mb-3'>
        <Label className='text-dark-75 mb-1'>Secret Key</Label>
        <textarea
          placeholder='Type in...'
          type='text'
          name='secret'
          value={values.secret}
          onChange={({ target }) => handleSetValue("secret", target.value)}
          className={classnames("form-control light", {
            "has-error": submitted && errors.secret,
          })}
        />
        {submitted && errors?.secret ? (
          <p className='text-danger font-sm mb-3 font-weight-300'>
            {errors.secret}
          </p>
        ) : null}
      </div>
      <ErrorAlert
        fade={false}
        toggle={() => setErrorMessage("")}
        isOpen={!!errorMessage}
        isDark={false}
      >
        {errorMessage}
      </ErrorAlert>
    </form>
  );
};

export default ConnectExchangeForm;
