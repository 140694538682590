import React from "react";
import styled from "styled-components";
import { CardHeader } from "reactstrap";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const HeaderPreloader = () => {
  return (
    <CardHeader className='p-4 d-flex flex-column'>
      <div className='d-flex mb-4'>
        <SkeletonPreloader width={20} height={20} circle />
        <SkeletonPreloader width={81} height={12} className='ml-2 my-auto' />
      </div>
      <SelectPreloader />
    </CardHeader>
  );
};

const SelectPreloader = styled.div`
  background: #2a2c42;
  width: 100%;
  height: 38px;
`;

export default HeaderPreloader;
