import { combineReducers } from "redux";

import withDataLoading from "store/withDataLoading";
import {
  ATTACH_STRATEGY_INDICATORS,
  DETACH_STRATEGY_INDICATOR,
  UPDATE_STRATEGY_INDICATOR,
  LOAD_MORE_STRATEGIES,
  UPDATE_STRATEGY_NAME,
  DELETE_STRATEGY_IMAGE,
  POST_STRATEGY_IMAGE,
  ADD_INDICATOR,
  REMOVE_INDICATOR,
  EDIT_INDICATOR,
  UPDATE_STRATEGY,
  RESET_WIZARD_INDICATORS,
} from "./constants";

const initialState = { data: {}, loading: false, error: false };

const list = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_MORE_STRATEGIES:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          page: payload.page,
          total: payload.total,
          items: [...state.data.items, ...payload.items],
        },
      };

    default:
      return state;
  }
};

const item = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ATTACH_STRATEGY_INDICATORS:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };

    case UPDATE_STRATEGY:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };

    case UPDATE_STRATEGY_INDICATOR:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };

    case DETACH_STRATEGY_INDICATOR:
      return {
        ...state,
        data: {
          ...state.data,
          indicators: state.data.indicators.filter(i => i.uuid !== payload),
        },
      };

    case UPDATE_STRATEGY_NAME:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.item,
        },
      };

    case POST_STRATEGY_IMAGE:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.item,
        },
      };

    case DELETE_STRATEGY_IMAGE:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.item,
        },
      };

    default:
      return state;
  }
};

const wizard = (state = { indicators: [] }, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_INDICATOR:
      return {
        ...state,
        indicators: [...state.indicators, payload].map((indicator, index) => ({
          ...indicator,
          id: index + 1,
        })),
      };

    case EDIT_INDICATOR:
      return {
        ...state,
        indicators: state.indicators.map(indicator => {
          if (indicator.id === payload.id) {
            return { ...indicator, ...payload };
          }
          return indicator;
        }),
      };

    case REMOVE_INDICATOR:
      return {
        ...state,
        indicators: state.indicators.filter(
          indicator => indicator.id !== payload,
        ),
      };

    case RESET_WIZARD_INDICATORS:
      return {
        ...state,
        indicators: [],
      };

    default:
      return state;
  }
};

export default combineReducers({
  list: withDataLoading(list, "STRATEGIES"),
  item: withDataLoading(item, "STRATEGY"),
  signals: withDataLoading(null, "SIGNALS"),
  wizard,
});
