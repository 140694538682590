import React, { useEffect } from "react";
import styled from "styled-components";
import { Modal, Card } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchTechnicalLogs } from "store/deals/actions";
import TechnicalLogsHeaderModal from "./TechnicalLogsHeaderModal";
import TechnicalLogsListModal from "./TechnicalLogsListModal";
import TechnicalLogsModalLoading from "./TechnicalLogsModalLoading";
import TechnicalLogsEmptyState from "./TechnicalLogsEmptyState";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";

const TechnicalLogsModal = ({
  dealId,
  setIsModalOpenTechnicalLogs,
  isModalOpenTechnicalLogs,
}) => {
  const dispatch = useDispatch();

  const technicalLogsData = useSelector(
    state => state.deals.technicalLogs.data,
  );
  const technicalLogsLoading = useSelector(
    state => state.deals.technicalLogs.loading,
  );

  const { handleFilter, handlePaginate } = useFiltersWithRequest(
    technicalLogsData.request,
    fetchTechnicalLogs,
    dealId,
  );

  const openModal = () => () =>
    setIsModalOpenTechnicalLogs(!isModalOpenTechnicalLogs);

  useEffect(() => {
    dispatch(fetchTechnicalLogs(dealId, "?limit=13"));
  }, [dealId, dispatch]);

  return (
    <TechnicalLogsModal.Modal
      isOpen={isModalOpenTechnicalLogs}
      toggle={openModal()}
      modalClassName='modal-black'
    >
      <TechnicalLogsModal.Card>
        <TechnicalLogsHeaderModal
          handleFilter={handleFilter}
          technicalLogsLoading={technicalLogsLoading}
          setIsModalOpenTechnicalLogs={setIsModalOpenTechnicalLogs}
        />
        {technicalLogsLoading ? (
          <TechnicalLogsModalLoading />
        ) : (
          <TechnicalLogsListModal
            data={technicalLogsData}
            handlePaginate={handlePaginate}
          />
        )}
        {!technicalLogsData.items?.length && !technicalLogsLoading && (
          <TechnicalLogsEmptyState />
        )}
      </TechnicalLogsModal.Card>
    </TechnicalLogsModal.Modal>
  );
};

TechnicalLogsModal.Modal = styled(Modal)`
  @media (min-width: 768px) {
    max-width: fit-content !important;
    height: 100% !important;
    margin: 1rem auto !important;

    .modal-content {
      width: 75vw;
    }
  }

  @media (max-width: 767.98px) {
    &.modal-dialog {
      max-width: 100% !important;
    }
    .modal-content {
      width: 85vw;
      margin: auto;
    }
  }

  .modal-content {
    background: #2e3148 !important;
    border-radius: 16px;
    overflow: auto;
  }

  .pointer {
    cursor: pointer;
  }
`;

TechnicalLogsModal.Card = styled(Card)`
  margin: 0 !important;
  padding: 32px 32px 12px 32px;
`;

export default TechnicalLogsModal;
