import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import classNames from "classnames";

import useClickOutside from "hooks/useClickOutside";
import Button from "shared/ui/buttons/Button";
import FormGroupWithSymbols from "shared/ui/FormGroupWithSymbols";
import { updateInfo } from "store/channels/actions";
import { addSnackBar } from "store/snackbar/actions";
import { ReactComponent as Alert } from "assets/icons/general/alert.svg";

const validationSchema = Yup.object().shape({
  telegram: Yup.string().matches(
    /^(https:\/\/t\.me\/[a-zA-Z0-9_]{5,32}|@[a-zA-Z0-9_]{5,32}|[a-zA-Z0-9_]{5,32})$/,
    "Invalid Telegram link",
  ),
  description: Yup.string()
    .required("This field is required")
    .max(4800, "Description can't be longer than 4800 characters."),
});

const ChannelSettingForm = ({ name, id, description, type, telegram }) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const formRef = useRef();
  const fieldRef = useRef();

  const showNotification = (isError, message, setSubmitting) => {
    const snackbarType = isError ? "danger" : "success";
    const snackbarMessage = message;
    dispatch(addSnackBar(snackbarType, snackbarMessage));
    setIsEditing(false);
    setSubmitting(false);
  };

  const handleSave = async (values, { setSubmitting }) => {
    if (values.telegram === telegram && values.description === description) {
      setIsEditing(false);
      setSubmitting(false);
      return;
    }

    const payload = {
      ...values,
      name,
      type,
    };

    const res = await dispatch(updateInfo(id, payload));
    showNotification(res.errors, res.message, setSubmitting);
  };

  useClickOutside(formRef, () => setIsEditing(false));

  const handleEnableEdit = e => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleCancel = resetForm => {
    setIsEditing(false);
    resetForm();
  };

  return (
    <Formik
      initialValues={{
        description,
        telegram,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSave}
    >
      {({
        isSubmitting,
        resetForm,
        errors,
        values,
        setFieldValue,
        submitForm,
      }) => (
        <Form
          ref={formRef}
          className='d-flex flex-column mb-1 w-100 h-auto'
          data-testid='inline-editor'
        >
          <WrapButtons className='d-flex justify-content-end position-absolute'>
            {isEditing && (
              <Button
                className='text-white py-2 px-3 mr-2'
                color='gray'
                outline
                onClick={() => handleCancel(resetForm)}
                data-testid='cancel-button'
              >
                Cancel
              </Button>
            )}
            <Button
              className='text-white py-2 px-3'
              loading={isSubmitting}
              disabled={isSubmitting}
              color={isEditing ? "blue" : "gray"}
              outline={!isEditing}
              onClick={isEditing ? null : handleEnableEdit}
              type={isEditing ? "submit" : "button"}
              data-testid='edit-button'
            >
              {isEditing ? "Save changes" : "Edit"}
            </Button>
          </WrapButtons>

          <div className='d-flex flex-column mt-4'>
            <label className='text-white font-weight-500 mb-1'>
              Link to the Telegram (optional):
            </label>
            {telegram?.length || isEditing ? (
              <>
                <FormGroupWithSymbols
                  symbolLeftColor
                  symbolLeft='https://t.me/'
                  className={`font-sm ${isEditing ? "text-white-35" : "text-white-75"} font-weight-400`}
                  classNameSymbolL={isEditing ? "pl-3" : "pl-0"}
                >
                  <Input
                    isEditing={isEditing}
                    type='text'
                    name='telegram'
                    style={{ paddingLeft: isEditing ? "5.9rem" : "4.9rem" }}
                    className={classNames(
                      "font-sm text-white-75 font-weight-400 border-0 overflow-hidden pr-3",
                      {
                        "has-error": errors?.telegram,
                      },
                    )}
                    data-testid='telegram-input'
                    disabled={!isEditing}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        submitForm();
                      }
                      if (e.key === "Escape") {
                        e.preventDefault();
                        handleCancel(resetForm);
                      }
                    }}
                  />
                </FormGroupWithSymbols>
                {errors?.telegram && (
                  <p className='text-danger mb-3 font-sm font-weight-300'>
                    {errors?.telegram}
                  </p>
                )}
              </>
            ) : (
              <EmptyTelegramField>
                <span className='d-inline-block'>
                  <Alert className='my-auto' />
                </span>
                <p className='mb-0 text-white-75 ml-4 font-sm'>
                  Telegram channel link not provided. Adding it is optional, but
                  recommended for better engagement
                </p>
              </EmptyTelegramField>
            )}
          </div>

          <label className='text-white font-weight-500 mt-4 mb-1'>
            Channel description:
          </label>
          <InlineEditField
            isEditing={isEditing}
            className={classNames(
              "font-sm text-white-75 font-weight-400 w-100 border-0 overflow-hidden",
              {
                "has-error": errors?.description,
              },
            )}
            ref={fieldRef}
            contentEditable={isEditing}
            onBlur={e =>
              setFieldValue("description", e.currentTarget.textContent)
            }
            suppressContentEditableWarning
            dangerouslySetInnerHTML={{ __html: values.description }}
            data-testid='description-input'
            onKeyDown={e => {
              if (e.key === "Enter") {
                e.preventDefault();
                submitForm();
              }
              if (e.key === "Escape") {
                e.preventDefault();
                handleCancel(resetForm);
              }
            }}
          />
          {errors?.description && (
            <p className='text-danger mb-3 font-sm font-weight-300'>
              {errors?.description}
            </p>
          )}
        </Form>
      )}
    </Formik>
  );
};

const WrapButtons = styled.div`
  top: 1rem;
  right: 1.5rem;
`;

const InlineEditField = styled.div`
  box-shadow: none;
  background: transparent;
  border-radius: 6px !important;
  padding: 0.6875rem 0rem;
  min-height: 189px;

  &:focus,
  &:focus-visible {
    border: 1px solid #44486a !important;
    background: var(--BG-2nd-block, #2e3148);
    outline: none;
  }

  ${({ isEditing }) =>
    isEditing &&
    ` 
    border: 1px solid #44486A !important;
    background: var(--BG-2nd-block, #2E3148);
    outline: none;
    padding: 0.6875rem 1rem;
  `}

  &.has-error {
    border: 1px solid #ed5f5f !important;
  }
`;

const Input = styled(Field)`
  box-shadow: none;
  background: transparent;
  border-radius: 6px !important;
  border: 1px solid transparent !important;
  padding: 0.6875rem 0rem;
  border: 1px solid transparent;
  width: 100%;
  color: white;

  ${({ isEditing }) =>
    isEditing &&
    ` 
    border: 1px solid #44486A !important;
    background: var(--BG-2nd-block, #2E3148);
    outline: none;
    padding: 0.6875rem 1rem;
  `}

  &:focus, &:focus-visible {
    border: 1px solid #44486a !important;
    background: var(--BG-2nd-block, #2e3148);
    outline: none;
  }

  &.has-error {
    border: 1px solid #ed5f5f !important;
  }

  &:disabled {
    background: transparent;
    color: white;
  }
`;

const EmptyTelegramField = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding: 0.6875rem 0rem;

  span {
    position: absolute;
    left: -0.25rem;
  }

  p {
    line-height: initial;
  }

  path,
  rect {
    stroke: rgba(255, 255, 255, 0.35);
  }
`;

ChannelSettingForm.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  telegram: PropTypes.string,
};

export default ChannelSettingForm;
