import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Card, CardHeader, CardBody, CardFooter } from "reactstrap";

import { disconnectGoogleAccount } from "store/user/actions";
import { addSnackBar } from "store/snackbar/actions";
import GooglePreloader from "../preloaders/GooglePreloader";
import { ReactComponent as DefaultAvatar } from "assets/icons/general/default-avatar.svg";
import GoogleAccountForm from "../forms/GoogleAccountForm";
import SubCard from "shared/ui/SubCard";

const GoogleAccount = ({ google, loading }) => {
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();

  const handleDisconnect = async e => {
    e.preventDefault();
    const res = await dispatch(disconnectGoogleAccount());
    if (res.errors) {
      dispatch(addSnackBar("error", res.message));
    } else {
      dispatch(addSnackBar("success", res.message));
    }
  };

  if (processing || loading) return <GooglePreloader />;

  return (
    <div>
      {google?.email ? (
        <Card className='p-2'>
          <GoogleAccount.Header>
            <div className='d-flex flex-row flex-md-column flex-lg-row align-items-lg-center'>
              <p className='h4 mb-0 mr-2 text-white text-nowrap'>
                Google account
              </p>
              <SubCard className='mt-md-2 mt-lg-0'>
                <p className='mb-0 font-weight-bold text-success font-sm'>
                  Connected
                </p>
              </SubCard>
            </div>
          </GoogleAccount.Header>
          <CardBody>
            <p className='mb-0 text-white-75 font-sm'>
              In case if you want to change account or disconnect it, then click
              on the link below.
            </p>
          </CardBody>
          <CardFooter className='pt-0 d-flex'>
            {google?.imageUrl ? (
              <img
                className='avatar mr-2 my-auto'
                alt='Google account user avatar'
                src={google?.imageUrl}
              />
            ) : (
              <DefaultAvatar className='avatar mr-2 my-auto' />
            )}
            <div className='d-flex flex-column'>
              <p className='my-auto'>
                {google.givenName + " " + google.familyName}
              </p>
              <GoogleAccount.Link
                href='/user/profile'
                className='my-auto'
                onClick={e => handleDisconnect(e)}
              >
                Disconnect
              </GoogleAccount.Link>
            </div>
          </CardFooter>
        </Card>
      ) : (
        <GoogleAccountForm setProcessing={setProcessing} />
      )}
    </div>
  );
};

GoogleAccount.Header = styled(CardHeader)`
  p {
    line-height: 19px;
  }
`;

GoogleAccount.Link = styled.a`
  font-size: 16px;
  font-weight: 400;
  color: rgba(47, 128, 237, 1) !important;
  width: inherit;

  &:hover {
    text-decoration: underline;
    color: rgba(47, 128, 237, 0.8) !important;
  }
`;

export default GoogleAccount;
