import React from "react";
import classNames from "classnames";

import {
  StyledBadgeIndicatorSettingsBlock,
  StyledBadgeIndicatorSettingsBlockGroup,
} from "./index.styles";

const BadgeCandle = ({
  candle,
  candlesOptions = [],
  param,
  setFieldValue,
  editCandleSetting,
  paramName,
}) => {
  if (candlesOptions?.options?.length) {
    return (
      <StyledBadgeIndicatorSettingsBlockGroup
        width={candlesOptions.styles.container.width}
        height={candlesOptions.styles.container.height}
      >
        {candlesOptions?.options.map(({ value, label, badgeClass }) => {
          return (
            <StyledBadgeIndicatorSettingsBlock
              key={`${value}-${label}-${candle?.id}`}
              width={candlesOptions.styles.badge.width}
              height={candlesOptions.styles.badge.height}
              className={classNames("", {
                [badgeClass]: param === value,
              })}
              onClick={() => {
                if (paramName) {
                  editCandleSetting({ ...candle, [paramName]: value });
                } else {
                  setFieldValue(value);
                }
              }}
            >
              <p
                className={classNames(
                  "font-sm font-weight-500 text-uppercase text-center mb-0",
                  {
                    dark: param === value,
                    "text-white-35": param !== value,
                  },
                )}
              >
                {label}
              </p>
            </StyledBadgeIndicatorSettingsBlock>
          );
        })}
      </StyledBadgeIndicatorSettingsBlockGroup>
    );
  }
};

export default React.memo(BadgeCandle);
