export const BACKTEST_STATUS_COLORS = {
  new: "#FFFFFF",
  preparing: "#A8F5C7",
  prepared: "#BBDA6C",
  analyzing: "#F6D589",
  analyzed: "#85C9F9",
  running: "#DB53C7",
  completed: "#5FD3BC",
  failed: "#FF8D72",
};

export const minMaxRange = {
  traditional: 5,
  camarilla: 4,
  classic: 4,
  woodie: 4,
  fibonacci: 3,
  demark: 1,
};
