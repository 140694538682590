import React from "react";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { times } from "lodash";

import { SkeletonPreloader } from "shared/ui";
import StyledPreloader from "shared/styled/StyledPreloader";

const SummaryWidgetPreloader = () => {
  return (
    <Card className='w-100 mr-2 card-border'>
      <CardHeader className='p-4 d-flex'>
        <StyledPreloader
          style={{ borderRadius: "8px", width: "56px", height: "56px" }}
        />
        <div className='d-flex flex-column my-auto ml-4'>
          <SkeletonPreloader height={12} width={73} className='mb-2' />
          <SkeletonPreloader height={16} width={161} />
        </div>
      </CardHeader>
      <hr className='m-0 mx-4' />
      <CardBody className='p-4'>
        {times(4, e => (
          <div className='d-flex justify-content-between mb-3' key={e}>
            <SkeletonPreloader height={12} width={73} />
            <SkeletonPreloader height={14} width={121} />
          </div>
        ))}
      </CardBody>
      <hr className='m-0 mx-4' />
      <CardFooter className='p-4 d-flex flex-column'>
        <SkeletonPreloader height={12} width={73} className='mb-2' />
        <SkeletonPreloader height={16} width={161} />
      </CardFooter>
    </Card>
  );
};

export default SummaryWidgetPreloader;
