import React from "react";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import _ from "lodash";

const IndicatorsMarketPreloader = () => {
  return _.times(3, e => (
    <div
      key={e}
      className='d-flex align-items-center justify-content-between px-4 py-3'
      style={{ borderTop: "1px solid #2E3148" }}
    >
      <div className='d-flex align-items-center'>
        <SkeletonPreloader width={24} height={24} circle />
        <SkeletonPreloader width={121} height={16} className='ml-3 mr-2' />
        <SkeletonPreloader width={121} height={10} />
      </div>

      <SkeletonPreloader width={43} height={26} borderRadius='4px' />
    </div>
  ));
};

export default IndicatorsMarketPreloader;
