import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import { useDispatch } from "react-redux";

import { ReactComponent as Information } from "assets/icons/general/information.svg";
import { removeDraftCreatedIndicator } from "store/bots/actions";
import { indicatorsConfiguration } from "../../models/indicatorsConfiguration";
import { IndicatorSettingsBlock } from "features/blocks";
import { BadgeIndicatorSettingsBlock } from "shared/ui/badges";
import { INDICATORS_OPTIONS } from "views/bots/models/options";
import { StyledIndicatorCard } from "./index.styles";
import {
  editDraftIndicator,
  removeDraftIndicator,
} from "store/strategies/actions";
import { SettingsTrashIcons } from "shared/ui";

const Indicator = ({
  indicator,
  setIsEditionIndicator,
  isEditionIndicator,
  setIsOpenSettings,
}) => {
  const [isEdition, setIsEdition] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [currentType, setCurrentType] = useState({
    target: { value: indicator?.type || "buy" },
  });
  const [currentRequired, setCurrentRequired] = useState(
    indicator?.required || false,
  );
  const dispatch = useDispatch();

  const indicatorName = indicatorsConfiguration.filter(
    ({ value }) => value === indicator?.code,
  )[0];

  const toggleShow = () => setIsShow(!isShow);

  const handleToogleEdit = () => {
    setIsEdition(prevState => !prevState);
    setIsEditionIndicator(prevState => !prevState);
    setIsOpenSettings(prevState => !prevState);
  };

  const handleRemoveIndicator = () => {
    dispatch(removeDraftIndicator(indicator?.id));
    dispatch(removeDraftCreatedIndicator(indicator?.id));
  };

  const handleCurrentType = type => {
    setCurrentType(type);
    dispatch(
      editDraftIndicator({
        ...indicator,
        type: type?.target?.value,
      }),
    );
  };

  if (!isEdition) {
    return (
      <StyledIndicatorCard
        required={currentRequired}
        className='d-block mb-2 d-flex flex-column flex-md-row align-md-items-center position-relative'
      >
        <div className='d-flex'>
          <div className='px-0 mr-3'>
            <div
              className='position-relative necessary-signal mr-0'
              id={`indicator-${indicator?.id}`}
              onClick={() => setCurrentRequired(!currentRequired)}
            >
              <Information />
            </div>
            <Tooltip
              target={`indicator-${indicator?.id}`}
              placement='top-start'
              isOpen={isShow}
              toggle={toggleShow}
              className='text-nowrap'
            >
              Necessary indicator for trade request
            </Tooltip>
          </div>
          <BadgeIndicatorSettingsBlock
            arrayIndicatorsOptions={INDICATORS_OPTIONS}
            type={currentType?.target?.value}
            setFieldValue={handleCurrentType}
            className='signals d-flex flex-row px-0 mr-3'
          />
          <SettingsTrashIcons
            classNameWrap='d-flex flex-row align-items-center justify-content-end ml-auto mr-0 px-0 d-md-none'
            onSettingsClick={handleToogleEdit}
            onDeleteClick={handleRemoveIndicator}
            isSettingsDisabled={isEditionIndicator}
            isEdition={isEdition}
          />
        </div>
        <div className='d-flex flex-column flex-md-row mt-3 mt-md-0 align-md-items-center px-0 text-white'>
          <p className='text-white font-weight-500 mb-0 font-sm'>
            {indicatorName && indicatorName.label}
          </p>
          <p className='mb-0 ml-md-4 text-white-35 font-weight-400 font-sm text-nowrap'>
            {Object.values(indicator?.settings || {}).length > 0 &&
              `${Object.values(indicator?.settings).join(", ")}`}
          </p>
        </div>
        <SettingsTrashIcons
          classNameWrap='d-none d-md-flex flex-row align-items-center justify-content-end mt-3 mt-md-0 ml-md-auto px-0'
          onSettingsClick={handleToogleEdit}
          onDeleteClick={handleRemoveIndicator}
          isSettingsDisabled={isEditionIndicator}
          isEdition={isEdition}
        />
      </StyledIndicatorCard>
    );
  } else {
    return (
      <IndicatorSettingsBlock
        isEdit
        indicators={indicator}
        toogleEdit={handleToogleEdit}
        isShowCreationBlock
      />
    );
  }
};

export default Indicator;
