import React from "react";

import SocialButton from "./SocialButton";
import { ReactComponent as TwitterIcon } from "assets/icons/social/twitter-X-share.svg";

const TwitterShareButton = ({ url, text, ...props }) => {
  const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;

  return (
    <SocialButton url={shareUrl} {...props}>
      <TwitterIcon width={18} height={18} />
    </SocialButton>
  );
};

export default TwitterShareButton;
