import React from "react";

import { StyledDefaultPreloader } from "./index.styles";
import BotsListPreload from "./ui/BotsListPreload";

const DefaultPreloader = ({ viewPreloader }) => {
  const renderPreloader = () => {
    switch (viewPreloader) {
      case "botsList":
        return (
          <StyledDefaultPreloader>
            <BotsListPreload />
          </StyledDefaultPreloader>
        );
      case "channelsList":
        return (
          <StyledDefaultPreloader>
            <BotsListPreload />
          </StyledDefaultPreloader>
        );
      default:
        null;
    }
  };
  return renderPreloader();
};

export default DefaultPreloader;
