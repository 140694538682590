export const BOT_WIZARD_HEAD = [
  { name: "", label: "Exchange", isSortable: false },
  { name: "asset", label: "Coin", isSortable: true },
  { name: "balance", label: "Amount", isSortable: true },
  { name: "estimated", label: "Estimated", isSortable: true },
  { name: "realPercent", label: "Allocation", isSortable: true },
  { name: "botsCount", label: "Bots", isSortable: true },
];

export const getMarketsTableHead = exchange => {
  return [
    { name: "market", label: "Market", isSortable: true },
    { name: "amount", label: "Volume", isSortable: true },
    ...(exchange !== "hitbtc"
      ? [{ name: "tradeCount", label: "Trades", isSortable: true }]
      : []),
    { name: "close", label: "Rate", isSortable: true },
    { name: "dailyChange", label: "24h Change", isSortable: true },
    { name: "high", label: "24h High", isSortable: true },
    { name: "low", label: "24h Low", isSortable: true },
  ];
};

export const MARTINGALE_CALCULATION_HEAD = [
  { name: "tr", label: "TR", isSortable: false },
  { name: "price", label: "Price", isSortable: false },
  { name: "invested", label: "Invested", isSortable: false },
  { name: "purchasedBtc", label: "Purchased", isSortable: false },
  { name: "totalInvested", label: "Total invested", isSortable: false },
  { name: "estimated", label: "Estimated", isSortable: false },
  { name: "totalPurchased", label: "Total purchased", isSortable: false },
  { name: "return", label: "Return", isSortable: false },
];
