import React from "react";
import styled from "styled-components";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import _ from "lodash";

const InvestmentsFiltersPreloader = () => {
  return (
    <InvestmentsFiltersPreloader.Wrapper className='d-flex flex-column flex-xl-row align-items-xl-center justify-content-between'>
      <SkeletonPreloader className='mb-3 mb-md-0' width={227} height={16} />
      <div className='d-flex align-items-center mb-3 mb-md-0'>
        <SkeletonPreloader
          width={165}
          height={38}
          className='ml-2 pl-1 mr-1'
          borderRadius='6px'
        />
        <SkeletonPreloader width={165} height={38} borderRadius='6px' />
      </div>

      <div className='d-flex align-items-center '>
        <SkeletonPreloader width={24} height={24} circle className='mr-2' />
        <SkeletonPreloader width={30} height={38} borderRadius='6px' />
        {_.times(4, e => (
          <SkeletonPreloader
            key={e}
            width={42}
            height={38}
            borderRadius='6px'
            className='ml-1'
          />
        ))}
      </div>
    </InvestmentsFiltersPreloader.Wrapper>
  );
};
InvestmentsFiltersPreloader.Wrapper = styled.div`
  padding: 24px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default InvestmentsFiltersPreloader;
