import React from "react";
import classNames from "classnames";
import styled from "styled-components";
import moment from "moment";
import { capitalize } from "lodash";
import { useDispatch } from "react-redux";

import { ReactComponent as Cancel } from "assets/icons/checkbox/confirmedFalse.svg";
import { cancelOrder } from "store/credentials/actions";
import { formatterToNull } from "helpers/valuesFormatters";
import { addSnackBar } from "store/snackbar/actions";
import { ORDERS_TABLE_HEAD } from "models/credentials/enum/tables";
import useFilterAndSorting from "hooks/useFilterAndSorting";
import { CustomScrollbars, OrdersProgressBar, SortTableHead } from "shared/ui";
import { StyledTable } from "shared/styled/StyledTable";

const OrdersTable = ({
  data = [],
  credentialId,
  setOrderId,
  setIsModalOpen,
  setCurrentOrderNumber,
}) => {
  const dispatch = useDispatch();
  const { sort, handleSorting } = useFilterAndSorting(
    `/credentials/${credentialId}/orders`,
  );

  const canCancel = async (event, order) => {
    event.stopPropagation();
    const res = await dispatch(cancelOrder(order));
    if (res?.errors) dispatch(addSnackBar("error", res.message));
    if (!res?.errors) dispatch(addSnackBar("success", res.message));
  };

  const handleRow = (idOrder, idNumber) => () => {
    setIsModalOpen(true);
    setOrderId(idOrder);
    setCurrentOrderNumber(idNumber);
  };

  return (
    <CustomScrollbars
      autoHideTimeout={500}
      autoHideDuration={500}
      autoHeight
      autoHeightMax={Number.MAX_VALUE}
    >
      <StyledTable className='mb-0' paddingsThead='1rem'>
        <SortTableHead
          handleSorting={handleSorting}
          sort={sort}
          theadItems={ORDERS_TABLE_HEAD}
        />
        <tbody className='font-sm'>
          {data.map(item => (
            <OrdersTable.BodyRow
              key={item.id}
              isManual={item.isManual}
              onClick={handleRow(item.id, item.orderNumber)}
            >
              <td>
                <p
                  className={classNames("text-uppercase mb-0 font-weight-500", {
                    "text-success": item.type === "BUY" || item.type === "buy",
                    "text-danger": item.type === "SELL" || item.type === "sell",
                  })}
                >
                  {item.type}
                </p>
              </td>
              <td>
                <OrdersTable.Currency>{item.orderNumber}</OrdersTable.Currency>
              </td>
              <td>
                <div className='d-flex align-content-end text-nowrap'>
                  <p className='text-white mb-0 font-weight-500'>
                    {item.market.base}
                  </p>
                  <OrdersTable.Currency className='mr-1'>
                    /
                  </OrdersTable.Currency>
                  <p className='text-white mb-0 font-weight-500'>
                    {item.market.quote}
                  </p>
                </div>
              </td>
              <td>
                <OrdersTable.Amount amount={item.rate}>
                  {item.rate}
                </OrdersTable.Amount>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <OrdersProgressBar filledPercent={item.filledPercent} />
                  <p className='mb-0 font-sm font-weight-500 text-white-35'>
                    {`${item.filledPercent || 0}%`}
                  </p>
                </div>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <OrdersTable.Amount amount={item.total.amount}>
                    {item.total.amount}
                  </OrdersTable.Amount>
                  <OrdersTable.Currency>
                    {item.total.currency}
                  </OrdersTable.Currency>
                </div>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <OrdersTable.Amount
                    amount={Number(item.executedAmount.amount)}
                  >
                    {item.executedAmount.amount || "0"}
                  </OrdersTable.Amount>
                  <OrdersTable.Currency>
                    {item.executedAmount.currency}
                  </OrdersTable.Currency>
                </div>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <OrdersTable.Amount
                    amount={Number(item.executedQuantity.amount)}
                  >
                    {item.executedQuantity.amount || "0"}
                  </OrdersTable.Amount>
                  <OrdersTable.Currency>
                    {item.executedQuantity.currency}
                  </OrdersTable.Currency>
                </div>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <OrdersTable.Amount amount={Number(item.fee.amount)}>
                    {item.fee.amount || "0"}
                  </OrdersTable.Amount>
                  <OrdersTable.Currency>
                    {item.fee.currency}
                  </OrdersTable.Currency>
                </div>
              </td>
              <td>
                <OrdersTable.Amount amount={item.tradesCount}>
                  {formatterToNull(item.tradesCount)}
                </OrdersTable.Amount>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <OrdersTable.Amount amount={1}>
                    {item.exchangeStatus}
                  </OrdersTable.Amount>
                </div>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <p
                    className={classNames("mb-0 font-weight-500", {
                      "text-success": item.status === "completed",
                      "text-white-35": item.status === "cancelled",
                      "text-info": item.status === "open",
                    })}
                  >
                    {capitalize(item.status)}
                  </p>
                </div>
              </td>
              <td>
                <p className='mb-0 text-white font-weight-300 text-nowrap'>
                  {moment(item.createdAt).format("DD.MM.YY HH:mm")}
                </p>
              </td>
              <td>
                {item.canCancel && item.isManual ? (
                  <OrdersTable.Trash>
                    <Cancel onClick={event => canCancel(event, item.id)} />
                  </OrdersTable.Trash>
                ) : null}
              </td>
            </OrdersTable.BodyRow>
          ))}
        </tbody>
      </StyledTable>
    </CustomScrollbars>
  );
};

OrdersTable.BodyRow = styled.tr`
  background: ${props => (props.isManual ? "#203251" : "")} !important;
  cursor: pointer;
  &:hover {
    background: ${props => (props.isManual ? "#202D48" : "")} !important;
  }
`;

OrdersTable.Amount = styled.p`
  margin-bottom: 0;
  color: ${props =>
    props.amount > 0 ? "#FFFFFF" : "rgba(255, 255, 255, 0.35)"} !important;
  font-weight: 500;
`;

OrdersTable.Currency = styled.p`
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.35) !important;
  font-weight: 300;
  margin-left: 4px;
`;

OrdersTable.Trash = styled.div`
  cursor: pointer;
  svg {
    path {
      stroke: rgba(255, 255, 255, 0.35);
    }
    rect {
      opacity: 0;
    }
  }
  &:hover {
    svg {
      path {
        stroke: rgba(255, 255, 255, 0.75);
      }
      rect {
        opacity: 0.1;
      }
    }
  }
`;

export default OrdersTable;
