import styled from "styled-components";
import { Table } from "reactstrap";

export const StyledTable = styled(Table)`
  & thead tr th {
    font-size: 0.875rem !important;
    line-height: 16.41px;
    font-weight: 300 !important;
    color: #ffffff !important;
    padding: ${props => props.paddingsThead || "0.5rem 1rem"} !important;
    background: ${props => props.backgroundThead || "initial"} !important;
  }

  & tbody tr {
    background: var(--dark-slate);
    box-shadow: 0 -1px #2e3148;
    &:hover {
      background: #2e3148;

      & + .additional-tr {
        background: ${props => (props.isPreloading ? "#2E3148" : "#363A54")};
      }
    }

    &:not(:last-child) td {
      border-bottom: 1px solid var(--dark-slate);
    }
  }

  & tbody tr td {
    padding: 1rem 1rem !important;

    &.px-0 {
      padding: 1rem 0 !important;
    }
  }

  & thead tr th,
  & tbody tr td {
    &:first-child {
      padding-left: 1.5rem !important;
    }

    &:last-child {
      padding-right: 1.5rem !important;
    }
  }
`;

export const StyledTableRow = styled.tr`
  &:last-child {
    border-bottom: ${({ total }) =>
      total > 10 ? "1px solid #2E3148" : "none"};
  }
`;

export const StyledTableCell = styled.td`
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    background: ${({ status, profit }) => {
      if (status) {
        return status === "running" ? "#DB53C7" : "var(--info)";
      }
      if (profit !== undefined) {
        if (!profit) return "rgba(255, 255, 255, 0.10)";
        if (profit > 0) return "#3FCBB0";
        return "#ED5F5F";
      }
      return "transparent";
    }};
  }
`;
