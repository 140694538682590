import React from "react";
import { FormGroup } from "reactstrap";

import { STATUS_RUNNING_DEALS_OPTIONS } from "shared/models/filters";
import { SelectInput } from "shared/ui";

const Filters = ({ handleFilter, loading, request }) => {
  return (
    <FormGroup className='w-100 pr-0 pr-sm-2 my-2 my-md-0 col-12 col-sm-3'>
      <SelectInput
        isClearable
        placeholder='Status'
        name='status'
        width='100%'
        value={STATUS_RUNNING_DEALS_OPTIONS.find(
          o => o.value === request?.filter?.status?.eq,
        )}
        onChange={option =>
          handleFilter({
            status: option
              ? { eq: option?.value }
              : { in: ["ready", "running"] },
          })
        }
        options={STATUS_RUNNING_DEALS_OPTIONS}
        isDisabled={loading}
      />
    </FormGroup>
  );
};

export default Filters;
