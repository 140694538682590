import React from "react";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import IndicatorsPreloader from "../../../strategies/wizard/IndicatorsPreloader";
import _ from "lodash";

const TradeSettingsPreloader = () => {
  return (
    <TradeSettingsPreloader.Wrapper>
      <div className='p4 bot-type__item d-flex align-items-center justify-content-between mb-4'>
        <div className='d-flex flex-column pl-2'>
          <SkeletonPreloader className='mb-2' width={88} height={16} />
          <SkeletonPreloader width={147} height={10} />
        </div>
        <div className='d-none d-md-flex align-items-center pr-2'>
          <SkeletonPreloader width={152} height={12} />
          <SkeletonPreloader
            className='ml-3 mr-1'
            width={104}
            height={38}
            borderRadius='6px'
          />
          <SkeletonPreloader width={122} height={38} borderRadius='6px' />
        </div>
      </div>
      <TradeSettingsPreloader.Body>
        {_.times(2, e => (
          <Row className='my-3' key={e}>
            <Col md='6' xs='12' className='w-100'>
              <div className='field d-flex flex-column'>
                <SkeletonPreloader width={88} height={14} className='mb-2' />
                <SkeletonPreloader
                  width={"100%"}
                  height={38}
                  borderRadius={"6px"}
                  className='opacity-5'
                />
              </div>
            </Col>
            {_.times(2, e => (
              <Col md='3' xs='12' className='w-100' key={e}>
                <div className='field d-flex flex-column mt-3 mt-md-0'>
                  <SkeletonPreloader width={88} height={14} className='mb-2' />
                  <SkeletonPreloader
                    width={"100%"}
                    height={38}
                    borderRadius={"6px"}
                    className='opacity-5'
                  />
                </div>
              </Col>
            ))}
          </Row>
        ))}
        <Row className='mb-5'>
          {_.times(2, e => (
            <Col md='6' xs='12' className='w-100' key={e}>
              <div className='field d-flex flex-column mt-3 mt-md-0'>
                <SkeletonPreloader width={88} height={14} className='mb-2' />
                <SkeletonPreloader
                  width={"100%"}
                  height={38}
                  borderRadius={"6px"}
                  className='opacity-5'
                />
              </div>
            </Col>
          ))}
        </Row>

        <div
          className='martingale d-flex flex-column mb-5'
          style={{ background: "#2A2C42" }}
        >
          <div className='d-flex align-items-center mb-3'>
            <SkeletonPreloader
              width={26}
              height={14}
              borderRadius='17px'
              className='mr-2'
            />
            <SkeletonPreloader width={88} height={18} />
            <SkeletonPreloader height={10} className='ml-5 pl-5 w-75' />
          </div>
          <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
            {_.times(3, e => (
              <div className='d-flex flex-column w-100 mb-3' key={e}>
                <SkeletonPreloader height={10} width={58} className='mb-1' />
                <SkeletonPreloader height={38} borderRadius='6px' />
              </div>
            ))}
          </div>
        </div>
        <div
          className='martingale d-flex flex-column mb-3'
          style={{ background: "#2A2C42" }}
        >
          <div className='d-flex align-items-center mb-3'>
            <SkeletonPreloader
              width={26}
              height={14}
              borderRadius='17px'
              className='mr-2'
            />
            <SkeletonPreloader width={88} height={18} />
          </div>
          <div className='d-flex flex-column flex-md-row align-items-md-center'>
            {_.times(2, e => (
              <div className='d-flex flex-column mr-2 mb-3 mb-md-0' key={e}>
                <SkeletonPreloader height={14} width={88} className='mb-1' />
                <SkeletonPreloader height={38} width={144} borderRadius='6px' />
              </div>
            ))}
            <div className='d-none d-md-flex flex-column ml-5 w-100'>
              <div className='d-flex align-items-center justify-content-between mb-3'>
                <SkeletonPreloader height={10} width={108} />
                <SkeletonPreloader height={10} width={108} />
              </div>
              <div className='d-flex align-items-center'>
                <SkeletonPreloader height={8} borderRadius='6px 0 0 6px' />
                {_.times(4, e => {
                  return (
                    <SkeletonPreloader
                      key={e}
                      height={8}
                      borderRadius='0'
                      className='ml-1'
                    />
                  );
                })}
                <SkeletonPreloader height={16} width={80} borderRadius='2px' />
                {_.times(4, e => {
                  return (
                    <SkeletonPreloader
                      key={e}
                      height={8}
                      borderRadius='0'
                      className='mr-1'
                    />
                  );
                })}
                <SkeletonPreloader height={8} borderRadius='0 6px 6px 0' />
              </div>
              <div className='d-flex align-items-center justify-content-between mt-2'>
                <SkeletonPreloader height={10} width={38} />
                <SkeletonPreloader height={10} width={19} />
                <SkeletonPreloader height={10} width={42} />
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex flex-column pt-4 mb-3'>
          <div className='d-flex align-items-center mb-2'>
            <SkeletonPreloader width={21} height={21} circle className='mr-2' />
            <SkeletonPreloader width={88} height={18} />
          </div>
          <SkeletonPreloader
            width={607}
            height={10}
            className='d-none d-md-block'
          />
        </div>
        <IndicatorsPreloader />
        <div className='mt-4 mb-3 d-flex flex-column'>
          <div className='d-flex align-items-center mb-2 mb-md-0'>
            <SkeletonPreloader circle width={21} height={21} />
            <SkeletonPreloader width={88} height={18} className='ml-2' />
          </div>
          <SkeletonPreloader width={607} height={10} />
        </div>
        <div className='d-flex flex-column flex-md-row align-items-center justify-content-between bot-type__item mb-3'>
          <div className='d-flex flex-column flex-md-row align-items-center'>
            <SkeletonPreloader
              width={109}
              height={32}
              borderRadius='6px'
              className='mb-3 mb-md-0'
            />
            <div className='ml-3 pl-3 d-flex flex-column mb-3 mb-md-0'>
              <SkeletonPreloader width={88} height={14} />
              <SkeletonPreloader
                width={177}
                height={38}
                borderRadius='6px'
                className='mt-2'
              />
            </div>
            <div className='ml-8 d-flex flex-column mb-3 mb-md-0'>
              <SkeletonPreloader width={88} height={14} />
              <SkeletonPreloader
                width={177}
                height={38}
                borderRadius='6px'
                className='mt-2'
              />
            </div>
          </div>
          <div className='d-flex align-items-center'>
            <SkeletonPreloader
              width={92}
              height={38}
              borderRadius='6px'
              className='mr-2'
            />
            <SkeletonPreloader width={92} height={38} borderRadius='6px' />
          </div>
        </div>
      </TradeSettingsPreloader.Body>
      <div className='d-flex flex-column flex-md-row justify-content-between p-4'>
        <SkeletonPreloader
          width={151}
          height={40}
          borderRadius='6px'
          className='mb-2 mb-md-0'
          style={{
            background:
              "linear-gradient(90deg, #32354B 0%, rgba(83, 84, 98, 0) 100%)",
            opacity: 0.5,
          }}
        />
        <SkeletonPreloader
          width={184}
          height={40}
          borderRadius='6px'
          style={{
            background:
              "linear-gradient(90deg, #32354B 0%, rgba(83, 84, 98, 0) 100%)",
            opacity: 0.5,
          }}
        />
      </div>
    </TradeSettingsPreloader.Wrapper>
  );
};

TradeSettingsPreloader.Wrapper = styled.div`
  .bot-type__item {
    cursor: pointer;
    border-radius: 8px;
    background: #32354e;
    opacity: 0.5;
    padding: 27px 32px;
  }

  .field + .field {
    margin-top: 12px;
  }

  .indicator {
    background: #2e3148;
    border-radius: 8px;
    padding: 12px 24px;
  }

  .indicator + .indicator {
    margin-top: 8px;
  }

  .martingale {
    padding: 24px;
    background: #2a2c42;
    border-radius: 8px;
  }
`;

TradeSettingsPreloader.Body = styled.div`
  padding: 0 111px;

  @media (max-width: 576px) {
    padding: 0 1rem;
  }
`;

export default TradeSettingsPreloader;
