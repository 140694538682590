import React from "react";
import styled from "styled-components";
import { ReactComponent as Empty } from "assets/icons/bot/backtestEmpty.svg";

const BacktestsEmptyState = () => {
  return (
    <div className='d-flex flex-column align-items-center mt-4 text-center px-4'>
      <Empty />
      <BacktestsEmptyState.Title>
        No backtests at the moment
      </BacktestsEmptyState.Title>
      <BacktestsEmptyState.Description>
        Run new backtest to teach the the genetic algorithm in order to handle
        your deals better
      </BacktestsEmptyState.Description>
    </div>
  );
};

BacktestsEmptyState.Title = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #ffffff !important;
  margin: 40px 0 0 0;
`;

BacktestsEmptyState.Description = styled.p`
  color: rgba(255, 255, 255, 0.75) !important;
  margin: 8px 0 32px 0;
  font-size: 0.875rem;
  font-weight: 400;
`;

export default BacktestsEmptyState;
