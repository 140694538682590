import React from "react";

import CriteriaSettingCard from "./CriteriaSettingCard";

const CriteriasSection = ({
  criteriasValues = [],
  handleToggleCriteria,
  handleChangePower,
}) => {
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex justify-content-between'>
        <p className='font-weight-500'>Criterias</p>
        <p className='text-white-35 font-sm'>Power of criteria</p>
      </div>
      {criteriasValues.map(criteria => (
        <CriteriaSettingCard
          key={criteria.code?.value}
          criteria={criteria}
          handleToggleCriteria={handleToggleCriteria}
          handleChangePower={handleChangePower}
        />
      ))}
    </div>
  );
};

export default CriteriasSection;
