import React from "react";
import { CardHeader } from "reactstrap";

import { ReactComponent as SetttingsIcon } from "assets/icons/general/setting-4.svg";
import EditButton from "../EditButton";

const DefaultHeader = ({ title = "", onEditButtonClick, isWithEditButton }) => (
  <CardHeader className='pt-4 px-4 pb-1'>
    <div className='d-flex flex-row justify-content-between align-items-center'>
      <div className='d-flex flex-row align-items-center'>
        <SetttingsIcon className='mr-2' />
        <p className='mb-0 text-white font-weight-500'>
          {title || "Bot settings"}
        </p>
      </div>
      {isWithEditButton ? (
        <EditButton className='my-auto' onClick={onEditButtonClick} />
      ) : null}
    </div>
  </CardHeader>
);

export default DefaultHeader;
