import React from "react";
import { CardHeader } from "reactstrap";

import { ReactComponent as Title } from "assets/icons/general/receiptsearch.svg";
import { ButtonsGroup } from "shared/ui";
import MarketFilters from "./MarketFilters";
import { TABLE_GROUP_BUTTONS } from "models/enum/tables";

const MarketHeader = ({
  handleRefresh,
  currentCredId,
  loadingOrders,
  loadingTrades,
  tableView,
  setTableViw,
  handleFilter,
  setStatusFilter,
  sideFilter,
  setSideFilter,
  statusFilter,
}) => {
  return (
    <CardHeader className='d-flex align-items-center px-4 pt-4 pb-3'>
      <div className='d-flex align-items-center mr-4'>
        <Title />
        <p className='mb-0 ml-2 font-weight-500 text-white'>History</p>
      </div>
      <ButtonsGroup
        group={TABLE_GROUP_BUTTONS}
        current={tableView}
        setCurrent={setTableViw}
        disable={!currentCredId}
      />

      <MarketFilters
        tableView={tableView}
        handleFilter={handleFilter}
        currentCredId={currentCredId}
        loadingTrades={loadingTrades}
        loadingOrders={loadingOrders}
        sideFilter={sideFilter}
        setSideFilter={setSideFilter}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        handleRefresh={handleRefresh}
      />
    </CardHeader>
  );
};

export default MarketHeader;
