import styled from "styled-components";

const ResultListHeader = styled.div`
  border-bottom: 1px solid rgba(29, 30, 42, 0.1);

  & p {
    color: #333333;
  }
`;

export default ResultListHeader;
