import { createSelector } from "reselect";

export const selectIsDashboardLoading = state => state.dashboard.loading;
export const selectDashboard = state => state.dashboard.data;
export const selectSkippedMainWizard = state =>
  state.dashboard.data?.wizard?.skipped;
export const selectIsCredentialsCreated = state =>
  state.dashboard.data?.wizard?.credentials;
const result = state => state.search.result;

export const credentialsSelector = createSelector([result], (result = []) => {
  return result.filter(r => r.type === "Credentials");
});

export const investmentsSelector = createSelector([result], (result = []) => {
  return result.filter(r => r.type === "Investment");
});

export const botsSelector = createSelector([result], (result = []) => {
  return result.filter(r => r.type === "Bot");
});

export const dealsSelector = createSelector([result], (result = []) => {
  return result.filter(r => r.type === "Deal");
});

export const strategiesSelector = createSelector([result], (result = []) => {
  return result.filter(r => r.type === "Strategy");
});

export const channelsSelector = createSelector([result], (result = []) => {
  return result.filter(r => r.type === "Channel");
});

export const signalsSelector = createSelector([result], (result = []) => {
  return result.filter(r => r.type === "Signal");
});

export const agreementsSelector = createSelector([result], (result = []) => {
  return result.filter(r => r.type === "Agreement");
});
