import React from "react";
import styled from "styled-components";
import { Card, CardHeader, Col, Row } from "reactstrap";
import classNames from "classnames";

import { ReactComponent as CandlestickChart } from "assets/icons/bot/CandlestickChart.svg";
import { ReactComponent as Balance } from "assets/icons/general/Balance.svg";
import { formatValueWithAsset } from "helpers/valuesFormatters";

const Bot = ({ bot = {} }) => {
  return (
    <Card>
      <Bot.CardHeader className='p-3'>
        <Row>
          <Col xs='4'>
            <div className='d-flex'>
              <Balance />
              <p className='text-white-35 mb-0 ml-1 mr-2'>
                {bot.investment && bot.marketVolume
                  ? bot.marketVolume + " " + bot.investment.asset
                  : 0}
              </p>
              <CandlestickChart />
              <p className='text-white-35 mb-0 ml-1'>
                {bot.period ? bot.period : 0}
              </p>
            </div>
          </Col>
          <Col xs='5'>
            <div className='d-flex'>
              <div className='flex-column mr-3'>
                <p className='text-white-35'>Total profit: </p>
                <p
                  className={classNames({
                    "text-success": bot.totalProfit >= 0,
                    "text-warning": bot.totalProfit < 0,
                  })}
                >
                  {formatValueWithAsset(bot.totalProfit, bot.investment.asset)}
                </p>
              </div>
              <div className='flex-column'>
                <p className='text-white-35'>Daily profit: </p>
                <p
                  className={classNames({
                    "text-success": bot.dailyProfit >= 0,
                    "text-warning": bot.dailyProfit < 0,
                  })}
                >
                  {formatValueWithAsset(bot.dailyProfit, bot.investment.asset)}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Bot.CardHeader>
    </Card>
  );
};

Bot.CardHeader = styled(CardHeader)`
  background: #2e3148 !important;
`;

Bot.IconButton = styled(CardHeader)`
  cursor: pointer;
`;

Bot.AssetBadge = styled.div`
  border-radius: 4px;

  &.danger {
    background: rgba(253, 93, 147, 0.1);
    border: 1px solid rgba(253, 93, 147, 0.2);
  }
  &.success {
    background: rgba(63, 203, 176, 0.1);
    border: 1px solid rgba(63, 203, 176, 0.2);
  }
`;

export default Bot;
