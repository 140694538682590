import React from "react";

import { ConfigurationProvider } from "context/bot/ConfigurationContext";
import Configuration from "../wizard/Configuration";

const ConfigurationWrapper = ({
  setIsSubmit,
  currentStep,
  loadingWizard,
  botData,
  goBack,
}) => {
  return (
    <ConfigurationProvider>
      <Configuration
        goBack={goBack}
        currentStep={currentStep}
        loadingWizard={loadingWizard}
        botData={botData}
        setIsSubmit={setIsSubmit}
      />
    </ConfigurationProvider>
  );
};
export default ConfigurationWrapper;
