export const selectCurrentPlan = state => state.me?.data?.subscription;
export const selectMe = state => state.me;
export const selectMeLoading = state => state.me.loading;
export const selectMeData = state => state.me.data;
export const selectWizard = state => state.me.data.wizard || {};
export const selectIsNeedOnboarding = state => {
  if (typeof state?.me?.data?.wizard?.skipped === "undefined") return false;
  const { skipped, credentials } = state.me.data.wizard;
  return (!skipped && !credentials) || (!credentials && !skipped);
};
export const selectWizardSteps = state => {
  if (!state.me.data?.wizard) return null;
  const { ...steps } = state.me.data.wizard;
  return steps;
};
