import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CandleConditionHeader from "./components/CandleConditionHeader";
import { AddButton } from "shared/ui/buttons";
import {
  selectCandleCondition,
  selectCandlePatterns,
} from "store/candleConditions/selectors";
import CandleCondition from "./components/CandleCondition";
import { getCandlePatterns } from "store/candleConditions/actions";
import PreloaderCandleCondition from "./components/CandleCondition/ui/PreloaderCandleCondition";
import { CandleSettingsBlock } from "features/blocks";

const CandleConditions = ({ candlesData = [], setIsOpenSettings }) => {
  const [isShowCreationBlock, setIsShowCreationBlock] = useState(false);
  const [isEditionCandle, setIsEditionCandle] = useState(false);

  const dispatch = useDispatch();

  const { data: candlesInitData } = useSelector(selectCandleCondition);
  const { data: patternsInitData, loading } = useSelector(selectCandlePatterns);

  useEffect(() => {
    if (!patternsInitData?.length) {
      dispatch(getCandlePatterns());
    }
  }, [dispatch, patternsInitData]);

  const toggleShowCreationBlock = () => {
    setIsShowCreationBlock(prev => !prev);
    setIsOpenSettings(prev => !prev);
  };

  return (
    <div className='mt-sm pt-2'>
      <CandleConditionHeader />
      {loading ? (
        <PreloaderCandleCondition />
      ) : (
        <>
          {patternsInitData?.length &&
            candlesData?.map(candle => (
              <CandleCondition
                key={candle?.id}
                candle={candle}
                patternsInitData={patternsInitData}
                setIsEditionCandle={setIsEditionCandle}
                isEditionCandle={isEditionCandle}
                setIsOpenSettings={setIsOpenSettings}
              />
            ))}
          {isShowCreationBlock ? (
            <CandleSettingsBlock
              candle={candlesInitData}
              patternsInitData={patternsInitData}
              handleClose={toggleShowCreationBlock}
            />
          ) : (
            <AddButton
              isDisabled={isEditionCandle}
              addHover={!isEditionCandle}
              array={candlesData}
              maxLength={2}
              buttonLabel='Add new candle condition'
              className='w-100'
              bgButton='dusky-graphite'
              classNameButtonLabel='ml-2 font-sm font-weight-400 text-muted-indigo'
              toogleEdit={toggleShowCreationBlock}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CandleConditions;
