import React from "react";

import { handleHideBalance } from "shared/helpers/handleHideBalance";

const HeaderBalance = ({ user, balanceHidden }) => {
  return (
    <div className='d-flex flex-column'>
      <p className='mb-0 text-white-35 font-xs'>Balance</p>
      <p className='mb-0 text-white font-weight-700 my-auto font-sm'>
        {handleHideBalance(balanceHidden, user?.holdings?.amount)}
        <span className='pl-1 text-white-35 font-weight-300'>
          {user?.holdings?.currency}
        </span>
      </p>
    </div>
  );
};

export default HeaderBalance;
