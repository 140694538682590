import React from "react";
import { Card, CardHeader } from "reactstrap";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import TablePreloader from "../TablePreloader";

const BotsPreloader = ({ renderHeader }) => {
  return (
    <Card className='mb-0 overflow-hidden'>
      {renderHeader && (
        <CardHeader className='d-flex align-items-center justify-content-between p-4'>
          <div className='d-flex align-items-center'>
            <SkeletonPreloader circle={true} height={16} width={16} />
            <SkeletonPreloader height={12} width={60} className='ml-2' />
          </div>
          <SkeletonPreloader height={12} width={189} />
        </CardHeader>
      )}

      <TablePreloader
        thHeight={8}
        thWidth={[57, 57, 38, 43, 58, 42, 35]}
        tdWidth={[57, 49, 33, 46, 129, 24, 24]}
        tdHeight={12}
        rowTimes={10}
        withCircle={16}
      />
    </Card>
  );
};

export default BotsPreloader;
