import React from "react";
import styled, { keyframes } from "styled-components";
import _ from "lodash";

const SkeletonPreloader = ({
  circle,
  width,
  height,
  count = 1,
  borderRadius,
  ...props
}) => (
  <>
    {_.times(count, e => (
      <SkeletonPreloader.Skeleton
        key={e}
        width={width}
        height={height}
        circle={circle}
        borderRadius={borderRadius}
        {...props}
      />
    ))}
  </>
);

export const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

SkeletonPreloader.Skeleton = styled.span`
  width: ${props => (props.width ? props.width + "px" : "100%")};
  height: ${props => (props.height ? props.height + "px" : "14px")};
  border-radius: ${props =>
    props.circle ? "50%" : props.borderRadius || "19px"};
  background-color: rgba(83, 84, 98, 0.05);
  background-image: linear-gradient(
    90deg,
    #535462 0%,
    rgba(83, 84, 98, 0) 100%
  );
  background-repeat: no-repeat;
  animation: ${skeletonKeyframes} 2s ease-in-out infinite;
  background-size: 200px 100%;
  display: inline-block;
  line-height: 1;
`;

export default SkeletonPreloader;
