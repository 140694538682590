import React, { useState } from "react";
import { Card, CardBody, Tooltip } from "reactstrap";
import styled from "styled-components";
import classnames from "classnames";

import LineWithError from "shared/ui/LineWithError";
import { ReactComponent as GeneticIcon } from "assets/icons/bot/botGenetic1.svg";
import IdWithCopy from "shared/ui/IdWithCopy";
import GeneticHeaderPreloader from "../preloaders/HeaderPreloader";
import { ReactComponent as Clock } from "assets/icons/general/clock.svg";

const GeneticHeader = ({ error, id, data = {}, loading }) => {
  const [isShow, setIsShow] = useState(false);

  const handleToggleTooltip = () => setIsShow(prev => !prev);

  if (loading) return <GeneticHeaderPreloader />;

  return (
    <GeneticHeader.Card>
      <CardBody className='p-4'>
        <div className='d-flex flex-column flex-md-row justify-content-between'>
          <div className='d-flex flex-column flex-md-row align-items-center'>
            <span className='d-inline-block mb-2 mb-md-0'>
              <GeneticIcon width={48} height={48} className='mr-3' />
            </span>
            <div className='d-flex flex-column align-items-center align-items-md-start justify-content-md-between'>
              <div className='d-flex mb-1'>
                <h1 className='text-white m-0'>Genetic algorithm</h1>
              </div>
              <IdWithCopy id={id} />
            </div>
          </div>
          <div className='d-flex'>
            <div className='d-flex mt-2 mt-md-0 flex-column align-items-start align-items-md-end'>
              <span
                className={classnames(
                  "m-0 font-weight-500 font-sm text-capitalize",
                  {
                    "text-success":
                      data.geneticAlgorithm?.status === "evolution",
                    "text-white-35":
                      data.geneticAlgorithm?.status === "terminated",
                    "text-white": data.geneticAlgorithm?.status === "new",
                  },
                )}
                style={{
                  background: "rgba(255, 255, 255, 0.05)",
                  padding: "3.5px 10px",
                  borderRadius: "6px",
                  cursor: data.status === "ready" ? "pointer" : "inherit",
                }}
              >
                {data.geneticAlgorithm?.status}
              </span>
              <div className='d-flex align-items-center'>
                <Clock
                  className='icon-stroke-gray mr-1 cursor-pointer'
                  id='genetic-date'
                  aria-hidden='true'
                />
                <p className='mt-2 text-white-35 font-xs'>
                  {data?.geneticAlgorithm?.createdAt}
                </p>
              </div>
              <Tooltip
                placement='top'
                target='genetic-date'
                isOpen={isShow}
                toggle={handleToggleTooltip}
              >
                This is the date when this genetic algorithm was created by a
                bot
              </Tooltip>
            </div>
          </div>
        </div>
      </CardBody>
      {error && <LineWithError errorMessage={error} />}
    </GeneticHeader.Card>
  );
};

GeneticHeader.Card = styled(Card)`
  border-radius: 8px;
`;

export default GeneticHeader;
