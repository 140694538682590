import { useEffect } from "react";

const useClickOutside = (ref, handler, value) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) handler(value);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref, handler, value]);
};

export default useClickOutside;
