import React from "react";
import { Card } from "reactstrap";

import { ReactComponent as Empty } from "assets/icons/general/greenPlanet.svg";
import ReferralDoughnutChart from "./ui/ReferralChartDoughnut";

const TotalReferrals = ({
  total,
  setHoverCurrentBg,
  hoverCurrentBg,
  hoverredElemId,
  setHoverredElemId,
  chartData,
}) => {
  const handleMouseEnter = index => setHoverredElemId(index);
  const handleMouseLeave = () => setHoverredElemId(null);

  if (chartData?.data?.length)
    return (
      <ReferralDoughnutChart
        chartData={chartData}
        setHoverCurrentBg={setHoverCurrentBg}
        hoverCurrentBg={hoverCurrentBg}
        hoverredElemId={hoverredElemId}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        total={total}
      />
    );
  return (
    <Card className='mt-4'>
      <div className='mt-4 mx-4 mb-5 d-flex justify-content-between align-items-center'>
        <h4 className='font-weight-500 text-white mb-0'>Total referrals</h4>
        <h2 className='h1 text-info m-0'>{total}</h2>
      </div>
      <div className='d-flex align-items-center flex-column mx-4 mb-5'>
        <div className='mb-4'>
          <Empty />
        </div>
        <div className='d-flex align-items-center flex-column'>
          <h2 className='font-weight-500 text-center text-white mb-1'>
            You have no refferals yet
          </h2>
          <p className='text-center text-white-75'>
            Here will be dispayed a pie chart of people who bought one of our
            plans and those who have registered using your referral link
          </p>
        </div>
      </div>
    </Card>
  );
};

export default TotalReferrals;
