import React from "react";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import { Card, CardBody, CardHeader } from "reactstrap";

const CredentialWidgetPreloader = () => {
  return (
    <Card>
      <CardHeader style={{ padding: "24px", background: "#2E3148" }}>
        <div className='d-flex align-items-center'>
          <SkeletonPreloader circle={true} height={41} width={41} />
          <div className='d-flex flex-column ml-3'>
            <SkeletonPreloader height={20} width={110} />
            <SkeletonPreloader height={12} width={234} className='mt-2' />
          </div>
        </div>
      </CardHeader>
      <CardBody style={{ padding: "24px" }}>
        <div className='d-flex align-items-center justify-content-between mt-3 mb-2'>
          <div className='d-flex flex-column'>
            <SkeletonPreloader
              count={2}
              height={12}
              width={81}
              className='mt-2'
            />
          </div>
          <div className='d-flex flex-column'>
            <SkeletonPreloader
              count={2}
              height={12}
              width={121}
              className='mt-2'
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default CredentialWidgetPreloader;
