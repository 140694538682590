import React from "react";
import { CardHeader } from "reactstrap";

import { ReactComponent as Rocket } from "assets/icons/general/rocket-outline.svg";
import Filters from "./Filters";

const RunningDealsHeader = ({ handleFilter, loading, request }) => (
  <CardHeader className='row no-gutters flex-column flex-sm-row align-items-center justify-content-between py-4 px-4'>
    <div className='d-flex align-items-center col-12 col-sm-6'>
      <div>
        <Rocket />
      </div>
      <p className='mb-0 ml-2 text-white font-weight-500 text-nowrap'>
        Running deals
      </p>
    </div>
    <Filters handleFilter={handleFilter} loading={loading} request={request} />
  </CardHeader>
);

export default RunningDealsHeader;
