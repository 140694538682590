import styled from "styled-components";

const StyledValue = styled.p`
  margin-bottom: 0;
  color: ${props =>
    props.amount > 0 ? "#FFFFFF" : "rgba(255, 255, 255, 0.35)"} !important;
  font-weight: 500;
  white-space: nowrap;
`;

export default StyledValue;
