import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { completeOpenDeal, forceRunDeal } from "store/deals/actions";
import { addSnackBar } from "store/snackbar/actions";
import { replaceDurations } from "helpers/replaceDurations";
import {
  formatterToNull,
  formatValue,
  setColorClass,
} from "helpers/valuesFormatters";
import { PercentBadge } from "shared/ui";
import { StyledTableCell, StyledTableRow } from "shared/styled/StyledTable";
import { useRedirect } from "hooks/useRedirect";
import { Button } from "shared/ui/buttons";

const RunningDealsTableRow = ({ item, total }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const handleRedirect = useRedirect();

  const handleForceComplete = async (event, idDeal) => {
    event.stopPropagation();
    setIsSubmitted(true);
    if (!item.actions.complete) return;
    const res = await dispatch(completeOpenDeal(idDeal, "running"));
    setIsSubmitted(false);
    return setResponseMessages(res);
  };

  const handleForceRun = async (event, idDeal) => {
    event.stopPropagation();
    setIsSubmitted(true);
    if (!item.actions.run) return;
    const res = await dispatch(forceRunDeal(idDeal, "running"));
    setIsSubmitted(false);
    return setResponseMessages(res);
  };

  const setResponseMessages = res => {
    if (res?.item?.error && !res?.errors) {
      dispatch(addSnackBar("error", res.item.error));
    } else if (!res?.errors) {
      dispatch(addSnackBar("success", res.message));
    }
    if (res?.errors) {
      dispatch(addSnackBar("error", res.message));
    }
  };

  const getRunningDealCellValue = () => {
    if (!item?.actions.complete && item?.status === "running")
      return <p className='text-white-35'>Completing</p>;
    if (!item?.actions.run && item?.status === "ready")
      return <p className='text-white-35'>Running</p>;
    if (item?.status === "running" && item?.actions.complete) {
      return (
        <Button
          outline
          size='middle'
          color='red'
          className='text-white'
          loading={isSubmitted}
          onClick={e => handleForceComplete(e, item?.id)}
        >
          Complete
        </Button>
      );
    } else if (item?.status === "ready" && item?.actions.run) {
      return (
        <Button
          outline
          size='middle'
          onClick={e => handleForceRun(e, item?.id)}
          color='success'
          loading={isSubmitted}
          className='text-white'
        >
          Run
        </Button>
      );
    }
  };

  return (
    <StyledTableRow
      onMouseDown={e => handleRedirect(e, `/deals/${item.id}`)}
      className='cursor-pointer'
      total={total}
    >
      <StyledTableCell status={item.status}>
        <div className='d-flex align-items-center text-nowrap'>
          <p className='mb-0 text-white font-weight-500'>
            {formatterToNull(item.invested.amount)}
          </p>
          <p className='mb-0 ml-1 mr-2 text-white-35 font-weight-300'>
            {item.invested.asset}
          </p>
          <PercentBadge
            value={item.invested.percent}
            showZeroValue
            isColorSpectrum
          />
        </div>
      </StyledTableCell>

      <td>
        <div className='d-flex align-items-center text-nowrap'>
          <p className='mb-0 text-white font-weight-500'>
            {formatterToNull(item.purchased.amount)}
          </p>
          <p className='mb-0 ml-1 mr-2 text-white-35 font-weight-300'>
            {item.purchased.asset}
          </p>
        </div>
      </td>

      <td>
        <div className='d-flex align-items-center text-nowrap'>
          <p
            className={`mb-0 font-weight-500 ${setColorClass(item.currentState.amount, "text-white-35")}`}
          >
            {formatValue(item.currentState.amount)}
          </p>
          <p className='mb-0 ml-1 mr-2 text-white-35 font-weight-300'>
            {item.currentState.currency || item.market.quote}
          </p>
          <PercentBadge value={item.currentState.percent} showZeroValue />
        </div>
      </td>

      <td>
        <div className='d-flex align-items-center justify-content-between text-nowrap'>
          <p className='mb-0 mr-3 text-white font-weight-500'>
            {replaceDurations(item.duration)}
          </p>
        </div>
      </td>

      <td>{getRunningDealCellValue()}</td>
    </StyledTableRow>
  );
};

export default RunningDealsTableRow;
