import React from "react";

import { RETURN_OPTIONS } from "models/filters";
import { SelectInput } from "shared/ui";

const DealsFilters = ({ onHandleFilter, filter = {} }) => {
  return (
    <div className='w-25'>
      <SelectInput
        isClearable
        className='mb-0 w-100'
        placeholder='Return'
        value={RETURN_OPTIONS.find(option => option.value === filter.profit)}
        name='return'
        onChange={option => onHandleFilter({ result: { eq: option?.value } })}
        options={RETURN_OPTIONS}
      />
    </div>
  );
};

export default DealsFilters;
