import React from "react";
import { Card } from "reactstrap";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import TablePreloader from "shared/ui/TablePreloader";

const RunningPreloader = () => {
  return (
    <Card className='mb-4 p-4'>
      <div className='d-flex flex-column flex-md-row align-items-center justify-content-between mb-3'>
        <div className='d-flex align-items-center'>
          <SkeletonPreloader height={20} width={20} />
          <SkeletonPreloader height={16} width={102} className='ml-2 mr-3' />
          <SkeletonPreloader height={16} width={60} />
        </div>
        <div className='d-flex align-items-center mt-3 mt-md-0'>
          <SkeletonPreloader height={38} width={145} borderRadius='6px' />
          <SkeletonPreloader height={38} width={145} borderRadius='6px' />
        </div>
      </div>

      <TablePreloader
        thHeight={12}
        thWidth={[44, 70, 68, 70]}
        tdWidth={[65, 90, 90, 100]}
        tdHeight={14}
        rowTimes={5}
        withCircle={20}
      />
    </Card>
  );
};

export default RunningPreloader;
