import React from "react";
import { FormGroup } from "reactstrap";
import { DelayInput } from "react-delay-input";

import { SIDE_OPTIONS } from "models/deal/enum/tables";
import { SelectInput, SkeletonPreloader } from "shared/ui";

const TradesFilters = ({ onHandleFilter, itemsLoading, loadingPage }) => {
  if (loadingPage) {
    return (
      <div className='d-flex align-items-center'>
        <SkeletonPreloader
          height={38}
          width={145}
          borderRadius='8px'
          className='mr-2'
        />
        <SkeletonPreloader height={38} width={145} borderRadius='8px' />
      </div>
    );
  }

  return (
    <div className='d-flex flex-row align-items-center flex-wrap'>
      <FormGroup
        className='statistic-filter mb-0 mr-2 mb-0 mt-2 mt-sm-0'
        style={{ width: "145px" }}
      >
        <SelectInput
          isClearable
          placeholder='Side'
          name='type'
          options={SIDE_OPTIONS}
          onChange={option =>
            onHandleFilter({
              type: { eq: option?.value },
            })
          }
          isLoading={loadingPage}
          isDisabled={itemsLoading}
        />
      </FormGroup>
      <FormGroup
        className='statistic-filter mb-0 mt-2 mt-sm-0'
        style={{ width: "145px" }}
      >
        <DelayInput
          autoComplete='off'
          delayTimeout={700}
          type='text'
          name='id'
          className='form-control'
          placeholder='Order ID'
          onChange={({ target }) =>
            onHandleFilter({
              orderNumber: target.value ? { like: target.value } : {},
            })
          }
          disabled={itemsLoading}
        />
      </FormGroup>
    </div>
  );
};

export default TradesFilters;
