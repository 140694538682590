import React from "react";
import moment from "moment/moment";
import styled from "styled-components";

import { formatterToNull } from "helpers/valuesFormatters";
import { ReactComponent as Buy } from "assets/icons/property/Buy.svg";
import { ReactComponent as Sell } from "assets/icons/property/Sell.svg";
import { STATUS_COLORS } from "views/credentials/models";
import { OrdersProgressBar } from "shared/ui";
import { StyledStatusLabel } from "shared/styled/StyledStatusLabel";
const OrdersTableItem = ({ order }) => {
  return (
    <tr>
      <td>
        {order.type === "BUY" ? (
          <Buy width='18px' height='18px' />
        ) : (
          <Sell width='18px' height='18px' />
        )}
      </td>
      <td>
        <p className='mb-0 text-white font-weight-300 font-sm'>
          {order.orderNumber || ""}
        </p>
      </td>
      <td>
        <OrdersTableItem.Amount amount={order.rate}>
          {formatterToNull(order.rate)}
        </OrdersTableItem.Amount>
      </td>
      <td>
        <div className='d-flex align-items-center'>
          <OrdersProgressBar filledPercent={order.filledPercent} />
          <p className='mb-0 font-sm font-weight-500 text-white-35'>
            {`${order.filledPercent || 0}%`}
          </p>
        </div>
      </td>
      <td>
        <div className='d-flex align-orders-center'>
          <OrdersTableItem.Amount amount={order.total.amount}>
            {formatterToNull(order.total.amount)}
          </OrdersTableItem.Amount>
          <OrdersTableItem.Currency>
            {order.total.currency}
          </OrdersTableItem.Currency>
        </div>
      </td>
      <td>
        <div className='d-flex align-orders-center'>
          <OrdersTableItem.Amount amount={order.executedAmount.amount}>
            {formatterToNull(order.executedAmount.amount)}
          </OrdersTableItem.Amount>
          <OrdersTableItem.Currency>
            {order.executedAmount.currency}
          </OrdersTableItem.Currency>
        </div>
      </td>
      <td>
        <div className='d-flex align-orders-center'>
          <OrdersTableItem.Amount amount={order.executedQuantity.amount}>
            {formatterToNull(order.executedQuantity.amount)}
          </OrdersTableItem.Amount>
          <OrdersTableItem.Currency>
            {order.executedQuantity.currency}
          </OrdersTableItem.Currency>
        </div>
      </td>
      <td>
        <div className='d-flex align-orders-center'>
          <OrdersTableItem.Amount amount={order.fee.amount}>
            {formatterToNull(order.fee.amount)}
          </OrdersTableItem.Amount>
          <OrdersTableItem.Currency>
            {order.fee.currency}
          </OrdersTableItem.Currency>
        </div>
      </td>
      <td>
        <OrdersTableItem.Amount amount={order.tradesCount}>
          {formatterToNull(order.tradesCount)}
        </OrdersTableItem.Amount>
      </td>
      <td>
        <StyledStatusLabel color={STATUS_COLORS[order.status]}>
          {order.status.replace("_", " ") || ""}
        </StyledStatusLabel>
      </td>
      <td>
        <div className='d-flex align-orders-center'>
          <OrdersTableItem.Currency>
            {moment(order.createdAt).format("DD.MM.YY")}
          </OrdersTableItem.Currency>
          <OrdersTableItem.Amount amount={1} className='ml-1'>
            {moment(order.createdAt).format("HH:mm")}
          </OrdersTableItem.Amount>
        </div>
      </td>
    </tr>
  );
};

OrdersTableItem.Currency = styled.p`
  font-weight: 400;
  margin: 0 0 0 4px;
  color: #fff !important;
  font-size: 0.875rem;
`;

OrdersTableItem.Amount = styled.p`
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 0;
  color: ${props =>
    props.amount > 0
      ? "rgba(255, 255, 255)"
      : "rgba(255, 255, 255, 0.35)"} !important;
  white-space: nowrap;
`;

export default OrdersTableItem;
