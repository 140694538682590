import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { ReactComponent as EmptyPlanets } from "assets/icons/general/planets.svg";

const UpgradeToPremium = ({ title, text }) => {
  return (
    <div
      className='d-flex align-items-center flex-column justify-content-center'
      style={{ padding: "80px 0px", margin: "auto" }}
    >
      <EmptyPlanets role='img' />
      <div
        className='d-flex align-items-center flex-column justify-content-center'
        style={{ marginBottom: "40px", marginTop: "32px" }}
      >
        <h2 className='font-weight-500 mb-2 text-white'>{title}</h2>
        <p
          className='text-white-75 font-weight-400 m-0 font-sm text-center'
          style={{ whiteSpace: "pre-line" }}
        >
          {text}
        </p>
      </div>
      <StyledButton className='d-flex align-items-center'>
        <Link to='/billing' className='text-white font-sm'>
          Upgrade to premium
        </Link>
      </StyledButton>
    </div>
  );
};

const StyledButton = styled.div`
  background-color: var(--info);
  padding: 11px 24px;
  border-radius: 6px;
`;

export default UpgradeToPremium;
