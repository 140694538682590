import React from "react";
import _ from "lodash";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const AllocationRowsPreload = () => {
  return (
    <div className='d-flex flex-column px-4'>
      {_.times(6, e => {
        return (
          <div
            className='d-flex align-items-center justify-content-between px-4 py-2'
            style={{
              background: "#2E3148",
              borderRadius: "8px",
              marginBottom: "6px",
            }}
            key={e}
          >
            <div className='d-flex align-items-center'>
              <SkeletonPreloader circle width={24} height={24} />
              <SkeletonPreloader width={126} height={14} className='ml-2' />
            </div>

            <SkeletonPreloader width={147} height={14} />
            <SkeletonPreloader width={248} height={8} />
            <SkeletonPreloader width={92} height={38} borderRadius='6px' />
            <SkeletonPreloader width={72} height={14} />
          </div>
        );
      })}
    </div>
  );
};

export default AllocationRowsPreload;
