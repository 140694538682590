import createAction from "store/thunkFactory";
import { get } from "lodash";

export const fetchProducts = signal =>
  createAction("PRODUCTS", fetch => {
    return fetch.get("/app/products", { signal });
  });

export const managePaymets = () => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/app/stripe/billing-portal/sessions");
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const createSession = body => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/app/stripe/checkout/sessions", body);
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const getSession = id => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.get(`/app/stripe/checkout/sessions/${id}`);
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const resetProducts = () => ({ type: "RESET_PRODUCTS" });
