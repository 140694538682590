import React from "react";
import PropTypes from "prop-types";

import { fetchRounds, forceStartAgreement } from "store/agreements/actions";
import { selectTradeRequest } from "store/agreements/selectors";
import { SignalProgress, TradeRequestList } from "shared/ui";

const OverviewTab = ({ loadingPage, id, data, onHandleRefresh }) => {
  return (
    <>
      <SignalProgress loading={loadingPage} data={data?.signal} />
      <TradeRequestList
        id={id}
        openTradeRequest={data?.openRound?.tradeRequest}
        goalStatus={data?.openRound?.goal}
        data={data}
        loadingPage={loadingPage}
        actionFetchHistoryTR={fetchRounds}
        selectData={selectTradeRequest}
        pageView='agreement'
        onHandleRefresh={onHandleRefresh}
        onHandleForceStart={forceStartAgreement}
      />
    </>
  );
};

OverviewTab.propTypes = {
  loadingPage: PropTypes.bool,
  id: PropTypes.string,
};

export default OverviewTab;
