import React, { useState } from "react";
import styled from "styled-components";
import { CardHeader, CardBody, CardFooter } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import RebalanceEmptyState from "./components/RebalanceEmptyState";
import RebalanceHistoryFreeSubscription from "views/credentials/pageViews/views/rebalanceHistory/components/RebalanceHistoryFreeSubscription";
import { ReactComponent as Repeat } from "assets/icons/credential/repeat.svg";
import { fetchRebalances, resetRebalances } from "store/credentials/actions";
import { rebalanceHistorySelector } from "store/credentials/selectors";
import StyledCard from "./components/StyledCard";
import TableRow from "./components/TableRow";
import TablePreload from "./components/TablePreload";
import usePagination from "hooks/usePagination";
import useFilterAndSorting from "hooks/useFilterAndSorting";
import useRequestCancellation from "hooks/useRequestCancellation";
import StyledTable from "./components/StyledTable";
import RebalanceFilter from "./components/RebalanceFilter";
import RebalanceHistoryPreloader from "./components/RebalanceHistoryPreloader";
import { REBALANCE_TABLE_HEAD } from "views/credentials/models";
import CustomScrollbars from "shared/ui/CustomScrollbars";
import EmptyState from "shared/ui/state/EmptyState";
import PaginationPanel from "shared/ui/PaginationPanel";
import SortTableHead from "shared/ui/SortTableHead";

const RebalanceHistory = ({ id, isRebalanceLoading, loadingPage }) => {
  const dispatch = useDispatch();
  const { error, data, loading } = useSelector(rebalanceHistorySelector);
  const subscription = useSelector(state => state.me?.data.subscription);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const location = useLocation();
  const { filter, sort, handleSorting, handleFilter } = useFilterAndSorting(
    `/credentials/${id}`,
    () => setIsTableLoading(true),
  );
  const { handlePaginate } = usePagination(`/credentials/${id}`);
  const isFiltersOrSortingOn = ["sort", "filter", "page"].some(v =>
    location.search?.includes(v),
  );
  const { useAbortableEffect } = useRequestCancellation();
  useAbortableEffect(
    signal => {
      dispatch(fetchRebalances(id, location.search, signal)).finally(() =>
        setIsTableLoading(false),
      );
      return () => dispatch(resetRebalances());
    },
    [dispatch, id, location.search],
    true,
  );

  if (loadingPage && isRebalanceLoading && !isTableLoading)
    return <RebalanceHistoryPreloader />;

  if (subscription?.type !== "premium" && data?.items?.length === 0) {
    return (
      <RebalanceHistoryFreeSubscription
        title={"Rebalancing feature is available only for premium users"}
        text={
          "In order to enable it and see detailed history here, please upgrade to premium so that you can use our Rebalancing feature"
        }
      />
    );
  }

  if (loadingPage && !isRebalanceLoading && !isTableLoading) return;

  if (error)
    return (
      <EmptyState
        title={"Unexpected issues, please try again later"}
        desc={null}
      />
    );

  if (!loading && !data?.items?.length && !isFiltersOrSortingOn) {
    return (
      <RebalanceEmptyState
        title={"You don't have any rebalance history at that moment"}
        text={"Please enable and configure rebalancing"}
      />
    );
  }

  return (
    <StyledCard>
      <CardHeader className='d-flex flex-column flex-md-row justify-content-between py-3 pl-4 pr-3'>
        <div className='d-flex my-auto'>
          <Repeat />
          <StyledTitle className='font-weight-500 ml-2 text-white'>
            Rebalance History
          </StyledTitle>
        </div>
        <RebalanceFilter filter={filter} onHandleFilter={handleFilter} />
      </CardHeader>
      {isTableLoading || loading ? (
        <TablePreload />
      ) : (
        <CardBody className='p-0'>
          <CustomScrollbars
            autoHideTimeout={500}
            autoHideDuration={500}
            autoHeight
            autoHeightMax={Number.MAX_VALUE}
          >
            {!data?.items?.length && location.search ? (
              <EmptyState
                title='No results for your search'
                desc='Please adjust your filters, we have not found anything by your request.'
              />
            ) : (
              <StyledTable className='mb-0'>
                <SortTableHead
                  handleSorting={handleSorting}
                  sort={sort}
                  theadItems={REBALANCE_TABLE_HEAD}
                />
                <tbody className='font-sm'>
                  {data?.items?.map(item => (
                    <TableRow key={item.id} item={item} />
                  ))}
                </tbody>
              </StyledTable>
            )}
          </CustomScrollbars>
        </CardBody>
      )}
      <CardFooter className='pb-0'>
        <PaginationPanel
          activePage={data?.page}
          totalItemsCount={data?.total}
          onChange={page => handlePaginate(page)}
          totalPagesView={5}
          isCircle
        />
      </CardFooter>
    </StyledCard>
  );
};

const StyledTitle = styled.p`
  font-size: 16px;
  line-height: 18.75px;
`;

export default RebalanceHistory;
