import styled from "styled-components";

export const ReferralLinkStyled = styled.p`
  background: #2e3148;
  border-radius: 8px;
  padding: 11px 1rem !important;
`;

export const ReferralShareButtonsStyled = styled.div`
  display: flex;
  gap: 0.75rem;
`;
