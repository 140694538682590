import React from "react";
import { Card, CardBody } from "reactstrap";

import HeaderPreloader from "./HeaderPreloader";
import TradeSettingsPreloader from "./TradeSettingsPreloader";

const TradeSettingsWidgetPreloader = () => {
  return (
    <Card>
      <HeaderPreloader />
      <CardBody className='p-4'>
        <TradeSettingsPreloader />
      </CardBody>
    </Card>
  );
};

export default TradeSettingsWidgetPreloader;
