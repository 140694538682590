import React from "react";
import PropTypes from "prop-types";

import OpenTradeRequest from "./OpenTradeRequest/OpenTradeRequest";
import HistoryTradeRequestsList from "./HistoryTradeRequest/HistoryTradeRequestsList";

const TradeRequestList = ({
  id,
  data,
  loadingPage,
  onHandleRefresh,
  pageView,
  actionFetchHistoryTR,
  onHandleForceStart,
  openTradeRequest,
  selectData,
  onHandleOpenNewBuy,
  goalStatus,
}) => (
  <>
    {openTradeRequest ? (
      <OpenTradeRequest
        goalStatus={goalStatus}
        data={openTradeRequest}
        loadingPage={loadingPage}
      />
    ) : null}

    <HistoryTradeRequestsList
      id={id}
      actions={data?.actions}
      forceStarted={data?.forceStarted}
      loadingPage={loadingPage}
      onHandleRefresh={onHandleRefresh}
      actionFetchHistoryTR={actionFetchHistoryTR}
      selectData={selectData}
      pageView={pageView}
      onHandleForceStart={onHandleForceStart}
      onHandleOpenNewBuy={onHandleOpenNewBuy}
    />
  </>
);

TradeRequestList.propTypes = {
  id: PropTypes.string,
  forceStarted: PropTypes.bool,
  loadingPage: PropTypes.bool,
};

export default TradeRequestList;
