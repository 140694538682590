import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SelectInput from "../SelectInput";
import { getUserExchanges, getExchanges } from "store/exchanges/actions";
import {
  exchangesSelector,
  userExchangesSelector,
} from "store/exchanges/selectors";

const ExchangesSelect = ({
  withAllOption,
  withAllExchanges,
  placeholder,
  value,
  isMulti,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const exchanges = useSelector(exchangesSelector);
  const userExchanges = useSelector(userExchangesSelector);

  const fetchExchange = useCallback(
    fetchFunc => {
      setIsLoading(true);
      dispatch(fetchFunc()).finally(() => setIsLoading(false));
    },
    [dispatch],
  );

  const fetchExchanges = useCallback(() => {
    if (!exchanges && withAllExchanges) return fetchExchange(getExchanges);
    if (!userExchanges) return fetchExchange(getUserExchanges);
  }, [exchanges, userExchanges, withAllExchanges, fetchExchange]);

  useEffect(() => {
    fetchExchanges();
  }, [fetchExchanges]);

  const getOptions = () => {
    if ((withAllExchanges && !exchanges) || !userExchanges) return [];
    return [...(withAllExchanges ? exchanges : userExchanges)].map(i => ({
      value: i.code,
      label: i.name,
      ips: i.ips,
      icon: {
        type: "exchanges",
        size: "selectSize",
        code: i.code,
      },
      website: i?.website,
    }));
  };

  const getCurrentValue = () => {
    const options = getOptions();
    if (isMulti)
      return value?.map(item => options.find(option => option.value === item));
    return options.find(option => option.value === value);
  };

  return (
    <SelectInput
      placeholder={placeholder || "Exchange"}
      options={[
        ...(withAllOption
          ? [{ label: "All", value: "", secondLabel: "" }]
          : []),
        ...getOptions(),
      ]}
      value={getCurrentValue()}
      isLoading={isLoading}
      isMulti={isMulti}
      isVirtualized
      {...props}
    />
  );
};

export default ExchangesSelect;
