import styled from "styled-components";

export const ExchangeHeaderPriceStyled = styled.div`
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ExchangeHeaderMarketStyled = styled.p`
  font-size: 1.375rem;
  line-height: 19px;
`;

export const ExchangeHeaderCoinStyled = styled.p`
  font-size: 1rem;
  line-height: 19px;
`;

export const ExchangeHeaderWrapperStyled = styled.div`
  gap: 32px;
`;
