import React from "react";
import styled from "styled-components";
import { ModalBody, ModalHeader, Modal } from "reactstrap";
import PropTypes from "prop-types";

import { CREATE_CHANNEL_CONTENT } from "models/constans/ui/createChannel/content";
import CreateChannelForm from "./ui/CreateChannelForm";

const CreateChannelModal = ({ setIsModalOpen, isModalOpen }) => {
  return (
    <CreateChannelModal.Modal
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(!isModalOpen)}
      modalClassName='modal-black'
    >
      <ModalHeader className='p-0'>
        <p className='h1 modal-title text-white font-weight-500 mb-0'>
          {CREATE_CHANNEL_CONTENT.title}
        </p>
      </ModalHeader>
      <ModalBody className='px-0 pb-2'>
        <CreateChannelForm
          content={CREATE_CHANNEL_CONTENT.form}
          setIsModalOpen={setIsModalOpen}
        />
      </ModalBody>
    </CreateChannelModal.Modal>
  );
};

CreateChannelModal.Modal = styled(Modal)`
  .modal-content {
    max-width: 455px;
    border-radius: 1rem;
    padding: 2rem;
  }
`;

CreateChannelModal.propTypes = {
  setIsModalOpen: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
};

export default CreateChannelModal;
