import React from "react";
import { Card, CardBody, Table, CardHeader } from "reactstrap";
import _ from "lodash";

import useWindowDimensions from "hooks/useWindowDimensions";
import StyledPreloader from "shared/styled/StyledPreloader";

const LastTradesPreloader = () => {
  const { width } = useWindowDimensions();

  return (
    <Card className='mb-3 overflow-hidden h-100'>
      <CardHeader className='d-flex font-weight-500 pt-4 px-4 pb-0'>
        <StyledPreloader
          circle
          height={20}
          width={20}
          className='mr-2 my-auto'
        />
        <StyledPreloader height={12} width={81} className='my-auto' />
      </CardHeader>
      <CardBody>
        <Table>
          <thead>
            <tr>
              {_.times(width > 576 ? 3 : 2, e => (
                <th key={e}>
                  <StyledPreloader height={8} width={57} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {_.times(15, e => (
              <tr key={e}>
                <td className='py-1'>
                  <StyledPreloader height={14} width={97} />
                </td>
                <td className='py-1'>
                  <StyledPreloader height={14} width={147} />
                </td>
                <td className='py-1 d-none d-md-table-cell'>
                  <StyledPreloader height={14} width={69} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default LastTradesPreloader;
