import React from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";

import { formatterToNull } from "helpers/valuesFormatters";
import { Checkbox, Icon, PercentBadge } from "shared/ui";

const MarketTableRow = ({
  item,
  handleInclude,
  handleDeselect,
  handleExclude,
  handleSelect,
  isBinance,
}) => {
  const {
    allCoins,
    excludedCoins,
    coins = [],
  } = useSelector(state => state.botWizard);

  const transformedExcludedCoins = Array.isArray(excludedCoins)
    ? excludedCoins
    : Object.values(excludedCoins);

  const transformedCoins = Array.isArray(coins) ? coins : Object.values(coins);

  const isBaseNotExcluded = base =>
    transformedExcludedCoins?.every(el => el !== base);
  const isBaseIncluded = base => transformedCoins?.some(el => el === base);

  const handleChange = base => {
    if (allCoins) {
      return isBaseNotExcluded(base)
        ? handleExclude(base)
        : handleInclude(base);
    }
    return transformedCoins?.every(el => el !== base)
      ? handleSelect(base)
      : handleDeselect(base);
  };

  return (
    <tr
      key={item.symbol}
      className={classnames("cursor-pointer", {
        excluded: allCoins || isBaseIncluded(item.market.base),
        deselect:
          transformedExcludedCoins?.some(el => el === item.market.base) &&
          allCoins,
      })}
      onClick={() => handleChange(item.market.base)}
    >
      <td>
        <div className='d-flex align-items-center text-nowrap'>
          <Checkbox
            withMinus={allCoins}
            checked={
              (isBaseNotExcluded(item.market.base) && allCoins) ||
              (isBaseIncluded(item.market.base) && !allCoins)
            }
          />

          <Icon size='thumb' code={item.market.base} className='ml-3' />
          <p className='mb-0 font-weight-500 text-white ml-2 mr-1 font-md'>
            {item.market.base}
          </p>
          <p className='mb-0 font-weight-300 text-white-35 font-md'>{`/ ${item.market.quote}`}</p>
        </div>
      </td>
      <td>
        <div className='d-flex align-items-center'>
          <p className='mb-0 text-white font-weight-500 font-md'>
            {formatterToNull(item.amount)}
          </p>
          <p className='mb-0 font-weight-300 text-white-35 ml-1 font-md'>
            {item.market.quote}
          </p>
        </div>
      </td>
      <td>
        <p className='mb-0 text-white font-weight-500 font-md'>
          {formatterToNull(item.tradeCount)}
        </p>
      </td>
      <td>
        <p className='mb-0 text-white font-weight-500 font-md'>
          {formatterToNull(item.close)}
        </p>
      </td>
      <td>
        <PercentBadge
          value={isBinance ? item.dailyChange * 100 : item.dailyChange}
          showZeroValue
        />
      </td>
      <td>
        <p className='mb-0 text-white font-weight-500 font-md'>
          {formatterToNull(item.high)}
        </p>
      </td>
      <td>
        <p className='mb-0 text-white font-weight-500 font-md'>
          {formatterToNull(item.low)}
        </p>
      </td>
    </tr>
  );
};

export default MarketTableRow;
