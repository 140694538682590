import { Card } from "reactstrap";
import styled from "styled-components";

export const EmptyRequestsCardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 80px;
  border-radius: 8px !important;

  @media (max-width: 768px) {
    padding: 30px;
  }

  svg {
    rect {
      fill-opacity: 0.6;
    }
  }
`;

export const EmptyRequestsTitleStyled = styled.p`
  margin: 40px 0 0 0;
  color: #ffffff !important;
  font-weight: 500;
`;

export const EmptyRequestsDescriptionStyled = styled.p`
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.75) !important;
  font-size: 14px;
`;

export const EmptyRequestsRunBtnStyled = styled.div`
  button {
    color: #1d1e2a;
    background: #5fd3bc;
    padding: 12px 32px;
    margin: 0;
  }
  .btn:focus,
  .btn:active,
  .btn:hover,
  .btn:active:focus,
  .btn:active:hover {
    background: rgb(0, 242, 195, 0.5) !important;
    color: #1d1e2a !important;
  }
`;

export const EmptyRequestsLoaderStyled = styled.div`
  display: flex;
  justify-content: center;
  background: rgb(0, 242, 195, 0.35);
  border-radius: 8px;
  height: 43px;
  width: 123px;
`;
