export const investedPercentColors = percent => {
  switch (true) {
    case +percent > 0 && +percent < 5:
      return "text-success";

    case +percent >= 5 && +percent < 15:
      return "text-yellow";

    case +percent >= 15 && +percent < 45:
      return "text-gold";

    case +percent >= 45 && +percent < 70:
      return "text-orange";

    case +percent >= 70.0:
      return "text-danger";

    default:
      return "text-white-35";
  }
};
