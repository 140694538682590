import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import styled from "styled-components";

import { ReactComponent as Info } from "assets/icons/general/infoIcon.svg";

const HelpTooltip = ({
  className,
  index,
  placement = "top",
  children,
  autohide,
  opacity,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const toggleTooltip = () => setIsTooltipOpen(prev => !prev);

  return (
    <span className={className}>
      <HelpTooltip.InfoIcon
        className='cursor-pointer'
        id={`info-${index}`}
        data-testid={`info-${index}`}
        opacity={opacity}
      />
      <Tooltip
        autohide={autohide}
        placement={placement}
        isOpen={isTooltipOpen}
        toggle={toggleTooltip}
        target={`info-${index}`}
      >
        {children}
      </Tooltip>
    </span>
  );
};

HelpTooltip.InfoIcon = styled(Info)`
  path {
    ${({ opacity }) => opacity && `stroke-opacity: ${opacity}`};
  }
`;

export default HelpTooltip;
