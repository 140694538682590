import React from "react";
import styled from "styled-components";
import classNames from "classnames";

import BigOptionIcon from "./BigOptionIcon";
import { getColor, getCursor, getIsChecked } from "./utils";
import Checkbox from "../Checkbox";

const OptionBigSize = ({
  isDark,
  label,
  secondLabel,
  icon,
  color,
  isMulti,
  selectedValues = [],
  value,
  isDisabled,
  menuIsOpen,
  disabledOptionIcon,
  ...props
}) => {
  const getRestSelected = () => {
    if (!isMulti) return null;
    if (selectedValues.length > 1 && !menuIsOpen)
      return (
        <Badge className='text-white text-nowrap'>
          {`+ ${selectedValues.length - 1}`}
        </Badge>
      );
    return null;
  };

  return (
    <StyledOption className='input-select pr-1'>
      <div className='input-select__single-value d-flex justify-content-between d-inline-block'>
        <div className='d-flex align-items-center w-100'>
          <BigOptionIcon
            isCustomLogo={
              !icon || label === "All" || label === "" || props?.logo
            }
            icon={icon}
            logo={props?.logo}
          />

          <div className='container ml-3 p-0'>
            <Label
              className={classNames("option-value font-weight-500 font-md")}
              color={getColor(isDisabled, color, isDark)}
              cursor={getCursor(isDisabled)}
            >
              {label}
            </Label>
            {secondLabel ? (
              <SubLabel className=' mb-0 text-white-75 font-weight-400'>
                {secondLabel}
              </SubLabel>
            ) : null}
          </div>
          {getRestSelected()}
        </div>
        {isMulti ? (
          <Checkbox
            checked={getIsChecked(selectedValues, value)}
            type='checkbox'
            className='position-relative my-auto pl-3'
            readOnly
          />
        ) : null}
        {isDisabled && disabledOptionIcon}
      </div>
    </StyledOption>
  );
};

const Label = styled.span`
  cursor: ${props => props.cursor} !important;
  color: ${props => props.color};
`;

const SubLabel = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledOption = styled.div`
  .input-select__single-value {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

const Badge = styled.span`
  background: var(--dusk-steel);
  padding: 2px 8px;
  border-radius: 32px;
  margin-left: 0.2rem;
`;

export default OptionBigSize;
