import React from "react";

const Error = ({ error, dataTestId = "error" }) => (
  <div
    data-testid={dataTestId}
    className='h-100 w-100 d-flex text-center my-auto'
  >
    <h3 className='text-danger' data-testid={`${dataTestId}-message`}>
      {error}
    </h3>
  </div>
);

export default Error;
