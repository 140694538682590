import React, { useCallback, useEffect, useId, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  resetCandle,
  setCandleConditions,
  setCandleNature,
  setCandleSignal,
} from "store/candleConditions/actions";
import { StyledCandleConditionWrap } from "views/bots/wizard/Configuration/candleConditions/components/CandleCondition/index.styles";
import {
  CANDLE_NEUTRAL_OPTIONS,
  CANDLE_OPTIONS,
} from "views/bots/models/options";
import CandleSettings from "./components/CandleSettings";
import CandleBTNGroup from "./components/CandleSettings/ui/CandleBTNGroup";
import {
  editCandleSetting,
  setCandleConditionsWizard,
} from "store/bots/wizard/actions";
import { selectCandleCondition } from "store/candleConditions/selectors";
import { BadgeCandle } from "shared/ui/badges";

const CandleSettingsBlock = ({
  isEdit,
  candle = {},
  patternsInitData,
  handleClose,
}) => {
  const [isValid, setIsValid] = useState(true);
  const [isProcessed, setIsProcessed] = useState(false);

  const { data: candlesInitData } = useSelector(selectCandleCondition);

  const id = useId();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEdit && !isProcessed) {
      dispatch(setCandleConditions(candle));
      setIsProcessed(true);
    }
  }, [isEdit, candle, dispatch, isProcessed]);

  const handleSetCandleSignal = useCallback(
    value => dispatch(setCandleSignal(value)),
    [dispatch],
  );

  const handleSetCandleNature = useCallback(
    value => dispatch(setCandleNature(value)),
    [dispatch],
  );

  const handleSubmit = e => {
    e.preventDefault();
    if (isEdit) {
      dispatch(editCandleSetting(candlesInitData));
      handleClose();
      dispatch(resetCandle());
      return;
    }
    if (candle?.pattern && candle?.percent) {
      dispatch(setCandleConditionsWizard({ id, ...candle }));
      handleClose();
      dispatch(resetCandle());
      setIsValid(true);
      return;
    }
    setIsValid(false);
  };

  return (
    <StyledCandleConditionWrap className='d-flex flex-column flex-md-row align-items-md-center mb-2'>
      <div className='d-flex flex-column flex-md-row'>
        <div>
          <p className='text-white-75 font-sm mb-2'>Signal</p>
          <BadgeCandle
            candle={candlesInitData}
            candlesOptions={CANDLE_OPTIONS}
            param={candlesInitData?.signal}
            setFieldValue={handleSetCandleSignal}
          />
        </div>
        <CandleSettings
          candle={candle}
          patternsInitData={patternsInitData}
          isValid={isValid}
        />
      </div>
      <div className='d-flex flex-column flex-md-row ml-md-auto mr-0 mt-3 mt-md-0'>
        <BadgeCandle
          candle={candlesInitData}
          candlesOptions={CANDLE_NEUTRAL_OPTIONS}
          param={candlesInitData?.nature}
          setFieldValue={handleSetCandleNature}
        />
        <CandleBTNGroup
          isEdit={isEdit}
          className='d-flex justify-content-between justify-content-md-start ml-md-2 mt-2 mt-md-0'
          handleClose={handleClose}
          formValues={candlesInitData}
          handleSubmit={handleSubmit}
        />
      </div>
    </StyledCandleConditionWrap>
  );
};

export default CandleSettingsBlock;
