import React from "react";
import styled from "styled-components";
import { FormGroup, CardHeader } from "reactstrap";
import { DelayInput } from "react-delay-input";
import classnames from "classnames";

import { BOTS_COUNT } from "models/bot/wizard/enum/filters";
import { ReactComponent as BotHead } from "assets/icons/bot/botHeadInfo.svg";
import { Checkbox, ExchangesSelect } from "shared/ui";

const InvestmentsFilters = ({
  emptyFilter,
  handleEmptyHide,
  handleBotsCount,
  loading,
  setSelectedBots,
  selectedBots,
  credentialsList,
  handleFilter,
  filterValues,
}) => {
  if (!credentialsList?.items?.length) return;

  return (
    <InvestmentsFilters.Wrapper className='d-flex flex-column flex-xl-row align-items-xl-center justify-content-between'>
      <div className='d-flex flex-column flex-md-row mb-3 mb-md-0'>
        <h3 className='mb-3 mb-md-0 text-white font-weight-500 text-sm-nowrap'>
          Choose available credentials
        </h3>
        <div
          className='d-flex align-items-center cursor-pointer ml-0 ml-md-3 mr-auto'
          onClick={handleEmptyHide}
        >
          <p className='mb-0 font-sm text-white-75 text-nowrap'>Hide empty</p>
          <Checkbox
            className='mx-1'
            checked={emptyFilter}
            disabled={loading}
            value={emptyFilter}
          />
        </div>
      </div>
      <div className='d-flex flex-column flex-md-row justify-content-between mt-md-3 mt-xl-0'>
        <div className='d-flex justify-content-between flex-column flex-md-row mt-lg-0'>
          <div className='d-flex flex-column flex-sm-row align-items-sm-center flex-sm-nowrap mx-xl-1'>
            <div className='d-flex w-100'>
              <FormGroup className='mb-0 w-50'>
                <ExchangesSelect
                  isClearable
                  placeholder='Exchange'
                  isMulti={false}
                  isSearchable={false}
                  isDisabled={loading}
                  value={filterValues?.["credentials.exchange"]?.eq}
                  onChange={option =>
                    handleFilter({
                      "credentials.exchange": { eq: option?.value || {} },
                    })
                  }
                />
              </FormGroup>
              <FormGroup className='form-input mb-0 ml-2 w-50'>
                <DelayInput
                  autoComplete='off'
                  delayTimeout={700}
                  minLength={1}
                  type='text'
                  name='asset'
                  className='form-control'
                  placeholder='Coin'
                  disabled={loading}
                  value={filterValues?.asset?.like || ""}
                  onChange={e =>
                    handleFilter({
                      asset: e.target.value ? { like: e.target.value } : {},
                    })
                  }
                />
              </FormGroup>
            </div>
          </div>
        </div>
        <InvestmentsFilters.Bots className='d-flex align-items-center flex-wrap flex-sm-nowrap mt-3 ml-xl-4 mt-md-0'>
          <BotHead className='mr-1' />
          {Object.keys(BOTS_COUNT).map(item => (
            <InvestmentsFilters.BotsCount
              key={item}
              onClick={() => {
                if (loading) return;
                setSelectedBots(item);
                handleBotsCount(BOTS_COUNT[item], item);
              }}
              className={classnames("mt-2 mt-sm-0", {
                active: item === selectedBots,
                disabled: loading,
              })}
            >
              <p>{item}</p>
            </InvestmentsFilters.BotsCount>
          ))}
        </InvestmentsFilters.Bots>
      </div>
    </InvestmentsFilters.Wrapper>
  );
};

InvestmentsFilters.Wrapper = styled(CardHeader)`
  @media (max-width: 576px) {
    padding: 24px 16px !important;
  }

  margin-bottom: 0 !important;
  padding: 24px 32px !important;
  background: var(--dark-slate) !important;
  box-shadow: none !important;
`;

InvestmentsFilters.Bots = styled.div`
  svg {
    path {
      stroke: #7d81ab;
    }
    circle {
      stroke: #7d81ab;
    }
  }
`;

InvestmentsFilters.BotsCount = styled.div`
  background: var(--dusk-steel);
  border-radius: 6px;
  border: 2px solid transparent;
  padding: 11px;
  margin-left: 4px;
  cursor: pointer;
  white-space: nowrap;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    margin-bottom: 0;
  }

  &:hover {
    background: #4a4e73;
  }

  &.active {
    background: rgba(29, 140, 248, 0.1);
    border: 2px solid #1d8cf8;
  }

  &.disabled {
    opacity: 0.35;
  }
`;

export default InvestmentsFilters;
