export const dynamicRgbaColors = (a = 1) => {
  const r = Math.floor(Math.random() * 255);
  const g = Math.floor(Math.random() * 255);
  const b = Math.floor(Math.random() * 255);
  return `rgba(${r},${g},${b},${a})`;
};

export const randomPieColors = length => {
  const colorsArr = new Array(length).fill("").map(() => dynamicRgbaColors());
  return {
    pieColors: colorsArr.map(item => item.slice(0, -2) + "0.6)"),
    elementsColors: colorsArr,
  };
};
