import { createSelector } from "reselect";

const notificationsCodes = state => state.notificationsCodes.data;

export const notificationsSelector = createSelector(
  notificationsCodes,
  () => "en",
  (state, language, type) => type,
  (state, language, type) => {
    if (state[language]) {
      return state[language].filter(item => item.code.split(".")[0] === type);
    }
  },
);
