import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectBotMarkets } from "store/bots/selectors";
import { getBotMarkets, setCurrentBot } from "store/bots/actions";
import SelectInput from "../SelectInput";

const BotMarketsSelect = ({ id, isWaiting, ...props }) => {
  const { data, loading, botId } = useSelector(selectBotMarkets);
  const dispatch = useDispatch();

  useEffect(() => {
    if (botId !== id && !isWaiting) {
      dispatch(getBotMarkets(id)).then(() => dispatch(setCurrentBot(id)));
    }
  }, [dispatch, id, botId, isWaiting]);

  return (
    <SelectInput
      {...props}
      isLoading={loading}
      options={
        data.length
          ? data.map(({ pair, base }) => ({
              label: pair.replace("-", " / "),
              value: pair,
              icon: {
                type: "cryptocurrency",
                size: "selectSize",
                code: base,
              },
            }))
          : []
      }
    />
  );
};

export default BotMarketsSelect;
