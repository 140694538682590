import React from "react";
import styled from "styled-components";

import Indicator from "../../../../deals/ui/Indicator";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import IndicatorsMarketPreloader from "./IndicatorsMarketPreloader";
import MarketSummaryEmptyStates from "./MarketSummaryEmptyStates";

const IndicatorsMarket = ({
  indicators,
  loadingPage,
  analysisMarketLoading,
  dates,
  botData,
  isHaveIndicators,
}) => {
  return (
    <>
      <IndicatorsMarket.Header className='d-flex align-items-center justify-content-between py-2 px-4'>
        {loadingPage || analysisMarketLoading ? (
          <div className='d-flex align-items-center justify-content-between py-2 w-100'>
            <SkeletonPreloader width={247} height={12} />
            <SkeletonPreloader width={105} height={9} />
          </div>
        ) : (
          <>
            <IndicatorsMarket.Title>Indicators</IndicatorsMarket.Title>

            {indicators?.length ? (
              <p className='mb-0 text-white-35 font-sm'>{`Checked ${dates}`}</p>
            ) : null}
          </>
        )}
      </IndicatorsMarket.Header>

      {loadingPage || analysisMarketLoading ? (
        <IndicatorsMarketPreloader />
      ) : (
        indicators?.map(indicator => (
          <Indicator indicator={indicator} key={indicator.id} />
        ))
      )}

      {!loadingPage && !analysisMarketLoading && !indicators?.length ? (
        <MarketSummaryEmptyStates
          title={
            isHaveIndicators
              ? "Technical analysis in the process"
              : "This bot doesn't use any indicators for trading"
          }
          values={indicators?.length}
          botValues={botData?.tradeSettings?.indicators?.length}
        />
      ) : null}
    </>
  );
};

IndicatorsMarket.Header = styled.div`
  background: rgba(46, 49, 72, 1);
`;

IndicatorsMarket.Title = styled.p`
  color: rgba(125, 129, 171, 1) !important;
  font-weight: 500;
  margin-bottom: 0;
  text-transform: uppercase;
`;

export default IndicatorsMarket;
