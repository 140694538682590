import React from "react";
import { CardHeader } from "reactstrap";
import styled from "styled-components";

import { formatterToNull } from "helpers/valuesFormatters";
import RequestsFiltersTrades from "./RequestsFiltersTrades";
import RequestsFiltersOrders from "./RequestsFiltersOrders";
import { TABLE_GROUP_BUTTONS } from "models/enum/tables";
import RefreshButton from "../RefreshButton";
import ButtonsGroup from "../ButtonsGroup";
import Loading from "../state/Loading";

const TradeRequestTableHeader = ({
  title,
  setTitle,
  tradesRequestsLoading,
  ordersRequestsLoading,
  tradesRequests,
  ordersRequests,
  handleFilter,
  fetchData,
}) => {
  return (
    <TradeRequestTableHeader.Header className='d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between px-4 py-3'>
      <div className='col-sm-6 p-0'>
        <ButtonsGroup
          group={TABLE_GROUP_BUTTONS}
          current={title}
          setCurrent={setTitle}
        />
      </div>

      <div className='d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-end col-sm-6 p-0 mt-3 mt-sm-0'>
        <div className='d-flex align-items-center mb-1 mb-sm-0 refresh'>
          <RefreshButton
            onHandleRefresh={fetchData}
            loading={tradesRequestsLoading || ordersRequestsLoading}
            withHoverBg={false}
            width={20}
          />
          {tradesRequestsLoading || ordersRequestsLoading ? (
            <div className='mx-3'>
              <Loading size={20} />
            </div>
          ) : (
            <p
              className='font-sm mb-0 text-white text-nowrap ml-2 mr-3'
              style={{
                background: "var(--dark-slate)",
                padding: "6px 12px",
                borderRadius: "6px",
              }}
            >
              {`${title === "trades" ? formatterToNull(tradesRequests.total) : formatterToNull(ordersRequests.total)} results`}
            </p>
          )}
        </div>
        {title === "trades" ? (
          <RequestsFiltersTrades
            tradesRequests={tradesRequests}
            handleFilter={handleFilter}
          />
        ) : (
          <RequestsFiltersOrders
            handleFilter={handleFilter}
            request={ordersRequests?.request}
          />
        )}
      </div>
    </TradeRequestTableHeader.Header>
  );
};

TradeRequestTableHeader.Header = styled(CardHeader)`
  @media (max-width: 575.98px) {
    .form-group {
      width: 100% !important;
    }
  }

  .form-group {
    width: 145px;
  }

  background: #2a2c42 !important;
  border-radius: 8px 8px 0 0;
`;

export default TradeRequestTableHeader;
