import styled from "styled-components";

export const StyledBadgeIndicatorSettingsBlockGroup = styled.div`
  background: var(--dark-slate);
  border-radius: 6px;
  height: 26px;
`;
export const StyledBadgeIndicatorSettingsBlock = styled.span`
  background: var(--dark-slate);
  border-radius: 4px;
  height: 20px;

  :hover {
    background: var(--white-05);
    border-radius: 4px;
    transition: 0.3s all ease-in-out;
  }

  p {
    font-size: 10px;
  }

  p.dark {
    color: var(--black) !important;
  }

  p.muted {
    color: var(--text-th);
  }
`;
