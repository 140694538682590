import React from "react";
import { CardBody, Col } from "reactstrap";

import { AGREEMENT_TR_SIDE, AGREEMENT_TR_STATUS_OPTIONS } from "models/filters";
import SelectInput from "shared/ui/SelectInput";

const FiltersTradeRequest = ({ loading, onHandleFilter, filter }) => {
  return (
    <CardBody className='d-flex flex-column flex-md-row p-0 mb-3 pr-md-2'>
      <Col xs={12} md={2} className='mb-0 mr-2 mb-2 mb-md-0 p-0'>
        <SelectInput
          isClearable
          placeholder='Side'
          name='tr.type'
          value={AGREEMENT_TR_SIDE.find(
            ({ value }) => value === filter?.["tr.type"]?.eq,
          )}
          onChange={option => {
            onHandleFilter({ "tr.type": { eq: option?.value } });
          }}
          options={AGREEMENT_TR_SIDE}
          isDisabled={loading}
        />
      </Col>
      <Col xs={12} md={10} className='mb-0 mb-2 mb-md-0 p-0'>
        <SelectInput
          isClearable
          placeholder='Status'
          name='tr.status'
          value={AGREEMENT_TR_STATUS_OPTIONS.find(
            o => o.value === filter?.["tr.status"]?.eq,
          )}
          onChange={option =>
            onHandleFilter({
              "tr.status": option ? { eq: option?.value } : { neq: "open" },
            })
          }
          options={AGREEMENT_TR_STATUS_OPTIONS}
          isDisabled={loading}
        />
      </Col>
    </CardBody>
  );
};

export default FiltersTradeRequest;
