import React from "react";
import { CardBody, Col, Row } from "reactstrap";

import { BOT_ACTIVE_STATUS } from "models/filters";
import { BotMarketsSelect, SelectInput } from "shared/ui";

const Filters = ({ botId, loading, handleFilter, data }) => {
  return (
    <CardBody className='d-flex flex-column flex-md-row justify-content-between p-0 px-4'>
      <Row className='d-flex align-items-center p-0 m-0 w-100'>
        <Col md={6} lg={3} className='p-0 mb-2 mb-lg-0'>
          <SelectInput
            isClearable
            placeholder='Active status'
            name='Active status'
            isDisabled={loading}
            isLoading={loading}
            onChange={option =>
              handleFilter({
                isExcluded: { eq: option?.value },
              })
            }
            options={BOT_ACTIVE_STATUS}
          />
        </Col>

        <Col md={6} lg={6} className='px-0 px-md-2 mb-2 mb-lg-0'>
          <BotMarketsSelect
            isMulti
            isClearable
            id={botId}
            placeholder='Market'
            name='market'
            isLoading={loading}
            isDisabled={loading}
            onChange={option =>
              handleFilter({
                market: { in: option ? option.map(e => e.value) : "" },
              })
            }
            value={data?.request?.filter?.market?.in?.map(item => ({
              label: item,
              value: item,
              icon: {
                type: "cryptocurrency",
                size: "selectSize",
                code: item,
              },
            }))}
          />
        </Col>
      </Row>
    </CardBody>
  );
};

export default Filters;
