import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import ChannelSubscriptionForm from "./ChannelSubscriptionForm";
import { updateSubscriptionSettings } from "store/channels/actions";
import { addSnackBar } from "store/snackbar/actions";
import { formatterToNull } from "helpers/valuesFormatters";
import { numberOrNull } from "../channelPropTypes";

const validationSchema = Yup.object().shape({
  volume: Yup.number().required("This field is required"),
  maximum: Yup.number().required("This field is required"),
});

const CreateChannelSubscription = ({
  channelId,
  handleToggleModal,
  handleRefresh,
  maxximun,
  volume,
  accept,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitted(true);

    const subscriptionSettings = {
      ...values,
      accept: values.accept ? "automatic" : "manual",
    };

    const res = await dispatch(
      updateSubscriptionSettings(channelId, subscriptionSettings),
    );

    if (!res.errors) {
      handleToggleModal();
      handleRefresh();
      return dispatch(addSnackBar("success", res.message));
    }
    setErrorMessage(res.message);
    setErrors(res.message);
    setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        volume: formatterToNull(volume),
        maximum: formatterToNull(maxximun),
        accept: accept,
      }}
      onSubmit={handleSubmit}
    >
      {() => (
        <ChannelSubscriptionForm
          isSubmitted={isSubmitted}
          setErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
          handleToggleModal={handleToggleModal}
          setIsSubmitted={setIsSubmitted}
        />
      )}
    </Formik>
  );
};

CreateChannelSubscription.propTypes = {
  channelId: PropTypes.string,
  handleToggleModal: PropTypes.func,
  handleRefresh: PropTypes.func,
  maxximun: numberOrNull,
  volume: PropTypes.number,
  accept: PropTypes.string,
};

export default CreateChannelSubscription;
