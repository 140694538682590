import React from "react";
import { useHistory } from "react-router-dom";
import queryString from "qs";
import { Col, Row } from "reactstrap";

import { beforeToday } from "helpers/DatepickerValidation";
import { DateField } from "shared/ui";

const RequestLogFilters = ({ location, id }) => {
  const history = useHistory();
  const search = queryString.parse(location.search);

  const handleFilter = filter => {
    history.push({
      pathname: `/credentials/${id}/request-log`,
      search: queryString
        .stringify({ ...search, ...filter, page: 1 })
        .replace(/(?:^|&)[a-zA-z]+=(?=&|$)/g, ""),
    });
  };

  return (
    <form>
      <Row>
        <Col xs='3'>
          <DateField
            onChange={date => handleFilter({ createdFrom: date })}
            isValidDate={beforeToday}
            inputProps={{
              className: "form-control",
              name: "createdFrom",
              readOnly: true,
              placeholder: "Created from",
            }}
          />
        </Col>
        <Col xs='3'>
          <DateField
            onChange={date => handleFilter({ createdTo: date })}
            isValidDate={current =>
              current.isAfter(search.createdFrom) && beforeToday(current)
            }
            inputProps={{
              className: "form-control",
              name: "createdTo",
              readOnly: true,
              placeholder: "Created to",
            }}
          />
        </Col>
      </Row>
    </form>
  );
};

export default RequestLogFilters;
