export const minMaxSteps = (minimumDailyChange, maximumDailyChange) => {
  return {
    0: {
      style: (minimumDailyChange > 3 || minimumDailyChange < -3) &&
        (maximumDailyChange > 3 || maximumDailyChange < -3) && {
          display: "block",
          width: "4px",
          height: "16px",
          borderRadius: 2,
          margin: "-16.5px 0 0 0",
          background: "#fff",
        },
      label: " ",
    },
    "-10": {},
    "-20": {},
    "-30": {},
    "-40": {},
    "-50": {},
    "-60": {},
    "-70": {},
    "-80": {},
    "-90": {},
    10: {},
    20: {},
    30: {},
    40: {},
    50: {},
    60: {},
    70: {},
    80: {},
    90: {},
  };
};
