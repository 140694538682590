import React from "react";

import { empty } from "shared/locales/en/state/emptyState";
import {
  DefaultPreloader,
  EmptyDataList,
  EmptyFiltersListingState,
} from "shared/ui";

const withRenderState = WrappedComponent => {
  const WithRenderStateComponent = ({
    loading,
    error,
    items,
    isFiltered,
    onHandleClick,
    viewPage,
    loaded,
  }) => {
    const { title, description, buttonText } = empty?.[viewPage] || {};
    const isEmpty = !items?.length;

    if (loading) return <DefaultPreloader viewPreloader={viewPage} />;
    if (isFiltered && isEmpty) return <EmptyFiltersListingState />;
    if ((isEmpty && loaded) || error)
      return (
        <EmptyDataList
          title={title}
          description={description}
          buttonText={buttonText}
          onHandleClick={onHandleClick}
        />
      );

    return <WrappedComponent items={items} />;
  };

  WithRenderStateComponent.displayName = `WithRenderState(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`;

  return WithRenderStateComponent;
};

export default withRenderState;
