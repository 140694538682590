import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader } from "reactstrap";
import { drawBallancesPieChart } from "charts";
import { CircularProgressbar } from "react-circular-progressbar";
import qs from "qs";

import {
  fetchBalances,
  resetCredentialBalances,
} from "store/credentials/actions";
import { randomPieColors } from "charts/helpers.js";
import CurrencyListBar from "./components/CurrencyListBar";
import { ReactComponent as Alert } from "assets/icons/general/alert.svg";
import { ReactComponent as TitleIcon } from "assets/icons/credential/money-change.svg";
import ExchangeBalancePreLoad from "./components/ExchangeBalancePreLoad";
import ExchangesBalancesModal from "../../modals/ExchangeBalanacesModal";
import useRequestCancellation from "hooks/useRequestCancellation";
import { selectBalances } from "store/credentials/selectors";
import { CustomScrollbars, DoughnutChart, Error } from "shared/ui";

const ExchangeBalances = ({ id, estimated }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialPieData, setInitialPieData] = useState(null);
  const [currentPieData, setCurrentPieData] = useState(null);
  const [hoverredElemId, setHoverredElemId] = useState(null);
  const [elementsColors, setElementColors] = useState([]);
  const [pieColors, setPieColors] = useState([]);
  const [hoverCurrentBg, setHoverCurrentBg] = useState(-1);
  const { useAbortableEffect } = useRequestCancellation();

  const dispatch = useDispatch();

  const isToggle = () => setIsModalOpen(!isModalOpen);

  const { data, loading, error } = useSelector(selectBalances);

  useAbortableEffect(
    signal => {
      const filter = qs.stringify({
        sort: { by: "percent", order: "DESC" },
        limit: 100,
      });

      dispatch(fetchBalances(id, `?${filter}`, signal));
      return () => dispatch(resetCredentialBalances());
    },
    [id, dispatch],
  );

  useEffect(() => {
    if (data?.items?.length) {
      const initColors = randomPieColors(data.items.length);
      const pieColors = initColors.pieColors;
      const elementsColors = initColors.elementsColors;
      const initPieData = drawBallancesPieChart(data, pieColors);
      setInitialPieData(initPieData);
      setPieColors(pieColors);
      setElementColors(elementsColors);
      setCurrentPieData(initPieData);
    }
  }, [data]);

  const handleMouseEnter = index => {
    const hoveredPieColors = pieColors.map((item, i) =>
      i === index ? elementsColors[i] : item,
    );
    const updatedPieData = drawBallancesPieChart(data, hoveredPieColors);
    setCurrentPieData(updatedPieData);
    setHoverredElemId(index);
  };

  const hadnleMouseLeave = () => {
    setCurrentPieData(initialPieData);
    setHoverredElemId(null);
  };

  const filteredData = currentPieData?.datasets[0].data.reduce(
    (total, item) => total + item,
    0,
  );
  const totalPercents = data?.items?.reduce(
    (total, item) => total + item.percent,
    0,
  );

  if (loading && !isModalOpen) {
    return (
      <div className='d-none'>
        <ExchangeBalancePreLoad />
      </div>
    );
  }

  if (error) {
    return (
      <Card>
        <CardBody>
          <Error error={error} />
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      <Card className='mb-4 overflow-hidden d-none'>
        <CardHeader className='d-flex align-items-center justify-content-between p-4'>
          <div className='d-flex align-items-center'>
            <TitleIcon />
            <p className='mb-0 ml-2 text-white font-weight-500'>
              Exchange balances
            </p>
          </div>
          <p className='link-info mb-0' onClick={isToggle}>
            Details
          </p>
        </CardHeader>
        {filteredData > 0 && data?.items?.length && (
          <ExchangeBalances.WrapperChart>
            <DoughnutChart
              pieData={currentPieData}
              index={hoverredElemId}
              key={hoverredElemId}
              asset={data}
              setHoverCurrentBg={setHoverCurrentBg}
            />
          </ExchangeBalances.WrapperChart>
        )}
        {data?.items?.length && totalPercents ? (
          <CustomScrollbars
            autoHideTimeout={500}
            autoHideDuration={500}
            autoHeight
            autoHeightMax={300}
            className='mt-3'
          >
            <CardBody className='py-4 px-0'>
              {data?.items?.map((balance, index) => (
                <CurrencyListBar
                  key={balance.asset}
                  balance={balance}
                  index={index}
                  hoverCurrentBg={hoverCurrentBg}
                  elementsColors={elementsColors}
                  handleMouseEnter={() => handleMouseEnter(index)}
                  hadnleMouseLeave={hadnleMouseLeave}
                />
              ))}
            </CardBody>
          </CustomScrollbars>
        ) : (
          <CardBody className='py-0'>
            <ExchangeBalances.PieChartTip>
              <ExchangeBalances.EmptyBar
                value={0}
                strokeWidth={15}
                heigth='70px'
              />
            </ExchangeBalances.PieChartTip>
            <ExchangeBalances.Alert className='d-flex align-items-center my-4'>
              <Alert alt='' />
              <p className='mb-0 font-sm text-white'>
                The balances of all of your assets are equal to zero
              </p>
            </ExchangeBalances.Alert>
          </CardBody>
        )}
      </Card>
      {isModalOpen && (
        <ExchangesBalancesModal
          isModalOpen={isModalOpen}
          isToggle={isToggle}
          balances={data}
          id={id}
          estimated={estimated}
        />
      )}
    </>
  );
};

ExchangeBalances.WrapperChart = styled.div`
  margin: 0 auto;
`;

ExchangeBalances.PieChartTip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 190px;
  top: 0;
  right: 0;
  left: 0;
`;

ExchangeBalances.Alert = styled.div`
  background: rgba(47, 128, 237, 0.1);
  border-radius: 8px;
  padding: 6px 17px;
  align-items: center;
`;

ExchangeBalances.EmptyBar = styled(CircularProgressbar)`
  stroke: #ffffff;
  opacity: 0.1;
  stroke-linecap: butt;
`;

export default ExchangeBalances;
