import { GET_BALANCE_HISTORY, GET_BALANCE_HISTORY_LOADING } from "./constants";

const initialState = {
  data: {},
  loading: false,
  loadingChart: false,
  error: false,
};

export const dashboard = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BALANCE_HISTORY:
      return {
        ...state,
        loadingChart: false,
        data: {
          ...state.data,
          balanceHistory: payload,
        },
      };

    case GET_BALANCE_HISTORY_LOADING:
      return {
        ...state,
        loadingChart: true,
      };

    default:
      return state;
  }
};
