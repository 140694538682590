import React from "react";
import { Scrollbars } from "react-custom-scrollbars-with-mac-support";
import styled from "styled-components";

const CustomScrollbars = ({
  children,
  isDark = true,
  trackVerticalProps,
  trackHorizontalProps,
  ...props
}) => (
  <StyledScrollbars
    renderThumbVertical={props => (
      <Thumb className='vertical' isDark={isDark} {...props} />
    )}
    renderTrackVertical={props => (
      <TrackVertical {...props} {...trackVerticalProps} />
    )}
    renderThumbHorizontal={props => (
      <Thumb className='horizontal' isDark={isDark} {...props} />
    )}
    renderTrackHorizontal={props => (
      <TrackHorizontal {...props} {...trackHorizontalProps} />
    )}
    {...props}
  >
    {children}
  </StyledScrollbars>
);

const Thumb = styled.div`
  .vertical {
    width: 2px !important;
  }

  .horizontal {
    height: 2px !important;
  }

  background: ${props => (props.isDark ? "rgba(94, 99, 146, 1)" : "#1D1E2A")};
  opacity: 0.5;
  border-radius: 2px;
  cursor: pointer;
`;

const TrackVertical = styled.div`
  width: 4px !important;
  right: 2px;
  bottom: 10px;
  top: 2px;
`;

const TrackHorizontal = styled.div`
  width: 100%;
  bottom: 0;
  height: 4px !important;
`;

const StyledScrollbars = styled(Scrollbars)`
  border-radius: 8px;
  & > div {
    margin: 0 !important;
    overflow: auto;
    max-height: inherit !important;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export default CustomScrollbars;
