import axios from "axios";

import { renewalToken, logout } from "auth";

const createAction = (name, thunk) => async (dispatch, getState, fetch) => {
  try {
    dispatch({ type: `GET_${name}_LOADING` });
    const res = await thunk(fetch);
    dispatch({ type: `GET_${name}_SUCCESS`, payload: res.data });
    return res.data;
  } catch (e) {
    if (axios.isCancel(e)) return;
    if (e.response?.status === 401) {
      const token = await renewalToken();
      if (token) {
        fetch.defaults.headers.Authorization = `Bearer ${token}`;
        return createAction(name, thunk)(dispatch, getState, fetch);
      } else {
        logout();
      }
    }
    dispatch({ type: `GET_${name}_FAILURE`, payload: e.message });
  }
};

export default createAction;
