import React from "react";
import classNames from "classnames";

import { minMaxRange } from "models/bot/enums";
import { getPP } from "helpers/pivotPoint/pivotPoint";
import PivotPointsRange from "../PivotPointsRange";

const PivotPoints = ({ data, isHorizontal }) => {
  if (!data?.calculation && !data?.timeframe) return null;

  return (
    <div
      className={classNames("d-flex w-100", {
        "flex-row px-4 pb-0 pt-4": isHorizontal,
        "flex-column mt-4": !isHorizontal,
      })}
    >
      <div
        className={classNames("d-flex flex-column", {
          "w-100": !isHorizontal,
          "w-25 mr-4": isHorizontal,
        })}
      >
        <p className='text-white font-weight-500'>Pivot points</p>
        <div className='d-flex justify-content-between mt-2 text-nowrap'>
          <p className='mb-0 text-white-75 font-weight-300 font-sm'>Type</p>
          <p className='text-white font-weight-500 text-capitalize mb-0'>
            {data?.calculation}
          </p>
        </div>
        <div className='d-flex justify-content-between mt-2 text-nowrap mb-4'>
          <p className='mb-0 text-white-75 font-weight-300 font-sm'>
            Timeframe
          </p>
          <p className='text-white font-weight-500 text-capitalize mb-0'>
            {data?.timeframe}
          </p>
        </div>
      </div>
      <div
        className={classNames("d-flex flex-column justify-content-center", {
          "w-75 pl-3": isHorizontal,
          "w-100": !isHorizontal,
        })}
      >
        {isHorizontal ? (
          <div className='d-flex align-items-center justify-content-between mb-3'>
            <div className='d-flex align-items-center'>
              <p className='mb-0 font-weight-500 text-white'>SUPPORT LEVELS</p>
            </div>
            <div className='d-flex align-items-center'>
              <p className='mb-0 font-weight-500 text-white'>
                RESISTANCE LEVELS
              </p>
            </div>
          </div>
        ) : null}
        <PivotPointsRange
          minMaxRange={minMaxRange[data?.calculation]}
          checkedSupport={data?.hasSupport}
          checkedResistance={data?.hasResistance}
          isEditable={false}
          displayMark
          values={{
            pivotPoint: getPP({
              resistanceBottom: data?.resistanceBottom,
              resistanceTop: data?.resistanceTop,
              supportBottom: data?.supportBottom,
              supportTop: data?.supportTop,
            }),
          }}
          handlePPRanges={() => null}
        />
      </div>
    </div>
  );
};

export default PivotPoints;
