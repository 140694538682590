import { sortWithSumOrderBook, convertOrderBook, updateOrders } from "../utils";
import { getAssetFromPair } from "helpers/valuesFormatters";

export const serializeTicker = data => ({
  market: {
    base: getAssetFromPair(data.symbol, "base", "_"),
    quote: getAssetFromPair(data.symbol, "quote", "_"),
  },
  symbol: data.symbol,
  open: data.open,
  high: data.high,
  low: data.low,
  close: data.close,
  markPrice: data.markPrice,
  quantity: data.quantity,
  amount: data.amount,
  tradeCount: data.tradeCount,
  dailyChange: data.dailyChange,
});

export const handlePoloniexMessage = (
  message,
  setTicker,
  setOrderBook,
  setTrades,
  handleLastIdCheck,
  depth,
) => {
  if (message.channel === "ticker" && message?.data?.length) {
    setTicker(message.data.map(item => serializeTicker(item)));
  }

  if (message.channel === "book_lv2") {
    const bids = message.data?.[0]?.bids.length
      ? convertOrderBook(message.data?.[0]?.bids)
      : [];
    const asks = message.data?.[0]?.asks.length
      ? convertOrderBook(message.data?.[0]?.asks)
      : [];

    if (message.action === "snapshot") {
      setOrderBook({
        asks: sortWithSumOrderBook(asks, "asks").splice(0, depth),
        bids: sortWithSumOrderBook(bids, "bids").splice(0, depth),
      });
    }

    if (message.action === "update") {
      handleLastIdCheck(message.data?.[0]?.id, message.data?.[0]?.lastId);
      setOrderBook(prev => ({
        asks: updateOrders(prev.asks, asks, "asks").splice(0, depth),
        bids: updateOrders(prev.bids, bids, "bids").splice(0, depth),
      }));
    }
  }

  if (message.channel === "trades" && message.data?.length) {
    setTrades(prev => ({
      ...prev,
      data: [...message.data, ...prev.data].splice(0, 10),
    }));
  }
};
