import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Card, CardBody } from "reactstrap";

import Filters from "./Filters";
import HistoryTable from "./HistoryTable";
import { fetchHistory } from "store/payments/actions";

const History = ({ fetchHistory, history }) => {
  useEffect(() => {
    fetchHistory();
  }, [fetchHistory]);

  useEffect(() => {
    window.analytics.page("/deposit-withdrawal-history");
  }, []);

  return (
    <>
      <h1>Deposit withdrawal history</h1>
      <Card className='mt-4'>
        <CardBody>
          <Filters />
        </CardBody>
      </Card>
      <Card className='mt-4'>
        <CardBody>
          <HistoryTable data={history} />
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = state => ({
  history: state.payments.withdrawalHistory,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchHistory }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(History);
