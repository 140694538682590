import React from "react";
import { Card } from "reactstrap";

import LineWithError from "shared/ui/LineWithError";
import TradeRequestCardHeader from "./ui/TradeRequestCardHeader";
import TradeRequestCardBody from "./ui/TradeRequestCardBody";

const TradeRequestCard = ({
  data,
  className = "mb-2",
  goal,
  handleOpenModal,
}) => {
  return (
    <Card className={`${className}`}>
      <TradeRequestCardHeader data={data} goal={goal} />
      <LineWithError errorMessage={data?.error} radius='none' />
      <TradeRequestCardBody
        data={data}
        goal={goal}
        handleOpenModal={handleOpenModal}
      />
    </Card>
  );
};

export default TradeRequestCard;
