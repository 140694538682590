import React from "react";
import { Card, Modal } from "reactstrap";
import PropTypes from "prop-types";

import CreateTargetsForm from "../forms/CreateTargetsForm";

const CreateTargetsModal = ({ isModalOpen, handleToggleModal }) => {
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={handleToggleModal}
      modalClassName='modal-black'
    >
      <Card className='m-0 p-4'>
        <h3 className='h1 mb-4 font-weight-500'>Add new Targets</h3>
        <CreateTargetsForm handleToggleModal={handleToggleModal} />
      </Card>
    </Modal>
  );
};

CreateTargetsModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleToggleModal: PropTypes.func,
};

export default CreateTargetsModal;
