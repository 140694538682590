import React, { memo } from "react";

import PercentBadge from "../PercentBadge";

const Spread = ({ spread, spreadPercent }) => {
  return (
    <div className='d-flex align-items-center align-items-lg-start my-lg-auto mr-lg-5 my-2'>
      <p className='font-weight-light text-white-35 font-sm mb-0 mb-lg-2 mr-2'>
        Spread:
      </p>
      <div className='d-flex'>
        <p className='text-white font-weight-500 pr-1 my-auto'>{spread}</p>
        <PercentBadge value={spreadPercent} isUnsignedVal />
      </div>
    </div>
  );
};

export default memo(Spread);
