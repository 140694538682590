import { deleteCredential } from "store/credentials/actions";

export const CREDENTIALS_CONTENT = {
  details: {
    widgets: {
      dangerZone: {
        title: "Danger zone",
        subtitle:
          "When you delete the credentials then all statistics, trade performance data and portfolio info will be lost, all bots will be stopped, new deals will not be created, all ready deals will not start execution round and will not switch to running status, but all running deals will continue to process.If you want to delete credentials, then all statistics and deals from all bots will be deleted forever without possibility to recover.",
        actions: {
          delete: {
            name: "Credentials",
            title:
              "Are you sure you want to delete your credentials? This action cannot be undone. \n To proceed, please type 'DELETE' in the confirmation input field and click the DELETE button",
            pathOnSucceess: "/credentials",
            actionFunction: deleteCredential,
          },
        },
        errorMessage: "Check if entered safety word is correct",
      },
    },
  },
};
