import React from "react";
import styled from "styled-components";

const EmptyStateCustom = ({
  Img,
  title,
  description,
  height,
  backgroundColor = "#2A2C42",
}) => {
  return (
    <EmptyStateCustom.Wrapper
      backgroundColor={backgroundColor}
      height={height}
      className='d-flex flex-column justify-content-center align-items-center mb-4'
    >
      <div className='mb-4'>
        <Img />
      </div>
      <h3 className='font-weight-500 text-white mt-3 mb-1'>{title}</h3>
      <p className='text-white-75 mb-4 text-center'>{description}</p>
    </EmptyStateCustom.Wrapper>
  );
};

EmptyStateCustom.Wrapper = styled.div`
  height: ${props => props.height + "px"} !important;
  padding: 40px 24px;
  background: ${props => props.backgroundColor} !important;
  border-radius: 16px;
  h3 {
    font-size: 18px;
  }
`;

export default EmptyStateCustom;
