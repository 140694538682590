import React from "react";
import classNames from "classnames";
import {
  StyledBadgeIndicatorSettingsBlock,
  StyledBadgeIndicatorSettingsBlockGroup,
} from "./index.styles";

const BadgeIndicatorSettingsBlock = ({
  arrayIndicatorsOptions = [],
  type,
  setFieldValue,
  className = "d-flex my-auto",
}) => {
  if (arrayIndicatorsOptions?.length) {
    return (
      <StyledBadgeIndicatorSettingsBlockGroup
        data-testid='indicator-badge-wrapper'
        className={className}
      >
        {arrayIndicatorsOptions.map(({ value, label, badgeClass }, index) => {
          return (
            <StyledBadgeIndicatorSettingsBlock
              key={`${value}-${label}`}
              data-testid={`indicator-badge-${index}}`}
              className={classNames("pt-1 pb-1 pl-2 pr-2 my-auto", {
                [badgeClass]: type === value,
              })}
              onClick={() => setFieldValue({ target: { name: "type", value } })}
            >
              <p
                className={classNames(
                  "h5 my-auto font-weight-bold text-uppercase",
                  {
                    dark: type === value,
                    "text-white-35": type !== value,
                  },
                )}
              >
                {label}
              </p>
            </StyledBadgeIndicatorSettingsBlock>
          );
        })}
      </StyledBadgeIndicatorSettingsBlockGroup>
    );
  }
};

export default BadgeIndicatorSettingsBlock;
