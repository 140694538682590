import React from "react";
import { CardHeader, Row } from "reactstrap";
import { Link } from "react-router-dom";

import { ReactComponent as LastDealsDashboard } from "assets/icons/dashboard/LastDealsDashboard.svg";

const LastActivitiesHeader = ({ title, linkTo, linkText }) => (
  <CardHeader className='p-4'>
    <Row className='no-gutters justify-content-between'>
      <div className='d-flex align-items-center'>
        <LastDealsDashboard />
        <p className='mb-0 ml-2 text-white font-weight-500 text-nowrap'>
          {title}
        </p>
      </div>
      <Link to={linkTo} className='d-block text-right link-info text-nowrap'>
        {linkText}
      </Link>
    </Row>
  </CardHeader>
);

export default LastActivitiesHeader;
