import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import styled from "styled-components";
import classnames from "classnames";

import Indicator from "./Indicator";
import GenomeWidgetPreloader from "./GenomeWidgetPreloader";
import PivotPoints from "shared/ui/PivotPoints";
import { EMPTY_GENOME_TEXT } from "./genome";
import {
  EXECUTION_TYPE_FIELDS,
  MARTINGALE_FIELDS,
} from "views/bots/wizard/Configuration/tradeSettingsConfiguration";
import { ReactComponent as Icon } from "assets/icons/bot/gridIcon.svg";
import EmptyState from "../state/EmptyState";

const BestTradeSettings = ({ data, loading, title = "" }) => {
  if (loading) return <GenomeWidgetPreloader />;

  return (
    <Card className='mr-2 mb-4'>
      <BestTradeSettings.Header className='p-4 d-flex flex-row align-items-center'>
        <Icon className='mr-2' />
        <p className='font-md font-weight-500 mb-0 text-white'>{title}</p>
      </BestTradeSettings.Header>
      {!data ? (
        <EmptyState
          title={EMPTY_GENOME_TEXT.title}
          description={EMPTY_GENOME_TEXT.desc}
        />
      ) : (
        <CardBody className='px-0 pt-2 pb-0'>
          <BestTradeSettings.SettigsWrapper
            className={classnames(
              "justify-content-between px-4 no-gutters pb-4",
              {
                "pb-4":
                  (data?.tradeSettings?.pivotPoint?.calculation &&
                    data?.tradeSettings?.pivotPoint?.timeframe) ||
                  data?.tradeSettings?.indicators,
                "pb-3":
                  !data?.tradeSettings?.pivotPoint?.calculation &&
                  !data?.tradeSettings?.pivotPoint?.timeframe &&
                  !data?.tradeSettings?.indicators,
              },
            )}
            hasBorder={
              data?.tradeSettings?.pivotPoint?.calculation &&
              data?.tradeSettings?.pivotPoint?.timeframe
            }
          >
            <Col xs={12} md={6} className='pr-md-3 mt-3 mt-md-0'>
              <BestTradeSettings.Amount className='d-flex align-items-center justify-content-between'>
                <p className='font-weight-400 font-sm text-white-75 mb-0'>
                  Take profit
                </p>
                <span className='font-md text-white font-weight-500'>
                  {data?.tradeSettings?.profit
                    ? data?.tradeSettings?.profit + "%"
                    : "-"}
                </span>
              </BestTradeSettings.Amount>
              <BestTradeSettings.Amount className='d-flex align-items-center justify-content-between'>
                <p className='font-weight-400 font-sm text-white-75 mb-0'>
                  Stop loss
                </p>
                <span
                  className={classnames("font-md", {
                    "text-white font-weight-500":
                      data?.tradeSettings?.stopLossPercent,
                    "text-white-35 font-weight-300":
                      !data?.tradeSettings?.stopLossPercent,
                  })}
                >
                  {data?.tradeSettings?.stopLossPercent
                    ? data?.tradeSettings?.stopLossPercent + "%"
                    : "-"}
                </span>
              </BestTradeSettings.Amount>
              <BestTradeSettings.Amount className='d-flex align-items-center justify-content-between'>
                <p className='font-weight-400 font-sm text-white-75 mb-0'>
                  Initial volume size
                </p>
                <span className='font-md text-white font-weight-500'>
                  {data?.tradeSettings?.volume
                    ? data?.tradeSettings?.volume + "%"
                    : "-"}
                </span>
              </BestTradeSettings.Amount>
              <BestTradeSettings.Amount className='d-flex align-items-center justify-content-between'>
                <p className='font-weight-400 font-sm text-white-75 mb-0'>
                  Execution Type
                </p>
                <span className='font-md text-white font-weight-500'>
                  {EXECUTION_TYPE_FIELDS.find(
                    ({ value }) => data?.tradeSettings?.executionType === value,
                  )?.label || "-"}
                </span>
              </BestTradeSettings.Amount>
              <BestTradeSettings.Amount className='d-flex align-items-center justify-content-between'>
                <p className='font-sm text-white-75 mb-0'>
                  Minimum <span className='text-success'>BUY</span> signals
                </p>
                <span
                  className={classnames("d-block font-md", {
                    "text-white font-weight-500":
                      data?.tradeSettings?.minimumBuySignals,
                    "text-white-35 font-weight-300":
                      !data?.tradeSettings?.minimumBuySignals,
                  })}
                >
                  {data?.tradeSettings?.minimumBuySignals || "-"}
                </span>
              </BestTradeSettings.Amount>
              <BestTradeSettings.Amount className='d-flex align-items-center justify-content-between'>
                <p className='font-sm text-white-75 mb-0'>
                  Minimum <span className='text-danger'>SELL</span> signals
                </p>
                <span
                  className={classnames("d-block font-md", {
                    "text-white font-weight-500":
                      data?.tradeSettings?.minimumSellSignals,
                    "text-white-35 font-weight-300":
                      !data?.tradeSettings?.minimumSellSignals,
                  })}
                >
                  {data?.tradeSettings?.minimumSellSignals || "-"}
                </span>
              </BestTradeSettings.Amount>
            </Col>
            <Col xs={12} md={6} className='pl-md-3 mt-2 mt-md-0'>
              <div className='mb-2'>
                <BestTradeSettings.Amount className='d-flex align-items-center justify-content-between'>
                  <p className='font-weight-400 font-sm text-white-75 mb-0'>
                    Trailing Buy
                  </p>
                  <span
                    className={classnames("font-md", {
                      "text-white font-weight-500":
                        data?.tradeSettings?.trailingBuy,
                      "text-white-35 font-weight-300":
                        !data?.tradeSettings?.trailingBuy,
                    })}
                  >
                    {data?.tradeSettings?.trailingBuy
                      ? data?.tradeSettings?.trailingBuy + "%"
                      : "-"}
                  </span>
                </BestTradeSettings.Amount>
                <BestTradeSettings.Amount className='d-flex align-items-center justify-content-between'>
                  <p className='font-weight-400 font-sm text-white-75'>
                    Trailing Sell
                  </p>
                  <span
                    className={classnames("font-md", {
                      "text-white font-weight-500":
                        data?.tradeSettings?.trailingSell,
                      "text-white-35 font-weight-300":
                        !data?.tradeSettings?.trailingSell,
                    })}
                  >
                    {data?.tradeSettings?.trailingSell
                      ? data?.tradeSettings?.trailingSell + "%"
                      : "-"}
                  </span>
                </BestTradeSettings.Amount>
              </div>
              {data?.tradeSettings?.martingale ? (
                <div className='pt-3 d-flex flex-column'>
                  <p className='mb-0 text-white font-md font-weight-500 mb-2'>
                    Average down strategy
                  </p>
                  {MARTINGALE_FIELDS.map(prop => (
                    <BestTradeSettings.Amount
                      key={prop.value}
                      className='d-flex align-items-center justify-content-between'
                    >
                      <p className='font-weight-400 font-sm text-white-75'>
                        {prop.label}
                      </p>
                      <span
                        className={classnames("font-md", {
                          "text-white font-weight-500":
                            data?.tradeSettings?.[prop.value],
                          "text-white-35 font-weight-300":
                            !data?.tradeSettings?.[prop.value],
                        })}
                      >
                        {data?.tradeSettings?.[prop.value]
                          ? data?.tradeSettings?.[prop.value] + "%"
                          : "-"}
                      </span>
                    </BestTradeSettings.Amount>
                  ))}
                </div>
              ) : null}
            </Col>
          </BestTradeSettings.SettigsWrapper>
          <PivotPoints data={data?.tradeSettings?.pivotPoint} isHorizontal />
          <div className='d-flex flex-column pt-2'>
            {data?.tradeSettings?.indicators.map(indicator => (
              <Indicator key={indicator.id} indicator={indicator} />
            ))}
          </div>
        </CardBody>
      )}
    </Card>
  );
};

BestTradeSettings.SettigsWrapper = styled(Row)`
  border-bottom: ${props => (props.hasBorder ? "1px solid #2E3148" : "none")};
`;

BestTradeSettings.Amount = styled.div`
  margin-bottom: 0.5rem;

  :last-child {
    margin-bottom: 0;
  }
  p {
    line-height: 16px;
  }
  span {
    line-height: 19px;
  }
`;

BestTradeSettings.Header = styled(CardHeader)`
  line-height: 19px;
`;

export default BestTradeSettings;
