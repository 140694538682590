import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, Modal } from "reactstrap";
import InfiniteScroll from "react-infinite-scroller";
import { useFormik } from "formik";
import styled from "styled-components";
import qs from "qs";

import { addSnackBar } from "store/snackbar/actions";
import { hideEmptyAssetsSelector } from "store/credentials/selectors";
import {
  fetchAllocationRebalancing,
  fetchCredential,
  getCredentialInvestments,
  toggleRebalance,
  updateInvestmentAllocation,
  loadMoreNotifications,
} from "store/credentials/actions";
import ManageAllocationHeader from "./ManageAllocationHeader";
import ManageAllocationTitles from "./ManageAllocationTitles";
import ManageAllocationRow from "./ManageAllocationRow";
import AllocationRowsPreload from "./AllocationRowsPreload";
import { CustomScrollbars, EmptyState, ErrorAlert, Loading } from "shared/ui";
import { Button } from "shared/ui/buttons";

const PortfolioModal = ({
  onSetModalIsOpen,
  modalIsOpen,
  credentialId,
  rebalancingDifference,
  onHandleRefresh,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const credentialsInvestments = useSelector(
    state => state.credentials.allocationRebalancing.data,
  );
  const loading = useSelector(
    state => state.credentials.allocationRebalancing.loading,
  );
  const defaultRebalance =
    credentialsInvestments?.items?.[0]?.credentials?.rebalance;
  const autoRebalanceLoading = useSelector(
    state => state.credentials.rebalances.loading,
  );
  const hideEmptyInPortfolio = useSelector(hideEmptyAssetsSelector);

  const [hasMore, setHasMore] = useState(true);
  const [isAllInvestmentsLoaded, setIsAllInvestmentsLoaded] = useState(false);
  const [allCredentialsInvestments, setAllCredentialsInvestments] = useState(
    [],
  );
  const [investmentsToShow, setInvestmentsToShow] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [hideEmptyAssets, setHideEmptyAssets] = useState(true);

  const initialPlannedCount = credentialsInvestments?.items?.reduce(
    (acc, item) => acc + (item.plannedPercent || 0),
    0,
  );
  const isEmptyState = credentialsInvestments?.items?.every(
    item => !Number(item?.balance?.available?.total),
  );

  useLayoutEffect(() => {
    dispatch(fetchAllocationRebalancing(credentialId));
  }, [dispatch, credentialId]);

  useEffect(() => {
    setEnabled(defaultRebalance);
  }, [defaultRebalance]);

  useEffect(() => {
    if (credentialsInvestments?.items?.length) {
      const allInvestments = credentialsInvestments?.items?.map(item => ({
        ...item,
        counter: 0,
      }));
      setAllCredentialsInvestments(allInvestments);
    }
  }, [credentialsInvestments]);

  const handleToggle = async value => {
    const flagValue = value ? "enable" : "disable";
    const res = await dispatch(toggleRebalance(credentialId, flagValue));
    if (res.errors) return dispatch(addSnackBar("error", res.message));
    dispatch(addSnackBar("success", res.message));
    setEnabled(prev => !prev);
  };

  const handleUpdate = async values => {
    if (disableSubmit !== 100) return;
    setIsSubmitting(true);

    const response = await dispatch(
      updateInvestmentAllocation(credentialId, {
        rebalancingDifference: values.rebalancing,
        investments: values.credentials.map(
          ({ id, plannedPercent, realPercent }) => ({
            id,
            weight: (initialPlannedCount ? plannedPercent : realPercent) || 0,
          }),
        ),
      }),
    );

    if (response.errors) dispatch(addSnackBar("error", response.message));
    if (!response.errors) dispatch(addSnackBar("success", response.message));
    const filter = hideEmptyInPortfolio
      ? { realPercent: { gte: 0 }, balance: { gte: 0 } }
      : { realPercent: { gte: {} }, balance: { gte: {} } };
    setIsSubmitting(false);
    onHandleRefresh(filter);
    onSetModalIsOpen(false);
  };

  const handleCancelModal = () => {
    if (autoRebalanceLoading) return;
    if (
      location.pathname.includes("credentials") &&
      defaultRebalance !== enabled
    ) {
      const filter = qs.stringify({
        filter: hideEmptyInPortfolio
          ? { realPercent: { gte: 0 }, balance: { gte: 0 } }
          : { realPercent: { gte: {} }, balance: { gte: {} } },
        sort: { by: "realPercent", order: "DESC" },
      });
      dispatch(getCredentialInvestments(credentialId, `?${filter}`));
      dispatch(fetchCredential(credentialId));
    }
    formik.resetForm();
    onSetModalIsOpen(!modalIsOpen);
  };

  const formik = useFormik({
    initialValues: {
      rebalancing: rebalancingDifference || 1,
      credentials: allCredentialsInvestments,
    },
    enableReinitialize: true,
    onSubmit: async values => {
      if (isSubmitting || !enabled || (enabled && disableSubmit !== 100))
        return;
      await handleUpdate(values);
      setIsSubmitting(false);
    },
  });

  const getInvestmentsToShow = () => {
    if (hideEmptyAssets)
      return formik.values?.credentials.filter(investment =>
        Number(investment?.balance?.available?.total),
      );
    return formik.values?.credentials;
  };

  useEffect(() => {
    const investments = getInvestmentsToShow();
    setInvestmentsToShow(investments);
  }, [formik.values?.credentials, hideEmptyAssets]);

  const disableSubmit = initialPlannedCount
    ? formik.values.credentials?.reduce(
        (acc, val) => acc + +val.plannedPercent,
        0,
      )
    : formik.values.credentials?.reduce(
        (acc, val) => acc + +val.realPercent,
        0,
      );

  useEffect(() => {
    if (!hideEmptyAssets && !isAllInvestmentsLoaded) setHasMore(true);
  }, [hideEmptyAssets, isAllInvestmentsLoaded]);

  const handleLoadMore = async () => {
    if (investmentsToShow.length >= 10) {
      const res = await dispatch(
        loadMoreNotifications(
          credentialId,
          credentialsInvestments?.request?.page + 1,
        ),
      );
      if (res.items.length < 10) {
        setHasMore(false);
        setIsAllInvestmentsLoaded(true);
      }
    } else {
      setHasMore(false);
    }
  };

  return (
    <PortfolioModal.Modal
      isOpen={modalIsOpen}
      toggle={() => handleCancelModal()}
      modalClassName='modal-black'
      size='lg'
    >
      <Card className='m-0'>
        <ManageAllocationHeader
          enabled={enabled}
          formik={formik}
          handleToggle={handleToggle}
          hideEmptyAssets={hideEmptyAssets}
          setHideEmptyAssets={setHideEmptyAssets}
          autoRebalanceLoading={autoRebalanceLoading}
        />
        {hideEmptyAssets && isEmptyState ? (
          <EmptyState
            title='No Available Balance for Investments'
            desc="Oops! It seems your investment balance is currently empty Don't worry,
                please add funds to your investment to start allocating and growing your portfolio. Let's make your money work for you."
          />
        ) : (
          <>
            <ManageAllocationTitles
              initialPlannedCount={initialPlannedCount}
              loading={loading}
              enabled={enabled}
            />

            {loading ? (
              <AllocationRowsPreload />
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <CustomScrollbars
                  autoHide
                  autoHideTimeout={500}
                  autoHideDuration={200}
                  autoHeight
                  autoHeightMin={200}
                  autoHeightMax={354}
                >
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={handleLoadMore}
                    hasMore={hasMore}
                    loader={
                      <Loading size={32} color='var(--info)' className='mt-3' />
                    }
                    useWindow={false}
                  >
                    {investmentsToShow.map((investment, index) => (
                      <ManageAllocationRow
                        formik={formik}
                        index={index}
                        investment={investment}
                        allCredentialsInvestments={allCredentialsInvestments}
                        credentialsInvestment={credentialsInvestments}
                        enabled={enabled}
                        initialPlannedCount={initialPlannedCount}
                        key={investment.id}
                      />
                    ))}
                  </InfiniteScroll>
                </CustomScrollbars>
              </form>
            )}
          </>
        )}

        <div className='mx-4 px-2  mt-3'>
          <ErrorAlert
            isOpen={disableSubmit !== 100 && formik?.submitCount}
            className='p-0'
          >
            The sum of all {initialPlannedCount ? "planned" : "real"} percents
            should be equal to 100
          </ErrorAlert>
        </div>

        <div className='d-flex p-4 mx-2 justify-content-between'>
          <Button onClick={() => handleCancelModal()}>Cancel</Button>

          <Button
            onClick={() => formik.handleSubmit()}
            loading={isSubmitting}
            color='blue'
            disabled={isSubmitting || !enabled || !formik.values.rebalancing}
          >
            Save changes
          </Button>
        </div>
      </Card>

      {autoRebalanceLoading && (
        <PortfolioModal.Overlay>
          <div className='d-flex flex-column justify-content-center align-items-center w-100 h-100'>
            <Loading size={48} color='var(--info)' />
            <h3 className='mb-0 mt-3 text-white font-weight-500'>
              Please wait
            </h3>
          </div>
        </PortfolioModal.Overlay>
      )}
    </PortfolioModal.Modal>
  );
};

PortfolioModal.Modal = styled(Modal)`
  max-width: 965px !important;
  height: 100% !important;
  margin: 1rem auto !important;

  .modal-content {
    border-radius: 16px !important;
    overflow: hidden !important;
  }
`;

PortfolioModal.Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(46, 49, 72, 0.7);
  z-index: 100;
`;

export default PortfolioModal;
