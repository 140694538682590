import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvailableMarkets,
  indicatorsSignals,
  resetIndicatorsSignals,
} from "store/bots/actions";
import { currentConfigurationSelector } from "store/indicatorsConfiguration/selectors";
import { selectMarketsSignal } from "store/bots/selectors";
import { setMarketsSelect } from "store/markets/actions";
import {
  addDraftIndicator,
  editDraftIndicator,
  validateIndicator,
} from "store/strategies/actions";

const useIndicatorSettings = (isEdit, indicators, toogleEdit) => {
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [coins, setCoins] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [errors, setErrors] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [formValues, setFormValues] = useState({
    code: indicators?.code || "",
    type: indicators?.type || "both",
    required: indicators?.required || false,
    settings: indicators?.settings || {},
  });

  const formValuesRef = useRef(formValues);

  const dispatch = useDispatch();

  const configurations = useSelector(state => state.indicatorsConfiguration);
  const currentConfiguration = useSelector(state =>
    currentConfigurationSelector(formValues?.code || "")(state),
  );
  const subscription = useSelector(state => state.me.data.subscription);
  const wizardMarkets = useSelector(state => state.botWizard);
  const marketsSignal = useSelector(selectMarketsSignal);

  useEffect(() => {
    setFormValues(prevValues => ({
      ...prevValues,
      code: currentConfiguration?.code || "",
    }));
  }, [currentConfiguration?.code]);

  useEffect(() => {
    if (formValues.code) {
      const settings = currentConfiguration?.settings.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.code]: currentValue.default || "",
        }),
        {},
      );

      setFormValues(prevValues => ({
        ...prevValues,
        settings,
      }));
    }
  }, [formValues.code, currentConfiguration?.settings]);

  useEffect(() => {
    if (
      wizardMarkets?.allCoins &&
      !wizardMarkets?.coins?.length &&
      !coins?.length
    ) {
      dispatch(getAvailableMarkets(wizardMarkets?.investment.id, "?limit=500"));
    }
  }, [
    wizardMarkets?.allCoins,
    wizardMarkets?.investment.id,
    coins?.length,
    dispatch,
  ]);

  useEffect(() => {
    if (marketsSignal?.data?.items) {
      const coinsArray = marketsSignal?.data?.items?.map(
        ({ market }) => market?.base,
      );
      setCoins(coinsArray);
    }
  }, [marketsSignal]);

  useEffect(() => {
    if (!wizardMarkets?.coins.length) {
      dispatch(setMarketsSelect(coins));
    }
  }, [coins, wizardMarkets?.coins?.length, dispatch]);

  const onHandleResetChart = () => {
    dispatch(resetIndicatorsSignals());
    toogleEdit();
  };

  const handleChange = e => {
    const { name, value, type, checked } = e.target || {};

    setFormValues(prevValues => {
      if (name === "settings") {
        return {
          ...prevValues,
          settings: { ...prevValues.settings, ...value },
        };
      }

      return {
        ...prevValues,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const handleSubmit = async (value, e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!value?.code) {
      setIsValid(false);
    }
    const res = await dispatch(validateIndicator(value));

    if (res.errors) {
      setErrors(res?.errors?.message);
    } else {
      if (!isEdit) {
        await dispatch(addDraftIndicator({ ...value }));
      }
      if (isEdit) {
        await dispatch(editDraftIndicator({ id: indicators?.id, ...value }));
      }

      onHandleResetChart();
    }
    setSubmitted(false);
  };

  useEffect(() => {
    formValuesRef.current = formValues;
  }, [formValues]);

  useEffect(() => {
    if (formValuesRef.current?.type && selectedMarket) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const id = setTimeout(() => {
        const param = {
          period: wizardMarkets?.period,
          ...formValuesRef.current,
        };

        dispatch(
          indicatorsSignals(
            wizardMarkets?.investment?.credentials?.exchange,
            selectedMarket,
            wizardMarkets?.investment?.asset,
            formValuesRef.current?.code,
            param,
          ),
        );
      }, 300);

      setTimeoutId(id);
    }
  }, [selectedMarket, wizardMarkets?.period, dispatch, formValuesRef.current]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return {
    formValues,
    submitted,
    configurations,
    currentConfiguration,
    handleChange,
    onHandleResetChart,
    handleSubmit,
    setSelectedMarket,
    selectedMarket,
    errors,
    isValid,
    subscription,
    wizardMarkets,
  };
};

export default useIndicatorSettings;
