import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { CardBody } from "reactstrap";

import { fetchHistoryDeals } from "store/deals/actions";
import EmptyDealsHistory from "./ui/EmptyDealsHistory";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import DealHistoryTable from "./ui/DealHistoryTable";
import { ExchangeWidgetStyledWrap } from "../index.styles";
import DealHistoryHeader from "./ui/DealHistoryHeader";
import { Error, PaginationPanel, TablePreloader } from "shared/ui";
import { selectHistoryDeals } from "store/deals/selectors";

const DealsHistory = ({ botId, loading, hasNotBots }) => {
  const {
    data: { items, total, page, request },
    loading: loadingDeals,
    error,
  } = useSelector(selectHistoryDeals);
  const wrapperRef = useRef(null);

  const { handleFilter, handleSorting, handlePaginate, filters } =
    useFiltersWithRequest(request, fetchHistoryDeals);

  if (!botId || (hasNotBots && !loading)) return null;

  return (
    <ExchangeWidgetStyledWrap className='mb-0' ref={wrapperRef}>
      <DealHistoryHeader
        loading={loading}
        loadingPage={loading}
        handleFilter={handleFilter}
        request={request}
        filters={filters}
      />
      <CardBody className='p-0 overflow-hidden'>
        {error && !loadingDeals && <Error error={error} />}
        {(loadingDeals || loading) && (
          <TablePreloader
            thHeight={11}
            thWidth={[44, 74, 68, 37, 37, 82]}
            tdWidth={[80, 144, 144, 41, 32, 93]}
            tdHeight={14}
            rowTimes={10}
          />
        )}
        {!loadingDeals && !loading && items?.length ? (
          <DealHistoryTable
            items={items}
            sort={request?.sort}
            filter={request?.filter || filters}
            handleSorting={handleSorting}
            id={botId}
            total={total}
          />
        ) : null}
        {!items?.length && !loadingDeals && !loading && <EmptyDealsHistory />}
        <div className={`${total > 10 ? "pt-3" : ""}`}>
          <PaginationPanel
            activePage={page}
            totalItemsCount={total}
            onChange={page => handlePaginate(page)}
            elementRef={wrapperRef}
          />
        </div>
      </CardBody>
    </ExchangeWidgetStyledWrap>
  );
};

export default DealsHistory;
