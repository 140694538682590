import React from "react";
import styled from "styled-components";
import { Button, Card, CardFooter } from "reactstrap";
import classNames from "classnames";
import StrategyImage from "../StrategyImage";
import TagsList from "../TagsList";

const StrategyDetails = ({
  strategy = {},
  withEdit,
  link,
  onSetModalIsOpen,
  image = false,
  showName = false,
}) => {
  return (
    <StrategyDetails.Card className={classNames({ link })}>
      {image && <StrategyImage strategy={strategy} />}
      <StrategyDetails.CardHeader className='d-flex flex-column mb-3'>
        <h4 className='mb-0 mt-4'>
          {showName ? strategy.name : "Strategy settings"}
        </h4>
      </StrategyDetails.CardHeader>
      <StrategyDetails.CardBody>
        <p className='text-white-35 mb-3'>
          First round volume:{" "}
          <span className='text-white'>
            {strategy.volume || 0}
            {" %"}
          </span>
        </p>
        <p className='text-white-35 mb-3'>
          Minimum deal profit:{" "}
          <span className='text-white'>
            {strategy.profit || 0}
            {" %"}
          </span>
        </p>
        <p className='text-white-35 mb-3'>
          Stop loss percent:{" "}
          <span className='text-white'>
            {strategy.stopLossPercent || 0}
            {" %"}
          </span>
        </p>
        <p className='text-white-35 mb-3'>
          Rounds martingale:
          <span className='text-white'>
            {strategy.martingale || 0}
            {" %"}
          </span>
        </p>
        <p className='text-white-35 mb-3'>
          Rounds deviation:{" "}
          <span className='text-white'>
            {strategy.deviation || 0}
            {" %"}
          </span>
        </p>
        <p className='text-white-35 mb-3'>
          Aggressive order processing:{" "}
          <span className='text-white'>
            {strategy.aggressive ? strategy.aggressive.toString() : "false"}
          </span>
        </p>
        <p className='text-white-35 mb-3'>
          Order book reverse:{" "}
          <span className='text-white'>
            {strategy.reverse ? strategy.reverse.toString() : "false"}
          </span>
        </p>
        <p className='text-white-35 mb-3'>
          Minumum indicator signals:{" "}
          <span className='text-white'>{strategy.minimumSignals || 0}</span>
        </p>
        <p className='text-white-35 mb-3'>
          Trailing Buy:{" "}
          <span className='text-white'>
            {strategy.trailingBuy || 0}
            {" %"}
          </span>
        </p>
        <p className='text-white-35 mb-3'>
          Trailing Sell:{" "}
          <span className='text-white'>
            {strategy.trailingSell || 0}
            {" %"}
          </span>
        </p>
        <p className='text-white-35 mb-3'>
          Max rounds:{" "}
          <span className='text-white'>{strategy.maxRounds || 0}</span>
        </p>
        {!withEdit && (
          <div className='mb-3'>
            <TagsList tags={strategy.tags} uuid={strategy.uuid} />
          </div>
        )}
      </StrategyDetails.CardBody>
      {withEdit && (
        <CardFooter>
          <div className='w-100'>
            <Button color='default' onClick={() => onSetModalIsOpen(true)}>
              Edit settings
            </Button>
          </div>
        </CardFooter>
      )}
    </StrategyDetails.Card>
  );
};

StrategyDetails.Card = styled(Card)`
  &.link {
    overflow: hidden;
    &:hover {
      background: #2b2e48;
    }
  }
`;

StrategyDetails.CardBody = styled("div")`
  cursor: pointer;
  padding: 0 24px 0px 24px;
`;

StrategyDetails.CardHeader = styled("div")`
  padding: 0 24px;
`;

export default StrategyDetails;
