import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop = ({ children, location: { pathname } }) => {
  const { scrollTo } = window;

  window.onunload = function () {
    scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [scrollTo, pathname]);

  return children || null;
};

export default withRouter(ScrollToTop);
