import React, { useState, useCallback } from "react";
import classnames from "classnames";
import styled from "styled-components";
import { UncontrolledAlert } from "reactstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { track } from "@amplitude/analytics-browser";

import { handleResponse } from "helpers/errorsValidator";
import { googleAuth, loginUser } from "store/auth/actions";
import { GOOGLE_OAUTH_CLIENT_ID } from "env-create-react-app";
import StyledGoogleButton from "./compounds/StyledGoogleButton";
import { Button } from "shared/ui/buttons";

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .email("Please enter a valid email address.")
    .required("This field is required."),
  password: Yup.string().required("This field is required."),
});

const initialValues = {
  username: "",
  password: "",
};

const LoginForm = ({ setIsLoading, setGoogleLoading }) => {
  const history = useHistory();
  const [googleError, setGoogleError] = useState(null);

  const [submitted, setSubmitted] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();

  const registerReferral = localStorage.getItem("registerReferral");

  const responseGoogleSuccess = useCallback(
    async response => {
      setGoogleLoading(true);
      const error = await dispatch(googleAuth(response));
      if (error) setGoogleError(error.message);
      setGoogleLoading(false);
      history.push("/");
    },
    [setGoogleError, history, dispatch, setGoogleLoading],
  );

  const responseGoogleFailure = useCallback(
    response => {
      setGoogleLoading(false);
      if (!response.code === "idpiframe_initialization_failed") {
        setGoogleError(response.details);
      }
    },
    [setGoogleError, setGoogleLoading],
  );

  return (
    <Formik
      initialValues={{ ...initialValues }}
      isInitialValid={LoginSchema.isValidSync(initialValues)}
      validationSchema={LoginSchema}
      onSubmit={async (values, { setErrors }) => {
        setIsLoading(true);
        const token = await executeRecaptcha("login");
        if (!token) {
          setIsLoading(false);
          setErrors({ message: "GoogleReCaptcha token is not valid" });
          return;
        }
        const error = await dispatch(
          loginUser({
            ...values,
            token,
          }),
        );
        if (error) {
          handleResponse(error, setErrors);
          setIsLoading(false);
        } else {
          track("User logged in", { email: values.email });
          history.push("/");
        }
      }}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <StyledFormContainer>
            {errors.message && (
              <UncontrolledAlert
                color='danger'
                fade={false}
                style={{ color: "var(--white)" }}
              >
                {errors.message}
              </UncontrolledAlert>
            )}
            {googleError && (
              <UncontrolledAlert color='danger' fade={false}>
                {googleError}
              </UncontrolledAlert>
            )}
            <div className='mb-3'>
              <label htmlFor='email' className='text-white-75 font-ms'>
                Email
              </label>
              <Field
                id='email'
                className={classnames("form-control", {
                  "has-error": submitted && (errors.username || errors.message),
                })}
                type='email'
                name='username'
                disabled={isSubmitting}
                placeholder='Enter your email'
              />
              {submitted && errors.username ? (
                <p className='text-danger font-sm'>{errors.username}</p>
              ) : null}
            </div>
            <div className='mb-2'>
              <label htmlFor='password' className='text-white-75 font-ms'>
                Password
              </label>
              <Field
                id='password'
                type='password'
                name='password'
                disabled={isSubmitting}
                className={classnames("form-control", {
                  "has-error": submitted && (errors.username || errors.message),
                })}
                placeholder='Enter your password'
              />
              {submitted && errors.password ? (
                <p className='text-danger font-sm'>{errors.password}</p>
              ) : null}
            </div>
            <div className='d-flex justify-content-end'>
              <Link
                className='text-info text-left font-sm font-md-md'
                to='/forgot-password'
              >
                Forgot password?
              </Link>
            </div>
            <Button
              className='w-100 mt-3'
              type='submit'
              onClick={() => setSubmitted(true)}
              color='blue'
            >
              Sign in
            </Button>
            <p className='mt-3 text-center text-white-35 font-sm font-md-md'>
              {"Don't have an account?"}{" "}
              <Link
                to={`/register${registerReferral ? `?ref=${registerReferral}` : ""}`}
                className='text-info'
              >
                Sign up
              </Link>
            </p>
          </StyledFormContainer>
          <Separator className='d-flex align-items-center justify-content-center'>
            <p className='mb-0 ml-2 mr-2 text-white-35 font-sm'>
              Or continue with
            </p>
          </Separator>
          <StyledGoogleButton
            clientId={GOOGLE_OAUTH_CLIENT_ID}
            buttonText='Google'
            onSuccess={responseGoogleSuccess}
            onFailure={responseGoogleFailure}
            cookiePolicy={"single_host_origin"}
            className='w-100 justify-content-center text-white'
          />
        </Form>
      )}
    </Formik>
  );
};

const StyledFormContainer = styled.div`
  margin: 2rem 0;

  @media (min-width: 1200px) {
    margin: 2rem 0;
  }
`;

const Separator = styled.div`
  margin-bottom: 1.5rem;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #363a54;
  }
  &::before {
    margin-right: 1.375rem;
  }
  &::after {
    margin-left: 1.375rem;
  }
`;

export default LoginForm;
