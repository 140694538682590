import React from "react";
import { Card, CardBody } from "reactstrap";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const CredentialHeaderPreloader = () => (
  <Card data-testid='credential-header-preloader'>
    <CardBody>
      <div className='d-flex flex-column flex-md-row justify-content-between'>
        <div className='d-flex mb-3 mb-md-0'>
          <SkeletonPreloader circle={true} height={58} width={58} />
          <div className='d-flex flex-column ml-4 w-50'>
            <SkeletonPreloader height={20} width={110} className='my-auto' />
            <SkeletonPreloader height={15} width={200} className='my-auto' />
          </div>
        </div>
        <div className='d-flex flex-column'>
          <SkeletonPreloader
            height={24}
            width={90}
            className='mb-2 my-md-auto'
          />
          <SkeletonPreloader height={10} width={94} className='my-auto' />
        </div>
      </div>
    </CardBody>
  </Card>
);

export default CredentialHeaderPreloader;
