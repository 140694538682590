import React, { useState } from "react";
import { Tooltip } from "reactstrap";

import { ReactComponent as Information } from "assets/icons/general/information.svg";
import { INDICATORS_OPTIONS } from "views/bots/models/options";
import { SelectInput } from "shared/ui";
import { BadgeIndicatorSettingsBlock } from "shared/ui/badges";
import { IndicatorFormBTNGroup } from "./ui";
import {
  SrtledIndicatorFormSettingsCard,
  StyledCrownIcon,
  StyledIndicatorInputWrap,
} from "./index.styles";

const IndicatorFormSettingsBlock = ({
  formValues,
  setFieldValue,
  indicatorsConfiguration,
  configurations,
  subscription,
  isEdit,
  toogleEdit,
  handleSubmit,
  isValid,
  submitted,
}) => {
  const [isShow, setIsShow] = useState(false);

  const getOptions = () => {
    return configurations?.map(({ code, free, settings }) => ({
      value: code || "",
      settings,
      label:
        indicatorsConfiguration.find(prop => prop.value === code).label || "",
      isDisabled:
        subscription === "premium" || subscription === "standard"
          ? false
          : !free,
    }));
  };

  const getDefaultValue = () => {
    return {
      value: formValues.code,
      label:
        (isEdit &&
          indicatorsConfiguration?.find(
            ({ value }) => value === formValues?.code,
          )?.label) ||
        "",
    };
  };

  const toggleShow = () => setIsShow(!isShow);

  return (
    <div className='d-flex flex-column flex-md-row align-items-center p-0 w-100'>
      <div className='d-flex flex-column flex-column-reverse flex-md-row w-100'>
        <SrtledIndicatorFormSettingsCard
          required={formValues?.required}
          className='d-flex align-items-center justify-content-between mr-0 mr-md-4 mt-3 mt-md-0'
        >
          <div className='d-flex px-0'>
            <div
              className='position-relative necessary-signal mr-3'
              id={`indicator-1`}
              onClick={() =>
                setFieldValue({
                  target: { name: "required", value: !formValues?.required },
                })
              }
            >
              <Information />
            </div>
            <Tooltip
              target={`indicator-1`}
              placement='top-start'
              isOpen={isShow}
              toggle={toggleShow}
              className='text-nowrap'
            >
              Necessary indicator for trade request
            </Tooltip>
            <BadgeIndicatorSettingsBlock
              arrayIndicatorsOptions={INDICATORS_OPTIONS}
              type={formValues?.type}
              setFieldValue={setFieldValue}
            />
          </div>
        </SrtledIndicatorFormSettingsCard>
        <StyledIndicatorInputWrap data-testid='indicator-input-wrapper'>
          <SelectInput
            className='w-100'
            hasError={!isValid && !formValues?.code}
            isSearchable
            withDisabledOptions
            placeholder='Type in...'
            name='code'
            onChange={({ value }) => {
              setFieldValue({
                target: {
                  name: "code",
                  value: value,
                },
              });
            }}
            options={getOptions()}
            defaultValue={getDefaultValue()}
            isOptionDisabled={option => option.isDisabled}
            disabledOptionIcon={<StyledCrownIcon className='crown-icon' />}
          />
        </StyledIndicatorInputWrap>
      </div>
      <IndicatorFormBTNGroup
        className='d-none d-md-flex justify-content-end mt-3 mt-md-0 '
        isEdit={isEdit}
        toogleEdit={toogleEdit}
        handleSubmit={handleSubmit}
        formValues={formValues}
        isDisabled={submitted}
      />
    </div>
  );
};

export default IndicatorFormSettingsBlock;
