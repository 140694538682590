import React, { useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";

import ActiveMarketsHeader from "./ActiveMarketsHeader";
import ActiveMarketsTable from "./ActiveMarketsTable";
import ActiveMarketsPreloader from "./ActiveMarketsPreloader";
import Filters from "./Filters";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import { fetchActiveMarkets } from "store/deals/actions";
import { EmptyState, LineWithError } from "shared/ui";

const ActiveMarkets = ({ botId, loadingPage, botType }) => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const { data, loading } = useSelector(state => state.deals.activeMarkets);
  const { handleFilter, handleSorting, handlePaginate } = useFiltersWithRequest(
    data.request,
    fetchActiveMarkets,
    botId,
  );

  useLayoutEffect(() => {
    dispatch(fetchActiveMarkets(botId, "?limit=11"));
  }, [dispatch, botId]);

  if (loadingPage)
    return <ActiveMarketsPreloader withHeader withTable withMarkets />;

  return (
    <div ref={tableRef}>
      <Card className='mb-4 p-0'>
        <ActiveMarketsHeader
          botId={botId}
          data={data}
          loadingPage={loadingPage}
          loading={loading}
          handleFilter={handleFilter}
          botType={botType}
        />
        <LineWithError
          className='mx-4 mb-4'
          radius='8px'
          errorMessage={data?.metadata?.warning}
        />
        <Filters
          botId={botId}
          data={data}
          loading={loading}
          handleFilter={handleFilter}
        />
        <CardBody
          className='p-0 overflow-hidden  mt-sm'
          style={{ borderRadius: "0 0 8px 8px" }}
        >
          {loading ? (
            <ActiveMarketsPreloader withTable />
          ) : (
            <ActiveMarketsTable
              items={data.items}
              request={data?.request}
              handleSorting={handleSorting}
              total={data.total}
              page={data.page}
              pages={data.pages}
              handlePaginate={handlePaginate}
              loading={loading}
              tableRef={tableRef}
            />
          )}
        </CardBody>
        {!data?.items?.length && !loading && !data?.request?.filter?.length ? (
          <EmptyState
            title='No results for your search'
            desc="Oops, we couldn't find any results for your search.
              Please try again with a different keyword or refine your search criteria."
          />
        ) : null}
      </Card>
    </div>
  );
};

export default ActiveMarkets;
