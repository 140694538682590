import { CRITERIA_FIELDS } from "../models/criteriaConfiguration";

export const getCriteriasInitialState = (criteriasFromStore = []) =>
  CRITERIA_FIELDS.map(item => {
    const enabledCriteria = criteriasFromStore.find(c => c.code === item.code);
    return {
      code: item.code,
      power: enabledCriteria?.power || 0,
      enabled: !!enabledCriteria,
    };
  });

export const getCofigInitialState = ({
  generationSize,
  mutationProbability,
  populationFilter,
}) => ({
  generationSize: generationSize || 100,
  mutationProbability: mutationProbability || 5,
  populationFilter: populationFilter || 20,
});

export const filterLevelsPivotPoint = (value, type) => {
  if (value === 0) return "PP";
  if (value === null) return null;
  if (value) {
    const newValue = String(value)?.replace("-", "");
    return `${type}${newValue}`;
  }
};
