import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Modal } from "reactstrap";
import styled from "styled-components";

import ConditionsMarket from "./ConditionsMarket";
import IndicatorsMarket from "./IndicatorsMarket";
import PivotPointMarket from "./PivotPointMarket";
import { Icon, LineWithError } from "shared/ui";
import { Button } from "shared/ui/buttons";

const MarketSummaryModal = ({
  isOpnModal,
  handleToggleModal,
  selectedMarket,
  loadingPage,
  botData,
}) => {
  const { data: analysisMarketData, loading: analysisMarketLoading } =
    useSelector(selectAnalysisMarket);
  const { data, loading } = useSelector(geneticStrategiesAnalysis);

  const dispatch = useDispatch();
  const [base, quote] = selectedMarket.split("-");

  useEffect(() => {
    if (botData?.type === "genetic") {
      dispatch(
        getGeneticStrategiesAnalysis(
          botData?.investment?.credentials?.exchange,
          base,
          quote,
        ),
      );
    }
  }, [
    botData?.type,
    botData?.investment?.credentials?.exchange,
    base,
    quote,
    dispatch,
  ]);

  const isHaveIndicators =
    !!data?.geneticAlgorithm?.bestGenome?.tradeSettings?.indicators ||
    !!botData?.strategy?.tradeSettings?.indicators;
  const isHavePivotPoint =
    !!data?.geneticAlgorithm?.bestGenome?.tradeSettings?.pivotPoint ||
    !!botData?.strategy?.tradeSettings?.pivotPoint?.enabled;

  return (
    <MarketSummaryModal.Modal
      isOpen={isOpnModal}
      toggle={handleToggleModal}
      modalClassName='modal-black'
    >
      <Card className='m-0'>
        <CardHeader className='d-flex align-items-center justify-content-between p-3 p-md-4'>
          <div className='d-flex align-items-center'>
            <Icon size='botSize' code={base} />

            <p
              className='mb-0 ml-2 font-weight-500 text-white'
              style={{ fontSize: "22px" }}
            >
              {base}
            </p>
            <p
              className='mb-0 ml-1 font-weight-300 text-white-35 text-nowrap'
              style={{ fontSize: "22px" }}
            >
              {`/ ${quote}`}
            </p>
          </div>
          <MarketSummaryModal.Share className='mx-2 mx-md-3' />
          <p className='mb-0 text-white-75'>Market Technical analysis</p>
          <Button onClick={handleToggleModal} cancel />
        </CardHeader>

        {analysisMarketData.error && (
          <div className='px-4 mb-3'>
            <LineWithError
              errorMessage={analysisMarketData.error}
              radius='8px'
            />
          </div>
        )}

        <CardBody className='p-0'>
          <ConditionsMarket
            conditions={analysisMarketData.conditions}
            dates={analysisMarketData?.dates?.conditions}
            analysisMarketLoading={analysisMarketLoading}
            loadingPage={loadingPage || loading}
            botData={botData}
          />

          <IndicatorsMarket
            indicators={analysisMarketData.indicators}
            dates={analysisMarketData?.dates?.indicators}
            analysisMarketLoading={analysisMarketLoading}
            loadingPage={loadingPage || loading}
            botData={botData}
            isHaveIndicators={isHaveIndicators}
          />

          <PivotPointMarket
            pivotPoint={analysisMarketData.pivotPoint}
            pivotPointStateResponse={
              analysisMarketData?.pivotPointStateResponse
            }
            analysisMarketLoading={analysisMarketLoading}
            loadingPage={loadingPage || loading}
            botData={botData}
            isHavePivotPoint={isHavePivotPoint}
          />
        </CardBody>
      </Card>
    </MarketSummaryModal.Modal>
  );
};

MarketSummaryModal.Modal = styled(Modal)`
  @media (min-width: 992px) {
    max-width: fit-content !important;
    .modal-content {
      width: 900px;
    }
  }

  max-width: 100% !important;
  margin: 1rem auto !important;

  .modal-content {
    border-radius: 16px !important;
    overflow: hidden !important;
  }
`;

MarketSummaryModal.Share = styled.div`
  display: block;
  width: 2px;
  height: 32px;
  background: #363a54;
  margin: 0 24px;
`;

export default MarketSummaryModal;
