import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import moment from "moment";

import Emoji from "shared/ui/Emoji";
import { getPlanEmoji } from "./utils";
import useBuySubscription from "hooks/useBuySubscription";
import Button from "shared/ui/buttons/Button";

const SubscriptionPlan = ({ subscription, hasTrial }) => {
  const { handleBuyNow } = useBuySubscription();
  const history = useHistory();

  const handleUpgrade = e => {
    e.preventDefault();
    handleBuyNow(subscription?.stripe);
  };

  const getFormattedDate = () => {
    const value =
      subscription?.status === "trialing"
        ? subscription?.trialEndAt
        : subscription?.endDate;
    const date = new Date(Date.parse(value));
    return `Due ${moment(date).format("DD MMM YYYY")}`;
  };

  const getPlanName = () => {
    const name =
      !subscription?.type || !subscription?.active
        ? "Free"
        : subscription?.type;
    return `${name} plan`;
  };

  if ((!subscription?.type || !subscription?.active) && !hasTrial)
    return (
      <Wrapper className='d-none d-md-flex'>
        <Button
          color='blue'
          className='w-auto h-auto py-2 px-3'
          onClick={() => history.push("/billing")}
        >
          Try 7-day trial
        </Button>
      </Wrapper>
    );

  return (
    <Wrapper className='d-none d-md-flex'>
      <Emoji
        symbol={getPlanEmoji(subscription?.type, subscription?.active)}
        size={24}
        className='mr-2 my-auto'
      />
      <div className='d-flex flex-column'>
        <PlanTitle className='m-0 text-white text-nowrap text-capitalize'>
          {getPlanName()}
          {subscription?.status === "trialing" ? (
            <TrialBadge className='font-xs text-success ml-1'>Trial</TrialBadge>
          ) : null}
        </PlanTitle>
        <div className='d-flex'>
          {subscription?.type &&
          subscription?.status !== "past_due" &&
          subscription?.active ? (
            <p className='text-white-35 font-xs m-0 text-nowrap mr-1'>
              {getFormattedDate()}
            </p>
          ) : null}
          {(subscription?.type !== "premium" || !subscription?.active) &&
          hasTrial ? (
            <StyledUpgradeLink
              href='/#'
              onClick={handleUpgrade}
              className='text-info text-underline font-sm'
            >
              Upgrade
            </StyledUpgradeLink>
          ) : null}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 40px;
`;

const StyledUpgradeLink = styled.a`
  line-height: 1.2;
`;

const PlanTitle = styled.p`
  line-height: 18.75px;
`;

const TrialBadge = styled.span`
  background-color: rgba(63, 203, 176, 0.05);
  border-radius: 4px;
  line-height: 14.06px;
  padding: 2px 6px;
`;

export default SubscriptionPlan;
