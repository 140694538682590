export const CREATE_CREDENTIAL = "CREATE_CREDENTIAL";
export const GET_CREDENTIAL = "GET_CREDENTIAL";
export const DELETE_INVESTMENT = "DELETE_INVESTMENT";
export const CREATE_INVESTMENT = "CREATE_INVESTMENT";
export const UPDATE_INVESTMENT_ALLOCATION = "UPDATE_INVESTMENT_ALLOCATION";
export const GET_CREDENTIALS_SUCCESS = "GET_CREDENTIALS_SUCCESS";
export const GET_CREDENTIALS_FAILURE = "GET_CREDENTIALS_FAILURE";
export const GET_CREDENTIAL_LOADING = "GET_CREDENTIAL_LOADING";
export const GET_CREDENTIAL_SUCCESS = "GET_CREDENTIAL_SUCCESS";
export const GET_CREDENTIAL_FAILURE = "GET_CREDENTIAL_FAILURE";
export const VERIFY_CREDENTIALS = "VERIFY_CREDENTIALS";
export const GET_CREDENTIAL_OPEN_ORDERS = "GET_CREDENTIAL_OPEN_ORDERS";
export const CANCEL_CREDENTIAL_OPEN_ORDER = "CANCEL_CREDENTIAL_OPEN_ORDER";
export const GET_CREDENTIAL_REQUEST_LOG = "GET_CREDENTIAL_REQUEST_LOG";
export const GET_CREDENTIAL_STATISTICS = "GET_CREDENTIAL_STATISTICS";
export const GET_CREDENTIAL_STATISTICS_LOADING =
  "GET_CREDENTIAL_STATISTICS_LOADING";
export const GET_CREDENTIAL_TRADE_HISTORY = "GET_CREDENTIAL_TRADE_HISTORY";
export const SELL_ALT_COIN = "SELL_ALT_COIN";
export const CANCEL_SELL_ALT_COIN = "CANCEL_SELL_ALT_COIN";
export const CANCEL_SELL_ALT_COINS = "CANCEL_SELL_ALT_COINS";
export const TOGGLE_INVESTMENT = "TOGGLE_INVESTMENT";
export const UPDATE_INVESTMENTS = "UPDATE_INVESTMENTS";
export const UPDATE_CREDENTIAL_COMMENT = "UPDATE_CREDENTIAL_COMMENT";
export const GET_TRADE_HISTORY_LOADING = "GET_TRADE_HISTORY_LOADING";
export const GET_TRADE_HISTORY_SUCCESS = "GET_TRADE_HISTORY_SUCCESS";
export const GET_TRADE_HISTORY_FAILURE = "GET_TRADE_HISTORY_FAILURE";
export const GET_CREDENTIAL_INVESTMENTS_SUCCESS =
  "GET_CREDENTIAL_INVESTMENTS_SUCCESS";
export const GET_CREDENTIAL_INVESTMENTS_LOADING =
  "GET_CREDENTIAL_INVESTMENTS_LOADING";
export const GET_BALANCES_FAILURE = "GET_BALANCES_FAILURE";
export const GET_BALANCES_LOADING = "GET_BALANCES_LOADING";
export const GET_BALANCES_SUCCESS = "GET_BALANCES_SUCCESS";
export const UPDATE_ACCESS = "UPDATE_ACCESS";
export const RESET_CREDENTIALS = "RESET_CREDENTIALS";
export const RESET_TRADE_HISTORY = "RESET_TRADE_HISTORY";
export const RESET_OPEN_ORDERS = "RESET_OPEN_ORDERS";
export const RESET_CREDENTIAL = "RESET_CREDENTIAL";
export const RESET_CREDENTIAL_STATISTICS = "RESET_CREDENTIAL_STATISTICS";
export const RESET_CREDETIAL_BALANCES = "RESET_CREDETIAL_BALANCES";
export const RESET_CREDETIAL_INVESTMENTS = "RESET_CREDETIAL_INVESTMENTS";
export const RESET_CREDETIAL_STATISTICS_BALANCE =
  "RESET_CREDETIAL_STATISTICS_BALANCE";
export const RESET_REBALANCE_HISTORY = "RESET_REBALANCE_HISTORY";
export const LOAD_MORE_ALLOCATION_REBALANCING =
  "LOAD_MORE_ALLOCATION_REBALANCING";
export const GET_ALLOCATION_REBALANCING_FAILURE =
  "GET_ALLOCATION_REBALANCING_FAILURE";
export const SET_HIDE_EMPTY_ASSETS = "SET_HIDE_EMPTY_ASSETS";
export const RESET_MY_CREDETIALS = "RESET_MY_CREDETIALS";
export const RESET_CREDENTIAL_BOTS = "RESET_CREDENTIAL_BOTS";
export const FETCH_MY_CREDENTIALS_BOTS_SUCCESS =
  "FETCH_MY_CREDENTIALS_BOTS_SUCCESS";
export const FETCH_MY_CREDENTIALS_BOTS_PENDING =
  "FETCH_MY_CREDENTIALS_BOTS_PENDING";
export const FETCH_MY_CREDENTIALS_BOTS_LOADING =
  "FETCH_MY_CREDENTIALS_BOTS_LOADING";
export const FETCH_MY_DEALS_SUCCESS = "FETCH_MY_DEALS_SUCCESS";
export const FETCH_MY_CREDENTIALS_BOTS_FAILURE =
  "FETCH_MY_CREDENTIALS_BOTS_FAILURE";
