import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Card, CardHeader, CardFooter } from "reactstrap";
import classNames from "classnames";
import Button from "shared/ui/buttons/Button";
import defaultAvatar from "assets/icons/general/default-avatar.svg";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { addSnackBar } from "store/snackbar/actions";
import ProfileAvatarPreloader from "../preloaders/ProfileAvatarPreloader";
import { deleteAvatar, uploadAvatar } from "store/user/actions";
import { useAvatarCache } from "../../layouts/AvatarCacheContext";

const MAX_FILE_SIZE = 1024 * 1024;

const ProfileAvatar = ({ avatars = {}, loading }) => {
  const { avatarCache, setAvatarCache } = useAvatarCache();
  const inputEl = useRef(null);
  const [textAvatar, setTextAvatar] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const isAvatarLoading = useSelector(state => state?.me.isAvatarLoading);

  const resetFileInput = () => {
    inputEl.current.value = null;
  };

  const handleRemoveAvatar = async e => {
    setIsLoading(true);
    setTextAvatar("Deleting...");
    e.preventDefault();
    if (avatars.original) {
      const res = await dispatch(deleteAvatar());
      const typeOfAlert = res.errors ? "error" : "success";
      dispatch(addSnackBar(typeOfAlert, res.message));
      if (avatarCache.original) setAvatarCache({});
    }
    setIsLoading(false);
  };

  const handleUploadAvatar = async e => {
    const file = e.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      dispatch(addSnackBar("error", "File size is too large."));
      resetFileInput();
      return;
    }
    setIsLoading(true);
    setTextAvatar("Uploading...");
    const formData = new FormData();
    formData.append("file", file, file.name);
    const res = await dispatch(uploadAvatar(formData));
    if (res.avatars) {
      setAvatarCache(prev => ({
        ...prev,
        original: URL.createObjectURL(file),
      }));
      dispatch(addSnackBar("success", res.message));
    } else {
      dispatch(addSnackBar("error", res.message));
    }
    resetFileInput();
    setIsLoading(false);
  };

  if (loading) return <ProfileAvatarPreloader avatars={false} />;

  return (
    <ProfileAvatar.Wrapper isAvatarLoading={isAvatarLoading}>
      <Card className='card-user p-4'>
        <CardHeader className='d-flex justify-content-center px-2 pt-0 mb-4'>
          <div className='position-relative d-flex flex-column justify-content-center align-items-center'>
            <div className='avatar-wrapper m-0'>
              <img
                className='avatar mb-0 d-block h-100'
                alt='...'
                src={avatarCache?.original || avatars.original || defaultAvatar}
              />
            </div>
            <div className='avatar-spinner position-absolute d-flex flex-column align-items-center justify-content-center'>
              <ClipLoader
                color='var(--info)'
                size={40}
                loading={isAvatarLoading}
              />
              <p className='text-white-75 mb-0 mt-2 font-weight-500'>
                {isAvatarLoading && textAvatar}
              </p>
            </div>
          </div>
        </CardHeader>
        <CardFooter className='text-center p-0'>
          <div className='d-flex flex-row align-items-center justify-content-between'>
            <div
              className={classNames("", {
                "w-100": !avatars.original,
                "w-50 mr-2": avatars.original,
              })}
            >
              <Button
                color='blue'
                onClick={e => {
                  e.preventDefault();
                  inputEl.current.click();
                }}
                className='w-100'
                disabled={isLoading}
              >
                Change
              </Button>
              <input
                type='file'
                ref={inputEl}
                onChange={handleUploadAvatar}
                className='d-none'
              />
            </div>
            {avatars?.original && (
              <div className='w-50'>
                <Button
                  color='gray'
                  onClick={handleRemoveAvatar}
                  className='w-100'
                  disabled={isLoading}
                >
                  Delete
                </Button>
              </div>
            )}
          </div>
        </CardFooter>
      </Card>
    </ProfileAvatar.Wrapper>
  );
};

ProfileAvatar.Wrapper = styled.div`
  .avatar {
    opacity: ${props => (props.isAvatarLoading ? 0.1 : 1)};
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .avatar-wrapper {
    border: 5px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    width: 161px;
    height: 161px;
    position: relative;
    overflow: hidden;
  }

  .avatar-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default ProfileAvatar;
