import React from "react";

import { StyledBadgeBuySell } from "./index.styled";

const BadgeBuySell = ({ type }) => {
  return (
    <StyledBadgeBuySell type={type}>{type?.slice(0, 1)}</StyledBadgeBuySell>
  );
};

export default BadgeBuySell;
