import React from "react";
import styled from "styled-components";

import CandleStickPeriodSelect from "../compounds/CandleStickPeriodSelect";

const EditMarketsHeader = ({ period }) => (
  <EditMarketsHeader.Header className='d-flex flex-column flex-sm-row align-items-sm-center'>
    <h3 className='h1 mb-0 text-white font-weight-500 mb-3 mb-sm-0'>
      Edit Bot settings
    </h3>
    <CandleStickPeriodSelect period={period} />
  </EditMarketsHeader.Header>
);

EditMarketsHeader.Header = styled.div`
  @media (max-width: 576px) {
    .period {
      width: 100% !important;
    }
  }

  .period {
    width: 180px;
  }

  justify-content: space-between;
  background: #2e3148;
  padding: 24px 32px;
`;

export default React.memo(EditMarketsHeader);
