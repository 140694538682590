import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

import { ReactComponent as Deals } from "assets/icons/general/Deals.svg";
import DealsFilters from "./DealsFilters";
import DealsTable from "./DealsTable";
import { fetchBacktestDeals, resetDeals } from "store/deals/actions";
import { dealsListSelector } from "store/deals/selectors";
import DealsPreloader from "../preloaders/DealsPreloader";
import { EMPTY_SEARCH_RESULT_TEXT, EMPTY_STATE_TEXT } from "../models/deals";
import usePagination from "hooks/usePagination";
import useFilterAndSorting from "hooks/useFilterAndSorting";
import { EmptyState, PaginationPanel, TablePreloader } from "shared/ui";

const BacktestDeals = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(dealsListSelector);
  const { id } = useParams();
  const { search } = useLocation();
  const [isTableLoading, setIsTableLoading] = useState(false);
  const { filter, sort, handleSorting, handleFilter } = useFilterAndSorting(
    `/backtests/${id}`,
  );
  const { handlePaginate } = usePagination(`/backtests/${id}`);

  const getEmptyStates = () => {
    if (!data.items?.length && search) return EMPTY_SEARCH_RESULT_TEXT;
    return EMPTY_STATE_TEXT;
  };

  useEffect(() => {
    dispatch(fetchBacktestDeals(id, search)).finally(() =>
      setIsTableLoading(false),
    );
    return () => dispatch(resetDeals());
  }, [dispatch, id, search]);

  if (!isTableLoading && loading) return <DealsPreloader />;

  return (
    <Card className={isTableLoading ? " overflow-hidden" : ""}>
      <CardHeader className='px-3 pt-3 pb-4'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex'>
            <StyledDealsIcon />
            <p className='mb-0 ml-2 text-white font-weight-500'>Deals</p>
          </div>
          {(data.items?.length && !isTableLoading) ||
          isTableLoading ||
          (!data.items?.length && search) ? (
            <DealsFilters onHandleFilter={handleFilter} filter={filter} />
          ) : null}
        </div>
      </CardHeader>
      {isTableLoading ? (
        <TablePreloader
          tdWidth={[35, 97, 129, 97, 97, 129]}
          tdHeight={13}
          rowTimes={5}
          withCircle={24}
        />
      ) : (
        <CardBody className='p-0'>
          {!data.items?.length ? (
            <DealsTable
              items={data.items}
              sort={sort}
              handleSorting={handleSorting}
            />
          ) : (
            <EmptyState {...getEmptyStates()} />
          )}
        </CardBody>
      )}
      <CardFooter className='px-3 pt-3 pb-0'>
        <PaginationPanel
          activePage={data.page}
          totalItemsCount={data.total}
          onChange={page => handlePaginate(page)}
          className='m-0'
        />
      </CardFooter>
    </Card>
  );
};

const StyledDealsIcon = styled(Deals)`
  & path,
  & circle {
    stroke: #007bff;
  }
`;

export default BacktestDeals;
