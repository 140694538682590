import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

import { ReactComponent as Setting2 } from "assets/icons/general/setting-2.svg";
import SettingsPreloader from "../preloaders/SettingsPreloader";

const BacktestSettings = ({
  exchange = "",
  market = {},
  period = 0,
  periods = {},
  loading,
}) => {
  const getDate = date => {
    if (!date) return "";
    return date.split(" ")[0];
  };

  if (loading) return <SettingsPreloader />;

  return (
    <Card>
      <CardHeader className='px-4 pt-4 pb-0'>
        <div className='d-flex align-items-center'>
          <Setting2 />
          <p className='mb-0 ml-2 text-white text-nowrap font-weight-500'>
            Settings
          </p>
        </div>
      </CardHeader>
      <CardBody className='p-4'>
        <div className='d-flex justify-content-between'>
          <p className='font-sm text-nowrap text-white-75'>Exchange</p>
          <p className='text-white text-nowrap font-weight-500 text-capitalize'>
            {exchange}
          </p>
        </div>
        <div className='d-flex justify-content-between'>
          <p className='font-sm text-nowrap text-white-75'>Market</p>
          <p className='text-white text-nowrap font-weight-500'>
            {`${market.base} / ${market.quote}`}
          </p>
        </div>
        <div className='d-flex justify-content-between'>
          <p className='font-sm text-nowrap text-white-75'>Period</p>
          <p className='text-white font-weight-500'>{`${period} minutes`}</p>
        </div>
        {periods.start ? (
          <div className='d-flex justify-content-between'>
            <p className='font-sm text-nowrap text-white-75'>Start</p>
            <p className='text-white text-nowrap font-weight-500'>
              {getDate(periods.start)}
            </p>
          </div>
        ) : null}
        {periods.end ? (
          <div className='d-flex justify-content-between'>
            <p className='font-sm text-nowrap text-white-75'>End</p>
            <p className='text-white text-nowrap font-weight-500'>
              {getDate(periods.end)}
            </p>
          </div>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default BacktestSettings;
