import React, {
  useCallback,
  useLayoutEffect,
  useState,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Drift from "react-driftjs";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

import DashboardSidebar from "./DashboardSidebar";
import DashboardNavigation from "./DashboardNavigation";
import usePersistedState from "hooks/usePersistedState";
import { AvatarCacheProvider } from "./AvatarCacheContext";
import { fetchMe } from "store/user/actions";
import useWindowDimensions from "hooks/useWindowDimensions";
import { selectMeData, selectIsNeedOnboarding } from "store/user/selectors";
import { FallBack, PageOverlay } from "shared/ui";
import { OverlayContext } from "context/OverlayContext";

const DashboardLayout = ({ children }) => {
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const [expanded, setExpanded] = usePersistedState("expanded", true);
  const me = useSelector(selectMeData);
  const dispatch = useDispatch();
  const history = useHistory();
  const toggleSidebar = expanded => setExpanded(!expanded);
  const isNeedOnboarding = useSelector(selectIsNeedOnboarding);

  const isMobile = useWindowDimensions().width < 768;

  useEffect(() => {
    if (isNeedOnboarding) history.push("/wizard");
  }, [isNeedOnboarding, history]);

  useLayoutEffect(() => {
    if (!me?.id) {
      dispatch(fetchMe());
    }
  }, [dispatch, me?.id]);

  const showNavbarButton = useCallback(() => {
    if (
      document.documentElement.scrollTop > 20 ||
      document.scrollingElement.scrollTop > 20
    ) {
      setOpacity(1);
    }
    if (
      document.documentElement.scrollTop <= 20 &&
      document.scrollingElement.scrollTop <= 20
    ) {
      setOpacity(0);
    }
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("scroll", showNavbarButton);
    return () => window.removeEventListener("scroll", showNavbarButton);
  }, [showNavbarButton]);

  useEffect(() => {
    const rootElement = document.getElementById("root");
    if (expanded && isMobile) {
      rootElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
    } else {
      rootElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    }
  }, [expanded, isMobile]);

  return (
    <div className={`wrapper ${expanded ? "nav-open" : ""}`}>
      {displayOverlay ? <PageOverlay /> : null}
      <OverlayContext value={{ setDisplayOverlay }}>
        <AvatarCacheProvider>
          <DashboardSidebar
            expanded={expanded}
            toggleSidebar={toggleSidebar}
            isMobile={isMobile}
          />
          <div className='main-panel' data='none'>
            {expanded && isMobile && (
              <DashboardLayout.Overlay
                onClick={() => toggleSidebar(expanded)}
              />
            )}
            <DashboardNavigation
              toggleSidebar={toggleSidebar}
              expanded={expanded}
              opacity={opacity}
              setExpanded={setExpanded}
              isMobile={isMobile}
            />
            <DashboardLayout.Content expanded={expanded}>
              <Sentry.ErrorBoundary fallback={<FallBack />}>
                {children}
              </Sentry.ErrorBoundary>
            </DashboardLayout.Content>
            <div className='footer'>
              <div className='text-right'>
                <p className='m-0'>
                  © {new Date().getFullYear()} DarkBot. All rights reserved
                </p>
              </div>
            </div>
          </div>
        </AvatarCacheProvider>
      </OverlayContext>
      {!window.location.href.includes("dev") && me?.id ? (
        <Drift
          appId='8i6m8iiez7xr'
          userId={me.id}
          attributes={{
            email: me.username,
            name: me.name,
            avatarUrl: me.avatars?.medium,
            createdAt: me.createdAt,
            updatedAt: me.updatedAt,
            verified: me.confirmed,
          }}
          development={false}
        />
      ) : null}
    </div>
  );
};

DashboardLayout.Content = styled.div`
  padding: 80px 40px 30px ${props => (props.expanded ? "276px" : "160px")};
  min-height: calc(100vh - 70px);

  @media (max-width: 1199.98px) {
    padding-left: ${props => (props.expanded ? "0" : "15px")} !important;
    padding-right: 15px !important;
  }

  @media (max-width: 1200px) {
    padding-top: 120px !important;
  }
`;

DashboardLayout.Footer = styled.div`
  position: absolute;
  bottom: 0;
  font-size: 12px;
  padding: 5px;
`;

DashboardLayout.Overlay = styled.div`
  background: rgb(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: absolute;
`;

export default DashboardLayout;
