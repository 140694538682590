import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormGroup, Label, Tooltip } from "reactstrap";
import styled from "styled-components";

import FilterMinMaxChange from "./FilterMinMaxChange";
import FilterPriceDifference from "./FilterPriceDifference";
import FiltersPreloader from "./FiltersPreloader";
import {
  setBidAskPercent,
  setMinimumMarketsTrades,
  setMinimumMarketsVolume,
  setTrend,
} from "store/bots/wizard/actions";
import { ReactComponent as DropdownIndicatorIcon } from "assets/icons/dashboard/dropdownIndicator.svg";
import { ReactComponent as Icon } from "assets/icons/bot/autobrightness.svg";
import { ReactComponent as Help } from "assets/icons/general/Help.svg";
import { NumberInput } from "shared/ui";

const Filters = ({ selectedInvestment, isEdit, loading }) => {
  const [showConditions, setShowConditions] = useState(isEdit);
  const [tooltip, setTooltip] = useState(false);

  const toggle = () => setTooltip(!tooltip);

  const dispatch = useDispatch();
  const { minimumTrades, volume, spreadPercent, marketDirection, investment } =
    useSelector(state => state.botWizard);

  const handleTrend = value => {
    if (value !== marketDirection) return dispatch(setTrend(value));
    dispatch(setTrend(null));
  };

  const handleMinimumTrades = value => dispatch(setMinimumMarketsTrades(value));
  const handleMinimumVolume = value => dispatch(setMinimumMarketsVolume(value));
  const handleBidAsk = value => dispatch(setBidAskPercent(value));

  return (
    <Filters.Wrapper showConditions={showConditions}>
      {loading ? (
        <FiltersPreloader />
      ) : (
        <>
          <div className='d-flex align-items-center justify-content-between mb-2 mb-md-4'>
            <div className='d-flex flex-column flex-md-row align-items-md-center justify-content-between w-100'>
              <div className='d-flex mb-4 mb-md-0'>
                <Icon />
                <h3 className='mb-0 ml-2 text-white font-weight-500'>
                  Market conditions
                </h3>
              </div>
            </div>
            <Filters.Show
              className='d-flex align-items-center cursor-pointer'
              onClick={() => setShowConditions(!showConditions)}
            >
              <p className='mb-0 mr-2 text-white'>
                {showConditions ? "Hide" : "Show"}
              </p>
              <DropdownIndicatorIcon className='chevron' />
            </Filters.Show>
          </div>
          {showConditions ? (
            <div className='d-flex flex-column flex-lg-row'>
              <Col xs='12' lg='8' className='pl-0 pr-0 pr-lg-4'>
                <div className='d-flex flex-column flex-md-row align-items-center mb-4'>
                  <div className='w-100 position-relative'>
                    <div className='mb-1'>
                      <Label className='text-white-75 mb-0'>
                        Minimum market trades
                      </Label>
                    </div>
                    <FormGroup className='markets-input mb-2 mb-md-0'>
                      <NumberInput
                        disabled={
                          investment?.credentials?.exchange === "hitbtc"
                        }
                        type='int'
                        name='minimumTrades'
                        placeholder=''
                        className={`form-control text-white font-sm ${minimumTrades === 0 ? "error" : ""}`}
                        value={minimumTrades}
                        onChange={value => handleMinimumTrades(value)}
                        autoComplete='off'
                      />
                    </FormGroup>
                    <p className='font-xs text-danger mb-0 mt-1 position-absolute'>
                      {minimumTrades === 0 &&
                        "* Should be greater than 0 or empty."}
                    </p>
                  </div>
                  <div className='w-100 mx-2 position-relative'>
                    <div className='mb-1'>
                      <Label className='text-white-75 mb-0'>
                        Minimum market volume
                      </Label>
                    </div>
                    <FormGroup className='markets-input mb-2 mb-md-0'>
                      <NumberInput
                        type='float'
                        name='volume'
                        placeholder=''
                        className={`form-control text-white font-sm ${volume === 0 ? "error" : ""}`}
                        value={volume}
                        onChange={value => handleMinimumVolume(value)}
                        autoComplete='off'
                      />
                      <div className='position-absolute top-2 right-3 text-white opacity-35 font-sm'>
                        {selectedInvestment && selectedInvestment?.asset}
                      </div>
                    </FormGroup>
                    <p className='font-xs text-danger mb-2 mb-md-0 mt-1 position-absolute'>
                      {volume === 0 && "* Should be greater than 0 or empty."}
                    </p>
                  </div>
                  <div className='w-100'>
                    <div className='mb-1'>
                      <Label className='text-white-75 mb-2 mb-md-0 mr-1'>
                        Bid-ask spread percent
                      </Label>
                      <Help className='cursor-pointer' id='bidAsk' />
                      <Tooltip
                        placement='top'
                        isOpen={tooltip}
                        target='bidAsk'
                        toggle={toggle}
                      >
                        The gap percent in price between the highest bid and
                        lowest ask
                      </Tooltip>
                    </div>
                    <FormGroup className='markets-input mb-2 mb-md-0'>
                      <NumberInput
                        type='float'
                        name='spreadPercent'
                        placeholder=''
                        className='form-control text-white font-sm'
                        value={spreadPercent}
                        onChange={value => handleBidAsk(value)}
                        autoComplete='off'
                      />
                      <div className='position-absolute top-2 right-3 text-white opacity-35 font-sm'>
                        %
                      </div>
                    </FormGroup>
                    <p className='font-xs text-danger mb-2 mb-md-0 mt-1 position-absolute'>
                      {spreadPercent === 0 &&
                        "* Should be greater than 0 or empty."}
                    </p>
                  </div>
                </div>
                <div className='pt-2'>
                  <FilterMinMaxChange />
                </div>
              </Col>
              <Col xs='12' lg='4' className='pl-0 pl-lg-3 pr-0 mt-3 mt-md-0'>
                <Label className='text-white-75 mb-0'>Trend</Label>
                <div className='d-flex align-items-center justify-content-between mb-4'>
                  <Filters.Trend
                    className={marketDirection === "bull" ? "active" : ""}
                    onClick={() => handleTrend("bull")}
                  >
                    <p className='text-success'>Bull</p>
                  </Filters.Trend>
                  <Filters.Trend
                    className={marketDirection === "bear" ? "active" : ""}
                    onClick={() => handleTrend("bear")}
                  >
                    <p className='text-danger'>Bear</p>
                  </Filters.Trend>
                </div>
                <FilterPriceDifference />
              </Col>
            </div>
          ) : null}
        </>
      )}
    </Filters.Wrapper>
  );
};

Filters.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px 40px 24px;

  .chevron {
    width: 24px;
    height: 24px;
    transition: transform 0.2s ease-in-out;
    transform: ${({ showConditions }) =>
      showConditions ? "rotate(-180deg)" : null};
  }
`;

Filters.Show = styled.div`
  &:hover {
    p {
      color: rgba(255, 255, 255, 0.75) !important;
    }
    svg {
      path {
        stroke: rgba(255, 255, 255, 0.75);
      }
    }
  }
`;

Filters.Trend = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2e3148;
  border-radius: 8px;
  border: 2px solid transparent;
  cursor: pointer;
  height: 43px;
  width: 49%;

  p {
    font-weight: 500;
    margin-bottom: 0;
  }

  &.active {
    background: #193e6f;
    border: 2px solid var(--info);
  }
`;

export default React.memo(Filters);
