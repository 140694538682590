export const GET_STRATEGIES_FAILURE = "GET_STRATEGIES_FAILURE";
export const GET_STRATEGY_FAILURE = "GET_STRATEGIES_FAILURE";
export const ATTACH_STRATEGY_INDICATORS = "ATTACH_STRATEGY_INDICATORS";
export const DETACH_STRATEGY_INDICATOR = "DETACH_STRATEGY_INDICATOR";
export const UPDATE_STRATEGY_INDICATOR = "UPDATE_STRATEGY_INDICATOR";
export const LOAD_MORE_STRATEGIES = "LOAD_MORE_STRATEGIES";
export const GET_SIGNALS_SUCCESS = "GET_SIGNALS_SUCCESS";
export const GET_SIGNALS_LOADING = "GET_SIGNALS_LOADING";
export const GET_SIGNALS_FAILURE = "GET_SIGNALS_FAILURE";
export const UPDATE_STRATEGY_NAME = "UPDATE_STRATEGY_NAME";
export const DELETE_STRATEGY_IMAGE = "DELETE_STRATEGY_IMAGE";
export const POST_STRATEGY_IMAGE = "POST_STRATEGY_IMAGE";
export const ADD_INDICATOR = "ADD_INDICATOR";
export const REMOVE_INDICATOR = "REMOVE_INDICATOR";
export const EDIT_INDICATOR = "EDIT_INDICATOR";
export const CLEAN_UP_INDICATORS = "CLEAN_UP_INDICATORS";
export const UPDATE_STRATEGY = "UPDATE_STRATEGY";
export const RESET_STRATEGIES = "RESET_STRATEGIES";
export const RESET_STRATEGY = "RESET_STRATEGY";
export const RESET_SIGNALS = "RESET_SIGNALS";
export const RESET_WIZARD_INDICATORS = "RESET_WIZARD_INDICATORS";
