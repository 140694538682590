import React from "react";

import { ReactComponent as Market } from "assets/icons/general/clipboardtext.svg";
import { MarketStatusStyled } from "./index.styled";

const MarketInformHeader = ({ active }) => {
  return (
    <div className='d-flex justify-content-between align-items-center p-4'>
      <div className='d-flex align-items-center'>
        <Market stroke='red' />
        <p className='mb-0 ml-2 text-white font-weight-500'>
          Market information
        </p>
      </div>
      <MarketStatusStyled className='rounded-top rounded-bottom custom-background'>
        <p
          className={`font-weight-500 font-sm text-center mb-0 ${
            active ? "text-success" : "text-danger"
          }`}
        >
          {active ? "Active" : "Inactive"}
        </p>
      </MarketStatusStyled>
    </div>
  );
};

export default MarketInformHeader;
