import React from "react";
import styled from "styled-components";
import { FormGroup, Card, CardBody } from "reactstrap";
import { useSelector } from "react-redux";

import { mergeStyles } from "shared/ui/SelectInput/utils";
import { beforeToday } from "helpers/DatepickerValidation";
import { ReactComponent as SmallToBig } from "assets/icons/general/small-to-big.svg";
import {
  DEAL_RETURN_OPTIONS,
  DEAL_SORTING_OPTIONS,
  DEAL_STATUS_OPTIONS,
} from "models/filters";
import useFilterAndSorting from "hooks/useFilterAndSorting";
import { DateField, ExchangesSelect, SelectInput } from "shared/ui";

const styles = {
  control: (_, props) => ({
    minWidth: props.selectProps.isMulti ? "175px" : "110px",
  }),
};

const marketStyles = {
  control: () => ({
    minWidth: "180px",
  }),
};

const Filters = ({ loading }) => {
  const { handleFilter, handleSorting, sort, filter } =
    useFilterAndSorting("/deals");

  const marketsData = useSelector(state => state.deals.dealsMarkets.data);
  const marketsLoading = useSelector(state => state.deals.dealsMarkets.loading);

  const getMarketValue = () => {
    const selectedMarket =
      marketsData?.length &&
      marketsData?.filter(item =>
        filter?.market?.in?.some(m => m === item?.market?.pair),
      );
    return selectedMarket?.length
      ? selectedMarket?.map(market => ({
          value: market?.market?.pair,
          label: market?.market?.pair?.replace("-", " / "),
          icon: {
            type: "cryptocurrency",
            size: "selectSize",
            code: market?.market?.base,
          },
        }))
      : [];
  };

  return (
    <Filters.Wrapper>
      <CardBody className='p-4'>
        <div className='d-flex flex-md-row flex-column justify-content-between m-0 flex-md-wrap flex-lg-nowrap'>
          <div className='d-flex flex-column flex-md-row w-100 mb-md-2 mb-lg-0'>
            <FormGroup className='mb-0 w-100'>
              <SelectInput
                isClearable
                placeholder='Status'
                className='mr-0 mr-md-3 mb-2 mb-md-0'
                styles={mergeStyles(styles)}
                isMulti
                value={DEAL_STATUS_OPTIONS.filter(o =>
                  filter?.status?.in?.some?.(s => s === o.value),
                )}
                name='status'
                onChange={values => {
                  handleFilter({
                    status: {
                      in: values?.some(({ value }) => value === "all")
                        ? DEAL_STATUS_OPTIONS?.map(e => e.value)
                        : values?.map(e => e.value),
                    },
                  });
                }}
                options={DEAL_STATUS_OPTIONS}
              />
            </FormGroup>
            <ExchangesSelect
              isClearable
              className='mr-3 w-100 mb-2 mb-md-0'
              placeholder='Exchange'
              styles={mergeStyles(styles)}
              name='exchange'
              value={filter?.["credentials.exchange"]?.in}
              isMulti
              onChange={values => {
                handleFilter({
                  "credentials.exchange": {
                    in: values ? values.map(e => e.value) : "",
                  },
                });
              }}
              width='100%'
            />
            <SelectInput
              isClearable
              placeholder='Market'
              className='mr-3 w-100 mb-2 mb-md-0'
              isMulti
              styles={mergeStyles(marketStyles)}
              isLoading={marketsLoading}
              value={getMarketValue()}
              onChange={values => {
                handleFilter({
                  market: {
                    in: values?.length
                      ? values?.map(e => e.label?.replace(" / ", "-"))
                      : "",
                  },
                });
              }}
              options={
                marketsData?.length &&
                marketsData?.map(market => {
                  return {
                    value: market?.market?.pair,
                    label: market?.market?.pair?.replace("-", " / "),
                    icon: {
                      type: "cryptocurrency",
                      size: "selectSize",
                      code: market?.market?.base,
                    },
                  };
                })
              }
            />
            <SelectInput
              isClearable
              placeholder='Return'
              className='mr-3 w-100 mb-2 mb-md-0'
              styles={mergeStyles(styles)}
              value={DEAL_RETURN_OPTIONS.find(
                s => s.value === Object.keys(filter?.profit || {})[0],
              )}
              onChange={option =>
                handleFilter({
                  profit: option?.value ? { [option.value]: 0 } : "",
                })
              }
              options={DEAL_RETURN_OPTIONS}
            />
          </div>

          <div className='d-flex flex-column flex-sm-row w-100'>
            <div className='d-flex w-100'>
              <DateField
                className='w-100 mr-2'
                disabled={loading}
                onChange={value => {
                  handleFilter({
                    createdAt: {
                      gte: value,
                      lte: "",
                    },
                  });
                }}
                isValidDate={beforeToday}
                initialViewDate={""}
                dateFormat='YYYY-MM-DD HH:mm:ss'
                inputProps={{
                  style: {
                    background: "#2E3148",
                    color: "#FFFFFF",
                    padding: "11px",
                  },
                  readOnly: true,
                  className: "form-control mb-2 mb-md-0",
                  name: "createdFrom",
                  placeholder: "From",
                  value: filter?.createdAt?.gte || "",
                }}
              />
              <DateField
                className='w-100 mr-sm-3'
                onChange={value =>
                  handleFilter({
                    createdAt: {
                      ...filter.createdAt,
                      lte: value,
                    },
                  })
                }
                initialViewDate={""}
                dateFormat='YYYY-MM-DD HH:mm:ss'
                isValidDate={current =>
                  (current.isAfter(filter?.createdAt?.gte) &&
                    beforeToday(current)) ||
                  !current
                }
                inputProps={{
                  style: {
                    background: "#2E3148",
                    color: "#FFFFFF",
                    padding: "11px",
                  },
                  readOnly: true,
                  className: "form-control w-100 mb-2 mb-md-0",
                  name: "createdTo",
                  placeholder: "To",
                  value: filter?.createdAt?.lte || "",
                }}
              />
            </div>

            <div className='d-flex justify-content-between'>
              <SelectInput
                isClearable
                placeholder='Sort by'
                className='pr-3 w-100 mb-2 mb-md-0'
                name='sortBy'
                styles={mergeStyles(styles)}
                value={DEAL_SORTING_OPTIONS.find(
                  option => option.value === sort?.by,
                )}
                onChange={option =>
                  handleSorting({
                    order: sort?.order || "DESC",
                    by: option?.value,
                  })
                }
                options={DEAL_SORTING_OPTIONS}
              />
              <Filters.Sorting
                onClick={() => {
                  handleSorting({
                    ...sort,
                    order: sort && sort.order === "ASC" ? "DESC" : "ASC",
                  });
                }}
                loading={loading}
              >
                <Filters.SortingOrderIcon order={sort?.order || "DESC"} />
              </Filters.Sorting>
            </div>
          </div>
        </div>
      </CardBody>
    </Filters.Wrapper>
  );
};

Filters.Calendar = styled.div`
  position: absolute;
  top: 20%;
  right: 4%;

  svg {
    path {
      fill: #a3a3a3;
    }
  }
`;

Filters.SortingOrderIcon = styled(SmallToBig)`
  transition: transform 0.2s ease-in-out;
  transform: ${props => (props.order === "DESC" ? "rotate(180deg)" : "")};
`;

Filters.Wrapper = styled(Card)`
  border-radius: 8px !important;
  margin-bottom: 24px !important;
  .react-select__value-container {
    padding-left: 11px;
  }
`;

Filters.SortWrapper = styled.div`
  .css-2b097c-container {
    width: 80%;
  }
`;

Filters.Sorting = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    path {
      fill-opacity: ${props => (props.loading ? ".2" : "0.35")};
    }
  }

  &:hover {
    svg {
      path {
        fill-opacity: ${props => (props.loading ? ".2" : "1")};
      }
    }
  }
`;

export default Filters;
