import React from "react";

import { formatterToNull } from "helpers/valuesFormatters";
import { MarketInfoItemStyled } from "views/exchanges/compounds/StyledCardBody";

const MarketInfoItem = ({ title, value }) => {
  return (
    <MarketInfoItemStyled className='d-flex flex-column my-auto '>
      <p className='font-weight-light title opacity-35'>{title}</p>
      <p className='value text-white font-weight-bold'>
        {formatterToNull(value)}
      </p>
    </MarketInfoItemStyled>
  );
};

export default MarketInfoItem;
