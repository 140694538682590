import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import classnames from "classnames";

import ResultsDropdownItem from "./ResultsDropdownItem";
import ResultListHeader from "./ResultListHeader";
import { ReactComponent as Channels } from "assets/icons/general/Channels.svg";
import { ReactComponent as Ellipse } from "assets/icons/general/ellipse.svg";
import { ChannelLogo } from "shared/ui";

const ChannelsResults = ({ channelsResult, onSetModalSearch }) => {
  const history = useHistory();
  if (!channelsResult.length) return null;

  return (
    <div className='d-flex flex-column mb-4'>
      <ResultListHeader className='d-flex p-2 mb-2'>
        <ChannelsResults.Channels />
        <p className='font-sm ml-2 my-auto'>Channels</p>
      </ResultListHeader>
      {channelsResult.map(item => (
        <ResultsDropdownItem
          className='d-flex flex-column flex-md-row justify-content-between align-items-md-center pr-2 pl-2 mt-1 mb-md-1 mb-3'
          key={item.id}
          onClick={() => {
            onSetModalSearch(false);
            history.push(`/channels/${item.id}`);
          }}
        >
          <div className='d-flex mb-1 mb-md-0'>
            <div className='d-flex'>
              <ChannelLogo size={24} />
              <p className='text-dark my-auto font-sm font-weight-500 ml-3'>
                {item.title}
              </p>
              <ChannelsResults.BotEllipse
                status={item.metadata.status}
                className='my-auto ml-1'
              />
            </div>
          </div>
          <div className='d-flex font-xs justify-content-between'>
            <p className='text-dark-35 m-0 mr-3'>
              {`Created at ${item.metadata.createdAt}`}
            </p>
            <p
              className={classnames("m-0 text-capitalize font-weight-500", {
                "text-success": item.metadata.status === "open",
                "text-dark-75": item.metadata.status === "closed",
              })}
            >
              {item.metadata.status}
            </p>
          </div>
        </ResultsDropdownItem>
      ))}
    </div>
  );
};

ChannelsResults.BotEllipse = styled(Ellipse)`
  width: 6px;
  height: 6px;

  & circle {
    fill: ${props =>
      props.status === "open" ? "#5FD3BC" : "rgba(29, 30, 42, 0.35)"};
  }
`;

ChannelsResults.Channels = styled(Channels)`
  width: 20px;
  height: 20px;
  path {
    stroke: rgba(51, 51, 51, 0.35);
  }
`;

export default ChannelsResults;
