import React from "react";
import styled from "styled-components";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import { CardHeader } from "reactstrap";

const MarketHeaderPreloader = () => {
  return (
    <MarketHeaderPreloader.Header className='d-flex flex-row align-items-center justify-content-between px-4 py-3'>
      <div className='d-flex flex-row align-items-center'>
        <SkeletonPreloader
          circle={true}
          height={41}
          width={41}
          className='mr-3'
        />
        <SkeletonPreloader height={16} width={94} />
      </div>
      <div>
        <SkeletonPreloader height={28} width={28} borderRadius={"6px"} />
      </div>
    </MarketHeaderPreloader.Header>
  );
};

MarketHeaderPreloader.Header = styled(CardHeader)`
  background: #2e3148 !important;
  border-radius: 8px 8px 0 0 !important;
`;

export default MarketHeaderPreloader;
