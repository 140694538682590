import React from "react";
import { Col, Row } from "reactstrap";

import ReferralLinkWidget from "./ReferrallinkWidget";
import TotalReferrals from "./TotalReferralsWidget/index";
import ReferralChartStatistics from "./ReferralChartStatistics";
import TableRegisteredUsers from "./TableRegisteredUsers";

const ReferralData = ({
  referral = {},
  chartData,
  setHoverCurrentBg,
  hoverCurrentBg,
  setHoverredElemId,
  hoverredElemId,
  labels,
  statisticsData,
  maxAmount,
  referralCode,
  total,
}) => {
  return (
    <Row>
      <Col xs='12' md='4'>
        <ReferralLinkWidget referralCode={referralCode} />
        <TotalReferrals
          total={total}
          setHoverCurrentBg={setHoverCurrentBg}
          hoverCurrentBg={hoverCurrentBg}
          hoverredElemId={hoverredElemId}
          setHoverredElemId={setHoverredElemId}
          chartData={chartData}
        />
      </Col>
      <Col xs='12' md='8'>
        <ReferralChartStatistics
          statisticsData={statisticsData}
          labels={labels}
          maxAmount={maxAmount}
        />
        <TableRegisteredUsers referral={referral} />
      </Col>
    </Row>
  );
};

export default ReferralData;
