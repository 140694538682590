import React from "react";
import styled from "styled-components";
import { Col, Row } from "reactstrap";

import { indicatorsConfiguration } from "views/bots/wizard/Configuration/Indicators/models/indicatorsConfiguration";
import { ReactComponent as BuyLabelIcon } from "assets/icons/property/Buy.svg";
import { ReactComponent as SellLabelIcon } from "assets/icons/property/Sell.svg";
import { ReactComponent as BothLabelIcon } from "assets/icons/property/BuySellLabel.svg";

const indicatorName = indicatorsConfiguration.filter(({ value }) => value);

const Indicator = ({ indicator }) => {
  const settings = Object.values(indicator.settings).join(", ");

  return (
    <Indicator.Wrapper
      className='d-block d-flex flex-row align-items-center position-relative'
      required={indicator.required}
    >
      <Row className='justify-content-between no-gutters w-100'>
        <Col
          xs={10}
          md={8}
          className='d-flex flex-column justify-content-start px-0 text-white'
        >
          <div className='d-flex align-items-center'>
            <p className='text-white text-uppercase font-weight-500 mb-0 font-sm mr-3'>
              {indicatorName.map(
                item => indicator.code === item.value && item.label,
              )}
            </p>
          </div>
          <p className='w-100 text-truncate mb-0 text-white-35 font-weight-400 font-xs text-nowrap'>
            {settings}
          </p>
        </Col>
        <Col xs={2} md={4} className='d-flex flex-row justify-content-end px-0'>
          <Indicator.IconWrapper className='d-flex align-items-center'>
            {indicator.type === "sell" && <SellLabelIcon />}
            {indicator.type === "buy" && <BuyLabelIcon />}
            {indicator.type === "both" && <BothLabelIcon />}
          </Indicator.IconWrapper>
        </Col>
      </Row>
    </Indicator.Wrapper>
  );
};

Indicator.Wrapper = styled.div`
  border-top: 1px solid #2e3148;
  padding: 0.75rem 1.5rem;

  .necessary-signal {
    width: 24px;
    svg {
      path {
        stroke: ${props =>
          props.required ? "var(--warning)" : "rgba(255, 255, 255, 0.35)"};
        transition: 0.3s all ease-in-out;
      }
    }
  }
`;

Indicator.IconWrapper = styled.div`
  & svg {
    width: 18px;
    height: 18px;
    margin-right: 0.5rem;
  }
`;

export default Indicator;
