import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";
import LineWithError from "shared/ui/LineWithError";
import PortfolioHeader from "./PortfolioHeader";
import PortfolioBody from "./PortfolioBody";

const Portfolio = ({
  investment = {},
  index,
  rebalance,
  initialPlannedCount,
  balanceHidden,
}) => {
  return (
    <Link to={`/investments/${investment.id}`}>
      <Portfolio.Card>
        <PortfolioHeader
          investment={investment}
          index={index}
          rebalance={rebalance}
          initialPlannedCount={initialPlannedCount}
        />
        <LineWithError errorMessage={investment.error} radius='0' />
        <PortfolioBody investment={investment} balanceHidden={balanceHidden} />
      </Portfolio.Card>
    </Link>
  );
};

Portfolio.Card = styled(Card)`
  cursor: pointer;
  border-radius: 8px !important;
  margin-bottom: 16px !important;
  overflow: hidden;
  .card-body {
    background: rgba(148, 158, 255, 0.06);
    padding: 20px 24px !important;
  }
  &:hover {
    .card-header {
      background: #202231 !important;
    }
    .card-body {
      background: #242637 !important;
    }
  }
`;

export default Portfolio;
