export const RETURN_OPTIONS = [
  { value: "gt", label: "Profit", color: "#3FCBB0" },
  { value: "lt", label: "Loss", color: "#EA4848" },
];

export const STATUS_OPTIONS = [
  { value: "completed", label: "Completed", color: "#5FD3BC" },
  {
    value: "cancelled",
    label: "Cancelled",
    color: "rgba(255, 255, 255, 0.35)",
  },
];
export const CONDITIONS_OPTIONS = [
  { value: "fulfilled", label: "Fulfilled", color: "#5FD3BC" },
  {
    value: "not_fulfilled",
    label: "Not satisfied",
    color: "rgba(255, 255, 255, 0.35)",
  },
];
export const INDICATORS_OPTIONS = [
  { value: "buy", label: "Buy", badgeClass: "bg-success" },
  { value: "sell", label: "Sell", badgeClass: "bg-primary" },
  { value: "both", label: "Both", badgeClass: "bg-warning" },
];

export const CANDLE_OPTIONS = {
  styles: {
    container: {
      width: "109px",
      height: "32px",
    },
    badge: {
      width: "52px",
      height: "26px",
    },
  },
  options: [
    {
      value: "buy",
      label: "Buy",
      badgeClass: "bg-success",
    },

    {
      value: "sell",
      label: "Sell",
      badgeClass: "bg-primary",
    },
  ],
};

export const CANDLE_NEUTRAL_OPTIONS = {
  styles: {
    container: {
      width: "250px",
      height: "32px",
    },
    badge: {
      width: "78px",
      height: "26px",
    },
  },
  options: [
    { value: "bullish", label: "Bullish", badgeClass: "bg-success" },
    { value: "neutral", label: "Neutral", badgeClass: "bg-info" },
    { value: "bearish", label: "bearish", badgeClass: "bg-primary" },
  ],
};
