import React from "react";

import Icon from "../../Icon";
import { StyledBadgeWithIcon } from "./index.styles";

const BadgeWithIcon = ({
  iconType = "exchanges",
  iconSize = "thumb",
  iconCode,
  isDark = true,
  text,
  className,
}) => {
  return (
    <StyledBadgeWithIcon
      className='d-flex justify-content-center align-items-center pl-1 pr-2 py-1'
      isDark={isDark}
    >
      <Icon size={iconSize} type={iconType} code={iconCode} />
      <p className={`ml-1 m-0 my-auto ${className}`}>{text}</p>
    </StyledBadgeWithIcon>
  );
};

export default BadgeWithIcon;
