import { Card } from "reactstrap";
import styled from "styled-components";

export const MyCredentialsStyledCard = styled(Card)`
  margin-bottom: 0 !important;
  border-radius: 12px 12px 0 0 !important;
`;

export const MyCredentialsStyledItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  div {
    border-radius: 10px !important;
  }
`;

export const MyCredentialsPreloaderItems = styled.div`
  border-radius: 10px;
  background: var(--BG-2nd-block, #2e3148);
`;
