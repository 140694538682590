import React from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";

import ResultsDropdownItem from "./ResultsDropdownItem";
import ResultListHeader from "./ResultListHeader";
import { ReactComponent as Deal } from "assets/icons/general/deal.svg";
import { Icon } from "shared/ui";
import { BadgeWithIcon } from "shared/ui/badges";

const DealsResults = ({ dealsResult, onSetModalSearch }) => {
  const history = useHistory();

  if (!dealsResult.length) return null;

  return (
    <div className='d-flex flex-column mb-4'>
      <ResultListHeader className='d-flex p-2 mb-2'>
        <Deal />
        <p className='font-sm font-weight-400 ml-2 my-auto'>Deals</p>
      </ResultListHeader>
      {dealsResult.map(item => (
        <ResultsDropdownItem
          className='h-auto d-flex flex-column flex-md-row justify-content-between pr-2 pl-2 mt-1 mb-md-1 mb-3'
          key={item.id}
          onClick={() => {
            onSetModalSearch(false);
            history.push(`/deals/${item.id}`);
          }}
        >
          <div className='d-flex align-items-center mb-1 mb-md-0'>
            <BadgeWithIcon
              text={item.metadata.exchange}
              className='font-sm'
              iconCode={item.metadata.exchange}
              iconSize='smallSize'
              isDark={false}
            />
            <div className='d-flex ml-2 align-items-center'>
              <Icon
                code={item.metadata.market.base}
                type='cryptocurrency'
                style={{
                  width: "29px",
                  position: "relative",
                  left: "15%",
                  background: "#FFFFFF",
                  border: "2px solid white",
                  borderRadius: "50%",
                }}
                className='hoverDeals'
              />
              <Icon
                code={item.metadata.market.quote}
                size='thumb'
                type='cryptocurrency'
              />
            </div>
            <p
              className='mb-0 ml-2 font-weight-500 text-nowrap'
              style={{ color: "#333333" }}
            >
              {`${item.metadata.market.base} / ${item.metadata.market.quote}`}
            </p>
          </div>
          <div className='d-flex align-items-center justify-content-between font-xs text-nowrap'>
            <p className='mb-0 mr-3 text-dark-35'>
              {`Created at ${moment(item.metadata.createdAt).format("DD.MM.YYYY HH:mm")}`}
            </p>
            <p
              className={classnames("mb-0 text-capitalize", {
                "status text-primary font-xs":
                  item.metadata.status === "running",
                "status text-success font-xs":
                  item.metadata.status === "completed",
                "status text-cancelled font-xs":
                  item.metadata.status === "cancelled",
                "status text-info font-xs": item.metadata.status === "ready",
              })}
            >
              {item.metadata.status}
            </p>
          </div>
        </ResultsDropdownItem>
      ))}
    </div>
  );
};

export default DealsResults;
