import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { getPeriods } from "store/exchanges/actions";

import CandleStickPeriodSelect from "../../compounds/CandleStickPeriodSelect";

const MarketsHeader = ({ exchange, period }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (exchange) dispatch(getPeriods(exchange));
  }, [exchange, dispatch]);

  return (
    <MarketsHeader.Header className='d-flex flex-column flex-sm-row align-items-sm-center'>
      <div className='mb-3 mb-sm-0'>
        <p className='mb-0 font-sm text-white-35'>Step 2 of 3</p>
        <h3 className='mb-0 text-white font-weight-500'>Bot settings</h3>
      </div>
      <CandleStickPeriodSelect period={period} />
    </MarketsHeader.Header>
  );
};

MarketsHeader.Header = styled.div`
  @media (max-width: 576px) {
    .period {
      width: 100% !important;
    }
  }

  .period {
    width: 180px;
  }

  justify-content: space-between;
  background: #2e3148;
  padding: 24px 32px;
`;

export default React.memo(MarketsHeader);
