import React, { useMemo } from "react";

import BotsWidgetEmptyState from "./ui/BotsWidgetEmptyState";
import BotsWidgetHeader from "./ui/BotsWidgetHeader";
import BotsTableRow from "./ui/botsTableRow";
import { EXCHANGE_BOTS_HEADER } from "models/bot/tables";
import { CustomScrollbars, SortTableHead, TablePreloader } from "shared/ui";
import { StyledTable } from "shared/styled/StyledTable";
import { ExchangeWidgetStyledWrap } from "../index.styles";

const BotsWidget = ({
  id,
  quote,
  base,
  data,
  fetchCredentialsBots,
  loading,
}) => {
  const botRedirectMap = useMemo(() => {
    const map = new Map();

    data?.deals?.forEach(({ bot, id }) => {
      map.set(bot?.id, id);
    });
    return map;
  }, [data?.deals]);

  if (!id) return null;

  return (
    <ExchangeWidgetStyledWrap className='p-0 mb-0'>
      <BotsWidgetHeader
        loading={loading}
        onHandleRefresh={fetchCredentialsBots}
      />
      {loading && (
        <div className='overflow-hidden'>
          <TablePreloader
            thHeight={12}
            thWidth={[44, 70, 68, 70]}
            tdWidth={[65, 90, 90, 100]}
            tdHeight={14}
            rowTimes={5}
            withCircle={20}
          />
        </div>
      )}
      {!loading && !data?.bots?.length ? (
        <BotsWidgetEmptyState
          title={"You have no bots at the moment"}
          description={
            "Please create some to see more detailed info by spot market"
          }
        />
      ) : null}
      {data?.bots?.length && !loading ? (
        <CustomScrollbars
          autoHideTimeout={500}
          autoHideDuration={500}
          autoHeight
          autoHeightMax={Number.MAX_VALUE}
        >
          <StyledTable>
            <SortTableHead theadItems={EXCHANGE_BOTS_HEADER} />
            <tbody className='font-sm'>
              {data?.bots?.map(item => {
                return (
                  <BotsTableRow
                    key={item.id}
                    item={item}
                    quote={quote}
                    base={base}
                    fetchCredentialsBots={fetchCredentialsBots}
                    redirectTo={botRedirectMap.get(item.id)}
                  />
                );
              })}
            </tbody>
          </StyledTable>
        </CustomScrollbars>
      ) : null}
    </ExchangeWidgetStyledWrap>
  );
};

export default BotsWidget;
