import React from "react";
import { ErrorBoundary } from "@sentry/react";

import FallBack from "shared/ui/FallBack";
import {
  MarketStatistics,
  TransactionChartStatistics,
  TransactionWidget,
  TransactionWidgetPreloader,
} from "./components";
import { ReactComponent as Trade } from "assets/icons/general/trade.svg";
import { ReactComponent as Orders } from "assets/icons/general/orders.svg";

const StatisticsSection = ({ data, loadingPage, id }) => {
  return (
    <>
      <ErrorBoundary fallback={<FallBack />}>
        {loadingPage ? (
          <TransactionWidgetPreloader />
        ) : (
          <div className='d-flex flex-nowrap justify-content-between'>
            <TransactionWidget
              total={data?.tradesCountTotal}
              daily={data?.tradesCountDaily}
              transactionType='trades'
            />
            <TransactionWidget
              total={data?.totalOrdersCount}
              daily={data?.openOrdersCount}
              transactionType='orders'
            />
          </div>
        )}
      </ErrorBoundary>
      <ErrorBoundary fallback={<FallBack />}>
        <TransactionChartStatistics
          dataChart={data.tradesChart}
          title='Trade History'
          linkTo={`/credentials/${id}/trade-history`}
          icon={<Trade />}
        />
      </ErrorBoundary>
      <ErrorBoundary fallback={<FallBack />}>
        <TransactionChartStatistics
          dataChart={data.openOrdersChart}
          title='Orders'
          linkTo={`/credentials/${id}/orders`}
          icon={<Orders />}
        />
      </ErrorBoundary>
      <ErrorBoundary fallback={<FallBack />}>
        <MarketStatistics id={id} loadingPage={loadingPage} />
      </ErrorBoundary>
    </>
  );
};

export default StatisticsSection;
