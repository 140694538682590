import { GET_SEARCH_RESULTS, GET_SEARCH_RESULTS_LOADING } from "./constants";

export default function search(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SEARCH_RESULTS_LOADING:
      return {
        loading: true,
      };

    case GET_SEARCH_RESULTS:
      return {
        loading: false,
        ...payload,
      };

    default:
      return state;
  }
}
