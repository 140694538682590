import React from "react";
import { Card } from "reactstrap";

import Filters from "../Filters";
import { EmptyState } from "shared/ui";
import ReferralSystemTable from "./ui/ReferralSystemTable";

const TableRegisteredUsers = ({ referral }) => {
  return (
    <Card className='p-0'>
      <h4 className='font-weight-500 text-white p-4 mb-0'>Registered users</h4>
      <Filters referrals={referral} />
      {referral?.items?.length ? (
        <ReferralSystemTable referrals={referral} />
      ) : (
        <EmptyState
          title='You have no refferals yet'
          desc='Here will be dispayed a table for each of your referrals who
            have registered using your referral link'
        />
      )}
    </Card>
  );
};

export default TableRegisteredUsers;
