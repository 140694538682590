export const SELECT_ALL_MARKETS = "SELECT_ALL_MARKETS";
export const MINIMUM_MARKET_TRADES = "MINIMUM_MARKET_TRADES";
export const MINIMUM_MARKET_VOLUME = "MINIMUM_MARKET_VOLUME";
export const BID_ASK_SPREAD_PERCENT = "BID_ASK_SPREAD_PERCENT";
export const TREND = "TREND";
export const PERIOD = "PERIOD";
export const MIN_MAX_CHANGE = "MIN_MAX_CHANGE";
export const PRICE_DIFFERENCE = "PRICE_DIFFERENCE";
export const EXCLUDED_MARKETS = "EXCLUDED_MARKETS";
export const SELECTED_MARKETS = "SELECTED_MARKETS";
export const SET_INVESTMENT = "SET_INVESTMENT";
export const SET_BOT_NAME = "SET_BOT_NAME";
export const RESET_BOT_WIZARD = "RESET_BOT_WIZARD";
export const SET_CANDLE_CONDITIONS = "SET_CANDLE_CONDITIONS";
export const SET_EDIT_CANDLE = "SET_EDIT_CANDLE";
export const EDIT_CANDLE_SETTING = "EDIT_CANDLE_SETTING";
export const REMOVE_CREATED_CANDLE = "REMOVE_CREATED_CANDLE";
