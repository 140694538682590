import React from "react";
import styled from "styled-components";

import { ReactComponent as Info } from "assets/icons/general/infoIcon.svg";

const InfoNotification = ({ text, className = "" }) => {
  return (
    <InfoNotification.Notification
      className={`px-4 py-3 d-flex align-items-center ${className}`}
    >
      <span className='d-inline-block'>
        <Info />
      </span>
      <p className='font-sm text-white mb-0 ml-3'>{text}</p>
    </InfoNotification.Notification>
  );
};

InfoNotification.Notification = styled.div`
  background-color: rgba(47, 128, 237, 0.1);
  border-radius: 8px;

  & svg {
    width: 20px;
    height: 20px;

    & path {
      stroke: var(--info);
      stroke-opacity: 1;
    }
  }
`;

export default InfoNotification;
