import React from "react";
import { Card, CardBody } from "reactstrap";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const DealHeaderPreloader = () => {
  return (
    <Card>
      <CardBody style={{ padding: "24px" }}>
        <div className='d-flex justify-content-between'>
          <div className='d-flex'>
            <div
              className='d-flex align-items-center'
              style={{ position: "relative" }}
            >
              <SkeletonPreloader circle={true} height={37} width={37} />
              <SkeletonPreloader
                circle={true}
                height={37}
                width={37}
                style={{ position: "absolute", left: "80%" }}
              />
            </div>
            <div className='d-flex flex-column ml-5'>
              <SkeletonPreloader height={21} width={98} />
              <SkeletonPreloader height={9} width={188} className='mt-2' />
            </div>
          </div>
          <div className='d-none d-md-flex align-items-end flex-column'>
            <SkeletonPreloader height={23} width={72} />
            <SkeletonPreloader height={12} width={111} className='mt-2' />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DealHeaderPreloader;
