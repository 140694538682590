import styled from "styled-components";
import GoogleLogin from "react-google-login";

const StyledGoogleLogin = styled(GoogleLogin)`
  background-color: rgba(255, 255, 255, 0.03) !important;
  font-size: 1rem !important;
  box-shadow: none !important;

  & > div {
    background-color: transparent !important;
    margin-right: 0 !important;
  }
`;

export default StyledGoogleLogin;
