import React from "react";
import { CardHeader } from "reactstrap";

import { ReactComponent as GeneticBot } from "assets/icons/bot/genetic-bot.svg";
import { ReactComponent as Trade } from "assets/icons/trade-linear-icon.svg";

const Header = ({ isGenetic }) => {
  return (
    <CardHeader className='p-4 d-flex'>
      {isGenetic ? <GeneticBot /> : <Trade />}
      <p className='ml-2 my-auto text-white font-weight-500'>Trade settings</p>
    </CardHeader>
  );
};

export default Header;
