import React from "react";
import styled from "styled-components";
import { Card } from "reactstrap";

import { ReactComponent as DealsCount } from "assets/icons/dashboard/ion_rocket-outline.svg";

const ActiveDeals = ({ dealsCount = {}, content }) => {
  return (
    <ActiveDeals.Card>
      <ActiveDeals.FixedCardBody className='d-flex flex-column justify-content-center p-4'>
        <div className='d-flex align-items-center'>
          <DealsCount />
          <div className='d-flex flex-column ml-3'>
            <ActiveDeals.Title className='font-sm text-nowrap'>
              {content.title}
            </ActiveDeals.Title>
            <p className='h3 m-0 font-weight-500 text-white'>
              {dealsCount.running}
            </p>
          </div>
        </div>
      </ActiveDeals.FixedCardBody>
    </ActiveDeals.Card>
  );
};

ActiveDeals.Card = styled(Card)`
  border-radius: 8px !important;
  margin-bottom: 24px !important;
  padding: 0 !important;
`;

ActiveDeals.FixedCardBody = styled.div`
  height: 86px;
`;

ActiveDeals.Title = styled.p`
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.75) !important;
  font-weight: 300;
`;

export default ActiveDeals;
