import React, { useState } from "react";
import styled from "styled-components";
import { Card, CardBody, CardHeader } from "reactstrap";
import { ReactComponent as DropdownIndicatorIcon } from "assets/icons/dashboard/dropdownIndicator.svg";

const ToggleCard = ({ children, header }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Card>
      <CardHeader
        onClick={() => setExpanded(!expanded)}
        className={`cursor-pointer ${expanded ? "px-4 pt-4 pb-3" : "p-4"}`}
      >
        <ToggleCard.Header expanded={expanded}>
          {header}
          <DropdownIndicatorIcon className='chevron' />
        </ToggleCard.Header>
      </CardHeader>
      {expanded ? (
        <CardBody className='pt-0 px-4 pb-4'>{children}</CardBody>
      ) : null}
    </Card>
  );
};

ToggleCard.Header = styled.div`
  display: flex;
  justify-content: space-between;
  .chevron {
    width: 24px;
    height: 24px;
    transition: transform 0.2s ease-in-out;
    transform: ${props => (props.expanded ? "rotate(-180deg)" : null)};
  }
`;

export default ToggleCard;
