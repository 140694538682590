import React from "react";
import _ from "lodash";

import SkeletonPreloader from "../SkeletonPreloader";
import { StyledTable } from "shared/styled/StyledTable";

const TablePreloader = ({
  thWidth,
  thHeight,
  rowTimes,
  tdWidth,
  tdHeight,
  withCircle,
  isAlternateCircle,
}) => {
  return (
    <StyledTable className='mb-0'>
      {thWidth && (
        <thead className='px-4'>
          <tr>
            {_.times(thWidth.length, e => (
              <th key={e} className={!e ? "pl-4" : ""}>
                <SkeletonPreloader width={thWidth[e]} height={thHeight} />
              </th>
            ))}
          </tr>
        </thead>
      )}

      <tbody className='px-4'>
        {_.times(rowTimes, rowIndex => (
          <tr key={rowIndex} style={{ borderTop: "1px solid #2E3148" }}>
            {_.times(tdWidth.length, colIndex => (
              <td key={colIndex}>
                <div className='d-flex align-items-center'>
                  {withCircle &&
                  (isAlternateCircle
                    ? rowIndex % 2 === 0 && !colIndex
                    : !colIndex) ? (
                    <SkeletonPreloader
                      width={withCircle}
                      height={withCircle}
                      circle
                      className='mr-2'
                    />
                  ) : null}
                  <SkeletonPreloader
                    width={tdWidth[colIndex]}
                    height={tdHeight}
                  />
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default TablePreloader;
