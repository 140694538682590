import React, { useState } from "react";
import styled from "styled-components";
import { CardBody, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as Alert } from "assets/icons/general/alert.svg";
import EditCredentialModal from "../modals/EditCredentialModal";
import { fetchCredential } from "store/credentials/actions";
import { ReactComponent as ExclamationPoint } from "assets/icons/general/error.svg";
import { PortfolioTableHead } from "../compounds/Credential/components/PortfolioTableHead";
import PortfolioTableRow from "../compounds/Credential/components/PortfolioTableRow";

const EmptyPortfolioTable = ({
  connected,
  id,
  portfolio,
  exchangeIndex,
  subscription,
  autoRebalancing,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const data = useSelector(state => state.credentials.item.data);

  const dispatch = useDispatch();
  const handleConnect = e => {
    e.stopPropagation();
    dispatch(fetchCredential(id));
    if (data) setModalIsOpen(true);
  };
  const shortPortfolio = portfolio.slice(0, 5);

  return (
    <div>
      {connected === false ? (
        <>
          <EmptyPortfolioTable.WrapperForModal id='container'>
            <CardBody
              className='p-4'
              style={{
                background: "var(--dark-slate)",
                borderRadius: "0 0 8px 8px",
                cursor: "default",
              }}
            >
              <Table className='mb-0'>
                <PortfolioTableHead
                  exchangeIndex={exchangeIndex}
                  subscription={subscription}
                  bots={false}
                  isHover={false}
                />
                <tbody className='font-sm'>
                  {shortPortfolio.map((investment, index) => (
                    <PortfolioTableRow
                      key={investment.id}
                      investment={investment}
                      index={index}
                      exchangeIndex={exchangeIndex}
                      subscription={subscription}
                      autoRebalancing={autoRebalancing}
                      bots={false}
                      isHover={false}
                    />
                  ))}
                </tbody>
              </Table>
            </CardBody>

            <EmptyPortfolioTable.CustomModalShadow className='d-flex justify-content-center align-items-center'>
              <EmptyPortfolioTable.CustomModalContent className='d-flex flex-column justify-content-center align-items-center p-3 m-3'>
                <ExclamationPoint width='56px' heigth='56px' />
                <EmptyPortfolioTable.PopapMessage>
                  Your api or secret key is no longer supported, please change
                  it to valid.
                </EmptyPortfolioTable.PopapMessage>
                <EmptyPortfolioTable.Btn
                  onClick={e => handleConnect(e)}
                  className='text-center my-3 my-md-0'
                >
                  Change API keys
                </EmptyPortfolioTable.Btn>
              </EmptyPortfolioTable.CustomModalContent>
            </EmptyPortfolioTable.CustomModalShadow>
          </EmptyPortfolioTable.WrapperForModal>

          <EditCredentialModal
            data={data}
            modalIsOpen={modalIsOpen}
            onSetModalIsOpen={setModalIsOpen}
          />
        </>
      ) : (
        <EmptyPortfolioTable.EmptyBalance>
          <span className='d-inline-block'>
            <Alert />
          </span>
          <p className='mb-0 text-white ml-1 font-sm'>
            Portfolio of these credentials is empty. Please deposit some coins
            to your portfolio in order to see them displayed here
          </p>
        </EmptyPortfolioTable.EmptyBalance>
      )}
    </div>
  );
};

EmptyPortfolioTable.EmptyBalance = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;

  p {
    line-height: initial;
  }

  path,
  rect {
    stroke: rgba(255, 255, 255, 0.35);
  }
`;

EmptyPortfolioTable.EmptyApi = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;

  path,
  rect {
    stroke: #ff8d72;
  }
`;

EmptyPortfolioTable.Btn = styled.div`
  color: #ffffff;
  background: var(--info);
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 0.875rem;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

EmptyPortfolioTable.WrapperForModal = styled.div`
  position: relative !important;
  min-height: 250px;
`;

EmptyPortfolioTable.CustomModalShadow = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(39, 41, 60, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
`;

EmptyPortfolioTable.CustomModalContent = styled.div`
  width: fit-content;
  background-color: #2e3148;
  border-radius: 8px;
`;

EmptyPortfolioTable.PopapMessage = styled.span`
  color: #fff;
  text-align: center;
  font-feature-settings: "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 16px 0 32px 0;
  width: 296px;
`;

export default EmptyPortfolioTable;
