import styled from "styled-components";

import { Table } from "reactstrap";

const StyledTable = styled(Table)`
  & thead tr th {
    font-size: 0.875rem !important;
    line-height: 16.41px;
    font-weight: 300 !important;
    color: #ffffff !important;
    opacity: 1 !important;
  }

  & thead tr {
    border-bottom: 1px solid #2e3148;
  }

  & thead tr {
    border-bottom: 1px solid #2e3148;
  }

  & tbody tr {
    background: var(--dark-slate);

    &.hover-bg {
      background: #363a54;
    }

    &:hover {
      background: ${props => (props.isPreloading ? "#2E3148" : "#363A54")};
    }

    &:not(:last-child) td {
      border-bottom: 1px solid #2e3148;
      border-bottom: 1px solid #2e3148;
    }
  }

  & tbody tr td {
    padding: 0 !important;
    &:first-child {
      padding: 0 1.5rem 0 !important;
    }
    &:last-child {
      padding: 0 1.5rem 0 !important;
    }
  }

  & thead tr th {
    padding: 0 !important;
    &:first-child {
      padding: 0 1.5rem 0 !important;
    }
    &:last-child {
      padding: 0 1.5rem 0 !important;
    }
  }
`;

export default StyledTable;
