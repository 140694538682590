import React from "react";
import moment from "moment/moment";

import { formatterToNull } from "helpers/valuesFormatters";
import { StyledAmount, StyledCurrency } from "../index.styles";
import { BadgeBuySell } from "shared/ui/badges";

const MarketOrderRow = ({ item }) => {
  return (
    <tr>
      <td>
        <div className='d-flex'>
          <BadgeBuySell type={item?.type} />
        </div>
      </td>

      <td>
        <StyledCurrency>{item.orderNumber}</StyledCurrency>
      </td>

      <td>
        <StyledAmount amount={item?.amount?.amount} className='d-inline'>
          {item?.amount?.amount}
        </StyledAmount>
        <StyledCurrency className='d-inline'>
          {item?.amount?.currency}
        </StyledCurrency>
      </td>

      <td>
        <StyledAmount amount={item.rate}>{item.rate}</StyledAmount>
      </td>

      <td>
        <div className='d-flex align-items-center'>
          <StyledAmount amount={item.total.amount}>
            {item.total.amount}
          </StyledAmount>
          <StyledCurrency>{item.total.currency}</StyledCurrency>
        </div>
      </td>

      <td>
        <div className='d-flex align-items-center'>
          <StyledAmount amount={item.fee.amount}>
            {formatterToNull(item.fee.amount)}
          </StyledAmount>
          <StyledCurrency>{item.fee.currency}</StyledCurrency>
        </div>
      </td>

      <td>
        <p className='mb-0 text-white font-weight-300 text-nowrap'>
          {moment(item.createdAt).format("DD.MM.YY HH:mm")}
        </p>
      </td>
    </tr>
  );
};

export default MarketOrderRow;
