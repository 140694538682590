import React from "react";
import { ErrorBoundary } from "@sentry/react";

import OrdersHistory from "./OrdersHistory";
import TradesHistory from "./TradesHistory";
import FallBack from "shared/ui/FallBack";
import Conditions from "../Overview/Conditions";

const HistoryTab = ({ id, credId, market, loadingPage }) => {
  return (
    <>
      <ErrorBoundary fallback={<FallBack title='Conditions' />}>
        <Conditions id={id} />
      </ErrorBoundary>
      <OrdersHistory
        id={id}
        credId={credId}
        market={market}
        loadingPage={loadingPage}
      />

      <TradesHistory
        id={id}
        credId={credId}
        market={market}
        loadingPage={loadingPage}
      />
    </>
  );
};

export default HistoryTab;
