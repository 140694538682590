import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import AuthLayout from "views/layouts/AuthLayout";
import FormDescription from "./compounds/FormDescription";
import supermen from "assets/icons/general/supermen.svg";
import { Button } from "shared/ui/buttons";

const ConfirmEmail = () => {
  const history = useHistory();

  return (
    <AuthLayout>
      <Wrapper>
        <div className='content content h-100 d-flex flex-column justify-content-center'>
          <FormDescription title='Congratulations! Your email address was successfully verified.' />
          <img src={supermen} alt='supermen' />
          <Button
            className='w-100 mt-3'
            type='submit'
            onClick={() => history.push("/")}
            color='blue'
          >
            Go to the application
          </Button>
        </div>
      </Wrapper>
    </AuthLayout>
  );
};

const Wrapper = styled.div`
  margin: 2.5rem 0;

  @media (min-width: 1200px) {
    margin: 9rem 0;
  }
`;

export default ConfirmEmail;
