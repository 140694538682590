import React from "react";
import { CardHeader } from "reactstrap";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ReactComponent as SettingsIcon } from "assets/icons/general/setting-4-white.svg";
import { ReactComponent as ClipboardText } from "assets/icons/general/clipboardtext.svg";
import Button from "shared/ui/buttons/Button";
import Label from "shared/ui/Label";
import { ReactComponent as UserAccountWhite } from "assets/icons/user-account-white.svg";

const ChannelDefaultWidgetHeader = ({
  onEditButtonClick,
  title,
  className,
  isEdit,
  isWidget,
  total,
  outline,
  color,
  owner,
}) => {
  return (
    <CardHeader className='p-0'>
      <StyledContainer
        className={`d-flex flex-row justify-content-between align-items-center ${className}`}
      >
        <div className='d-flex flex-row align-items-center '>
          {isWidget ? <SettingsIcon /> : <ClipboardText />}
          <p className='ml-2 mb-0 text-white font-weight-500'>{title}</p>
          {owner ? (
            <Label
              size='small'
              color='danger'
              fontSize='font-xs'
              className='ml-2 ml-md-4 my-auto'
            >
              <UserAccountWhite width='12px' height='12px' />
              Admin
            </Label>
          ) : null}
        </div>
        {isEdit ? (
          <Button
            className='d-block text-white-75 py-2 px-3'
            onClick={onEditButtonClick}
            outline={outline}
            color={color}
          >
            Edit
          </Button>
        ) : null}
        {total ? (
          <p className='pl-2 mb-0 text-white font-weight-500'>{total}</p>
        ) : null}
      </StyledContainer>
    </CardHeader>
  );
};

const StyledContainer = styled.div`
  border-radius: 5px 5px 0 0;
`;

ChannelDefaultWidgetHeader.propTypes = {
  onEditButtonClick: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
  isEdit: PropTypes.bool,
  isWidget: PropTypes.bool,
  total: PropTypes.number,
  outline: PropTypes.bool,
  owner: PropTypes.bool,
  color: PropTypes.string,
};

export default ChannelDefaultWidgetHeader;
