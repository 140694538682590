import styled from "styled-components";

const StyledButton = styled.button`
  background-image: none !important;
  color: #ffffff !important;
  width: 100%;
  padding: 1rem 0 !important;
  font-size: 1rem !important;
  line-height: 18.75px !important;

  &.btn.btn-info:hover,
  &.btn.btn-info:hover + &.btn.btn-info:focus,
  &.btn.btn-info:active {
    background: #19519b !important;
  }

  &.btn-simple.btn-info:hover {
    background: #2f80ed !important;
    color: #ffffff;
  }

  &.btn-info:focus {
    background: #2f80ed !important;
  }

  &.btn-simple.btn-info:disabled {
    opacity: 1;
    border: none !important;
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.1);
  }
`;

export default StyledButton;
