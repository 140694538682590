import axios from "axios";
import { API_URL } from "env-create-react-app";
import jwtDecode from "jwt-decode";

import browserHistory from "./browserHistory";

/* eslint no-use-before-define: 0 */
export const login = async (token, refreshToken) => {
  localStorage.setItem("token", token);
  localStorage.setItem("refreshToken", refreshToken);
  await scheduleRenewal();
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("skipped");
  browserHistory.push("/login");
};

const scheduleRenewal = async () => {
  const token = localStorage.getItem("token");
  const decoded = jwtDecode(token);
  const currentTime = Date.now();
  const expiresIn = decoded.exp * 1000;
  const remain = new Date(expiresIn - currentTime).getTime();
  setTimeout(() => renewalToken(), remain - 60000);
};

export const renewalToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");

  try {
    const res = await axios.post(`${API_URL}/auth/token/refresh`, {
      refreshToken,
    });
    localStorage.setItem("token", res.data.token);
    localStorage.setItem("refreshToken", res.data.refreshToken);
    await scheduleRenewal();
    return res.data.token;
  } catch {
    logout();
  }
};

export const authenticate = async token => {
  const decoded = jwtDecode(token);
  const expired = Date.now() >= decoded.exp * 1000;

  if (expired) {
    await renewalToken();
  } else {
    await scheduleRenewal();
  }
};
