import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import GeneticHeader from "./compouds/GeneticHeader";
import GenerationWidget from "./compouds/GenerationWidget";
import IndividualsWidget from "./compouds/IndividualsWidget";
import {
  getGeneticAlgorithm,
  getGeneticIndividuals,
  resetGeneticAlgorithm,
  resetGeneticIndividuals,
} from "store/bots/actions";
import GeneticSettingsWidget from "./compouds/GeneticSettingsWidget";
import EditGAModal from "./modals/EditGAModal";
import ParametersWidget from "./compouds/ParametersWidget";
import { setGAPageBreadcrumbs } from "models/enum/breadcrumbs";
import useRequestCancellation from "hooks/useRequestCancellation";
import {
  BestTradeSettings,
  Breadcrumbs,
  Error,
  RefreshButton,
} from "shared/ui";

const GeneticPage = ({ match }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const dispatch = useDispatch();
  const { useAbortableEffect } = useRequestCancellation();

  const data = useSelector(state => state.bots.geneticAlgorithm.data);
  const individuals = useSelector(state => state.bots.geneticIndividuals.data);
  const error = useSelector(state => state.bots.geneticAlgorithm.error);
  const loading = useSelector(state => state.bots.geneticAlgorithm.loading);

  const handleOpenSettings = e => {
    e.stopPropagation();
    setIsSettingsOpen(true);
  };

  const handleRefresh = () => {
    dispatch(getGeneticAlgorithm(match.params.id));
    dispatch(getGeneticIndividuals(match.params.id));
  };

  useAbortableEffect(
    signal => {
      dispatch(getGeneticAlgorithm(match.params.id, signal));
      return () => {
        dispatch(resetGeneticAlgorithm());
        dispatch(resetGeneticIndividuals());
      };
    },
    [dispatch, match.params.id],
    true,
  );

  if (error) return <Error error={error} />;

  return (
    <>
      <Row>
        <Col xs='12' md='12'>
          <div className='d-none d-md-flex flex-row align-items-center justify-content-between mb-2'>
            <Breadcrumbs
              items={setGAPageBreadcrumbs(
                data?.exchange,
                data?.market?.quote,
                data?.bot?.investment?.id,
                data?.market?.base,
                data?.market?.pair,
                "Genetic Algorithm",
              )}
              loading={loading}
            />

            <RefreshButton loading={loading} onHandleRefresh={handleRefresh} />
          </div>
          <GeneticHeader
            data={data}
            id={match.params.id}
            error={error}
            loading={loading}
          />
        </Col>
      </Row>
      <Row>
        <Col xs='12' md='4' className='no-gutters'>
          <ParametersWidget data={data} loading={loading} />
          <GeneticSettingsWidget
            defaultHeader
            data={data}
            loading={loading}
            onEditButtonClick={handleOpenSettings}
          />
        </Col>
        <Col xs='12' md='8'>
          <BestTradeSettings
            data={data?.geneticAlgorithm?.bestGenome}
            loading={loading}
            title='Best Trade settings Genome'
          />
          <GenerationWidget
            data={data?.geneticAlgorithm?.population}
            loading={loading}
            lifetime={data?.geneticAlgorithm?.population?.lifetime}
          />
          <IndividualsWidget
            loading={loading}
            geneticId={match.params.id}
            individuals={individuals}
            individualsStatistic={individuals?.metadata?.statistics}
          />
        </Col>
      </Row>
      <EditGAModal
        setIsModalOpen={setIsSettingsOpen}
        bot={data?.bot}
        isModalOpen={isSettingsOpen}
        geneticId={match.params.id}
        criterion={data?.bot?.criterion}
      />
    </>
  );
};

export default GeneticPage;
