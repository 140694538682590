import React from "react";
import { Card } from "reactstrap";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const StatisticWidgetPreloader = () => {
  return (
    <Card className='p-4'>
      <div className='d-flex align-items-center mb-3'>
        <SkeletonPreloader circle={true} height={16} width={16} />
        <SkeletonPreloader width={80} height={13} className='ml-2' />
      </div>

      <div className='d-flex justify-content-between mb-3'>
        <div className='d-flex flex-column'>
          <SkeletonPreloader
            count={3}
            height={13}
            width={80}
            className='mt-2 mb-2'
          />
        </div>
        <div className='d-flex flex-column'>
          <SkeletonPreloader
            count={3}
            height={11}
            width={120}
            className='mt-2 mb-2'
          />
        </div>
      </div>
      <div className='d-flex justify-content-between mb-3'>
        <div className='d-flex flex-column'>
          <SkeletonPreloader
            count={3}
            height={11}
            width={81}
            className='mt-2 mb-2'
          />
        </div>
        <div className='d-flex flex-column'>
          <SkeletonPreloader
            count={3}
            height={11}
            width={80}
            className='mt-2 mb-2'
          />
        </div>
      </div>
      <div
        className='d-flex mt-3'
        style={{
          background:
            "linear-gradient(90deg, rgba(83, 84, 98, 0.3) 0%, rgba(83, 84, 98, 0) 100%)",
          borderRadius: "8px",
          padding: "9px 24px",
        }}
      >
        <SkeletonPreloader circle={true} height={24} width={24} />
        <div className='d-flex flex-column ml-3'>
          <SkeletonPreloader height={8} width={41} />
          <SkeletonPreloader height={12} width={121} className='mt-2' />
        </div>
      </div>
    </Card>
  );
};

export default StatisticWidgetPreloader;
