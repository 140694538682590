import axios from "axios";
import { API_URL } from "env-create-react-app";

import { handlePoloniexMessage } from "./poloniex";
import { handleBinanceMessage } from "./binance";
import { handleHitBtcMessage } from "./hitbtc";

const fetchResource = path => {
  const token = localStorage.getItem("token");
  return axios({
    url: `${API_URL}${path}`,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  }).then(res => res.data);
};

const getTrades = (exchange, symbols = []) => {
  const market = symbols[0]?.replace("_", "-");
  return fetchResource(`/app/exchanges/${exchange}/markets/${market}/trades`);
};

const exchangesChannels = {
  poloniex: {
    orderBook: "book_lv2",
  },
  binance: {
    orderBook: "depth",
    trades: "trade",
  },
  hitbtc: {
    ticker: "ticker/1s",
    orderBook: "orderbook/full",
  },
  bitfinex: {},
};

const convertChannels = (channels, exchange) =>
  channels.map(channel => exchangesChannels[exchange][channel] || channel);

const exchangesConfig = {
  poloniex: {
    url: "wss://ws.poloniex.com/ws/public",
    subscribe: (channels, symbols, ws) => {
      const channel = convertChannels(channels, "poloniex");
      const message = JSON.stringify({
        event: "subscribe",
        channel,
        symbols,
      });
      ws?.send(message);
    },
    unsubscribe: (channels, symbols, ws) => {
      const channel = convertChannels(channels, "poloniex");
      const message = JSON.stringify({
        event: "unsubscribe",
        channel,
        symbols,
      });
      ws?.send(message);
    },
    pingInterval: 2500,
    pingMessage: JSON.stringify({ event: "ping" }),
    handleWSMessage: handlePoloniexMessage,
    getTickers: () => fetchResource("/app/exchanges/poloniex/tickers"),
    getTrades: symbols => getTrades("poloniex", symbols),
    checkSymbol: (base, quote) =>
      fetchResource(
        `/app/exchanges/poloniex/markets/${base}-${quote}/information`,
      ),
    docsLink:
      "https://docs.darkbot.io/getting-started-with-darkbot/how-to-create-and-setup-poloniex-api-key",
  },

  binance: {
    url: "wss://stream.binance.com:9443/ws",
    subscribe: (channels, symbols, ws) => {
      const message = JSON.stringify({
        method: "SUBSCRIBE",
        params: convertChannels(channels, "binance").reduce((acc, channel) => {
          symbols.forEach(symbol => {
            acc.push(
              `${symbol.toLowerCase().replace("_", "")}@${channel.toLowerCase()}`,
            );
          });
          return acc;
        }, []),
        id: new Date().getTime(),
      });
      ws?.send(message);
    },
    unsubscribe: (channels, symbols, ws) => {
      const message = JSON.stringify({
        method: "UNSUBSCRIBE",
        params: convertChannels(channels, "binance").reduce((acc, channel) => {
          symbols.forEach(symbol => {
            acc.push(`${symbol.toLowerCase()}@${channel.toLowerCase()}`);
          });
          return acc;
        }, []),
        id: new Date().getTime(),
      });
      ws?.send(message);
    },
    pingInterval: 30000,
    pingMessage: "",
    handleWSMessage: handleBinanceMessage,
    getTickers: () => fetchResource("/app/exchanges/binance/tickers"),
    getTrades: symbols => getTrades("binance", symbols),
    getOrderBookSnapshot: (symbols, limit = 15) => {
      const symbol = symbols[0]?.replace("_", "");
      return axios
        .get(
          `https://api.binance.com/api/v3/depth?symbol=${symbol}&limit=${limit}`,
        )
        .then(res => res.data);
    },
    checkSymbol: (base, quote) =>
      fetchResource(
        `/app/exchanges/binance/markets/${base}-${quote}/information`,
      ),
    ips: ["54.89.220.170", "34.232.48.137"],
    docsLink:
      "https://docs.darkbot.io/getting-started-with-darkbot/how-to-create-and-setup-binance-api-key",
  },

  binanceus: {
    url: "wss://stream.binance.us:9443/ws",
    subscribe: (channels, symbols, ws) => {
      const message = JSON.stringify({
        method: "SUBSCRIBE",
        params: convertChannels(channels, "binance").reduce((acc, channel) => {
          symbols.forEach(symbol => {
            acc.push(
              `${symbol.toLowerCase().replace("_", "")}@${channel.toLowerCase()}`,
            );
          });
          return acc;
        }, []),
        id: new Date().getTime(),
      });
      ws?.send(message);
    },
    unsubscribe: (channels, symbols, ws) => {
      const message = JSON.stringify({
        method: "UNSUBSCRIBE",
        params: convertChannels(channels, "binance").reduce((acc, channel) => {
          symbols.forEach(symbol => {
            acc.push(`${symbol.toLowerCase()}@${channel.toLowerCase()}`);
          });
          return acc;
        }, []),
        id: new Date().getTime(),
      });
      ws?.send(message);
    },
    pingMessage: "",
    handleWSMessage: handleBinanceMessage,
    getTickers: () => fetchResource("/app/exchanges/binanceus/tickers"),
    getTrades: symbols => getTrades("binanceus", symbols),
    checkSymbol: (base, quote) =>
      fetchResource(
        `/app/exchanges/binanceus/markets/${base}-${quote}/information`,
      ),
  },

  hitbtc: {
    url: "wss://api.hitbtc.com/api/3/ws/public",
    subscribe: (channels = [], symbols, ws) => {
      convertChannels(channels, "hitbtc").forEach(channel => {
        const message = JSON.stringify({
          method: "subscribe",
          ch: channel,
          params: { symbols: symbols.map(s => s.replace("_", "")) },
          id: new Date().getTime(),
        });
        ws?.send(message);
      });
    },
    unsubscribe: (channels, symbols, ws) => {
      convertChannels(channels, "hitbtc").forEach(channel => {
        const message = JSON.stringify({
          method: "unsubscribe",
          ch: channel,
          params: { symbols: symbols.map(s => s.replace("_", "")) },
          id: new Date().getTime(),
        });
        ws?.send(message);
      });
    },
    handleWSMessage: handleHitBtcMessage,
    getTickers: () => fetchResource("/app/exchanges/hitbtc/tickers"),
    getTrades: symbols => getTrades("hitbtc", symbols),
    checkSymbol: (base, quote) =>
      fetchResource(
        `/app/exchanges/hitbtc/markets/${base}-${quote}/information`,
      ),
    ips: ["54.89.220.170", "34.232.48.137"],
    docsLink:
      "https://docs.darkbot.io/getting-started-with-darkbot/how-to-create-and-setup-hitbtc-api-key",
  },

  bitfinex: {
    url: "wss://api-pub.bitfinex.com/ws/2",
    subscribe: () => {},
    unsubscribe: () => {},
    handleWSMessage: () => {},
    getTickers: () => fetchResource("/app/exchanges/bitfinex/tickers"),
    getTrades: symbols => getTrades("bitfinex", symbols),
    checkSymbol: (base, quote) =>
      fetchResource(
        `/app/exchanges/bitfinex/markets/${base}-${quote}/information`,
      ),
  },
};

export default exchangesConfig;
