import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { indicatorsConfiguration } from "views/bots/wizard/Configuration/Indicators/models/indicatorsConfiguration";
import EmptyStates from "./EmptyStates";
import GeneralSettings from "./GeneralSettings";
import Martingale from "./Martingale";
import TradeSettingsPreloader from "./preloaders/TradeSettingsPreloader";
import { geneticStrategies } from "store/bots/selectors";
import BotIndicators from "./BotIndicators";
import { PivotPoints } from "shared/ui";

const indicatorName = indicatorsConfiguration.filter(({ value }) => value);

const TradeSettings = ({ tradeSettings, market, isGenetic }) => {
  const { data, loading } = useSelector(geneticStrategies);
  const location = useLocation();
  const isBotDetailsPage = location.pathname.includes("bot");
  const chooseDataToPass = () => {
    if (isGenetic && isBotDetailsPage)
      return data?.geneticAlgorithm?.bestGenome?.tradeSettings;
    return tradeSettings;
  };

  if (
    (!isGenetic && tradeSettings) ||
    (!isBotDetailsPage && tradeSettings) ||
    (isGenetic && data?.geneticAlgorithm?.bestGenome?.tradeSettings)
  )
    return (
      <>
        <GeneralSettings data={chooseDataToPass()} />
        <Martingale data={chooseDataToPass()} />
        <PivotPoints data={chooseDataToPass()?.pivotPoint} />
        <BotIndicators
          indicators={chooseDataToPass()?.indicators}
          indicatorName={indicatorName}
          minSellSignals={chooseDataToPass()?.minimumSellSignals}
          minBuySignals={chooseDataToPass()?.minimumBuySignals}
        />
      </>
    );

  if (loading) return <TradeSettingsPreloader />;

  return <EmptyStates market={market} />;
};

export default TradeSettings;
