import React from "react";
import empty from "assets/icons/general/emptySearch.svg";

const SearchNotResults = () => {
  return (
    <div className='d-flex align-items-center flex-column'>
      <img src={empty} alt='' />
      <h3 className='mb-2' style={{ color: "#000000" }}>
        No results for your search
      </h3>
      <p className='font-sm' style={{ color: "rgba(29, 30, 42, 0.75)" }}>
        You may want to try adjusting your filters or checking the data you
        enter.
      </p>
    </div>
  );
};

export default SearchNotResults;
