import { useSelector } from "react-redux";
import React from "react";
import styled from "styled-components";

import SnackBarMessage from "./ui/SnackBarMessage";

const SnackBar = () => {
  const snack = useSelector(state => state.snackBar);

  if (!snack.length) return null;

  return (
    <SnackBar.Wrapper>
      {snack.map(notification => {
        return (
          <SnackBarMessage
            key={notification.id}
            type={notification.type}
            message={notification.message}
            id={notification.id}
          />
        );
      })}
    </SnackBar.Wrapper>
  );
};

SnackBar.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  position: fixed;
  bottom: 2.5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 500px;
  z-index: 10000;
`;

export default SnackBar;
