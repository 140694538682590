import React from "react";
import styled from "styled-components";

import { ReactComponent as ClearIndicatorIcon } from "assets/icons/general/clear.svg";
import { components } from "react-select";

const ClearIndicator = props => (
  <components.ClearIndicator {...props} className='p-0'>
    <RemoveButton className='btn btn-reset my-auto d-flex mr-1 align-items-center'>
      <ClearIndicatorIcon />
    </RemoveButton>
  </components.ClearIndicator>
);

const RemoveButton = styled.button`
  &.btn svg path {
    stroke: transparent;
  }

  &:hover {
    transform: none !important;
  }
`;

export default ClearIndicator;
