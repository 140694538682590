import { Card, CardBody } from "reactstrap";
import styled from "styled-components";

import { ReactComponent as Flag } from "assets/icons/general/flag.svg";

export const DealCardStyled = styled(Card)`
  cursor: pointer;
  padding: 0;

  &.card {
    border-radius: ${props => (props.error ? "8px 8px 0 0" : "8px")} !important;
  }

  &.ready {
    border-left: 8px solid var(--info);
  }

  &.running {
    border-left: 8px solid #db53c7;
  }

  &.completed {
    border-left: 8px solid #3fcbb0;
  }

  &.cancelled {
    border-left: 8px solid rgba(255, 255, 255, 0.35);
  }

  .status {
    display: flex;
    align-items: center;
    position: relative;

    svg {
      position: absolute;
      top: -12px !important;
      right: -10% !important;
      background: var(--dark-slate);
      border: 3px solid var(--dark-slate);
      border-radius: 100px;
      box-sizing: initial;
      width: 8px;
      height: 8px;
      circle {
        fill: ${props =>
          props.status === "enabled" ? "#3FCBB0" : "rgba(255, 255, 255, 0.35)"};
        stroke-opacity: 1;
      }
    }
  }
`;

export const DealBodyStyled = styled(CardBody)`
  padding: 1.25rem 1.5rem 1.25rem 2rem !important;
  display: flex;
  align-items: center;

  @media (max-width: 992px) {
    padding: 1.25rem 0.5rem 1.25rem 1rem !important;
  }

  &:hover {
    background: #363a54;

    .status {
      svg {
        border: 3px solid #363a54;
        background: #363a54;
      }
    }
  }
`;

export const DealTitleStyled = styled.p`
  white-space: nowrap;
  margin-bottom: 0;
  font-weight: 300;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.35) !important;
`;

export const DealPercentStyled = styled.p`
  line-height: 100%;
  padding: 0.125rem 0.25rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  font-size: 0.75rem;
`;

export const DealAmountStyled = styled.p`
  color: ${props =>
    props.amount > 0
      ? "rgba(255, 255, 255)"
      : "rgba(255, 255, 255, 0.35)"} !important;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
`;

export const DealCurrencyStyled = styled.p`
  color: rgba(255, 255, 255, 0.35) !important;
  margin-bottom: 0;
  font-weight: 300;
  font-size: 16px;
  white-space: nowrap;
`;
export const DealIconWrapperStyled = styled.div`
  & svg {
    width: 18px;
    height: 18px;
    margin-right: 0.125rem;
  }
`;

export const DealOpenTradeWrapStyled = styled.div`
  padding: 1.25rem 1.5rem 1.25rem 2rem !important;
  background: #2e3148;

  @media (max-width: 992px) {
    padding: 1.25rem 0.5rem 1.25rem 1rem !important;
  }

  &:hover {
    background: #363a54;
  }
`;
export const DealOpenTradeFlag = styled(Flag)`
  path {
    stroke: "#2F80ED";
  }
`;
