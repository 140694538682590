export const ENABLE_BOT = "ENABLE_BOT";
export const ENABLE_BOT_LOADING = "ENABLE_BOT_LOADING";
export const DISABLE_BOT = "DISABLE_BOT";
export const DISABLE_BOT_LOADING = "DISABLE_BOT_LOADING";
export const ATTACH_STRATEGY = "ATTACH_STRATEGY";
export const EDIT_BOT = "EDIT_BOT";
export const EDIT_INDICATOR = "EDIT_INDICATOR";
export const UPDATE_BOT_NAME = "UPDATE_BOT_NAME";
export const TOGGLE_TICKER_ITEM = "TOGGLE_TICKER_ITEM";
export const TOGGLE_TICKER_FILTER = "TOGGLE_TICKER_FILTER";
export const RESET_BOTS = "RESET_BOTS";
export const RESET_BOT = "RESET_BOT";
export const RESET_DAILY_INCOME_CHART = "RESET_DAILY_INCOME_CHART";
export const EDIT_BOT_LOADING = "EDIT_BOT_LOADING";
export const EDIT_BOT_ERROR = "EDIT_BOT_ERROR";
export const REMOVE_CREATED_INDICATOR = "REMOVE_CREATED_INDICATOR";
export const CREATE_BACKTEST = "CREATE_BACKTEST";
export const RESET_GENETIC = "RESET_GENETIC";
export const RESET_GENETIC_ALGORITHM = "RESET_GENETIC_ALGORITHM";
export const RESET_GENETIC_INDIVIDUALS = "RESET_GENETIC_INDIVIDUALS";
export const UPDATE_GENETIC_ALGORITHM = "UPDATE_GENETIC_ALGORITHM";
export const RESET_GENETIC_STRATEGIES = "RESET_GENETIC_STRATEGIES";
export const GET_GENETIC_INDIVIDUALS_SUCCESS =
  "GET_GENETIC_INDIVIDUALS_SUCCESS";
export const GET_GENETIC_INDIVIDUALS_LOADING =
  "GET_GENETIC_INDIVIDUALS_LOADING";
export const GET_GENETIC_INDIVIDUALS_FAILURE =
  "GET_GENETIC_INDIVIDUALS_FAILURE";
export const SET_CURRENT_BOT = "SET_CURRENT_BOT";
export const UPDATE_BOT_IN_LIST = "UPDATE_BOT_IN_LIST";
export const RESET_INDICATORS_SIGNALS = "RESET_INDICATORS_SIGNALS";
