import React, { createContext, useContext, useState } from "react";

const AvatarCacheContext = createContext();

export const AvatarCacheProvider = ({ children }) => {
  const [avatarCache, setAvatarCache] = useState({});

  return (
    <AvatarCacheContext.Provider value={{ avatarCache, setAvatarCache }}>
      {children}
    </AvatarCacheContext.Provider>
  );
};

export const useAvatarCache = () => {
  return useContext(AvatarCacheContext);
};
