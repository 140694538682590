export const COMPLETE_DEAL = "COMPLETE_DEAL";
export const COMPLETE_DEAL_ERROR = "COMPLETE_DEAL_ERROR";
export const COMPLETE_DEAL_LOADING = "COMPLETE_DEAL_LOADING";
export const CANCEL_DEAL_LOADING = "CANCEL_DEAL_LOADING";
export const CANCEL_DEAL = "CANCEL_DEAL";
export const CANCEL_DEAL_ERROR = "CANCEL_DEAL_ERROR";
export const RESTART_DEAL = "RESTART_DEAL";
export const RESTART_DEAL_ERROR = "RESTART_DEAL_ERROR";
export const FORCE_RUN_DEAL = "CANCEL_DEAL";
export const FORCE_RUN_DEAL_LOADING = "CANCEL_DEAL_LOADING";
export const FORCE_RUN_DEAL_ERROR = "FORCE_RUN_DEAL_ERROR";
export const RESET_DEAL_CHART = "RESET_DEAL_CHART";
export const RESET_DEAL = "RESET_DEAL";
export const RESET_DEALS = "RESET_DEALS";
export const RESET_TRADE_REQUESTS = "RESET_TRADE_REQUESTS";
export const TRADES = "TRADES";
export const ORDERS = "ORDERS";
export const RESET_CONDITIONS = "RESET_CONDITIONS";
export const ENABLE_BOT_MARKET = "ENABLE_BOT_MARKET";
export const DISABLE_BOT_MARKET = "DISABLE_BOT_MARKET";
export const CREATE_BOT_DEAL = "CREATE_BOT_DEAL";
export const UPDATE_BOT_CONFIG = "UPDATE_BOT_CONFIG";
