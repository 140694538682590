import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { Col, Row } from "reactstrap";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const ProfileSettingsPreloader = () => {
  return (
    <div>
      <SkeletonPreloader height={20} width={150} />
      <div className='mb-4'>
        <Row>
          <Col xs='6'>
            {_.times(3, e => (
              <div className='mb-2 mt-1' key={e}>
                <ProfileSettingsPreloader.Wrapper>
                  <SkeletonPreloader
                    height={12}
                    width={88}
                    borderRadius='6px'
                  />
                </ProfileSettingsPreloader.Wrapper>
                <SkeletonPreloader height={38} borderRadius='6px' />
              </div>
            ))}
          </Col>
          <Col xs='6'>
            {_.times(3, e => (
              <div className='mb-2 mt-1' key={e}>
                <ProfileSettingsPreloader.Wrapper>
                  <SkeletonPreloader
                    height={12}
                    width={88}
                    borderRadius='6px'
                  />
                </ProfileSettingsPreloader.Wrapper>
                <SkeletonPreloader height={38} borderRadius='6px' />
              </div>
            ))}
          </Col>
        </Row>
        <Row>
          <Col xs='12'>
            {_.times(2, e => (
              <div className='mb-2 mt-1' key={e}>
                <ProfileSettingsPreloader.Wrapper>
                  <SkeletonPreloader
                    height={12}
                    width={88}
                    borderRadius='6px'
                  />
                </ProfileSettingsPreloader.Wrapper>
                <SkeletonPreloader height={38} borderRadius='6px' />
              </div>
            ))}
          </Col>
        </Row>
        <Row>
          <Col xs='12'>
            <SkeletonPreloader borderRadius='6px' height={76} />
          </Col>
        </Row>
      </div>
      <div className='d-flex justify-content-end mt-4 ml-auto'>
        <SkeletonPreloader
          height={38}
          width={170}
          circle={false}
          borderRadius='6px'
          className='ml-auto'
        />
      </div>
    </div>
  );
};

ProfileSettingsPreloader.Wrapper = styled.div`
  margin-bottom: 6px;
`;

export default ProfileSettingsPreloader;
