import React from "react";
import classNames from "classnames";
import styled from "styled-components";

import { ReactComponent as Deals } from "assets/icons/general/Deals.svg";
import { ReactComponent as Trades } from "assets/icons/general/flag.svg";
import { ReactComponent as Credentials } from "assets/icons/general/Credentials.svg";
import { ReactComponent as Auth } from "assets/icons/general/auth.svg";
import { ReactComponent as Trailing } from "assets/icons/general/story.svg";
import { ReactComponent as Backtest } from "assets/icons/general/backtest.svg";
import { ReactComponent as Signals } from "assets/icons/general/signals-sidebar.svg";

const NotificationNav = ({ navItems = [], setActiveType, activeType }) => {
  const ICONS = {
    deals: <Deals width={24} height={24} />,
    tradeRequest: <Trades width={24} height={24} />,
    credentials: <Credentials width={24} height={24} />,
    security: <Auth width={24} height={24} />,
    trailing: <Trailing width={24} height={24} />,
    backtests: <Backtest width={24} height={24} />,
    signal: <Signals width={24} height={24} />,
  };

  return (
    <div className='d-flex flex-column'>
      {navItems.map(({ name, label }) => (
        <NotificationNav.NavItem
          onClick={() => setActiveType(name)}
          key={name}
          className={classNames("p-3 d-flex align-items-center", {
            active: activeType === name,
          })}
        >
          {ICONS[name]}
          <p className='text-capitalize my-auto ml-2'>{label}</p>
        </NotificationNav.NavItem>
      ))}
    </div>
  );
};

NotificationNav.NavItem = styled.div`
  background: inherit;
  cursor: pointer;
  margin: 6px 0;
  p {
    color: rgba(255, 255, 255, 0.35) !important;
  }
  svg {
    path {
      stroke: #777988;
    }
    circle {
      stroke: #777988;
    }
  }
  &:hover {
    background: #2e3148;
    border-radius: 6px;
    margin: 6px 8px 6px 0;
    text-decoration: underline;
  }
  &.active {
    background: #2e3148;
    border-radius: 6px 0 0 6px;
    border-left: 4px solid var(--info);
    margin: 6px 0;
    p {
      color: #ffffff !important;
    }
    svg {
      path {
        stroke: var(--info);
      }

      circle {
        stroke: var(--info);
      }
    }
  }
`;

export default NotificationNav;
