import React from "react";
import styled from "styled-components";
import { Card } from "reactstrap";

import { ReactComponent as ListingError } from "assets/icons/general/emptyDataListing.svg";
import { Button } from "../buttons";

const EmptyDataList = ({
  Img,
  title,
  description,
  buttonText,
  className,
  onHandleClick,
}) => {
  return (
    <EmptyDataList.Card className={className}>
      {Img ? <Img /> : <ListingError />}
      <EmptyDataList.Title>{title}</EmptyDataList.Title>
      <EmptyDataList.Description>{description}</EmptyDataList.Description>
      {buttonText && (
        <Button className='mt-4' color='blue' onClick={onHandleClick}>
          {buttonText}
        </Button>
      )}
    </EmptyDataList.Card>
  );
};

EmptyDataList.Card = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px !important;
  margin: 0 auto;
  padding: 140px 0 125px 0;
  text-align: center;
`;

EmptyDataList.Title = styled.p`
  margin: 40px 0 8px 0;
  color: #ffffff !important;
  font-weight: 500;
  font-size: 18px;
`;

EmptyDataList.Description = styled.p`
  margin: 0;
  color: rgba(255, 255, 255, 0.75) !important;
  font-weight: 400;
  font-size: 14px;
  white-space: pre-line;
`;

export default EmptyDataList;
