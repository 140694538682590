import React from "react";

import { StyledBadgeStyled } from "./index.styles";

const BadgeBacktestStatus = ({ status = "" }) => {
  if (!status) return null;

  return (
    <StyledBadgeStyled
      data-testid={`backtest-status-badge-${status}`}
      status={status}
      pill
    >
      {status}
    </StyledBadgeStyled>
  );
};

export default BadgeBacktestStatus;
