import React from "react";
import WidgetsPreloaders from "./WidgetsPreloaders";
import { Col } from "reactstrap";
import * as Sentry from "@sentry/react";

import FallBack from "shared/ui/FallBack";
import ActiveDeals from "./ActiveDeals";
import Invested from "./Invested";
import Profit from "./Profit";

const MiniWidgets = ({
  me,
  profit,
  invested,
  dealsCount,
  loading,
  content,
}) => {
  if (loading) return <WidgetsPreloaders />;

  return (
    <div style={{ display: "contents" }}>
      <Col xs='12' lg='4' className='pr-lg-1'>
        <Sentry.ErrorBoundary fallback={<FallBack />}>
          <ActiveDeals dealsCount={dealsCount} content={content.activeDeals} />
        </Sentry.ErrorBoundary>
      </Col>
      <Col xs='12' lg='4' className='py-0 px-lg-2'>
        <Sentry.ErrorBoundary fallback={<FallBack />}>
          <Invested invested={invested} me={me} content={content.invested} />
        </Sentry.ErrorBoundary>
      </Col>
      <Col xs='12' lg='4' className='pl-lg-1'>
        <Sentry.ErrorBoundary fallback={<FallBack />}>
          <Profit data={profit} />
        </Sentry.ErrorBoundary>
      </Col>
    </div>
  );
};

export default MiniWidgets;
