import React, { useState } from "react";
import styled from "styled-components";
import { Card, CardBody, Badge, CardHeader, Tooltip } from "reactstrap";
import moment from "moment";

import LineWithError from "shared/ui/LineWithError";
import { replaceDurations } from "helpers/replaceDurations";
import { capitalize } from "lodash";
import { formatterToNull } from "helpers/valuesFormatters";
import { handleStatusData } from "models/constans/ui/tradeRequests/content";

const TradeRequest = ({
  data = {},
  setIsModalOpen,
  setIsModalOpenID,
  isModal,
  index,
  renderMarket,
}) => {
  const [tooltip, setTooltip] = useState(false);
  const toggle = () => setTooltip(!tooltip);

  const statusData = handleStatusData(data);

  const handleOpenModal = () => {
    if (isModal) {
      setIsModalOpenID(data.id);
      setIsModalOpen(true);
    }
  };

  const renderStatus = status => {
    const IconStatus = statusData[status]?.Icon;
    const dateStatus = statusData[status]?.date;
    return (
      statusData[status] && (
        <>
          <p className='mb-0 text-white font-weight-500 mr-3 font-sm'>
            {moment(dateStatus).format("DD.MM.YY HH:mm")}
          </p>
          <TradeRequest.Icon>
            <IconStatus width={20} height={20} id={`status-${index}`} />
          </TradeRequest.Icon>
          <Tooltip
            placement='top'
            isOpen={tooltip}
            target={`status-${index}`}
            toggle={toggle}
          >
            {capitalize(status)}
          </Tooltip>
        </>
      )
    );
  };

  return (
    <TradeRequest.Card className='mb-2 d-flex flex-column'>
      <CardHeader className='d-flex flex-md-row flex-column align-items-center justify-content-start justify-content-md-between py-3 px-4'>
        <div className='d-flex flex-wrap justify-content-center justify-content-md-start align-items-center mb-2 mb-md-0'>
          <Badge
            style={{
              background: data?.type === "buy" ? "#3FCBB0" : "#ED5F5F",
              color: data?.type === "buy" ? "#1D1E2A" : "#FFFFFF",
              fontWeight: "500",
              fontSize: "14px",
              margin: "0 16px 0 0",
              padding: "4px 11px",
              lineHeight: "16px",
              display: "block",
            }}
          >
            {data?.type}
          </Badge>
          {renderMarket && (
            <div className='d-flex mr-3 px-3 px-md-0'>
              <p className='mb-0 text-white font-md font-weight-500'>
                {data?.market?.base}
              </p>
              <TradeRequest.Currency className='mb-0 font-md font-weight-300 ml-1'>{`/ ${data?.market?.quote}`}</TradeRequest.Currency>
            </div>
          )}
          <div className='d-flex flex-column px-3 px-md-0'>
            <TradeRequest.Title>Initial price</TradeRequest.Title>
            <TradeRequest.Amount amount={data?.price?.amount}>
              {formatterToNull(data?.price?.amount)}
            </TradeRequest.Amount>
          </div>
          <div className='d-flex flex-column px-3 ml-4 px-md-0'>
            <TradeRequest.Title>Boundary price</TradeRequest.Title>
            <TradeRequest.Amount amount={data?.boundaryRate}>
              {formatterToNull(data?.boundaryRate)}
            </TradeRequest.Amount>
          </div>
          <div className='d-flex flex-column ml-4 mr-4 px-3 px-md-0'>
            <TradeRequest.Title>Planned amount</TradeRequest.Title>
            <div className='d-flex align-items-center'>
              <TradeRequest.Amount amount={data?.plannedAmount?.amount}>
                {formatterToNull(data?.plannedAmount?.amount)}
              </TradeRequest.Amount>
              <TradeRequest.Currency>
                {data?.plannedAmount?.asset}
              </TradeRequest.Currency>
            </div>
          </div>
          <div className='d-flex flex-column px-3 px-md-0'>
            <TradeRequest.Title>Planned quantity</TradeRequest.Title>
            <div className='d-flex align-items-center'>
              <TradeRequest.Amount amount={data?.plannedQuantity?.amount}>
                {formatterToNull(data?.plannedQuantity?.amount)}
              </TradeRequest.Amount>
              <TradeRequest.Currency>
                {data?.plannedQuantity?.asset}
              </TradeRequest.Currency>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center px-3 px-md-0'>
          {renderStatus(data?.status)}
        </div>
      </CardHeader>
      <LineWithError errorMessage={data?.error} radius='none' />
      <TradeRequest.CardBody
        className='w-100 d-flex align-items-center flex-wrap justify-content-md-between justify-content-start py-3 px-4'
        onClick={handleOpenModal}
      >
        <div className='d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2'>
          <TradeRequest.Title>Executed amount</TradeRequest.Title>
          <div className='d-flex align-items-center'>
            <TradeRequest.Amount amount={data?.executedAmount?.amount}>
              {formatterToNull(data?.executedAmount?.amount)}
            </TradeRequest.Amount>
            <TradeRequest.Currency>
              {data?.executedAmount?.asset}
            </TradeRequest.Currency>
          </div>
        </div>
        <div className='d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2'>
          <TradeRequest.Title>Executed quantity</TradeRequest.Title>
          <div className='d-flex align-items-center'>
            <TradeRequest.Amount amount={data?.executedQuantity?.amount}>
              {formatterToNull(data?.executedQuantity?.amount)}
            </TradeRequest.Amount>
            <TradeRequest.Currency>
              {data?.executedQuantity?.asset}
            </TradeRequest.Currency>
          </div>
        </div>
        <div className='d-flex flex-column px-2 px-md-0 mb-md-0 mb-2'>
          <TradeRequest.Title>Fee</TradeRequest.Title>
          <div className='d-flex align-items-center'>
            <TradeRequest.Amount amount={data.fee.amount}>
              {formatterToNull(data?.fee?.amount)}
            </TradeRequest.Amount>
            <TradeRequest.Currency>{data?.fee?.asset}</TradeRequest.Currency>
          </div>
        </div>
        <div className='d-flex flex-column px-2 px-md-0 mb-md-0 mb-2'>
          <TradeRequest.Title>Average price</TradeRequest.Title>
          <div className='d-flex align-items-center'>
            <TradeRequest.Amount amount={data?.averageRate}>
              {formatterToNull(data?.averageRate)}
            </TradeRequest.Amount>
          </div>
        </div>
        <div className='d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2'>
          <TradeRequest.Title>Trades</TradeRequest.Title>
          <TradeRequest.Amount amount={data?.tradesCount}>
            {formatterToNull(data?.tradesCount)}
          </TradeRequest.Amount>
        </div>
        <div className='d-flex flex-column text-nowrap px-2 px-md-0 mb-md-0 mb-2'>
          <TradeRequest.Title>Orders</TradeRequest.Title>
          <TradeRequest.Amount amount={data?.ordersCount}>
            {formatterToNull(data?.ordersCount)}
          </TradeRequest.Amount>
        </div>
        <div className='d-flex flex-column align-items-end text-nowrap px-2 px-md-0 mb-md-0 mb-2'>
          <TradeRequest.Title>Duration</TradeRequest.Title>
          <p
            className={`mb-0 font-sm font-weight-500 ${data?.lifetime ? "text-white" : "text-white-35"}`}
          >
            {replaceDurations(data?.lifetime)}
          </p>
        </div>
      </TradeRequest.CardBody>
    </TradeRequest.Card>
  );
};

TradeRequest.Card = styled(Card)`
  border-radius: 8px !important;
  margin-bottom: 8px !important;
`;

TradeRequest.CardBody = styled(CardBody)`
  background: #2e3148;
  border-radius: 0 0 8px 8px !important;

  &:hover {
    cursor: pointer;
    background: #363a54;
  }
`;

TradeRequest.Title = styled.p`
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.35) !important;
  font-weight: 300;
  font-size: 12px;
`;

TradeRequest.Amount = styled.p`
  margin-bottom: 0;
  color: ${props =>
    props.amount > 0
      ? "rgba(255, 255, 255)"
      : "rgba(255, 255, 255, 0.35)"} !important;
  font-weight: 500;
  font-size: 0.875rem;
`;

TradeRequest.Currency = styled.p`
  margin: 0 0 0 4px;
  color: rgba(255, 255, 255, 0.35) !important;
  font-weight: 300;
  font-size: 0.875rem;
`;

TradeRequest.Status = styled.span`
  background: rgba(255, 255, 255, 0.05);
  font-weight: 500;
  font-size: 14px;
  border-radius: 6px;
  padding: 3.5px 10px;
`;

TradeRequest.Icon = styled.div`
  cursor: pointer;

  svg {
    &:focus,
    &:active {
      outline: none;
    }
  }
`;

export default TradeRequest;
