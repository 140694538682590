import React from "react";
import { createGlobalStyle } from "styled-components";
import { Provider } from "react-redux";
import { Router, Switch } from "react-router-dom";
import { browserTracingIntegration, init } from "@sentry/react";
import { init as initAmplitude } from "@amplitude/analytics-browser";

import useNetwork from "hooks/useNetworks";
import { SENTRY_DSN, AMPLITUDE_API_KEY } from "env-create-react-app";
import store from "store";
import PrivateRoute from "./PrivateRoute";
import AuthRoute from "./AuthRoute";
import ConfirmEmail from "./auth/ConfirmEmail";
import Route from "./Route";
import ScrollToTop from "hooks/ScrollToTop";
import NotFound from "./NotFound";
import EmailConfirm from "./user/EmailConfirm";
import browserHistory from "../browserHistory";
import { ListingErrorState, SnackBar } from "shared/ui";

init({
  dsn: SENTRY_DSN,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new browserTracingIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});

initAmplitude(AMPLITUDE_API_KEY);

const App = () => {
  const network = useNetwork();

  if (!network.online) {
    return <ListingErrorState online={network.online} />;
  }

  return (
    <Provider store={store}>
      <Router history={browserHistory}>
        <ScrollToTop>
          <Switch>
            <Route path='/404' component={NotFound} />
            <Route path='/email/confirm' component={EmailConfirm} />
            <Route
              exact
              path='/callback/email/confirm'
              component={ConfirmEmail}
            />
            <Route
              exact
              path={[
                "/login",
                "/register",
                "/forgot-password",
                "/change-password",
                "/success",
                "",
              ]}
              component={AuthRoute}
            />
            <Route component={PrivateRoute} />
          </Switch>
        </ScrollToTop>
        <GlobalStyle />
        <SnackBar />
      </Router>
    </Provider>
  );
};

const GlobalStyle = createGlobalStyle`
  @-webkit-keyframes autofill {
    0%,100% {
      color: #666;
      background: transparent;
    }
  }

  input:-webkit-autofill {
    -webkit-animation-delay: 1s;
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }

  ul {
    list-style-type: none;
  }

  &.modal {
    background: rgb(0, 0, 0, 0.7);
  }

  body &.login-page {
    background: #271053 !important;
  }

  .modal.show .modal-dialog {
    transform: translate(0, 5%) !important;
  }

  .rdt.rdtOpen .rdtPicker {
    display: block !important;
  }

  .rdt .rdtPicker {
    display: none !important;
  }

  a {
    outline: 0;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  .table tr td, .table tr th {
    border: none;
  }

  .error label {
    color: #ec250d;
  }

  .btn.btn-danger, .btn.btn-danger:hover, .btn.btn-danger:active, .btn.btn-danger:focus {
    background: var(--danger) !important;
    background-image: none;
  }

  .sweet-alert {
    top: 30% !important;
    right: 0 !important;
    left: 0 !important;
  }

  .alert {
    font-size: 0.875rem;
  }

  .tooltip.show {
    opacity: 1 !important;
  }

  .list-page-header {
    height: 40px;
  }

  .icon-stroke-gray path {
    stroke: rgba(255, 255, 255, 0.35);
    stroke-opacity: 1;
  }

  .rc-slider-disabled {
    background-color: transparent !important;
  }

  .code-text {
    font-family: Courier;
    color: #9C9FBF !important;
  }
`;

export default App;
