import React, { useState } from "react";
import { Progress } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { createBotDeal } from "store/deals/actions";
import { addSnackBar } from "store/snackbar/actions";
import { BotsListToggle, Icon, MultiProgress } from "shared/ui";
import MarketBotsTooltips from "shared/ui/MarketBots/MarketBotsTooltips";
import { Button } from "shared/ui/buttons";

const BotsTableRow = ({
  item,
  quote,
  base,
  redirectTo,
  fetchCredentialsBots,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleCreateDeal = async id => {
    setLoading(true);
    const res = await dispatch(createBotDeal(id, quote, base));
    if (res?.errors) {
      setLoading(false);
      dispatch(addSnackBar("error", res.message));
      return;
    }
    dispatch(addSnackBar("success", res.message));
    fetchCredentialsBots();
    setLoading(false);
  };

  return (
    <tr>
      <td className='pr-0'>
        <BotsListToggle status={item?.status} id={item?.id} isList />
      </td>

      <td className='pl-0'>
        <div className='d-flex align-items-center'>
          <div className='mr-3 d-flex align-items-center'>
            <span className='d-inline-block mr-2'>
              <Icon size='thumb' code={`${item?.type}-bot`} type='icons' />
            </span>
            <p className='mb-0 text-white'>{item.name}</p>
          </div>
          <Link className='text-info' to={`/bots/${item?.id}/configure`}>
            Edit
          </Link>
        </div>
      </td>

      <td>
        <p className='mb-0 text-white'>{item?.marketsCount}</p>
      </td>

      <td>
        {item?.statistics?.profit.percent && item?.statistics?.loss.percent ? (
          <>
            <MultiProgress height='12px' id={`multi-${item.id}`}>
              <Progress
                bar
                className='text-success'
                color='success'
                value={+item?.statistics?.profit.percent}
              />
              <Progress
                bar
                className='text-danger'
                color='danger'
                value={+item?.statistics?.loss.percent}
              />
            </MultiProgress>
            <MarketBotsTooltips
              index={item.id}
              loss={item.statistics.loss}
              profit={item.statistics.profit}
            />
          </>
        ) : (
          <p className='mb-0 text-white-35'>No deals yet</p>
        )}
      </td>

      <td>
        <div className='d-flex align-items-center'>
          <p
            className={`mb-0 ${
              item?.totalReturn ? "text-white" : "text-white-35"
            }`}
          >
            {item?.totalReturn ? item?.totalReturn : 0}
          </p>
          <p className='ml-1 mb-0 text-white-35'>{quote}</p>
        </div>
      </td>

      <td>
        <p className='mb-0 text-white'>{item?.period} min</p>
      </td>

      <td>
        <p className='mb-0 text-white'>{item?.runningDeals}</p>
      </td>

      <td>
        {redirectTo ? (
          <Link
            to={`/deals/${redirectTo}`}
            className='font-sm text-info text-nowrap px-3 py-2'
          >
            View deal
          </Link>
        ) : (
          <Button
            color='blue'
            className='text-nowrap px-3 py-2'
            loading={loading}
            disabled={loading}
            onClick={handleCreateDeal}
          >
            Force run a deal
          </Button>
        )}
      </td>
    </tr>
  );
};

export default BotsTableRow;
