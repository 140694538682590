export const CREDENTIALS_STATUS_OPTIONS = [
  { value: "enabled", label: "Enabled", color: "#3FCBB0" },
  { value: "disabled", label: "Disabled", color: "rgba(255, 255, 255, 0.35)" },
];

export const BOT_TYPE_OPTIONS = [
  { value: "custom", label: "Custom", color: "#5F9EF1" },
  { value: "genetic", label: "Genetic", color: "#937AFF" },
  { value: "template", label: "Template", color: "#E49D31" },
];

export const BOT_STATUS_OPTIONS = [
  { value: "running", label: "Running", color: "#3FCBB0" },
  { value: "stopped", label: "Stopped", color: "rgba(255, 255, 255, 0.35)" },
];

export const BOT_SORT_OPTIONS = [
  { value: "countDeals", label: "Deals", color: "#FFFFFF" },
  { value: "period", label: "Period", color: "#FFFFFF" },
  { value: "totalProfit", label: "Total profit", color: "#FFFFFF" },
  { value: "totalLoss", label: "Total loss", color: "#FFFFFF" },
  { value: "successDeals", label: "Profit deals", color: "#FFFFFF" },
  { value: "losingDeals", label: "Loss deals", color: "#FFFFFF" },
  { value: "dailyReturn", label: "AVG return", color: "#FFFFFF" },
  { value: "totalReturn", label: "Total return", color: "#FFFFFF" },
  { value: "volume", label: "MMV 24H", color: "#FFFFFF" },
];

export const BOT_ACTIVE_STATUS = [
  { value: true, label: "Enabled", color: "#FFFFFF" },
  { value: false, label: "Disabled", color: "#FFFFFF" },
];

export const DEAL_STATUS_OPTIONS = [
  { value: "ready", label: "Ready", color: "var(--info)" },
  { value: "running", label: "Running", color: "#DB53C7" },
  { value: "completed", label: "Completed", color: "#5FD3BC" },
  {
    value: "cancelled",
    label: "Cancelled",
    color: "rgba(255, 255, 255, 0.35)",
  },
];

export const DEAL_RETURN_OPTIONS = [
  { value: "gt", label: "Profit", color: "#3FCBB0" },
  { value: "lt", label: "Loss", color: "#EA4848" },
];

export const DEAL_SORTING_OPTIONS = [
  { value: "createdAt", label: "Date of creation" },
  { value: "completedAt", label: "Date of completion" },
  { value: "invested", label: "Invested" },
  { value: "currentReturn", label: "Estimated return" },
  { value: "profit", label: "Total profit" },
];

export const RETURN_OPTIONS = [
  { value: "profit", label: "Profit", color: "#3FCBB0" },
  { value: "loss", label: "Loss", color: "#EA4848" },
];

export const INDIVIDUALS_STATUS_OPTIONS = [
  { value: "new", label: "New", color: "#FFFFFF" },
  { value: "ready", label: "Ready", color: "#85C9F9" },
  { value: "computing", label: "Computing", color: "#FA76E6" },
  { value: "computed", label: "Computed", color: "#BBDA6C" },
  { value: "failed", label: "Failed", color: "#FF8D72" },
];

export const INDIVIDUALS_GENESIS_OPTIONS = [
  { value: "generated", label: "Generated", color: "#FFFFFF" },
  { value: "spawned", label: "Spawned", color: "#FFFFFF" },
];

export const CHANNELS_STATUS_OPTIONS = [
  { value: "subscribed", label: "Subscribed", color: "#FFFFFF" },
  { value: "subscribe", label: "Non subscribed", color: "#FFFFFF" },
];

export const CHANNELS_SORT_OPTIONS = [
  { value: "name", label: "Name", color: "#FFFFFF" },
  { value: "createdAt", label: "Created At", color: "#FFFFFF" },
  { value: "totalSignals", label: "Signals Count", color: "#FFFFFF" },
  { value: "totalProfit", label: "Profit", color: "#FFFFFF" },
];

export const SIGNALS_STATUS_OPTIONS = [
  { value: "open", label: "Open", color: "#DB53C7" },
  { value: "completed", label: "Completed", color: "#5FD3BC" },
  {
    value: "cancelled",
    label: "Cancelled",
    color: "rgba(255, 255, 255, 0.35)",
  },
];

export const AGREEMENT_STATUS_OPTIONS = [
  { value: "pending", label: "Pending", color: "var(--info)" },
  { value: "running", label: "Running", color: "#DB53C7" },
  { value: "completed", label: "Completed", color: "#5FD3BC" },
  {
    value: "cancelled",
    label: "Cancelled",
    color: "rgba(255, 255, 255, 0.35)",
  },
];

export const AGREEMENT_TR_STATUS_OPTIONS = [
  { value: "completed", label: "Completed", color: "#5FD3BC" },
  {
    value: "cancelled",
    label: "Cancelled",
    color: "rgba(255, 255, 255, 0.35)",
  },
  { value: "failed", label: "Failed", color: "#FF8D72" },
];

export const AGREEMENT_TR_SIDE = [
  { value: "buy", label: "Buy", color: "#3FCBB0" },
  { value: "sell", label: "Sell", color: "#ED5F5F" },
];

export const SUBSCRIPTION_TYPE = [
  { value: null, label: "Free", color: "#FFFFFF" },
  { value: "standard", label: "Standard", color: "#FFFFFF" },
  { value: "premium", label: "Premium", color: "#FFFFFF" },
];

export const CONFIRMED_ACCOUNT = [
  { value: true, label: "Confirmed", color: "#FFFFFF" },
  { value: false, label: "Unconfirmed", color: "#FFFFFF" },
];

export const TRIAL = [
  { value: true, label: "Yes", color: "#FFFFFF" },
  { value: false, label: "No", color: "#FFFFFF" },
];
