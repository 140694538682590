import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import ChannelDescriptionPreload from "./ChannelDescriptionPreload";
import ChannelSignalsTablePreloader from "./ChannelSignalsTablePreloader";

const OverviewPreloader = () => (
  <>
    <div className='mb-4'>
      <div className='d-flex align-items-center'>
        <SkeletonPreloader height={11} width={67} />
        <SkeletonPreloader height={11} width={67} className='ml-4' />
      </div>
      <SkeletonPreloader height={3} width={67} />
    </div>
    <ChannelDescriptionPreload />
    <Card className='pt-3'>
      <CardHeader className='p-0 px-4 d-flex justify-content-between mb-3'>
        <SkeletonPreloader height={20} width={20} />
        <SkeletonPreloader height={19} width={136} className='ml-2 mb-0' />
      </CardHeader>
      <CardBody className='p-0'>
        <ChannelSignalsTablePreloader />
      </CardBody>
    </Card>
    <Card className='pt-3'>
      <CardHeader className='p-0 px-4 d-flex justify-content-between mb-3'>
        <SkeletonPreloader height={20} width={20} />
        <SkeletonPreloader height={19} width={136} className='ml-2 mb-0' />
      </CardHeader>
      <CardBody className='p-0'>
        <ChannelSignalsTablePreloader />
      </CardBody>
    </Card>
  </>
);

export default OverviewPreloader;
