import React from "react";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";

import { INDIVIDUALS_STATUS_OPTIONS } from "models/filters";
import { TABLE_HEAD, EMPTY_STATE_TEXT } from "../models/individuals";
import { formatBooleanToText } from "helpers/valuesFormatters";
import {
  CustomScrollbars,
  EmptyState,
  SortTableHead,
  TablePreloader,
} from "shared/ui";
import { StyledTable } from "shared/styled/StyledTable";

const IndividualsTable = ({ individuals, onSorting, sort }) => {
  const getColorClassName = title => {
    return INDIVIDUALS_STATUS_OPTIONS.find(({ value }) => value === title)
      .color;
  };

  const loading = useSelector(state => state.bots.geneticIndividuals.loading);
  const total = useSelector(state => state.bots.geneticIndividuals.data.total);

  if (loading) {
    return (
      <TablePreloader
        thHeight={9}
        thWidth={[64, 64, 68, 64, 64, 82, 64, 64]}
        tdWidth={[83, 89, 52, 65, 52, 50, 52, 95]}
        tdHeight={12}
        rowTimes={5}
        withCircle={24}
      />
    );
  }

  return (
    <CustomScrollbars
      autoHideTimeout={500}
      autoHideDuration={500}
      autoHeight
      autoHeightMax={Number.MAX_VALUE}
    >
      {!total ? (
        <EmptyState
          title={EMPTY_STATE_TEXT.title}
          description={EMPTY_STATE_TEXT.desc}
        />
      ) : (
        <StyledTable className='mb-0'>
          <SortTableHead
            handleSorting={onSorting}
            sort={sort}
            theadItems={[...TABLE_HEAD]}
          />
          <IndividualsTable.Tbody className='font-sm'>
            {individuals?.items.map((item, key) => (
              <tr key={item[key]}>
                <td>
                  <p className='mb-0 font-weight-500 text-white font-sm'>
                    {item.fitnessScore || "-"}
                  </p>
                </td>
                <td>
                  <IndividualsTable.Status
                    color={getColorClassName(item.status)}
                  >
                    <p className='mb-0 font-weight-500 font-sm text-capitalize'>
                      {item.status}
                    </p>
                  </IndividualsTable.Status>
                </td>
                <td>
                  <p
                    className={`mb-0 font-sm font-weight-500 text-${item.mutated ? "success" : "white-35"}`}
                  >
                    {formatBooleanToText(item.mutated)}
                  </p>
                </td>
                <td>
                  <p className='mb-0 font-sm text-capitalize font-weight-500 text-white'>
                    {item.genesis}
                  </p>
                </td>
                <td>
                  <p
                    className={`mb-0 font-sm font-weight-500 text-${item.cloned ? "success" : "white-35"}`}
                  >
                    {formatBooleanToText(item.cloned)}
                  </p>
                </td>
                <td>
                  <p className='mb-0 font-weight-500 text-white font-sm'>
                    {item.position || "-"}
                  </p>
                </td>
                <td>
                  <p
                    className={`mb-0 font-sm font-weight-500 text-${item.selected ? "success" : "white-35"}`}
                  >
                    {formatBooleanToText(item.selected)}
                  </p>
                </td>
                <td>
                  <p className='mb-0 font-weight-500 text-white text-nowrap'>
                    {item.computedAt
                      ? moment(item.computedAt).format("DD.MM.YYYY HH:mm")
                      : "-"}
                  </p>
                </td>
              </tr>
            ))}
          </IndividualsTable.Tbody>
        </StyledTable>
      )}
    </CustomScrollbars>
  );
};

IndividualsTable.Tbody = styled.tbody`
  .individual-green {
    color: #a8f5c7;
  }
  .individual-blue {
    color: #85c9f9;
  }
  .individual-light__green {
    color: #bbda6c;
  }
  tr {
    background: #2e3148;
    cursor: pointer;
    border-bottom: 1px solid var(--dark-slate);

    :hover {
      background: #363a54;
      transition: all 0.2s ease-in-out;
    }

    :last-child {
      border-bottom: none;
    }
  }
`;

IndividualsTable.Status = styled.div`
  background: rgba(255, 255, 255, 0.03);
  border-radius: 6px;
  padding: 3.5px 8px;
  width: max-content;
  p {
    color: ${props => props.color} !important;
    line-height: 16px;
  }
`;

export default IndividualsTable;
