import React from "react";
import styled from "styled-components";
import { CardBody } from "reactstrap";
import { useDispatch } from "react-redux";

import { addSnackBar } from "store/snackbar/actions";
import { ToggleSwitch } from "shared/ui";

const NotificationsItem = ({
  item,
  onToggleNotification,
  disabled,
  messageId,
  setMessageId,
  isLoading,
}) => {
  const dispatch = useDispatch();

  const handleToggle = async (code, value) => {
    const res = await dispatch(
      onToggleNotification(code, { notification: value }),
    );
    if (res.errors) return dispatch(addSnackBar("error", res.message));
    dispatch(addSnackBar("success", res.message));
  };

  return (
    <NotificationsItem.Messages className='pl-0 pr-2 py-4 p-sm-4'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex my-auto'>
          <p className='mb-0 font-weight-300'>{item.message}</p>
        </div>
        <div className='d-flex my-auto ml-2 ml-sm-4'>
          <ToggleSwitch
            onChange={() => {
              setMessageId(item.message);
              handleToggle(item.code, !item.enabled);
            }}
            checked={item.enabled}
            disabled={(disabled && item.message === messageId) || isLoading}
            height={18}
            width={45}
            className='my-auto'
          />
        </div>
      </div>
    </NotificationsItem.Messages>
  );
};

NotificationsItem.Messages = styled(CardBody)`
  margin: 0 6px 0 16px;
  &:hover {
    background: #363a54;
    border-radius: 6px;
    margin: 0 6px 0 16px;
    cursor: pointer;
  }
`;

export default NotificationsItem;
