import React from "react";

import { ReactComponent as FacebookIcon } from "assets/icons/social/facebook.svg";
import SocialButton from "./SocialButton";

const FacebookShareButton = ({ url, text, hashtags, ...props }) => {
  const hashtagsString = hashtags ? hashtags.join(",") : "";
  const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(text)}${hashtagsString ? `&hashtags=${encodeURIComponent(hashtagsString)}` : ""}`;

  return (
    <SocialButton url={shareUrl} {...props}>
      <FacebookIcon width={18} height={18} />
    </SocialButton>
  );
};

export default FacebookShareButton;
