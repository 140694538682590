import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useField from "hooks/useField";
import {
  connectTelegram,
  disconnectTelegram,
  sendTestMessageTelegram,
} from "store/user/actions";
import { useDispatch } from "react-redux";
import { addSnackBar } from "store/snackbar/actions";
import TelegramPreloader from "../preloaders/TelegramPreloader";
import TelegramForm from "./TelegramForm";
import TelegramDefaultForm from "../forms/TelegramDefaultForm";

const TelegramWidget = ({ telegram, loading }) => {
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTestMsgSend, setIsTestMsgSend] = useState(false);
  const [secretValue, setSecretValue] = useState({ secret: "" });
  const [fieldProps, errors] = useField(secretValue, setSecretValue);

  const dispatch = useDispatch();

  const handleConnectTelegram = async () => {
    setSubmitted(true);
    if (!errors.secret) {
      setIsSubmitting(true);
      const res = await dispatch(connectTelegram(secretValue));
      if (!res.status) {
        dispatch(addSnackBar("success", res.message));
      } else {
        dispatch(addSnackBar("error", res.data.message));
      }
    }
    setIsTestMsgSend(false);
    setIsSubmitting(false);
    setSecretValue({ secret: "" });
  };

  const handleDisconnectTelegram = async e => {
    e.preventDefault();
    const res = await dispatch(disconnectTelegram());
    dispatch(addSnackBar("success", res.message));
    setSubmitted(false);
    setSecretValue({ secret: "" });
    setIsTestMsgSend(false);
  };

  const handleTestMessage = async () => {
    setIsSubmitting(true);
    const res = await dispatch(sendTestMessageTelegram());
    dispatch(addSnackBar("success", res.message));
    setIsTestMsgSend(res.message);
    setIsSubmitting(false);
  };

  useEffect(() => {
    return () => {
      setIsTestMsgSend(false);
    };
  }, []);

  if (loading) return <TelegramPreloader />;

  return (
    <TelegramWidget.Wrapper>
      {telegram ? (
        <TelegramDefaultForm
          isTestMsgSend={isTestMsgSend}
          isSubmitting={isSubmitting}
          onDisconnect={handleDisconnectTelegram}
          onTestMessage={handleTestMessage}
        />
      ) : (
        <TelegramForm
          submitted={submitted}
          isSubmitting={isSubmitting}
          fieldProps={fieldProps}
          secretValue={secretValue}
          errors={errors}
          onConnectTelegram={handleConnectTelegram}
        />
      )}
    </TelegramWidget.Wrapper>
  );
};

TelegramWidget.Wrapper = styled.div`
  .send-wrapper {
    border-radius: 6px;
    height: 40px;
    transition: all 0.3s ease-in-out;
  }
  .disconnect-link {
    :hover {
      text-decoration: underline;
    }
  }
`;

export default TelegramWidget;
