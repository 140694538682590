import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import ResultsDropdownItem from "./ResultsDropdownItem";
import ResultListHeader from "./ResultListHeader";
import { ReactComponent as CredentialsDark } from "assets/icons/credential/CredentialsDark.svg";
import { ReactComponent as Ellipse } from "assets/icons/general/ellipse.svg";
import { ReactComponent as Clock } from "assets/icons/general/clock.svg";
import { Icon } from "shared/ui";

const CredentialsResults = ({ credentialsResult, onSetModalSearch }) => {
  const history = useHistory();

  if (!credentialsResult.length) return null;

  return (
    <div className='d-flex flex-column mb-4'>
      <ResultListHeader className='d-flex p-2 mb-2'>
        <CredentialsDark />
        <p className='font-sm ml-2 my-auto'>Credentials</p>
      </ResultListHeader>
      {credentialsResult.map(item => (
        <ResultsDropdownItem
          className='d-flex justify-content-between align-items-md-center pr-2 pl-2 mt-1 mb-md-1 mb-3'
          key={item.id}
          onClick={() => {
            onSetModalSearch(false);
            history.push(`/credentials/${item.id}`);
          }}
        >
          <CredentialsResults.LeftSideWrapper className='d-flex align-items-center'>
            <div className='d-flex position-relative'>
              <Icon code={item.title} size='thumb' type='exchanges' />
              <CredentialsResults.IconWrapper>
                {item.metadata.status === "enabled" ? (
                  <Ellipse className='hoverStatus' />
                ) : (
                  <Clock className='hoverStatus' />
                )}
              </CredentialsResults.IconWrapper>
            </div>
            <p
              className='mb-0 ml-3 font-weight-500 text-capitalize'
              style={{ color: "#333333" }}
            >
              {item.metadata.exchange}
            </p>
            <CredentialsResults.Comment>
              {item.metadata.comment}
            </CredentialsResults.Comment>
          </CredentialsResults.LeftSideWrapper>
          <p className='mb-0 font-weight-500 font-md text-dark text-center text-md-left my-auto text-nowrap'>
            {item.info?.split(" ")[0]}{" "}
            <span className='text-dark-35 font-md font-weight-300'>
              {item.info?.split(" ")[1]}
            </span>
          </p>
        </ResultsDropdownItem>
      ))}
    </div>
  );
};

CredentialsResults.LeftSideWrapper = styled.div`
  @media (max-width: 576px) {
    width: 50%;
  }
`;

CredentialsResults.Comment = styled.p`
  margin-bottom: 0;
  margin-left: 4px;
  max-width: 200px;
  font-size: 0.75rem;
  color: rgba(29, 30, 42, 0.75);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

CredentialsResults.IconWrapper = styled.div`
  svg {
    background: #ffffff;
    border: 3px solid #ffffff;
    width: 13px;
    height: 13px;
    border-radius: 100px;
    position: absolute;
    top: 60%;
    right: -10%;
  }
`;

export default CredentialsResults;
