import PropTypes from "prop-types";

export const numberOrNull = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.oneOf([null]),
]);
export const stringOrNull = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.oneOf([null]),
]);
export const ProfitStatistics = {
  average: numberOrNull,
  total: numberOrNull,
  month: numberOrNull,
};
