import {
  GET_NOTIFICATIONS_CODES_SUCCESS,
  GET_NOTIFICATIONS_CODES_LOADING,
  GET_NOTIFICATIONS_CODES_FAILURE,
  TOGGLE_NOTIFICATION,
  ENABLE_ALL_NOTIFICATIONS,
  ENABLE_ALL_NOTIFICATIONS_LOADING,
  DISABLE_ALL_NOTIFICATIONS_LOADING,
  DISABLE_ALL_NOTIFICATIONS,
  TOGGLE_NOTIFICATION_LOADING,
} from "./constants";

export default function notificationsCodes(
  state = {
    data: {},
    loading: false,
    disabled: false,
    toggleLoading: false,
    error: false,
  },
  action,
) {
  const { type, payload } = action;

  switch (type) {
    case GET_NOTIFICATIONS_CODES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
      };

    case GET_NOTIFICATIONS_CODES_LOADING:
      return {
        loading: true,
        data: {},
        error: false,
      };

    case GET_NOTIFICATIONS_CODES_FAILURE:
      return {
        data: {},
        loading: false,
        error: payload,
      };

    case TOGGLE_NOTIFICATION:
      return {
        ...state,
        loading: false,
        disabled: false,
        data: {
          [payload.lang]: state.data[payload.lang].map(item => {
            if (item.code === payload.code) {
              return { ...item, enabled: payload.enabled };
            }
            return item;
          }),
        },
      };

    case TOGGLE_NOTIFICATION_LOADING:
      return {
        ...state,
        disabled: true,
      };

    case ENABLE_ALL_NOTIFICATIONS:
      return {
        ...state,
        disabled: false,
        toggleLoading: false,
        data: {
          [payload.lang]: state.data[payload.lang].map(item => {
            return { ...item, enabled: true };
          }),
        },
      };

    case ENABLE_ALL_NOTIFICATIONS_LOADING:
      return {
        ...state,
        toggleLoading: true,
        disabled: true,
      };

    case DISABLE_ALL_NOTIFICATIONS:
      return {
        ...state,
        disabled: false,
        toggleLoading: false,
        data: {
          [payload.lang]: state.data[payload.lang].map(item => {
            return { ...item, enabled: false };
          }),
        },
      };

    case DISABLE_ALL_NOTIFICATIONS_LOADING:
      return {
        ...state,
        toggleLoading: true,
        disabled: true,
      };

    default:
      return state;
  }
}
