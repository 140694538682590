import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import ChannelDetailsHeader from "./compounds/ChannelDetailsHeader";
import { setChannelsBreadcrumbs } from "models/enum/breadcrumbs";
import ChannelSubscriptionWidget from "views/channels/widgets/ChannelSubscriptionWidget";
import ChannelSignalsWidget from "./widgets/ChannelSignalsWidget";
import ChannelStatisticsWidget from "./widgets/ChannelStatisticsWidget";
import { selectChannel } from "store/channels/selectors";
import useRequestCancellation from "hooks/useRequestCancellation";
import { getChannel, resetChannel } from "store/channels/actions";
import PagesView from "./pagesView";
import { CHANNELS_CONTENT } from "models/constans/channels/content";
import { Breadcrumbs, Error, RefreshButton } from "shared/ui";
import { DangerZone } from "features/widgets";

const ChannelPage = ({ match }) => {
  const [pageView, setPagView] = useState("overview");
  const dispatch = useDispatch();
  const { useAbortableEffect } = useRequestCancellation();

  const { error, loading, data } = useSelector(selectChannel);

  useEffect(() => {
    window.analytics.page("/channels/:id");
  });

  const telegramLink = useMemo(() => {
    if (data?.telegram?.[0] === "@") {
      return data?.telegram?.slice(1);
    }
    return data?.telegram;
  }, [data?.telegram]);

  useAbortableEffect(
    signal => {
      dispatch(getChannel(match.params.id, signal));
      return () => dispatch(resetChannel());
    },
    [match.params.id, dispatch],
    true,
  );

  const handleRefresh = () => {
    dispatch(getChannel(match.params.id));
  };

  if (error) return <Error error={error} />;
  return (
    <>
      <div className='d-none d-md-flex align-items-center justify-content-between'>
        <Breadcrumbs
          items={setChannelsBreadcrumbs(data?.name, data?.id, false)}
          loading={loading}
          data-testid='breadcrumbs'
        />
        <RefreshButton
          onHandleRefresh={handleRefresh}
          loading={loading}
          data-testid='refresh-button'
        />
      </div>
      <ChannelDetailsHeader
        loading={loading}
        handleRefresh={handleRefresh}
        channel={data}
        data-testid='channel-details-header'
        telegramLink={telegramLink}
      />
      <Row>
        <Col xs='12' md='4' className='no-gutters'>
          {data?.subscribed ? (
            <ChannelSubscriptionWidget
              loading={loading}
              channelId={data?.id}
              handleRefresh={handleRefresh}
              maxximun={data?.subscriberResponse?.maximum}
              volume={data?.subscriberResponse?.volume}
              accept={data?.subscriberResponse?.accept}
              data-testid='subscription-widget'
            />
          ) : null}
          <ChannelSignalsWidget
            channel={data}
            signalsLoss={data?.statistics?.signals.loss}
            signalsOpen={data?.statistics?.signals.open}
            signalsProfit={data?.statistics?.signals.profit}
            lastSignalDate={data?.lastSignalDate}
            loading={loading}
            data-testid='signals-widget'
          />
          <ChannelStatisticsWidget
            loading={loading}
            average={data?.profit?.average}
            total={data?.profit?.total}
            month={data?.profit?.month}
            data-testid='statistics-widget'
          />
          {data?.owner ? (
            <DangerZone
              id={data?.id}
              loading={loading}
              status={data?.status}
              content={CHANNELS_CONTENT?.details?.widgets?.dangerZone}
            />
          ) : null}
        </Col>
        <Col xs='12' md='8'>
          <PagesView
            chanelId={match.params.id}
            loadingPage={loading}
            data={data}
            pageView={pageView}
            setPagView={setPagView}
            data-testid='pages-view'
          />
        </Col>
      </Row>
    </>
  );
};

ChannelPage.propTypes = {
  isExact: PropTypes.bool,
  path: PropTypes.string,
  url: PropTypes.string,
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default ChannelPage;
