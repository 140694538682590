import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import _ from "lodash";
import styled from "styled-components";

import useWindowDimensions from "hooks/useWindowDimensions";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import TablePreloader from "shared/ui/TablePreloader";

const IndividualsWidgetPreloader = () => {
  const { width } = useWindowDimensions();

  return (
    <Card className='p-0 pb-0'>
      <CardHeader className='d-flex flex-column flex-md-row align-items-md-center px-4 pt-4 mb-4'>
        <div className='d-flex h-auto mb-3 mb-md-0'>
          <SkeletonPreloader circle height={25} width={25} className='mr-2' />
          <SkeletonPreloader height={12} width={79} className='my-auto' />
        </div>
        <SkeletonPreloader
          height={20}
          borderRadius={"5px"}
          className='my-auto ml-md-5'
        />
      </CardHeader>
      <CardBody className='p-0'>
        <div className='d-flex flex-row align-items-center justify-content-between mb-4 px-4'>
          {_.times(width > 576 ? 4 : 2, e => (
            <IndividualsWidgetPreloader.Box
              key={e}
              height={38}
              borderRadius={"6px"}
              className='mr-2'
            />
          ))}
        </div>

        <TablePreloader
          thHeight={9}
          thWidth={[64, 64, 68, 64, 64, 82, 64, 64]}
          tdWidth={[83, 89, 52, 65, 52, 50, 52, 95]}
          tdHeight={12}
          rowTimes={5}
          withCircle={24}
        />
      </CardBody>
    </Card>
  );
};

IndividualsWidgetPreloader.Box = styled(SkeletonPreloader)`
  background: #2c2f44 !important;
  :last-child {
    margin-right: 0 !important;
  }
`;

export default IndividualsWidgetPreloader;
