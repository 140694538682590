import React, { useEffect } from "react";
import styled from "styled-components";
import { Modal, Card } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import QueryString from "qs";

import TradeRequestGoal from "./ui/TradeRequestCard/ui/TradeRequestGoal";
import {
  fetchTradeRequestOrders,
  fetchTradeRequestTrades,
} from "store/agreements/actions";
import { ReactComponent as Info } from "assets/icons/general/infoIcon.svg";
import LineWithError from "../LineWithError";
import TradeRequestDetails from "../TradeRequestDetailsModal";
import Button from "../buttons/Button";
import IdWithCopy from "../IdWithCopy";
import TradeRequestsDetailsTable from "../TradeRequestDetailsModal/TradeRequestsDetailsTable";
import { BadgeBuySell } from "../badges";

const TradeRequestsDetailsModal = ({
  setIsModalOpen,
  isModalOpen,
  modalOpenItem,
  itemsRebalance = [],
}) => {
  const dispatch = useDispatch();

  const tradeRebalance = itemsRebalance?.filter(
    item => item.tradeRequest.id === modalOpenItem.id,
  )[0];

  const ordersRequests = useSelector(
    state => state.agreements.requestsOrders.data,
  );
  const ordersRequestsLoading = useSelector(
    state => state.agreements.requestsOrders.loading,
  );
  const tradesRequests = useSelector(
    state => state.agreements.requestsTrades.data,
  );
  const tradesRequestsLoading = useSelector(
    state => state.agreements.requestsTrades.loading,
  );

  const fetchData = () => {
    const filtersOrders = QueryString.stringify({
      filter: {
        status: {
          eq: "open",
        },
      },
      limit: 5,
    });
    dispatch(fetchTradeRequestOrders(modalOpenItem.id, `?${filtersOrders}`));
    dispatch(fetchTradeRequestTrades(modalOpenItem.id, "?limit=5"));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderDetails = (trade, tradeRebalance) => {
    if (trade) {
      return (
        <>
          <LineWithError radius='8px' errorMessage={trade.error} />
          <TradeRequestDetails trade={trade} setIsModalOpen={setIsModalOpen} />
        </>
      );
    }
    if (tradeRebalance) {
      return (
        <>
          {tradeRebalance.tradeRequest.error && (
            <LineWithError radius='8px' errorMessage={tradeRebalance.error} />
          )}
          <TradeRequestDetails
            trade={tradeRebalance.tradeRequest}
            setIsModalOpen={setIsModalOpen}
          />
        </>
      );
    }
  };

  return (
    <TradeRequestsDetailsModal.Modal
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(!isModalOpen)}
      modalClassName='modal-black'
    >
      <TradeRequestsDetailsModal.Card>
        <div className='px-4 pt-4'>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
              <BadgeBuySell type={modalOpenItem?.type} />
              <h3 className='mb-0 ml-2 text-white font-weight-500'>
                Trade request
              </h3>
              <TradeRequestGoal className='ml-3' goal={modalOpenItem?.goal} />

              {modalOpenItem?.forceStarted && (
                <TradeRequestsDetailsModal.Label className='text-white font-xs font-weight-400 ml-3'>
                  Manual
                </TradeRequestsDetailsModal.Label>
              )}
            </div>
            <Button
              color='gray'
              cancel={true}
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          <IdWithCopy id={modalOpenItem.id} className='mb-2' />

          {renderDetails(modalOpenItem, tradeRebalance)}

          {modalOpenItem.comment && (
            <TradeRequestsDetailsModal.Comment className='d-flex align-items-center mb-4'>
              <Info />
              <p className='mb-0 ml-3 text-white font-sm'>
                {modalOpenItem.comment}
              </p>
            </TradeRequestsDetailsModal.Comment>
          )}
        </div>

        <TradeRequestsDetailsTable
          ordersRequests={ordersRequests}
          tradesRequests={tradesRequests}
          isModalOpenID={modalOpenItem.id}
          ordersRequestsLoading={ordersRequestsLoading}
          tradesRequestsLoading={tradesRequestsLoading}
          fetchData={fetchData}
        />
      </TradeRequestsDetailsModal.Card>
    </TradeRequestsDetailsModal.Modal>
  );
};

TradeRequestsDetailsModal.Modal = styled(Modal)`
  @media (max-width: 1200px) {
    width: 100% !important;
    margin: 0 auto !important;
  }

  max-width: 1150px !important;
  margin: 1rem auto !important;

  .modal-content {
    border-radius: 8px !important;
  }

  .pointer {
    cursor: pointer;
  }
`;

TradeRequestsDetailsModal.Card = styled(Card)`
  background: #2a2c42 !important;
  border-radius: 8px !important;
  margin-bottom: 0 !important;
`;

TradeRequestsDetailsModal.Comment = styled.div`
  background: rgba(47, 128, 237, 0.1);
  border-radius: 8px;
  padding: 12px 16px;

  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: var(--info);
      stroke-opacity: 1;
    }
  }
`;

TradeRequestsDetailsModal.Label = styled.span`
  padding: 4px 6px;
  border-radius: 2px;
  background: rgba(47, 128, 237, 0.2);
`;

export default TradeRequestsDetailsModal;
