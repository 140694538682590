import React from "react";
import { CardHeader, Col } from "reactstrap";

import TradeRequestGoal from "./TradeRequestGoal";
import TradeRequestStatus from "./TradeRequestStatus";
import ParameterDisplay from "shared/ui/ParameterDisplay";
import { BadgeBuySell } from "shared/ui/badges";

const TradeRequestCardHeader = ({ data, goal }) => {
  return (
    <CardHeader className='d-flex flex-wrap flex-md-nowrap py-3 px-4'>
      <Col
        xl={9}
        className='d-flex align-items-center flex-wrap flex-xl-nowrap p-0'
      >
        <BadgeBuySell type={data?.type} />
        <div className='d-flex d-xl-none align-items-center mr-0 ml-auto '>
          <TradeRequestGoal goal={goal} />
          <TradeRequestStatus data={data} />
        </div>
        <div className='d-flex flex-wrap flex-lg-nowrap mt-3 mt-xl-0 w-100'>
          <ParameterDisplay
            title='Initial price'
            amount={data?.price?.amount}
            className='col-7 col-md-2 p-0'
          />

          <ParameterDisplay
            title='Boundary price'
            amount={data?.boundaryRate}
            className='col-5 col-md-3 p-0'
          />

          <ParameterDisplay
            title='Planned amount'
            amount={data?.plannedAmount?.amount}
            coin={data?.plannedAmount?.asset}
            className='col-7 col-md-3 p-0'
          />

          <ParameterDisplay
            title='Planned quantity'
            amount={data?.plannedQuantity?.amount}
            coin={data?.plannedQuantity?.asset}
            className='col-5 col-md-4 p-0'
          />
        </div>
      </Col>
      <Col
        xl={3}
        className='d-none d-xl-flex align-items-center justify-content-end p-0 '
      >
        <TradeRequestGoal goal={goal} />
        <TradeRequestStatus data={data} />
      </Col>
    </CardHeader>
  );
};

export default TradeRequestCardHeader;
