import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import qs from "qs";

import InvestmentTable from "./InvestmentTable";
import Error from "shared/ui/state/Error";
import PaginationPanel from "shared/ui/PaginationPanel";
import InvestmentsPreloader from "./InvestmentsPreloader";
import InvestmentsFiltersPreloader from "./InvestmentsFiltersPreloader";
import InvestmentsFilters from "views/bots/wizard/Investments/InvestmentsFilters";
import { getInvestmentsList } from "store/investment/actions";
import { userExchangesSelector } from "store/exchanges/selectors";
import { fetchCredentials } from "store/credentials/actions";
import InvestmentHeader from "./InvestmentHeader";
import EmptyState from "./EmptyState";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import { Button } from "shared/ui/buttons";

const Investments = ({ goNextStep, location, setCredentialId }) => {
  const [initLoading, setInitLoading] = useState(true);
  const [emptyFilter, setEmptyFilter] = useState(true);
  const [selectedBots, setSelectedBots] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  const credentialsList = useSelector(state => state.credentials.list.data);
  const credentialsLoading = useSelector(
    state => state.credentials.list.loading,
  );
  const {
    items = [],
    page,
    pages,
    total,
    request,
  } = useSelector(state => state.investment.list.data);
  const loading = useSelector(state => state.investment.list.loading);
  const error = useSelector(state => state.investment.list.error);
  const userExchanges = useSelector(userExchangesSelector);
  const { investment, name } = useSelector(state => state.botWizard);

  const { handleFilter, handleSorting, handlePaginate, filters } =
    useFiltersWithRequest(request, getInvestmentsList);

  const handleEmptyHide = () => {
    if (!emptyFilter) handleFilter({ balance: { gt: 0 } });
    if (emptyFilter) handleFilter({ balance: {} });
    setEmptyFilter(!emptyFilter);
  };

  const handleBotsCount = (value, item) => {
    if (item === selectedBots) {
      setSelectedBots("");
      return handleFilter({ botsCount: {} });
    }
    handleFilter({ botsCount: value });
  };

  const handleToNextStep = () => {
    if (investment.id) goNextStep();
  };

  const handleCancel = () => history.goBack();

  useLayoutEffect(() => {
    const queryString = qs.stringify({ filter: { balance: { gt: 0 } } });
    dispatch(getInvestmentsList(`?${queryString}`));
    dispatch(fetchCredentials());
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => setInitLoading(false), 2000);
  }, []);

  return (
    <>
      {error ? <Error error={error} /> : null}
      <InvestmentHeader
        name={name}
        initLoading={initLoading}
        loading={loading}
        credentialsList={credentialsList}
        credentialsLoading={credentialsLoading}
      />
      {!credentialsList?.items?.length && !credentialsLoading ? (
        <EmptyState
          title='You do not have any available credentials yet'
          description='Please connect at least one of your exchanges in order to have ability to create automatic trading bots for them.'
          credential
        />
      ) : null}
      {(initLoading && loading) || credentialsLoading ? (
        <InvestmentsFiltersPreloader />
      ) : (
        <InvestmentsFilters
          location={location}
          loading={loading}
          emptyFilter={emptyFilter}
          handleEmptyHide={handleEmptyHide}
          handleBotsCount={handleBotsCount}
          setSelectedBots={setSelectedBots}
          selectedBots={selectedBots}
          credentialsList={credentialsList}
          handleFilter={handleFilter}
          filterValues={filters}
        />
      )}
      {(!error && loading) || credentialsLoading ? (
        <InvestmentsPreloader />
      ) : (
        <div>
          <div className='d-flex flex-column justify-content-between mb-3'>
            <InvestmentTable
              items={items}
              active={investment?.id}
              loading={loading}
              request={request}
              filters={filters}
              selectedBots={selectedBots}
              setCredentialId={setCredentialId}
              credentialsList={credentialsList}
              handleSorting={handleSorting}
              handleEmptyHide={handleEmptyHide}
            />
          </div>
          <PaginationPanel
            activePage={page}
            pages={pages}
            totalItemsCount={total}
            onChange={page => handlePaginate(page)}
          />
          <div className='d-flex justify-content-between mx-4 mb-4'>
            <Button
              color='gray'
              outline
              onClick={handleCancel}
              className='pull-right'
            >
              {(!items.length && !loading && !userExchanges?.length) ||
              !credentialsList?.items?.length
                ? "Quit creation"
                : "Cancel"}
            </Button>
            <Button
              color='blue'
              disabled={!investment.id || !name || name?.length === 100}
              onClick={handleToNextStep}
              className='pull-right'
            >
              Next - Market
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Investments;
