import React from "react";
import styled from "styled-components";
import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";

import CurrentPlan from "./CurrentPlan";
import FeaturesTableHeader from "./FeaturesTableHeader";
import FeaturesTableRow from "./FeaturesTableRow";
import { FEATURES_LIST } from "../models/features";
import { CustomScrollbars } from "shared/ui";

const FeaturesTable = ({
  plans = [],
  paymentInterval,
  currentPlan,
  setPaymentInterval,
  hasTrial,
}) => {
  return (
    <StyledCard currentPlan={currentPlan}>
      {currentPlan ? (
        <StyledCardHeader>
          <CurrentPlan plans={plans} paymentInterval={paymentInterval} />
        </StyledCardHeader>
      ) : null}
      <CustomScrollbars
        autoHideTimeout={500}
        autoHideDuration={500}
        autoHeight
        autoHeightMax={Number.MAX_VALUE}
      >
        <CardBody className='p-0'>
          <FeaturesTableHeader
            paymentInterval={paymentInterval}
            currentPlan={currentPlan}
            plans={plans}
            setPaymentInterval={setPaymentInterval}
            hasTrial={hasTrial}
          />
          <div className='px-3'>
            {FEATURES_LIST.map(feature => (
              <FeaturesTableRow key={feature.name} feature={feature} />
            ))}
          </div>
        </CardBody>
      </CustomScrollbars>
      <StyledCardFooter>
        <p className='w-75 text-white-35'>
          For tailor-made plans or corporate solutions, please get in touch with
          our{" "}
          <a
            href='https://darkbot.io/contact-us'
            target='_blank'
            rel='noopener noreferrer'
            className='text-info hover-underline'
          >
            sales department
          </a>
          {". "}
          All costs are billed monthly. Annual plan discounts are available.
        </p>
      </StyledCardFooter>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-top: ${({ currentPlan }) => (currentPlan ? 0 : "26px")};

  &.card {
    border-radius: 16px !important;
    overflow: hidden;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  background-color: #2e3148 !important;
  padding: 1.5rem 2rem !important;
`;

const StyledCardFooter = styled(CardFooter)`
  padding: 48px 40px 32px !important;
`;

export default FeaturesTable;
