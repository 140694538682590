import {
  BID_ASK_SPREAD_PERCENT,
  MINIMUM_MARKET_TRADES,
  MINIMUM_MARKET_VOLUME,
  SELECT_ALL_MARKETS,
  PRICE_DIFFERENCE,
  MIN_MAX_CHANGE,
  TREND,
  PERIOD,
  EXCLUDED_MARKETS,
  SELECTED_MARKETS,
  RESET_BOT_WIZARD,
  SET_INVESTMENT,
  SET_BOT_NAME,
  SET_CANDLE_CONDITIONS,
  REMOVE_CREATED_CANDLE,
  EDIT_CANDLE_SETTING,
  SET_EDIT_CANDLE,
} from "./constants";

const initialState = {
  allCoins: false,
  coins: [],
  excludedCoins: [],
  minimumTrades: null,
  volume: null,
  spreadPercent: null,
  marketDirection: null,
  minimumDailyChange: null,
  maximumDailyChange: null,
  markPriceDifference: null,
  period: 30,
  investment: {},
  name: null,
  tradeSettings: {
    candleConditions: [],
  },
};

const botWizard = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_ALL_MARKETS:
      return {
        ...state,
        allCoins: payload,
      };

    case MINIMUM_MARKET_TRADES:
      return {
        ...state,
        minimumTrades: payload,
      };

    case MINIMUM_MARKET_VOLUME:
      return {
        ...state,
        volume: payload,
      };

    case BID_ASK_SPREAD_PERCENT:
      return {
        ...state,
        spreadPercent: payload,
      };

    case TREND:
      return {
        ...state,
        marketDirection: payload,
      };

    case MIN_MAX_CHANGE:
      return {
        ...state,
        minimumDailyChange: payload[0],
        maximumDailyChange: payload[1],
      };

    case PRICE_DIFFERENCE:
      return {
        ...state,
        markPriceDifference: payload,
      };

    case PERIOD:
      return {
        ...state,
        period: payload,
      };

    case EXCLUDED_MARKETS:
      return {
        ...state,
        excludedCoins: payload,
      };

    case SELECTED_MARKETS:
      return {
        ...state,
        coins: payload,
      };

    case SET_INVESTMENT:
      return {
        ...state,
        investment: payload,
      };

    case SET_BOT_NAME:
      return {
        ...state,
        name: payload,
      };

    case RESET_BOT_WIZARD:
      return {
        ...initialState,
        name: state.name || null,
        investment: state.investment,
      };

    case SET_CANDLE_CONDITIONS:
      return {
        ...state,
        tradeSettings: {
          ...state.tradeSettings,
          candleConditions: [...state.tradeSettings.candleConditions, payload],
        },
      };
    case SET_EDIT_CANDLE:
      return {
        ...state,
        tradeSettings: {
          ...state.tradeSettings,
          candleConditions: [
            ...state.tradeSettings.candleConditions,
            ...payload,
          ],
        },
      };

    case EDIT_CANDLE_SETTING:
      return {
        ...state,
        tradeSettings: {
          ...state.tradeSettings,
          candleConditions: state.tradeSettings.candleConditions.map(candle => {
            if (candle.id === payload.id) {
              return { ...candle, ...payload };
            }
            return candle;
          }),
        },
      };

    case REMOVE_CREATED_CANDLE:
      return {
        ...state,

        tradeSettings: {
          ...state.tradeSettings,
          candleConditions: [
            ...(state?.tradeSettings?.candleConditions || []),
          ]?.filter(candle => candle.id !== payload),
        },
      };

    default:
      return state;
  }
};

export default botWizard;
