import styled from "styled-components";

export const StyledCandleConditionWrap = styled.div`
  background: #2e3148;
  border-radius: 8px;
  padding: 12px 24px;
  cursor: pointer;

  .settings-indicator-icon,
  .trash-indicator-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    width: 24px;
    height: 24px;

    &:hover {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 4px;
    }
  }

  .settings-indicator-icon {
    :hover {
      svg {
        path {
          stroke: var(--white);
          stroke-opacity: 1;
        }
      }
    }
  }

  .trash-indicator-icon {
    margin-left: 10px;
    :hover {
      svg {
        path {
          stroke: var(--white);
          stroke-opacity: 0.75;
        }
      }
    }
  }
`;
