import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import { ReactComponent as ProfitableDeals } from "assets/icons/general/ProfitableDeals.svg";
import { ReactComponent as LossDeals } from "assets/icons/bot/LossDeals.svg";

const StatisticsDealsCount = ({
  profitCount,
  profitTitle,
  lossCount,
  lossTitle,
}) => {
  return (
    <div className='d-flex justify-content-between align-items-center mt-4 mb-4'>
      <div className='d-flex align-items-center'>
        <StatisticsDealsCount.Profitable profit={profitCount}>
          <ProfitableDeals />
        </StatisticsDealsCount.Profitable>
        <div className='ml-3'>
          <h3
            className={classNames("mb-0 font-weight-500", {
              "text-white-35": profitCount === 0,
              "text-white": profitCount !== 0,
            })}
          >
            {profitCount}
          </h3>
          <p className='mb-0 text-white-35 font-xs'>{profitTitle}</p>
        </div>
      </div>
      <div className='d-flex align-items-center'>
        <div className='mr-3'>
          <h3
            className={classNames(
              "mb-0 font-weight-500 d-flex justify-content-end",
              {
                "text-white-35": lossCount === 0,
                "text-white": lossCount !== 0,
              },
            )}
          >
            {lossCount}
          </h3>
          <p className='mb-0 text-white-35 font-xs'>{lossTitle}</p>
        </div>
        <StatisticsDealsCount.Loss loss={lossCount}>
          <LossDeals />
        </StatisticsDealsCount.Loss>
      </div>
    </div>
  );
};

StatisticsDealsCount.Profitable = styled.div`
  background: ${props =>
    props.profit > 0 ? "rgba(63, 203, 176, 0.1)" : "rgba(255, 255, 255, 0.05)"};
  padding: 11px 8px;
  border-radius: 8px;
  svg {
    path {
      stroke: ${props =>
        props.profit > 0 ? "#3FCBB0" : "rgba(255, 255, 255, 0.35)"};
    }
  }
`;

StatisticsDealsCount.Loss = styled.div`
  background: ${props =>
    props.loss > 0 ? "rgba(237, 95, 95, 0.1)" : "rgba(255, 255, 255, 0.05)"};
  padding: 11px 8px;
  border-radius: 8px;
  svg {
    path {
      stroke: ${props =>
        props.loss > 0 ? "#ED5F5F" : "rgba(255, 255, 255, 0.35)"};
    }
  }
`;

export default StatisticsDealsCount;
