import { createSelector } from "reselect";
import _ from "lodash";

const assets = state => state.exchanges.assets;
export const exchangesSelector = state => state.exchanges.allExchanges?.items;
export const userExchangesSelector = state =>
  state.exchanges.userExchanges?.items;
export const exchangeAssetsSelector = type =>
  createSelector([assets], (assets = {}) => {
    if (!_.isEmpty(assets)) {
      return assets[type];
    }
    return [];
  });
export const currentExchange = state => state.exchanges.ticker;
