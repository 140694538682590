import React, { useRef } from "react";
import Select from "react-select";
import styled from "styled-components";

import Option from "./Option";
import ValueContainer from "./ValueContainer";
import DropdownIndicator from "./DropdownIndicator";
import MenuList from "./MenuList";
import LoadingIndicator from "./LoadingIndicator";
import ClearIndicator from "./ClearIndicator";
import { customStyles } from "./styles";
import OptionBigSize from "./OptionBigSize";

const SelectInput = ({
  options = [],
  isDark = true,
  isBigOption,
  hasError,
  isMulti,
  value,
  isSearchable = true,
  onChange,
  isLoading,
  styles,
  disabledOptionIcon,
  optionHeight,
  isVirtualized,
  className,
  ...props
}) => {
  const selectRef = useRef(null);
  const wrapperRef = useRef(null);

  const getRestOptionsAmount = () => {
    if (!value?.length || value?.length === options.length) return "";
    return value?.length - 1;
  };

  const OptionComponent = isBigOption ? OptionBigSize : Option;

  return (
    <div className={className} ref={wrapperRef}>
      <StyledSelect
        ref={selectRef}
        isLoading={isLoading}
        isMulti={isMulti}
        options={options}
        value={value}
        onChange={onChange}
        isSearchable={options.length > 5 && isSearchable}
        onMenuClose={() => selectRef.current.blur()}
        restOptions={getRestOptionsAmount()}
        components={{
          DropdownIndicator,
          MenuList: ({ ...props }) => (
            <MenuList
              {...props}
              wrapperRef={wrapperRef}
              optionHeight={optionHeight}
            />
          ),
          ValueContainer,
          LoadingIndicator,
          ClearIndicator,
        }}
        isVirtualized={isVirtualized}
        formatOptionLabel={props => {
          return (
            <OptionComponent
              isMulti={isMulti}
              selectedValues={value}
              isDark={isDark}
              isDisabled={props.isDisabled}
              menuIsOpen={selectRef.current?.props?.menuIsOpen}
              disabledOptionIcon={disabledOptionIcon}
              isLoading={isLoading}
              {...props}
            />
          );
        }}
        classNamePrefix='react-select'
        styles={styles || customStyles}
        isDark={isDark}
        hasError={hasError}
        hideSelectedOptions={false}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: isDark ? "#2A2C42" : "rgba(29, 30, 42, 0.06)",
            primary: isDark ? "var(--info)" : "rgba(29, 30, 42, 0.06)",
            neutral0: isDark ? "#2E3148" : "rgba(29, 30, 42, 0.06)",
            neutral5: isDark ? "#2E3148" : "rgba(29, 30, 42, 0.06)",
          },
        })}
        {...props}
      />
    </div>
  );
};

const StyledSelect = styled(Select)`
  input {
    color: ${props => (props.isDark ? "#FFFFFF" : "#333333")} !important;
  }

  .react-select__control {
    & input[type="checkbox"] {
      display: none;
    }
  }

  .react-select__multi-value__remove,
  .react-select__multi-value {
    display: none;
  }

  .react-select__multi-value {
    position: relative;
  }

  .react-select__multi-value:first-child {
    display: block;
  }

  .react-select__option:last-child {
    margin-bottom: 15px;
  }

  .react-select__option:hover {
    background: ${props => (!props.isDark ? "#EEEEEE" : "")};
  }

  & svg path {
    stroke: ${props => (props.isDark ? "#F2F2F2" : "#1D1E2A")};
  }

  .react-select__multi-value {
    & .input-select {
      padding-right: 0.25rem !important;
    }
  }

  .react-select__menu-list > div,
  .react-select__menu-list > div > div {
    max-height: 200px !important;
    filter: ${props =>
      props.isDark ? "drop-shadow(0 0.5rem 0.5rem #222435)" : ""};
  }
`;

export default SelectInput;
