import { combineReducers } from "redux";

import withDataLoading from "store/withDataLoading";

export default combineReducers({
  item: withDataLoading(null, "AGREEMENT"),
  items: withDataLoading(null, "AGREEMENTS"),
  request: withDataLoading(null, "ROUNDS_TRADE_REQUESTS"),
  requestsOrders: withDataLoading(null, "TRADE_REQUEST_ORDERS"),
  requestsTrades: withDataLoading(null, "TRADE_REQUEST_TRADES"),
  agreementsMarkets: withDataLoading(null, "AGREEMENTS_MARKETS"),
});
