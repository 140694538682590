import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

import {
  formatterToNull,
  formatValue,
  setColorClass,
} from "helpers/valuesFormatters";
import {
  enableMarket,
  disableMarket,
  createBotDeal,
} from "store/deals/actions";
import { addSnackBar } from "store/snackbar/actions";
import { Checkbox } from "shared/ui";
import { Button } from "shared/ui/buttons";

const ActiveMarketsTableRow = ({ item, isDisabled, setIsDisabled }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  const showNotification = (isError, message) => {
    const snackbarType = isError ? "danger" : "success";
    dispatch(addSnackBar(snackbarType, message));
    setIsLoading(false);
    setIsDisabled(false);
  };

  const handleToggle = async () => {
    const res = item?.isExcluded
      ? await dispatch(enableMarket(id, item?.market?.base))
      : await dispatch(disableMarket(id, item?.market?.base));
    showNotification(res.errors, res.message);
  };

  const handleForceRun = async () => {
    setIsDisabled(true);
    setIsLoading(true);
    const res = await dispatch(
      createBotDeal(id, item?.market?.quote, item?.market?.base),
    );
    showNotification(res.errors, res.message);
  };

  const getRunnigDealCellValue = () => {
    if (!item.deal && item?.isExcluded)
      return <p className='text-white-35'>No</p>;
    if (!item.deal) {
      return (
        <Button
          color='success'
          outline
          loading={isDisabled && isLoading}
          disabled={isDisabled && isLoading}
          onClick={handleForceRun}
        >
          Run
        </Button>
      );
    }
    if (item.deal?.status === "running" || item.deal?.status === "ready") {
      return (
        <Link
          to={`/deals/${item.deal.id}`}
          className='className font-sm text-info'
        >
          View
        </Link>
      );
    }
  };

  return (
    <ActiveMarketsTableRow.Row excluded={item?.isExcluded}>
      <td>
        <div className='d-flex align-items-center text-nowrap'>
          <Checkbox
            withMinus={item?.isExcluded}
            checked={!item?.isExcluded}
            onChange={handleToggle}
          />
          <p className='mb-0 ml-2 text-white font-weight-500'>
            {item.market.base}
          </p>
          <p className='mb-0 ml-1 text-white-35 font-weight-300'>
            {`/ ${item.market.quote}`}
          </p>
        </div>
      </td>

      <td>
        <p className='mb-0 text-white font-weight-500'>
          {formatterToNull(item.successDeals)}
        </p>
      </td>

      <td>
        <p className='mb-0 text-white font-weight-500'>
          {formatterToNull(item.losingDeals)}
        </p>
      </td>

      <td>
        <div className='d-flex align-items-center text-nowrap'>
          <p
            className={`mb-0 font-weight-500 ${setColorClass(item.totalProfit, "text-white-35")}`}
          >
            {formatValue(item.totalProfit)}
          </p>
          <p className='mb-0 ml-1 text-white-35 font-weight-300'>
            {item.market.quote}
          </p>
        </div>
      </td>

      <td>
        <div className='d-flex align-items-center text-nowrap'>
          <p
            className={`mb-0 font-weight-500 ${setColorClass(item.dailyReturn, "text-white-35")}`}
          >
            {formatValue(item.dailyReturn)}
          </p>
          <p className='mb-0 ml-1 text-white-35 font-weight-300'>
            {item.market.quote}
          </p>
        </div>
      </td>

      <td>
        <div className='d-flex justify-content-center'>
          {getRunnigDealCellValue()}
        </div>
      </td>
    </ActiveMarketsTableRow.Row>
  );
};

ActiveMarketsTableRow.Row = styled.tr`
  background: ${({ isExcluded }) =>
    isExcluded ? "#222435" : "inherit"} !important;

  td:not(:first-of-type) {
    opacity: ${({ isExcluded }) => (isExcluded ? "0.3" : "1")};
  }
`;

export default ActiveMarketsTableRow;
