import React from "react";
import styled from "styled-components";
import classNames from "classnames";

import Error from "shared/ui/state/Error";
import Loading from "../state/Loading";

const ChangeStrategyList = ({
  items = [],
  loading,
  error,
  onHandleSelectedStrategy,
  selectedStrategy,
  hasMore,
}) => {
  if (loading && !hasMore) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <ul className='m-0 p-0'>
      {items.map(strategy => (
        <ChangeStrategyList.StrategyItem
          key={strategy.uuid}
          onClick={() => onHandleSelectedStrategy(strategy)}
          className={classNames("default", {
            selected: selectedStrategy.uuid === strategy.uuid,
          })}
        >
          <div className='d-flex flex-column'>
            <h4>{strategy.name}</h4>
            <p className='text-white-35'>{strategy.comment}</p>
          </div>
        </ChangeStrategyList.StrategyItem>
      ))}
    </ul>
  );
};

ChangeStrategyList.StrategyItem = styled.li`
  padding: 15px;
  list-style: none;
  border-radius: 3px;
  cursor: pointer
  margin-bottom: 10px;

  &.default {
    background: #1f2134;
    border: none;
  }

  &.selected {
    background: #435a91;
    border: 2px solid #1d8cf8;
  }

  &:hover {
    background: #435a91;
  }
`;

export default ChangeStrategyList;
