import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { ReactComponent as Square } from "assets/icons/bot/minuscirlce.svg";
import { ReactComponent as Tick } from "assets/icons/checkbox/tick-circle.svg";
import { ReactComponent as Clear } from "assets/icons/remove-x.svg";
import {
  setExcludedMarkets,
  setSelectedMarkets,
} from "store/bots/wizard/actions";
import { Icon } from "shared/ui";
import { Button } from "shared/ui/buttons";

const SelectedMarkets = ({
  coins,
  excludedCoins,
  allCoins,
  handleInclude,
  handleDeselect,
  asset,
  isEdited,
}) => {
  const dispatch = useDispatch();

  const clearMarkets = () => {
    if (allCoins) return dispatch(setExcludedMarkets([]));
    dispatch(setSelectedMarkets([]));
  };

  if (!coins?.length && !excludedCoins?.length) return;

  return (
    <div className='p-4'>
      <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
        <div>
          <div className='d-flex align-items-center'>
            {allCoins ? <Square /> : <Tick />}
            <h3 className='mb-0 text-white ml-2 font-weight-500'>
              {allCoins ? "Excluded markets" : "Selected markets"}
            </h3>
          </div>
          {allCoins ? (
            <p className='mb-0 mt-2 text-white-75 font-sm'>
              {
                "These are the markets you've marked as excluded. This means that they"
              }
              <span className='font-weight-500 text-white mx-1'>
                will not be used
              </span>{" "}
              for automated bot trading.
            </p>
          ) : (
            <p className='mb-0 mt-2 text-white-75 font-sm'>
              Selected markets will be used for automated bot trading, but note
              that choosing multiple markets will make you unable to use the
              genetic algorithm in this bot.
            </p>
          )}
        </div>

        {isEdited && (
          <Button
            onClick={clearMarkets}
            outline
            type='button'
            className='ml-md-4 ml-0 mt-3 mt-md-0'
          >
            Clear all
          </Button>
        )}
      </div>
      {allCoins && excludedCoins.length ? (
        <div className='d-flex flex-wrap mt-4' style={{ gap: "8px" }}>
          {excludedCoins.map(item => {
            return (
              <SelectedMarkets.SelectedBadge
                key={item}
                onClick={() => isEdited && handleInclude(item)}
                isEdited={isEdited}
              >
                <Icon size='thumb' code={item} />
                <p className='mb-0 text-white ml-2 font-weight-500'>
                  {item}{" "}
                  <span className='mb-0 text-white-35 font-weight-300'>
                    {`/ ${asset}`}
                  </span>
                </p>
                {isEdited && (
                  <div className='position-absolute trash'>
                    <Clear />
                  </div>
                )}
              </SelectedMarkets.SelectedBadge>
            );
          })}
        </div>
      ) : null}
      {!allCoins && coins.length ? (
        <div className='d-flex flex-wrap mt-4' style={{ gap: "8px" }}>
          {coins.map(item => {
            return (
              <SelectedMarkets.SelectedBadge
                key={item}
                onClick={() => isEdited && handleDeselect(item)}
                isEdited={isEdited}
              >
                <Icon size='thumb' code={item} />
                <p className='mb-0 text-white ml-2 font-weight-500'>
                  {item}{" "}
                  <span className='mb-0 text-white-35 font-weight-300'>
                    {`/ ${asset}`}
                  </span>
                </p>
                {isEdited && (
                  <div className='position-absolute trash'>
                    <Clear />
                  </div>
                )}
              </SelectedMarkets.SelectedBadge>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

SelectedMarkets.SelectedBadge = styled.div`
  display: flex;
  justify-content: center;
  background: #2e3148;
  border-radius: 100px;
  padding: 4px 8px 4px 4px;
  width: fit-content;
  cursor: pointer;
  position: relative;

  .trash {
    display: none;

    svg {
      path {
        fill: #9c9fbf;
        fill-opacity: 1;
      }
    }
  }

  &:hover {
    p,
    svg,
    img {
      opacity: ${({ isEdited }) => (isEdited ? "0.2" : "1")};
    }

    .trash {
      display: flex;
      svg {
        opacity: 1;
      }
    }
  }
`;

export default SelectedMarkets;
