import { Badge } from "reactstrap";
import styled from "styled-components";

export const StyledBadgeBuySell = styled(Badge)`
  &.badge {
    background: ${({ type }) =>
      type === "buy" || type === "BUY" ? "#3FCBB0" : "#ED5F5F"};
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    margin: 0 24px 0 0;
    line-height: 16px;
    display: block;
  }
`;
