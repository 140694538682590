import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const InvestmentTableTooltips = ({ index }) => {
  const [isShow, setIsShow] = useState(false);
  const toggleShow = () => setIsShow(!isShow);

  return (
    <Tooltip
      target={`bots-${index}`}
      placement='top-end'
      isOpen={isShow}
      toggle={toggleShow}
    >
      Amount of bots in this investment
    </Tooltip>
  );
};

export default InvestmentTableTooltips;
