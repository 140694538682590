import React from "react";
import { Card } from "reactstrap";

import DoughnutChart from "shared/ui/DoughnutChart";
import ReferralRow from "./ReferralRow";
import { REFERRAL_CHART_COLORS } from "shared/models/user/refferalSystem";

const ReferralDoughnutChart = ({
  chartData,
  setHoverCurrentBg,
  hoverCurrentBg,
  hoverredElemId,
  handleMouseEnter,
  handleMouseLeave,
  total,
}) => {
  if (chartData) {
    return (
      <Card className='mt-4'>
        <div className='mt-4 mx-4 mb-5 d-flex justify-content-between align-items-center'>
          <h4 className='font-weight-500 text-white mb-0'>Total referrals</h4>
          <h1 className='text-info m-0'>{total}</h1>
        </div>
        <div className='d-flex flex-column'>
          <div className='d-flex align-self-center'>
            <DoughnutChart
              pieData={chartData}
              setHoverCurrentBg={setHoverCurrentBg}
              index={hoverredElemId}
              key={hoverredElemId}
              backgroundColors={REFERRAL_CHART_COLORS}
            />
          </div>
          <div className='d-flex'>
            <ReferralRow
              data={chartData}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              hoverCurrentBg={hoverCurrentBg}
            />
          </div>
        </div>
      </Card>
    );
  }
};

export default ReferralDoughnutChart;
