import React from "react";
import styled from "styled-components";
import moment from "moment";
import { ReactComponent as IconInfo } from "assets/icons/general/logsInfo.svg";
import { ReactComponent as IconSuccess } from "assets/icons/general/logsSuccess.svg";
import { ReactComponent as IconError } from "assets/icons/general/logsError.svg";
import { ReactComponent as IconWarning } from "assets/icons/general/logsWarning.svg";

const iconTypes = {
  deal: {
    Icon: IconInfo,
  },
  trade_request: {
    Icon: IconSuccess,
  },
  order: {
    Icon: IconError,
  },
  trade: {
    Icon: IconWarning,
  },
};

const iconLevels = {
  info: "rgba(47, 128, 237, 1)",
  success: "rgba(63, 203, 176, 1)",
  error: "rgba(237, 95, 95, 1)",
  warning: "rgba(255, 141, 114, 1)",
};

const TechnicalLog = ({ log }) => {
  const renderLevel = () => {
    const Icon = iconTypes[log.type]?.Icon;
    return (
      <TechnicalLog.IconWrapper color={iconLevels[log.level]}>
        {Icon ? <Icon /> : <IconInfo />}
      </TechnicalLog.IconWrapper>
    );
  };

  return (
    <div className='d-flex flex-column flex-lg-row align-content-center'>
      <div className='d-flex align-items-center'>
        <TechnicalLog.Time className='font-sm'>
          {moment(log.createdAt).format("DD.MM.YYYY HH:mm:ss")}
        </TechnicalLog.Time>
        {renderLevel()}
      </div>

      <p className='font-sm mb-0 text-white'>{log.message}</p>
    </div>
  );
};

TechnicalLog.Time = styled.p`
  margin-bottom: 0 !important;
  color: rgba(156, 159, 191, 1) !important;
  white-space: nowrap;
`;

TechnicalLog.IconWrapper = styled.div`
  margin: 0 24px 0 8px !important;

  svg {
    path {
      stroke: ${props => props.color} !important;
    }
    circle {
      stroke: ${props => props.color} !important;
    }
  }
`;

export default TechnicalLog;
