import React from "react";
import { Table } from "reactstrap";
import moment from "moment";
import styled from "styled-components";

import { ReactComponent as SortUp } from "assets/icons/general/ArrowUp.svg";
import { ReactComponent as SortDown } from "assets/icons/general/ArrowDown.svg";
import EmptyTableOrderModal from "./EmptyTableOrderModal";
import { formatterToNull } from "helpers/valuesFormatters";
import { CustomScrollbars, Loading } from "shared/ui";

const LABELS = {
  tradeId: "ID",
  matchRole: "Role",
  tradeType: "Type",
  amount: "Amount",
  quantity: "Total",
  feePercent: "Fee",
  feeAmount: "Fee amount",
  createdAt: "Time",
};

const TableOrderModal = ({
  trades,
  sort,
  handleSort,
  sortOrder,
  tradesLoading,
}) => {
  const handleClickSort = item => () => handleSort(item);

  if (tradesLoading) {
    return <Loading />;
  }

  return trades?.items?.length > 0 ? (
    <CustomScrollbars
      autoHideTimeout={500}
      autoHideDuration={500}
      autoHeight='true'
      autoHeightMax='500px'
    >
      <Table className='mb-0'>
        <TableOrderModal.Thead>
          <tr>
            {Object.keys(LABELS).map(item => (
              <TableOrderModal.Label key={item} onClick={handleClickSort(item)}>
                <div className='d-flex align-items-center'>
                  <p className='cursor-pointer text-white-35 mb-0 font-weight-300 font-sm'>
                    {LABELS[item]}
                  </p>
                  <TableOrderModal.Sort>
                    <div className='d-flex justify-content-between ml-2'>
                      <TableOrderModal.SortUp
                        sort={sort}
                        by={item}
                        order={sortOrder}
                      >
                        <SortUp />
                      </TableOrderModal.SortUp>
                      <TableOrderModal.SortDown
                        sort={sort}
                        by={item}
                        order={sortOrder}
                      >
                        <SortDown />
                      </TableOrderModal.SortDown>
                    </div>
                  </TableOrderModal.Sort>
                </div>
              </TableOrderModal.Label>
            ))}
          </tr>
        </TableOrderModal.Thead>
        <TableOrderModal.Tbody className='font-sm'>
          {trades.items?.map(trade => {
            return (
              <tr key={trade.tradeId}>
                <td>
                  <TableOrderModal.Currency>
                    {trade.tradeId}
                  </TableOrderModal.Currency>
                </td>
                <td>
                  <p className='mb-0 text-white'>{trade.matchRole || "-"}</p>
                </td>
                <td>
                  <p className='mb-0 text-white'>{trade.tradeType || "-"}</p>
                </td>
                <td>
                  <div className='d-flex align-right'>
                    <TableOrderModal.Amount amount={trade.amount.amount}>
                      {formatterToNull(trade.amount.amount)}
                    </TableOrderModal.Amount>
                    <TableOrderModal.Currency>
                      {trade.amount.currency}
                    </TableOrderModal.Currency>
                  </div>
                </td>
                <td>
                  <div className='d-flex align-right'>
                    <TableOrderModal.Amount amount={trade.quantity.amount}>
                      {formatterToNull(trade.quantity.amount)}
                    </TableOrderModal.Amount>
                    <TableOrderModal.Currency>
                      {trade.quantity.currency}
                    </TableOrderModal.Currency>
                  </div>
                </td>
                <td>
                  <div className='d-flex align-right'>
                    <TableOrderModal.Amount amount={trade.fee.percent}>
                      {formatterToNull(trade.fee.percent)}
                    </TableOrderModal.Amount>
                    <TableOrderModal.Currency>%</TableOrderModal.Currency>
                  </div>
                </td>
                <td>
                  <div className='d-flex align-right'>
                    <TableOrderModal.Amount amount={trade.fee.amount}>
                      {formatterToNull(trade.fee.amount)}
                    </TableOrderModal.Amount>
                    <TableOrderModal.Currency>
                      {trade.fee.currency}
                    </TableOrderModal.Currency>
                  </div>
                </td>
                <td>
                  <div className='d-flex align-right'>
                    <TableOrderModal.Amount amount={1}>
                      {moment(trade.createdAt).format("DD.MM.YY")}
                    </TableOrderModal.Amount>
                    <TableOrderModal.Currency>
                      {moment(trade.createdAt).format("HH:mm")}
                    </TableOrderModal.Currency>
                  </div>
                </td>
              </tr>
            );
          })}
        </TableOrderModal.Tbody>
      </Table>
    </CustomScrollbars>
  ) : (
    <EmptyTableOrderModal />
  );
};

TableOrderModal.Label = styled.th`
  white-space: nowrap;

  p {
    :hover {
      color: rgba(255, 255, 255, 0.75) !important;
    }
  }

  &:first-child {
    padding-left: 24px !important;
  }

  &:last-child {
    padding-right: 24px !important;
  }
`;

TableOrderModal.Thead = styled.thead`
  background: #2a2c42 !important;
`;

TableOrderModal.Tbody = styled.tbody`
  tr {
    background: var(--dark-slate);
    border-bottom: 1px solid #2e3148;

    &:hover {
      background: #2a2c41;
    }

    td {
      white-space: nowrap;

      &:first-child {
        padding-left: 24px !important;
      }
      &:last-child {
        padding-right: 24px !important;
      }
    }
  }
`;

TableOrderModal.Amount = styled.p`
  margin-bottom: 0;
  color: ${props =>
    props.amount > 0
      ? "rgba(255, 255, 255)"
      : "rgba(255, 255, 255, 0.35)"} !important;
  font-weight: 500;
`;

TableOrderModal.Currency = styled.p`
  margin: 0 0 0 4px;
  color: rgba(255, 255, 255, 0.35) !important;
  font-weight: 300;

  &:first-child {
    margin-left: 0;
  }
`;

TableOrderModal.Sort = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

TableOrderModal.SortUp = styled.div`
  opacity: ${props =>
    props.sort && props.sort === props.by && props.order === "asc"
      ? "1"
      : ".1"};
`;

TableOrderModal.SortDown = styled.div`
  opacity: ${props =>
    props.sort && props.sort === props.by && props.order === "desc"
      ? "1"
      : ".1"};
`;

export default TableOrderModal;
