import { Card, CardBody } from "reactstrap";
import styled from "styled-components";

export const TradeRequestCard = styled(Card)`
  border-radius: 8px !important;
  margin-bottom: 8px !important;
`;

export const TradeRequestBody = styled(CardBody)`
  background: #2e3148;
  border-radius: 0 0 8px 8px !important;

  &:hover {
    cursor: pointer;
    background: var(--dusk-steel);
  }
`;

export const TradeRequestStatus = styled.span`
  background: rgba(255, 255, 255, 0.05);
  font-weight: 500;
  font-size: 14px;
  border-radius: 6px;
  padding: 3.5px 10px;
`;

export const TradeRequestIcon = styled.div`
  cursor: pointer;
  margin-bottom: 2px;

  svg {
    &:focus,
    &:active {
      outline: none;
    }
  }
`;
