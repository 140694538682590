import React from "react";
import styled from "styled-components";
import { ModalBody, Modal } from "reactstrap";

import { indicatorsConfiguration } from "views/strategies/wizard/indicatorsConfiguration";
import TemplateDetailsMartingale from "../TemplateDetailsMartingale";
import { PivotPoints } from "shared/ui";
import { Button } from "shared/ui/buttons";
import BotIndicators from "features/widgets/TradeSettingsWidget/BotIndicators";

const indicatorName = indicatorsConfiguration.filter(({ value }) => value);

const TemplateDetailsModal = ({
  modalIsOpen,
  onSetModalIsOpen,
  tradeSettings = {},
  name,
  description,
}) => {
  return (
    <TemplateDetailsModalWrap
      isOpen={modalIsOpen}
      toggle={() => onSetModalIsOpen(!modalIsOpen)}
      modalClassName='modal-black'
    >
      <ModalBody className='p-4'>
        <div className='d-flex justify-content-between'>
          <p className='h1 m-0'>{name}</p>
          <Button color='gray' cancel onClick={() => onSetModalIsOpen(false)} />
        </div>
        <StyledDescription className='text-white-75'>
          {description}
        </StyledDescription>
        <hr className='my-sm' />
        <div className='d-flex justify-content-between'>
          <p className='text-white-75 font-sm m-0'>Initial volume</p>
          <p className='text-white font-weight-500 m-0'>
            {`${tradeSettings.volume}%`}
          </p>
        </div>
        <div className='d-flex justify-content-between'>
          <p className='text-white-75 font-sm m-0'>Minimum profit</p>
          <p className='text-white font-weight-500 m-0'>
            {`${tradeSettings.profit}%`}
          </p>
        </div>
        <div className='d-flex justify-content-between'>
          <p className='text-white-75 font-sm m-0'>Stop loss</p>
          <p className='text-white font-weight-500 m-0'>
            {`${tradeSettings.stopLossPercent}%`}
          </p>
        </div>
        <div className='d-flex justify-content-between'>
          <p className='text-white-75 font-sm m-0'>Execution Type</p>
          <p className='text-white font-weight-500 m-0'>
            {tradeSettings.executionType === "market" && "Market"}
            {tradeSettings.executionType === "aggressive" && "Aggressive limit"}
            {tradeSettings.executionType === "passive" && "Passive limit"}
          </p>
        </div>
        <div className='d-flex justify-content-between'>
          <p className='text-white-75 font-sm m-0'>Trailing Buy</p>
          <p className='text-white font-weight-500 m-0'>
            {`${tradeSettings.trailingBuy}%`}
          </p>
        </div>
        <div className='d-flex justify-content-between mb-4'>
          <p className='text-white-75 font-sm m-0'>Trailing Sell</p>
          <p className='text-white font-weight-500 m-0'>
            {`${tradeSettings.trailingSell}%`}
          </p>
        </div>
        {tradeSettings.martingale ? (
          <TemplateDetailsMartingale tradeSettings={tradeSettings} />
        ) : null}
        {tradeSettings.pivotPoint?.valid ? (
          <PivotPoints data={tradeSettings.pivotPoint} />
        ) : null}
        <BotIndicators
          indicators={tradeSettings.indicators}
          indicatorName={indicatorName}
          minSellSignals={tradeSettings.trailingSell}
          minBuySignals={tradeSettings.trailingBuy}
        />
      </ModalBody>
    </TemplateDetailsModalWrap>
  );
};

const TemplateDetailsModalWrap = styled(Modal)`
  .modal-content {
    border-radius: 1rem !important;
  }
`;

const StyledDescription = styled.p`
  margin: 0.75rem 0 2rem;
`;

export default TemplateDetailsModal;
