import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const CredentialWidgetPreload = () => {
  return (
    <Card data-testid='preloader'>
      <CardHeader style={{ padding: "24px", background: "#2E3148" }}>
        <div className='d-flex align-items-center'>
          <SkeletonPreloader circle={true} height={41} width={41} />
          <div className='d-flex flex-column ml-3'>
            <SkeletonPreloader height={20} width={110} />
            <SkeletonPreloader height={12} width={234} className='mt-2' />
          </div>
        </div>
      </CardHeader>
      <CardBody style={{ padding: "24px" }}>
        <div className='d-flex align-items-center justify-content-between mt-3 mb-2'>
          <div className='d-flex flex-column'>
            <SkeletonPreloader
              count={3}
              height={12}
              width={81}
              className='mt-2'
            />
          </div>
          <div className='d-flex flex-column'>
            <SkeletonPreloader
              count={3}
              height={12}
              width={121}
              className='mt-2'
            />
          </div>
        </div>
        <div className='d-flex align-items-center mt-4'>
          <SkeletonPreloader circle={true} height={20} width={20} />
          <SkeletonPreloader height={12} width={90} className='ml-2' />
        </div>
      </CardBody>
    </Card>
  );
};

export default CredentialWidgetPreload;
