import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import PropTypes from "prop-types";

import { formatterToNull } from "helpers/valuesFormatters";
import SignalDetailsWidgetPreload from "../preloaders/SignalDetailsWidgetPreload";
import { ReactComponent as AirDrop } from "assets/icons/signal/airdrop.svg";

const SignalDetails = ({ loading, openFrom, openTo, stopLoss }) => {
  if (loading) return <SignalDetailsWidgetPreload />;
  return (
    <Card className='p-4'>
      <CardHeader className='p-0'>
        <div className='d-flex flex-row align-items-center '>
          <AirDrop width='20px' height='20px' />
          <p className='ml-2 mb-0 text-white font-weight-500'>Signal details</p>
        </div>
      </CardHeader>
      <CardBody className='p-0 mt-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <p className='font-sm font-weight-400 text-white-75 mb-0'>
            Open From
          </p>
          <p className='mb-0 font-sm font-weight-500 text-white'>
            {formatterToNull(openFrom)}
          </p>
        </div>
        <div className='d-flex justify-content-between align-items-center my-1'>
          <p className='font-sm font-weight-400 text-white-75 mb-0'>Open To</p>
          <p className='mb-0 font-sm font-weight-500 text-white'>
            {formatterToNull(openTo)}
          </p>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <p className='font-sm font-weight-400 text-white-75 mb-0'>
            Stop Loss
          </p>
          <p className='mb-0 font-sm font-weight-500 text-white'>
            {formatterToNull(stopLoss)}
          </p>
        </div>
      </CardBody>
    </Card>
  );
};

SignalDetails.propType = {
  loading: PropTypes.bool,
  openFrom: PropTypes.string,
  openTo: PropTypes.string,
  stopLoss: PropTypes.string,
};

export default SignalDetails;
