import React from "react";
import styled from "styled-components";

import BotsTableRow from "./BotsTableRow";
import SortTableHead from "../SortTableHead";
import { MARKET_BOTS_TABLE } from "models/coin/enum/tables";
import { CustomScrollbars } from "shared/ui";
import { StyledTable } from "shared/styled/StyledTable";

const BotsTable = ({ items, id, sort, handleSorting }) => {
  return (
    <CustomScrollbars
      autoHideTimeout={500}
      autoHideDuration={500}
      autoHeight
      autoHeightMax={Number.MAX_VALUE}
    >
      <StyledTable className='mb-0'>
        <StyledSortTableHead
          handleSorting={handleSorting}
          sort={sort}
          theadItems={MARKET_BOTS_TABLE}
        />
        {items?.map((item, index) => (
          <BotsTableRow item={item} key={item.id} index={index} id={id} />
        ))}
      </StyledTable>
    </CustomScrollbars>
  );
};
const StyledSortTableHead = styled(SortTableHead)`
  & tr th:first-child > div {
    padding-left: 2rem;
  }
`;
export default BotsTable;
