import React from "react";

import { ReactComponent as TelegramIcon } from "assets/icons/social/telegram.svg";
import SocialButton from "./SocialButton";

const TelegramShareButton = ({ url, text, ...props }) => {
  const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;

  return (
    <SocialButton url={shareUrl} {...props}>
      <TelegramIcon width={18} height={18} />
    </SocialButton>
  );
};

export default TelegramShareButton;
