import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import Button from "shared/ui/buttons/Button";
import { addSnackBar } from "store/snackbar/actions";
import { subscribeChannel, unsubscribeChannel } from "store/channels/actions";
import { numberOrNull } from "../channelPropTypes";
import ChannelSetSettings from "../modals/ChannelSetSettings";

const ChannelSubscribe = ({
  totalSubscribers,
  subscribed,
  id,
  handleRefresh,
}) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggleModal = () => setIsModalOpen(prev => !prev);

  const handleSubscriptionToggle = async id => {
    try {
      setSubmitted(true);
      const res = await dispatch(unsubscribeChannel(id));
      dispatch(addSnackBar("success", res.message));
      if (!subscribed) setIsModalOpen(true);
    } catch (error) {
      dispatch(addSnackBar("error", error));
    } finally {
      setSubmitted(false);
    }
  };

  return (
    <>
      <ChannelSubscribe.Wrap className='d-flex flex-row-reverse flex-md-column align-items-end '>
        <ChannelSubscribe.Button
          color='blue'
          outline={subscribed}
          loading={submitted}
          disabled={submitted}
          onClick={e => {
            e.preventDefault();
            subscribed ? handleSubscriptionToggle(id) : handleToggleModal();
          }}
        >
          {subscribed ? "Unsubscribe" : "Subscribe"}
        </ChannelSubscribe.Button>
        <p className='mb-0 font-sm font-weight-400 text-white-35 overflow-hidden'>
          {`${totalSubscribers} Subscribers`}
        </p>
      </ChannelSubscribe.Wrap>
      {isModalOpen ? (
        <ChannelSetSettings
          isModalOpen={isModalOpen}
          handleToggleModal={handleToggleModal}
          channelId={id}
          handleRefresh={handleRefresh}
          isList
          onSubscribeChannel={subscribeChannel}
        />
      ) : null}
    </>
  );
};

ChannelSubscribe.Wrap = styled.div`
  gap: 0.75rem;
`;

ChannelSubscribe.Button = styled(Button)`
  width: 127px;

  &:hover {
    border: ${prop =>
      prop.disabled
        ? "1px solid var(--blue)"
        : "1px solid transparent"}!important;
  }
`;

ChannelSubscribe.propType = {
  totalSubscribers: PropTypes.number,
  subscribed: PropTypes.string,
  id: PropTypes.string,
  handleRefresh: PropTypes.func,
  maxximun: numberOrNull,
  volume: PropTypes.number,
  accept: PropTypes.bool,
};

export default ChannelSubscribe;
