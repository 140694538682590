export const empty = {
  botsList: {
    title: "You have no bots at the moment",
    description:
      "Please create bots first to have them displayed here and have an ability to work with your investment capital",
    buttonText: "Create new bot",
  },
  channelsList: {
    title: "Create Your First Channel",
    description:
      "Ready to share your trading signals? Create a channel for \n users to subscribe and enjoy your insights!",
    buttonText: "Create my channel",
  },
};
