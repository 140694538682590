import React from "react";
import styled from "styled-components";

import { ClipLoader } from "react-spinners";

const Loader = ({ isGoogle, isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className='d-flex h-100 justify-content-center align-items-center'>
      <div className='d-flex flex-column align-items-center'>
        <StyledText className='font-weight-700 text-center text-white mb-3'>
          Signing in
          <br />
          {isGoogle ? "with Google account" : null}
        </StyledText>
        <StyledWaitText className='text-white-75'>
          Please wait...
        </StyledWaitText>
        <ClipLoader size={32} color='#2f80ed' />
      </div>
    </div>
  );
};

const StyledText = styled.p`
  font-size: 2rem;
`;

const StyledWaitText = styled.p`
  margin-bottom: 2.5rem;
`;

export default Loader;
