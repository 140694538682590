import React from "react";
import styled from "styled-components";

import { handleHideBalance } from "shared/helpers/handleHideBalance";
import { Icon } from "shared/ui";

const ExchangeTableRowModal = ({ balanceHidden, item }) => {
  return (
    <ExchangeTableRowModal.Row>
      <td>
        <div className='d-flex align-items-center'>
          <Icon code={item.asset} size='botSize' />
          <p className='mb-0 ml-2 text-uppercase text-white font-weight-500'>
            {item.asset}
          </p>
        </div>
      </td>

      <td>
        <div className='d-flex align-items-center'>
          <p className='mb-0 text-white font-weight-500'>
            {handleHideBalance(balanceHidden, item.available.amount)}
          </p>
          <p className='mb-0 ml-1 text-white-35 font-weight-300'>
            {item.available.currency}
          </p>
        </div>
      </td>

      <td>
        <div className='d-flex align-items-center'>
          <p className='mb-0 text-white font-weight-500'>
            {handleHideBalance(balanceHidden, item.hold.amount)}
          </p>
          <p className='mb-0 ml-1 text-white-35 font-weight-300'>
            {item.hold.currency}
          </p>
        </div>
      </td>

      <td>
        <div className='d-flex align-items-center'>
          <p className='mb-0 text-white font-weight-500'>
            {handleHideBalance(balanceHidden, item.estimated.amount)}
          </p>
          <p className='mb-0 ml-1 text-white-35 font-weight-300'>
            {item.estimated.currency}
          </p>
        </div>
      </td>
    </ExchangeTableRowModal.Row>
  );
};

ExchangeTableRowModal.Row = styled.tr`
  background: var(--dark-slate) !important;
  cursor: pointer;
  height: 56px;
  box-shadow: 0 -1px #2e3148;

  &:hover {
    background: #2e3148 !important;
  }
`;

export default ExchangeTableRowModal;
