import React from "react";
import styled from "styled-components";
import { times } from "lodash";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import StyledTable from "./StyledTable";

const TablePreload = () => {
  return (
    <StyledTable className='mb-0' isPreloading>
      <thead>
        <tr>
          {times(5, e => (
            <th key={e} {...(e === 4 ? { className: "text-right" } : {})}>
              <SkeletonPreloader height={9} width={56} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {times(5, e => (
          <tr key={e}>
            <td>
              <div className='d-flex align-items-center'>
                <SkeletonPreloader
                  circle
                  height={24}
                  width={24}
                  className='mr-2 my-3'
                />
                <SkeletonPreloader height={12} width={35} className='my-3' />
              </div>
            </td>
            <td>
              <SkeletonPreloader height={14} width={130} className='my-3' />
            </td>
            <td>
              <SkeletonPreloader height={14} width={113} className='my-3' />
            </td>
            <td>
              <SkeletonPreloader height={14} width={42} className='my-3' />
            </td>
            <td className='text-right'>
              <BadgePreloader />
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

const BadgePreloader = styled.span`
  width: 58px;
  height: 23px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  display: inline-block;
`;

export default TablePreload;
