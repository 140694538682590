import React, { useEffect, useState } from "react";
import { Form, useFormikContext } from "formik";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import TradeSettingsFilters from "./TradeSettingsFilters";
import Martingale from "./Martingale";
import PivotPoints from "./PivotPoints";
import { ErrorAlert } from "shared/ui";
import Indicators from "./Indicators";
import CandleConditions from "./candleConditions";
import { useConfigurationContext } from "context/bot/ConfigurationContext";
import { useEditBotContext } from "context/bot/EditBotContext";

const TradeSettingsForm = ({
  botData,
  isEdit,
  currentStep,
  sellSignals,
  buySignals,
  botIndicators,
}) => {
  const [errorsLength, setErrorsLength] = useState(0);
  const location = useLocation();

  const isCreatePage = location.pathname.includes("create");
  const context = isCreatePage
    ? useConfigurationContext()
    : useEditBotContext();

  const { values, errors, setErrors } = useFormikContext();
  const { submitted, wizardMarkets, setIsOpenSettings } = context;

  useEffect(() => {
    if (!values?.martingale) {
      delete errors?.maxTradeRequests;
      delete errors?.martingalePercent;
      delete errors?.deviation;
    }
    setErrorsLength(Object.keys(errors).length);
  }, [values, errors, setErrorsLength]);

  useEffect(() => {
    if (currentStep !== "custom" && !isEdit) {
      setErrorsLength(0);
      Object.keys(errors).forEach(item => delete errors[item]);
    }
  }, [currentStep, isEdit, setErrorsLength, errors]);

  return (
    <Form>
      <TradeSettingsForm.Wrapper errors={errors}>
        <TradeSettingsFilters botData={botData} submitted={submitted} />

        <Martingale submitted={submitted} currentStep={currentStep} />

        <PivotPoints submitted={submitted} botData={botData} />

        <Indicators
          botIndicators={botIndicators}
          isEdit={isEdit}
          submitted={submitted}
          buySignals={buySignals}
          sellSignals={sellSignals}
          setIsOpenSettings={setIsOpenSettings}
        />

        <CandleConditions
          candlesData={wizardMarkets?.tradeSettings?.candleConditions}
          isEdit={isEdit}
          submitted={submitted}
          setIsOpenSettings={setIsOpenSettings}
        />

        <div className='d-flex justify-content-center px-3'>
          <ErrorAlert
            className='my-3'
            toggle={() => setErrors({})}
            isOpen={submitted && !!errorsLength}
          >
            {errors?.message ||
              "Error. Please check if you have filled up all fields correctly and try again"}
          </ErrorAlert>
        </div>
      </TradeSettingsForm.Wrapper>
    </Form>
  );
};

TradeSettingsForm.Wrapper = styled.div`
  .out-of {
    background: ${({ errors }) =>
      errors ? "rgba(108, 117, 125, 0.04)" : "#2E3148"} !important;
    border-radius: 0 6px 6px 0;
  }

  .form-error {
    color: var(--danger);
    margin-bottom: 12px !important;
  }
`;

export default TradeSettingsForm;
