import React from "react";

import GAconfigField from "./GAconfigField";

const GAConfigForm = ({ values = {}, handleSetConfigValues, errors = {} }) => {
  const getMutationProbabilityDesc = () => {
    if (!values.mutationProbability) return "";
    const amount = (values.generationSize * values.mutationProbability) / 100;
    return `~ ${Math.round(amount)} out of ${values.generationSize} trade settings genes will be mutated`;
  };

  const getPopulationFilterDesc = () => {
    if (!values.populationFilter) return "";
    const amount = (values.populationFilter * values.generationSize) / 100;
    return `${Math.round(amount)} best trade settings will be copied to the next generation`;
  };

  return (
    <>
      <GAconfigField
        onChange={value => handleSetConfigValues("generationSize", value)}
        value={values.generationSize}
        label='Generation size'
        style={{ marginBottom: "30px" }}
        error={errors.generationSize}
      />
      <GAconfigField
        onChange={value => handleSetConfigValues("mutationProbability", value)}
        value={values.mutationProbability}
        label='Gene mutation probability'
        unit='%'
        style={{ marginBottom: "30px" }}
        desc={getMutationProbabilityDesc()}
        error={errors.mutationProbability}
      />
      <GAconfigField
        onChange={value => handleSetConfigValues("populationFilter", value)}
        value={values.populationFilter}
        label='Population filter'
        unit='%'
        max={80}
        style={{ marginBottom: "40px" }}
        desc={getPopulationFilterDesc()}
        error={errors.populationFilter}
      />
    </>
  );
};

export default GAConfigForm;
