import styled from "styled-components";

const Hint = styled.span`
  background: #ffffff;
  color: rgba(29, 30, 42, 1) !important;
  font-size: 12px;
  font-weight: 300;
  padding: 0 0.5rem;
  border-radius: 20px;
  display: inline-block;
  line-height: 1.2;
`;

export default Hint;
