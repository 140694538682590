import React from "react";
import styled from "styled-components";

import { ReactComponent as Planet } from "assets/icons/general/EmptyAssetAllocation.svg";
import { ReactComponent as Empty } from "assets/icons/general/empty-state-planet2.svg";

const EmptyStates = ({ market }) => {
  return (
    <div className='d-flex h-100 flex-column justify-content-center align-items-center'>
      <EmptyStates.ImageWrapper>
        {market ? (
          <Planet width='93px' height='80px' className='mb-4' />
        ) : (
          <Empty width='83px' height='71px' className='mb-4' />
        )}
      </EmptyStates.ImageWrapper>
      <p className='font-weight-500 text-white mb-2'>
        {market
          ? "This bot is working hard..."
          : "Please select a market first"}
      </p>
      <p className='text-white-75 font-sm text-center'>
        {market
          ? "Wait for the genetic trade settings to be generated for your selected market, they will be displayed here shortly."
          : "Once you have completed that, you will be able to view the trade settings for the selected market, provided they have already been generated."}
      </p>
    </div>
  );
};

EmptyStates.ImageWrapper = styled.div`
  margin-top: 40px;
`;

export default EmptyStates;
