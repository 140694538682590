import styled from "styled-components";

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 2rem;
`;

export const Body = styled.div`
  padding: 2rem 2rem 124px;
  position: relative;
`;
