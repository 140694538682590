import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as InfoCircle } from "assets/icons/general/infocircle.svg";

const LineWithInform = ({
  infoMessage,
  currentPlan,
  linkText,
  redirectTo,
  color = "blue",
  className = "mt-4",
}) => {
  return (
    currentPlan?.type !== "premium" && (
      <LineWithInform.Error
        color={color}
        className={`d-flex flex-wrap flex-md-nowrap align-items-center py-3 px-4 ${className}`}
      >
        <span className='d-none d-md-inline-block '>
          <InfoCircle />
        </span>
        <LineWithInform.Description
          className={`"mb-2 mb-md-0 pl-3 text-white font-weight-400" ${redirectTo ? "w-75" : "w-100"}`}
        >
          {infoMessage}
        </LineWithInform.Description>

        {redirectTo ? (
          <Link
            className='font-weight-500 text-nowrap mx-auto mr-md-0 '
            to={redirectTo}
          >
            {linkText}
          </Link>
        ) : null}
      </LineWithInform.Error>
    )
  );
};

LineWithInform.Error = styled.div`
  background: ${props =>
    (props.color === "blue" && "rgba(47, 128, 237, 0.10)") ||
    (props.color === "gold" && "rgba(255, 196, 102, 0.15)")};
  border-radius: 0.5rem;

  svg {
    path {
      stroke: ${props =>
        (props.color === "blue" && "var(--info)") ||
        (props.color === "gold" && "#FFC466")};
    }
  }

  a {
    color: #ffc466 !important;
    text-decoration: underline;

    &:hover,
    &:active {
      text-decoration: none;
    }
  }
`;

LineWithInform.Description = styled.p`
  white-space: pre-line;
`;

export default LineWithInform;
