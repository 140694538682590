import * as Yup from "yup";

export const SCHEMA_CREATE_CHANNEL = ({
  fieldName,
  fieldDescription,
  fieldTelegram,
  fieldFile,
}) =>
  Yup.object().shape({
    name: Yup.string()
      .required(fieldName.errors.requiredName)
      .max(50, fieldName.errors.maxName),
    description: Yup.string()
      .required(fieldDescription.errors.requiredDescription)
      .max(4800, fieldDescription.errors.maxDescription),
    telegram: Yup.string().matches(
      /^(https:\/\/t\.me\/[a-zA-Z0-9_]{5,32}|@[a-zA-Z0-9_]{5,32}|[a-zA-Z0-9_]{5,32})$/,
      fieldTelegram.errors.invalidTelegram,
    ),
    file: Yup.mixed()
      .required(fieldFile.errors.requiredFile)
      .test(
        "fileSize",
        fieldFile.errors.maxFileSize,
        value => value && value.size <= 1048576,
      )
      .test("fileFormat", fieldFile.errors.maxFileFormat, value => {
        if (!value) return true;
        const supportedFormats = [...fieldFile.supportedFormats];
        return supportedFormats.includes(value.type);
      }),
  });
