import React from "react";
import { Chart as ReactChart } from "react-chartjs-2";
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  TimeScale,
  Tooltip,
  registerables,
} from "chart.js";
import {
  CandlestickController,
  CandlestickElement,
} from "chartjs-chart-financial";
import "chartjs-adapter-moment";
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from "chartjs-plugin-annotation";

import {
  candlestickChartOptions,
  getAnnotations,
  getChartData,
} from "./options";
import LineWithInform from "shared/ui/LineWithInform";
import SupportContactNotification from "shared/ui/SupportContactNotification";
import CandlestickChartPreloader from "./preloaders/CandlestickChartPreloader";

import { CandlestickChartWrap } from "./index.styles";

ChartJS.register(
  ...registerables,
  CandlestickController,
  CandlestickElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Tooltip,
  zoomPlugin,
  annotationPlugin,
);

const CandlestickChart = ({
  candlestickChart,
  indicatorSignals,
  loading,
  error,
}) => {
  const { candles } = candlestickChart || {};
  const { signals } = indicatorSignals || {};

  if (loading) return <CandlestickChartPreloader />;

  if (error)
    return (
      <SupportContactNotification
        infoMessage='System error detected. Please contact support via email:'
        linkText='hello@darkbot.io'
        redirectTo='mailto:hello@darkbot.io'
      />
    );

  if (!candles && !loading)
    return (
      <LineWithInform
        className='mt-2'
        infoMessage="Please select indicators to check signals and display a chart with relevant buy and sell points for the selected market. Click the button 'Check Signals' to transfer the parameters and retrieve the actual points for the chart."
      />
    );

  const chartData = getChartData(candles, signals);
  const annotations = getAnnotations(candles, signals);

  const chartOptions = {
    ...candlestickChartOptions,
    plugins: {
      ...candlestickChartOptions.plugins,
      annotation: {
        annotations,
      },
    },
  };

  return (
    <CandlestickChartWrap data-testid='candlestick-chart-wrap'>
      <ReactChart type='candlestick' data={chartData} options={chartOptions} />
    </CandlestickChartWrap>
  );
};

export default CandlestickChart;
