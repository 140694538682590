import React from "react";

import { Button } from "shared/ui/buttons";

const CandleBTNGroup = ({ className, isEdit, handleClose, handleSubmit }) => {
  return (
    <div className={className}>
      <Button
        color='gray'
        className='mr-2 py-3'
        type='button'
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button color='blue' className='py-3' onClick={handleSubmit}>
        {isEdit ? "Save" : "Add"}
      </Button>
    </div>
  );
};

export default CandleBTNGroup;
