import moment from "moment";
import { dynamicRgbaColors } from "./helpers";
import { BALANCE_DATASETS_TITLES, INVESTMENT_DATASETS_TITLES } from "./enum";

import {
  Chart as ChartJS,
  CategoryScale,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  BarElement,
} from "chart.js";

import { chartOptions, lineChartOptions, tooltipsRender } from "./options";

ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
);

export const drawBotDailyIncome = ({ data = [], labels = [] }) => {
  return {
    data: {
      labels: labels.map(item => moment(item).format("DD.MM.YYYY")),
      datasets: [
        {
          fill: true,
          backgroundColor: data.map(item =>
            item < 0 ? "rgba(237, 95, 95, 1)" : "rgba(63, 203, 176, 1)",
          ),
          hoverBackgroundColor: data.map(item =>
            item < 0 ? "rgba(237, 95, 95, 0.5)" : "rgba(63, 203, 176, 0.5)",
          ),
          drawBorder: false,
          data: data.map(item => (item < 0 ? -item : item)),
        },
      ],
    },
    options: {
      ...chartOptions,
      interaction: {
        intersect: false,
        mode: "index",
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          backgroundColor: "#ffffff",
          bodyColor: "rgba(0, 0, 0, 1)",
          callbacks: {
            label: context => {
              return context.dataset.backgroundColor[context.dataIndex] ===
                "rgba(237, 95, 95, 1)"
                ? `${context.label}: -${context.raw}`
                : `${context.label}: ${context.raw}`;
            },
            title: () => null,
          },
        },
      },
      scales: {
        y: {
          grid: {
            display: false,
            color: "#27334F",
            borderColor: "#27334F",
          },
          scaleLabel: {
            display: true,
          },
          ticks: {
            display: true,
            fontColor: "#3FCBB0",
            beginAtZero: true,
          },
        },
        x: {
          categoryPercentage: 0.1,
          barPercentage: 0.1,
          grid: {
            display: false,
            drawBorder: true,
            color: "#27334F",
            borderColor: "#27334F",
          },
          ticks: {
            padding: 5,
            fontColor: "#6C757D",
            beginAtZero: true,
          },
        },
      },
    },
  };
};

export const getDealStatistic = data => {
  return {
    data: {
      labels: Object.keys(data.charts.line)
        .slice(0, 40)
        .map(date => date * 1000),
      datasets: [
        {
          fill: false,
          backgroundColor: "rgba(29,140,248,0.2)",
          borderColor: "#1f8ef1",
          borderWidth: 3,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#2F80ED",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#2F80ED",
          pointBorderWidth: 4,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 10,
          pointRadius: 1,
          data: Object.keys(data.charts.line).map(key => data.charts.line[key]),
          label: false,
        },
        {
          fill: false,
          backgroundColor: "rgba(29,140,248,0.2)",
          borderColor: "#00F2C3",
          borderWidth: 3,
          borderDash: [8],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#00F2C3",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#00F2C3",
          pointBorderWidth: 4,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 10,
          pointRadius: 1,
          data: Object.keys(data.trailing.buy).map(
            key => data.trailing.buy[key],
          ),
          label: "Trailing buy",
        },
        {
          fill: false,
          backgroundColor: "rgba(29,140,248,0.2)",
          borderColor: "#ED5F5F",
          borderWidth: 3,
          borderDash: [8],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#ED5F5F",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#ED5F5F",
          pointBorderWidth: 4,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 10,
          pointRadius: 1,
          data: Object.keys(data.trailing.sell).map(
            key => data.trailing.sell[key],
          ),
          label: "Trailing sell",
        },
      ],
    },
    options: {
      ...chartOptions,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
        x: {
          type: "time",
          display: true,
          ticks: {
            display: false,
          },
          grid: {
            display: true,
            color: "#27334F",
            borderColor: "#27334F",
          },
        },
      },
    },
  };
};

export const balanceHistory = (data, period, currency) => {
  const datasets = Object.keys(data)?.map(key => {
    return {
      label: BALANCE_DATASETS_TITLES[key],
      fill: BALANCE_DATASETS_TITLES[key] === "Maximum balance",
      borderColor: "#2F80ED",
      pointBackgroundColor: "#2F80ED",
      pointBorderColor: "#2F80ED",
      pointHoverBackgroundColor: "#2F80ED",
      backgroundColor: "rgba(29,140,248,0.1)",
      fillColor: "rgba(220,220,220,0.5)",
      borderDash:
        BALANCE_DATASETS_TITLES[key] === "Minimum balance" ? [10, 5] : [],
      pointHoverBorderColor: "#2F80ED",
      drawBorder: true,
      tension: 0.2,
      pointBorderWidth: 0,
      pointRadius: 0,
      pointHoverBorderWidth: 2,
      borderWidth: 2,
      pointHoverRadius: 3,
      data: Object.values(data[key])
        .map(value => value)
        ?.reverse(),
    };
  });

  const initialDatasets = [
    {
      label: Object.values(data).map(value => value),
      fill: true,
      borderColor: "#2F80ED",
      pointBackgroundColor: "#2F80ED",
      pointBorderColor: "#2F80ED",
      pointHoverBackgroundColor: "#2F80ED",
      backgroundColor: "rgba(29,140,248,0.1)",
      fillColor: "rgba(220,220,220,0.5)",
      tension: 0.2,
      pointRadius: 0,
      drawBorder: true,
      pointHoverBorderWidth: 2,
      pointHoverRadius: 3,
      pointBorderWidth: 0,
      pointHoverBorderColor: "#2F80ED",
      borderWidth: 2,
      data: Object.values(data).map(value => +value),
    },
  ];

  return {
    data: {
      labels:
        period === "day"
          ? Object.keys(data).map(date => +date * 1000)
          : Object.keys(data.maxBalance)?.reverse(),
      datasets: period === "day" ? initialDatasets : datasets,
    },
    options: {
      ...chartOptions,
      normalized: true,
      animation: Object.keys(data).length < 5000,
      interaction: {
        intersect: false,
        mode: "index",
      },
      plugins: {
        legend: {
          display: period !== "day",
          position: "bottom",
        },
        ...tooltipsRender(period, currency),
      },
      scales: {
        y: {
          grid: {
            display: false,
          },
          ticks: {
            padding: 0,
            stepSize: 0.25,
          },
        },
        x: {
          ...(period === "day" ? { type: "time" } : {}),
          grid: {
            display: true,
            color: "#27334F",
            borderColor: "#27334F",
          },
          time: {
            format: "HH:mm",
            displayFormats: {
              minute: "HH:mm",
              hour: "HH:mm",
            },
          },
        },
      },
    },
  };
};

export const drawSignalsChart = data => {
  return {
    data: () => {
      return {
        labels: Object.keys(data).map(value =>
          moment.unix(value).format("YYYY-MM-DD hh:mm"),
        ),
        datasets: [
          {
            label: "Price",
            fill: false,
            backgroundColor: "#1d8cf8",
            hoverBackgroundColor: "#1d8cf8",
            borderColor: "#1d8cf8",
            drawBorder: true,
            data: Object.values(data).map(value => value),
          },
        ],
      };
    },
    options: {
      legend: {
        display: true,
      },
      ...lineChartOptions,
    },
  };
};

export const drawBallancesPieChart = (balances, colorsArr) => {
  return {
    labels: balances.items.map(item => item.asset),
    datasets: [
      {
        pointRadius: 0,
        pointHoverRadius: 2,
        backgroundColor: [...colorsArr],
        borderWidth: 0,
        data: balances.items.map(item => item.percent),
      },
    ],
  };
};

export const drawBotStatistics = ({ data = [], labels = [] }) => {
  return {
    data: canvas => {
      const ctx = canvas.getContext("2d");
      const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
      return {
        labels,
        datasets: [
          {
            label: "An average bot earns",
            fill: true,
            backgroundColor: data.map(value =>
              value > 0 ? "#3FCBB0" : "#FD5D93",
            ),
            hoverBackgroundColor: data.map(value =>
              value > 0 ? "#3FCBB0" : "#FD5D93",
            ),
            drawBorder: false,
            data,
          },
        ],
      };
    },
    options: chartOptions,
  };
};

export const drawInvestmentStatisticsChart = (data, period) => {
  const datasets = Object.keys(data).map(key => {
    const color = dynamicRgbaColors();
    return {
      label: INVESTMENT_DATASETS_TITLES[key],
      fill: false,
      borderColor: color,
      pointBackgroundColor: color,
      pointBorderColor: color,
      pointHoverBackgroundColor: color,
      drawBorder: true,
      tension: 0.2,
      pointBorderWidth: 0,
      pointRadius: 0,
      pointHoverBorderWidth: 2,
      borderWidth: 2,
      pointHoverRadius: 3,
      data: Object.values(data[key])
        .map(value => value)
        ?.reverse(),
    };
  });
  const labels = Object.keys(
    period === "day" ? data.total : data.minTotal || {},
  );

  return {
    data: {
      labels:
        period === "day"
          ? labels?.map(timestamp => timestamp * 1000)
          : labels?.reverse(),
      datasets:
        period === "day" ? datasets : datasets.filter(line => line.label),
    },
    options: {
      ...lineChartOptions,
      interaction: {
        intersect: false,
        mode: "index",
      },
      plugins: {
        legend: {
          display: true,
          position: "bottom",
        },
        tooltips: {
          backgroundColor: "#f5f5f5",
          fontColor: "rgba(0, 0, 0, 0.5)",
          titleColor: "rgba(0, 0, 0, 0.5)",
          bodyColor: "rgba(0, 0, 0, 1)",
          labelColor: "rgba(0, 0, 0, 1)",
          color: "rgba(0, 0, 0, 0.5)",
          bodySpacing: 4,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
          width: 252,
          height: 134,
          xPadding: 16,
          yPadding: 16,
          callbacks: {
            title: function () {
              return moment(new Date()).format("DD.MM.YY");
            },
          },
        },
      },
      scales: {
        y: {
          grid: {
            display: true,
            color: "#27334F",
            borderColor: "#27334F",
          },
        },
        x: {
          ...(period === "day" ? { type: "time" } : {}),
          grid: {
            display: true,
            color: "#27334F",
            borderColor: "#27334F",
          },
          time: {
            displayFormats: {
              minute: "HH:mm",
              hour: "HH:mm",
            },
          },
        },
      },
    },
  };
};

export const depthChart = (orderBookAsks, orderBookBids = 15) => {
  const sortedAsks = [...orderBookAsks].sort((a, b) => a.total - b.total);
  const sortedBids = [...orderBookBids].sort((a, b) => b.total - a.total);
  const emptyArr = new Array(sortedBids.length).fill(null);

  return {
    data: {
      labels: [...sortedBids, ...sortedAsks].map(item => item.price),
      datasets: [
        {
          fill: true,
          backgroundColor: "rgba(63, 203, 176, 0.1)",
          fillColor: "#3FCBB0",
          data: [...sortedBids, 0].map(item => (item ? +item.total : item)),
          borderColor: "#3fCbb0",
          tension: 0.2,
          pointRadius: 0,
          pointHoverRadius: 2,
          pointHoverBorderWidth: 2,
          pointBorderWidth: 8,
          pointHoverBorderColor: "#3fCbb0",
          pointBackgroundColor: "#3fCbb0",
          borderWidth: 2,
          yAxisID: "y",
        },
        {
          fill: true,
          backgroundColor: "rgba(237, 95, 95, 0.1)",
          fillColor: "#3FCBB0",
          data: [...emptyArr, ...sortedAsks].map(item =>
            item ? item.total : item,
          ),
          borderColor: "#ED5F5F",
          tension: 0.2,
          pointRadius: 0,
          pointHoverRadius: 2,
          pointHoverBorderWidth: 2,
          pointBorderWidth: 8,
          pointHoverBorderColor: "#ED5F5F",
          pointBackgroundColor: "#ED5F5F",
          borderWidth: 2,
          yAxisID: "y1",
        },
      ],
    },
    options: {
      ...chartOptions,
      animation: false,
      interaction: {
        intersect: false,
        mode: "index",
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            footer: context => `${context[0].formattedValue}`,
            label: context => context.label,
            title: context => (context[0].dataIndex >= 15 ? "Asks" : "Bids"),
          },
        },
      },
      scales: {
        y: {
          grid: {
            stepSize: 1,
            display: false,
          },
          ticks: {
            padding: 0,
          },
        },
        y1: {
          grid: {
            display: false,
          },
          ticks: {
            padding: 0,
          },
          position: "right",
        },
        x: {
          grid: {
            display: true,
            color: "#27334F",
            borderColor: "#27334F",
          },
        },
      },
    },
  };
};
