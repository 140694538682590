export const FEATURES_LIST = [
  {
    name: "Signal trading",
    free: 1,
    standard: 10,
    premium: "Unlimited",
  },
  {
    name: "Running deals",
    free: 1,
    standard: 100,
    premium: "Unlimited",
  },
  {
    name: "Maximum Bots",
    free: 5,
    standard: 50,
    premium: 500,
  },
  {
    name: "Backtests per day",
    free: 5,
    standard: 50,
    premium: 100,
  },
  {
    name: "Automatic Trades",
    free: true,
    standard: true,
    premium: true,
  },
  {
    name: "Trade statistics",
    free: true,
    standard: true,
    premium: true,
  },
  {
    name: "Login history",
    free: true,
    standard: true,
    premium: true,
  },
  {
    name: "Telegram notifications",
    free: true,
    standard: true,
    premium: true,
  },
  {
    name: "Premium indicators",
    free: false,
    standard: true,
    premium: true,
  },
  {
    name: "Portfolio rebalancing",
    free: false,
    standard: false,
    premium: true,
  },
  {
    name: "Priority support",
    free: false,
    standard: false,
    premium: true,
  },
  {
    name: "Machine learning",
    free: false,
    standard: false,
    premium: true,
    cuttingEdge: true,
  },
];
