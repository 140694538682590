import { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import Button from "shared/ui/buttons/Button";
import { acceptSignalTable } from "store/signals/action";
import { addSnackBar } from "store/snackbar/actions";
import { ReactComponent as TickSquare } from "assets/icons/checkbox/tick.svg";

const MyAgreementsEmpty = ({ setIsDisabled, isDisabled, status, signalId }) => {
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleAcceptSignal = async signalId => {
    setIsSubmitted(true);
    setIsDisabled(true);
    const res = await dispatch(acceptSignalTable(signalId));
    if (!res.errors) {
      setIsSubmitted(false);
      setIsDisabled(false);
      return dispatch(addSnackBar("success", res.message));
    }
    setIsSubmitted(false);
    setIsDisabled(false);
    return dispatch(addSnackBar("error", res.message));
  };

  return (
    <MyAgreementsEmpty.Row>
      <td colSpan={8}>
        <div className='d-flex align-items-center'>
          <TickSquare className='mr-1' />
          <p className='mb-0 font-weight-500 text-white-35'>No agreements</p>
          {status !== "completed" ? (
            <Button
              color='blue'
              size='small'
              className='px-3 py-2 ml-4'
              loading={isSubmitted}
              disabled={isDisabled}
              onClick={() => handleAcceptSignal(signalId)}
            >
              Accept
            </Button>
          ) : null}
        </div>
      </td>
    </MyAgreementsEmpty.Row>
  );
};

MyAgreementsEmpty.Button = styled(Button)`
  &:hover {
    border: ${prop =>
      prop.disabled
        ? "1px solid var(--blue)"
        : "1px solid transparent"}!important;
  }
`;

MyAgreementsEmpty.Row = styled.tr`
  background: #2e3148 !important;
`;

export default MyAgreementsEmpty;
