import React from "react";
import styled from "styled-components";
import { Table, CardBody } from "reactstrap";

import PortfolioTableRow from "./PortfolioTableRow";
import { ReactComponent as Trash } from "assets/icons/trash-red-icon.svg";
import { PortfolioTableHead } from "./PortfolioTableHead";
import EmptyPortfolioTable from "views/credentials/states/EmptyPortfolioTable";
import { CustomScrollbars } from "shared/ui";

const PortfolioTable = ({
  portfolio = [],
  connected,
  id,
  subscription,
  exchangeIndex,
  autoRebalancing,
  status,
  balanceHidden,
}) => {
  const filteredEmptyPortfolio = portfolio.reduce((acc, currentInvestment) => {
    return acc + +currentInvestment.available.total;
  }, 0);

  return filteredEmptyPortfolio === 0 || connected === false ? (
    <EmptyPortfolioTable
      id={id}
      connected={connected}
      portfolio={portfolio}
      exchangeIndex={exchangeIndex}
      subscription={subscription}
      autoRebalancing={autoRebalancing}
    />
  ) : (
    <CardBody
      className='p-4'
      style={{ background: "var(--dark-slate)", borderRadius: "0 0 8px 8px" }}
    >
      <CustomScrollbars
        autoHideTimeout={500}
        autoHideDuration={500}
        autoHeight='true'
        autoHeightMax={Number.MAX_VALUE}
      >
        {status === "pending_delete" ? (
          <PortfolioTable.WrapperDescription>
            <Trash width='32px' heigth='32px' />
            <span>
              Your credentials are in progress of deleting, you cannot cancel
              this process
            </span>
          </PortfolioTable.WrapperDescription>
        ) : (
          <Table className='mb-0'>
            <PortfolioTableHead
              exchangeIndex={exchangeIndex}
              subscription={subscription}
              bots
            />
            <tbody className='font-sm'>
              {portfolio.map((investment, index) =>
                +investment.available.total > 0 ? (
                  <PortfolioTableRow
                    key={investment.id}
                    investment={investment}
                    index={index}
                    exchangeIndex={exchangeIndex}
                    subscription={subscription}
                    autoRebalancing={autoRebalancing}
                    bots
                    balanceHidden={balanceHidden}
                  />
                ) : null,
              )}
            </tbody>
          </Table>
        )}
      </CustomScrollbars>
    </CardBody>
  );
};

PortfolioTable.WrapperDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px;
  span {
    width: 265px;
    color: #fff;
    text-align: center;
    font-feature-settings: "liga" off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

export default PortfolioTable;
