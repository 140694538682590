import React, { useState } from "react";
import { capitalize } from "lodash";
import moment from "moment";
import { Tooltip } from "reactstrap";

import { handleStatusData } from "models/constans/ui/tradeRequests/content";
import { TradeRequestIcon } from "shared/styled/StyledTradeRequestComponents";

const TradeRequestStatus = ({ data }) => {
  const [tooltip, setTooltip] = useState(false);

  const toggle = () => setTooltip(!tooltip);
  const statusData = handleStatusData(data);
  const IconStatus = statusData[data?.status]?.Icon;

  if (statusData[data?.status]) {
    return (
      <>
        <p className='mb-0 text-white text-nowrap font-weight-500 mr-2 font-sm'>
          {moment(data?.createdAt).format("DD.MM.YY HH:mm")}
        </p>
        <TradeRequestIcon>
          <IconStatus
            width={20}
            height={20}
            id={`status-${data?.id}`}
            data-testid='trade-request-status'
          />
        </TradeRequestIcon>
        <Tooltip
          placement='top'
          isOpen={tooltip}
          target={`status-${data?.id}`}
          toggle={toggle}
        >
          {capitalize(data?.status)}
        </Tooltip>
      </>
    );
  }
};

export default TradeRequestStatus;
