import React from "react";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import TablePreloader from "shared/ui/TablePreloader";

const BacktestPreload = ({ renderHeader }) => {
  return (
    <>
      {renderHeader && (
        <div className='d-flex justify-content-between my-3 ml-4 mr-3'>
          <div className='d-flex align-items-center'>
            <SkeletonPreloader
              circle={true}
              height={20}
              width={20}
              className='mr-2'
            />
            <SkeletonPreloader height={13} width={63} />
          </div>
          <SkeletonPreloader height={32} width={144} borderRadius='6px' />
        </div>
      )}

      <TablePreloader
        thHeight={10}
        thWidth={[42, 42, 42, 42, 42, 42]}
        tdWidth={[48, 71, 113, 113, 56, 56]}
        tdHeight={12}
        rowTimes={10}
      />
    </>
  );
};

export default BacktestPreload;
