import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as InfoCircle } from "assets/icons/general/infocircle.svg";
import { ReactComponent as Copy } from "assets/icons/general/copy-2.svg";
import exchangesConfig from "exchanges/exchangesConfig";
import Hint from "shared/styled/Hint";

const IPsBlock = ({ exchange }) => {
  const [copiedText, setCopiedText] = useState("");

  const handleCopy = text => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedText(text);
      setTimeout(() => {
        setCopiedText("");
      }, 1200);
    });
  };

  if (!exchange?.ips?.length) return;

  return (
    <StyledWrapper>
      <div className='d-flex justify-content-between'>
        <p className='font-sm font-weight-500 mb-3 text-dark'>
          Please use one the following IPs
          <br />
          to generate your API Key:
        </p>
        <a
          href={exchangesConfig[exchange]?.docsLink}
          target='_blank'
          rel='noopener noreferrer'
        >
          <InfoCircle />
        </a>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex flex-column'>
          {exchange?.ips?.map(ip => (
            <p className='code-text' key={ip}>
              {ip}
            </p>
          ))}
        </div>
        <div className='d-flex align-items-center'>
          {copiedText ? <Hint className='mr-2 mb-1'>Copied!</Hint> : null}
          <button
            className='btn btn-reset my-0'
            onClick={() => handleCopy(exchange?.ips?.join(" "))}
          >
            <Copy />
          </button>
        </div>
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  border-radius: 8px;
  background: rgba(47, 128, 237, 0.1);
  padding: 1rem 1.25rem;

  &.code-text {
    color: #7d81ab !impotant;
  }

  button svg {
    width: 1.5rem;
    height: 1.5rem;

    & path {
      stroke: var(--info);
    }
  }
`;

export default IPsBlock;
