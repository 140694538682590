import React from "react";
import { Modal } from "reactstrap";

import StyledModalBody from "./StyledModalBody";
import { Button } from "shared/ui/buttons";

const AboutAverageDownStrategy = ({ isOpen, setIsOpen }) => {
  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      modalClassName='modal-black'
      size='lg'
    >
      <StyledModalBody>
        <div className='d-flex justify-content-between mb-4'>
          <p className='h1 m-0'>What is Average Down Strategy?</p>
          <Button color='gray' cancel={true} onClick={() => setIsOpen(false)} />
        </div>
        <p className='mb-3 text-white-75'>
          In an average down strategy with increasing position percent for a
          crypto trading, the Martingale percent refers to the percentage
          increase in position size after a losing trade. The idea behind this
          approach is that if the price continues to fall and the bot&apos;s
          deal is losing money, the bot will increase the position size in an
          attempt to recoup the losses more quickly when the price eventually
          recovers.
        </p>
        <p className='mb-3 text-white-75'>
          For example, if the starting position size is 5% of available funds
          and the Martingale percent is set at 100%, the bot will double its
          position
          {"size after a losing trade. So if the bot's first position was "}
          $50, the bot will open a second position of $100 after a losing trade.
          If the second position also loses, the bot will double the position
          size again to $200 for the third position.
        </p>
        <p className='mb-3 text-white-75'>
          {"It's important to note that using Martingale in any trading "}
          strategy carries significant risk. Doubling the position size after
          each losing trade can lead to larger and larger
          {"losses, particularly in a volatile market. As such, it's "}
          important to carefully consider the Martingale percent and limit the
          number of positions opened by the bot to avoid excessive risk.
        </p>
        <p className='mb-3 text-white-75'>
          In an average down strategy with increasing position percent, using
          Martingale can be particularly risky as the position size is already
          increasing with each subsequent position. Traders should carefully
          consider their risk tolerance and market conditions before using
          Martingale in conjunction with an average down strategy with
          increasing position percent.
        </p>
      </StyledModalBody>
    </Modal>
  );
};

export default AboutAverageDownStrategy;
