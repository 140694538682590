import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import { Card, CardBody } from "reactstrap";
import { ReactComponent as Information } from "assets/icons/general/information.svg";
import { indicatorsConfiguration } from "views/strategies/wizard/indicatorsConfiguration";

const Indicator = ({ indicator = {} }) => {
  const getIndicatorName = code =>
    indicatorsConfiguration.find(i => i.value === code)?.label;

  return (
    <Indicator.Wrapper required={indicator.indicator.required}>
      <Card>
        <CardBody className='d-flex align-items-center justify-content-between px-2 px-sm-4'>
          <div className='d-flex align-items-center overflow-hidden'>
            <div className='d-flex align-items-center text-nowrap'>
              <span className='d-inline-block'>
                <Information role='img' />
              </span>
              <p className='text-white font-weight-500 my-auto mx-1 mx-sm-3'>
                {getIndicatorName(indicator.indicator.code)}
              </p>
            </div>

            <div className='d-flex align-items-center text-nowrap overflow-hidden'>
              <p
                className={classNames(
                  "text-uppercase font-weight-bold font-sm my-auto",
                  {
                    "text-warning": indicator.indicator.type === "both",
                    "text-success": indicator.indicator.type === "buy",
                    "text-danger": indicator.indicator.type === "sell",
                    "text-white-35":
                      indicator.indicator.type === "hold" ||
                      indicator.indicator.type === "none",
                  },
                )}
              >
                {indicator.indicator.type}
              </p>
              {Object.values(indicator.indicator.settings || {}).length ? (
                <p className='mb-0 ml-1 text-white-35 font-sm text-nowrap overflow-hidden rbc-ellipsis'>
                  {Object.values(indicator.indicator.settings).join(", ")}
                </p>
              ) : null}
            </div>
          </div>

          <Indicator.Signal
            className={classNames("ml-2", {
              none: indicator.signal === "none" || indicator.signal === "hold",
              buy: indicator.signal === "buy",
              sell: indicator.signal === "sell",
              both: indicator.signal === "both",
            })}
          >
            <p className='mb-0 text-uppercase font-sm font-weight-500'>
              {indicator.signal}
            </p>
          </Indicator.Signal>
        </CardBody>
      </Card>
    </Indicator.Wrapper>
  );
};

Indicator.Wrapper = styled.div`
  .card {
    box-shadow: none;
    border-top: 1px solid #2e3148;
    border-radius: 0 0 8px 8px !important;
    margin-bottom: 0;
  }

  .card-body {
    padding: 12px 16px;
  }

  svg {
    path {
      stroke: ${props =>
        props.required ? "var(--warning)" : "rgba(255, 255, 255, 0.35)"};
    }
  }

  .active svg {
    path {
      stroke: ${props =>
        props.required ? "var(--warning)" : "rgba(255, 255, 255, 0.35)"};
      transition: 0.3s all ease-in-out;
    }

    &:hover {
      transition: 0.3s all ease-in-out;

      path {
        fill: white;
        stroke: ${props =>
          props.required ? "var(--warning)" : "rgba(255, 255, 255, 0.35)"};
        stroke-opacity: ${props => (props.required ? 0.35 : 1)};
      }
    }
  }
`;

Indicator.Signal = styled.div`
  border-radius: 4px;
  padding: 5px 8px;
  line-height: 16px;

  &.buy {
    background: #3fcbb0;
    p {
      color: #1d1e2a !important;
    }
  }

  &.sell {
    background: #ed5f5f;
    p {
      color: #ffffff;
    }
  }

  &.none {
    border: 1px solid #2e3148;
    p {
      color: rgba(255, 255, 255, 0.35) !important;
    }
  }

  &.both {
    background: #f2994a;
    p {
      color: #2a2c42;
    }
  }
`;

export default Indicator;
