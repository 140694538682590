import React from "react";
import { Col } from "reactstrap";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import _ from "lodash";

const MarketsTablePreloader = () => {
  return (
    <div className='d-flex flex-column py-4'>
      <div className='mb-0 d-flex flex-row align-items-center justify-content-between'>
        {_.times(2, e => {
          return (
            <Col xs={2} key={e} className='pl-5'>
              <SkeletonPreloader width={58} height={10} />
            </Col>
          );
        })}
        {_.times(5, e => (
          <Col xs={1} key={e}>
            <SkeletonPreloader width={58} height={10} />
          </Col>
        ))}
      </div>
      <div style={{ borderBottom: "1px solid #2E3148" }} className='mt-3' />
      {_.times(8, e => (
        <div
          className='py-3 d-flex flex-row align-items-center justify-content-between'
          key={e}
          style={{ borderBottom: "1px solid #2E3148" }}
        >
          <Col xs={2} className='d-flex align-items-center px-4'>
            <SkeletonPreloader className='mr-2' width={24} height={24} circle />
            <SkeletonPreloader width={88} height={13} />
          </Col>
          <Col xs={2} className='pl-5'>
            <SkeletonPreloader width={147} height={15} />
          </Col>
          <Col xs={1}>
            <SkeletonPreloader width={46} height={15} />
          </Col>
          <Col xs={1}>
            <SkeletonPreloader width={87} height={15} />
          </Col>
          <Col xs={1}>
            <SkeletonPreloader width={82} height={24} borderRadius='4px' />
          </Col>
          <Col xs={1}>
            <SkeletonPreloader width={87} height={15} />
          </Col>
          <Col xs={1} className='justify-content-end'>
            <SkeletonPreloader width={87} height={15} />
          </Col>
        </div>
      ))}
    </div>
  );
};

export default MarketsTablePreloader;
