import React from "react";

const FallBack = () => {
  return (
    <div className='text-center p-3 mt-2'>
      <h2 className='text-center'>
        <i className='tim-icons icon-alert-circle-exc text-warning' /> Something
        went wrong!
      </h2>
    </div>
  );
};

export default FallBack;
