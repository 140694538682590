import { useEffect, useLayoutEffect } from "react";

const useRequestCancellation = () => {
  const controllers = new Map();

  const createController = key => {
    const controller = new AbortController();
    controllers.set(key, controller);
    return controller;
  };

  const getController = key => controllers.get(key);

  const abort = key => {
    const controller = getController(key);
    if (controller) {
      controller.abort();
      controllers.delete(key);
    }
  };

  const useAbortableEffect = (effect, deps, layout) => {
    const controllerKey = deps.join("-");
    const hook = layout ? useLayoutEffect : useEffect;

    hook(() => {
      const controller = createController(controllerKey);
      const cleanup = effect(controller.signal);

      return () => {
        if (typeof cleanup === "function") cleanup();
        abort(controllerKey);
      };
    }, deps);
  };

  return { useAbortableEffect };
};

export default useRequestCancellation;
