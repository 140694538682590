import React, { useState } from "react";
import { CardHeader, Tooltip } from "reactstrap";
import classnames from "classnames";

import { ReactComponent as HelpIcon } from "assets/icons/general/Help.svg";
import TradesFilters from "./TradesFilters";
import OrdersFilters from "./OrdersFilters";
import { Checkbox, RefreshButton, SkeletonPreloader } from "shared/ui";

const TableHeader = ({
  loadingPage,
  itemsLoading,
  handleRefresh,
  total,
  handleFilter,
  dealOnly,
  setDealOnly,
  handleDealOnly,
  filter,
  title,
}) => {
  const [isShow, setIsShow] = useState(false);

  const toggleShow = () => setIsShow(prevState => !prevState);

  return (
    <CardHeader className='row flex-sm-column flex-lg-row align-items-lg-center justify-content-lg-between pt-3 mr-md-3 pb-4 ml-4 p-0'>
      {loadingPage ? (
        <div className='d-flex align-items-center'>
          <SkeletonPreloader height={14} width={81} className='mr-2' />
          <SkeletonPreloader height={16} width={16} circle />
        </div>
      ) : (
        <div className='d-flex align-items-center'>
          <p className='mb-0 font-weight-500 text-white'>{title}</p>
          <div className='cursor-pointer m-0 ml-1' id={title}>
            <HelpIcon width={16} height={16} />
          </div>
          <Tooltip
            target={title}
            placement='top-center'
            isOpen={isShow}
            toggle={toggleShow}
          >
            {title} related to this deal
          </Tooltip>

          <Checkbox
            onClick={() => {
              setDealOnly(!dealOnly);
              handleDealOnly(!dealOnly);
            }}
            checked={dealOnly}
            disabled={itemsLoading}
            className='ml-3'
          />
          <p className='mb-0 ml-2 text-white font-sm'>Deal-only</p>
        </div>
      )}
      <div className='d-flex align-items-center mt-3 mt-lg-0 flex-wrap'>
        {!itemsLoading && !loadingPage ? (
          <div className='d-flex align-items-center mr-3 mt-2 mt-sm-0'>
            <RefreshButton
              withHoverBg={false}
              onHandleRefresh={handleRefresh}
              loading={itemsLoading}
              width={16}
              height={16}
            />
            <p
              className={classnames("mb-0 font-md ml-2 text-nowrap", {
                "text-white-35 font-weight-400": total === 0,
                "text-white font-weight-500": !total,
              })}
            >
              {total} results
            </p>
          </div>
        ) : (
          <SkeletonPreloader width={61} height={14} className='mr-3' />
        )}
        {title === "Trades" ? (
          <TradesFilters
            itemsLoading={itemsLoading}
            onHandleFilter={handleFilter}
            loadingPage={loadingPage}
          />
        ) : (
          <OrdersFilters
            itemsLoading={itemsLoading}
            onHandleFilter={handleFilter}
            filter={filter}
            loadingPage={loadingPage}
          />
        )}
      </div>
    </CardHeader>
  );
};

export default TableHeader;
