import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Filters from "../wizard/Markets/Filters";
import MarketMessageModal from "../wizard/Markets/MarketMessageModal";
import MarketsTable from "../wizard/Markets/MarketsTable";
import EditMarketsHeader from "./EditMarketsHeader";
import { getAvailableMarkets } from "store/markets/actions";
import { SupportContactNotification } from "shared/ui";

const EditBotMarkets = ({ period, isEdit }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isFetchCompleted, setIsFetchCompleted] = useState(false);
  const dispatch = useDispatch();

  const { data, loading, error } = useSelector(state => state.availableMarkets);
  const { excludedCoins, coins, allCoins, investment } = useSelector(
    state => state.botWizard,
  );

  useLayoutEffect(() => {
    if (investment.id && !isFetchCompleted) {
      dispatch(getAvailableMarkets(investment.id, "?limit=10"));
      setIsFetchCompleted(true);
    }
  }, [dispatch, investment.id, isFetchCompleted]);

  return (
    <>
      <EditMarketsHeader
        exchange={investment?.credentials?.exchange}
        selectedInvestment={investment}
        period={period}
      />
      {error ? (
        <div className='mx-4 my-3'>
          <SupportContactNotification
            infoMessage='System error detected. Please contact support via email:'
            linkText='hello@darkbot.io'
            redirectTo='mailto:hello@darkbot.io'
          />
        </div>
      ) : (
        <>
          <MarketsTable
            data={data}
            loading={loading || !investment.id}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setIsOpenModal={setIsOpenModal}
          />

          <Filters
            selectedInvestment={investment}
            isEdit={isEdit}
            loading={!investment.id}
          />

          <MarketMessageModal
            isOpenModal={isOpenModal && coins?.length > 10 && !allCoins}
            setIsOpenModal={setIsOpenModal}
            allCoins={allCoins}
            excludedCoins={excludedCoins}
            coins={coins}
          />
        </>
      )}
    </>
  );
};

export default EditBotMarkets;
