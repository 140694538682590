import React from "react";
import { useHistory } from "react-router-dom";

import Button from "shared/ui/buttons/Button";

const BotsWidgetEmptyState = ({ title, description }) => {
  const history = useHistory();

  return (
    <div className='d-flex align-items-center flex-column justify-content-center mb-4 px-2'>
      <h2 className='font-weight-500 mb-2 text-white text-center'>{title}</h2>
      <p className='text-white-75 mb-4 text-center'>{description}</p>
      <Button
        size='big'
        color='blue'
        className='px-2 py-3 text-center'
        onClick={() => history.push("/bots/create")}
      >
        Create a bot
      </Button>
    </div>
  );
};

export default BotsWidgetEmptyState;
