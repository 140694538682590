const PPstyle = {
  width: "4px",
  height: "16px",
  borderRadius: 2,
  margin: "-16.5px 0 0 0",
  background: "#fff",
};

const markStyles = {
  fontSize: "0.875rem",
  marginTop: "0.25rem",
  color: "rgba(255, 255, 255, 0.35)",
};

const level = {
  border: "2px solid var(--dark-slate)",
  borderRadius: 50,
  boxShadow: "none",
  opacity: 1,
  width: 16,
  height: 16,
  bottom: "1px",
};

export const renderSteps = (
  range,
  displayMark,
  initialDisplayRange,
  currentSignal,
  currentLevel = "",
) => {
  const steps = {};

  const current =
    currentLevel[0] === "S"
      ? currentLevel?.replace("S", "-")
      : currentLevel?.replace("R", "");

  const setColor = (green, red, color) => {
    if (green) return "rgb(10, 175, 149)";
    if (red) return "rgb(237, 95, 95)";
    return color;
  };

  const setSteps = idx => {
    if (currentLevel === "S" && idx === -range) {
      return {
        style: {
          ...level,
          background: "rgb(10, 175, 149)",
        },
        label: " ",
      };
    }

    if (currentLevel === "R" && idx === range) {
      return {
        style: {
          ...level,
          background: "rgb(237, 95, 95)",
        },
        label: " ",
      };
    }

    if (currentLevel) {
      return (
        idx === +current && {
          style: {
            ...level,
            background: setColor(
              currentSignal === "buy",
              currentSignal === "sell",
              "rgba(156, 159, 191, 1)",
            ),
          },
          label: " ",
        }
      );
    }
    return { label: Math.abs(idx), style: markStyles };
  };

  for (let i = -range; i <= range; i++) {
    steps[i] = {
      ...(!i
        ? {
            style:
              currentLevel === "PP"
                ? {
                    ...level,
                    background: "rgba(255, 255, 255)",
                  }
                : {
                    display: displayMark || initialDisplayRange,
                    ...PPstyle,
                  },
            label: " ",
          }
        : setSteps(i)),
    };
  }

  return steps;
};
