import { useState, useEffect } from "react";

const usePersistedState = (key, defaultValue) => {
  const localStorageItem = localStorage.getItem(key);
  let currentState;
  if (
    typeof JSON.parse(localStorageItem) === "boolean" &&
    typeof defaultValue === "boolean"
  ) {
    currentState = JSON.parse(localStorageItem);
  } else {
    currentState = JSON.parse(localStorageItem) || defaultValue;
  }
  const [state, setState] = useState(currentState);
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
};

export default usePersistedState;
