import React from "react";
import styled from "styled-components";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import { Col } from "reactstrap";
import _ from "lodash";

const InvestmentsPreloader = () => {
  return (
    <InvestmentsPreloader.Wrapper className='d-flex flex-column'>
      {_.times(7, e => (
        <div
          key={e}
          className='item d-flex flex-row align-items-center justify-content-between p-3 w-100 item'
        >
          <Col xs={2} className='d-flex flex-row align-items-center px-4 mr-3'>
            <div
              style={{
                width: "19px",
                height: "19px",
                border: "1px solid #3B3C4D",
                borderRadius: "100px",
              }}
            />
            <SkeletonPreloader height={32} className='my-auto ml-2' />
          </Col>
          <Col xs={2} className='d-flex flex-row align-items-center p-0 mr-3'>
            <SkeletonPreloader
              circle={true}
              height={24}
              width={24}
              className='mr-2'
            />
            <SkeletonPreloader circle={false} height={14} />
          </Col>
          <Col xs={2} className='px-4'>
            <SkeletonPreloader height={14} />
          </Col>
          <Col xs={2}>
            <SkeletonPreloader height={14} />
          </Col>
          <Col xs={2}>
            <SkeletonPreloader height={8} />
          </Col>
          <Col xs={2} className='px-4 justify-content-end'>
            <SkeletonPreloader height={23} borderRadius='24px' />
          </Col>
        </div>
      ))}
      <div className='d-flex flex-row justify-content-between align-items-center m-4'>
        <SkeletonPreloader
          height={40}
          width={107}
          className='my-auto'
          borderRadius='6px'
        />
        <SkeletonPreloader
          circle={false}
          height={40}
          width={149}
          className='my-auto'
          borderRadius='6px'
        />
      </div>
    </InvestmentsPreloader.Wrapper>
  );
};

InvestmentsPreloader.Wrapper = styled.div`
  .item {
    border-top: 1px solid #2e3148;
  }
  &:last-child {
    .item {
      border-bottom: 1px solid #2e3148;
    }
  }
`;

export default InvestmentsPreloader;
