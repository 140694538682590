import styled from "styled-components";
import _ from "lodash";
import { Card, Table } from "reactstrap";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const ReferralSystemTablePreloader = () => {
  return (
    <Card className='p-4'>
      <div className='d-flex align-items-center justify-content-between'>
        <SkeletonPreloader width={111} height={16} />
        <div className='d-flex align-items-center justify-content-between'>
          <SkeletonPreloader width={60} height={20} className='ml-2' />
          <SkeletonPreloader width={60} height={20} className='ml-2' />
          <SkeletonPreloader width={60} height={20} className='ml-2' />
          <SkeletonPreloader width={60} height={20} />
        </div>
      </div>
      <ReferralSystemTablePreloader.Wrapper className='d-flex justify-content-between mt-4 px-4 py-3'>
        <SkeletonPreloader width={36} height={11} />
        <SkeletonPreloader width={75} height={11} />
        <SkeletonPreloader width={112} height={11} />
        <SkeletonPreloader width={48} height={11} />
        <SkeletonPreloader width={113} height={11} />
      </ReferralSystemTablePreloader.Wrapper>
      <Table>
        <tbody>
          {_.times(10, e => (
            <tr key={e}>
              <td>
                <SkeletonPreloader width={63} height={10} />
              </td>
              <td>
                <SkeletonPreloader width={15} height={15} circle={true} />
              </td>
              <td>
                <SkeletonPreloader width={15} height={15} circle={true} />
              </td>
              <td>
                <SkeletonPreloader width={120} height={10} />
              </td>
              <td>
                <SkeletonPreloader width={120} height={10} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

ReferralSystemTablePreloader.Wrapper = styled.div`
  background: #2e3148;
  border-radius: 8px;
`;

export default ReferralSystemTablePreloader;
