import React from "react";
import { CardBody, CardHeader } from "reactstrap";
import _ from "lodash";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import {
  MyCredentialsPreloaderItems,
  MyCredentialsStyledCard,
} from "./MyCredentialsTable.styles";

const MyCredentialsTablePreloader = () => {
  return (
    <MyCredentialsStyledCard>
      <CardHeader className='d-flex align-items-center pt-4 px-4'>
        <SkeletonPreloader circle height={20} width={20} />
        <SkeletonPreloader height={12} width={79} className='ml-2' />
      </CardHeader>
      <CardBody className='p-4 d-flex flex-column'>
        {_.times(2, e => (
          <MyCredentialsPreloaderItems
            key={e}
            className='d-flex align-items-center justify-content-between p-4 mb-2'
          >
            <div className='d-flex'>
              <div className='d-flex position-relative w-auto'>
                <SkeletonPreloader circle height={57} width={57} />
                <SkeletonPreloader
                  className='position-absolute bottom-0 right-0'
                  circle
                  height={23}
                  width={23}
                />
              </div>
              <div className='d-flex flex-column'>
                <SkeletonPreloader height={20} width={78} className='ml-3' />
                <SkeletonPreloader
                  height={20}
                  width={150}
                  className='ml-3 mt-3'
                />
              </div>
            </div>
            <div className='d-none d-sm-flex'>
              <SkeletonPreloader height={20} width={30} />
              <div className='d-flex flex-column align-items-end'>
                <SkeletonPreloader height={20} width={50} className='ml-2' />
                <SkeletonPreloader
                  height={20}
                  width={50}
                  className='ml-2 mt-3'
                />
              </div>
            </div>
          </MyCredentialsPreloaderItems>
        ))}
      </CardBody>
    </MyCredentialsStyledCard>
  );
};

export default MyCredentialsTablePreloader;
