import { Card } from "reactstrap";
import styled from "styled-components";

export const StyledAmount = styled.p`
  color: ${props =>
    props.amount > 0
      ? "rgba(255, 255, 255, 1)"
      : "rgba(255, 255, 255, 0.35)"} !important;
  margin-bottom: 0;
  white-space: nowrap;
  font-weight: 500;
`;

export const StyledCurrency = styled.p`
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.35) !important;
  font-weight: 300;
  margin-left: 4px;
`;

export const StyledMarketDetailsWrap = styled(Card)`
  &.card {
    border-radius: 0 0 12px 12px !important;
  }
`;
