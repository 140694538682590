import React from "react";
import styled from "styled-components";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import { Card } from "reactstrap";
import { ReactComponent as BackBreadCrumb } from "assets/icons/credential/backTo.svg";

const HeaderLoader = () => {
  return (
    <Card className='p-4 mb-3'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex align-items-center'>
          <SkeletonPreloader circle={true} height={56} width={56} />
          <SkeletonPreloader height={20} width={183} className='ml-3' />
        </div>
        <div className='d-flex align-items-center'>
          <HeaderLoader.BackBreadCrumb>
            <BackBreadCrumb />
          </HeaderLoader.BackBreadCrumb>
          <SkeletonPreloader height={16} width={137} className='ml-3' />
        </div>
      </div>
    </Card>
  );
};

HeaderLoader.BackBreadCrumb = styled.div`
  svg {
    path {
      stroke: rgba(255, 255, 255, 0.35);
    }
  }
`;

export default HeaderLoader;
