import React, { lazy } from "react";
import { Switch, useHistory } from "react-router-dom";

import Dashboard from "./dashboard/Dashboard";
import DashboardLayout from "./layouts/DashboardLayout";
import ProfileSettings from "./user/ProfileSettings";
import CredentialsList from "./credentials/CredentialsList";
import RequestLog from "./credentials/RequestLog";
import CredentialPage from "./credentials/CredentialPage";
import OpenOrders from "./credentials/openOrders/OpenOrders";
import TradeHistory from "./credentials/tradeHistory/TradeHistory";
import History from "./payments/history/History";
import BotPage from "./bots/BotPage";
import BotsList from "./bots/BotsList";
import DealPage from "./deals/DealPage";
import DealsList from "./deals/DealsList";
import Withdrawal from "./payments/withdrawal/Withdrawal";
import Deposit from "./payments/deposit/Deposit";
import CreateBot from "./bots/createBot/CreateBot";
import LoginHistory from "./user/LoginHistory";
import InvestmentPage from "./investments/InvestmentPage";
import ExchangeMarketPage from "./exchanges/ExchangeMarketPage";
import RebalancePage from "./rebalance/RebalancePage";
import BacktestPage from "./backtest/BacktestPage";
import EditBot from "./bots/EditBot/EditBot";
import BillingPage from "./billing/BillingPage";
import Route from "./Route";
import GeneticPage from "./genetic/GeneticPage";
import MainWizard from "./wizard/MainWizard";
import ChannelsList from "./channels/ChannelsList";
import SignalsList from "./signals/SignalsList";
import NotFound from "./NotFound";
import ChannelPage from "./channels/ChannelPage";
import SignalPage from "./signals/SignalPage";
import AgreementsPage from "./agreements/AgreementsPage";

const StrategiesList = () => lazy(() => import("./strategies/StrategiesList"));
const StrategyPage = () => lazy(() => import("./strategies/StrategyPage"));
const CreateStrategy = () =>
  lazy(() => import("./strategies/wizard/CreateStrategy"));

const PrivateRoute = () => {
  const history = useHistory();

  if (!localStorage.getItem("token")) {
    history.replace("/login");
    return null;
  }

  return (
    <Switch>
      <Route exact path='/wizard' component={() => <MainWizard />} />
      <DashboardLayout>
        <Switch>
          <Route exact path='/dashboard' component={Dashboard} />
          <Route exact path='/deals' component={DealsList} />
          <Route exact path='/backtests/:id' component={BacktestPage} />
          <Route exact path='/deals/:id' component={DealPage} />
          <Route exact path='/bots/create' component={CreateBot} />
          <Route exact path='/bots/:id' component={BotPage} />
          <Route exact path='/bots/:id/configure' component={EditBot} />
          <Route exact path='/bots' component={BotsList} />
          <Route exact path='/credentials' render={() => <CredentialsList />} />
          <Route exact path='/credentials/:id' component={CredentialPage} />
          <Route exact path='/credentials/:id/orders' component={OpenOrders} />
          <Route
            exact
            path='/credentials/:id/trade-history'
            component={TradeHistory}
          />
          <Route exact path='/deposits' component={Deposit} />
          <Route exact path='/withdrawal' component={Withdrawal} />
          <Route exact path='/deposit-withdrawal-history' component={History} />
          <Route path='/user' component={ProfileSettings} />
          <Route exact path='/login-history' component={LoginHistory} />
          <Route exact path='/notifications' render={() => <h1>404</h1>} />
          <Route
            exact
            path='/credentials/:id/request-log'
            component={RequestLog}
          />
          <Route exact path='/strategies' component={StrategiesList} />
          <Route exact path='/strategies/create' component={CreateStrategy} />
          <Route exact path='/strategies/:id' component={StrategyPage} />
          <Route exact path='/investments/:id' component={InvestmentPage} />
          <Route
            exact
            path='/exchanges/:exchange/markets/:quote/:base'
            component={ExchangeMarketPage}
          />
          <Route exact path='/rebalance/:id' component={RebalancePage} />
          <Route exact path='/genetic-algorithm/:id' component={GeneticPage} />
          <Route exact path='/billing' component={BillingPage} />
          <Route exact path='/channels' component={ChannelsList} />
          <Route exact path='/channels/:id' component={ChannelPage} />
          <Route exact path='/signals' component={SignalsList} />
          <Route exact path='/signals/:id' component={SignalPage} />
          <Route exact path='/agreements/:id' component={AgreementsPage} />
          <Route exact path='*' component={NotFound} />
        </Switch>
      </DashboardLayout>
    </Switch>
  );
};

export default PrivateRoute;
