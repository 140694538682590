import React from "react";
import { Card } from "reactstrap";

import { ReactComponent as Coin } from "assets/icons/general/coin1.svg";
import Icon from "shared/ui/Icon";

const AvailableBalance = ({ balances }) => {
  if (!balances) return;

  return (
    <Card className='mb-4 p-4'>
      <div className='d-flex align-items-center mb-4'>
        <Coin role='img' />
        <p className='mb-0 font-weight-500 text-white ml-2'>
          Available balances
        </p>
      </div>

      <div className='d-flex align-items-center mb-2 overflow-hidden'>
        <Icon code={balances?.base.coin} size='thumb' />
        <h3 className='ml-2 mr-1 mb-0 text-white font-weight-500 overflow-hidden rbc-ellipsis'>
          {balances?.base.available || "0.00"}
        </h3>
        <h3 className='mb-0 text-white-35 font-weight-300 '>
          {balances?.base.coin}
        </h3>
      </div>
      <div className='d-flex align-items-center mt-1'>
        <Icon code={balances?.quote.coin} size='thumb' />
        <h3 className='ml-2 mr-1 mb-0 text-white font-weight-500 overflow-hidden rbc-ellipsis'>
          {balances?.quote.available}
        </h3>
        <h3 className='mb-0 text-white-35 font-weight-300'>
          {balances?.quote.coin}
        </h3>
      </div>
    </Card>
  );
};

export default AvailableBalance;
