import React from "react";
import { CardBody, Card } from "reactstrap";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const DangerZonePreloader = () => (
  <Card>
    <CardBody>
      <div className='d-flex align-items-center'>
        <SkeletonPreloader circle={true} height={24} width={24} />
        <SkeletonPreloader height={14} width={90} className='ml-2' />
      </div>
    </CardBody>
  </Card>
);

export default DangerZonePreloader;
