import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import Button from "shared/ui/buttons/Button";
import { ReactComponent as SuccessStatus } from "assets/icons/checkbox/successStatus.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { wizardIndicatorsSelector } from "store/strategies/selectors";
import { delay } from "store/helpers";

const FinishBotModal = ({
  title,
  onSetAlertOpen,
  alertOpen,
  redirectPath,
  setIsEdit,
  isEdit,
}) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  const history = useHistory();

  const indicators = useSelector(state => wizardIndicatorsSelector(state));

  const handleToggle = () => {
    onSetAlertOpen(!alertOpen);
  };

  const handleToDetails = async () => {
    if (isEdit) {
      setIsEdit(false);
    }
    await delay(setIsCancelLoading, 1000);
    setIsCancelLoading(false);
    handleToggle();
    history.push(redirectPath);
    indicators.length = 0;
  };

  const handleToCreateBot = async () => {
    await delay(setIsSubmitLoading, 1000);
    setIsSubmitLoading(false);
    window.location.reload();
    history.push({
      pathname: "/bots/create",
    });
  };

  return (
    <Modal isOpen={true} modalClassName='modal-black' centered>
      <ModalBody.Styled className='p-3'>
        <div className='d-flex flex-column align-items-center justify-content-center'>
          <div className='pt-4 mb-3'>
            <SuccessStatus height={61} width={61} />
          </div>
          <h5 className='h3 mb-1'>Success!</h5>
          <span className='mb-4 text-white-75 font-sm'>{title}</span>
          <div className='d-flex flex-row justify-content-between align-items-center w-100 mt-3'>
            <Button
              color='gray'
              loading={isCancelLoading}
              onClick={handleToDetails}
              className='w-50 mr-2'
            >
              Go to details
            </Button>
            <Button
              color='blue'
              loading={isSubmitLoading}
              onClick={handleToCreateBot}
              className='w-50'
            >
              Create a new bot
            </Button>
          </div>
        </div>
      </ModalBody.Styled>
    </Modal>
  );
};

ModalBody.Styled = styled(ModalBody)`
  background: var(--dark-slate);
  border-radius: 8px;
`;

export default FinishBotModal;
