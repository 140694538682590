import React from "react";
import { CardHeader, FormGroup } from "reactstrap";
import styled from "styled-components";

import {
  CONDITIONS_OPTIONS,
  INDICATORS_OPTIONS,
} from "views/bots/models/options";
import { ReactComponent as Celo } from "assets/icons/bot/celo.svg";
import { BotMarketsSelect, SelectInput, SkeletonPreloader } from "shared/ui";

const MarketSummaryHeader = ({
  botId,
  handleFilter,
  analysisLoading,
  loadingPage,
  filter,
  items,
}) => {
  if (loadingPage) {
    return (
      <div className='d-flex align-items-center justify-content-between p-4'>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <SkeletonPreloader width={20} height={20} circle />
            <SkeletonPreloader width={113} height={14} className='ml-2' />
          </div>
          <SkeletonPreloader width={393} height={14} className='mt-3' />
        </div>
        <SkeletonPreloader width={161} height={38} borderRadius='6px' />
      </div>
    );
  }

  return (
    <CardHeader className='d-flex flex-column flex-md-column  justify-content-between py-4 pl-4 pr-3'>
      <div className='d-flex align-items-center'>
        <Celo />
        <p className='mb-0 ml-2 text-white font-weight-500'>Market summary</p>
      </div>
      <MarketSummaryHeader.SelectMarket className='mt-3 mb-0 d-flex justify-content-between '>
        <BotMarketsSelect
          isMulti
          isClearable
          id={botId}
          placeholder='Market'
          name='market'
          isDisabled={analysisLoading || (!items?.length && !filter?.market)}
          onChange={option =>
            handleFilter({
              market: {
                in: option ? option.map(e => e.value) : "",
              },
            })
          }
          value={filter?.market?.in?.map(item => ({
            label: item,
            value: item,
            icon: {
              type: "cryptocurrency",
              size: "selectSize",
              code: item,
            },
          }))}
        />

        <SelectInput
          isClearable
          placeholder='Conditions'
          value={CONDITIONS_OPTIONS.find(
            option => option.value === filter?.conditions,
          )}
          name='conditions'
          onChange={option =>
            handleFilter({ conditions: { eq: option?.value } })
          }
          options={CONDITIONS_OPTIONS}
          isDisabled={
            analysisLoading || (!items?.length && !filter?.conditions)
          }
        />

        <SelectInput
          isClearable
          placeholder='Indicators'
          value={INDICATORS_OPTIONS.find(
            option => option.value === filter?.indicators,
          )}
          name='indicators'
          onChange={option =>
            handleFilter({ indicators: { eq: option?.value } })
          }
          options={INDICATORS_OPTIONS}
          isDisabled={
            analysisLoading || (!items?.length && !filter?.indicators)
          }
        />

        <SelectInput
          isClearable
          placeholder='Pivot point'
          value={INDICATORS_OPTIONS.find(
            option => option.value === filter?.pivotPoint,
          )}
          name='pivotPoint'
          onChange={option =>
            handleFilter({ pivotPoint: { eq: option?.value } })
          }
          options={INDICATORS_OPTIONS}
          isDisabled={
            analysisLoading || (!items?.length && !filter?.pivotPoint)
          }
        />

        <SelectInput
          isClearable
          placeholder='Candles'
          value={INDICATORS_OPTIONS.find(
            option => option.value === filter?.candle,
          )}
          name='candle'
          onChange={option => handleFilter({ candle: { eq: option?.value } })}
          options={INDICATORS_OPTIONS}
          isDisabled={analysisLoading || (!items?.length && !filter?.candle)}
        />
      </MarketSummaryHeader.SelectMarket>
    </CardHeader>
  );
};

MarketSummaryHeader.SelectMarket = styled(FormGroup)`
  gap: 8px;

  & > * {
    white-space: nowrap;
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100% !important;
    flex-direction: column;
  }
`;

export default MarketSummaryHeader;
