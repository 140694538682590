import React from "react";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import _ from "lodash";

const BotsStatisticsPreload = ({ header }) => {
  return (
    <>
      {header && (
        <>
          <div className='d-flex justify-content-between px-4 pt-4'>
            <div className='d-flex'>
              <SkeletonPreloader circle={true} height={20} width={20} />
              <SkeletonPreloader height={16} width={103} className='ml-2' />
            </div>
            <SkeletonPreloader height={10} width={54} />
          </div>
          <div className='d-flex justify-content-between mt-3 mb-4 px-4'>
            <SkeletonPreloader height={38} borderRadius='6px' />
            <SkeletonPreloader
              height={38}
              width={149}
              borderRadius='6px'
              className='ml-2'
            />
          </div>
        </>
      )}
      <div className='d-flex justify-content-between px-4 mb-3'>
        <SkeletonPreloader height={10} width={54} />
        <SkeletonPreloader height={10} width={54} />
      </div>
      {_.times(5, e => {
        return (
          <div
            className='d-flex justify-content-between mb-2 py-1 px-4 mx-1'
            key={e}
          >
            <div className='d-flex'>
              <SkeletonPreloader
                height={24}
                width={24}
                borderRadius='6px'
                className='mr-2'
              />
              <SkeletonPreloader height={17} width={89} />
            </div>
            <SkeletonPreloader height={17} width={147} />
          </div>
        );
      })}
    </>
  );
};

export default BotsStatisticsPreload;
