import React from "react";
import { Card } from "reactstrap";

import ChannelSettingForm from "views/channels/forms/ChannelSettingForm";
import ChannelDefaultWidgetHeader from "views/channels/widgets/reusable/ChannelDefaultWidgetHeader";

const ChannelSettingsWidget = ({ channel }) => {
  return (
    <Card className='p-4'>
      <ChannelDefaultWidgetHeader title='Admin settings' isWidget />
      <ChannelSettingForm
        name={channel?.name}
        id={channel?.id}
        description={channel?.description}
        type={channel?.type}
        telegram={channel?.telegram}
      />
    </Card>
  );
};

export default ChannelSettingsWidget;
