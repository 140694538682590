import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";

import { paginationScrollUp } from "helpers/paginationScrollUp";

const usePagination = (pathname, isScrollToTop, prePaginate) => {
  const location = useLocation();
  const history = useHistory();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  const handlePaginate = page => {
    if (prePaginate && typeof prePaginate === "function") prePaginate();
    history.push({
      pathname,
      search: qs.stringify({ ...search, page }),
    });

    if (isScrollToTop) paginationScrollUp();
  };

  return { handlePaginate };
};

export default usePagination;
