import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Navbar, Container } from "reactstrap";

import Notifications from "../notifications/Notifications";
import UserAvatar from "./UserAvatar";
import Search from "../search/Search";
import Tickers from "../tickers/Tickers";
import { Userlogout } from "store/auth/actions";
import HeaderBalance from "./HeaderBalance";
import SidebarToggle from "./SidebarToggle";
import SubscriptionPlan from "./SubscriptionPlan";
import NavigationPreloader from "./NavigationPreloader";
import useWindowDimensions from "hooks/useWindowDimensions";
import { selectMe } from "store/user/selectors";
import { resetMe, toggleBalanceVisibility } from "store/user/actions";
import usePersistedState from "hooks/usePersistedState";

const DashboardNavigation = ({
  toggleSidebar,
  expanded,
  opacity,
  setExpanded,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownTableRef = useRef();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const { data, loading } = useSelector(selectMe);

  const logOut = () => {
    dispatch(Userlogout());
    dispatch(resetMe());
  };

  const [balanceHidden, setBalanceHidden] = usePersistedState(
    "balanceHidden",
    false,
  );

  useEffect(() => {
    dispatch(toggleBalanceVisibility(balanceHidden));
  }, [balanceHidden, dispatch]);

  if (!data?.id || loading) {
    return (
      <Navbar
        className='navbar-absolute left-0 navbar-transparent pt-md-0'
        expand='lg'
      >
        <NavigationPreloader />
      </Navbar>
    );
  }

  return (
    <Navbar
      className='navbar-absolute left-0 navbar-transparent pt-3 pt-md-0'
      expand='lg'
    >
      {width < 1200 ? (
        <div className='w-100'>
          <Tickers
            dropdownRef={dropdownTableRef}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </div>
      ) : null}
      <DashboardNavigation.Container
        className='d-flex justify-content-between position-fixed top-0 right-0 left-0'
        fluid
        opacity={opacity}
        expanded={expanded}
      >
        <div className='d-flex justify-content-between navbar-wrapper w-100'>
          <div className='d-flex'>
            <SidebarToggle toggleSidebar={toggleSidebar} expanded={expanded} />
            {width > 1200 ? (
              <Tickers
                dropdownRef={dropdownTableRef}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            ) : null}
            <div className='d-block d-lg-none'>
              <HeaderBalance user={data} balanceHidden={balanceHidden} />
            </div>
          </div>
          <DashboardNavigation.NavIconsGroup className='d-flex'>
            <div className='d-none d-lg-block'>
              <HeaderBalance user={data} balanceHidden={balanceHidden} />
            </div>
            <SubscriptionPlan
              subscription={data?.subscription}
              hasTrial={data?.hasTrial}
            />
            <Notifications expanded={expanded} />
            <Search setExpanded={setExpanded} />
            <UserAvatar
              logout={logOut}
              loading={loading}
              avatars={data.avatars}
              subscription={data?.subscription}
              user={data}
              setBalanceHidden={setBalanceHidden}
              balanceHidden={balanceHidden}
            />
          </DashboardNavigation.NavIconsGroup>
        </div>
      </DashboardNavigation.Container>
    </Navbar>
  );
};

DashboardNavigation.Container = styled(Container)`
  background: ${props => (props.opacity === 1 ? "#1D1E2A" : "transparent")};
  border-bottom: ${props =>
    props.opacity === 1 ? "1px solid #222435" : "none"};
  padding: 16px 32px 8px 32px !important;

  @media (min-width: 768px) and (max-width: 1199.98px) {
    padding: 16px 24px 8px 0 !important;
    button {
      margin-left: ${props => (props.expanded ? 0 : "16px")} !important;
    }
  }

  @media (max-width: 1200px) {
    padding: 1rem !important;
    top: 2rem !important;
    margin: 0 !important;
  }
`;

DashboardNavigation.NavIconsGroup = styled.div`
  & button:hover {
    & svg path {
      stroke: #8e8e94;
    }
  }
`;

export default DashboardNavigation;
