import React from "react";

import { StyledMinSignalRow } from "./index.styles";
import StyledValue from "shared/styled/StyledValue";
import { formatterToNull } from "helpers/valuesFormatters";

const MinSignalDisplay = ({ type, signals }) => {
  if (!signals) return null;
  return (
    <StyledMinSignalRow>
      <p className='text-white-75 font-weight-300 font-sm'>
        Minimum{" "}
        <span className={type === "BUY" ? "text-success" : "text-danger"}>
          {type}
        </span>{" "}
        signals
      </p>
      <StyledValue amount={signals}>{formatterToNull(signals)}</StyledValue>
    </StyledMinSignalRow>
  );
};

export default MinSignalDisplay;
