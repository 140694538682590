const defaultState = { data: {}, loading: false, error: false };

const withDataLoading =
  (reducer, name, initialState = defaultState) =>
  (state, action) => {
    const { type, payload } = action;

    switch (type) {
      case `RESET_${name}`:
        return initialState;

      case `GET_${name}_SUCCESS`:
        return {
          ...state,
          loading: false,
          data: payload,
        };

      case `GET_${name}_LOADING`:
        return {
          ...initialState,
          loading: true,
        };

      case `GET_${name}_FAILURE`:
        return {
          ...initialState,
          loading: false,
          error: payload,
        };

      default:
        return reducer ? reducer(state, action) : state || initialState;
    }
  };

export default withDataLoading;
