import React from "react";
import classNames from "classnames";
import moment from "moment";

import { formatterToNull, formatValue } from "helpers/valuesFormatters";
import { replaceDurations } from "helpers/replaceDurations";
import { PercentBadge } from "shared/ui";
import { StyledTableCell, StyledTableRow } from "shared/styled/StyledTable";
import { useRedirect } from "hooks/useRedirect";

const DealRow = ({ item, total }) => {
  const handleRedirect = useRedirect();

  return (
    <StyledTableRow
      onMouseDown={e => handleRedirect(e, `/deals/${item.id}`)}
      className='cursor-pointer'
      total={total}
    >
      <StyledTableCell profit={+item.profit?.percent}>
        <div className='d-flex align-items-center'>
          <p
            className={`mb-0 font-weight-500 ${item.invested.amount > 0 ? "text-white" : "text-white-35"}`}
          >
            {formatterToNull(item.invested.amount)}
          </p>
          <p className='mb-0 text-white-35 ml-1 font-weight-300'>
            {item.invested.asset}
          </p>
        </div>
      </StyledTableCell>
      <td>
        <div className='d-flex align-items-center text-nowrap'>
          <p
            className={classNames("mb-0 font-weight-500", {
              "text-white-35": !item.profit?.amount,
              "text-success": item.profit?.amount > 0,
              "text-danger": item.profit?.amount < 0,
            })}
          >
            {formatValue(item.profit?.amount)}
          </p>
          <p className='mb-0 ml-1 text-white-35 font-weight-300 mr-2'>
            {item.profit?.asset || item.market?.quote}
          </p>
        </div>
      </td>
      <td>
        <PercentBadge value={item.profit?.percent} showZeroValue />
      </td>
      <td>
        <p className='mb-0 text-white font-weight-500 text-nowrap'>
          {replaceDurations(item.duration) || "0m"}
        </p>
      </td>
      <td>
        {item.status === "completed" && item.completedAt ? (
          <p className='mb-0 text-white font-weight-500 text-nowrap'>
            {moment(item.completedAt).format("DD.MM.YYYY HH:mm")}{" "}
          </p>
        ) : null}
        {item.status === "cancelled" && item.cancelledAt ? (
          <p className='mb-0 text-white font-weight-500 text-nowrap'>
            {moment(item.cancelledAt).format("DD.MM.YYYY HH:mm")}{" "}
          </p>
        ) : null}
      </td>
    </StyledTableRow>
  );
};

export default DealRow;
