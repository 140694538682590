import { get } from "lodash";
import { GET_SEARCH_RESULTS, GET_SEARCH_RESULTS_LOADING } from "./constants";

export const search = searchQuery => async (dispatch, getState, fetch) => {
  try {
    dispatch({ type: GET_SEARCH_RESULTS_LOADING });
    const res = await fetch.get(`/app/search?q=${searchQuery}`);
    dispatch({ type: GET_SEARCH_RESULTS, payload: res.data });
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};
