import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import QueryString from "qs";

import MyCredentialsTable from "./MyCredentialTable/MyCredentialsTable";
import { MyCredentialBalances } from "./MyCredentialBalances/MyCredentialBalances";
import BotsWidget from "./BotsWidget";
import {
  selectCredential,
  selectMyCredentialsBots,
} from "store/credentials/selectors";
import RunningDeals from "./RunningDeals";
import { fetchMyCredentialsBots } from "store/credentials/actions";
import { fetchDealsRunning, fetchHistoryDeals } from "store/deals/actions";
import DealsHistory from "./DealsHistory";
import useRequestCancellation from "hooks/useRequestCancellation";
import MarketDetails from "../marketDetails";

const MyCredentialsBlock = ({ exchangeMarketLoading }) => {
  const [isbotsDontLoad, setIsbotsDontLoad] = useState(true);
  const { quote, base, exchange: currentExchange } = useParams();
  const tableRef = useRef(null);
  const { useAbortableEffect } = useRequestCancellation();
  const dispatch = useDispatch();

  const {
    data: { id },
  } = useSelector(selectCredential);
  const { data, loading } = useSelector(selectMyCredentialsBots);
  const hasBots = data?.bots?.length;

  const botsId = useMemo(
    () => data?.bots?.items?.map(item => item.id),
    [data?.bots],
  );

  const fetchCredentialsBots = useCallback(
    async signal => {
      const filter = QueryString.stringify({
        filter: {
          "credentials.exchange": { eq: currentExchange },
        },
        sort: {
          by: "name",
        },
      });
      const res = await dispatch(
        fetchMyCredentialsBots(id, quote, base, `?${filter}`, signal),
      );
      if (res) setIsbotsDontLoad(false);
    },
    [id, quote, base, dispatch, currentExchange],
  );

  useAbortableEffect(
    signal => {
      if (id) {
        fetchCredentialsBots(signal);
      }
    },
    [id, fetchCredentialsBots],
  );

  useAbortableEffect(
    signal => {
      if (hasBots) {
        const historyDealsFilte = QueryString.stringify({
          filter: {
            bot: { in: botsId },
            status: { eq: "completed" },
            market: { in: [`${base}-${quote}`] },
          },
          sort: { by: "completedAt", order: "DESC" },
        });
        const dealsRunningFilter = QueryString.stringify({
          filter: {
            bot: { in: botsId },
            status: { in: ["ready", "running"] },
            market: { in: [`${base}-${quote}`] },
          },
        });
        dispatch(fetchHistoryDeals(`?${historyDealsFilte}`, signal));
        dispatch(fetchDealsRunning(`?${dealsRunningFilter}`, signal));
      }
    },
    [hasBots, dispatch, botsId, base, quote],
  );

  return (
    <MyCredentialsBlock.Wrapper ref={tableRef} className='mb-4'>
      <MyCredentialsTable
        exchangeMarketLoading={exchangeMarketLoading}
        tableRef={tableRef}
        quote={quote}
        base={base}
        currentExchange={currentExchange}
        id={id}
        setIsbotsDontLoad={setIsbotsDontLoad}
      />
      <MyCredentialBalances botId={id} loading={loading && !hasBots} />
      <BotsWidget
        id={id}
        quote={quote}
        base={base}
        data={data}
        fetchCredentialsBots={() => fetchCredentialsBots()}
        loading={loading || isbotsDontLoad}
        hasNotBots={!hasBots}
      />
      <RunningDeals
        botId={id}
        loading={loading || isbotsDontLoad}
        hasNotBots={!hasBots}
      />
      <DealsHistory
        botId={id}
        loading={loading || isbotsDontLoad}
        hasNotBots={!hasBots}
      />
      <MarketDetails
        id={id}
        base={base}
        quote={quote}
        exchange={currentExchange}
        hasNotBots={!hasBots}
        loading={loading || isbotsDontLoad}
      />
    </MyCredentialsBlock.Wrapper>
  );
};

MyCredentialsBlock.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export default React.memo(MyCredentialsBlock);
