import { Card, CardHeader } from "reactstrap";
import styled from "styled-components";

import EmptyChart from "assets/img/chart/EmptyChart.png";

export const StyledStatisticsChartCard = styled(Card)`
  margin-bottom: 32px !important;
  border-radius: 8px !important;
  &.empty {
    background-image: ${props =>
      !props.data ? `url(${EmptyChart}) !important` : "none"};
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
  min-height: 325px;
`;

export const StyledStatisticsCardHeader = styled(CardHeader)`
  svg {
    width: 12px;
    height: 10px;
    & path {
      stroke: var(--info);
    }
  }
`;
