import React, { useState } from "react";
import styled from "styled-components";

import RegisterForm from "./RegisterForm";
import FormDescription from "./compounds/FormDescription";
import Loader from "./Loader";

const RegisterPage = () => {
  const [googleLoading, setGoogleLoading] = useState(false);

  return (
    <div className='content content h-100 d-flex flex-column justify-content-center'>
      <Loader isGoogle isLoading={googleLoading} />
      <div className={googleLoading ? "d-none" : "d-block"}>
        <StyledHeaderWrapper>
          <FormDescription
            title='Register your account'
            text='Darkbot enables you to streamline your trading activities on prominent cryptocurrency platforms through automation.'
          />
        </StyledHeaderWrapper>
        <RegisterForm setGoogleLoading={setGoogleLoading} />
      </div>
    </div>
  );
};

const StyledHeaderWrapper = styled.div`
  margin: 2.5rem 0;
`;

export default RegisterPage;
