import React, { useState } from "react";
import styled from "styled-components";
import { Tooltip } from "reactstrap";

import { formatValue, setColorClass } from "helpers/valuesFormatters";
import BotNameWithIcon from "shared/ui/BotNameWithIcon";

const BotStatistics = ({ bot, index }) => {
  const [tooltipOpenReturn, setTooltipOpenReturn] = useState(false);
  const toggleReturn = () => setTooltipOpenReturn(!tooltipOpenReturn);

  return (
    <BotStatistics.Row className='d-flex align-items-center justify-content-between mb-1'>
      <div className='d-flex align-items-center w-50'>
        <BotNameWithIcon fontClassName='font-sm' withTooltip bot={bot} />
      </div>
      <div className='d-flex align-items-center ml-2 overflow-hidden rbc-ellipsis text-nowrap'>
        <BotStatistics.Paragraph
          className={`${setColorClass(bot.totalReturn, "text-white-35")} font-weight-500 overflow-hidden rbc-ellipsis`}
          id={`totalReturn${index}`}
        >
          {formatValue(bot.totalReturn || 0)}
        </BotStatistics.Paragraph>
        <Tooltip
          placement='top'
          isOpen={tooltipOpenReturn}
          target={`totalReturn${index}`}
          toggle={toggleReturn}
        >
          {bot.totalReturn}
        </Tooltip>
        <BotStatistics.Paragraph className='ml-1 text-white-35 font-weight-300'>
          {bot.investment.asset}
        </BotStatistics.Paragraph>
      </div>
    </BotStatistics.Row>
  );
};

BotStatistics.Row = styled.div`
  cursor: pointer;
  padding: 4px;
  &:hover {
    background: #2e3148;
    border-radius: 8px;
    padding: 4px;
  }
  svg {
    &:focus,
    &:active {
      outline: none;
    }
  }
`;

BotStatistics.Paragraph = styled.p`
  margin-bottom: 0;
  font-size: 14px;
`;

export default BotStatistics;
