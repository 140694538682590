import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import * as Sentry from "@sentry/react";

import Header from "./compounds/Header";
import Settings from "./compounds/Settings";
import TradeRequestsWrapper from "./compounds/TradeRequestsWrapper";
import { rebalanceSelector } from "store/rebalance/selectors";
import { setCredentialsBreadcrumbs } from "models/enum/breadcrumbs";
import {
  fetchRebalance,
  fetchRebalanceTradeRequests,
  resetRebalance,
} from "store/rebalance/actions";
import useRequestCancellation from "hooks/useRequestCancellation";
import {
  Breadcrumbs,
  Credential,
  Error,
  FallBack,
  Investment,
  RefreshButton,
} from "shared/ui";

const RebalancePage = ({ match }) => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(rebalanceSelector);
  const { useAbortableEffect } = useRequestCancellation();

  useAbortableEffect(
    signal => {
      dispatch(fetchRebalance(match.params.id, signal));
      return () => dispatch(resetRebalance());
    },
    [dispatch, match.params.id],
    true,
  );

  useAbortableEffect(
    signal => {
      dispatch(fetchRebalanceTradeRequests(match.params.id, signal));
    },
    [dispatch, match.params.id],
    true,
  );

  const onHandleRefresh = () => {
    dispatch(fetchRebalance(match.params.id));
    dispatch(fetchRebalanceTradeRequests(match.params.id));
  };

  if (error) return <Error error={error} />;

  return (
    <>
      <div className='d-flex align-items-center justify-content-between'>
        <Breadcrumbs
          items={setCredentialsBreadcrumbs(
            data?.investment?.credentials?.exchange,
            data?.investment?.credentials?.id,
            "Rebalance",
          )}
          loading={loading}
        />

        <RefreshButton loading={loading} onHandleRefresh={onHandleRefresh} />
      </div>

      <Header data={data} loading={loading} />
      <Row className='mb-2'>
        <Col xs='12' md='4'>
          <Sentry.ErrorBoundary fallback={<FallBack />}>
            <Settings data={data} loading={loading} />
          </Sentry.ErrorBoundary>
          <Sentry.ErrorBoundary fallback={<FallBack />}>
            <Investment
              investment={data.investment}
              loading={loading}
              runningDeals={data?.investment?.statistics?.deals?.running}
              showDealsCount
            />
          </Sentry.ErrorBoundary>
          <Sentry.ErrorBoundary fallback={<FallBack />}>
            <Credential
              credential={data?.investment?.credentials}
              loading={loading}
            />
          </Sentry.ErrorBoundary>
        </Col>
        <Col xs='12' md='8'>
          <Sentry.ErrorBoundary fallback={<FallBack />}>
            <TradeRequestsWrapper />
          </Sentry.ErrorBoundary>
        </Col>
      </Row>
    </>
  );
};

export default RebalancePage;
