import React from "react";
import { Modal } from "reactstrap";

import StyledModalBody from "./StyledModalBody";
import { Button } from "shared/ui/buttons";

const DeviationInfo = ({ isOpen, setIsOpen }) => {
  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      modalClassName='modal-black'
      size='lg'
    >
      <StyledModalBody>
        <div className='d-flex justify-content-between mb-4'>
          <p className='h1 m-0'>What is Price Deviation?</p>
          <Button color='gray' cancel={true} onClick={() => setIsOpen(false)} />
        </div>
        <p className='mb-3 text-white-75'>
          The price deviation parameter is an important parameter in the average
          down strategy for a crypto trading. It is used to set the percentage
          deviation from the average price of a cryptocurrency that triggers the
          opening of a new position.
        </p>
        <p className='mb-3 text-white-75'>
          For example, suppose you have set the price deviation parameter at 2%.
          If the price of a cryptocurrency falls by 2% from your average entry
          price, the bot will open a new trade request at the lower price. The
          bot will continue to open new trade requests as long as the price
          falls and the deviation from the average price exceeds the set
          parameter.
        </p>
        <p className='mb-3 text-white-75'>
          The price deviation parameter is used to ensure that the bot only
          opens new positions when the price has fallen significantly from the
          average price, thereby reducing the average entry price and increasing
          the potential for profit when the price rebounds. However, it is
          important to set this parameter carefully, as setting it too low could
          result in the bot opening too many positions and increasing the risk
          of loss. On the other hand, setting it too high could result in the
          bot missing out on profitable opportunities.
        </p>
        <p className='mb-3 text-white-75'>
          In general, the price deviation parameter should be set based on the
          volatility of the cryptocurrency being traded and
          {"the trader's risk tolerance. More volatile cryptocurrencies may "}
          require a higher deviation parameter, while less volatile
          cryptocurrencies may require a lower deviation parameter.
          Additionally, the parameter should be adjusted over time based on the
          performance of the bot and the market conditions.
        </p>
      </StyledModalBody>
    </Modal>
  );
};

export default DeviationInfo;
