import React from "react";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import { Card, CardHeader, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import "chartjs-adapter-moment";

import chartConfig from "./helpers";

const TransactionChartStatistics = ({ dataChart, title, linkTo, icon }) => {
  const { chartData } = chartConfig({ data: dataChart });

  return (
    <TransactionChartStatistics.ChartCard className='card-chart'>
      <TransactionChartStatistics.CardHeader className='p-4'>
        <div className='d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between'>
          <div className='d-flex align-items-center mb-2 mb-md-0'>
            {icon}
            <h4 className='ml-2 mb-0 text-white font-weight-500'>{title}</h4>
          </div>
          <Link to={linkTo} className='link-info'>
            Show all
          </Link>
        </div>
      </TransactionChartStatistics.CardHeader>
      <CardBody className='pb-4'>
        <div className='chart-area' style={{ height: "260px" }}>
          <Line data={chartData.config} options={chartData.options} />
        </div>
      </CardBody>
    </TransactionChartStatistics.ChartCard>
  );
};

TransactionChartStatistics.ChartCard = styled(Card)`
  margin-bottom: 2rem !important;
  border-radius: 0.5rem !important;
  min-height: 325px;
`;

TransactionChartStatistics.CardHeader = styled(CardHeader)`
  svg {
    width: 17px;
    height: 13px;
    & path {
      stroke: var(--info);
    }
  }
`;

export default TransactionChartStatistics;
