import React from "react";
import { Card } from "reactstrap";
import PropTypes from "prop-types";

import ChannelDefaultWidgetHeader from "../reusable/ChannelDefaultWidgetHeader";
import ChannelDefaultWidgetBody from "./ChannelSignalsWidgetBody";
import { numberOrNull, stringOrNull } from "views/channels/channelPropTypes";
import ChannelWidgetPreload from "views/channels/preloaders/ChannelWidgetPreload";
import StatisticsChannelBar from "shared/ui/ChannelWidget/StatisticsChannelBar";

const ChannelSignalsWidget = ({
  loading,

  channel,
}) => {
  if (loading)
    return <ChannelWidgetPreload count={3} isEditButton isStatisticsBar />;
  return (
    <Card className='p-4'>
      <ChannelDefaultWidgetHeader
        isWidget
        total={channel?.totalSignals}
        title='Signals'
      />
      <StatisticsChannelBar
        loss={channel?.statistics?.signals.loss}
        open={channel?.statistics?.signals.open}
        profit={channel?.statistics?.signals.profit}
        lastSignalDate={channel?.lastSignalDate}
        className='mt-3 mb-4'
      />
      <ChannelDefaultWidgetBody
        loss={channel?.statistics?.signals.loss}
        open={channel?.statistics?.signals.open}
        profit={channel?.statistics?.signals.profit}
        accepted={channel?.statistics?.signals.accepted}
      />
    </Card>
  );
};

ChannelSignalsWidget.propType = {
  loading: PropTypes.string,
  totalSignals: numberOrNull,
  signalsLoss: numberOrNull,
  signalsOpen: numberOrNull,
  signalsProfit: numberOrNull,
  lastSignalDate: stringOrNull,
};

export default ChannelSignalsWidget;
