import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import styled from "styled-components";

import { ReactComponent as Ellipse } from "assets/icons/general/ellipse.svg";

const BotErrorTooltip = ({ error, index }) => {
  const [tooltipError, setTooltipError] = useState(false);
  const toggleError = () => setTooltipError(!tooltipError);
  if (!error) return;

  return (
    <BotErrorTooltip.Error>
      <Ellipse id={`error-${index}`} />
      <Tooltip
        placement='top'
        isOpen={tooltipError}
        target={`error-${index}`}
        toggle={toggleError}
      >
        {error}
      </Tooltip>
    </BotErrorTooltip.Error>
  );
};

BotErrorTooltip.Error = styled.div`
  margin-bottom: 3px;
  margin-left: 6px;

  svg {
    width: 7.33px;
    &:hover {
      transform: scale(1.5);
      transition: 0.3s;
    }

    circle {
      fill: #ff8d72;
    }
  }
`;

export default BotErrorTooltip;
