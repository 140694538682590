import React from "react";

import ParameterDisplay from "../ParameterDisplay";

const OpenOrderBody = ({
  createdAt,
  tradesCount,
  amount,
  quantity,
  filledPercent,
  price,
  className = "px-4",
}) => {
  return (
    <div
      className={`d-flex align-items-center flex-wrap justify-content-between pb-3 ${className}`}
    >
      <ParameterDisplay
        title='Price'
        amount={price}
        className='col-6 p-0 col-md-3 col-lg-2 col-xl-1'
      />

      <ParameterDisplay
        title='Filled'
        filledPercent={filledPercent}
        className='col-6 p-0 col-md-4 col-lg-3 col-xl-2'
      />

      <ParameterDisplay
        title='Quantity'
        amount={quantity?.amount}
        coin={quantity?.currency}
        className='col-6 mt-2 p-0 col-md-5 mt-md-0 col-lg-3 col-xl-2'
      />
      <ParameterDisplay
        title='Amount'
        amount={amount?.amount}
        coin={amount?.currency}
        className='col-6 mt-2 p-0 col-md-3 mt-md-0 col-lg-4 col-xl-2'
      />

      <ParameterDisplay
        title='Trades'
        amount={tradesCount}
        className='col-6 mt-2 p-0 col-md-2 mt-md-0 col-lg-2 col-xl-1'
      />

      <ParameterDisplay
        title='Amount'
        amount={amount?.amount}
        coin={amount?.currency}
        className='col-6 mt-2 p-0 col-md-3 mt-md-0 col-lg-3 col-xl-2'
      />
      <ParameterDisplay
        title='Time'
        date={createdAt}
        className='col-6 mt-2 p-0 col-md-3 mt-md-0 col-lg-3 col-xl-2'
      />
    </div>
  );
};

export default OpenOrderBody;
