import React from "react";
import styled from "styled-components";
import { Card, CardBody } from "reactstrap";

import { ReactComponent as SandClock } from "assets/icons/general/clock.svg";
import { Icon, IdWithCopy, LineWithError } from "shared/ui";
import { LookupCoins } from "shared/utils";

const Header = ({ asset, id, subscription, connected, error }) => {
  return (
    <>
      <Card>
        <CardBody className='p-4'>
          <div className='d-flex w-100 justify-content-between align-items-center flex-wrap'>
            <div className='d-flex'>
              <Icon
                type='cryptocurrency'
                size='xs'
                code={asset || ""}
                className='mr-3 my-auto'
              />
              <div className='flex-column my-auto'>
                <div className='flex-column'>
                  <div className='d-flex align-items-center'>
                    <h1 className='m-0 text-white font-weight-500'>{asset}</h1>
                    <h1 className='m-0 ml-2 text-white-35 font-weight-300'>
                      <LookupCoins coin={asset} />
                    </h1>
                  </div>
                  <IdWithCopy id={id} className='d-none d-md-flex' />
                </div>
              </div>
            </div>
            <Header.Status status={connected} className='font-sm mt-3 mt-md-0'>
              {connected ? "Active" : "Inactive"}
            </Header.Status>
          </div>
        </CardBody>
        <LineWithError errorMessage={error} />
      </Card>
      {subscription?.type === "premium" ? (
        <div className='mb-4 d-none'>
          <Header.Time>
            <SandClock className='ml-4 mr-2' />
            <p className='mb-0'>
              Trading ill continue after 12:28 due to the maximum daily drawdown
            </p>
          </Header.Time>
        </div>
      ) : null}
    </>
  );
};

Header.Active = styled.div`
  background: rgba(255, 255, 255, 0.05);
  color: #3fcbb0;
  border-radius: 8px;
`;

Header.Inactive = styled.div`
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.35);
  border-radius: 8px;
  padding: 5.5px 13px;
`;

Header.Status = styled.div`
  background: rgba(255, 255, 255, 0.05);
  color: ${({ status }) =>
    status ? "var(--success)" : "rgba(255, 255, 255, 0.35)"};
  border-radius: 8px;
  padding: ${({ status }) => (status ? "5.5px 19px" : "5.5px 13px")};
`;

Header.Time = styled.div`
  display: flex;
  align-items: center;
  background: rgba(234, 72, 72, 0.2);
  width: 50%;
  border-radius: 0 0 0 8px;
  height: 30px;
  svg {
    width: 8px;
  }
`;

export default Header;
