import React from "react";
import styled from "styled-components";

import { ReactComponent as Alert } from "assets/icons/general/alert.svg";

const DealCommentWidget = ({ comment, loading }) => {
  if (loading || !comment) return null;

  return (
    <DealCommentWidget.Wrapper>
      <span className='d-inline-block'>
        <Alert />
      </span>
      <p className='mb-0 text-white ml-2'>{comment}</p>
    </DealCommentWidget.Wrapper>
  );
};

DealCommentWidget.Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: #28324e;
  border-radius: 8px;
  padding: 11px 17px;
  margin-bottom: 24px;
`;

export default DealCommentWidget;
