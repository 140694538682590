import React, { useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { Card, CardBody, CardHeader, Tooltip } from "reactstrap";

import { setColorClass } from "helpers/valuesFormatters";
import BalancePreloader from "./components/BalancePreloader";
import { ReactComponent as BalanceIcon } from "assets/icons/general/BalanceIcon.svg";
import PercentBadge from "shared/ui/PercentBadge";
import { handleHideBalance } from "shared/helpers/handleHideBalance";

const Balance = ({
  balance = {},
  estimated = {},
  dailyRevenueBtc = 0,
  dailyRevenueBtcPercent,
  loading,
  balanceHidden,
}) => {
  const [tooltip, setTooltip] = useState(false);
  const toggle = () => setTooltip(!tooltip);

  if (loading) return <BalancePreloader />;

  return (
    <Card className='mb-4' data-testid='credential-balance-card'>
      <CardHeader className='d-flex align-items-center justify-content-between p-4'>
        <Balance.Title className='d-flex align-items-center'>
          <BalanceIcon data-testid='credential-balance-icon' className='mr-2' />
          <p
            data-testid='credential-balance-title'
            className='mb-0 text-white font-weight-500'
          >
            Balance
          </p>
        </Balance.Title>
        <div id='percent' className='cursor-pointer'>
          <PercentBadge
            value={dailyRevenueBtcPercent}
            isDefault={false}
            showZeroValue
          />
        </div>
        <Tooltip
          target='percent'
          placement='top'
          isOpen={tooltip}
          toggle={toggle}
        >
          Daily change percent
        </Tooltip>
      </CardHeader>
      <CardBody className='px-4 pb-4 pt-0'>
        <div className='d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-lg-center justify-content-between text-nowrap'>
          <p
            data-testid='credential-balance-daily-change'
            className='mb-0 font-sm text-white-75 font-weight-300'
          >
            Daily change:
          </p>
          <div className='d-flex align-items-center font-sm'>
            <p
              data-testid='credential-balance-dailyRevenueBtc'
              className={classNames(
                `${setColorClass(dailyRevenueBtc, "text-white")} mb-0 font-weight-500`,
              )}
            >
              {handleHideBalance(balanceHidden, dailyRevenueBtc)}
              <span className='ml-1 mb-0 font-weight-300 text-white-35'>
                BTC
              </span>
            </p>
          </div>
        </div>
        <div className='d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-lg-center justify-content-between mt-2 text-nowrap'>
          <p
            data-testid='credential-balance-total-amount'
            className='mb-0 font-sm text-white-75 font-weight-300'
          >
            Total amount:
          </p>
          <div className='d-flex align-items-center font-sm'>
            <p
              data-testid='credential-balance-btc'
              className='mb-0 text-white font-weight-500 mr-1'
            >
              {handleHideBalance(balanceHidden, balance.btc || "0.00000000")}
            </p>
            <p
              data-testid='credential-balance-coin'
              className='mb-0 font-weight-300 text-white-35'
            >
              BTC
            </p>
          </div>
        </div>
        <div className='d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-lg-center justify-content-between mt-2'>
          <p
            data-testid='credential-balance-estimated-value'
            className='mb-0 font-sm text-white-75 font-weight-300'
          >
            Estimated value:
          </p>
          <div className='d-flex align-items-center font-sm'>
            <p
              data-testid='credential-balance-estimated-amount'
              className='mb-0 text-white font-weight-500 mr-1'
            >
              {handleHideBalance(balanceHidden, estimated.amount || "0.00000")}
            </p>
            <p
              data-testid='credential-balance-estimated-currency'
              className='mb-0 font-weight-300 text-white-35'
            >
              {estimated.currency}
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

Balance.Title = styled.div`
  svg {
    path {
      stroke: #007bff;
    }
  }
`;

export default Balance;
