import React, { useState } from "react";
import styled from "styled-components";
import { Tooltip } from "reactstrap";

const TagsList = ({ tags = [], uuid = "" }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const idForToolTip = "uuid" + uuid.slice(0, 4);
  const isToolTip = tags.length > 4;
  const tagsForList = isToolTip ? tags.slice(0, 4) : tags;
  const tagsForToolTip = isToolTip ? tags.slice(4) : [];
  return (
    <TagsList.Container>
      {tags.length > 0 ? (
        tagsForList.map(tag => {
          return <TagsList.Badge key={tag}>{tag}</TagsList.Badge>;
        })
      ) : (
        <div style={{ height: "24px" }} />
      )}
      {isToolTip && (
        <>
          <TagsList.Badge id={idForToolTip}>
            +{tagsForToolTip.length}
          </TagsList.Badge>
          <Tooltip
            placement='top'
            isOpen={tooltipOpen}
            target={idForToolTip}
            toggle={() => setTooltipOpen(!tooltipOpen)}
          >
            <div className='d-flex flex-wrap '>
              {tagsForToolTip.map(tag => {
                return <TagsList.Badge key={tag}>{tag}</TagsList.Badge>;
              })}
            </div>
          </Tooltip>
        </>
      )}
    </TagsList.Container>
  );
};

TagsList.Container = styled.div`
  display: flex;
  justify-content: spacet-between;
  overflow: hidden;
`;

TagsList.Badge = styled.div`
  padding: 4px 6px;
  background: #1d1e2a;
  margin-right: 8px;
  margin-bottom: 4px;
  border-radius: 2px;
  border: none;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  max-width: 5vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default TagsList;
