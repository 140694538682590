import React from "react";
import MarketSummary from "./marketSummary/MarketSummary";

const TechnicalAnalysisTab = ({ botId, loadingPage, botData }) => {
  return (
    <MarketSummary botId={botId} loadingPage={loadingPage} botData={botData} />
  );
};

export default TechnicalAnalysisTab;
