import classNames from "classnames";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { firstLetterUpper } from "helpers/valuesFormatters";

const StatusIndicator = ({ status, margin, className }) => (
  <StatusIndicator.Label
    margin={margin}
    className={classNames(`font-sm ${className}`, {
      open: status === "open",
      completed: status === "completed",
      cancelled: status === "cancelled",
      reached: status === "reached",
      pending: status === "pending",
      running: status === "running",
      failed: status === "failed",
    })}
  >
    {firstLetterUpper(status)}
  </StatusIndicator.Label>
);

StatusIndicator.Label = styled.span`
  border-radius: 6px;
  background: var(--white-3, rgba(255, 255, 255, 0.03));
  padding: 0 0.625rem;
  margin: ${props => props.margin || "0 0 0 0.625"}rem;

  &.open {
    color: #db53c7;
  }

  &.completed {
    color: #5fd3bc;
  }

  &.cancelled {
    color: rgba(255, 255, 255, 0.35);
  }

  &.reached {
    color: #5fd3bc;
  }

  &.pending {
    color: var(--info);
  }

  &.running {
    color: #db53c7;
  }

  &.failed {
    color: #ff8d72;
  }
`;

StatusIndicator.propTypes = {
  status: PropTypes.string,
  marginLeft: PropTypes.string,
  className: PropTypes.string,
};

export default StatusIndicator;
