import React from "react";
import { useHistory } from "react-router-dom";

import { ReactComponent as BreadCrumb } from "assets/icons/general/breadRight.svg";
import BreadcrumbsPreLoader from "./ui/BreadcrumbsPreloader";
import { StyledBreadcrumbsLink, StyledBreadcrumbsNav } from "./index.styles";

const Breadcrumbs = ({ items, loading, className = "" }) => {
  const history = useHistory();

  if (loading) return <BreadcrumbsPreLoader repeat={items?.length} />;

  return (
    <StyledBreadcrumbsNav
      data-testid='breadcrumbs-component'
      className={`mb-3 ${className}`}
    >
      <ul className='d-flex m-0 p-0'>
        {items.map(({ label, path }) => (
          <li key={label} className='d-flex align-items-center'>
            <StyledBreadcrumbsLink
              data-testid='breadcrumbs-link-component'
              onClick={() => path && history.push(path)}
              className='mb-0 font-sm text-truncate'
              path={path}
            >
              {label}
            </StyledBreadcrumbsLink>
            <BreadCrumb />
          </li>
        ))}
      </ul>
    </StyledBreadcrumbsNav>
  );
};

export default Breadcrumbs;
