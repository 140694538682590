import React from "react";
import { Card, CardHeader, Modal } from "reactstrap";
import styled from "styled-components";

import BacktestForm from "../../../forms/BacktestForm";
import { ReactComponent as BacktestIcon } from "assets/icons/general/backtest.svg";
import { Button } from "shared/ui/buttons";

const CreateBacktestModal = ({ handleToggleModal, isModalOpen, botId }) => {
  return (
    <StyledModal
      isOpen={isModalOpen}
      toggle={handleToggleModal}
      modalClassName='modal-black'
    >
      <Card className='m-0'>
        <CardHeader className='d-flex justify-content-between px-4 pt-4 pb-3'>
          <div className='d-flex'>
            <BacktestIcon className='my-auto mr-2' />
            <Title className='my-auto font-weight-500'>Run new backtest</Title>
          </div>
          <Button onClick={handleToggleModal} cancel />
        </CardHeader>
        <BacktestForm handleToggleModal={handleToggleModal} botId={botId} />
      </Card>
    </StyledModal>
  );
};

const Title = styled.p`
  font-size: 20px;
  line-height: 23.44px;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 360px;
  }

  @media (max-width: 576px) {
    .modal-dialog {
      width: 100%;
    }
  }

  & .card,
  & .modal-content {
    border-radius: 1rem !important;
  }
`;

export default CreateBacktestModal;
