import { CardHeader } from "reactstrap";
import styled from "styled-components";

export const CredentialItemCardHeaderStyled = styled(CardHeader)`
  cursor: pointer;
  padding: 24px !important;
  background: ${({ isActive }) =>
    isActive
      ? "linear-gradient(0deg, rgba(47, 128, 237, 0.07) 0%, rgba(47, 128, 237, 0.07) 100%), #2E3148 !important"
      : "#2E3148 !important"};
  border: ${({ isActive }) =>
    isActive
      ? "2px solid var(--info) !important"
      : "2px solid var(--BG-2nd-block, #2E3148) !important"};

  &:hover {
    background: #363a54 !important;
    .connect-icon {
      background: #363a54 !important;
    }
  }
`;

export const CredentialItemHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const CredentialItemIconWrapperStyled = styled.div`
  position: absolute;
  transform: scale(-1, 1);
  padding: 2px;
  width: 28px;
  height: 28px;
  right: 10%;
  bottom: -10%;
  background: #2e3148 !important;
  border-radius: 50px;
`;

export const CredentialItemAmountStyled = styled.div`
  opacity: ${({ isTransparent }) => (isTransparent ? 0.2 : 1)};
`;

export const CredentialItemDailyStyled = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  background: rgba(63, 203, 176, 0.1);
  border-radius: 6px;
  padding: 5px 6px;
  background: ${props => {
    if (props.daily > 0) {
      return "rgba(63, 203, 176, 0.1)";
    }
    if (props.daily < 0) {
      return "rgba(237, 95, 95, 0.1)";
    }
    return "rgba(255, 255, 255, 0.1)";
  }};

  p {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
  }
`;

export const CredentialItemStatusWrapperStyled = styled.div`
  display: flex;
  height: 23px;
  padding: 0 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--white-3, rgba(255, 255, 255, 0.03));
`;

export const CredentialItemStatusTextStyled = styled.span`
  color: #ed5f5f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
