import React from "react";
import styled from "styled-components";
import { Col } from "reactstrap";

import logo from "assets/icons/general/Logo.svg";
import textLogo from "assets/icons/general/text-logo.svg";
import Button from "shared/ui/buttons/Button";
import StepsNavigation from "./StepsNavigation";

const Navigation = ({
  currentStep,
  handleSetCurrentStep,
  onSetModalIsOpen,
}) => {
  return (
    <Navbar className='d-flex align-items-center w-100'>
      <Col xs={6} md={4} className='p-0'>
        <img
          src={logo}
          width='158'
          height='38'
          alt='logo'
          className='d-none d-md-block rounded-0'
        />
        <img
          src={textLogo}
          width='102'
          height='12'
          alt='logo'
          className='d-block d-md-none rounded-0'
        />
      </Col>
      <Col md={4} className='d-none p-0 d-md-flex justify-content-center'>
        <StepsNavigation
          handleSetCurrentStep={handleSetCurrentStep}
          currentStep={currentStep}
        />
      </Col>
      <Col xs={6} md={4} className='p-0 d-flex justify-content-end'>
        {currentStep === 1 ? (
          <Button outline onClick={() => onSetModalIsOpen(true)}>
            {"I'll do this later"}
          </Button>
        ) : (
          <div />
        )}
        {currentStep === 4 ? (
          <Button
            className='d-block d-md-none'
            color='blue'
            onClick={() => onSetModalIsOpen(true)}
          >
            Go to dashboard
          </Button>
        ) : null}
      </Col>
    </Navbar>
  );
};

const Navbar = styled.header`
  padding: 1.5rem;

  @media (min-width: 768px) {
    padding: 3.125rem 4.5rem;
  }
`;

export default Navigation;
