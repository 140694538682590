import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import _ from "lodash";
import styled from "styled-components";
import BotSettingsWidgetPreloader from "../BotSettingsWidget/preloaders/BotSettingsWidgetPreloader";

const GenomeWidgetPreloader = () => {
  return (
    <Card className='mr-2 mb-4'>
      <CardHeader className='p-4 d-flex flex-row align-items-center'>
        <SkeletonPreloader circle={true} height={20} width={20} />
        <div className='d-flex flex-column ml-2'>
          <SkeletonPreloader height={12} width={203} />
        </div>
      </CardHeader>
      <CardBody className='px-0 pt-2 pb-0'>
        <Row className='justify-content-between px-4 no-gutters pb-4'>
          <Col md={6} xs={12} className='pr-3'>
            <div className='d-flex flex-row align-items-center mb-4'>
              <SkeletonPreloader height={12} width={73} />
            </div>
            {_.times(3, e => (
              <div key={e}>
                <GenomeWidgetPreloader.Box>
                  <SkeletonPreloader height={9} width={57} />
                  <SkeletonPreloader height={12} width={121} />
                </GenomeWidgetPreloader.Box>
                <BotSettingsWidgetPreloader.Box>
                  <SkeletonPreloader height={9} width={81} />
                  <SkeletonPreloader height={12} width={121} />
                </BotSettingsWidgetPreloader.Box>
              </div>
            ))}
          </Col>
          <Col md={6} xs={12} className='pr-3 pr-md-0 pl-md-3 mt-3 mt-md-0'>
            <div className='mb-2'>
              <div>
                <BotSettingsWidgetPreloader.Box className='mt-0'>
                  <SkeletonPreloader height={9} width={81} />
                  <SkeletonPreloader height={12} width={121} />
                </BotSettingsWidgetPreloader.Box>
              </div>
              <GenomeWidgetPreloader.Box>
                <SkeletonPreloader height={9} width={57} />
                <SkeletonPreloader height={12} width={121} />
              </GenomeWidgetPreloader.Box>
              <BotSettingsWidgetPreloader.Box>
                <SkeletonPreloader height={9} width={81} />
                <SkeletonPreloader height={12} width={121} />
              </BotSettingsWidgetPreloader.Box>
            </div>
            <div className='pt-4'>
              <SkeletonPreloader height={12} width={73} className='mb-3' />
              <div className='d-flex flex-row align-items-center flex-nowrap'>
                <GenomeWidgetPreloader.Card className='w-50'>
                  <div className='mb-2'>
                    <SkeletonPreloader height={14} width={73} />
                  </div>
                  <SkeletonPreloader height={9} />
                </GenomeWidgetPreloader.Card>
                {_.times(2, e => (
                  <GenomeWidgetPreloader.Card className='w-25 ml-2' key={e}>
                    <div className='mb-2'>
                      <SkeletonPreloader height={14} />
                    </div>
                    <SkeletonPreloader height={9} />
                  </GenomeWidgetPreloader.Card>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <div className='d-flex flex-column pt-2'>
          {_.times(4, e => (
            <GenomeWidgetPreloader.Indicator
              className='d-flex flex-row align-items-center'
              key={e}
            >
              <SkeletonPreloader circle={true} height={24} width={24} />
              <SkeletonPreloader height={12} width={83} className='ml-2' />
              <SkeletonPreloader height={9} width={96} className='ml-3' />
              <SkeletonPreloader
                height={26}
                width={43}
                borderRadius={"4px"}
                className='ml-auto'
              />
            </GenomeWidgetPreloader.Indicator>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

GenomeWidgetPreloader.Card = styled.div`
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.03);
  padding: 8px 12px;
`;

GenomeWidgetPreloader.Indicator = styled.div`
  padding: 12px 16px;
  border-top: 1px solid #2e3148;
`;

GenomeWidgetPreloader.Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`;

export default GenomeWidgetPreloader;
