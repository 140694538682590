import React from "react";
import { CardHeader, FormGroup } from "reactstrap";
import styled from "styled-components";

import { ReactComponent as Logo } from "assets/icons/general/command.svg";
import { GS_STATUS } from "models/bot/filters";
import { BotMarketsSelect, SelectInput } from "shared/ui";

const GSHeader = ({ botId, handleFilter, request, loading }) => {
  return (
    <CardHeader className='d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between py-3 pl-4 pr-3'>
      <div className='d-flex align-items-center'>
        <Logo />
        <p className='mb-0 ml-2 font-weight-500 text-white'>
          Genetic strategies
        </p>
      </div>

      <GSHeader.Selects className='d-flex align-items-center mt-3 mt-sm-0'>
        <FormGroup className='pr-2 mb-2 mb-md-0 w-50 selects'>
          <BotMarketsSelect
            isMulti
            isClearable
            id={botId}
            placeholder='Market'
            name='market'
            isDisabled={loading}
            onChange={option =>
              handleFilter({
                market: { in: option ? option.map(e => e.value) : "" },
              })
            }
            value={request?.filter?.market?.in?.map(item => ({
              label: item,
              value: item,
              icon: {
                type: "cryptocurrency",
                size: "selectSize",
                code: item,
              },
            }))}
          />
        </FormGroup>

        <FormGroup className='mb-0 mr-3 mb-2 mb-md-0 w-50 selects'>
          <SelectInput
            isClearable
            placeholder='Status'
            name='status'
            width='100%'
            isDisabled={loading}
            onChange={option =>
              handleFilter({ "ga.status": { eq: option?.value } })
            }
            options={GS_STATUS}
          />
        </FormGroup>
      </GSHeader.Selects>
    </CardHeader>
  );
};

GSHeader.Selects = styled.div`
  @media (max-width: 576px) {
    width: 100% !important;
    .selects {
      width: 100% !important;
    }
  }
  width: 50%;
`;

export default GSHeader;
