import { capitalize } from "lodash";
import { cryptoSymbol } from "crypto-symbol";

const { nameLookup } = cryptoSymbol({});

export const setCredentialsBreadcrumbs = (exchange, id, title) => {
  return [
    { label: "Credentials", path: "/credentials" },
    { label: capitalize(exchange), path: `/credentials/${id}` },
    { label: title, path: "" },
  ];
};

export const setCredentialsPageBreadcrumbs = exchange => {
  return [
    { label: "Credentials", path: "/credentials" },
    { label: capitalize(exchange), path: "" },
  ];
};

export const setBotPageBreadcrumbs = (
  exchange,
  credId,
  investmentId,
  asset,
  title,
  botId,
  isEdit,
) => {
  const coin = nameLookup(asset, { exact: true });
  return [
    { label: "Credentials", path: "/credentials" },
    { label: capitalize(exchange), path: `/credentials/${credId}` },
    { label: `${asset} ${coin}`, path: `/investments/${investmentId}` },
    { label: "Bots", path: "/bots", isClickable: true },
    { label: title, path: isEdit ? `/bots/${botId}` : "" },
  ];
};

export const setDealPageBreadcrumbs = (
  exchange,
  credId,
  investmentId,
  asset,
  title,
  botId,
) => {
  return [
    { label: "Credentials", path: "/credentials" },
    { label: capitalize(exchange), path: `/credentials/${credId}` },
    { label: asset, path: `/investments/${investmentId}` },
    { label: "Bots", path: "/bots" },
    { label: title, path: `/bots/${botId}` },
    { label: "Deals", path: "" },
  ];
};

export const setGAPageBreadcrumbs = (
  exchange,
  asset,
  investmentId,
  base,
  pair,
  title,
) => {
  return [
    { label: "Credentials", path: "/credentials" },
    {
      label: capitalize(exchange),
      path: `/credentials?filter%5Bexchange%5D%5Bin%5D%5B0%5D=${exchange}`,
    },
    { label: asset, path: `/investments/${investmentId}` },
    { label: pair, path: `/exchanges/${exchange}/markets/${asset}/${base}` },
    { label: title, path: "" },
  ];
};

export const setExchangePageBreadcrumbs = (exchange, quote, base) => {
  return [
    { label: "Exchange", path: "" },
    { label: capitalize(exchange), path: "" },
    { label: "Spot", path: "" },
    { label: quote, path: "" },
    { label: base, path: "" },
  ];
};

export const setChannelsBreadcrumbs = (exchange, id, isEdit) => {
  return [
    { label: "Channels", path: "/channels" },
    { label: capitalize(exchange), path: isEdit ? `/channels/${id}` : "" },
  ];
};

export const setSignalBreadcrumbs = (channelName, id) => {
  return [
    { label: "Channels", path: "/channels" },
    { label: channelName, path: `/channels/${id}` },
    { label: "Signals", path: "" },
  ];
};

export const setAgreementsBreadcrumbs = (
  exchange,
  credentialsId,
  asset,
  investmentId,
) => {
  return [
    { label: "Credentials", path: "/credentials" },
    { label: capitalize(exchange), path: `/credentials/${credentialsId}` },
    { label: asset, path: `/investments/${investmentId}` },
    { label: "Agreements", path: "" },
  ];
};
