import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { Card, CardBody } from "reactstrap";

import Error from "shared/ui/state/Error";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import { changePassword } from "store/user/actions";
import ChangePasswordForm from "../forms/ChangePasswordForm";

const ChangePassword = ({ loading, error }) => {
  if (loading) {
    return (
      <Card className='p-3'>
        <CardBody className='p-2'>
          <SkeletonPreloader className='mb-4' width={88} height={16} />
          <div className='d-flex flex-column'>
            {_.times(3, e => (
              <div key={e}>
                <SkeletonPreloader className='mb-2' width={88} height={12} />
                <ChangePassword.InputPreLoader className='mb-4 w-100' />
              </div>
            ))}
            <div className='mt-3 d-flex justify-content-end'>
              <ChangePassword.ButtonPreLoader className='p-2 w-25 d-flex justify-content-center' />
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <Card className='p-3'>
      <CardBody className='p-2'>
        <ChangePasswordForm onChangePassword={changePassword} />
      </CardBody>
    </Card>
  );
};

ChangePassword.InputPreLoader = styled.div`
  background: #32354e;
  opacity: 0.5;
  border-radius: 6px;
  height: 38px;
`;

ChangePassword.ButtonPreLoader = styled.div`
  background: linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 100%);
  border-radius: 6px;
  height: 38px;
`;

export default ChangePassword;
