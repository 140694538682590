import React, { useState } from "react";
import PropTypes from "prop-types";
import { CardBody } from "reactstrap";

import SignalsEmptyState from "./SignalsEmptyState";
import ChannelSignalsTablePreloader from "views/channels/preloaders/ChannelSignalsTablePreloader";
import SignalsRow from "./SignalsRow";
import MyAgreementsEmpty from "./MyAgreementsEmpty";
import MyAgreementsRow from "./AgreementsRow";
import { CustomScrollbars, SortTableHead } from "shared/ui";
import { StyledTable } from "shared/styled/StyledTable";

const SignalsBody = ({
  loading,
  loadingPage,
  items,
  owner,
  total,
  sort,
  handleSorting,
  theadItems,
  subscribed,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  if (!loadingPage && !loading && !items?.length) {
    return (
      <SignalsEmptyState
        title={`${subscribed ? "Looks like there are no signals yet" : "Please subscribe to the channel to see the signals"}`}
        description={`${
          subscribed && owner
            ? "Please create signal channels first to have them displayed here."
            : ""
        }`}
      />
    );
  }

  const renderAgreement = signal => {
    if (signal?.agreements?.length) {
      return signal?.agreements?.map(agreement => (
        <MyAgreementsRow key={agreement?.id} agreement={agreement} />
      ));
    } else {
      return (
        <MyAgreementsEmpty
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          status={signal.status}
          signalId={signal?.id}
        />
      );
    }
  };

  return (
    <CardBody className='p-0'>
      <CustomScrollbars
        autoHideTimeout={500}
        autoHideDuration={500}
        autoHeight
        autoHeightMax={Number.MAX_VALUE}
      >
        {loadingPage || loading ? (
          <ChannelSignalsTablePreloader />
        ) : (
          <StyledTable className='mb-0'>
            <SortTableHead
              handleSorting={handleSorting}
              sort={sort}
              theadItems={theadItems}
            />
            <tbody className='font-sm'>
              {items?.map(signal => {
                return (
                  <>
                    <SignalsRow
                      key={signal?.id}
                      exchange={signal?.exchange}
                      signal={signal}
                      total={total}
                    />
                    {renderAgreement(signal)}
                  </>
                );
              })}
            </tbody>
          </StyledTable>
        )}
      </CustomScrollbars>
    </CardBody>
  );
};

SignalsBody.propTypes = {
  isCompletedTabl: PropTypes.bool,
  loading: PropTypes.bool,
  loadingPage: PropTypes.bool,
  owner: PropTypes.bool,
  total: PropTypes.number,
  handleSorting: PropTypes.func,
  theadItems: PropTypes.arrayOf(
    PropTypes.shape({
      isSortable: PropTypes.bool,
      label: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  sort: PropTypes.shape({
    by: PropTypes.string,
    order: PropTypes.string,
  }),
};

export default SignalsBody;
