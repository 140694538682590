import React from "react";

import { ReactComponent as Coffee } from "assets/icons/bot/coffee.svg";
import StyledSubCard from "./StyledSubCard";
import { replaceDurations } from "helpers/replaceDurations";

const DealsLifeTime = ({ summary = {} }) => {
  if (
    !summary.averageDealLifetime &&
    !summary.minimalDealLifetime &&
    !summary.maximalDealLifetime
  )
    return (
      <StyledSubCard className='d-flex'>
        <Coffee className='my-auto' />
        <div className='d-flex flex-column ml-3'>
          <p className='h3 m-0 text-white'>No data to display</p>
          <label className='m-0'>
            This backtest doesn’t have any deals yet.
          </label>
        </div>
      </StyledSubCard>
    );

  return (
    <StyledSubCard className='d-flex justify-content-between'>
      <div className='d-flex flex-column'>
        <label className='m-0 text-white-35'>Average deal lifetime</label>
        <p className='h3 m-0 text-white'>
          {replaceDurations(summary.averageDealLifetime)}
        </p>
      </div>
      <div className='d-flex flex-column'>
        <p className='m-0 font-sm'>
          <span className='text-white-35 mr-2 font-weight-300'>Min.</span>
          <span className='text-white font-weight-500'>
            {replaceDurations(summary.minimalDealLifetime)}
          </span>
        </p>
        <p className='m-0 font-sm'>
          <span className='text-white-35 mr-2 font-weight-300'>Max.</span>
          <span className='text-white font-weight-500'>
            {replaceDurations(summary.maximalDealLifetime)}
          </span>
        </p>
      </div>
    </StyledSubCard>
  );
};

export default DealsLifeTime;
