import {
  cancelOpenDeal,
  completeOpenDeal,
  restartOpenDeal,
} from "store/deals/actions";

export const DEALS_CONTENT = {
  details: {
    widgets: {
      dangerZone: {
        title: "Danger zone",
        subtitle:
          "This is manual actions allowed for this deal. Please keep in mind that bot will cancel or complete this deal according to your configuration settings.",
        actions: {
          complete: {
            name: "Complete",
            title: "Confirm deal Completion",
            warning:
              "Are you sure you want to force complete this deal? All purchased coins will be sold as soon as possible with current estimated return.",
            actionFunction: completeOpenDeal,
          },
          restart: {
            name: "Restart",
            title: "Please confirm the deal reset",
            warning:
              "Before resetting, please ensure there are no purchased coins associated with this deal. This action is irreversible.",
            actionFunction: restartOpenDeal,
          },
          cancel: {
            name: "Cancel",
            title: "Confirm deal Cancellation",
            warning:
              "Are you sure you want to cancel this deal? All open trade requests will be cancelled and deal will be closed.",
            actionFunction: cancelOpenDeal,
          },
        },
      },
    },
  },
  list: {
    errorState: {
      title: "Oops, something went wrong!",
      descriptionBefore:
        "Sorry! It seems there is a problem on our end. Please try again in a few moments!",
    },
    emptyState: {
      title: "You have no open deals at the moment",
      description:
        "You may want to try enabling your bots, or just keep calm, and wait while they are working if they are already enabled.",
    },
    header: {
      title: "Deals",
    },
    deal: {
      invested: "Invested",
      purchased: "Purchased",
    },
  },
};
