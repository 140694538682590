import React from "react";
import styled from "styled-components";
import { Card, CardBody } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as BackBreadCrumb } from "assets/icons/credential/backTo.svg";
import HeaderLoader from "./HeaderLoader";
import { Icon } from "shared/ui";

const TradeHistoryHeader = ({ exchange = "", loading }) => {
  const history = useHistory();
  const { id } = useParams();

  const handleBackToCredentials = () => history.push(`/credentials/${id}`);

  if (loading) return <HeaderLoader />;

  return (
    <TradeHistoryHeader.TradeHistoryWrapper>
      <TradeHistoryHeader.Card>
        <CardBody>
          <div className='d-flex flex-column flex-md-row justify-content-between'>
            <div className='d-flex ml-3 pt-2 pb-2'>
              <Icon
                type='exchanges'
                size='xs'
                className='my-auto mr-3'
                code={exchange}
              />
              <div className='flex-column my-auto'>
                <div className='flex-column'>
                  <TradeHistoryHeader.Title>
                    Trade history
                  </TradeHistoryHeader.Title>
                  <p className='m-0 text-white-35 font-sm'>
                    List of all trades in your exchange account
                  </p>
                </div>
              </div>
            </div>
            <div
              className='d-flex align-items-center mr-2 cursor-pointer'
              onClick={handleBackToCredentials}
            >
              <TradeHistoryHeader.BackBreadCrumb>
                <BackBreadCrumb />
              </TradeHistoryHeader.BackBreadCrumb>
              <TradeHistoryHeader.Back>
                Back to credentials
              </TradeHistoryHeader.Back>
            </div>
          </div>
        </CardBody>
      </TradeHistoryHeader.Card>
    </TradeHistoryHeader.TradeHistoryWrapper>
  );
};

TradeHistoryHeader.TradeHistoryWrapper = styled.div`
  .breadcrumb-item {
    margin: 0;
    color: rgba(255, 255, 255, 0.35);
    cursor: pointer;
    z-index: 10000;

    &:last-child {
      color: var(--white);
      cursor: default;
    }

    &:hover {
      color: var(--white) !important;
      transition: 0.5s all ease;
    }
  }
`;

TradeHistoryHeader.Card = styled(Card)`
  margin-bottom: 16px !important;
  border-radius: 8px !important;
`;

TradeHistoryHeader.Back = styled.p`
  color: rgba(255, 255, 255, 0.3) !important;
  margin-bottom: 0;
  cursor: pointer;
  margin-left: 18px;
  font-weight: 500;
  font-size: 16px;

  &:hover {
    color: rgba(255, 255, 255, 0.7) !important;
    text-decoration: underline;
    transition: 0.5s all ease;
  }
`;

TradeHistoryHeader.BackBreadCrumb = styled.div`
  svg {
    path {
      stroke: rgba(255, 255, 255, 0.35);
    }
  }
`;

TradeHistoryHeader.Title = styled.p`
  font-size: 24px;
  font-weight: 500;
  color: #ffffff !important;
  margin-bottom: 0;
`;

export default TradeHistoryHeader;
