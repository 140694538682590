import React from "react";

import Button from "../buttons/Button";
import styled from "styled-components";

const SocialButton = ({ url, children, ...props }) => {
  const handleClick = () => {
    window.open(url, "_blank");
  };

  return (
    <SocialButton.Button className='px-2' onClick={handleClick} {...props}>
      {children}
    </SocialButton.Button>
  );
};

export default SocialButton;

SocialButton.Button = styled(Button)`
  &.btn-gray {
    background: #2e3148 !important;

    &:hover {
      background: #3a3d5a !important;
    }
    &:active {
      background: #3a3d5a !important;
    }
  }
`;
