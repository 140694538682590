import React, {
  createContext,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAvailableMarkets } from "store/markets/actions";
import { setInvestment } from "store/bots/wizard/actions";
import { getPeriods } from "store/exchanges/actions";
import { wizardIndicatorsSelector } from "store/strategies/selectors";
import { selectCurrentPlan } from "store/user/selectors";
import { handleInitValues } from "views/bots/EditBot/editBotConfiguration";

const EditBotContext = createContext();

export const useEditBotContext = () => useContext(EditBotContext);

export const EditBotProvider = ({ data, loading, wizardMarkets, children }) => {
  const mounting = useRef(false);

  const [submitted, setSubmitted] = useState(false);
  const [botType, setBotType] = useState(data?.type);
  const [isOpenSettings, setIsOpenSettings] = useState(false);

  const initialValues = handleInitValues(data, botType)?.[botType];
  const indicators = useSelector(wizardIndicatorsSelector);
  const currentPlan = useSelector(selectCurrentPlan);

  const dispatch = useDispatch();

  useEffect(() => {
    const { exchange } = wizardMarkets?.investment?.credentials || {};
    if (exchange) {
      dispatch(getPeriods(exchange));
    }
  }, [wizardMarkets?.investment?.credentials?.exchange, dispatch]);

  useEffect(() => {
    if (data?.type) {
      if (!mounting.current) dispatch(getAvailableMarkets(data.investment.id));
      dispatch(setInvestment(data));
      setBotType(data.type);
    }

    return () => {
      mounting.current = true;
      dispatch(setInvestment({}));
    };
  }, [dispatch, data?.type]);

  return (
    <EditBotContext.Provider
      value={{
        botData: data,
        loading,
        botType,
        setBotType,
        initialValues,
        currentPlan,
        submitted,
        setSubmitted,
        indicators,
        isOpenSettings,
        setIsOpenSettings,
        wizardMarkets,
      }}
    >
      {children}
    </EditBotContext.Provider>
  );
};
