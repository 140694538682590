import React from "react";
import { ReactComponent as EmptyDealsIcon } from "assets/icons/general/EmptyDealsHistory.svg";

const EmptyDealsHistory = () => {
  return (
    <div
      className='d-flex flex-column align-items-center'
      style={{ padding: "95px 0" }}
    >
      <EmptyDealsIcon />
      <h3 className='text-white mt-3 font-weight-500 mb-2'>
        This bot has no deals history yet
      </h3>
      <p className='px-5 font-sm text-center'>
        To see the deals displayed here, please check if your bot is enabled, or
        try to force run the deal by clicking the “Force run” button
      </p>
    </div>
  );
};

export default EmptyDealsHistory;
