import React from "react";
import styled from "styled-components";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import BacktestTab from "./Backtest/BacktestTab";
import DealsTab from "./Deals/DealsTab";
import MachineLearningTab from "./MachineLearning/MachineLearningTab";
import MarketsTab from "./Markets/MarketsTab";
import StatisticsTab from "./Statistics/StatisticsTab";
import TechnicalAnalysisTab from "./TechnicalAnalysis/TechnicalAnalysisTab";

const pages = {
  deals: "Deals",
  markets: "Markets",
  statistics: "Statistics",
  technicalAnalysis: "Technical analysis",
  machineLearning: "Machine learning",
  backtest: "Backtest",
};

const PagesView = ({
  data,
  loadingPage,
  id,
  location,
  pageView,
  setPagView,
}) => {
  return (
    <>
      {loadingPage ? (
        <div className='mb-4'>
          <div className='d-flex align-items-center'>
            <SkeletonPreloader height={11} width={66} />
            <SkeletonPreloader height={11} width={66} className='mx-4' />
            <SkeletonPreloader height={11} width={66} />
            <SkeletonPreloader height={11} width={66} className='mx-4' />
            <SkeletonPreloader height={11} width={66} />
          </div>
          <SkeletonPreloader height={3} width={66} />
        </div>
      ) : (
        <PagesView.Page className='d-flex justify-content-center justify-content-md-start flex-wrap'>
          {Object.entries(pages).map(page => {
            if (data.type !== "genetic" && page[0] === "machineLearning")
              return null;
            return (
              <div className='mb-3 mb-md-0' key={page[0]}>
                <PagesView.Tab
                  onClick={() => setPagView(page[0])}
                  current={pageView}
                  tab={page[0]}
                  className='text-nowrap mb-2'
                >
                  {page[1]}
                </PagesView.Tab>
                <span className={`${pageView === page[0] ? "active" : ""}`} />
              </div>
            );
          })}
        </PagesView.Page>
      )}
      {pageView === "deals" && (
        <DealsTab loadingPage={loadingPage} id={id} location={location} />
      )}
      {pageView === "markets" && (
        <MarketsTab loadingPage={loadingPage} id={id} botType={data?.type} />
      )}
      {pageView === "technicalAnalysis" && (
        <TechnicalAnalysisTab
          botId={id}
          loadingPage={loadingPage}
          botData={data}
        />
      )}
      {pageView === "statistics" && (
        <StatisticsTab id={id} loadingPage={loadingPage} data={data} />
      )}
      {pageView === "machineLearning" && <MachineLearningTab botId={id} />}
      {pageView === "backtest" && (
        <BacktestTab id={id} loadingPage={loadingPage} />
      )}
    </>
  );
};

PagesView.Page = styled.div`
  display: flex;
  margin-bottom: 24px;

  div {
    &:nth-child(2) {
      margin: 0 24px;
    }

    &:nth-child(4) {
      margin: 0 24px;
    }

    &:nth-child(6) {
      margin: 0 24px;
    }

    span {
      &.active {
        display: block;
        border-bottom: 3px solid var(--info);
        border-radius: 2px;
      }
    }
  }
`;

PagesView.Tab = styled.p`
  cursor: pointer;
  font-weight: 500;
  color: ${({ current, tab }) =>
    current === tab ? "#fff" : "rgba(255,255,255, .35)"};

  &:hover {
    color: #fff;
  }
`;

export default PagesView;
