import styled from "styled-components";

export const StyledDefaultPreloader = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;

  @media (min-width: 578px) and (max-width: 1199.98px) {
    display: grid;
    grid-template-columns: 48% 48%;
    justify-content: space-between;
  }

  @media (max-width: 577.98px) {
    display: flex;
    flex-direction: column;
  }
`;
