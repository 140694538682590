import React, { useState } from "react";
import styled from "styled-components";
import Button from "shared/ui/buttons/Button";
import { FormGroup, Col, Label, Row } from "reactstrap";
import { Formik, Form, Field } from "formik";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import PhoneInput from "react-phone-input-2";

import { handleResponse } from "helpers/errorsValidator";
import { SET_BALANCE } from "store/user/constants";
import { addSnackBar } from "store/snackbar/actions";
import {
  GENDER_FIELDS,
  handleInitUserValues,
  handleUserSchema,
} from "../compounds/userConfiguration";
import ProfileSettingsPreloader from "../preloaders/ProfileSettingsPreloader";
import { DateField, SelectInput } from "shared/ui";

const ProfileSettingsForm = ({
  loading,
  user,
  editUser,
  currencies = {},
  languages = {},
  countries = {},
}) => {
  const initialValues = handleInitUserValues(user);

  const [submitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  if (loading) return <ProfileSettingsPreloader />;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={handleUserSchema}
      onSubmit={async (values, formik) => {
        formik.setSubmitting(true);
        setSubmitted(true);
        const res = await dispatch(
          editUser({
            ...values,
            phone: !values.phone ? null : "+" + values.phone,
          }),
        );
        if (res.errors) {
          handleResponse(res, formik.setErrors);
        } else {
          dispatch({ type: SET_BALANCE, payload: res.item.holdings.currency });
          dispatch(addSnackBar("success", res.message));
          formik.setStatus({ message: res.message });
        }
        formik.setSubmitting(false);
      }}
    >
      {({ errors, setFieldValue, values, isSubmitting }) => (
        <Form>
          <h4 className='font-weight-500 text-white'>Personal information</h4>
          <ProfileSettingsForm.Wrapper>
            <Row>
              <Col xs='12' lg='6'>
                <Label>Name</Label>
                <FormGroup>
                  <Field
                    className={classNames("form-control", {
                      "has-error": submitted && errors.name,
                    })}
                    type='text'
                    name='name'
                  />
                </FormGroup>
              </Col>
              <Col xs='12' lg='6'>
                <Label>Phone</Label>
                <FormGroup
                  className={classNames("form-control p-0 mb-1", {
                    "has-error": submitted && (errors.phone || errors.message),
                  })}
                >
                  <PhoneInput
                    autoFormat={true}
                    enableAreaCodes
                    enableTerritories
                    enableSearch
                    specialLabel=''
                    value={values.phone}
                    onChange={value => setFieldValue("phone", value)}
                    name='phone'
                    placeholder=''
                  />
                </FormGroup>
                {submitted && (errors.phone || errors.message) ? (
                  <p className='text-danger font-weight-300 mb-0 font-sm'>
                    {errors.phone || errors.message}
                  </p>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xs='12' lg='6'>
                <Label>Gender</Label>
                <FormGroup>
                  <SelectInput
                    hasError={errors.currency}
                    isDark={true}
                    name='gender'
                    className='w-100'
                    defaultValue={{ label: "" }}
                    value={GENDER_FIELDS.find(
                      item => item.value === values.gender,
                    )}
                    onChange={({ value }) => setFieldValue("gender", value)}
                    options={GENDER_FIELDS.map(item => ({
                      label: item.label,
                      value: item.value,
                    }))}
                  />
                </FormGroup>
              </Col>
              <Col xs='12' lg='6'>
                <Label>Main currency</Label>
                <FormGroup>
                  <SelectInput
                    isLoading={currencies.loading}
                    hasError={errors.currency}
                    isDark={true}
                    name='currency'
                    className='w-100'
                    defaultValue={{ label: "" }}
                    value={{ label: currencies.data[values.currency] }}
                    onChange={({ value }) => setFieldValue("currency", value)}
                    options={Object.keys(currencies.data).map(key => ({
                      label: currencies.data[key],
                      value: key,
                    }))}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs='12' lg='6'>
                <Label>Birthday</Label>
                <DateField
                  initialViewMode='days'
                  onChange={value => setFieldValue("birthday", value)}
                  dateFormat='YYYY-MM-DD'
                  timeFormat={false}
                  inputProps={{
                    className: "form-control",
                    name: "birthday",
                    value: values.birthday || "",
                    readOnly: true,
                  }}
                />
              </Col>
              <Col xs='12' lg='6'>
                <Label>Language</Label>
                <FormGroup>
                  <SelectInput
                    isLoading={languages.loading}
                    isDark={true}
                    name='language'
                    className='w-100'
                    value={{ label: languages.data[values.language] }}
                    onChange={({ value }) => setFieldValue("language", value)}
                    options={Object.keys(languages.data).map(key => ({
                      label: languages.data[key],
                      value: key,
                    }))}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs='12' md='12'>
                <Label>Country</Label>
                <FormGroup>
                  <SelectInput
                    isLoading={countries.loading}
                    isDark={true}
                    name='country'
                    className='w-100'
                    value={{ label: countries.data[values.country] }}
                    onChange={({ value }) => setFieldValue("country", value)}
                    options={Object.keys(countries.data).map(key => ({
                      label: countries.data[key],
                      value: key,
                    }))}
                    isSearchable
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs='12' md='12'>
                <Label>City</Label>
                <FormGroup>
                  <Field type='text' name='city' className='form-control' />
                </FormGroup>
              </Col>
            </Row>
            <Row className='mb-4'>
              <Col xs='12'>
                <Label>About you</Label>
                <FormGroup>
                  <Field
                    type='text'
                    component='textarea'
                    name='about'
                    className='form-control'
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className='pull-right'>
              <Button
                color='blue'
                loading={isSubmitting}
                onClick={() => {
                  setSubmitted(true);
                }}
                type='submit'
              >
                Save Changes
              </Button>
            </div>
          </ProfileSettingsForm.Wrapper>
        </Form>
      )}
    </Formik>
  );
};

ProfileSettingsForm.Wrapper = styled.div`
  label {
    font-size: 14px !important;
    color: rgba(255, 255, 255, 0.75) !important;
  }

  textarea {
    font-size: 14px !important;
    padding: 11px !important;
  }
`;

export default ProfileSettingsForm;
