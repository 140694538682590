import React from "react";
import { CardBody } from "reactstrap";

import RunningDealsTableRow from "./RunningDealsTableRow";
import RunningEmptyState from "./RunningEmptyState";
import { CustomScrollbars, PaginationPanel, SortTableHead } from "shared/ui";
import { StyledTable } from "shared/styled/StyledTable";
import { EXCHANGE_RUNNING_DEALS_TABLE_HEAD } from "shared/models/exchanges/tables";

const RunningDealsTable = ({
  items,
  request,
  page,
  total,
  handlePaginate,
  handleSorting,
  wrapperRef,
}) => {
  if (!items?.length) {
    return (
      <RunningEmptyState
        title='Looks like there are no deals yet '
        description="Hey there! It looks like there aren't any Running or Ready deals for this bot just yet.
          Don't worry though, you can either create a new deal or simply hang tight and wait for one to be executed automatically"
      />
    );
  }
  return (
    <CardBody className='p-0 overflow-hidden'>
      <CustomScrollbars
        autoHideTimeout={500}
        autoHideDuration={500}
        autoHeight
        autoHeightMax={Number.MAX_VALUE}
        className=' rounded-0'
      >
        <StyledTable className='mb-0' paddingsThead='1rem'>
          <SortTableHead
            handleSorting={handleSorting}
            sort={request?.sort}
            theadItems={EXCHANGE_RUNNING_DEALS_TABLE_HEAD}
          />
          <tbody className='font-sm'>
            {items?.map(item => (
              <RunningDealsTableRow item={item} key={item.id} total={total} />
            ))}
          </tbody>
        </StyledTable>
      </CustomScrollbars>
      <div className={total > 10 ? "mt-3" : "mt-0"}>
        <PaginationPanel
          activePage={page}
          totalItemsCount={total}
          onChange={page => handlePaginate(page)}
          className='m-0'
          elementRef={wrapperRef}
        />
      </div>
    </CardBody>
  );
};

export default RunningDealsTable;
