import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { ErrorBoundary } from "@sentry/react";
import qs from "qs";

import EditCredentialModal from "./modals/EditCredentialModal";
import { getTopMarkets } from "store/markets/actions";
import {
  fetchCredential,
  clearCredential,
  getCredentialInvestments,
  fetchBalances,
  fetchRebalances,
  fetchCredentialStatistics,
} from "store/credentials/actions";
import PagesViewCredentials from "./pageViews";
import { setCredentialsPageBreadcrumbs } from "models/enum/breadcrumbs";
import useRequestCancellation from "hooks/useRequestCancellation";
import { getExchanges } from "store/exchanges/actions";
import { CREDENTIALS_CONTENT } from "models/constans/credentials/content";
import { selectCredential } from "store/credentials/selectors";
import {
  Balance,
  CredentialHeader,
  CredentialSettings,
  ExchangeBallances,
  IPsAddresses,
} from "./compounds";
import { Breadcrumbs, Error, FallBack, RefreshButton } from "shared/ui";
import { DangerZone } from "features/widgets";

const CredentialPage = ({ match, location }) => {
  const [pageView, setPagView] = useState("Main portfolio");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isRebalanceLoading, setIsRebalanceLoading] = useState(false);
  const [isExchangesLoading, setIsExchangesLoading] = useState(false);
  const { useAbortableEffect } = useRequestCancellation();

  const dispatch = useDispatch();

  const { data, loading, error } = useSelector(selectCredential);
  const { balanceHidden } = useSelector(state => state.me);
  const exchange = useSelector(
    state => state.exchanges.allExchanges?.items,
  )?.[0];

  useEffect(() => {
    window.analytics.page("/credentials/:id");
  }, []);

  const fetchData = useCallback(
    async signal => {
      setIsExchangesLoading(true);
      try {
        const response = await dispatch(
          fetchCredential(match.params.id, signal),
        );
        if (response && response.exchange) {
          const filterExchanges = qs.stringify({
            filter: {
              code: {
                eq: response.exchange,
              },
            },
          });
          await dispatch(getExchanges(`?${filterExchanges}`, signal));
        }
      } finally {
        setIsExchangesLoading(false);
      }
    },
    [dispatch, match.params.id],
  );

  useAbortableEffect(
    signal => {
      fetchData(signal);
      return () => dispatch(clearCredential());
    },
    [match.params.id, dispatch],
    true,
  );

  const onHandleRefresh = filter => {
    const InvestmentsFilter = qs.stringify({
      filter: filter || { realPercent: { gte: 0 }, balance: { gte: 0 } },
      sort: { by: "realPercent", order: "DESC" },
    });
    const filterBalances = qs.stringify({
      sort: { by: "percent", order: "DESC" },
      limit: 10,
    });

    setIsRebalanceLoading(true);
    dispatch(fetchCredentialStatistics(match.params.id, location.search));
    dispatch(getTopMarkets(match.params.id));
    dispatch(
      getCredentialInvestments(match.params.id, `?${InvestmentsFilter}`),
    );
    dispatch(fetchBalances(match.params.id, `?${filterBalances}`));
    dispatch(fetchRebalances(match.params.id)).finally(() =>
      setIsRebalanceLoading(false),
    );
    return dispatch(fetchCredential(match.params.id));
  };

  if (error) return <Error error={error} dataTestId='credential-error' />;

  return (
    <>
      <div className='d-none d-md-flex align-items-center justify-content-between'>
        <Breadcrumbs
          items={setCredentialsPageBreadcrumbs(data.exchange)}
          loading={loading}
        />
        <RefreshButton loading={loading} onHandleRefresh={onHandleRefresh} />
      </div>
      <ErrorBoundary fallback={<FallBack />}>
        <CredentialHeader
          status={data.status}
          connected={data.connected}
          id={data.id}
          exchange={data.exchange}
          comment={data.comment}
          createdAt={data.createdAt}
          loading={loading}
          error={data.error}
        />
      </ErrorBoundary>
      <Row className='mb-2'>
        <Col xs='12' md='4'>
          <ErrorBoundary fallback={<FallBack />}>
            <Balance
              id={match.params.id}
              balance={data.balance}
              estimated={data.estimated}
              dailyRevenueBtc={data.dailyRevenueBtc}
              dailyRevenueBtcPercent={data.dailyRevenueBtcPercent}
              loading={loading}
              balanceHidden={balanceHidden}
            />
          </ErrorBoundary>
          <ErrorBoundary fallback={<FallBack />}>
            <IPsAddresses loading={isExchangesLoading} exchange={exchange} />
          </ErrorBoundary>
          <ErrorBoundary fallback={<FallBack />}>
            <ExchangeBallances
              id={match.params.id}
              estimated={data?.estimated}
            />
          </ErrorBoundary>
          <ErrorBoundary fallback={<FallBack />}>
            <CredentialSettings
              data={data}
              onSetModalIsOpen={setModalIsOpen}
              loading={loading}
            />
          </ErrorBoundary>
          <DangerZone
            id={match?.params.id}
            loading={loading}
            content={CREDENTIALS_CONTENT?.details?.widgets?.dangerZone}
            isDelete
          />
        </Col>
        <Col xs='12' md='8'>
          <PagesViewCredentials
            data={data}
            loadingPage={loading}
            id={match.params.id}
            location={location}
            pageView={pageView}
            setPagView={setPagView}
            isRebalanceLoading={isRebalanceLoading}
            balanceHidden={balanceHidden}
          />
        </Col>
      </Row>
      <EditCredentialModal
        data={data}
        modalIsOpen={modalIsOpen}
        onSetModalIsOpen={setModalIsOpen}
      />
    </>
  );
};

export default CredentialPage;
