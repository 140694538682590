import {
  BID_ASK_SPREAD_PERCENT,
  MINIMUM_MARKET_TRADES,
  MINIMUM_MARKET_VOLUME,
  SELECT_ALL_MARKETS,
  PRICE_DIFFERENCE,
  MIN_MAX_CHANGE,
  PERIOD,
  TREND,
  EXCLUDED_MARKETS,
  SELECTED_MARKETS,
  RESET_BOT_WIZARD,
  SET_INVESTMENT,
  SET_BOT_NAME,
  SET_CANDLE_CONDITIONS,
  REMOVE_CREATED_CANDLE,
  EDIT_CANDLE_SETTING,
  SET_EDIT_CANDLE,
} from "./constants";

export const setSelectAllMarkets = value => ({
  type: SELECT_ALL_MARKETS,
  payload: value,
});

export const setMinimumMarketsTrades = value => ({
  type: MINIMUM_MARKET_TRADES,
  payload: value,
});

export const setMinimumMarketsVolume = value => ({
  type: MINIMUM_MARKET_VOLUME,
  payload: value,
});

export const setBidAskPercent = value => ({
  type: BID_ASK_SPREAD_PERCENT,
  payload: value,
});

export const setCandleConditionsWizard = candle => ({
  type: SET_CANDLE_CONDITIONS,
  payload: candle,
});

export const setEditCandleWizard = candle => ({
  type: SET_EDIT_CANDLE,
  payload: candle,
});

export const editCandleSetting = setting => ({
  type: EDIT_CANDLE_SETTING,
  payload: setting,
});

export const setTrend = value => ({ type: TREND, payload: value });

export const setPeriod = value => ({ type: PERIOD, payload: value });

export const setMinMaxChange = value => ({
  type: MIN_MAX_CHANGE,
  payload: value,
});

export const setPriceDifference = value => ({
  type: PRICE_DIFFERENCE,
  payload: value,
});

export const setExcludedMarkets = value => ({
  type: EXCLUDED_MARKETS,
  payload: value,
});

export const setSelectedMarkets = value => ({
  type: SELECTED_MARKETS,
  payload: value,
});

export const setInvestment = value => ({
  type: SET_INVESTMENT,
  payload: value,
});

export const setBotName = value => ({ type: SET_BOT_NAME, payload: value });

export const setResetBotWizard = () => ({ type: RESET_BOT_WIZARD });

export const removeDraftCreatedCandle = id => {
  return {
    type: REMOVE_CREATED_CANDLE,
    payload: id,
  };
};
