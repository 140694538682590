import createAction from "store/thunkFactory";
import { get } from "lodash";

import {
  CANCEL_AGREEMENT,
  COMPLETE_AGREEMENT,
  FORCE_START_AGREEMENT,
  RESET_AGREEMENT,
  RESTART_AGREEMENT,
} from "./constants";

export const getAgreement = (id, signal) =>
  createAction("AGREEMENT", fetch => {
    return fetch.get(`/app/agreements/${id}`, { signal });
  });

export const getAgreements = (filter, signal) =>
  createAction("AGREEMENTS", fetch => {
    return fetch.get(`/app/agreements${filter || ""}`, { signal });
  });

export const fetchRounds = (id, failed) =>
  createAction("ROUNDS_TRADE_REQUESTS", fetch => {
    return fetch.get(`/app/agreements/${id}/rounds${failed || ""}`);
  });

export const forceStartAgreement = id => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post(`/app/agreements/${id}/start`);
    dispatch({ type: FORCE_START_AGREEMENT, payload: res.data.item });
    return res.data;
  } catch (error) {
    return get(error, "response.data", { errors: [], message: error.message });
  }
};

export const completedOpenAgreement =
  id => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.post(`/app/agreements/${id}/complete`);
      dispatch({ type: COMPLETE_AGREEMENT, payload: res.data.item });
      return res.data;
    } catch (error) {
      return get(error, "response.data", {
        errors: [],
        message: error.message,
      });
    }
  };

export const restartOpenAgreement = id => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post(`/app/agreements/${id}/restart`);
    dispatch({ type: RESTART_AGREEMENT, payload: res.data.item });
    return res.data;
  } catch (error) {
    return get(error, "response.data", { errors: [], message: error.message });
  }
};

export const cancelOpenAgreement = id => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post(`/app/agreements/${id}/cancel`);
    dispatch({ type: CANCEL_AGREEMENT, payload: res.data.item });
    return res.data;
  } catch (error) {
    return get(error, "response.data", { errors: [], message: error.message });
  }
};

export const getAgreementsMarkets = filters =>
  createAction("AGREEMENTS_MARKETS", fetch => {
    return fetch.get(`/app/agreements/filters/markets${filters || ""}`);
  });

export const resetAgreement = () => ({ type: RESET_AGREEMENT });

export const fetchTradeRequestOrders = (id, filters) =>
  createAction("TRADE_REQUEST_ORDERS", fetch => {
    return fetch.get(`/app/trade-requests/${id}/orders${filters || ""}`);
  });

export const fetchTradeRequestTrades = (id, filters) =>
  createAction("TRADE_REQUEST_TRADES", fetch => {
    return fetch.get(`/app/trade-requests/${id}/trades${filters || ""}`);
  });
