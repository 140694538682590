import createAction from "store/thunkFactory";
import { get } from "lodash";

import {
  ACCEPT_SIGNAL,
  ACCEPT_SIGNAL_ACTIVE,
  ACCEPT_SIGNAL_ITEM,
  CREATE_ACTIVE_SIGNAL,
  CREATE_SIGNAL,
  RESET_CHANNELS,
  SUBSCRIBE_CHANNEL_SIGNAL,
} from "./constants";

export const getSignals = (filter, signal) =>
  createAction("SIGNALS", fetch => {
    return fetch.get(`/app/signals${filter || ""}`, { signal });
  });

export const getSignal = (id, signal) =>
  createAction("SIGNAL", fetch => {
    return fetch.get(`/app/signals/${id}`, { signal });
  });

export const createSignal =
  (id, body, isActiveTable) => async (dispatch, getState, fetch) => {
    try {
      const type = isActiveTable ? CREATE_ACTIVE_SIGNAL : CREATE_SIGNAL;
      const res = await fetch.post(`/app/channels/${id}/signals`, body);
      dispatch({ type, payload: res.data.item });
      return res.data;
    } catch (error) {
      return get(error, "response.data", {
        errors: [],
        message: error.message,
      });
    }
  };

export const getSignalsCompleted = (filter, signal) =>
  createAction("COMPLETED_SIGNALS", fetch => {
    return fetch.get(`/app/signals${filter || ""}`, { signal });
  });

export const getSignalsActive = (filter, signal) =>
  createAction("ACTIVE_SIGNALS", fetch => {
    return fetch.get(`/app/signals${filter || ""}`, { signal });
  });

export const resetSignals = () => ({ type: RESET_CHANNELS });

export const subscribeChannelSignal =
  id => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.post(`/app/channels/${id}/subscribe`, {
        volume: 0.1,
        accept: "manual",
      });
      dispatch({ type: SUBSCRIBE_CHANNEL_SIGNAL });
      return res.data;
    } catch (error) {
      return get(error, "response.data", {
        errors: [],
        message: error.message,
      });
    }
  };

export const acceptSignal =
  (id, isItem) => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.post(`/app/signals/${id}/accept`);
      const type = isItem ? ACCEPT_SIGNAL_ITEM : ACCEPT_SIGNAL;
      dispatch({ type, payload: { agreements: res.data.items, signalId: id } });
      return res.data;
    } catch (error) {
      return get(error, "response.data", {
        errors: [],
        message: error.message,
      });
    }
  };

export const acceptSignalTable = id => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post(`/app/signals/${id}/accept`);
    dispatch({
      type: ACCEPT_SIGNAL_ACTIVE,
      payload: { agreements: res.data.items, signalId: id },
    });
    return res.data;
  } catch (error) {
    return get(error, "response.data", { errors: [], message: error.message });
  }
};

export const getSignalsMarkets = (exchange, filters) =>
  createAction("SIGNALS_MARKETS", fetch => {
    return fetch.get(`/app/exchanges/${exchange}/markets${filters || ""}`);
  });
