import { get } from "lodash";
import {
  GET_CREDENTIALS_SUCCESS,
  GET_CREDENTIALS_FAILURE,
  CREATE_CREDENTIAL,
  DELETE_INVESTMENT,
  CREATE_INVESTMENT,
  VERIFY_CREDENTIALS,
  GET_CREDENTIAL_REQUEST_LOG,
  GET_CREDENTIAL_STATISTICS,
  GET_CREDENTIAL_STATISTICS_LOADING,
  SELL_ALT_COIN,
  CANCEL_SELL_ALT_COIN,
  CANCEL_SELL_ALT_COINS,
  TOGGLE_INVESTMENT,
  UPDATE_INVESTMENTS,
  UPDATE_CREDENTIAL_COMMENT,
  GET_CREDENTIAL_INVESTMENTS_SUCCESS,
  UPDATE_ACCESS,
  RESET_CREDENTIALS,
  RESET_CREDENTIAL,
  GET_CREDENTIAL_INVESTMENTS_LOADING,
  UPDATE_INVESTMENT_ALLOCATION,
  RESET_TRADE_HISTORY,
  RESET_OPEN_ORDERS,
  RESET_CREDENTIAL_STATISTICS,
  RESET_CREDETIAL_BALANCES,
  RESET_CREDETIAL_INVESTMENTS,
  RESET_CREDETIAL_STATISTICS_BALANCE,
  RESET_REBALANCE_HISTORY,
  LOAD_MORE_ALLOCATION_REBALANCING,
  GET_ALLOCATION_REBALANCING_FAILURE,
  SET_HIDE_EMPTY_ASSETS,
  RESET_MY_CREDETIALS,
  RESET_CREDENTIAL_BOTS,
  FETCH_MY_CREDENTIALS_BOTS_SUCCESS,
  FETCH_MY_DEALS_SUCCESS,
  FETCH_MY_CREDENTIALS_BOTS_FAILURE,
  FETCH_MY_CREDENTIALS_BOTS_LOADING,
  FETCH_MY_CREDENTIALS_BOTS_PENDING,
} from "./constants";
import { recordWizardCredentials } from "store/user/actions";
import {
  REBALANCE_TOGGLE_ERROR,
  REBALANCE_TOGGLE_LOADING,
  REBALANCE_TOGGLE_SUCCESS,
  RESET_CREDETIAL,
  SET_CURRENT_CREDENTIAL,
} from "../investment/constants";

import { parseQueryString } from "../helpers";
import createAction from "store/thunkFactory";
import QueryString from "qs";

export const fetchCredentials = (filters, signal) =>
  createAction("CREDENTIALS", fetch => {
    return fetch.get(`/app/credentials${filters || ""}`, { signal });
  });

export const fetchMyCredentials = (filters, signal) =>
  createAction("MY_CREDENTIALS", fetch => {
    return fetch.get(`/app/credentials${filters || ""}`, { signal });
  });

export const fetchCredential = (id, signal) =>
  createAction("CREDENTIAL", fetch => {
    return fetch.get(`/app/credentials/${id}`, { signal });
  });

export const fetchOpenOrders = (id, filters, signal) =>
  createAction("OPEN_ORDERS", fetch => {
    return fetch.get(`/app/credentials/${id}/orders${filters || ""}`, {
      signal,
    });
  });

export const fetchTradeHistory = (id, filters, signal) =>
  createAction("TRADE_HISTORY", fetch => {
    return fetch.get(`/app/credentials/${id}/trade-history${filters || ""}`, {
      signal,
    });
  });

export const fetchStatisticsBalance = (id, period, signal) =>
  createAction("STATISTICS_BALANCE", fetch => {
    return fetch.get(
      `/app/credentials/${id}/chart/balance-history?period=${period}`,
      { signal },
    );
  });

export const fetchBalances = (id, filters, signal) =>
  createAction("BALANCES", fetch => {
    return fetch.get(`/app/credentials/${id}/balances${filters || ""}`, {
      signal,
    });
  });

export const getBalance = (id, filter, signal) =>
  createAction("BALANCE", fetch => {
    return fetch.get(`/app/credentials/${id}/balances${filter || ""}`, {
      signal,
    });
  });

export const fetchRebalances = (credentialsId, filters = "", signal) =>
  createAction("REBALANCE_HISTORY", fetch => {
    return fetch.get(
      `/app/credentials/${credentialsId}/rebalances${filters}${filters ? "&" : "?"}limit=5`,
      { signal },
    );
  });

export const resetRebalances = () => ({ type: RESET_REBALANCE_HISTORY });

export const cancelOrder = id => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.delete(`/app/credentials/orders/${id}`);
    return res.data;
  } catch (e) {
    return e.response.data;
  }
};

export const fetchMyCredentialsBots =
  (credential, quote, base, filter, signal) =>
  async (dispatch, getState, fetch) => {
    try {
      dispatch({ type: FETCH_MY_CREDENTIALS_BOTS_PENDING });
      const res = await fetch.get(
        `/app/credentials/${credential}/markets/${base}-${quote}/bots${filter || ""}`,
        { signal },
      );
      dispatch({
        type: FETCH_MY_CREDENTIALS_BOTS_LOADING,
        payload: res.data.items,
      });
      const botIds = res.data.items.map(bot => bot.id);

      if (botIds.length) {
        const dealsFilter = QueryString.stringify({
          filter: {
            bot: { in: botIds },
            status: { in: ["ready", "running"] },
            market: { in: [`${base}-${quote}`] },
          },
        });
        const dealsRes = await fetch.get(`/app/deals?${dealsFilter || ""}`, {
          signal,
        });
        dispatch({
          type: FETCH_MY_DEALS_SUCCESS,
          payload: dealsRes.data.items,
        });
      }

      dispatch({ type: FETCH_MY_CREDENTIALS_BOTS_SUCCESS });
      return res.data;
    } catch (e) {
      dispatch({ type: FETCH_MY_CREDENTIALS_BOTS_FAILURE, payload: e.message });
    }
  };

export const refreshCredentials =
  (page, filters) => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.get(
        `/app/credentials${page ? "?page=" + page : ""}${filters ? parseQueryString(filters) : ""}`,
      );
      dispatch({ type: GET_CREDENTIALS_SUCCESS, payload: res.data });
      return res.data;
    } catch (e) {
      dispatch({ type: GET_CREDENTIALS_FAILURE, payload: e.message });
    }
  };

export const createCredential =
  credential => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.post("/app/credentials", credential);
      dispatch({ type: CREATE_CREDENTIAL, payload: res.data });
      dispatch(recordWizardCredentials());
      return res.data;
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const updateAccess = (body, id) => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.patch(`/app/credentials/${id}/update-access`, body);
    dispatch({ type: UPDATE_ACCESS, payload: res.data.item });
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const addTag = (id, tags) => async (dispatch, getState, fetch) => {
  try {
    await fetch.put(`/app/credentials/${id}/tags`, tags);
  } catch (e) {
    return e.response.data.errors;
  }
};

export const deleteTag = (id, tag) => async (dispatch, getState, fetch) => {
  try {
    await fetch.delete(`/app/credentials/${id}/tags/${tag}`);
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const deleteCredential = id => async (dispatch, getState, fetch) => {
  try {
    return await fetch.delete(`/app/credentials/${id}`);
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const createInvestment = body => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/app/investments", body);
    dispatch({ type: CREATE_INVESTMENT, payload: res.data.portfolio });
    return res.data;
  } catch (e) {
    return get(e, "response.data", { message: e.message });
  }
};

export const updateInvestmentAllocation =
  (id, body) => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.put(`/app/credentials/${id}/investments`, body);
      dispatch({ type: UPDATE_INVESTMENT_ALLOCATION, payload: res.data.item });
      return res.data;
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const deleteInvestment = id => async (dispatch, getState, fetch) => {
  try {
    await fetch.delete(`/app/investments/${id}`);
    dispatch({ type: DELETE_INVESTMENT, payload: id });
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const verifyCredentials = data => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/app/credentials/verify", data);
    dispatch({ type: VERIFY_CREDENTIALS, payload: res.data });
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const fetchRequestLog =
  (id, page, filters) => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.get(
        `/app/credentials/${id}/request-log?page=${page}${parseQueryString(filters)}`,
      );
      dispatch({ type: GET_CREDENTIAL_REQUEST_LOG, payload: res.data });
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const fetchCredentialStatistics =
  (id, filters = "") =>
  async (dispatch, getState, fetch) => {
    try {
      dispatch({ type: GET_CREDENTIAL_STATISTICS_LOADING });
      const res = await fetch.get(
        `/app/credentials/${id}/statistics${filters}`,
      );
      dispatch({ type: GET_CREDENTIAL_STATISTICS, payload: res.data });
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const updateComment =
  (id, body) => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.patch(`/app/credentials/${id}/comment`, {
        comment: body,
      });
      dispatch({ type: UPDATE_CREDENTIAL_COMMENT, payload: res.data });
      return res.data;
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const sellAltCoin = (altCoin, profit, estimatedProfit, soldFor) => {
  return {
    type: SELL_ALT_COIN,
    payload: { altCoin, profit, estimatedProfit, soldFor },
  };
};

export const cancelSellAltCoin = altCoin => {
  return {
    type: CANCEL_SELL_ALT_COIN,
    payload: { altCoin },
  };
};

export const cancelSellAltCoins = stableCoin => {
  return {
    type: CANCEL_SELL_ALT_COINS,
    payload: { stableCoin },
  };
};

export const toggleInvestment = (coin, value) => {
  return {
    type: TOGGLE_INVESTMENT,
    payload: { coin, value },
  };
};

export const updateInvestments = (coin, allocation) => {
  return {
    type: UPDATE_INVESTMENTS,
    payload: { coin, allocation },
  };
};

export const getCredentialInvestments =
  (id, filters, signal) => async (dispatch, getState, fetch) => {
    try {
      dispatch({ type: GET_CREDENTIAL_INVESTMENTS_LOADING });
      const res = await fetch.get(
        `/app/credentials/${id}/investments${filters || ""}`,
        { signal },
      );
      dispatch({ type: GET_CREDENTIAL_INVESTMENTS_SUCCESS, payload: res.data });
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const fetchAllocationRebalancing = (id, filters) =>
  createAction("ALLOCATION_REBALANCING", fetch => {
    return fetch.get(`/app/credentials/${id}/investments${filters || ""}`);
  });

export const loadMoreNotifications =
  (id, page) => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.get(
        `/app/credentials/${id}/investments?page=${page}`,
      );
      dispatch({ type: LOAD_MORE_ALLOCATION_REBALANCING, payload: res.data });
      return res.data;
    } catch (e) {
      dispatch({
        type: GET_ALLOCATION_REBALANCING_FAILURE,
        payload: e.message,
      });
    }
  };

export const toggleRebalance =
  (id, isEnabled) => async (dispatch, getState, fetch) => {
    try {
      dispatch({ type: REBALANCE_TOGGLE_LOADING });
      const res = await fetch.post(
        `/app/credentials/${id}/rebalance/${isEnabled}`,
      );
      dispatch({ type: REBALANCE_TOGGLE_SUCCESS, payload: res.data });
      return res.data;
    } catch (e) {
      dispatch({ type: REBALANCE_TOGGLE_ERROR, payload: e.message });
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const clearCredentialsData = () => ({ type: RESET_CREDENTIALS });

export const clearCredential = () => ({ type: RESET_CREDENTIAL });

export const clearTradeHistory = () => ({ type: RESET_TRADE_HISTORY });

export const clearOpenOrders = () => ({ type: RESET_OPEN_ORDERS });

export const resetCredentialStatistics = () => ({
  type: RESET_CREDENTIAL_STATISTICS,
});

export const resetCredentialBalances = () => ({
  type: RESET_CREDETIAL_BALANCES,
});

export const resetCredentialInvestments = () => ({
  type: RESET_CREDETIAL_INVESTMENTS,
});

export const resetCredentialStatisticsBalance = () => ({
  type: RESET_CREDETIAL_STATISTICS_BALANCE,
});

export const setHideEmptyAssets = value => ({
  type: SET_HIDE_EMPTY_ASSETS,
  payload: value,
});

export const setCurrentCredential = value => ({
  type: SET_CURRENT_CREDENTIAL,
  payload: value,
});

export const resetCredential = () => ({ type: RESET_CREDETIAL });

export const resetMyCredentials = () => ({ type: RESET_MY_CREDETIALS });

export const resetCredentialBots = () => ({ type: RESET_CREDENTIAL_BOTS });
