import React from "react";
import { Card } from "reactstrap";
import { ReactComponent as BotListChartPreload } from "assets/icons/general/bot-list-chart-preload.svg";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import _ from "lodash";

const DealsStatisticsWidgetPreload = () => {
  return (
    <Card style={{ padding: "24px" }}>
      <div className='d-flex align-items-center'>
        <SkeletonPreloader
          circle={true}
          height={20}
          width={20}
          className='mr-2'
        />
        <SkeletonPreloader height={13} width={100} />
      </div>
      <div className='d-flex align-items-center justify-content-between mt-4 mb-3'>
        <div className='d-flex align-items-center'>
          <BotListChartPreload />
          <div className='d-flex flex-column ml-2'>
            <SkeletonPreloader height={16} width={29} />
            <SkeletonPreloader height={11} width={52} className='mt-2' />
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <BotListChartPreload style={{ transform: "scale(1, -1)" }} />
          <div className='d-flex flex-column ml-2'>
            <SkeletonPreloader height={16} width={29} />
            <SkeletonPreloader height={11} width={52} className='mt-2' />
          </div>
        </div>
      </div>
      {_.times(2, e => (
        <div
          className='d-flex align-items-center justify-content-between mt-2'
          key={e}
        >
          <SkeletonPreloader height={12} width={72} />
          <SkeletonPreloader height={12} width={117} />
        </div>
      ))}
      <div className='d-flex align-items-center justify-content-between mt-4'>
        <SkeletonPreloader height={12} width={76} />
        <SkeletonPreloader height={12} width={76} />
      </div>
      <div className='d-flex align-items-center justify-content-between mt-2'>
        <SkeletonPreloader height={14} className='mr-1' />
        <SkeletonPreloader height={14} className='ml-1' />
      </div>
      <div className='d-flex align-items-center justify-content-between mt-2'>
        <SkeletonPreloader height={12} width={76} />
        <SkeletonPreloader height={12} width={76} />
      </div>
    </Card>
  );
};

export default DealsStatisticsWidgetPreload;
