import React from "react";
import { FormGroup } from "reactstrap";

import { STATUS_OPTIONS } from "models/deal/enum/tables";
import SelectInput from "../SelectInput";

const RequestsFiltersOrders = ({ handleFilter, request }) => {
  return (
    <FormGroup className='mb-0'>
      <SelectInput
        isClearable
        placeholder='Status'
        name='status'
        options={STATUS_OPTIONS}
        value={STATUS_OPTIONS.find(
          ({ value }) => value === request?.filter?.status?.eq,
        )}
        onChange={option =>
          handleFilter({
            status: { eq: option?.value || {} },
          })
        }
      />
    </FormGroup>
  );
};

export default RequestsFiltersOrders;
