import React, { useState } from "react";
import { CardHeader, Tooltip } from "reactstrap";
import styled from "styled-components";
import { DelayInput } from "react-delay-input";

import { ReactComponent as Search } from "assets/icons/general/search-black.svg";
import { ReactComponent as Help } from "assets/icons/general/Help.svg";
import FormGroupWithSymbols from "shared/ui/FormGroupWithSymbols";
import { Button } from "shared/ui/buttons";

const TechnicalLogsHeaderModal = ({
  setIsModalOpenTechnicalLogs,
  handleFilter,
  technicalLogsLoading,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => () => setTooltipOpen(!tooltipOpen);

  const closeModal = () => () => setIsModalOpenTechnicalLogs(false);

  return (
    <CardHeader className='d-flex flex-column flex-sm-row align-items-sm-center justify-content-between p-0'>
      <div className='d-flex align-items-center'>
        <h2 className='mb-0  mr-2 font-weight-500 text-white'>
          Technical logs
        </h2>
        <TechnicalLogsHeaderModal.Helps>
          <Help id='logs' />
          <Tooltip
            placement='top'
            isOpen={tooltipOpen}
            target='logs'
            toggle={toggle()}
          >
            Deal`s technical logs timeline
          </Tooltip>
        </TechnicalLogsHeaderModal.Helps>
      </div>

      <TechnicalLogsHeaderModal.Search className='d-flex align-items-center mt-2 mt-sm-0'>
        <div className='ml-sm-2 mr-sm-3 w-100'>
          <FormGroupWithSymbols symbolLeft={<Search />}>
            <DelayInput
              autoComplete='off'
              delayTimeout={700}
              type='string'
              name='from'
              className='form-control w-100'
              placeholder='Search'
              disabled={technicalLogsLoading}
              onChange={({ target }) =>
                handleFilter({ message: { like: target.value || {} } })
              }
            />
          </FormGroupWithSymbols>
        </div>

        <Button
          color='gray'
          cancel={true}
          onClick={closeModal()}
          className='searchButton'
        />
      </TechnicalLogsHeaderModal.Search>
    </CardHeader>
  );
};

TechnicalLogsHeaderModal.Helps = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    &:focus {
      outline: none;
    }
  }
`;

TechnicalLogsHeaderModal.Search = styled.div`
  @media (max-width: 576px) {
    .searchButton {
      position: absolute;
      right: 24px;
      top: 24px;
    }
  }

  svg {
    path {
      stroke: rgba(255, 255, 255, 0.35) !important;
    }
  }
`;

export default TechnicalLogsHeaderModal;
