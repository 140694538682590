import React from "react";

import { setColorClass, formatValue } from "helpers/valuesFormatters";
import TickerWrapper from "./TickerWrapper";
import { Icon } from "shared/ui";

const MovingTikerLine = ({ tickers = [], exchange, onClick }) => {
  return (
    <TickerWrapper className='d-flex' onClick={onClick}>
      <div className='ticker-wrap'>
        <div className='ticker'>
          {tickers.map(item => (
            <div className='ticker__item py-1 pl-3' key={item.symbol}>
              <Icon
                type='exchanges'
                size='smallSize'
                code={exchange}
                className='my-auto mr-2'
              />
              <span className='my-auto font-xs font-weight-500 text-white'>
                {item.close}{" "}
                <span className='font-weight-300 text-white-35'>
                  {`${item.market.base} / ${item.market.quote}`}
                </span>{" "}
                <span
                  className={`font-weight-400 ${setColorClass(+item.dailyChange)}`}
                >
                  {`${formatValue(item.dailyChange || 0)}%`}
                </span>
              </span>
              <span
                className='ml-3 d-inline-block'
                style={{ color: "rgba(255, 255, 255, 0.1)" }}
              >
                |
              </span>
            </div>
          ))}
        </div>
      </div>
    </TickerWrapper>
  );
};

export default MovingTikerLine;
