export const CHART_GROUP_BUTTONS = [
  { title: "All time", type: "all" },
  { title: "Month", type: "month" },
  { title: "Week", type: "week" },
  { title: "Day", type: "day" },
];

export const BALANCE_DATASETS_TITLES = {
  maxBalance: "Maximum balance",
  minBalance: "Minimum balance",
};

export const INVESTMENT_DATASETS_TITLES = {
  minTotal: "Minimum total",
  maxTotal: "Maximum total",
  minInvested: "Minimum invested",
  maxInvested: "Maximum invested",
  minAvailable: "Minimum available",
  maxAvailable: "Maximum available",
  maxEstimated: "Maximum estimated",
  minEstimated: "Minimum estimated",
  minOnOrders: "Minimum on orders",
  maxOnOrders: "Maximum on orders ",
  total: "Total",
  invested: "Invested",
  available: "Available",
  onOrders: "On orders",
};
