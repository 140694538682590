import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { resetWizardIndicators } from "store/strategies/actions";
import { getBot } from "store/bots/actions";
import {
  setBidAskPercent,
  setBotName,
  setEditCandleWizard,
  setExcludedMarkets,
  setInvestment,
  setMinimumMarketsTrades,
  setMinimumMarketsVolume,
  setMinMaxChange,
  setPeriod,
  setPriceDifference,
  setResetBotWizard,
  setSelectAllMarkets,
  setSelectedMarkets,
  setTrend,
} from "store/bots/wizard/actions";
import { selectBot } from "store/bots/selectors";

const useEditBot = botId => {
  const [isSubmit, setIsSubmit] = useState();
  const dispatch = useDispatch();

  const { data: botData, loading } = useSelector(selectBot);
  const wizardMarkets = useSelector(state => state.botWizard);

  useEffect(() => {
    if (botId) {
      dispatch(getBot(botId));
    }
  }, [botId, dispatch]);

  useEffect(() => {
    if (botData?.investment) {
      dispatch(setBotName(botData.name));
      dispatch(setSelectAllMarkets(botData.allCoins));
      dispatch(setMinimumMarketsTrades(botData.minimumTrades));
      dispatch(setMinimumMarketsVolume(botData.volume));
      dispatch(setBidAskPercent(botData.spreadPercent));
      dispatch(setTrend(botData.marketDirection));
      dispatch(setPeriod(botData.period));
      dispatch(
        setEditCandleWizard(botData?.tradeSettings?.candleConditions || []),
      );
      dispatch(
        setMinMaxChange([
          botData.minimumDailyChange,
          botData.maximumDailyChange,
        ]),
      );
      dispatch(setPriceDifference(botData.markPriceDifference || 0));
      dispatch(setExcludedMarkets(botData.excludedCoins));
      dispatch(setSelectedMarkets(botData.coins));
      dispatch(setInvestment(botData.investment));
    }
    return () => {
      dispatch(setBotName(""));
      dispatch(setResetBotWizard());
      dispatch(setInvestment({}));
      dispatch(resetWizardIndicators());
    };
  }, [dispatch, botData?.investment]);

  return { botData, loading, wizardMarkets, setIsSubmit, isSubmit };
};

export default useEditBot;
