import React from "react";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const SearchPreloader = () => (
  <div className='d-flex justify-content-between'>
    <div className='d-flex w-25 flex-column'>
      <div className='d-flex'>
        <SkeletonPreloader
          circle={true}
          style={{ background: "#535462", opacity: ".3" }}
          className='mr-2 my-auto'
          height={24}
          width={24}
        />
        <div className='d-flex flex-column w-75'>
          <SkeletonPreloader
            style={{
              background:
                "linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 100%)",
              opacity: ".3",
            }}
            count={1}
            height={12}
            className='mt-1 mb-1 '
          />
        </div>
      </div>
      <div className='d-flex mt-2'>
        <SkeletonPreloader
          circle={true}
          style={{ background: "#535462", opacity: ".3" }}
          className='mr-2 my-auto'
          height={24}
          width={24}
        />
        <div className='d-flex flex-column w-75'>
          <SkeletonPreloader
            count={1}
            height={12}
            style={{
              background:
                "linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 100%)",
              opacity: ".3",
            }}
            className='mt-1 mb-1 '
          />
        </div>
      </div>
      <div className='d-flex mt-2'>
        <SkeletonPreloader
          circle={true}
          style={{ background: "#535462", opacity: ".3" }}
          className='mr-2 my-auto'
          height={24}
          width={24}
        />
        <div className='d-flex flex-column w-75'>
          <SkeletonPreloader
            count={1}
            height={12}
            style={{
              background:
                "linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 100%)",
              opacity: ".3",
            }}
            className='mt-1 mb-1 '
          />
        </div>
      </div>
    </div>
    <div className='d-flex flex-column'>
      <SkeletonPreloader
        height={10}
        width={92}
        style={{
          background:
            "linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 100%)",
          opacity: ".3",
        }}
        className='my-auto'
      />
      <SkeletonPreloader
        height={10}
        width={92}
        style={{
          background:
            "linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 100%)",
          opacity: ".3",
        }}
        className='my-auto'
      />
      <SkeletonPreloader
        height={10}
        width={92}
        style={{
          background:
            "linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 100%)",
          opacity: ".3",
        }}
        className='my-auto'
      />
    </div>
  </div>
);

export default SearchPreloader;
