import styled from "styled-components";

export const StyledIndicator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  margin-top: 6px;
  height: 48px;
  padding: ${props => (props.required ? "12px 8px 12px 16px" : "12px 16px")};
  border-radius: 6px;
  background: var(--white-03);
  color: var(--white);
`;
