import styled from "styled-components";

export const CenterColumn = ({ header, main, footer, items }) => (
  <FlexColumn items={items}>
    <Header>{header}</Header>
    <Main>{main}</Main>
    <Footer>{footer}</Footer>
  </FlexColumn>
);

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ items }) => items};
  gap: ${({ gap }) => gap};
  justify-content: ${({ content }) => content};

  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

export const Header = styled.span`
  color: rgba(255, 255, 255, 0.35);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  white-space: nowrap;
`;

export const Main = styled.span`
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Footer = styled.span`
  color: rgba(255, 255, 255, 0.35);
  font-size: 12px;
  font-style: normal;
  line-height: normal;

  @media (max-width: 768px) {
    display: none;
  }
`;
