import React, { useState } from "react";
import styled from "styled-components";

import LoginForm from "./LoginForm";
import Loader from "./Loader";
import FormDescription from "./compounds/FormDescription";

const LoginPage = () => {
  const [googleLoading, setGoogleLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Wrapper className='content content h-100 d-flex flex-column justify-content-center'>
      <Loader isGoogle={googleLoading} isLoading={isLoading || googleLoading} />
      <div className={isLoading || googleLoading ? "d-none" : "d-block"}>
        <FormDescription
          title='Sign in to your account'
          text='Welcome to the world of Darkbot, where you can unleash
          the power of automated trading. Sign in to experience
          a new level of control and precision.'
        />
        <LoginForm
          setGoogleLoading={setGoogleLoading}
          setIsLoading={setIsLoading}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 80px 0;
`;

export default LoginPage;
