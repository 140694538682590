import { combineReducers } from "redux";

import {
  SUBSCRIBE_CHANNEL,
  CREATE_CHANNEL,
  UPDATE_CHANNEL_INFORM,
  UNSUBSCRIBE_CHANNEL_ITEM,
  SUBSCRIBE_CHANNEL_ITEM,
  CLOSE_CHANNEL,
  RESTORE_CHANNEL,
  UPLOAD_CHANNEL_LOGO,
  RESET_CHANNEL_LOGO,
} from "./constants";
import withDataLoading from "store/withDataLoading";

const initialState = {
  loading: false,
  data: {},
  error: null,
};

const toggleSubscription = (state, increment) => ({
  ...state,
  data: {
    ...state.data,
    subscribed: increment,
    totalSubscribers: state.data.totalSubscribers + (increment ? 1 : -1),
  },
});

const channelItem = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CLOSE_CHANNEL:
    case RESTORE_CHANNEL:
    case UPDATE_CHANNEL_INFORM:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };

    case SUBSCRIBE_CHANNEL_ITEM:
      return toggleSubscription(state, true);

    case UNSUBSCRIBE_CHANNEL_ITEM:
      return toggleSubscription(state, false);

    case UPLOAD_CHANNEL_LOGO:
    case RESET_CHANNEL_LOGO:
      return {
        ...state,
        data: {
          ...state.data,
          logos: payload
            ? {
                ...payload,
              }
            : [],
        },
      };

    default:
      return state;
  }
};

const channelsList = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SUBSCRIBE_CHANNEL:
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.items.map(channel =>
            channel.id === payload.id
              ? {
                  ...channel,
                  subscribed: true,
                  totalSubscribers: channel.totalSubscribers + 1,
                }
              : channel,
          ),
        },
      };

    case CREATE_CHANNEL: {
      const { channel, limit } = payload;
      const updatedItems = [channel, ...(state.data?.items || [])].slice(
        0,
        limit || 9,
      );
      return {
        ...state,
        data: {
          ...state.data,
          items: updatedItems,
          total: state.data.total + 1,
        },
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  list: withDataLoading(channelsList, "CHANNELS"),
  item: withDataLoading(channelItem, "CHANNEL"),
});
