import React from "react";
import { FormGroup } from "reactstrap";
import { DelayInput } from "react-delay-input";
import classNames from "classnames";

import Loading from "shared/ui/state/Loading";
import ErrorAlert from "shared/ui/ErrorAlert";
import { ConfirmationDeleteDescribe } from "./index.styles";
import {
  ConfirmationModalStyled,
  ConfirmationWrapperStyled,
} from "../ConfirmationModal/index.styles";
import { Button } from "shared/ui/buttons";

const ConfirmationDeleteModal = ({
  modalIsOpen,
  load,
  content,
  handleDelete,
  handleClose,
  handleChange,
  errorMessage,
  isSubmitted,
  correctError,
  setErrorMessage,
}) => {
  if (modalIsOpen) {
    return (
      <ConfirmationModalStyled
        data-testid='danger-zone-confirmation-wrap'
        isOpen={modalIsOpen}
        modalClassName='modal-black'
        toggle={handleClose}
      >
        <ConfirmationWrapperStyled className='p-4'>
          <h3
            data-testid='danger-zone-confirmation-delete-title'
            className='modal-title text-white text-center mt-4 font-weight-500 mb-0'
          >
            Delete {content.actions.delete.name}
          </h3>
          <ConfirmationDeleteDescribe
            data-testid='danger-zone-confirmation-delete-subtitle'
            className='mb-0 text-left text-white-75 font-sm mt-2'
          >
            {content.actions.delete.title}
          </ConfirmationDeleteDescribe>
          <FormGroup className='form-input m-0'>
            <DelayInput
              data-testid='danger-zone-confirmation-delete-delay-input'
              autoComplete='off'
              delayTimeout={700}
              type='text'
              name='DELETE'
              className={classNames("text-white form-control mt-4", {
                "has-error": isSubmitted && correctError,
              })}
              placeholder='DELETE'
              onChange={handleChange}
            />
          </FormGroup>
          {isSubmitted && correctError ? (
            <label
              className='d-flex mt-1 text-danger font-weight-300'
              data-testid='danger-zone-confirmation-correct-error'
            >
              {correctError}
            </label>
          ) : null}
          {errorMessage && (
            <ErrorAlert
              toggle={() => setErrorMessage("")}
              isOpen={!!errorMessage}
              className='mt-3'
            >
              {errorMessage}
            </ErrorAlert>
          )}
          <div className='d-flex justify-content-between'>
            <Button
              data-testid='danger-zone-confirmation-delete-btn-cancel'
              color='gray'
              disabled={load}
              onClick={handleClose}
              className='mt-4'
            >
              Cancel
            </Button>
            {load ? (
              <div
                className='d-flex justify-content-center mt-4'
                style={{
                  background: "rgba(237, 95, 95, 0.7)",
                  borderRadius: "6px",
                  padding: "7px 50px",
                }}
              >
                <Loading size={21} color='#FFFFFF' />
              </div>
            ) : (
              <Button
                data-testid='danger-zone-confirmation-delete-btn-delete'
                color='red'
                disabled={load}
                onClick={handleDelete}
                className='mt-4'
              >
                Delete
              </Button>
            )}
          </div>
        </ConfirmationWrapperStyled>
      </ConfirmationModalStyled>
    );
  }
};

export default ConfirmationDeleteModal;
