import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Tooltip } from "reactstrap";
import { DelayInput } from "react-delay-input";
import { useSelector } from "react-redux";

import { ReactComponent as SmallToBig } from "assets/icons/general/small-to-big.svg";
import useFilterAndSorting from "hooks/useFilterAndSorting";
import { CHANNELS_SORT_OPTIONS, CHANNELS_STATUS_OPTIONS } from "models/filters";
import { selectMeData } from "store/user/selectors";
import { SelectInput } from "shared/ui";

const Filters = ({ loading }) => {
  const [tooltip, setTooltip] = useState(false);
  const { id } = useSelector(selectMeData);
  const { handleFilter, handleSorting, filter, sort } =
    useFilterAndSorting("/channels");
  const toggle = () => setTooltip(!tooltip);
  const sortingButton = () => {
    if (sort?.by) {
      handleSorting({
        order: sort?.order === "ASC" || !sort?.order ? "DESC" : "ASC",
        by: sort?.by,
      });
    }
  };

  const handleSubscriptionFilter = option => {
    if (option === "subscribed") {
      handleFilter({ "subscribers.user": { eq: id } });
    } else {
      handleFilter({ "subscribers.user": { neq: id } });
    }
  };

  return (
    <Card className='mt-3 mb-4 mx-0'>
      <CardBody className='p-4 d-flex flex-column flex-md-row justify-content-between'>
        <Col xs={12} md={6} className='p-0 mb-2 mb-md-0 pr-md-3'>
          <DelayInput
            autoComplete='off'
            delayTimeout={700}
            minLength={1}
            type='text'
            name='name'
            className='form-control'
            placeholder='Name'
            value={filter?.name?.like ?? ""}
            onChange={({ target }) =>
              handleFilter({ name: { like: target.value } })
            }
          />
        </Col>
        <Col xs={12} md={4} className='p-0 mb-2 mb-md-0 pr-md-3'>
          <SelectInput
            isClearable
            placeholder='Subscription status'
            name='subscriptionStatus'
            onChange={option => handleSubscriptionFilter(option?.value)}
            options={CHANNELS_STATUS_OPTIONS}
          />
        </Col>
        <Col xs={12} md={2} className='d-flex p-0 mb-2 mb-md-0'>
          <SelectInput
            className='w-100 mr-3'
            isClearable
            placeholder='Sort'
            name='sort'
            onChange={option =>
              handleSorting({ order: sort?.order || "DESC", by: option?.value })
            }
            options={CHANNELS_SORT_OPTIONS}
          />
          <Filters.Sorting
            onClick={sortingButton}
            loading={loading}
            by={sort?.by}
            id='sort'
          >
            <Filters.SortingOrderIcon order={sort?.order || "DESC"} />
            {!sort?.by && (
              <Tooltip
                target='sort'
                placement='top'
                isOpen={tooltip}
                toggle={toggle}
              >
                Select sort by
              </Tooltip>
            )}
          </Filters.Sorting>
        </Col>
      </CardBody>
    </Card>
  );
};

Filters.Sorting = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    path {
      fill-opacity: ${props => (props.loading || !props?.by ? ".2" : "0.35")};
    }
  }

  &:hover {
    svg {
      path {
        fill-opacity: ${props => (props.loading || !props?.by ? ".2" : "1")};
      }
    }
  }
`;

Filters.SortingOrderIcon = styled(SmallToBig)`
  transition: transform 0.2s ease-in-out;
  transform: ${props => (props.order === "DESC" ? "rotate(180deg)" : "")};
`;

Filters.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Filters;
