import React from "react";
import { Col } from "reactstrap";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const FiltersPreloader = () => (
  <>
    <div className='d-flex align-items-center justify-content-between mb-2 mb-md-4'>
      <div className='d-flex flex-column flex-md-row align-items-md-center justify-content-between w-100'>
        <div className='d-flex mb-4 mb-md-0'>
          <SkeletonPreloader circle width={20} height={20} />
          <SkeletonPreloader width={120} height={20} className='ml-2' />
        </div>
      </div>
      <div className='d-flex align-items-center cursor-pointer'>
        <SkeletonPreloader width={40} height={20} className='mr-2' />
        <SkeletonPreloader width={20} height={20} />
      </div>
    </div>
    <div className='d-flex flex-column flex-lg-row'>
      <Col xs='12' lg='8' className='pl-0 pr-0 pr-lg-4'>
        <div className='d-flex flex-column flex-md-row align-items-center mb-4'>
          <div className='w-100 position-relative'>
            <SkeletonPreloader width={150} height={20} />
            <SkeletonPreloader width={210} height={40} />
          </div>
          <div className='w-100 mx-2 position-relative'>
            <SkeletonPreloader width={150} height={20} />
            <SkeletonPreloader width={210} height={40} />
          </div>
          <div className='w-100'>
            <SkeletonPreloader width={150} height={20} />
            <SkeletonPreloader width={210} height={40} />
          </div>
        </div>
        <div className='pt-2'>
          <div className='d-flex flex-column flex-sm-row align-items-sm-center justify-content-between'>
            <SkeletonPreloader width={210} height={20} />
            <div className='d-flex align-items-center p-0'>
              <SkeletonPreloader width={40} height={20} />
              <SkeletonPreloader width={85} height={40} />
              <SkeletonPreloader width={40} height={20} className='ml-3' />
              <SkeletonPreloader width={85} height={40} />
            </div>
          </div>
          <div className='mt-3'>
            <SkeletonPreloader width={635} height={20} />
          </div>
        </div>
      </Col>
      <Col xs='12' lg='4' className='pl-0 pl-lg-3 pr-0 mt-3 mt-md-0'>
        <SkeletonPreloader width={70} height={20} />
        <div className='d-flex align-items-center justify-content-between mb-4'>
          <SkeletonPreloader width={210} height={40} />
          <SkeletonPreloader width={210} height={40} />
        </div>
        <div className='d-flex align-items-center justify-content-between pt-2'>
          <SkeletonPreloader width={210} height={20} />
          <SkeletonPreloader width={110} height={40} />
        </div>
        <div className='pt-3'>
          <SkeletonPreloader width={320} height={20} />
        </div>
      </Col>
    </div>
  </>
);

export default FiltersPreloader;
