import { get } from "lodash";
import {
  GET_STRATEGIES_FAILURE,
  ATTACH_STRATEGY_INDICATORS,
  DETACH_STRATEGY_INDICATOR,
  UPDATE_STRATEGY_INDICATOR,
  LOAD_MORE_STRATEGIES,
  UPDATE_STRATEGY_NAME,
  DELETE_STRATEGY_IMAGE,
  POST_STRATEGY_IMAGE,
  ADD_INDICATOR,
  REMOVE_INDICATOR,
  EDIT_INDICATOR,
  UPDATE_STRATEGY,
  RESET_STRATEGY,
  RESET_STRATEGIES,
  RESET_SIGNALS,
  RESET_WIZARD_INDICATORS,
} from "./constants";
import { parseQueryString } from "../helpers";
import createAction from "store/thunkFactory";

export const fetchStrategies = (filter, perPage) =>
  createAction("STRATEGIES", fetch => {
    return fetch.get(
      `/app/strategies${filter ? filter + "&" : "?"}${perPage ? `perPage=${perPage}` : ""}`,
    );
  });

export const fetchStrategy = id =>
  createAction("STRATEGY", fetch => {
    return fetch.get(`/app/strategies/${id}`);
  });

export const getSignals = (id, body) =>
  createAction("SIGNALS", fetch => {
    return fetch.post(`/app/strategies/${id}/signals`, body);
  });

export const loadMoreStrategies =
  (page, filter, perPage) => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.get(
        `/app/strategies?page=${page}${parseQueryString(filter)}${perPage ? `&perPage=${perPage}` : ""}`,
      );
      dispatch({ type: LOAD_MORE_STRATEGIES, payload: res.data });
      return res.data;
    } catch (e) {
      dispatch({ type: GET_STRATEGIES_FAILURE, payload: e.message });
    }
  };

export const createStratedy = strategy => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/app/strategies", strategy);
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const deleteStrategy = id => async (dispatch, getState, fetch) => {
  try {
    await fetch.delete(`/app/strategies/${id}`);
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const updateStrategy =
  (id, body) => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.patch(`/app/strategies/${id}`, body);
      dispatch({ type: UPDATE_STRATEGY, payload: res.data.item });
      return res.data;
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const attachIndicators =
  (id, body) => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.post(`/app/strategies/${id}/indicators`, body);
      dispatch({ type: ATTACH_STRATEGY_INDICATORS, payload: res.data.item });
      return res.data;
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const editIndicator =
  (strategyId, indicatorId, body) => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.patch(
        `/app/strategies/${strategyId}/indicators/${indicatorId}`,
        body,
      );
      dispatch({ type: UPDATE_STRATEGY_INDICATOR, payload: res.data.item });
      return res.data;
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const detachIndicator =
  (strategyId, indicatorId) => async (dispatch, getState, fetch) => {
    try {
      await fetch.delete(
        `/app/strategies/${strategyId}/indicators/${indicatorId}`,
      );
      dispatch({ type: DETACH_STRATEGY_INDICATOR, payload: indicatorId });
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const validateIndicator = body => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post("/app/indicators/validate", body);
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const updateName = (id, body) => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.patch(`/app/strategy/${id}/name`, body);
    dispatch({ type: UPDATE_STRATEGY_NAME, payload: res.data });
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const postStrategyImage =
  (id, file) => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.post(`/app/strategies/${id}/image`, file);
      dispatch({ type: POST_STRATEGY_IMAGE, payload: res.data });
      return res.data;
    } catch (e) {
      return get(e, "response.data", {
        errors: [],
        message: "File is too large",
      });
    }
  };

export const deleteStrategyImage = id => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.delete(`/app/strategies/${id}/image`);
    dispatch({ type: DELETE_STRATEGY_IMAGE, payload: res.data });
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const addDraftIndicator = indicatorValues => {
  return {
    type: ADD_INDICATOR,
    payload: indicatorValues,
  };
};

export const editDraftIndicator = payload => {
  return {
    type: EDIT_INDICATOR,
    payload: payload,
  };
};

export const removeDraftIndicator = id => {
  return {
    type: REMOVE_INDICATOR,
    payload: id,
  };
};

export const resetStrategy = () => ({ type: RESET_STRATEGY });

export const resetStrategies = () => ({ type: RESET_STRATEGIES });

export const resetSignals = () => ({ type: RESET_SIGNALS });

export const resetWizardIndicators = () => ({ type: RESET_WIZARD_INDICATORS });
