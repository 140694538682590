import React from "react";
import { Card } from "reactstrap";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const BalancesPreloader = () => {
  return (
    <Card className='p-4'>
      <div className='d-flex align-items-center'>
        <SkeletonPreloader circle={true} height={16} width={16} />
        <SkeletonPreloader height={12} width={60} className='ml-2' />
      </div>
      <div className='d-flex align-items-center justify-content-between mt-4'>
        <div className='d-flex flex-column'>
          <SkeletonPreloader
            count={4}
            height={12}
            width={60}
            className='mt-3'
          />
        </div>
        <div className='d-flex flex-column'>
          <SkeletonPreloader height={12} width={177} className='mt-3' />
          <div className='d-flex align-items-center mt-3'>
            <SkeletonPreloader height={12} width={45} />
            <SkeletonPreloader height={12} width={123} className='ml-2' />
          </div>
          <SkeletonPreloader
            count={2}
            height={12}
            width={177}
            className='mt-3'
          />
        </div>
      </div>
    </Card>
  );
};

export default BalancesPreloader;
