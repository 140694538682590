import React from "react";
import { useSelector } from "react-redux";

import OverviewTab from "./Overview/OverviewTab";
import MarketTab from "./Market/MarketTab";
import HistoryTab from "./History/HistoryTab";
import { selectMarketInformation } from "store/deals/selectors";
import { RenderTabs } from "shared/ui";

const PagesView = ({
  data,
  loadingPage,
  id,
  pageView,
  setPagView,
  onHandleRefresh,
}) => {
  const pages = ["overview", "market", "history"];

  const marketInformation = useSelector(selectMarketInformation);
  const isMarketHasError =
    marketInformation?.error && !marketInformation?.loading;

  return (
    <>
      <RenderTabs
        arrTabs={pages}
        setPagView={setPagView}
        currentTab={pageView}
        loading={loadingPage || marketInformation?.loading}
        shouldExclude={page => isMarketHasError && page === "market"}
      />

      {pageView === "overview" && (
        <OverviewTab
          id={id}
          data={data}
          loadingPage={loadingPage}
          onHandleRefresh={onHandleRefresh}
        />
      )}
      {pageView === "market" && (
        <MarketTab
          data={data}
          loadingPage={loadingPage}
          marketInformation={marketInformation}
        />
      )}
      {pageView === "history" && (
        <HistoryTab
          id={id}
          credId={data?.bot?.investment?.credentials?.id}
          market={data?.market}
          loadingPage={loadingPage}
        />
      )}
    </>
  );
};

export default PagesView;
