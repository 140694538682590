import React from "react";
import { Card } from "reactstrap";

import TablePreloader from "shared/ui/TablePreloader";

const ChannelSignalsTablePreloader = () => {
  return (
    <Card className='pt-3 px-4 mb-0 overflow-hidden flex-row'>
      <TablePreloader
        thHeight={16}
        thWidth={[43, 66, 48, 47, 60, 34, 57, 57]}
        tdWidth={[96, 80, 80, 80, 80, 64, 111, 76]}
        tdHeight={16}
        rowTimes={7}
        withCircle={24}
        isAlternateCircle
      />
    </Card>
  );
};

export default ChannelSignalsTablePreloader;
