import React from "react";
import styled from "styled-components";

const EmptyDealsStatisticsChart = () => {
  return (
    <div className='d-flex flex-column align-items-center mt-5 pt-4'>
      <h3 className='text-white font-weight-500 mb-2'>
        There is not enough data to display
      </h3>
      <EmptyDealsStatisticsChart.Description>
        Keep calm, this chart will be displayed here as soon as this bot will
        create some deals.
      </EmptyDealsStatisticsChart.Description>
    </div>
  );
};

EmptyDealsStatisticsChart.Description = styled.p`
  color: rgba(255, 255, 255, 0.75) !important;
  margin-bottom: 0;
  font-size: 14px;
  text-align: center;
`;

export default EmptyDealsStatisticsChart;
