import { GET_ASSETS, GET_ALLOWED_ASSETS } from "./constants";

export default function assets(state = [], action) {
  switch (action.type) {
    case GET_ASSETS:
      return [...action.payload];

    case GET_ALLOWED_ASSETS:
      return [...action.payload];

    default:
      return state;
  }
}
