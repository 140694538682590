import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { CardBody } from "reactstrap";

import LineWithError from "shared/ui/LineWithError";
import { ReactComponent as Flag } from "assets/icons/general/flag.svg";
import RoundsLoading from "../../deals/ui/preloaders/RoundsLoading";
import TradeRequest from "./TradeRequest";
import EmptyRequestsList from "./EmptyRequestsList";
import TradeRequestsDetailsModal from "./TradeRequestsDetailsModal";
import { rebalanceTradeRequestsSelector } from "store/rebalance/selectors";

const TradeRequestsWrapper = () => {
  const { data, loading } = useSelector(rebalanceTradeRequestsSelector);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenID, setIsModalOpenID] = useState(null);

  if (loading) return <RoundsLoading />;

  return (
    <>
      <div className='d-flex align-items-center mb-3 justify-content-between'>
        <div className='d-flex mr-3'>
          <Flag />
          <TradeRequestsWrapper.Title>
            Trade requests
          </TradeRequestsWrapper.Title>
        </div>
      </div>
      {data?.items?.length ? (
        data.items.map((request, index) =>
          request.error ? (
            <TradeRequestsWrapper.CardBody key={request?.id} className='mb-2'>
              <LineWithError errorMessage={request?.error} radius='none' />
            </TradeRequestsWrapper.CardBody>
          ) : (
            <TradeRequest
              key={request.tradeRequest?.id}
              index={index}
              data={request.tradeRequest}
              setIsModalOpen={setIsModalOpen}
              setIsModalOpenID={setIsModalOpenID}
              isModal={true}
              renderMarket={true}
            />
          ),
        )
      ) : (
        <EmptyRequestsList
          title='No available trade requests for this rebalance yet'
          beforeDescription='As soon as this rebalance will open some'
          afterDescription='requests, they will be displayed here'
        />
      )}
      {isModalOpen && (
        <TradeRequestsDetailsModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isModalOpenID={isModalOpenID}
          itemsRebalance={data?.items}
        />
      )}
    </>
  );
};

TradeRequestsWrapper.Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 0 8px;
  color: #ffffff !important;
`;

TradeRequestsWrapper.CardBody = styled(CardBody)`
  background: #2e3148;
  border-radius: 8px !important;
`;

export default TradeRequestsWrapper;
