import React from "react";

import FinishBotModal from "./FinishBotModal";
import { ErrorAlert } from "shared/ui";

const WizardErrors = ({
  errors = {},
  status = {},
  alertOpen,
  redirectPath = "/",
  onSetAlertOpen,
  setIsEdit,
  isEdit,
  setErrors,
}) => {
  return (
    <>
      <ErrorAlert
        fade={false}
        toggle={() => setErrors({ ...errors, message: "" })}
        isOpen={!!errors.message}
      >
        {errors.message}
      </ErrorAlert>
      {alertOpen && (
        <FinishBotModal
          onSetAlertOpen={onSetAlertOpen}
          title={status.message}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          alertOpen={alertOpen}
          redirectPath={redirectPath}
        />
      )}
    </>
  );
};

export default WizardErrors;
