import React from "react";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const DefaultHeaderPreloader = () => {
  return (
    <div className='d-flex justify-content-between align-items-center px-4 pt-3 pb-0'>
      <div className='d-flex align-items-center'>
        <SkeletonPreloader
          circle
          height={20}
          width={20}
          className='mt-2 mb-2'
        />
        <SkeletonPreloader
          count={1}
          width={113}
          height={13}
          className='mt-2 mb-2 ml-2'
        />
      </div>
      <SkeletonPreloader
        width={24}
        height={24}
        borderRadius='6px'
        className='mt-2 mb-2'
      />
    </div>
  );
};

export default DefaultHeaderPreloader;
