import React, { useState } from "react";
import { CardHeader, Tooltip } from "reactstrap";
import { Icon } from "shared/ui";
import PlannedRange from "shared/ui/AllocationRebalancing/PlannedRange";
import RealRange from "shared/ui/AllocationRebalancing/RealRange";
import { LookupCoins } from "shared/utils";

const PortfolioHeader = ({
  investment,
  index,
  rebalance,
  initialPlannedCount,
}) => {
  const [isShow, setIsShow] = useState(false);
  const toggleTooltip = () => setIsShow(prevState => !prevState);

  return (
    <CardHeader className='py-3 py-4'>
      <div className='d-flex flex-column flex-lg-row justify-content-between'>
        <div className='d-flex align-items-center mb-2 mb-lg-0'>
          <Icon
            type='cryptocurrency'
            size='thumb'
            code={investment.asset}
            className='my-auto mr-2'
          />
          <h3 className='text-white ml-2 mb-0'>{investment.asset}</h3>
          <h3 className='ml-2 mb-0 font-weight-500 text-white-35'>
            <LookupCoins coin={investment.asset} />
          </h3>
        </div>
        <div className='d-flex align-items-center'>
          <p
            className='mr-2 mb-0 text-nowrap font-sm'
            style={{ color: "#848EFA" }}
          >
            {`${investment.realPercent} % `}
          </p>
          <div style={{ width: "360px" }}>
            {!initialPlannedCount ? (
              <div id={`realRange-${investment?.id}`}>
                <RealRange
                  index={index}
                  realPercent={investment?.realPercent}
                  enabled={rebalance}
                />
                <Tooltip
                  placement='top'
                  target={`realRange-${investment?.id}`}
                  isOpen={isShow}
                  toggle={toggleTooltip}
                >
                  <p
                    className='font-xs font-weight-500 p-0'
                    style={{ color: "#848EFA", fontSize: "12px" }}
                  >
                    {"Real percent"}
                  </p>
                </Tooltip>
              </div>
            ) : (
              <div id={`plannedRange-${investment?.id}`}>
                <PlannedRange
                  index={index}
                  plannedPercent={investment?.plannedPercent}
                  realPercent={investment?.realPercent}
                  enabled={rebalance}
                />
                <Tooltip
                  placement='top'
                  target={`plannedRange-${investment?.id}`}
                  isOpen={isShow}
                  toggle={toggleTooltip}
                >
                  <p
                    className='font-xs font-weight-500 p-0'
                    style={{ color: "#848EFA", fontSize: "12px" }}
                  >
                    {"Planned percent"}
                  </p>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
    </CardHeader>
  );
};

export default PortfolioHeader;
