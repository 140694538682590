import React from "react";
import { useSelector } from "react-redux";

import { getUsersReferrals } from "store/user/actions";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import { REFERRAL_TABLE_HEAD } from "models/user/tableHead";
import { ReactComponent as ConfirmedFalse } from "assets/icons/checkbox/confirmedFalse.svg";
import { ReactComponent as ConfirmedTrue } from "assets/icons/checkbox/successfully.svg";
import { CustomScrollbars, PaginationPanel, SortTableHead } from "shared/ui";
import { StyledTable } from "shared/styled/StyledTable";
import ReferralSystemTablePreloader from "views/user/preloaders/ReferralSystemTablePreloader";

const ReferralSystemTable = ({ referrals = {} }) => {
  const referralsLoading = useSelector(state => state.me.isReferralsLoading);
  const { handlePaginate, handleSorting } = useFiltersWithRequest(
    referrals.request,
    getUsersReferrals,
  );

  if (referralsLoading) return <ReferralSystemTablePreloader />;

  return (
    <>
      <CustomScrollbars
        autoHideTimeout={500}
        autoHideDuration={500}
        autoHeight
        autoHeightMax={Number.MAX_VALUE}
      >
        <StyledTable>
          <SortTableHead
            theadItems={REFERRAL_TABLE_HEAD}
            handleSorting={handleSorting}
            sort={referrals?.request?.sort}
          />
          <tbody className='font-sm'>
            {referrals?.items?.map(item => (
              <tr key={item.id}>
                <td>
                  <p className='text-white'>{item.email}</p>
                </td>
                <td>
                  <p className='text-white'>{item.name}</p>
                </td>
                <td>
                  <p className='text-capitalize text-white'>
                    {!item.subscription ? "Free" : item.subscription}
                  </p>
                </td>
                <td>
                  <p className='text-white'>{item.trial ? "Yes" : "No"}</p>
                </td>
                <td>
                  <span>
                    {item.confirmed ? <ConfirmedTrue /> : <ConfirmedFalse />}
                  </span>
                </td>
                <td>
                  <p className='text-white text-nowrap'>{item.createdAt}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </CustomScrollbars>
      <div className={referrals?.total > 10 ? "pt-3" : ""}>
        <PaginationPanel
          activePage={referrals?.request?.page}
          totalItemsCount={referrals?.total}
          onChange={page => handlePaginate(page)}
        />
      </div>
    </>
  );
};

export default ReferralSystemTable;
