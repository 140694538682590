import React from "react";
import styled from "styled-components";
import { Card } from "reactstrap";

import { ReactComponent as WalletIcon } from "assets/icons/wallet-money-icon.svg";
import { investedPercentColors } from "helpers/investedPercentColors";

const Invested = ({ invested = {}, me, content }) => {
  return (
    <Invested.Card>
      <Invested.FixedCardBody className='d-flex align-items-center p-4'>
        <WalletIcon />
        <div className='d-flex flex-column ml-3'>
          <Invested.Title className='font-sm'>{content.title}</Invested.Title>
          <div className='d-flex align-items-center text-nowrap'>
            <Invested.Amount
              className={`h3 m-0 ${invested.amount > 0 ? "text-white" : "text-white-35"}`}
            >
              {invested.amount || "0.00"}{" "}
              <Invested.Currency className='h3 m-0'>
                {invested.currency || me.currency}
              </Invested.Currency>
            </Invested.Amount>
          </div>
          <Invested.Percent
            className={`font-xs ${investedPercentColors(invested.percent)}`}
          >
            {`${invested.percent || "0.00"} %`}
          </Invested.Percent>
        </div>
      </Invested.FixedCardBody>
    </Invested.Card>
  );
};

Invested.Card = styled(Card)`
  border-radius: 8px !important;
  margin-bottom: 24px !important;
  padding: 0 !important;
`;

Invested.FixedCardBody = styled.div`
  height: 86px;
`;

Invested.Title = styled.p`
  font-weight: 300;
  color: rgba(255, 255, 255, 0.75) !important;
  margin-bottom: 0;
`;

Invested.Amount = styled.p`
  font-weight: 500;
  margin-bottom: 0;
`;

Invested.Currency = styled.span`
  font-weight: 300;
  margin: 0 0 0 4px;
  color: rgba(255, 255, 255, 0.35) !important;
`;

Invested.Percent = styled.p`
  margin-bottom: 0;
  white-space: nowrap;
`;

export default Invested;
