/* eslint-disable no-console */
import React, { useEffect, useState } from "react";

import defaultIcon from "assets/icons/payments/stripe.svg";

const PaymentIcon = ({ name = "", ...props }) => {
  const [iconUrl, setIconUrl] = useState(null);

  useEffect(() => {
    const fetchIcon = async () => {
      try {
        const icon = await import(`assets/icons/payments/${name}.svg`);
        setIconUrl(icon.default);
      } catch (error) {
        console.error(`Failed to load payment icon '${name}':`, error);
      }
    };

    fetchIcon();
  }, [name]);

  return (
    <img
      src={iconUrl || defaultIcon}
      alt={name}
      style={{ width: "31px", height: "20px" }}
      {...props}
    />
  );
};

export default PaymentIcon;
