import styled from "styled-components";
import moment from "moment";
import { Card, CardBody } from "reactstrap";
import { capitalize } from "lodash";
import { useState } from "react";

import CommentModal from "../../modals/CommentModal";
import CredentialHeaderPreloader from "./components/CredentialHeaderPreloader";
import { ReactComponent as Clock } from "assets/icons/general/clock.svg";
import { ReactComponent as ForkOff } from "assets/icons/credential/forkOff.svg";
import { ReactComponent as Fork } from "assets/icons/credential/fork.svg";
import { EditButton, Icon, IdWithCopy, LineWithError } from "shared/ui";

const CredentialHeader = ({
  id,
  comment,
  createdAt,
  exchange,
  loading,
  error,
  connected,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (loading) return <CredentialHeaderPreloader />;

  return (
    <CredentialHeader.Card data-testid='credential-header-card'>
      <CredentialHeader.CardBody>
        <div className='d-flex flex-column flex-md-row justify-content-between'>
          <div className='d-flex align-items-center overflow-hidden mb-2 mb-md-0'>
            <div className='d-flex position-relative'>
              <Icon
                data-testid='credential-header-icon'
                type='exchanges'
                size='xs'
                code={exchange}
                className='mr-3 my-auto'
              />
              <CredentialHeader.IconWrapper className='connect-icon'>
                {connected ? (
                  <Fork
                    width='100%'
                    height='23px'
                    data-testid='credential-header-fork'
                  />
                ) : (
                  <ForkOff
                    width='100%'
                    height='23px'
                    data-testid='credential-header-forkOff'
                  />
                )}
              </CredentialHeader.IconWrapper>
            </div>
            <CredentialHeader.TitleWrapper>
              <div className='d-flex align-items-center mb-1'>
                <h2
                  className='mb-0 text-white font-weight-500'
                  data-testid='credential-header-title'
                >
                  {capitalize(exchange)}
                </h2>
                {isModalOpen ? (
                  <>
                    <CredentialHeader.EditComment
                      data-testid='credential-header-edit-comment-open'
                      comment={comment}
                      className='text-white-35'
                    >
                      {comment}
                    </CredentialHeader.EditComment>
                    <CommentModal
                      data-testid='credential-header-comment-modal'
                      comment={comment}
                      id={id}
                      isToggle={() => setIsModalOpen(prevState => !prevState)}
                      setIsModalOpen={setIsModalOpen}
                      isModalOpen={isModalOpen}
                    />
                  </>
                ) : (
                  <>
                    <CredentialHeader.EditComment
                      data-testid='credential-header-edit-comment-close'
                      comment={comment}
                      className='text-white-35'
                    >
                      {comment}
                    </CredentialHeader.EditComment>
                    <EditButton
                      type={"pencil"}
                      onClick={() => setIsModalOpen(true)}
                    />
                  </>
                )}
              </div>
              <IdWithCopy id={id} />
            </CredentialHeader.TitleWrapper>
          </div>
          <div className='d-flex flex-column justify-content-between'>
            <div className='d-flex justify-content-start justify-content-md-end'>
              <CredentialHeader.Badge
                connected={connected}
                data-testid='credential-header-badge'
              >
                <span>{connected ? "Connected" : "Disconnected"}</span>
              </CredentialHeader.Badge>
            </div>
            <CredentialHeader.Time className='text-white-35 font-xs'>
              <Clock
                className='icon-stroke-gray mr-1'
                aria-hidden='true'
                data-testid='credential-header-clock'
              />
              <p
                data-testid='credential-header-createdAt'
                className='d-flex mb-0 text-white-35'
              >
                {moment(createdAt).format("DD.MM.YYYY HH:mm")}
              </p>
            </CredentialHeader.Time>
          </div>
        </div>
      </CredentialHeader.CardBody>
      {error && (
        <LineWithError
          errorMessage={error}
          dataTestId='credential-header-error-line'
        />
      )}
    </CredentialHeader.Card>
  );
};

CredentialHeader.CardBody = styled(CardBody)`
  padding: 1.5rem 2rem !important;
`;

CredentialHeader.Card = styled(Card)`
  border-radius: 8px !important;
`;

CredentialHeader.EditComment = styled.span`
  margin: ${props => (props.comment ? "0 8px 0 16px" : "0 0 0 16px")};
  padding-top: 6px;
  border-radius: 4px;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

CredentialHeader.TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

CredentialHeader.Badge = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 3.5px 10px;
  border-radius: 6px;

  span {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    color: ${props =>
      props.connected ? "#5FD3BC" : "rgba(255,255,255, 0.35)"} !important;
  }
`;

CredentialHeader.Time = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
`;

CredentialHeader.IconWrapper = styled.div`
  position: absolute;
  transform: scale(-1, 1);
  padding: 2px;
  width: 28px;
  height: 28px;
  right: 12%;
  bottom: 0%;
  background: #27293d;
  border-radius: 50px;
`;

export default CredentialHeader;
