import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useFormikContext } from "formik";

import MartingaleForm from "./MartingaleForm";
import MartingaleTable from "./MartingaleTable";
import MartingaleTablePreloader from "./Martingale/MartingaleTablePreloader";
import AboutAverageDownStrategy from "./modals/AboutAverageDownStrategy";
import { ReactComponent as Chevron } from "assets/icons/dashboard/dropdownIndicator.svg";
import { ToggleSwitch } from "shared/ui";
import { getAvailableMarkets } from "store/bots/actions";
import { selectMarketsSignal } from "store/bots/selectors";

const Martingale = ({ submitted, currentStep }) => {
  const [isMartingaleTable, setIsMartingaleTable] = useState(false);
  const [isOpenAbout, setIsOpenAbout] = useState(false);
  const { values, errors, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();

  const { data, loading } = useSelector(selectMarketsSignal);
  const { investment } = useSelector(state => state.botWizard);

  useLayoutEffect(() => {
    if (isMartingaleTable && currentStep === 2 && !data?.length)
      dispatch(getAvailableMarkets(investment.id, "?limit=500"));
  }, [dispatch, isMartingaleTable, investment.id, currentStep, data?.length]);

  useEffect(() => {
    if (!data?.items?.length) {
      dispatch(getAvailableMarkets(investment?.id, "?limit=500"));
    }
  }, [dispatch]);

  const handleMartingale = (value, setFieldValue) => {
    setFieldValue("martingale", value);
    if (values?.martingale) {
      setFieldValue("deviation", null);
      setFieldValue("martingalePercent", null);
      setFieldValue("maxTradeRequests", null);
    }
  };

  const renderTable = () => {
    if (isMartingaleTable) {
      return loading ? (
        <MartingaleTablePreloader />
      ) : (
        data && <MartingaleTable />
      );
    }
    return null;
  };

  return (
    <Martingale.Wrapper data-testid='martingale-wrapper'>
      <div className='martingale d-flex-column p-4'>
        <div className='d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between mb-1'>
          <div className='d-flex align-items-center'>
            <ToggleSwitch
              height={18}
              width={36}
              onColor='#26366b'
              offColor='#4b4c5e'
              onHandleColor='#00F2C3'
              offHandleColor='#FFFFFF59'
              activeBoxShadow='none'
              checked={values?.martingale}
              onChange={value => handleMartingale(value, setFieldValue)}
            />
            <p className='martingale font-weight-500 text-white ml-3 mb-0 mr-2'>
              Average down strategy
            </p>
            <a
              href='/#'
              onClick={e => {
                e.preventDefault();
                setIsOpenAbout(true);
              }}
              className='d-none d-sm-block text-info font-weight-500'
            >
              What is this?
            </a>
            <a
              href='/#'
              onClick={e => {
                e.preventDefault();
                setIsOpenAbout(true);
              }}
              className='d-block d-sm-none text-info font-weight-500 ml-1'
            >
              Info
            </a>
          </div>
        </div>
        {values?.martingale ? (
          <MartingaleForm
            submitted={submitted}
            errors={errors}
            values={values}
            setFieldValue={setFieldValue}
          />
        ) : null}
      </div>
      {values?.martingale && (
        <>
          <Martingale.Table
            data-testid='martingale-table'
            isMartingaleTable={isMartingaleTable}
            onClick={() => setIsMartingaleTable(!isMartingaleTable)}
          >
            <p className='mb-0 text-white'>
              {isMartingaleTable
                ? "Hide martingale calculation table"
                : "Show martingale calculation table"}
            </p>
            <Chevron className='chevron' />
          </Martingale.Table>
          {renderTable()}
        </>
      )}
      <AboutAverageDownStrategy
        isOpen={isOpenAbout}
        setIsOpen={setIsOpenAbout}
      />
    </Martingale.Wrapper>
  );
};

Martingale.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #363a54;
  background: #2e3148;
  border-radius: 12px;
  margin-bottom: 16px;
  .chevron {
    transition: transform 0.2s ease-in-out;
    transform: ${props => (props.isMartingaleTable ? "rotate(-180deg)" : null)};
  }
`;

Martingale.Table = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(58, 61, 90, 1);
  padding: 8px 24px;
  cursor: pointer;
  border-radius: 0 0 10px 10px;
  border-radius: ${({ isMartingaleTable }) =>
    isMartingaleTable ? "0" : "0 0 10px 10px"};
`;

export default Martingale;
