import React from "react";
import styled from "styled-components";
import { Row, Col } from "reactstrap";
import { times } from "lodash";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import PlanCardPreloader from "./PlanCardPreloader";
import TablePreloader from "./TablePreloader";

const BillingPagePreloader = () => {
  return (
    <BillingPagePreloader.Wrapper>
      <BillingPagePreloader.Header className='d-flex justify-content-between'>
        <div className='d-flex align-items-end'>
          <SkeletonPreloader
            height={29}
            width={154}
            className='mr-3 sq-preload'
          />
          <SkeletonPreloader height={14} width={112} />
        </div>
        <div className='d-flex'>
          <SkeletonPreloader
            height={40}
            width={110}
            className='mr-2 sq-preload'
          />
          <SkeletonPreloader height={40} width={110} className='sq-preload' />
        </div>
      </BillingPagePreloader.Header>
      <Row className='px-2'>
        {times(3, e => (
          <Col md='4' key={e} className='px-2'>
            <PlanCardPreloader />
          </Col>
        ))}
      </Row>
      <TablePreloader />
    </BillingPagePreloader.Wrapper>
  );
};

BillingPagePreloader.Header = styled.div`
  margin-bottom: 2rem;
`;

BillingPagePreloader.Wrapper = styled.div`
  & .sq-preload {
    border-radius: 6px !important;
  }

  & hr {
    margin-top: 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

export default BillingPagePreloader;
