import { combineReducers } from "redux";

import withDataLoading from "store/withDataLoading";
import me from "./user/reducers";
import notifications from "./notification/reducers";
import search from "./search/reducers";
import { dashboard } from "./dashboard/reducers";
import credentials from "./credentials/reducers";
import deals from "./deals/reducers";
import payments from "./payments/reducers";
import bots from "./bots/reducers";
import investment from "./investment/reducers";
import assets from "./assets/reducers";
import strategies from "./strategies/reducers";
import exchanges from "./exchanges/reducers";
import indicatorsConfiguration from "./indicatorsConfiguration/reducers";
import labels from "./labels/reducers";
import notificationsCodes from "./notificationsCodes/reducers";
import backtesting from "./backtesting/reducers";
import snackBar from "./snackbar/reducers";
import botWizard from "./bots/wizard/reducers";
import channels from "./channels/reducers";
import signals from "./signals/reducers";
import agreements from "./agreements/reducers";
import candleConditions from "./candleConditions/reducers";
import markets from "./markets/reducers";

export default combineReducers({
  me: withDataLoading(me, "ME"),
  notifications: withDataLoading(notifications, "NOTIFICATIONS"),
  search,
  loginHistory: withDataLoading(null, "LOGIN_HISTORY"),
  dashboard: withDataLoading(dashboard, "DASHBOARD"),
  credentials,
  deals,
  payments,
  bots,
  availableMarkets: withDataLoading(null, "AVAILABLE_MARKETS"),
  markets,
  topMarkets: withDataLoading(null, "TOP_MARKETS"),
  investment,
  assets,
  transactions: withDataLoading(null, "TRANSACTIONS"),
  strategies,
  indicatorsConfiguration,
  exchanges,
  labels,
  notificationsCodes,
  backtesting,
  snackBar,
  rebalance: withDataLoading(null, "REBALANCE"),
  rebalanceTradeRequests: withDataLoading(null, "REBALANCE_TRADE_REQUESTS"),
  products: withDataLoading(null, "PRODUCTS"),
  botWizard,
  channels,
  signals,
  agreements,
  candleConditions,
});
