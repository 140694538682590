import React from "react";
import Switch from "react-switch";

const ToggleSwitch = ({
  onChange,
  checked,
  height,
  width,
  onColor = "#305383",
  offColor = "#585a6d",
  onHandleColor = "#00F2C3",
  offHandleColor = "#d5d6db",
  ...props
}) => (
  <Switch
    onChange={onChange}
    checked={checked}
    checkedIcon={false}
    uncheckedIcon={false}
    height={height}
    width={width}
    onColor={onColor}
    offColor={offColor}
    onHandleColor={onHandleColor}
    offHandleColor={offHandleColor}
    {...props}
  />
);

export default ToggleSwitch;
