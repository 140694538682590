export const EXECUTION_TYPE_FIELDS = [
  { value: "passive", label: "Passive limit" },
  { value: "aggressive", label: "Aggressive limit" },
  { value: "market", label: "Market" },
];

export const MARTINGALE_FIELDS = [
  { value: "martingalePercent", label: "Martingale percent", type: "float" },
  { value: "deviation", label: "Price deviation", type: "float" },
  { value: "maxTradeRequests", label: "Maximum trade requests", type: "int" },
];
