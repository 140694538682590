import React from "react";
import { Card, CardBody, CardHeader, FormGroup, Label } from "reactstrap";
import { TELEGRAM_BOT } from "env-create-react-app";
import classNames from "classnames";
import styled from "styled-components";

import Button from "shared/ui/buttons/Button";
import SubCard from "shared/ui/SubCard";
import { ReactComponent as TelegramIcon } from "assets/icons/social/telegram.svg";

const TelegramForm = ({
  secretValue,
  submitted,
  errors,
  fieldProps,
  isSubmitting,
  onConnectTelegram,
}) => {
  const handleConnect = () => onConnectTelegram();

  return (
    <Card className='p-2'>
      <TelegramForm.Header className='d-flex flex-row flex-md-column flex-xl-row align-items-xl-center'>
        <div className='d-flex align-items-center'>
          <TelegramIcon className='mr-1' />
          <p className='mb-0 mr-2 font-weight-500 text-white'>Telegram</p>
        </div>
        <SubCard className='mt-md-2 mt-xl-0'>
          <p className='mb-0 font-weight-500 font-sm text-danger'>
            Disconnected
          </p>
        </SubCard>
      </TelegramForm.Header>
      <CardBody>
        <div className='d-flex flex-column'>
          <div className='font-sm'>
            <span className='mb-0 text-white-75'>
              In order to connect Telegram, follow the link to our
            </span>
            <TelegramForm.Link
              href={`https://t.me/${TELEGRAM_BOT}`}
              target='_blank'
              rel='noopener noreferrer'
              className='mx-1 link-info'
            >
              @ioDarkbot
            </TelegramForm.Link>
            <span className='mb-0 text-white-75'>and run</span>
            <span className='text-info mx-1'>/start</span>
            <span className='mb-0 text-white-75'>
              command. You will receive an activation code that needs to be
              entered below.
            </span>
          </div>
          <div className='justify-content-center mt-3'>
            <Label className='text-white-75 font-sm'>Activation code</Label>
            <FormGroup className='mb-0'>
              <input
                className={classNames("form-control mb-1", {
                  "has-error": submitted && errors.secret,
                })}
                name='secret'
                placeholder='Type in...'
                value={secretValue.secret}
                {...fieldProps}
              />
              {submitted && errors.secret ? (
                <span className='text-danger font-weight-300 mb-0 font-sm'>
                  Please, check you if you entered an activation code and try
                  again
                </span>
              ) : null}
            </FormGroup>
          </div>
          <div className='mt-4 w-100'>
            <Button
              color='blue'
              loading={isSubmitting}
              className='w-100'
              type='submit'
              onClick={handleConnect}
            >
              Connect Telegram
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

TelegramForm.Header = styled(CardHeader)`
  p {
    line-height: 19px;
  }
`;

TelegramForm.Link = styled.a`
  width: inherit;
`;

export default TelegramForm;
