/* eslint-disable no-console */

import { useState, useEffect, useRef } from "react";
import qs from "qs";
import { useHistory } from "react-router-dom";

import exchangesConfig from "./exchangesConfig";
import {
  updateGroupPrices,
  findDuplicatesAndSum,
  convertOrderBook,
  sortWithSumOrderBook,
  getSpread,
  getSpreadPercent,
} from "./utils";

export const useExchangeWS = (
  exchange,
  typeOfData = [],
  markets = [],
  isCheckSymbol,
) => {
  const [ticker, setTicker] = useState([]);
  const [error, setError] = useState(null);
  const [isSymbolValid, setIsSymbolValid] = useState(false);
  const [trades, setTrades] = useState({
    isLoading: false,
    data: [],
    error: null,
  });
  const [orderBook, setOrderBook] = useState({});
  const [isWebsocketOpen, setWebsocketOpen] = useState(false);
  const [group, setGroup] = useState(null);
  const ws = useRef(null);
  const prevId = useRef(null);
  const {
    url,
    unsubscribe,
    subscribe,
    pingMessage,
    pingInterval,
    handleWSMessage,
    getOrderBookSnapshot,
    getTrades,
    checkSymbol,
  } = exchange ? exchangesConfig[exchange] : {};
  const history = useHistory();

  const getOrderBook = limit => {
    getOrderBookSnapshot(markets, limit).then(data => {
      prevId.current = data.lastUpdateId;
      const orderBookData = {
        asks: data.asks.length ? convertOrderBook(data.asks) : [],
        bids: data.bids.length ? convertOrderBook(data.bids) : [],
      };
      setOrderBook({
        asks: sortWithSumOrderBook(orderBookData.asks, "asks"),
        bids: sortWithSumOrderBook(orderBookData.bids, "bids"),
      });
    });
  };

  useEffect(() => {
    const isOrderBookConnected = typeOfData.includes("orderBook");
    if (
      isOrderBookConnected &&
      typeof getOrderBookSnapshot === "function" &&
      ((isSymbolValid && isCheckSymbol) || !isCheckSymbol)
    ) {
      getOrderBook();
    }
  }, [
    `${markets}`,
    `${typeOfData}`,
    getOrderBookSnapshot,
    isCheckSymbol,
    isSymbolValid,
  ]);

  const getSearch = () => {
    return qs.parse(history.location.search, { ignoreQueryPrefix: true });
  };

  const handleFilter = (filter, value) => {
    const search = getSearch();
    history.push({
      search: qs.stringify({ ...search, [filter]: value }),
    });
  };

  const handleSetDepth = value => {
    if (typeof getOrderBookSnapshot === "function") {
      getOrderBook(value);
    } else {
      unsubscribe(["orderBook"], markets, ws.current);
      setTimeout(() => {
        subscribe(["orderBook"], markets, ws.current);
      }, 0);
    }
    handleFilter("depth", value);
  };

  const handleLastIdCheck = (id, lastId) => {
    if (prevId.current && prevId.current !== lastId) {
      unsubscribe(typeOfData, markets, ws.current);
      Promise.resolve().then(() => {
        subscribe(typeOfData, markets, ws.current);
      });
    }
    prevId.current = id;
  };

  const handleClose = () => {
    ws.current?.close();
    setTicker([]);
    setTrades({ isLoading: true, data: [], error: null });
    setOrderBook({});
  };

  useEffect(() => {
    const isTradesRequired = typeOfData.some(type => type === "trades");
    if (
      isTradesRequired &&
      typeof getTrades === "function" &&
      ((isSymbolValid && isCheckSymbol) || !isCheckSymbol)
    ) {
      setTrades(prev => ({ ...prev, isLoading: true }));
      getTrades(markets)
        .then(data =>
          setTrades(prev => ({
            ...prev,
            data: data.splice(0, 10),
            isLoading: false,
          })),
        )
        .catch(error => {
          setTrades(prev => ({ ...prev, error, isLoading: false }));
        });
    }
  }, [exchange, `${typeOfData}`, `${markets}`, isSymbolValid, isCheckSymbol]);

  const createWsConnection = () => {
    if (isWebsocketOpen) {
      handleClose();
      setWebsocketOpen(false);
      ws.current = null;
    }
    try {
      ws.current = new WebSocket(url);
      ws.current.onopen = () => {
        setWebsocketOpen(true);
        subscribe(typeOfData, markets, ws.current);
      };
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (exchange && markets.length && typeOfData.length) {
      createWsConnection();
      setWebsocketOpen(true);
      ws.current.onmessage = e => {
        const data = JSON.parse(e.data);
        const depth = getSearch().depth || 15;
        handleWSMessage(
          data,
          setTicker,
          setOrderBook,
          setTrades,
          handleLastIdCheck,
          depth,
          prevId.current,
        );
      };
      ws.current.onerror = e => setError(e);
      ws.onclose = () => setWebsocketOpen(false);

      return () => handleClose();
    }
  }, [exchange, `${typeOfData}`, `${markets}`]);
  useEffect(() => {
    if (isWebsocketOpen && pingInterval) {
      const intervalId = setInterval(() => {
        ws.current.send(pingMessage);
      }, pingInterval);

      return () => clearInterval(intervalId);
    }
  }, [isWebsocketOpen, pingMessage, pingInterval]);

  const groupOrders = (orderBook, group) => {
    if (!group) return orderBook;
    const bids = findDuplicatesAndSum(updateGroupPrices(orderBook.bids, group));
    const asks = findDuplicatesAndSum(updateGroupPrices(orderBook.asks, group));
    return { bids, asks };
  };

  const getOrders = orderBook => {
    const spread = getSpread(orderBook.asks, orderBook.bids);
    const spreadPercent = getSpreadPercent(
      orderBook.asks,
      orderBook.bids,
      spread,
    );
    if (group?.value)
      return { ...groupOrders(orderBook, group), spread, spreadPercent };
    return { ...orderBook, spread, spreadPercent };
  };

  useEffect(() => {
    if (isCheckSymbol) {
      if (error) setError(null);
      const market = markets[0]?.split("_");
      if (market?.[0] && market?.[1])
        setTrades(prev => ({ ...prev, isLoading: true }));
      checkSymbol(market[0], market[1])
        .then(() => setIsSymbolValid(true))
        .catch(() => setError("Invalid symbol"));
    }
  }, [`${markets}`, isCheckSymbol, exchange]);

  return {
    ticker,
    orderBook: getOrders(orderBook),
    isWebsocketOpen,
    handleClose,
    setGroup,
    group,
    handleSetDepth,
    depth: getSearch().depth || 15,
    trades,
    error,
  };
};

export default useExchangeWS;
