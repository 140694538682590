import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "shared/ui/buttons/Button";
import { ReactComponent as CheckMark } from "assets/icons/checkbox/CheckMark.svg";
import { ReactComponent as CrossMark } from "assets/icons/general/CrossMark.svg";

const InlineButton = ({ onCancel, onSubmit, loading }) => {
  return (
    <div className='d-flex'>
      <InlineButton.Mark className='mr-1 ml-2' type='button' onClick={onCancel}>
        <CrossMark />
      </InlineButton.Mark>

      <InlineButton.Mark
        color='blue'
        type='submit'
        onClick={onSubmit}
        loading={loading}
      >
        <CheckMark width='27px' height='27px' />
      </InlineButton.Mark>
    </div>
  );
};

InlineButton.Mark = styled(Button)`
  width: 36px;
  height: 36px;
  padding: 0.5rem;

  & svg {
    & path {
      stroke: #fff;
    }
  }
`;

InlineButton.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default InlineButton;
