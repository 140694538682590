import React, { useState } from "react";
import { Link } from "react-router-dom";
import qs from "qs";
import { Tooltip } from "reactstrap";
import PortfolioList from "./components/PortfolioList";
import { useDispatch, useSelector } from "react-redux";

import {
  getCredentialInvestments,
  resetCredentialInvestments,
  setHideEmptyAssets,
} from "store/credentials/actions";
import { hideEmptyAssetsSelector } from "store/credentials/selectors";
import { ReactComponent as Crown } from "assets/icons/general/crown.svg";
import { ReactComponent as Help } from "assets/icons/general/Help.svg";
import EmptyPortfolio from "../../../states/EmptyPortfolio";
import PortfolioModal from "../../../../investments/modals/manageAllocation/PortfolioModal";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import useRequestCancellation from "hooks/useRequestCancellation";
import { Checkbox } from "shared/ui";
import PortfolioPreloaders from "./components/PortfolioPreloaders";
import { Button } from "shared/ui/buttons";

const PortfolioSection = ({
  id,
  rebalancingDifference,
  rebalance,
  onHandleRefresh,
  loadingPage,
  balanceHidden,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const dispatch = useDispatch();
  const subscription = useSelector(state => state.me.data.subscription);
  const portfolio = useSelector(state => state.credentials.investments.data);
  const loading = useSelector(state => state.credentials.investments.loading);
  const hideEmptyAssets = useSelector(hideEmptyAssetsSelector);
  const { handlePaginate, handleFilter } = useFiltersWithRequest(
    portfolio?.request,
    getCredentialInvestments,
    id,
  );
  const { useAbortableEffect } = useRequestCancellation();

  const handleChange = () => {
    const filter = !hideEmptyAssets
      ? { realPercent: { gte: 0 }, balance: { gte: 0 } }
      : { realPercent: { gte: {} }, balance: { gte: {} } };
    dispatch(setHideEmptyAssets(!hideEmptyAssets));
    handleFilter(filter);
  };

  useAbortableEffect(
    signal => {
      const filter = qs.stringify({
        filter: { realPercent: { gte: 0 }, balance: { gte: 0 } },
        sort: { by: "realPercent", order: "DESC" },
      });
      dispatch(getCredentialInvestments(id, `?${filter}`, signal));
      return () => {
        dispatch(resetCredentialInvestments());
        dispatch(setHideEmptyAssets(true));
      };
    },
    [dispatch, id],
  );

  const filteredEmptyPortfolio = portfolio?.items?.reduce(
    (acc, currentInvestment) => {
      return acc + +currentInvestment.balance.amount;
    },
    0,
  );

  const toggle = () => setTooltipOpen(!tooltipOpen);

  if (loading || loadingPage) return <PortfolioPreloaders />;

  return (
    <>
      <div className='d-flex flex-column flex-md-row justify-content-between mb-3 flex-wrap'>
        <div className='d-flex align-items-center flex-wrap flex-sm-nowrap'>
          <Checkbox
            name='hideEmpty'
            value={hideEmptyAssets}
            checked={hideEmptyAssets}
            onChange={handleChange}
            labelRight={
              <p className='mb-0 ml-1 font-sm text-white-75 text-nowrap'>
                Hide empty assets
              </p>
            }
          />
        </div>
        <div className='d-flex align-items-center mt-2 mt-md-0 text-nowrap'>
          {subscription?.type === "premium" && subscription?.active ? (
            <div>
              {!portfolio?.items?.length ||
              (portfolio?.items?.length &&
                !hideEmptyAssets &&
                !filteredEmptyPortfolio) ? null : (
                <Button color='blue' onClick={() => setModalIsOpen(true)}>
                  Manage allocation
                </Button>
              )}
            </div>
          ) : (
            <div className='d-flex align-items-center flex-wrap flex-sm-nowrap'>
              <Link to='/billing' className='link-gold font-sm'>
                <Crown alt='' className='mr-1' />
                Upgrade to premium
              </Link>
              <div className='d-flex align-items-center'>
                <p className='mb-0 ml-2 mr-2 text-white-75 font-sm'>
                  to manage allocation
                </p>
                <Help
                  alt=''
                  id='premium'
                  style={{ width: "14px", cursor: "pointer" }}
                />
                <Tooltip
                  placement='top'
                  isOpen={tooltipOpen}
                  target='premium'
                  toggle={toggle}
                >
                  Please upgrade to premium to activate the automatic
                  rebalancing mechanism.
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      </div>
      {!portfolio?.items?.length ||
      (portfolio?.items?.length &&
        hideEmptyAssets &&
        !filteredEmptyPortfolio) ? (
        <EmptyPortfolio />
      ) : (
        <PortfolioList
          portfolio={portfolio?.items}
          page={portfolio?.page}
          total={portfolio?.total}
          rebalance={rebalance}
          handlePaginate={handlePaginate}
          balanceHidden={balanceHidden}
        />
      )}
      {modalIsOpen && (
        <PortfolioModal
          modalIsOpen={modalIsOpen}
          onSetModalIsOpen={setModalIsOpen}
          portfolio={portfolio?.items}
          credentialId={id}
          rebalancingDifference={rebalancingDifference}
          rebalance={rebalance}
          onHandleRefresh={onHandleRefresh}
        />
      )}
    </>
  );
};

export default PortfolioSection;
