import React from "react";

import { ReactComponent as AddIcon } from "assets/icons/add-icon-border-none.svg";
import { StyledAddButton } from "./index.styles";

const AddButton = ({
  array,
  maxLength,
  buttonLabel,
  isDisabled,
  addHover = true,
  className,
  classNameButtonLabel,
  toogleEdit,
  bgButton,
}) => {
  if (array?.length < maxLength)
    return (
      <StyledAddButton
        type='button'
        bgButton={bgButton}
        addHover={addHover}
        disabled={isDisabled}
        className={`${className} transparent-outline bg-none font-sm font-weight-500 text-white gray-outline`}
        onClick={toogleEdit}
      >
        <AddIcon />
        <span className={classNameButtonLabel}>{buttonLabel}</span>
      </StyledAddButton>
    );
};

export default AddButton;
