import styled from "styled-components";

const ResultsDropdownItem = styled.div`
  cursor: pointer;
  height: 35px;
  border-radius: 6px;

  &:hover {
    background: rgba(29, 30, 42, 0.1);
    .hoverStatus {
      border: 3px solid rgba(29, 30, 42, 0.1);
    }
    .hoverDeals {
      border: 2px solid rgba(29, 30, 42, 0.1) !important;
    }
  }

  & .status {
    font-weight: 600;
  }

  & .text-cancelled {
    color: #1d1e2a;
    opacity: 35%;
  }

  & .quote-asset {
    color: rgba(29, 30, 42, 0.35) !important;
  }

  & .text-title {
    max-width: 150px;
  }
`;

export default ResultsDropdownItem;
