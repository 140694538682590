export const COMPLETED_SIGNALS_HEAD = [
  { name: "market", label: "Market", isSortable: true },
  { name: "buyFrom", label: "Open from", isSortable: true },
  { name: "buyTo", label: "Open to", isSortable: true },
  { name: "targets", label: "Targets", isSortable: true },
  { name: "stopLoss", label: "Stop loss", isSortable: true },
  { name: "profitPercent", label: "Profit", isSortable: true },
  { name: "createdAt", label: "Published", isSortable: true },
  { name: "completedAt", label: "Completed", isSortable: true },
];

export const ACTIVE_SIGNALS_HEAD = [
  { name: "market", label: "Market", isSortable: true },
  { name: "buyFrom", label: "Open from", isSortable: true },
  { name: "buyTo", label: "Open to", isSortable: true },
  { name: "targets", label: "Targets", isSortable: true },
  { name: "stopLoss", label: "Stop loss", isSortable: true },
  { name: "createdAt", label: "Published", isSortable: true },
];

export const SIGNAL_DETAIL_HEAD = [
  { name: "id", label: "Target", isSortable: false },
  { name: "rate", label: "Price", isSortable: false },
  { name: "percent", label: "Percent", isSortable: false },
  { name: "reachedAt", label: "Completed at", isSortable: false },
  { name: "status", label: "Status", isSortable: false },
];
