import React from "react";
import styled from "styled-components";
import { Range } from "rc-slider";

import _ from "lodash";
import { renderSteps } from "./utils";

const PivotPointsRange = ({
  minMaxRange,
  checkedSupport,
  checkedResistance,
  values,
  handlePPRanges,
  isEditable = true,
  displayMark,
  currentLevel,
  currentSignal,
}) => {
  const initialDisplayRange =
    values?.pivotPoint?.supportBottom === 0 ||
    values?.pivotPoint?.supportTop === 0 ||
    values?.pivotPoint?.resistanceBottom === 0 ||
    values?.pivotPoint?.resistanceTop === 0 ||
    !values?.pivotPoint?.hasSupport ||
    !values?.pivotPoint?.hasResistance
      ? "none"
      : "block";

  const handleOnChange = percent => {
    if (percent[1] > 0) return null;
    if (percent[2] < 0) return null;
    if (values?.pivotPoint?.hasSupport && percent[0] === percent[1])
      return null;
    if (values?.pivotPoint?.hasResistance && percent[2] === percent[3])
      return null;
    handlePPRanges(percent);
  };

  const displaySupportView = !checkedSupport || !isEditable ? "none" : "";
  const displayResistanceView = !checkedResistance || !isEditable ? "none" : "";

  return (
    <>
      <StyledRange
        min={-minMaxRange}
        max={minMaxRange}
        step={1}
        onChange={handleOnChange}
        value={[
          values?.pivotPoint?.supportBottom,
          values?.pivotPoint?.supportTop,
          values?.pivotPoint?.resistanceBottom,
          values?.pivotPoint?.resistanceTop,
        ]}
        count={3}
        railStyle={{ backgroundColor: "#363A54", height: 8 }}
        trackStyle={[
          {
            background: "#0AAF95",
            height: 8,
            display: !checkedSupport ? "none" : "",
          },
          { background: "#363A54" },
          {
            background: "#ED5F5F",
            height: 8,
            display: !checkedResistance ? "none" : "",
          },
        ]}
        handleStyle={[
          {
            display: displaySupportView,
            border: "none",
            boxShadow: "none",
            backgroundColor: "#0AAF95",
            opacity: 1,
            width: 22,
            height: 22,
            bottom: "-50%",
          },
          {
            display: displaySupportView,
            border: "none",
            boxShadow: "none",
            backgroundColor: "#0AAF95",
            opacity: 1,
            width: 22,
            height: 22,
            bottom: "-50%",
          },
          {
            display: displayResistanceView,
            border: "none",
            boxShadow: "none",
            background:
              values?.pivotPoint?.hasResistance &&
              values?.pivotPoint?.hasSupport &&
              !values?.pivotPoint?.resistanceBottom &&
              !values?.pivotPoint?.supportTop
                ? "linear-gradient(90deg, transparent 50%, #C55353 50%)"
                : "#C55353",
            opacity: 1,
            width: 22,
            height: 22,
            bottom: "-50%",
          },
          {
            display: displayResistanceView,
            border: "none",
            boxShadow: "none",
            background:
              values?.pivotPoint?.hasResistance &&
              values?.pivotPoint?.hasSupport &&
              !values?.pivotPoint?.resistanceTop &&
              !values?.pivotPoint?.resistanceBottom &&
              !values?.pivotPoint?.supportTop
                ? "linear-gradient(90deg, transparent 50%, #C55353 50%)"
                : "#C55353",
            opacity: 1,
            width: 22,
            height: 22,
            bottom: "-50%",
          },
        ]}
        marks={renderSteps(
          minMaxRange,
          displayMark,
          initialDisplayRange,
          currentSignal,
          currentLevel,
        )}
        dotStyle={{
          border: "none",
          borderRadius: 2,
          height: "10px",
          margin: "0 0 -3px -2px",
          width: "4px",
          background: "#2E3148",
        }}
      />
      {currentLevel ? (
        <div className='d-flex align-items-center justify-content-between mt-2'>
          {_.times(minMaxRange, e => (
            <p className='mb-0 text-white-35 font-sm font-weight-300' key={e}>
              {minMaxRange - e}
            </p>
          ))}
          <p className='mb-0 text-white font-sm font-weight-500'>PP</p>
          {_.times(minMaxRange, e => (
            <p className='mb-0 text-white-35 font-sm font-weight-300' key={e}>
              {e + 1}
            </p>
          ))}
        </div>
      ) : (
        <div className='d-flex align-items-center justify-content-center mt-2'>
          <p className='mb-0 text-white font-sm font-weight-500'>PP</p>
        </div>
      )}
    </>
  );
};

const StyledRange = styled(Range)`
  span.rc-slider-dot:first-child,
  span.rc-slider-dot:last-child {
    display: none !important;
  }

  .rc-slider-dot {
    border-radius: 0 !important;
    height: 8px !important;
    margin: 0 0 -2px -2px !important;
  }
`;

export default PivotPointsRange;
