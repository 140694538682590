import React from "react";
import styled from "styled-components";
import { ModalBody, ModalHeader, Modal } from "reactstrap";
import EditCredentialForm from "../forms/EditCredentialForm";

const EditCredentialModal = ({ data, onSetModalIsOpen, modalIsOpen }) => {
  return (
    <EditCredentialModal.Wrapper>
      <Modal
        centered
        isOpen={modalIsOpen}
        toggle={() => onSetModalIsOpen(!modalIsOpen)}
        modalClassName='modal-black'
      >
        <StyledModalHeader className='modal-header mb-2'>
          <h3 className='modal-title px-2 pb-0 text-white font-weight-500'>
            Edit credentials settings
          </h3>
        </StyledModalHeader>
        <ModalBody className='px-4 pb-2'>
          <EditCredentialForm
            credential={data}
            onSetModalIsOpen={onSetModalIsOpen}
          />
        </ModalBody>
      </Modal>
    </EditCredentialModal.Wrapper>
  );
};

EditCredentialModal.Wrapper = styled.div`
  width: 455px !important;
`;

const StyledModalHeader = styled(ModalHeader)`
  h3 {
    font-size: 24px;
  }
`;

export default EditCredentialModal;
