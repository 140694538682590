import React from "react";

import {
  ORDERS_TABLE_HEAD,
  TRADE_HISTORY_TABLE_HEAD,
} from "models/credentials/enum/tables";
import {
  CustomScrollbars,
  EmptyState,
  PaginationPanel,
  SortTableHead,
  TablePreloader,
} from "shared/ui";
import { StyledTable } from "shared/styled/StyledTable";
import MarketTradeRow from "./MarketTradeRow";
import MarketOrderRow from "./MarketOrderRow";

const MarketTables = ({
  tableView,
  orders,
  trades,
  loadingTrades,
  loadingOrders,
  handleSort,
  handlePagination,
  currentCredId,
}) => {
  if (loadingTrades || loadingOrders) {
    return (
      <div className='overflow-hidden'>
        <TablePreloader
          thHeight={12}
          thWidth={[44, 48, 68, 37, 37, 44, 54, 52]}
          tdWidth={[32, 72, 71, 134, 75, 134, 134, 95]}
          tdHeight={12}
          rowTimes={10}
        />
      </div>
    );
  }

  if (!currentCredId) {
    return (
      <EmptyState
        title='No credentials selected'
        desc='Please select a credential to view corresponding data in the table below.'
      />
    );
  }

  if (
    (tableView === "orders" && !orders?.items?.length) ||
    (tableView === "trades" && !trades?.items?.length)
  ) {
    return (
      <EmptyState
        title='No results for your search'
        desc='Please adjust your filters, we have not found anything by your request.'
      />
    );
  }

  return (
    <>
      <CustomScrollbars
        autoHideTimeout={500}
        autoHideDuration={500}
        autoHeight
        autoHeightMax={Number.MAX_VALUE}
      >
        <StyledTable className='mb-0' paddingsThead='1rem'>
          {tableView === "orders" ? (
            <>
              <SortTableHead
                handleSorting={handleSort}
                sort={orders?.request?.sort}
                theadItems={ORDERS_TABLE_HEAD}
              />
              <tbody className='font-sm'>
                {orders?.items?.map(item => (
                  <MarketOrderRow item={item} key={item.id} />
                ))}
              </tbody>
            </>
          ) : (
            <>
              <SortTableHead
                handleSorting={handleSort}
                sort={trades?.request?.sort}
                theadItems={TRADE_HISTORY_TABLE_HEAD}
              />
              <tbody className='font-sm'>
                {trades?.items?.map(item => (
                  <MarketTradeRow item={item} key={item.id} />
                ))}
              </tbody>
            </>
          )}
        </StyledTable>
      </CustomScrollbars>

      <div className='mt-3'>
        <PaginationPanel
          activePage={tableView === "orders" ? orders?.page : trades?.page}
          totalItemsCount={
            tableView === "orders" ? orders?.total : trades?.total
          }
          onChange={page => handlePagination(page)}
        />
      </div>
    </>
  );
};

export default MarketTables;
