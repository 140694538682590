import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";

import ProfileSettingsForm from "./forms/ProfileSettingsForm";
import { editUser } from "store/user/actions";
import {
  getCurrencies,
  getLanguages,
  getСountries,
} from "store/labels/actions";
import Error from "shared/ui/state/Error";
import ProfileAvatar from "views/user/compounds/ProfileAvatar";
import GoogleAccount from "views/user/compounds/GoogleAccount";
import TelegramWidget from "views/user/compounds/TelegramWidget";
import BetaFeatures from "views/user/compounds/BetaFeatures";
import ChangeEmail from "views/user/compounds/ChangeEmail";
import ChangePassword from "views/user/compounds/ChangePassword";
import useRequestCancellation from "hooks/useRequestCancellation";

const Account = () => {
  const user = useSelector(state => state.me.data);
  const loading = useSelector(state => state.me.loading);
  const error = useSelector(state => state.me.error);
  const currencies = useSelector(state => state.labels.currencies);
  const languages = useSelector(state => state.labels.languages);
  const countries = useSelector(state => state.labels.countries);
  const { useAbortableEffect } = useRequestCancellation();

  const dispatch = useDispatch();

  useAbortableEffect(signal => dispatch(getCurrencies(signal)), [dispatch]);
  useAbortableEffect(signal => dispatch(getLanguages(signal)), [dispatch]);
  useAbortableEffect(signal => dispatch(getСountries(signal)), [dispatch]);

  if (error) return <Error error={error} />;

  return (
    <div className='d-flex flex-column'>
      <Col className='px-0'>
        <Row>
          <Col xs='12' md='3'>
            <ProfileAvatar avatars={user.avatars} loading={loading} />
            <TelegramWidget loading={loading} telegram={user.telegram} />
          </Col>
          <Col xs='12' md='5'>
            <Card className='p-3'>
              <CardBody className='p-2'>
                <ProfileSettingsForm
                  user={user}
                  editUser={editUser}
                  currencies={currencies}
                  languages={languages}
                  countries={countries}
                  loading={loading}
                />
              </CardBody>
            </Card>
            <ChangePassword loading={loading} error={error} />
          </Col>
          <Col xs='12' md='4' className='d-flex flex-column'>
            <GoogleAccount google={user.googleProfile} loading={loading} />
            <ChangeEmail
              loading={loading}
              error={error}
              email={user.username}
              confirmed={user.confirmed}
            />
            <BetaFeatures
              loading={loading}
              isBetaEnabled={user.isBetaEnabled}
            />
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default Account;
