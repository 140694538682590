import React from "react";

import { TradeRequestBody } from "shared/styled/StyledTradeRequestComponents";
import { OpenOrder, ParameterDisplay } from "shared/ui";

const TradeRequestCardBody = ({ data, handleOpenModal, goal }) => {
  return (
    <TradeRequestBody
      className='p-0'
      onClick={() => handleOpenModal({ ...data, goal })}
    >
      <div className='w-100 d-flex align-items-center flex-wrap flex-lg-nowrap justify-content-between py-3 px-4'>
        <ParameterDisplay
          title='Executed amount'
          amount={data?.executedAmount?.amount}
          coin={data?.executedAmount?.asset}
          className='col-7 col-md-4 col-lg-2 mr-lg-2 p-0'
        />
        <ParameterDisplay
          title='Executed quantity'
          amount={data?.executedQuantity?.amount}
          coin={data?.executedQuantity?.asset}
          className='col-5 col-md-4 col-lg-2 p-0'
        />

        <ParameterDisplay
          title='Fee'
          amount={data?.fee?.amount}
          coin={data?.fee?.asset}
          className='col-7 mt-2 col-md-4 mt-md-0 col-lg-3 p-0'
        />

        <ParameterDisplay
          title='Average price'
          amount={data?.averageRate}
          className='col-5 mt-2 col-md-4 col-lg-2 p-0'
        />

        <ParameterDisplay
          title='Trades'
          amount={data?.tradesCount}
          className='col-4 mt-2 col-md-2 col-lg-1 p-0'
        />

        <ParameterDisplay
          title='Orders'
          amount={data?.ordersCount}
          className='col-4 mt-2 col-md-2 col-lg-1 p-0'
        />

        <ParameterDisplay
          title='Duration'
          durations={data?.lifetime}
          className='col-4 mt-2 col-md-2 col-lg-1 p-0'
        />
      </div>
      {data?.openOrderRequest ? (
        <OpenOrder openOrderRequest={data?.openOrderRequest} />
      ) : null}
    </TradeRequestBody>
  );
};

export default TradeRequestCardBody;
