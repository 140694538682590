import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment";

import { selectStatisticsBalance } from "store/credentials/selectors";
import { ReactComponent as Balance } from "assets/icons/general/orders.svg";
import { ReactComponent as CredentialChartPreload } from "assets/icons/general/credential-chart-preload.svg";
import { fetchStatisticsBalance } from "store/credentials/actions";
import { balanceHistory } from "charts";
import { CHART_GROUP_BUTTONS } from "charts/enum";
import useRequestCancellation from "hooks/useRequestCancellation";
import { ButtonsGroup } from "shared/ui";
import StatisticsBalancePreloader from "./ui/StatisticsBalancePreloader";
import {
  StyledStatisticsCardHeader,
  StyledStatisticsChartCard,
} from "./index.styles";

Chart.register(...registerables);

const StatisticsBalance = ({ id, currency, loadingPage }) => {
  const [period, setPeriod] = useState("day");
  const dispatch = useDispatch();
  const { useAbortableEffect } = useRequestCancellation();
  const { data, loading, error } = useSelector(selectStatisticsBalance);
  const loadingBalances = useSelector(
    state => state.credentials.balances.loading,
  );

  useAbortableEffect(
    signal => {
      if (!loadingBalances) dispatch(fetchStatisticsBalance(id, "day", signal));
    },
    [id, dispatch],
  );

  if (loading || loadingPage) return <StatisticsBalancePreloader />;

  if (error) {
    return (
      <Card>
        <CardBody>
          <div
            className='chart-area d-flex flex-column justify-content-center text-center w-100'
            style={{ height: "325px" }}
          >
            <p className='text-danger'>{error}</p>
          </div>
        </CardBody>
      </Card>
    );
  }

  const renderChart = () => {
    if (loading || loadingBalances)
      return (
        <div className='p-4'>
          <CredentialChartPreload className='h-100' />
        </div>
      );
    if (data && Object.keys(data).length)
      return (
        <div className='chart-area'>
          <Line
            data={balanceHistory(data, period, currency).data}
            options={balanceHistory(data, period, currency).options}
          />
        </div>
      );
    if (!(data && Object.keys(data).length) && !loading) {
      return (
        <div className='d-flex flex-column justify-content-center text-center h-75'>
          <h3>There are no data to show</h3>
        </div>
      );
    }
  };

  return (
    <StyledStatisticsChartCard
      className={classNames("card-chart", {
        empty: !(data && Object.keys(data).length) && !loading,
      })}
    >
      <StyledStatisticsCardHeader className='p-4'>
        <div className='d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between'>
          <div className='d-flex align-items-center mb-2 mb-md-0'>
            <Balance />
            <h4 className='ml-2 mb-0 text-white'>Balance history</h4>
          </div>
          <ButtonsGroup
            group={CHART_GROUP_BUTTONS}
            current={period}
            setCurrent={setPeriod}
            callFunc={fetchStatisticsBalance}
            params={id}
            disable={loading}
          />
        </div>
      </StyledStatisticsCardHeader>
      <CardBody>{renderChart()}</CardBody>
    </StyledStatisticsChartCard>
  );
};

export default StatisticsBalance;
