import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import classNames from "classnames";
import styled from "styled-components";

import { investedPercentColors } from "helpers/investedPercentColors";
import { formatterToNull, formatValue } from "helpers/valuesFormatters";
import { ReactComponent as Plus } from "assets/icons/credential/plus.svg";
import { Icon } from "shared/ui";
import { LookupCoins } from "shared/utils";
import PlannedRange from "shared/ui/AllocationRebalancing/PlannedRange";
import { handleHideBalance } from "shared/helpers/handleHideBalance";

const PortfolioTableRow = ({
  investment,
  index,
  autoRebalancing,
  bots,
  isHover,
  balanceHidden,
}) => {
  const history = useHistory();
  const handleRedirect = (e, investment) => {
    e.stopPropagation();
    e.preventDefault();
    history.push({
      pathname: "/bots/create",
      state: {
        investment: investment,
      },
    });
  };
  return (
    <Link to={`/investments/${investment.id}`} style={{ display: "contents" }}>
      <PortfolioTableRow.TableRow
        botsCount={investment.botsCount}
        isHover={isHover}
      >
        <td className='text-white d-flex ml-2 align-items-center text-nowrap'>
          <Icon code={investment.asset} type='cryptocurrency' size='thumb' />
          <p className='mb-0 text-white font-weight-500 ml-2 mr-1'>
            {investment.asset}
          </p>
          <p
            className='mb-0 font-weight-300 text-truncate'
            style={{ opacity: ".5" }}
          >
            <LookupCoins coin={investment.asset} />
          </p>
        </td>
        <td>
          <div style={{ width: "185px" }}>
            <PlannedRange
              index={index}
              plannedPercent={investment?.plannedPercent}
              realPercent={investment?.realPercent}
              enabled={autoRebalancing}
            />
          </div>
        </td>
        <td>
          <div className='d-flex text-nowrap px-3 px-md-0'>
            <p className='mb-0 text-white font-weight-500'>
              {handleHideBalance(balanceHidden, investment.available.total)}
            </p>
            <p className='mb-0 text-white-35 font-weight-300 ml-1'>
              {investment.available.currency}
            </p>
          </div>
        </td>
        <td>
          <div className='d-flex text-nowrap px-3 px-md-0'>
            <p className='mb-0 text-white font-weight-500'>
              {handleHideBalance(balanceHidden, investment.invested.amount)}
            </p>
            <p className='mb-0 text-white-35 font-weight-300 ml-1'>
              {investment.invested.currency}
            </p>
            {investment.invested.percent > 0 && (
              <p
                className={`${investedPercentColors(investment.invested.percent)} mb-0 ml-1 text-nowrap`}
              >
                {`${formatterToNull(investment.invested.percent)} %`}
              </p>
            )}
          </div>
        </td>
        <td>
          <div className='d-flex text-nowrap px-3 px-md-0'>
            <p className='mb-0 text-white font-weight-500'>
              {handleHideBalance(balanceHidden, investment.estimated.total)}
            </p>
            <p className='mb-0 text-white-35 ml-1 font-weight-300'>
              {investment.estimated.currency}
            </p>
          </div>
        </td>
        <td>
          <p
            className={classNames(
              "text-nowrap my-auto font-weight-500 px-3 px-md-0",
              {
                "text-success": investment.dailyRevenuePercent > 0,
                "text-danger": investment.dailyRevenuePercent < 0,
                "text-white-35": !investment.dailyRevenuePercent,
              },
            )}
          >
            {`${formatValue(investment.dailyRevenuePercent) || "0.00"} %`}
          </p>
        </td>
        {bots && (
          <td className='d-flex justify-content-center'>
            {investment.botsCount ? (
              <p className='mb-0 font-weight-500 text-white px-3 px-md-0'>
                {investment.botsCount}
              </p>
            ) : (
              <PortfolioTableRow.Add className='px-3 px-md-0'>
                <Button
                  color='info'
                  style={{
                    padding: "8.5px 13.5px",
                    height: "28px",
                    width: "38px",
                  }}
                  onClick={e => handleRedirect(e, investment)}
                  className='d-flex align-items-center justify-content-center'
                >
                  <Plus />
                </Button>
              </PortfolioTableRow.Add>
            )}
          </td>
        )}
      </PortfolioTableRow.TableRow>
    </Link>
  );
};

PortfolioTableRow.TableRow = styled.tr`
  cursor: ${({ isHover }) => (!isHover ? "pointer" : "default")};
  background: var(--dark-slate);
  tr {
    padding: 0 4px !important;
  }
  td {
    padding: 13px 4px !important;
    vertical-align: middle !important;
  }
  &:hover {
    background: ${({ isHover }) => (isHover !== false ? "#2e3148" : "none")};
  }
`;

PortfolioTableRow.Add = styled.div`
  &:hover {
    opacity: 0.5;
  }
`;

export default PortfolioTableRow;
