import React from "react";
import styled from "styled-components";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useSelector } from "react-redux";

import { MyCredentialsBalancesPreloader } from "./MyCredentialsBalancesPreloader/MyCredentialsBalancesPreloader";
import { CenterColumn } from "./CenterColumn";
import { SideColumn } from "./SideColumn";
import { selectBalance } from "store/credentials/selectors";
import { formatterToNull } from "helpers/valuesFormatters";

export const MyCredentialBalances = ({ botId, loading }) => {
  const { data, loading: loadingBalances } = useSelector(selectBalance);
  const [quoteData, baseData] = data?.items || [];

  if (!botId) return null;

  if (loading || loadingBalances) return <MyCredentialsBalancesPreloader />;
  if (data?.items?.length) {
    return (
      <StyledCard className='StyledCard'>
        <StyledCardHeader className='p-0 mb-3'>
          <p className='mb-0 pt-0 text-white font-weight-500 text-nowrap'>
            {" "}
            Balances{" "}
          </p>
        </StyledCardHeader>
        <StyledCardBody>
          <Flex className='w-100' gap='16px' content='space-between'>
            <SideColumn asset={baseData?.available?.currency} />
            <Flex gap='16px'>
              <CenterColumn
                header='On orders'
                main={formatterToNull(baseData?.onOrders?.amount)}
                footer={baseData?.available?.currency}
              />
              <CenterColumn
                header='Available'
                main={formatterToNull(baseData?.available?.amount)}
                footer={baseData?.available?.currency}
              />
            </Flex>
          </Flex>
          <Separator />
          <Flex className='w-100' gap='16px' content='space-between'>
            <Flex gap='16px' className='one flex-row-reverse flex-md-row'>
              <CenterColumn
                header={"Available"}
                main={formatterToNull(quoteData?.available?.amount)}
                footer={quoteData?.onOrders?.currency}
              />
              <CenterColumn
                header={"On orders"}
                main={formatterToNull(quoteData?.onOrders?.amount)}
                footer={quoteData?.onOrders?.currency}
              />
            </Flex>
            <SideColumn
              asset={quoteData?.onOrders?.currency}
              side='left'
              className='two'
            />
          </Flex>
        </StyledCardBody>
      </StyledCard>
    );
  }
};

export const StyledCard = styled(Card)`
  padding: 32px 24px;
  margin-bottom: 0 !important;

  &.StyledCard {
    border-radius: 0 !important;
  }
`;

export const StyledCardHeader = styled(CardHeader)`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    justify-content: start;
  }
`;

export const StyledCardBody = styled(CardBody)`
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const Separator = styled.div`
  width: 1px;
  height: 40px;
  margin: 0 32px;
  background: rgba(255, 255, 255, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    height: 1px;
    margin: 16px 0;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ items }) => items};
  gap: ${({ gap }) => gap};
  justify-content: ${({ content }) => content};

  @media (max-width: 768px) {
    &.one {
      order: 1;
    }

    &.two {
      order: 2;
    }
  }
`;
