import React, { useState } from "react";
import { Button, Card } from "reactstrap";
import { useHistory } from "react-router-dom";

import { ReactComponent as ListingError } from "assets/icons/general/error-bg.svg";
import Loading from "../Loading";

const ListingErrorState = ({ online }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const reloadPage = () => {
    setLoading(true);
    if (online) {
      history.push(history.location.pathname);
    }
    if (!online) {
      const timeOut = setTimeout(() => {
        setLoading(false);
      }, 5000);
      return () => clearTimeout(timeOut);
    }
  };

  return (
    <Card>
      <div
        style={{ margin: "0 auto", textAlign: "center", padding: "200px 0" }}
      >
        <ListingError />
        <p
          className='mt-3 text-white font-weight-500'
          style={{ fontSize: "20px" }}
        >
          Oops, seems like network error...
        </p>
        <p className='font-weight-300' style={{ fontSize: "16px" }}>
          Please repeat your request or come back later and try again
        </p>
        {loading ? (
          <div className='mt-4 pt-2'>
            <Loading />
          </div>
        ) : (
          <Button className='mt-4' color='info' onClick={() => reloadPage()}>
            {online === false ? "Refresh" : "Try again"}
          </Button>
        )}
      </div>
    </Card>
  );
};

export default ListingErrorState;
