import React from "react";
import styled from "styled-components";
import { CardFooter } from "reactstrap";

import { formatValue, setColorClass } from "helpers/valuesFormatters";
import { ReactComponent as ChartUp } from "assets/icons/trending-lightning-up.svg";
import { ReactComponent as ChartDown } from "assets/icons/general/chartDown.svg";

const VolumesWidgetFooter = ({ amount, status, currency, percent }) => {
  return (
    <>
      <Separator className='m-0 p-0' />
      <CardFooter className='p-4'>
        <Return className='py-2 px-2 px-lg-4' amount={amount} status={status}>
          <span className='d-block'>
            {amount >= 0 ? <ChartUp /> : <ChartDown />}
          </span>
          <div className='d-flex flex-column ml-2 ml-lg-4'>
            <p className='mb-0 text-white-35 font-weight-300 font-sm'>
              {status === "running" ? "Estimated return" : "Return"}
            </p>
            <div className='d-flex align-items-center text-nowrap'>
              <p
                className={`mb-0 font-weight-500
                  ${status === "completed" ? setColorClass(amount, "text-white-35") : "text-white"}`}
              >
                {formatValue(amount)}
              </p>
              <p className='mb-0 text-white-35 font-weight-300 ml-2'>
                {currency}
              </p>
            </div>
            <p className={`mb-0 ${setColorClass(percent, "text-white-35")}`}>
              {`${formatValue(percent)} %`}
            </p>
          </div>
        </Return>
      </CardFooter>
    </>
  );
};

const Separator = styled.hr`
  border-top: 1px dashed rgba(255, 255, 255, 0.1);
`;

const Return = styled.div`
  display: flex;
  align-items: center;
  background: ${props =>
    props.amount >= 0 ? "rgba(95, 211, 188, 0.05)" : "rgba(237, 95, 95, 0.05)"};
  border-radius: 6px;

  svg {
    path {
      stroke: ${props => {
        if (props.amount > 0) return "#3FCBB0";
        if (props.amount < 0) return "#ED5F5F";
        if (!props.amount) return "rgba(255, 255, 255, 0.35)";
      }};
      opacity: 1;
    }
  }
`;

export default VolumesWidgetFooter;
