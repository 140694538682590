import styled from "styled-components";
import { Card } from "reactstrap";
import React from "react";

import Button from "shared/ui/buttons/Button";

export const EmptyData = ({
  title,
  description,
  buttonText,
  onHandleClick,
}) => {
  return (
    <EmptyData.Card>
      <EmptyData.Title>{title}</EmptyData.Title>
      <EmptyData.Description>{description}</EmptyData.Description>
      {buttonText && (
        <Button className='mt-4' color='blue' onClick={onHandleClick}>
          {buttonText}
        </Button>
      )}
    </EmptyData.Card>
  );
};

EmptyData.Card = styled(Card)`
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px !important;
  margin-bottom: 24px !important;
  text-align: center;
  padding: 24px;
`;

EmptyData.Title = styled.p`
  margin: 0 0 8px 0;
  color: #ffffff !important;
  font-weight: 500;
  font-size: 18px;
`;

EmptyData.Description = styled.p`
  margin: 0;
  color: rgba(255, 255, 255, 0.75) !important;
  font-weight: 400;
  font-size: 14px;
  white-space: pre-line;
`;
