import styled from "styled-components";

export const StyledStatusLabel = styled.span`
  width: fit-content;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 6px;
  padding: 3.5px 10px;
  color: ${props => props.color || "var(--info)"} !important;
  text-transform: capitalize;
  white-space: nowrap;
`;
