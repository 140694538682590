import React from "react";
import styled from "styled-components";

const BurgerMenuButton = ({ expanded, ...props }) => {
  return (
    <HamburgerMenu
      className='mr-4 btn btn-reset'
      expanded={expanded}
      {...props}
    >
      <span
        className={`d-flex align-items-center cursor-pointer menu__btn ${expanded ? "open" : ""}`}
      >
        <span />
      </span>
    </HamburgerMenu>
  );
};

const HamburgerMenu = styled.button`
  display: none !important;

  @media (max-width: 1199.98px) {
    display: block !important;
  }

  .menu__btn {
    height: 24px;
    width: 24px;

    & > span,
    & > span::before,
    & > span::after {
      display: block;
      position: absolute;
      width: 24px;
      height: 1.5px;
      background-color: #ffffff;
      transition-duration: 0.25s;
    }

    & > span::before {
      content: "";
      top: -0.5rem;
    }

    & > span::after {
      content: "";
      top: 0.5rem;
    }

    &.open > span {
      transform: rotate(45deg);
    }

    &.open > span::before {
      top: 0;
      transform: rotate(0);
    }

    &.open > span::after {
      top: 0;
      transform: rotate(90deg);
    }
  }
`;

export default BurgerMenuButton;
