import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import FormDescription from "./compounds/FormDescription";
import illustration from "assets/icons/general/illustration-3.svg";
import { Button } from "shared/ui/buttons";

const Success = () => {
  const history = useHistory();

  return (
    <div className='content h-100 d-flex flex-column justify-content-center'>
      <FormDescription title='Congrats! You have successfully reset your password' />
      <StyledImg src={illustration} alt='create bot' />
      <Button
        className='w-100'
        type='submit'
        onClick={() => history.push("/login")}
        color='blue'
      >
        Go to Sign in
      </Button>
    </div>
  );
};

const StyledImg = styled.img`
  margin: 3rem 0 4rem;
`;

export default Success;
