import React, { useEffect } from "react";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import classNames from "classnames";

import HeaderPreLoader from "shared/ui/HeaderPreLoader";
import BotHeader from "../compounds/BotDetailsHeader";
import EditBotSettings from "./EditBotSettings";
import { setBotPageBreadcrumbs } from "models/enum/breadcrumbs";
import Breadcrumbs from "shared/ui/Breadcrumbs";
import EditBotMarkets from "./EditBotMarkets";
import useEditBot from "shared/entities/bot/model/useEditBot";
import { EditBotProvider } from "context/bot/EditBotContext";

const EditBot = ({ match }) => {
  const { botData, loading, wizardMarkets, setIsSubmit, isSubmit } = useEditBot(
    match.params.id,
  );
  useEffect(() => {
    window.analytics.page("/bots/:id/configure");
  }, []);

  return (
    <div className={classNames({ "opacity-5": isSubmit })}>
      <Breadcrumbs
        className='d-none d-md-block'
        items={[
          ...setBotPageBreadcrumbs(
            botData?.investment?.credentials?.exchange,
            botData?.investment?.credentials?.id,
            botData?.investment?.id,
            botData?.investment?.asset,
            botData?.name,
            match.params.id,
            true,
          ),
          { label: "Configure", path: "", isClickable: false },
        ]}
        loading={loading && !isSubmit}
      />
      <Row>
        <Col xs='12'>
          {loading && !isSubmit ? (
            <HeaderPreLoader />
          ) : (
            <BotHeader isEdit data={botData} id={match.params.id} />
          )}
        </Col>
      </Row>
      <EditBot.Wrapper className='mb-3'>
        <EditBotMarkets
          credentialId={botData?.investment?.credentials?.id}
          loadingBotData={loading}
          period={wizardMarkets?.period}
          isEdit
        />
        <EditBotProvider
          data={botData}
          wizardMarkets={wizardMarkets}
          loading={loading}
        >
          <EditBotSettings isSubmit={isSubmit} setIsSubmit={setIsSubmit} />
        </EditBotProvider>
      </EditBot.Wrapper>
    </div>
  );
};

EditBot.Wrapper = styled.div`
  background: var(--dark-slate);
  border-radius: 16px;
  overflow: hidden;
`;

export default EditBot;
