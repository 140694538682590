import React from "react";
import { CardBody, Table, Card } from "reactstrap";
import { times } from "lodash";

import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import { ListHeader, Row } from "./StyledFeatures";
import styled from "styled-components";

const TablePreloader = () => {
  return (
    <TablePreloader.Card>
      <CardBody className='p-0'>
        <Table>
          <ListHeader>
            <div className='d-flex justify-content-center'>
              <div className='d-flex flex-column justify-content-center'>
                <div className='d-flex mb-3'>
                  <SkeletonPreloader
                    width={48}
                    height={48}
                    className='mr-2'
                    circle
                  />
                  <SkeletonPreloader
                    width={139}
                    height={16}
                    className='my-auto'
                  />
                </div>
                <div className='d-flex flex-column'>
                  <SkeletonPreloader width={164} height={7} className='mb-2' />
                  <SkeletonPreloader width={164} height={7} />
                </div>
              </div>
            </div>
            {times(3, () => (
              <div className='d-none d-md-flex justify-content-center'>
                <div className='d-flex flex-column align-items-center'>
                  <SkeletonPreloader
                    width={106}
                    height={16}
                    style={{ marginBottom: "30px" }}
                  />
                  <SkeletonPreloader width={66} height={28} className='mb-2' />
                  <SkeletonPreloader width={43} height={9} className='mb-5' />
                  <SkeletonPreloader
                    width={110}
                    height={40}
                    className='sq-preload'
                  />
                </div>
              </div>
            ))}
          </ListHeader>
          <tbody>
            {times(9, e => (
              <Row key={e}>
                <div className='text-center py-3'>
                  <SkeletonPreloader width={134} height={9} />
                </div>
                <div className='text-center py-3 d-none d-md-flex'>
                  <SkeletonPreloader width={59} height={9} />
                </div>
                <div className='text-center py-3 d-none d-md-flex'>
                  <SkeletonPreloader width={59} height={9} />
                </div>
                <div className='text-center py-3 d-none d-md-flex'>
                  <SkeletonPreloader width={59} height={9} />
                </div>
              </Row>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </TablePreloader.Card>
  );
};

TablePreloader.Card = styled(Card)`
  &.card {
    border-radius: 16px !important;
  }
`;

export default TablePreloader;
