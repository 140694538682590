import React from "react";
import styled from "styled-components";
import classnames from "classnames";
import PropTypes from "prop-types";

const Label = ({
  disabled = true,
  color = "teal-green",
  children,
  outline,
  className,
  size,
  fontSize = "font-sm",
  ...props
}) => {
  const setColorWithoutOutline = bgc => !outline && color === bgc;

  return (
    <Label.Styled
      color={color}
      outline={outline}
      disabled={disabled}
      className={classnames(
        `${className} transparent-outline bg-none ${fontSize} font-weight-400 text-white`,
        {
          "btn-teal-green-opacity-10": setColorWithoutOutline("teal-green"),
          "btn-red": setColorWithoutOutline("danger"),
          big: size === "big",
          small: size === "small",
        },
      )}
      {...props}
    >
      {children}
    </Label.Styled>
  );
};

Label.Styled = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.25rem 0.75rem 1rem;
  line-height: 16px;
  border-radius: 6px;
  gap: 0.625rem;
  white-space: nowrap;

  &.big {
    width: 8.5rem !important;
    height: 2.5rem !important;

    & > :first-child {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &.small {
    width: 71px !important;
    height: 23px !important;
    padding: 0.25rem 0.625rem 0.25rem 0.5rem;
    gap: 6px;
  }
`;
Label.propTypes = {
  disabled: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.node,
  outline: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default Label;
