import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { ReactComponent as TrashIcon } from "assets/icons/trash-grey-icon.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/general/settings-indicator-icon.svg";
import {
  CANDLE_NEUTRAL_OPTIONS,
  CANDLE_OPTIONS,
} from "views/bots/models/options";
import { StyledCandleConditionWrap } from "./index.styles";
import {
  editCandleSetting,
  removeDraftCreatedCandle,
} from "store/bots/wizard/actions";
import { BadgeCandle } from "shared/ui/badges";
import { CandleSettingsBlock } from "features/blocks";
import { SettingsTrashIcons } from "shared/ui";

const CandleCondition = ({
  candle,
  setIsEditionCandle,
  isEditionCandle,
  patternsInitData = [],
  setIsOpenSettings,
}) => {
  const [isEdition, setIsEdition] = useState(false);
  const dispatch = useDispatch();

  const patternName =
    patternsInitData?.find(pattern => pattern.code === candle?.pattern)?.name ||
    null;

  const handleToogleEdit = () => {
    setIsEdition(prevState => !prevState);
    setIsEditionCandle(prevState => !prevState);
    setIsOpenSettings(prevState => !prevState);
  };

  const handleRemoveCandle = () => {
    dispatch(removeDraftCreatedCandle(candle?.id));
  };

  const handleEditCandleSetting = newCandle => {
    dispatch(editCandleSetting(newCandle));
  };

  if (!isEdition) {
    return (
      <StyledCandleConditionWrap className='d-flex flex-column flex-md-row align-items-md-center mb-2'>
        <div className='d-flex flex-column flex-md-row'>
          <div>
            <p className='text-white-75 font-sm mb-2'>Signal</p>
            <BadgeCandle
              candle={candle}
              candlesOptions={CANDLE_OPTIONS}
              param={candle?.signal}
              editCandleSetting={handleEditCandleSetting}
              paramName='signal'
            />
          </div>
          <SettingsTrashIcons
            classNameWrap='position-absolute right-5 d-flex flex-row align-items-center justify-content-end ml-auto mr-0 px-0 d-md-none'
            onSettingsClick={handleToogleEdit}
            onDeleteClick={handleRemoveCandle}
            isSettingsDisabled={isEditionCandle}
            isEdition={isEdition}
          />
          <div className='d-flex flex-column flex-md-row'>
            <div className='ml-md-4 pt-3 pt-md-0'>
              <p className='text-white-75 font-sm mb-1 '>Pattern</p>
              <p className='text-white font-weight-500 font-sm mb-0'>
                {patternName}
              </p>
            </div>
            <div className='ml-md-4 pt-3 pt-md-0'>
              <p className='text-white-75 font-sm mb-1'>High-Low percent</p>
              <p className='text-white-35 font-weight-400 font-sm text-nowrap mb-0'>
                {candle?.percent}%
              </p>
            </div>
          </div>
        </div>
        <div className='d-flex ml-md-auto mr-0 mt-3 mt-md-0'>
          <BadgeCandle
            candle={candle}
            candlesOptions={CANDLE_NEUTRAL_OPTIONS}
            param={candle?.nature}
            editCandleSetting={handleEditCandleSetting}
            paramName='nature'
          />
          <div
            className='d-none d-md-block settings-indicator-icon mx-3'
            onClick={!isEditionCandle && !isEdition ? handleToogleEdit : null}
          >
            <SettingsIcon />
          </div>
          <div
            className='d-none d-md-block trash-indicator-icon ml-0'
            onClick={handleRemoveCandle}
          >
            <TrashIcon />
          </div>
        </div>
      </StyledCandleConditionWrap>
    );
  } else {
    return (
      <CandleSettingsBlock
        candle={candle}
        handleClose={handleToogleEdit}
        patternsInitData={patternsInitData}
        isEdit
      />
    );
  }
};

export default CandleCondition;
