import React from "react";
import { useDispatch } from "react-redux";
import { Card, Modal } from "reactstrap";

import {
  setExcludedMarkets,
  setSelectAllMarkets,
  setSelectedMarkets,
} from "store/bots/wizard/actions";
import Emoji from "shared/ui/Emoji";
import { Button } from "shared/ui/buttons";

const MarketMessageModal = ({
  setIsOpenModal,
  isOpenModal,
  allCoins,
  coins,
  excludedCoins,
}) => {
  const dispatch = useDispatch();

  const handleAllMarkets = () => {
    dispatch(setExcludedMarkets([]));
    dispatch(setSelectedMarkets([]));
    dispatch(setSelectAllMarkets(!allCoins));
    setIsOpenModal(!isOpenModal);
  };

  const renderTitle = () => {
    if (coins?.length > 10 || excludedCoins?.length > 10)
      return "Hey there! Just a quick advice";
    if (coins?.length)
      return "Hey there! You`ve selected all available markets";
    if (excludedCoins?.length)
      return "Hey there! You`ve excluded all available markets";
  };

  const renderMessage = () => {
    if (coins?.length && coins?.length > 10)
      return `You've selected more than 10 markets from the available ones.
    Consider enabling the “Select all” checkbox, and exclude those markets you do not need to make things easier.`;

    if (excludedCoins?.length && excludedCoins?.length > 10)
      return `You've selected more than 10 markets from the available ones.
    Consider disabling the “Select all” checkbox, and select those markets you need, to make things easier.`;

    if (coins?.length)
      return `We've enabled the "Select all" checkbox for your convenience to make things easier.`;

    if (excludedCoins?.length)
      return `To proceed to the next step, please select at least one market.
    Consider selecting some excluded markets or using the "Select all" checkbox to enable them.`;
  };

  const renderEmoji = () => {
    if (coins?.length > 10 || excludedCoins?.length > 10) return "😉";
    if (coins?.length) return "✅";
    if (excludedCoins?.length) return "⚡️";
  };

  return (
    <Modal
      isOpen={isOpenModal}
      modalClassName='modal-black'
      style={{ borderRadius: "16px", overflow: "hidden" }}
    >
      <Card className='m-0 px-4 pt-4 mb-3 shadow-none'>
        <div className='d-flex flex-column align-items-center mb-4'>
          <Emoji symbol={renderEmoji()} size={72} />

          <h1 className='mb-3 mt-4 text-white font-weight-500 text-center'>
            {renderTitle()}
          </h1>
          <p className='mb-0 text-white-75 text-center'>{renderMessage()}</p>
        </div>

        {(coins?.length && coins?.length > 10) ||
        (excludedCoins?.length && excludedCoins?.length > 10) ? (
          <div className='d-flex align-items-center justify-content-between mt-3'>
            <Button outline onClick={() => setIsOpenModal(!isOpenModal)}>
              Dismiss
            </Button>

            <Button color='blue' onClick={handleAllMarkets}>
              {allCoins ? "Disable “Select all”" : "Enable “Select all”"}
            </Button>
          </div>
        ) : (
          <div className='d-flex align-items-center justify-content-between mt-3'>
            {allCoins && (
              <Button outline onClick={() => setIsOpenModal(!isOpenModal)}>
                Got it!
              </Button>
            )}
            <Button
              color='blue'
              onClick={() => {
                if (!allCoins) return handleAllMarkets();
                dispatch(setExcludedMarkets([]));
                setIsOpenModal(!isOpenModal);
              }}
              className={!allCoins ? "w-100" : ""}
            >
              {!allCoins ? "Got it, thanks!" : "Enable “Select all”"}
            </Button>
          </div>
        )}
      </Card>
    </Modal>
  );
};

export default MarketMessageModal;
