export const martingaleCalculate = (
  available = 1,
  volume = 1,
  martingale = 1,
  deviation = 1,
  maximum = 1,
  price = 1,
) => {
  const firstItem = {
    tr: 0,
    price: price,
    invested: (available * volume) / 100,
    purchased: (available * volume) / 100 / price,
    totalInvested: (available * volume) / 100,
    totalInvestedPercent: (100 * ((available * volume) / 100)) / available,
    estimated: ((available * volume) / 100 / price) * price,
    totalPurchased: (available * volume) / 100 / price,
    get estimatedPercent() {
      return (100 * this.estimated) / this.totalInvested - 100;
    },
    result() {
      return null;
    },
  };

  const items = [firstItem];

  for (let i = 0; i < maximum - 1; i++) {
    const prevItem = items[i];
    const newItem = {
      tr: i + 1,
      price: prevItem.price * (1 - deviation / 100),
      invested: (prevItem.invested * (100 + martingale)) / 100,
      purchased:
        (prevItem.invested * (100 + martingale)) /
        100 /
        (prevItem.price * (1 - deviation / 100)),
      totalInvested:
        prevItem.totalInvested + (prevItem.invested * (100 + martingale)) / 100,
      totalInvestedPercent:
        (100 *
          (prevItem.totalInvested +
            (prevItem.invested * (100 + martingale)) / 100)) /
        available,
      estimated:
        (prevItem.totalPurchased +
          (prevItem.invested * (100 + martingale)) /
            100 /
            (prevItem.price * (1 - deviation / 100))) *
        (prevItem.price * (1 - deviation / 100)),
      totalPurchased:
        prevItem.totalPurchased +
        (prevItem.invested * (100 + martingale)) /
          100 /
          (prevItem.price * (1 - deviation / 100)),
      get estimatedPercent() {
        return (100 * this.estimated) / this.totalInvested - 100;
      },
      result() {
        return (
          (this.estimated /
            (prevItem.totalInvested +
              (prevItem.invested * (100 + martingale)) / 100) -
            1) *
          100
        );
      },
    };
    if (newItem.estimatedPercent >= -deviation) {
      items.push(newItem);
    } else {
      break;
    }
  }

  return items;
};
