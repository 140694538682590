import { Tooltip } from "reactstrap";
import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Help } from "assets/icons/general/Help.svg";

export const PortfolioTableHead = ({
  exchangeIndex,
  subscription,
  bots,
  isHover,
}) => {
  const [tooltipOpenAllocation, setTooltipOpenAllocation] = useState(false);
  const toggleAllocation = () =>
    setTooltipOpenAllocation(!tooltipOpenAllocation);
  return (
    <PortfolioTableHead.Head isHover={isHover}>
      <tr>
        <th>
          <p className='text-white-35 mb-0 ml-2'>Coin</p>
        </th>
        <th>
          <div className='d-flex align-items-center text-white-35'>
            <p className='text-white-35 mb-0 mr-1'>Allocation</p>
            <div className='d-flex' id={`realPercent-${exchangeIndex}`}>
              <Help width='12px' />
            </div>
          </div>
          <Tooltip
            placement='top'
            isOpen={tooltipOpenAllocation}
            target={`realPercent-${exchangeIndex}`}
            toggle={toggleAllocation}
          >
            <div className='d-flex align-items-center'>
              <span
                style={{ border: "3px solid #848EFA", borderRadius: "50%" }}
              />
              <p className='mb-0 ml-1' style={{ color: "#6C757D" }}>
                Real percent
              </p>
            </div>
            {subscription?.type === "premium" && (
              <>
                <div className='d-flex align-items-center'>
                  <span
                    style={{ border: "3px solid #00F2C3", borderRadius: "50%" }}
                  />
                  <p className='mb-0 ml-1' style={{ color: "#6C757D" }}>
                    Proficit
                  </p>
                </div>
                <div className='d-flex align-items-center'>
                  <span
                    style={{ border: "3px solid #EB5757", borderRadius: "50%" }}
                  />
                  <p className='mb-0 ml-1' style={{ color: "#6C757D" }}>
                    Deficit
                  </p>
                </div>
              </>
            )}
          </Tooltip>
        </th>
        <th>
          <p className='mb-0 text-white-35 px-3 px-md-0'>Available</p>
        </th>
        <th>
          <p className='mb-0 text-white-35 px-3 px-md-0'>Invested</p>
        </th>
        <th>
          <p className='mb-0 text-white-35 px-3 px-md-0'>Estimated</p>
        </th>
        <th>
          <p className='mb-0 text-white-35 px-3 px-md-0'>Daily revenue</p>
        </th>
        {bots && (
          <th>
            <p className='d-flex justify-content-center mb-0 text-white-35'>
              Bots
            </p>
          </th>
        )}
      </tr>
    </PortfolioTableHead.Head>
  );
};

PortfolioTableHead.Head = styled.thead`
  cursor: ${({ isHover }) =>
    isHover !== false ? "pointer" : "default"} !important;
  tr {
    th {
      color: #ffffff !important;
      opacity: 0.7 !important;
      font-weight: 300 !important;
      padding: 0 !important;
    }
  }
`;
