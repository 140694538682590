import styled from "styled-components";

export const StyledBadgeIndicatorSettingsBlockGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--dark-slate);
  border-radius: 6px;
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  gap: 4px;
  padding: 0.25rem;
`;
export const StyledBadgeIndicatorSettingsBlock = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--dark-slate);
  border-radius: 4px;
  width: ${({ width }) => width || "52px"};
  height: ${({ height }) => height || "26px"};
  padding: 0.25rem 0.5rem;
  transition: background-color 0.3s ease;

  P {
    transition: color 0.3s ease;
  }

  p.dark {
    color: var(--black) !important;
  }

  p.muted {
    color: var(--text-th);
  }
`;
