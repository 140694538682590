import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import { StyledInputWrap } from "shared/styled/StyledInputWrap";
import { FormGroupWithSymbols, NumberInput, SelectInput } from "shared/ui";
import {
  setCandlePattern,
  setCandlePercent,
} from "store/candleConditions/actions";
import styled from "styled-components";

const CandleSettings = ({ candle, patternsInitData, isValid }) => {
  const dispatch = useDispatch();

  const getOptions = useMemo(() => {
    if (patternsInitData?.length) {
      return patternsInitData.map(({ code, name }) => ({
        value: code,
        label: name,
      }));
    }
    return [];
  }, [patternsInitData]);

  const getDefaultPatternValue = useMemo(() => {
    if (patternsInitData?.length) {
      return patternsInitData
        .filter(({ code }) => code === candle.pattern)
        .map(({ code, name }) => ({
          value: code,
          label: name,
        }));
    }
    return [];
  }, [patternsInitData, candle.pattern]);

  const handleSetCandlePattern = (code, name) =>
    dispatch(setCandlePattern(code, name));

  const handleSetCandlePercent = value => dispatch(setCandlePercent(value));

  return (
    <>
      <StyledInputWrap width='180px' className='mt-2 mt-md-0 ml-md-sm'>
        <p className='text-white-75 font-sm  mb-1 '>Pattern</p>
        <CandleSettings.SelectInput
          name='pattern'
          defaultValue={getDefaultPatternValue}
          isSearchable={false}
          options={getOptions}
          style={{ background: "#222435 !important" }}
          hasError={!candle?.pattern && !isValid}
          onChange={({ value, label }) => {
            handleSetCandlePattern({ code: value, name: label });
          }}
        />
      </StyledInputWrap>
      <StyledInputWrap width='160px' className='mt-2 mt-md-0 ml-md-2'>
        <p className='text-white-75 font-sm mb-1'>High-Low percent</p>
        <FormGroupWithSymbols symbolRight='%'>
          <NumberInput
            min={0}
            max={100}
            className={`form-control ${!candle?.percent && !isValid ? " has-error" : ""}`}
            defaultValue={candle?.percent}
            onChange={e => {
              handleSetCandlePercent(e);
            }}
          />
        </FormGroupWithSymbols>
      </StyledInputWrap>
    </>
  );
};

CandleSettings.SelectInput = styled(SelectInput)`
  .react-select__menu-list > div,
  .react-select__menu-list > div > div {
    max-height: 130px !important;
`;

export default CandleSettings;
