import React from "react";
import { Card, CardBody } from "reactstrap";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import styled from "styled-components";

const ChangeEmailPreloader = () => {
  return (
    <Card className='p-3'>
      <CardBody className='p-2'>
        <div className='d-flex flex-row align-items-center mb-2'>
          <SkeletonPreloader
            className='mr-2'
            circle={true}
            width={16}
            height={16}
          />
          <SkeletonPreloader className='w-50' height={16} />
        </div>
        <div className='d-flex flex-column mb-4'>
          <SkeletonPreloader height={10} count={3} className='w-100 mb-2' />
        </div>
        <ChangeEmailPreloader.Input className='w-100 mb-3' />
        <ChangeEmailPreloader.Button className='p-2 w-100 d-flex justify-content-center' />
      </CardBody>
    </Card>
  );
};

ChangeEmailPreloader.Input = styled.div`
  background: #32354e;
  opacity: 0.5;
  border-radius: 6px;
  height: 38px;
`;

ChangeEmailPreloader.Button = styled.div`
  background: linear-gradient(90deg, #535462 0%, rgba(83, 84, 98, 0) 100%);
  border-radius: 6px;
  height: 38px;
`;

export default ChangeEmailPreloader;
