import { deleteBot } from "store/bots/actions";

export const BOTS_CONTENT = {
  details: {
    widgets: {
      dangerZone: {
        title: "Danger zone",
        subtitle:
          "All deals will be deleted together with bot. If bot is genetic, then all results of genetic algorithm will be deleted also.",
        actions: {
          delete: {
            name: "bot",
            title:
              "If you definitely want to delete this bot, please enter 'DELETE' below",
            pathOnSucceess: "/bots",
            actionFunction: deleteBot,
          },
        },
        errorMessage: "Check if entered safety word is correct",
      },
    },
  },
};
