import React from "react";
import moment from "moment";

import { ParameterAmountStyled, ParameterCurrencyStyled } from "./index.styled";
import { formatterToNull } from "helpers/valuesFormatters";
import { replaceDurations } from "helpers/replaceDurations";
import OrdersProgressBar from "../OrdersProgressBar";

const ParameterDisplay = ({
  amount,
  coin,
  title,
  className,
  durations,
  date,
  filledPercent,
  textColor,
  classNameTitle = "text-white-35 font-xs font-weight-300",
}) => {
  const renderAmount = () => (
    <ParameterAmountStyled
      amount={amount}
      textColor={textColor}
      className='text-truncate'
    >
      {formatterToNull(amount)}
      {coin ? <ParameterCurrencyStyled>{coin}</ParameterCurrencyStyled> : null}
    </ParameterAmountStyled>
  );

  const renderDurations = () => (
    <p
      className={`mb-0 font-sm font-weight-500 text-nowrap ${
        durations ? "text-white" : "text-white-35"
      }`}
    >
      {replaceDurations(durations)}
    </p>
  );

  const renderDate = () => (
    <p className='mb-0 text-white text-nowrap font-weight-500 font-sm'>
      {moment(date).format("DD.MM.YY HH:mm")}
    </p>
  );

  const renderProgressBar = () => (
    <div className='d-flex align-items-center'>
      <OrdersProgressBar filledPercent={filledPercent} />
      <p className='mb-0 font-sm font-weight-500 text-white-35'>
        {`${filledPercent || 0}%`}
      </p>
    </div>
  );

  return (
    <div className={className}>
      <p className={`mb-0 ${classNameTitle}`}>{title}</p>
      {amount || amount === 0 || amount === null ? renderAmount() : null}
      {durations ? renderDurations() : null}
      {date ? renderDate() : null}
      {filledPercent || filledPercent === 0 ? renderProgressBar() : null}
    </div>
  );
};

export default ParameterDisplay;
