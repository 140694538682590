import React, { useRef } from "react";
import { useSelector } from "react-redux";

import { fetchDeals } from "store/deals/actions";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import RunningDealsHeader from "./ui/RunningDealsHeader";
import TablePreloader from "shared/ui/TablePreloader";
import RunningDealsTable from "./ui/RunningDealsTable";
import { selectRunningDeals } from "store/deals/selectors";
import { ExchangeWidgetStyledWrap } from "../index.styles";

const RunningDeals = ({ botId, loading, hasNotBots }) => {
  const wrapperRef = useRef(null);

  const {
    loading: loadingDeals,
    data: { items, page, total, request },
  } = useSelector(selectRunningDeals);

  const { handleFilter, handleSorting, handlePaginate } = useFiltersWithRequest(
    request,
    fetchDeals,
  );

  if (!botId || (hasNotBots && !loading)) return null;

  return (
    <ExchangeWidgetStyledWrap className='mb-0' ref={wrapperRef}>
      <RunningDealsHeader
        handleFilter={handleFilter}
        loading={loadingDeals}
        request={request}
      />
      {loading || loadingDeals ? (
        <TablePreloader
          thHeight={12}
          thWidth={[44, 70, 68, 70]}
          tdWidth={[65, 90, 90, 100]}
          tdHeight={14}
          rowTimes={5}
          withCircle={20}
        />
      ) : (
        <RunningDealsTable
          items={items}
          page={page}
          total={total}
          request={request}
          handleSorting={handleSorting}
          handlePaginate={handlePaginate}
          wrapperRef={wrapperRef}
        />
      )}
    </ExchangeWidgetStyledWrap>
  );
};

export default RunningDeals;
