import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";

import {
  clearCredentialsData,
  fetchCredentials,
  fetchOpenOrders,
  fetchTradeHistory,
} from "store/credentials/actions";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import {
  selectCredentialOpenOrders,
  selectCredentialTradeHistor,
} from "store/credentials/selectors";
import MarketHeader from "./ui/MarketHeader";
import MarketTables from "./ui/MarketTables";
import { StyledMarketDetailsWrap } from "./index.styles";

const MarketDetails = ({ base, quote, exchange, id, hasNotBots, loading }) => {
  const [tableView, setTableViw] = useState("orders");
  const [sideFilter, setSideFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const dispatch = useDispatch();

  const { loading: loadingTrades, data: tradesData } = useSelector(
    selectCredentialTradeHistor,
  );
  const { loading: loadingOrders, data: ordersData } = useSelector(
    selectCredentialOpenOrders,
  );

  const requests =
    tableView === "orders" ? ordersData?.request : tradesData?.request;
  const callFunc = tableView === "orders" ? fetchOpenOrders : fetchTradeHistory;

  const { handleFilter, handleSorting, handlePaginate } = useFiltersWithRequest(
    requests,
    callFunc,
    id,
  );

  useLayoutEffect(() => {
    const filters = qs.stringify({
      filter: { exchange: { eq: exchange } },
      limit: 100,
    });
    dispatch(fetchCredentials(`?${filters}`));
    setSideFilter("");
    setStatusFilter("");
    return () => dispatch(clearCredentialsData());
  }, [dispatch, base, quote, exchange]);

  useLayoutEffect(() => {
    if (!id) return;
    const filters = qs.stringify({
      filter: { market: { eq: `${base}-${quote}` } },
      sort:
        tableView === "orders"
          ? { by: "createdAt", order: "DESC" }
          : { by: "date", order: "DESC" },
      page: 1,
    });
    if (tableView === "orders") dispatch(fetchOpenOrders(id, `?${filters}`));
    if (tableView === "trades") dispatch(fetchTradeHistory(id, `?${filters}`));
    setSideFilter("");
    setStatusFilter("");
  }, [tableView, dispatch, base, quote, id]);

  if (!id || (hasNotBots && !loading)) return null;

  const handleRefresh = () => {
    const filters = qs.stringify({
      filter: { ...requests.filter, market: { eq: `${base}-${quote}` } },
      page: 1,
    });
    if (tableView === "orders")
      return dispatch(fetchOpenOrders(id, `?${filters}`));
    dispatch(fetchTradeHistory(id, `?${filters}`));
    setSideFilter("");
  };

  return (
    <StyledMarketDetailsWrap>
      <MarketHeader
        handleRefresh={handleRefresh}
        currentCredId={id}
        loadingTrades={loadingTrades}
        loadingOrders={loadingOrders}
        tableView={tableView}
        setTableViw={setTableViw}
        handleFilter={handleFilter}
        sideFilter={sideFilter}
        setSideFilter={setSideFilter}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
      />

      <MarketTables
        tableView={tableView}
        handleSort={handleSorting}
        handlePagination={handlePaginate}
        orders={ordersData}
        trades={tradesData}
        loadingTrades={loadingTrades}
        loadingOrders={loadingOrders}
        currentCredId={id}
      />
    </StyledMarketDetailsWrap>
  );
};

export default React.memo(MarketDetails);
