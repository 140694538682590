import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Filters from "views/channels/compounds/Filters";
import PaginationPanel from "shared/ui/PaginationPanel";
import usePagination from "hooks/usePagination";
import ChannelsListPreload from "../../shared/ui/ChannelWidget/ChannelsListPreload";
import useRequestCancellation from "hooks/useRequestCancellation";
import ChannelsModal from "./modals/ChannelsModal";
import {
  getChannels,
  resetChannels,
  subscribeChannel,
} from "store/channels/actions";
import { selectChannels } from "store/channels/selectors";
import OwnChannelFilter from "./compounds/OwnChannelFilter";
import {
  Channel,
  CreateChannelModal,
  EmptyDataList,
  EmptyFiltersListingState,
} from "shared/ui";
import { Button } from "shared/ui/buttons";

const ChannelsList = ({ location }) => {
  const [isInfoRead, setInfoRead] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const { useAbortableEffect } = useRequestCancellation();
  const { handlePaginate } = usePagination("/channels", true);

  const {
    error,
    loading,
    data: { items, total, page },
  } = useSelector(selectChannels);

  const handleToggleModal = () => {
    setInfoRead(true);
  };

  const handleToCreateChannels = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const isButtonClicked = localStorage.getItem("isInfoRead");
    if (isButtonClicked) {
      handleToggleModal();
    }
  }, []);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    }
    if (isInfoRead) {
      localStorage.setItem("isInfoRead", true);
    }
  }, [isInfoRead, initialLoad]);

  useAbortableEffect(
    signal => {
      const queryString = `${location.search}${location.search ? "&" : "?"}limit=9`;
      if (!initialLoad) {
        dispatch(getChannels(queryString, signal));
      }
      return () => dispatch(resetChannels());
    },
    [location.search, dispatch, initialLoad],
    true,
  );

  const renderState = () => {
    if (loading || initialLoad)
      return (
        <ChannelsList.ChannelsWrapper>
          <ChannelsListPreload count={9} />
        </ChannelsList.ChannelsWrapper>
      );
    if (history.location.search?.includes("filter") && !items?.length)
      return <EmptyFiltersListingState />;
    if (!items?.length || error) {
      return (
        <EmptyDataList
          title='Create Your First Channel'
          description={
            "Ready to share your trading signals? Create a channel for \n users to subscribe and enjoy your insights!"
          }
          buttonText='Create my channel'
          onHandleClick={handleToCreateChannels}
        />
      );
    }
  };

  useEffect(() => {
    window.analytics.page("/channels");
  }, []);

  return (
    <>
      <ChannelsModal isInfoRead={isInfoRead} toggleModal={handleToggleModal} />
      <div className='list-page-header d-flex align-items-center mt-4 justify-content-between'>
        <div className='d-flex flex-sm-row flex-column'>
          <h1 className='mb-0 text-white font-weight-500 mr-4'>Channels</h1>
          <OwnChannelFilter disabled={loading} />
        </div>
        {!history.location.search?.includes("filter") ? (
          <Button
            color='blue'
            className='m-0 mb-sm-2'
            onClick={handleToCreateChannels}
            disabled={isModalOpen}
          >
            Create my channel
          </Button>
        ) : null}
      </div>
      <Filters location={location} items={items} loading={loading} />
      {renderState()}
      <ChannelsList.ChannelsWrapper>
        {items?.map((channel, index) => {
          return (
            <Channel
              key={channel.id}
              onSubscribeChannel={subscribeChannel}
              index={index}
              channel={channel}
            />
          );
        })}
      </ChannelsList.ChannelsWrapper>
      <PaginationPanel
        activePage={page}
        totalItemsCount={total}
        totalPagesView={9}
        onChange={handlePaginate}
      />
      <CreateChannelModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

ChannelsList.ChannelsWrapper = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;

  @media (min-width: 578px) and (max-width: 1199.98px) {
    display: grid;
    grid-template-columns: 48% 48%;
    justify-content: space-between;
  }

  @media (max-width: 577.98px) {
    display: flex;
    flex-direction: column;
  }
`;

ChannelsList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }),
};

export default ChannelsList;
