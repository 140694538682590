import { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";

import { paginationScrollUp } from "helpers/paginationScrollUp";
import {
  setBotName,
  setInvestment,
  setResetBotWizard,
} from "store/bots/wizard/actions";

export const useCreateBot = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [highestStep, setHighestStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [credentialId, setCredentialId] = useState("");

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    return () => {
      dispatch(setBotName(""));
      dispatch(setResetBotWizard());
      dispatch(setInvestment({}));
    };
  }, [dispatch]);

  useEffect(() => {
    paginationScrollUp();
  }, [currentStep]);

  useEffect(() => {
    window.analytics.page("/bots/create");
  }, []);

  return {
    isSubmit,
    setIsSubmit,
    currentStep,
    setCurrentStep,
    highestStep,
    setHighestStep,
    loading,
    setLoading,
    credentialId,
    setCredentialId,
  };
};
