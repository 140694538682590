import React, { useState, useEffect } from "react";

export const NumberInput = ({
  type = "float",
  value,
  min,
  max,
  onChange,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (/^0[0-9]/.test(internalValue))
      setInternalValue(internalValue.replace(/^0/, ""));
    if (+internalValue !== value) setInternalValue(value);
  }, [internalValue, value, setInternalValue]);

  const handleChange = ({ target }) => {
    if (!target.value) {
      setInternalValue("");
      onChange(0);
    }
    if (target.validity.valid) {
      if (target.value < min && target.value !== "") {
        setInternalValue(min);
        onChange(min);
      } else if (target.value > max && target.value !== "") {
        setInternalValue(max);
        onChange(max);
      } else {
        setInternalValue(target.value);
        onChange(
          isNaN(+target.value) || target.value === "" ? "" : +target.value,
        );
      }
    }
  };

  return (
    <input
      pattern={type === "int" ? "^[0-9]*$" : "^-?([0-9]*[.])?[0-9]*"}
      inputMode={type === "int" ? "numeric" : "decimal"}
      className='form-control'
      autoComplete='off'
      onChange={event => handleChange(event)}
      value={internalValue}
      onWheel={e => e.target.blur()}
      {...props}
    />
  );
};

export default NumberInput;
