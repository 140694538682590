import React from "react";
import moment from "moment";

import { ReactComponent as ForceStart } from "assets/icons/deal/ForceStartIcon.svg";
import { ReactComponent as ForceCompleted } from "assets/icons/deal/ForceCompletedIcon.svg";

import { DealIconWrapperStyled } from "./index.styles";

const DealIcons = ({ data }) => (
  <div className='d-flex justify-content-center justify-content-md-end'>
    <div className='d-flex flex-column flex-md-row mr-md-2 font-sm'>
      <div className='d-flex align-self-start align-self-md-end mr-md-4 flex-column flex-md-row'>
        {data.forceCompleted && (
          <DealIconWrapperStyled className='d-flex'>
            <ForceCompleted data-testid='deal-card-force-completed-icon' />
          </DealIconWrapperStyled>
        )}

        {data.forceStarted && (
          <DealIconWrapperStyled className='d-flex'>
            <ForceStart data-testid='deal-card-force-start-icon' />
          </DealIconWrapperStyled>
        )}
      </div>
      <div className='d-flex flex-column'>
        <p
          data-testid='deal-card-createdAt-HH:mm'
          className='mb-0 font-weight-300 align-self-md-end'
        >
          {moment(data.createdAt).format("HH:mm")}
        </p>
        <p
          data-testid='deal-card-createdAt-DD.MM.YYYY'
          className='mb-0 text-white font-weight-500'
        >
          {moment(data.createdAt).format("DD.MM.YYYY")}
        </p>
      </div>
    </div>
  </div>
);

export default DealIcons;
