import React from "react";
import { Label } from "reactstrap";

import { FormGroupWithSymbols, NumberInput } from "shared/ui";
import { ReactComponent as BarChart } from "assets/icons/general/barChart.svg";

const IndicatorsHeader = ({
  sellSignals,
  buySignals,
  values,
  errors,
  submitted,
  setFieldValue,
}) => {
  const handleMinimumBuySignalsChange = (value, type) => {
    if (!value) {
      setFieldValue(type, null);
    } else {
      setFieldValue(type, value);
    }
  };
  return (
    <div className='d-flex justify-content-between flex-column flex-md-row mb-3'>
      <div className='d-flex flex-column justify-content-end'>
        <div className='d-flex align-items-center'>
          <BarChart className='my-auto mr-2' />
          <p className='mb-0 font-weight-500 text-white'>Indicators</p>
        </div>
      </div>
      <div className='d-flex flex-column flex-md-row ml-md-auto mr-0 mt-3 mt-md-0'>
        <div className='d-flex flex-column mr-md-2 mb-2'>
          <Label className='mb-1 text-white-75 text-nowrap'>
            Minimum <span className='text-success font-weight-400'>BUY</span>{" "}
            signals
          </Label>
          <FormGroupWithSymbols
            symbolRight={
              <div className='d-flex flex-row align-items-center pr-3'>
                <p className='text-white-35 mb-0 text-nowrap'>Out of</p>
                <span className='text-white ml-1'>{buySignals}</span>
              </div>
            }
          >
            <NumberInput
              value={values?.minimumBuySignals}
              type='int'
              name='minimumBuySignals'
              className={`form-control text-white ${submitted && errors?.minimumBuySignals ? "has-error" : ""}`}
              onChange={value =>
                handleMinimumBuySignalsChange(value, "minimumBuySignals")
              }
              autoComplete='off'
            />
          </FormGroupWithSymbols>
          {errors.minimumBuySignals && (
            <p className='form-error font-sm mb-0'>
              {`Amount of attached indicators (${buySignals}) is less then entered amount.`}
            </p>
          )}
        </div>
        <div className='d-flex flex-column'>
          <Label className='mb-1 text-white-75 text-nowrap'>
            Minimum <span className='text-danger font-weight-400'>SELL</span>{" "}
            signals
          </Label>
          <FormGroupWithSymbols
            symbolRight={
              <div className='d-flex flex-row align-items-center pr-3'>
                <p className='text-white-35 mb-0 text-nowrap'>Out of</p>
                <span className='text-white ml-1'>{sellSignals}</span>
              </div>
            }
          >
            <NumberInput
              value={values?.minimumSellSignals}
              type='int'
              name='minimumSellSignals'
              className={`form-control text-white ${submitted && errors?.minimumSellSignals ? "has-error" : ""}`}
              onChange={value =>
                handleMinimumBuySignalsChange(value, "minimumSellSignals")
              }
              autoComplete='off'
            />
          </FormGroupWithSymbols>
          {errors.minimumSellSignals && (
            <p className='form-error font-sm mb-0'>
              {`Amount of attached indicators (${sellSignals}) is less then entered amount.`}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default IndicatorsHeader;
