import React, { useEffect } from "react";
import { Formik, Form } from "formik";

import Button from "shared/ui/buttons/Button";
import StepHeader from "./StepHeader";
import WizardErrors from "../Wizard/WizardErrors";
import { handleBotSchema } from "../../EditBot/editBotConfiguration";
import RenderBotTab from "./RenderBotTab";
import SelectBotType from "./SelectBotType";
import { useCreateBotSubmit } from "shared/entities/bot/model/useCreateBotSubmit";
import { useConfigurationContext } from "context/bot/ConfigurationContext";
import useCalculatedSignals from "hooks/useCalculatedISignals";

const Configuration = ({
  goBack,
  setIsSubmit,
  loadingWizard,
  botData,
  currentStep,
}) => {
  const {
    botType,
    setBotType,
    submitted,
    setSubmitted,
    alertOpen,
    setAlertOpen,
    redirectPath,
    setRedirectPath,
    currentPlan,
    initialValues,
    wizardMarkets,
    indicators,
    isOpenSettings,
  } = useConfigurationContext();

  const { handleSubmitBot, filteredIndicators } = useCreateBotSubmit({
    wizardMarkets,
    botType,
    indicators,
    setRedirectPath,
    setAlertOpen,
    setIsSubmit,
  });

  const { buySignals, sellSignals, setBuySignals, setSellSignals } =
    useCalculatedSignals(botData, filteredIndicators);

  useEffect(() => {
    if (currentStep !== 2) {
      indicators.length = 0;
      setSubmitted(false);
      setSellSignals(0);
      setBuySignals(0);
    }
  }, [currentStep, indicators, setBuySignals, setSellSignals]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={handleBotSchema(botType, buySignals, sellSignals)}
      onSubmit={handleSubmitBot}
    >
      {({ isSubmitting, status, setErrors }) => (
        <Form>
          <StepHeader />
          <div className='p-3 p-md-sm'>
            <SelectBotType setBotType={setBotType} botType={botType} />
            <RenderBotTab
              currentStep={botType}
              buySignals={buySignals}
              sellSignals={sellSignals}
              botData={botData}
              loadingWizard={loadingWizard}
              submitted={submitted}
              setSubmitted={setSubmitted}
              type={botType}
              currentPlan={currentPlan}
            />
          </div>
          <div className='d-flex flex-column flex-sm-row justify-content-between p-4'>
            <Button color='gray' onClick={goBack} outline type='button'>
              Back - Market
            </Button>
            <Button
              color='blue'
              className='mt-2 mt-sm-0'
              disabled={
                (currentPlan?.type !== "premium" && botType === "genetic") ||
                isOpenSettings
              }
              loading={isSubmitting}
              onClick={() => setSubmitted(true)}
              type='submit'
            >
              Finish bot creation!
            </Button>
          </div>
          <WizardErrors
            status={status}
            alertOpen={alertOpen}
            onSetAlertOpen={setAlertOpen}
            redirectPath={redirectPath}
            setErrors={setErrors}
          />
        </Form>
      )}
    </Formik>
  );
};

export default Configuration;
