import * as Yup from "yup";

export const initialPivotPoint = {
  enabled: false,
  calculation: null,
  timeframe: null,
  hasSupport: null,
  hasResistance: null,
  supportBottom: null,
  supportTop: null,
  resistanceBottom: null,
  resistanceTop: null,
};

export const formatterPPtoNumbers = (type, value) => {
  if (type === "s") return value === "PP" ? 0 : +value?.replace("S", "-");
  if (type === "r") return value === "PP" ? 0 : +value?.replace("R", "");
};

export const handleBotSchema = (type, buySignals, sellSignals) => {
  if (type === "custom")
    return Yup.object().shape({
      volume: Yup.number()
        .moreThan(0)
        .lessThan(21, 'This value should be between "1" and "20".')
        .required("Initial volume is a required field")
        .nullable(true),
      profit: Yup.number()
        .moreThan(0)
        .lessThan(101, 'This value should be between "1" and "100".')
        .required("Minimum profit is a required field")
        .nullable(true),
      stopLossPercent: Yup.number()
        .moreThan(0)
        .lessThan(101, 'This value should be between "1" and "100".')
        .nullable(true)
        .notRequired(),
      minimumBuySignals: Yup.number()
        .integer()
        .nullable(true)
        .max(buySignals)
        .notRequired(
          `Amount of attached indicators (${buySignals}) is less then entered amount.`,
        ),
      minimumSellSignals: Yup.number()
        .integer()
        .nullable(true)
        .max(sellSignals)
        .notRequired(
          `Amount of attached indicators (${sellSignals}) is less then entered amount.`,
        ),
      trailingSell: Yup.number()
        .moreThan(0)
        .lessThan(21, 'This value should be between "0.0001" and "20".')
        .required("Trailing Sell is a required field")
        .nullable(true),
      trailingBuy: Yup.number()
        .moreThan(0)
        .lessThan(21, 'This value should be between "0.0001" and "20".')
        .required("Trailing Buy is a required field")
        .nullable(true),
      type: Yup.string().required(),
      martingale: Yup.boolean().required(),
      maxTradeRequests: Yup.number()
        .min(1)
        .integer()
        .when("martingale", martingale =>
          martingale
            ? Yup.number()
                .nullable(true)
                .required("Maximum trade requests is a required field")
            : Yup.number().nullable(true).notRequired(),
        ),
      martingalePercent: Yup.number()
        .moreThan(0)
        .when("martingale", martingale =>
          martingale
            ? Yup.number()
                .moreThan(0)
                .lessThan(101, 'This value should be between "1" and "100".')
                .nullable(true)
                .required("Martingale percent is a required field")
            : Yup.number().moreThan(0).nullable(true).notRequired(),
        ),
      deviation: Yup.number()
        .moreThan(0)
        .when("martingale", martingale =>
          martingale
            ? Yup.number()
                .moreThan(0)
                .lessThan(100, 'This value should be between "1" and "99".')
                .nullable(true)
                .required("Deviation is a required field")
            : Yup.number().min(1).nullable(true).notRequired(),
        ),
    });
};

export const handleInitValues = data => {
  return {
    genetic: {},
    template: {
      strategy: data?.strategy?.id || null,
    },
    custom: {
      volume: data?.tradeSettings?.volume || null,
      profit: data?.tradeSettings?.profit || null,
      stopLossPercent: Number(data?.tradeSettings?.stopLossPercent) || null,
      minimumBuySignals: data?.tradeSettings?.minimumBuySignals || null,
      minimumSellSignals: data?.tradeSettings?.minimumSellSignals || null,
      trailingBuy: data?.tradeSettings?.trailingBuy || null,
      trailingSell: data?.tradeSettings?.trailingSell || null,
      type: data?.tradeSettings?.executionType || "passive",
      martingale: data?.tradeSettings?.martingale || false,
      ...(data?.tradeSettings?.martingale
        ? {
            martingalePercent: data?.tradeSettings?.martingalePercent || null,
            deviation: data?.tradeSettings?.deviation || null,
            maxTradeRequests: data?.tradeSettings?.maxTradeRequests || null,
          }
        : {}),
      pivotPoint: data?.tradeSettings?.pivotPoint
        ? {
            ...data?.tradeSettings?.pivotPoint,
            supportBottom: formatterPPtoNumbers(
              "s",
              data?.tradeSettings?.pivotPoint.supportBottom,
            ),
            supportTop: formatterPPtoNumbers(
              "s",
              data?.tradeSettings?.pivotPoint.supportTop,
            ),
            resistanceBottom: formatterPPtoNumbers(
              "r",
              data?.tradeSettings?.pivotPoint.resistanceBottom,
            ),
            resistanceTop: formatterPPtoNumbers(
              "r",
              data?.tradeSettings?.pivotPoint.resistanceTop,
            ),
          }
        : initialPivotPoint,
    },
  };
};

export const handleInitCreateValues = data => {
  return {
    genetic: {},
    template: {
      strategy: null,
    },
    custom: {
      volume: null,
      profit: null,
      stopLossPercent: null,
      minimumBuySignals: null,
      minimumSellSignals: null,
      trailingBuy: null,
      trailingSell: null,
      type: "passive",
      martingalePercent: null,
      deviation: null,
      maxTradeRequests: null,
      martingale: false,
      pivotPoint: {
        supportBottom: formatterPPtoNumbers(
          "s",
          data?.tradeSettings?.pivotPoint.supportBottom,
        ),
        supportTop: formatterPPtoNumbers(
          "s",
          data?.tradeSettings?.pivotPoint.supportTop,
        ),
        resistanceBottom: formatterPPtoNumbers(
          "r",
          data?.tradeSettings?.pivotPoint.resistanceBottom,
        ),
        resistanceTop: formatterPPtoNumbers(
          "r",
          data?.tradeSettings?.pivotPoint.resistanceTop,
        ),
      },
    },
  };
};
