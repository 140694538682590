import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import ChangeEmailForm from "views/user/forms/ChangeEmailForm";
import Error from "shared/ui/state/Error";
import {
  changeEmail,
  checkEmail,
  resendEmail,
  confirmEmail,
  cancelEmail,
  resendConfirmEmail,
} from "store/user/actions";
import { addSnackBar } from "store/snackbar/actions";
import ChangeEmailPreloader from "../preloaders/ChangeEmailPreloader";
import DefaultEmailForm from "../forms/DefaultEmailForm";

const ChangeEmail = ({ email, loading, error, confirmed }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChangeEmail, setIsChangeEmail] = useState(false);
  const [newEmail, setNewEmail] = useState({});

  const emailForChange = useSelector(state => state?.me?.checkEmail?.email);
  const dispatch = useDispatch();

  const handleChangeEmail = () => {
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
      setIsChangeEmail(true);
    }, 1000);
  };

  const handleChangeCurrentEmail = async () => {
    const res = await dispatch(cancelEmail());
    if (res.message) handleChangeEmail();
  };

  const handleResend = async e => {
    e.preventDefault();
    const res = await dispatch(resendConfirmEmail());
    if (res.message) {
      dispatch(addSnackBar("success", res.message));
    } else {
      setIsChangeEmail(false);
    }
  };

  useEffect(() => {
    const getEmail = async () => {
      const res = await dispatch(checkEmail());
      if (res.data) {
        setNewEmail({ email: res.data.email, id: res.data.id });
        setIsChangeEmail(true);
      } else {
        setIsChangeEmail(false);
      }
    };
    getEmail();

    return () => {
      setIsChangeEmail(false);
    };
  }, [dispatch]);

  if (loading) return <ChangeEmailPreloader />;

  if (error) return <Error error={error} />;

  return (
    <ChangeEmail.Wrapper className='p-3'>
      <CardBody className='px-2 py-2'>
        {isChangeEmail ? (
          <ChangeEmailForm
            changeEmail={changeEmail}
            emailForChange={emailForChange}
            onResendEmail={resendEmail}
            confirmEmail={confirmEmail}
            onCancelEmail={cancelEmail}
            setIsChangeEmail={setIsChangeEmail}
            setNewEmail={setNewEmail}
            newEmail={newEmail}
            isChangeEmail={isChangeEmail}
          />
        ) : (
          <DefaultEmailForm
            email={email}
            onResend={handleResend}
            onChangeEmail={handleChangeEmail}
            onChangeCurrentEmail={handleChangeCurrentEmail}
            confirmed={confirmed}
            isSubmitting={isSubmitting}
          />
        )}
      </CardBody>
    </ChangeEmail.Wrapper>
  );
};

ChangeEmail.Wrapper = styled(Card)`
  .sub-card {
    background: #2e3148;
    border-radius: 4px;
    padding: 3.5px 10px;
  }
  .resend-code {
    :hover {
      text-decoration: underline;
    }
  }
`;

export default ChangeEmail;
