import React from "react";

import ActiveMarkets from "./activeMarkets/ActiveMarkets";

const MarketsTab = ({ id, loadingPage, botType }) => {
  return (
    <ActiveMarkets botId={id} loadingPage={loadingPage} botType={botType} />
  );
};

export default MarketsTab;
