import React from "react";
import { Card } from "reactstrap";

import ReferralStatisticsChart from "./ui/ReferralStatisticsChart";
import { EmptyStateCustom } from "shared/ui";
import { ReactComponent as ReferralStatistics } from "assets/icons/general/EmptyAssetAllocation.svg";

const ReferralChartStatistics = ({ statisticsData, labels, maxAmount }) => {
  return (
    <Card>
      <h4 className='font-weight-500 text-white mt-4 ml-4'>Statistics</h4>
      {statisticsData?.length ? (
        <ReferralStatisticsChart
          statisticsData={statisticsData}
          labels={labels}
          maxAmount={maxAmount}
        />
      ) : (
        <EmptyStateCustom
          backgroundColor='var(--card-black-background)'
          title='You have no referral statistic yet'
          description='Here will be displayed a graph with people statistics who have
              registered using your referral link'
          Img={ReferralStatistics}
        />
      )}
    </Card>
  );
};

export default ReferralChartStatistics;
