import React from "react";

import { ReactComponent as Empty } from "assets/icons/general/empty-state-planet2.svg";

const EmptyState = ({ title, desc }) => {
  return (
    <div
      className='d-flex flex-column align-items-center'
      style={{ padding: "37px 0 61px" }}
    >
      <Empty role='img' style={{ marginBottom: "2.5rem" }} />
      <p className='text-white font-weight-500 mb-2'>{title}</p>
      <p className='px-5 font-sm text-center w-100'>{desc}</p>
    </div>
  );
};

export default EmptyState;
