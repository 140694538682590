import styled from "styled-components";
import { Card } from "reactstrap";

const StyledPlanCard = styled(Card)`
  background: var(--dark-slate) !important;

  a.text-info:focus {
    color: #2f80ed !important;
  }

  a.text-info:hover,
  a.text-info:focus + a.text-info:hover {
    color: #115bbf !important;
  }

  &.card {
    border-radius: 1rem !important;
  }

  & .card-header,
  & .card-footer {
    padding: 2rem 2rem 0 !important;
  }

  & .card-body {
    padding: 0 2rem !important;
  }

  & .card-footer {
    padding: 2rem !important;
  }
`;

export default StyledPlanCard;
