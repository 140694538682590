import React from "react";
import styled from "styled-components";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";

import PaginationPanel from "shared/ui/PaginationPanel";
import BotsStatisticsPreload from "./BotsStatisticsPreload";
import Filters from "./Filters";
import BotStatistics from "./BotStatistics";
import EmptyDashboardWidgets from "../EmptyDashboadWidgets";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import useRequestCancellation from "hooks/useRequestCancellation";
import { getBots, resetBots } from "store/bots/actions";
import { ReactComponent as Trade } from "assets/icons/general/trade.svg";
import { ReactComponent as Empty } from "assets/icons/general/greenPlanet-2.svg";

const BotsStatisticsWidget = ({ loadingPage, content }) => {
  const dispatch = useDispatch();

  const bots = useSelector(state => state.bots.list.data);
  const loading = useSelector(state => state.bots.list.loading);

  const { useAbortableEffect } = useRequestCancellation();
  const { handleFilter, handlePaginate } = useFiltersWithRequest(
    bots.request,
    getBots,
  );

  useAbortableEffect(
    signal => {
      const queryString = qs.stringify({
        sort: { by: "totalReturn", order: "DESC" },
      });
      dispatch(getBots(`?${queryString}`, signal));

      return () => dispatch(resetBots());
    },
    [dispatch],
  );

  if (loading && loadingPage) {
    return (
      <Card className='overflow-hidden'>
        <BotsStatisticsPreload header />
      </Card>
    );
  }

  return (
    <Card className='mb-4 p-4'>
      <BotsStatisticsWidget.CardHeader>
        <div className='d-flex align-items-center'>
          <Trade />
          <p className='mb-0 ml-2 text-white font-weight-500'>
            {content.title}
          </p>
        </div>
        <Link to='/bots' className='link-info'>
          {content.link}
        </Link>
      </BotsStatisticsWidget.CardHeader>

      <Filters
        handleFilter={handleFilter}
        loading={loading}
        items={bots.items}
      />

      <CardBody className='p-0 m-0'>
        {loading && !loadingPage ? (
          <BotsStatisticsPreload header={false} />
        ) : (
          <>
            {bots?.items?.length ? (
              <div className='d-flex align-items-center justify-content-between mb-2 font-sm'>
                <p className='mb-0 text-white-35 font-weight-300'>
                  {content.leftLabel}
                </p>
                <p className='mb-0 text-white-35 font-weight-300'>
                  {content.rightLabel}
                </p>
              </div>
            ) : null}

            {bots?.items?.map((bot, index) => {
              return (
                <Link to={`bots/${bot.id}`} key={bot.id}>
                  <BotStatistics bot={bot} index={index} />
                </Link>
              );
            })}
          </>
        )}
      </CardBody>
      <BotsStatisticsWidget.Pagination>
        <PaginationPanel
          activePage={bots.page}
          totalItemsCount={bots.total}
          onChange={page => handlePaginate(page)}
          isCircle
        />
      </BotsStatisticsWidget.Pagination>
      {!bots?.items?.length && !loading ? (
        <EmptyDashboardWidgets
          Img={Empty}
          title={content.empty.title}
          descriptionBefore={content.empty.descriptionBefore}
        />
      ) : null}
    </Card>
  );
};

BotsStatisticsWidget.CardHeader = styled(CardHeader)`
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
  margin-bottom: 8px !important;
  svg {
    path {
      stroke: rgba(47, 128, 237, 1);
    }
  }
`;

BotsStatisticsWidget.Pagination = styled.div`
  ul {
    margin-top: 24px;
    margin-bottom: 0;
  }
`;

export default BotsStatisticsWidget;
