import React from "react";
import { Card, CardBody, Col, FormGroup, Row } from "reactstrap";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
  SIGNALS_STATUS_OPTIONS,
  AGREEMENT_STATUS_OPTIONS,
} from "models/filters";
import { signalsMarketsList } from "store/signals/selectors";
import { ExchangesSelect, SelectInput } from "shared/ui";

const Filters = ({ userId, filters, handleFilter, exchange }) => {
  const { data, loading } = useSelector(signalsMarketsList);
  const marketValue = filters?.market?.in?.[0];

  const acceptedSignalsOptions = [
    { value: userId, label: "Accepted signals", color: "#FFFFFF" },
  ];

  const getMarketValue = () => {
    return filters?.market?.in?.length
      ? {
          value: marketValue,
          label: marketValue.replace("-", " / "),
          icon: {
            type: "cryptocurrency",
            size: "selectSize",
            code: marketValue.split("-")?.[0],
          },
        }
      : [];
  };

  return (
    <Card className='mt-3 mb-4 mx-0 '>
      <CardBody className='p-4 d-flex flex-column flex-md-row justify-content-between'>
        <Row className='d-flex align-items-center p-0 m-0 w-100'>
          <Col md={6} lg={2} className='p-0 mb-2 mb-lg-0'>
            <FormGroup className='form-input m-0'>
              <ExchangesSelect
                isClearable
                withAllExchanges
                name='exchange'
                value={exchange}
                onChange={values => {
                  handleFilter({
                    exchange: { in: [values?.value] },
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6} lg={2} className='p-0 pl-0 mb-2 pl-md-3 mb-lg-0'>
            <FormGroup className='form-input m-0'>
              <SelectInput
                isClearable
                placeholder='Market'
                isMulti
                isDisabled={!exchange}
                isLoading={loading}
                value={getMarketValue()}
                onChange={values => {
                  handleFilter({
                    market: {
                      in: values?.length
                        ? values?.map(e => e.label?.replace(" / ", "-"))
                        : "",
                    },
                  });
                }}
                options={
                  data?.length &&
                  data?.map(market => {
                    return {
                      value: market,
                      label: market.replace("-", " / "),
                      icon: {
                        type: "cryptocurrency",
                        size: "selectSize",
                        code: market.split("-")?.[0],
                      },
                    };
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col md={4} lg={2} className='p-0 pl-0 mb-2 pl-lg-3 mb-lg-0'>
            <FormGroup className='form-input m-0'>
              <SelectInput
                isClearable
                placeholder='Status signal'
                name='status'
                value={SIGNALS_STATUS_OPTIONS.find(
                  option => option.value === filters?.status?.eq,
                )}
                onChange={option =>
                  handleFilter({
                    status: { eq: option?.value },
                  })
                }
                options={SIGNALS_STATUS_OPTIONS}
              />
            </FormGroup>
          </Col>
          <Col md={4} lg={3} className='p-0 pl-0 mb-2 pl-md-3 mb-lg-0'>
            <FormGroup className='form-input m-0'>
              <SelectInput
                isClearable
                placeholder='Status agreement'
                name='agreements.status'
                value={AGREEMENT_STATUS_OPTIONS.find(
                  option =>
                    option.value === filters?.["agreements.status"]?.in?.[0],
                )}
                onChange={option =>
                  handleFilter({
                    "agreements.status": { in: [option?.value] },
                  })
                }
                options={AGREEMENT_STATUS_OPTIONS}
              />
            </FormGroup>
          </Col>
          <Col md={4} lg={3} className='p-0 pl-0 mb-2 pl-md-3 mb-lg-0'>
            <FormGroup className='form-input m-0'>
              <SelectInput
                isClearable
                placeholder='Accepted signals'
                name='credentials.user'
                value={acceptedSignalsOptions.find(option =>
                  option.value
                    ? option.value === filters?.["credentials.user"]?.eq
                    : null,
                )}
                onChange={option =>
                  handleFilter({
                    "credentials.user": { eq: option?.value },
                  })
                }
                options={acceptedSignalsOptions}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

Filters.propTypes = {
  userId: PropTypes.string,
  handleFilter: PropTypes.func,
};

export default Filters;
