import React from "react";
import styled from "styled-components";
import { useFormikContext } from "formik";

import PivotPointsForm from "./PivotPointsForm";
import { initialPivotPoint } from "../../EditBot/editBotConfiguration";
import { ToggleSwitch } from "shared/ui";

const PivotPoints = ({ submitted, botData }) => {
  const { values, errors, setFieldValue } = useFormikContext();

  const handlePivotPoints = value => {
    if (value) {
      return setFieldValue("pivotPoint", {
        enabled: value,
        calculation: "traditional",
        timeframe: "day",
        hasSupport: true,
        hasResistance: true,
        supportBottom: -1,
        supportTop: 0,
        resistanceBottom: 0,
        resistanceTop: 1,
      });
    }
    return setFieldValue("pivotPoint", initialPivotPoint);
  };

  return (
    <PivotPoints.Wrapper>
      <div className='d-flex flex-row align-items-center p-4'>
        <ToggleSwitch
          height={18}
          width={36}
          onColor='#26366b'
          offColor='#4b4c5e'
          onHandleColor='#00F2C3'
          offHandleColor='#FFFFFF59'
          checked={values?.pivotPoint?.enabled}
          activeBoxShadow='none'
          onChange={value => handlePivotPoints(value)}
        />
        <p className='martingale font-weight-500 text-white ml-3 mb-0 mr-2'>
          Pivot points
        </p>
      </div>

      {values?.pivotPoint?.enabled ? (
        <PivotPointsForm
          values={values}
          setFieldValue={setFieldValue}
          submitted={submitted}
          errors={errors}
          botData={botData}
        />
      ) : null}
    </PivotPoints.Wrapper>
  );
};

PivotPoints.Wrapper = styled.div`
  background: #2e3148;
  border: 2px solid #363a54;
  border-radius: 12px;
  margin-bottom: 24px;
`;

export default PivotPoints;
