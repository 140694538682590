import React from "react";
import moment from "moment";

import { REQUESTS_TRADES_TABLE_HEAD } from "models/deal/enum/tables";
import { formatterToNull } from "helpers/valuesFormatters";
import TablePreloader from "../TablePreloader";
import SortTableHead from "../SortTableHead";
import { StyledTable } from "shared/styled/StyledTable";
import {
  TableAmount,
  TableCurrency,
} from "shared/styled/StyledTradeTableComponents";
import { BadgeBuySell } from "../badges";

const TradesTable = ({ data, sort, handleSort, tradesRequestsLoading }) => {
  if (tradesRequestsLoading) {
    return (
      <TablePreloader
        rowTimes={5}
        tdHeight={14}
        tdWidth={[32, 85, 39, 53, 77, 77, 52, 105, 81, 73, 93]}
      />
    );
  }

  return (
    <StyledTable
      className='mb-0'
      paddingsThead='0.5rem 1rem'
      backgroundThead='#2A2C42'
    >
      <SortTableHead
        handleSorting={handleSort}
        sort={sort}
        theadItems={REQUESTS_TRADES_TABLE_HEAD}
      />
      <tbody className='font-sm'>
        {data?.items?.map(item => (
          <tr key={item.tradeId} className='cursor-pointer'>
            <td>
              <BadgeBuySell type={item?.type} />
            </td>
            <td>
              <div className='d-flex align-items-center text-nowrap'>
                <TableAmount amount={1}>
                  {formatterToNull(item.market.base)}
                </TableAmount>
                <TableCurrency className='mx-1'>/</TableCurrency>
                <TableAmount amount={1}>
                  {formatterToNull(item.market.quote)}
                </TableAmount>
              </div>
            </td>
            <td>
              <TableAmount amount={item.tradeId}>
                {formatterToNull(item.tradeId)}
              </TableAmount>
            </td>
            <td>
              <TableAmount amount={item.orderNumber}>
                {formatterToNull(item.orderNumber)}
              </TableAmount>
            </td>
            <td>
              <p className='mb-0 text-white'>{item.matchRole || "-"}</p>
            </td>
            <td>
              <p className='mb-0 text-white'>{item.tradeType || "-"}</p>
            </td>
            <td>
              <TableAmount amount={item.rate}>
                {formatterToNull(item.rate)}
              </TableAmount>
            </td>
            <td>
              <div className='d-flex align-items-center'>
                <TableAmount amount={item.amount.amount}>
                  {formatterToNull(item.amount.amount)}
                </TableAmount>
                <TableCurrency>{item.amount.currency}</TableCurrency>
              </div>
            </td>
            <td>
              <div className='d-flex align-items-center'>
                <TableAmount amount={item.quantity.amount}>
                  {formatterToNull(item.quantity.amount)}
                </TableAmount>
                <TableCurrency>{item.quantity.currency}</TableCurrency>
              </div>
            </td>
            <td>
              <div className='d-flex align-items-center'>
                <TableAmount amount={item.fee.amount}>
                  {formatterToNull(item.fee.amount)}
                </TableAmount>
                <TableCurrency>{item.fee.currency}</TableCurrency>
              </div>
            </td>
            <td>
              <div className='d-flex align-items-center'>
                <TableCurrency>
                  {moment(item.createdAt).format("DD.MM.YY")}
                </TableCurrency>
                <TableAmount amount={1} className='ml-1'>
                  {moment(item.createdAt).format("HH:mm")}
                </TableAmount>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default TradesTable;
