import React from "react";
import { ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";
import styled from "styled-components";

import Button from "shared/ui/buttons/Button";
import { ReactComponent as Error } from "assets/icons/general/error-bg.svg";

const ConfirmModal = ({
  onSetModalIsOpen,
  modalIsOpen,
  handleSkip,
  isLoading,
}) => {
  return (
    <StyledModal
      isOpen={modalIsOpen}
      toggle={() => onSetModalIsOpen(!modalIsOpen)}
      modalClassName='modal-black'
    >
      <ModalHeader className='d-flex justify-content-center pt-5 px-3'>
        <Error />
      </ModalHeader>
      <ModalBody className='px-3 pb-0'>
        <h2 className='mb-2 text-center'>Are you sure you want to skip?</h2>
        <p className='font-weight-300 text-center m-0'>
          You are not able to use Darkbot without connecting your exchange
          account
        </p>
      </ModalBody>
      <Footer className='d-flex flex-nowrap px-3 pb-3'>
        <Button
          outline
          onClick={() => onSetModalIsOpen(false)}
          className='w-100 mr-2'
        >
          Cancel
        </Button>
        <Button
          loading={isLoading}
          color='blue'
          className='w-100 ml-2'
          onClick={() => handleSkip("/")}
        >
          Go to dashboard
        </Button>
      </Footer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  max-width: fit-content !important;
  p {
    line-height: 1.5 !important;
  }

  .modal-content {
    width: 100%;
  }

  @media (min-width: 768px) {
    .modal-content {
      width: 384px;
    }
  }
`;

const Footer = styled(ModalFooter)`
  padding-top: 2.5rem;
`;

export default ConfirmModal;
