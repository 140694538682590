import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader } from "reactstrap";

import { INDIVIDUALS_STATUS_OPTIONS } from "models/filters";
import { getGeneticIndividuals } from "store/bots/actions";
import IndividualsWidgetPreloader from "../preloaders/IndividualsWidgetPreloader";
import usePagination from "hooks/usePagination";
import useFilterAndSorting from "hooks/useFilterAndSorting";
import { ReactComponent as Icon } from "assets/icons/general/boxIcon.svg";
import IndividualsFilters from "./IndividualsFilters";
import IndividualsTable from "./IndividualsTable";
import { ColorfulBar, PaginationPanel } from "shared/ui";

const IndividualsWidget = ({
  loading,
  geneticId,
  individuals,
  individualsStatistic,
}) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { filter, sort, handleSorting, handleFilter } = useFilterAndSorting(
    `/genetic-algorithm/${geneticId}`,
  );
  const { handlePaginate } = usePagination(`/genetic-algorithm/${geneticId}`);

  const individualsCount =
    individualsStatistic &&
    Object.values(individualsStatistic).reduce((acc, item) => item + acc, 0);
  const individualsLength =
    individualsStatistic &&
    Object.keys(individualsStatistic).filter(
      item => individualsStatistic[item] > 0,
    ).length;
  const total = useSelector(state => state.bots.geneticIndividuals.data.total);

  useEffect(() => {
    dispatch(getGeneticIndividuals(geneticId, search));
  }, [dispatch, geneticId, search]);

  if (loading) return <IndividualsWidgetPreloader />;

  return (
    <Card className='mr-2 mb-4'>
      <CardHeader className='d-flex flex-column flex-md-row p-4 pb-3 align-items-start align-items-md-center justify-content-between'>
        <span className='d-inline-block'>
          <Icon className='mr-2' />
        </span>
        <p className='font-md mb-0 font-weight-500 text-white'>Individuals</p>
        <div className='w-100 mt-2 mt-md-0 ml-md-3 d-flex flex-row align-items-center'>
          {individualsStatistic &&
            Object.keys(individualsStatistic)
              .filter(item => individualsStatistic[item] > 0)
              .sort(
                (item1, item2) =>
                  individualsStatistic[item2] - individualsStatistic[item1],
              )
              .map((item, index) => (
                <ColorfulBar
                  key={item}
                  title={item}
                  value={individualsStatistic[item]}
                  itemsLength={individualsLength}
                  itemsWithValueLength={individualsCount}
                  index={index}
                  tooltipText='Individuals'
                  withSpacing
                  color={
                    INDIVIDUALS_STATUS_OPTIONS.find(
                      ({ value }) => value === item,
                    ).color
                  }
                />
              ))}
        </div>
      </CardHeader>
      <CardBody className='p-0'>
        <IndividualsFilters
          onHandleFilter={handleFilter}
          filter={filter}
          className='px-4 pb-3'
        />
        <IndividualsTable
          sort={sort}
          individuals={individuals}
          onSorting={handleSorting}
        />
        <div className={`${total >= 5 ? "mt-3" : ""}`}>
          <PaginationPanel
            activePage={individuals.page}
            totalItemsCount={individuals.total}
            onChange={page => handlePaginate(page)}
            totalPagesView={5}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default IndividualsWidget;
