import {
  cancelOpenAgreement,
  completedOpenAgreement,
  restartOpenAgreement,
} from "store/agreements/actions";

export const AGREEMENTS_CONTENT = {
  details: {
    widgets: {
      dangerZone: {
        title: "Danger zone",
        subtitle:
          "The purchased assets will be immediately sold regardless of the current profit, as a result of which the deal will be completed.",
        actions: {
          complete: {
            name: "Complete",
            title: "Confirm Agreement Completion",
            warning:
              "Before confirming, please ensure that all terms of the agreement have been met. This action is irreversible.",
            actionFunction: completedOpenAgreement,
          },
          restart: {
            name: "Restart",
            title: "Please confirm the agreement reset",
            warning:
              "Before resetting, please ensure there are no purchased coins associated with this agreement. This action is irreversible.",
            actionFunction: restartOpenAgreement,
          },
          cancel: {
            name: "Cancel",
            title: "Confirm Agreement Cancellation",
            warning:
              "Before confirming, please double-check that you have no purchased coins associated with this agreement. This action is irreversible.",
            actionFunction: cancelOpenAgreement,
          },
        },
      },
    },
  },
};
