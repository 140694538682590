import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { enableBot, disableBot } from "store/bots/actions";
import ToggleSwitch from "../ToggleSwitch";
import { addSnackBar } from "store/snackbar/actions";

const BotsListToggle = ({ status, id, isList }) => {
  const dispatch = useDispatch();

  const initialCheck = status === "running";
  const [check, setCheck] = useState(initialCheck);
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleBot = async (value, e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
    setCheck(value);
    try {
      const res = value
        ? await dispatch(enableBot(id, isList))
        : await dispatch(disableBot(id, isList));
      if (!res.data.errors) dispatch(addSnackBar("success", res.data.message));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ToggleSwitch
      onChange={(value, e) => handleToggleBot(value, e)}
      checked={check}
      height={18}
      width={36}
      onColor='#0AAF95'
      offColor='#3A3D5A'
      onHandleColor='#FFFFFF'
      offHandleColor='#5E6392'
      className='mt-1'
      activeBoxShadow='none'
      disabled={isLoading}
    />
  );
};

export default BotsListToggle;
