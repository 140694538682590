import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { formatterToNull } from "helpers/valuesFormatters";
import { STATUS_COLORS } from "../../../../models";
import Icon from "shared/ui/Icon";
import { StyledStatusLabel } from "shared/styled/StyledStatusLabel";

const TableRow = ({ item }) => {
  const getFormattedDate = createdAt => {
    const formattedDate = createdAt.split(" ");
    return (
      <>
        <span className='text-white font-weight-500'>
          {formattedDate[1] || ""}
        </span>{" "}
        <span className='text-white opacity-35'>{formattedDate[0] || ""}</span>
      </>
    );
  };

  const handleLink = id => `/rebalance/${id}`;

  return (
    <tr className='cursor-pointer'>
      <td>
        <TableRow.Link to={handleLink(item.id)} className='mr-2'>
          <div className='d-flex align-items-center'>
            <Icon
              code={item.investment?.asset}
              type='cryptocurrency'
              size='thumb'
            />
            <span className='ml-2 font-weight-500 text-white'>
              {item.investment?.asset}
            </span>
          </div>
        </TableRow.Link>
      </td>
      <td>
        <TableRow.Link to={handleLink(item.id)} className='mr-2'>
          <span className='font-weight-500 text-white px-2'>
            {formatterToNull(item.total)}{" "}
            <span className='font-weight-300 text-white opacity-35'>
              {item.investment?.asset}
            </span>
          </span>
        </TableRow.Link>
      </td>
      <td>
        <TableRow.Link to={handleLink(item.id)} className='mr-2'>
          <span className='text-white font-weight-500'>
            {`${formatterToNull(item.percent)} %`}
          </span>
        </TableRow.Link>
      </td>
      <td>
        <TableRow.Link to={handleLink(item.id)} className='mr-2'>
          {getFormattedDate(item.createdAt)}
        </TableRow.Link>
      </td>
      <td className='text-right'>
        <TableRow.Link to={handleLink(item.id)}>
          <StyledStatusLabel color={STATUS_COLORS[item.status]}>
            {item.status || ""}
          </StyledStatusLabel>
        </TableRow.Link>
      </td>
    </tr>
  );
};

TableRow.Link = styled(Link)`
  display: block;
  white-space: nowrap;
  padding: 1rem 0;
`;

export default TableRow;
