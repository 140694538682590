export const DASHBOARD_CONTENT = {
  widgets: {
    miniWidgets: {
      activeDeals: {
        title: "Running deals",
      },
      invested: {
        title: "Invested",
      },
    },
    balanceHistory: {
      title: "Balance history",
      emptyState: {
        isNoData: {
          title: "There is not enough data to display",
          descriptionBefore:
            "Keep calm, this chart will be displayed here as soon as this bot will create some deals.",
        },
        connectExchange: {
          title: "You have no connected exchanges yet",
          descriptionBefor: "Please",
          link: "connect",
          descriptionAfter:
            "at least one exchange to have an ability to track your balance",
        },
      },
    },
    assetAllocation: {
      title: "Asset allocation",
      checkbox: "Hide empty",
      button: "Connect Exchange",
      errorState: {
        title: "Oops, something went wrong!",
        descriptionBefore:
          "Sorry! It seems there is a problem on our end. Please try again in a few moments!",
      },
      emptyStateHideEmpty: {
        title: "No results for your search",
        descriptionBefore:
          "You may try adjusting the filters or try again later",
      },
      emptyNoConnected: {
        title: "You have no connected exchanges",
        descriptionBefore: "Please",
        link: "connect",
        descriptionAfter:
          "at least one exchange to monitor the distribution of your investment capital.",
      },
    },
    botsStatistics: {
      title: "Bots statistics",
      link: "All bots",
      leftLabel: "Bots",
      rightLabel: "Total return",
      empty: {
        title: "Your bots haven’t completed any deals at the moment",
        descriptionBefore:
          "As soon as your bots complete some deals, you’ll see those bots here.",
      },
    },
  },
};
