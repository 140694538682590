import { Card, CardBody } from "reactstrap";

import { MarketInfoShare } from "../StyledCardBody";
import HeaderPreloader from "shared/ui/OrderBook/preloaders/HeaderPreloader";
import { setColorClass } from "helpers/valuesFormatters";
import { getCoinName } from "exchanges/utils";
import { MarketInfoItem } from "./ui";
import {
  ExchangeHeaderCoinStyled,
  ExchangeHeaderMarketStyled,
  ExchangeHeaderPriceStyled,
  ExchangeHeaderWrapperStyled,
} from "./index.styled";
import { Icon } from "shared/ui";

const ExchangeMarketHeader = ({
  exchange,
  ticker,
  isWebsocketOpen,
  base,
  quote,
}) => {
  if (!isWebsocketOpen || !ticker) return <HeaderPreloader />;

  return (
    <Card className='mb-3'>
      <CardBody className='p-4'>
        <ExchangeHeaderWrapperStyled className='d-flex flex-column align-items-start flex-lg-row justify-content-lg-between'>
          <div className='d-flex flex-wrap flex-md-nowrap'>
            <Icon type='cryptocurrency' size='xs' code={base} />

            <div className='d-flex flex-column my-auto ml-3'>
              <ExchangeHeaderMarketStyled className='mb-1 text-white font-weight-bold text-nowrap'>
                {`${base} / ${quote}`}
              </ExchangeHeaderMarketStyled>
              <ExchangeHeaderCoinStyled className='mb-0 opacity-75 font-weight-light '>
                {getCoinName(base)}
              </ExchangeHeaderCoinStyled>
            </div>

            <MarketInfoShare className='mx-2 mx-md-3' />

            <div className='d-flex flex-column mr-5 mt-3 mt-md-0'>
              <ExchangeHeaderPriceStyled className='value'>
                {ticker.close || 0}
              </ExchangeHeaderPriceStyled>
              <p
                style={{ whiteSpace: "nowrap" }}
                className={`${setColorClass(
                  ticker.dailyChange,
                  "text-success",
                )} value font-weight-bold `}
              >
                {ticker.dailyChange > 0 && "+"}
                {ticker.dailyChange && exchange === "poloniex"
                  ? ticker.dailyChange * 100
                  : ticker.dailyChange}{" "}
                %
              </p>
            </div>
          </div>

          <ExchangeHeaderWrapperStyled className='h-100 d-flex flex-wrap flex-lg-nowrap mb-2 mb-lg-0'>
            <ExchangeHeaderWrapperStyled className='d-flex flex-wrap'>
              <MarketInfoItem title='24h Open' value={ticker.open} />
              <MarketInfoItem title='24h High' value={ticker.high} />
              <MarketInfoItem title='24h Low' value={ticker.low} />
            </ExchangeHeaderWrapperStyled>

            <ExchangeHeaderWrapperStyled className='d-flex flex-wrap'>
              <MarketInfoItem
                title={`24h Volume (${base})`}
                value={ticker.quantity}
              />

              <MarketInfoItem
                title={`24h Volume (${quote})`}
                value={ticker.amount}
              />
            </ExchangeHeaderWrapperStyled>
          </ExchangeHeaderWrapperStyled>
        </ExchangeHeaderWrapperStyled>
      </CardBody>
    </Card>
  );
};

export default ExchangeMarketHeader;
