import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import qs from "qs";

import { fetchDeals, getDealsMarkets, resetDeals } from "store/deals/actions";
import Filters from "./ui/Filters";
import ListLoading from "./ui/preloaders/ListLoading";
import Deal from "./ui/Deal";
import EmptyFiltersListingState from "../../shared/ui/state/EmptyFiltersListingState";
import usePagination from "hooks/usePagination";
import useRequestCancellation from "hooks/useRequestCancellation";
import { dealsListSelector } from "store/deals/selectors";
import { DEALS_CONTENT } from "models/constans/deals/content";
import {
  EmptyDataList,
  Error,
  PaginationPanel,
  RefreshButton,
} from "shared/ui";

const DealsList = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const firstRender = useRef(true);

  const { handlePaginate } = usePagination("/deals", true);
  const { useAbortableEffect } = useRequestCancellation();

  const {
    loading,
    error,
    data: { items, total, page },
  } = useSelector(dealsListSelector);

  useEffect(() => {
    dispatch(getDealsMarkets());
  }, [dispatch]);

  const getFilters = (search, savedSearch) => {
    if (firstRender.current && savedSearch) return savedSearch.filter;
    if (firstRender.current && !search.filter?.status?.in)
      return {
        status: { in: ["running", "completed"] },
      };
    if (search.filter?.status?.in)
      return {
        status: { in: search.filter?.status?.in },
      };
    return {};
  };

  const getSort = (sort, savedSearch) => {
    if (firstRender.current && savedSearch) return savedSearch.sort;
    if (firstRender.current && !sort) return { order: "DESC", by: "createdAt" };
    return sort || {};
  };

  const getSearch = () => {
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    const savedSearch = JSON.parse(localStorage.getItem("dealsFilters"));
    return {
      ...search,
      filter: {
        ...search.filter,
        ...getFilters(search, savedSearch),
      },
      sort: getSort(search.sort, savedSearch),
      page: search.page,
    };
  };

  useAbortableEffect(
    signal => {
      const search = getSearch();
      const newSearch = qs.stringify(search);
      history.push({ pathname: "/deals", search: newSearch });
      localStorage.setItem("dealsFilters", JSON.stringify(search));
      if (firstRender.current && !location.search && !newSearch)
        dispatch(fetchDeals());
      if (
        !firstRender.current ||
        (firstRender.current && location.search === `?${newSearch}`)
      ) {
        dispatch(fetchDeals(location.search, signal));
      }
      firstRender.current = false;
      return () => dispatch(resetDeals());
    },
    [location.search, history, dispatch],
    true,
  );

  const onHandleRefresh = () => dispatch(fetchDeals(location.search));

  const renderState = () => {
    if (loading) return <ListLoading />;
    if (location.search?.includes("filter") && !items?.length)
      return <EmptyFiltersListingState />;
    if (!items?.length && !loading) {
      return (
        <EmptyDataList
          title={DEALS_CONTENT.list.emptyState.title}
          description={DEALS_CONTENT.list.emptyState.description}
        />
      );
    }
    if (error) return <Error title={DEALS_CONTENT.list.errorState.title} />;
  };

  useEffect(() => {
    window.analytics.page("/deals");
  }, []);

  return (
    <>
      <div
        className='d-flex justify-content-between mt-4 mb-3'
        style={{ height: "40.89px", padding: "20px 0px !important" }}
      >
        <h1 className='mb-0 text-white font-weight-500'>
          {DEALS_CONTENT.list.header.title}
        </h1>
        <RefreshButton loading={loading} onHandleRefresh={onHandleRefresh} />
      </div>
      <Filters loading={loading} />
      {renderState()}
      {items?.length &&
        items.map((deal, index) => (
          <Deal
            key={deal.id}
            data={deal}
            index={index}
            content={DEALS_CONTENT.list.deal}
          />
        ))}
      <div className='pt-3'>
        <PaginationPanel
          activePage={page}
          totalItemsCount={total}
          onChange={page => handlePaginate(page)}
        />
      </div>
    </>
  );
};

export default DealsList;
