import React from "react";
import { Label } from "reactstrap";
import { useSelector } from "react-redux";

import { SelectInput } from "shared/ui";
import CandlestickChart from "../CandlestickChart";
import { marketsSelect } from "store/markets/selectors";

const IndicatorFormChartBlock = ({
  loading,
  error,
  chartData,
  setSelectedMarket,
  wizardMarkets,
}) => {
  const { data: marketsSelectData } = useSelector(marketsSelect);

  return (
    <div className='d-flex flex-column w-100'>
      <Label className='mb-2 text-white-35 font-weight-300'>
        Select market
      </Label>
      <SelectInput
        isClearable
        className='w-100 mb-1'
        placeholder='Market'
        onChange={values => {
          setSelectedMarket(values?.icon?.base);
        }}
        options={
          marketsSelectData?.length
            ? marketsSelectData?.map(coin => {
                return {
                  value: `${coin} - ${wizardMarkets?.investment?.asset}`,
                  label: `${coin}-${wizardMarkets?.investment?.asset}`,
                  icon: {
                    type: "cryptocurrency",
                    size: "selectSize",
                    code: coin,
                    base: coin,
                  },
                };
              })
            : []
        }
      />
      <CandlestickChart
        loading={loading}
        error={error}
        candlestickChart={chartData?.candlestickChart}
        indicatorSignals={chartData?.indicatorSignals}
      />
    </div>
  );
};

export default IndicatorFormChartBlock;
