import React, { useState, useEffect } from "react";
import qs from "qs";
import { Card, CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { getMarkets, resetMarkets } from "store/markets/actions";
import {
  fetchCredentialStatistics,
  resetCredentialStatistics,
} from "store/credentials/actions";
import MarketStatisticsPreloader from "./ui/MarketStatisticsPreLoad";
import MarketStatisticsFilters from "./ui/MarketStatisticsFilters";
import MarketTable from "./ui/MarketsStatisticsTable";
import PaginationPanel from "shared/ui/PaginationPanel";
import EmptyTables from "../../../../../states/EmptyTables";
import { paginationScrollUp } from "helpers/paginationScrollUp";
import useRequestCancellation from "hooks/useRequestCancellation";
import { selectCredentialStatistics } from "store/credentials/selectors";
import { ReactComponent as Market } from "assets/icons/credential/market.svg";
import { selectMarkets } from "store/markets/selectors";

const MarketStatistics = ({ id, loadingPage }) => {
  const [emptyFilter, setEmptyFilter] = useState(null);
  const [initialLoading, setInitialLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { useAbortableEffect } = useRequestCancellation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { loading, data } = useSelector(selectCredentialStatistics);
  const markets = useSelector(selectMarkets);
  const credential = useSelector(state => state.credentials.item.data);

  const handlePaginate = page => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({ ...search, page }),
    });
    paginationScrollUp();
  };

  useAbortableEffect(
    signal => {
      dispatch(fetchCredentialStatistics(id, location.search, signal)).finally(
        () => setInitialLoading(false),
      );
      return () => dispatch(resetCredentialStatistics());
    },
    [id, dispatch, location.search],
    true,
  );

  useEffect(() => {
    if (credential.exchange) dispatch(getMarkets(credential.exchange));
    return () => dispatch(resetMarkets());
  }, [credential, dispatch]);

  return (
    <Card>
      <MarketStatistics.Wrapper className='d-flex flex-column flex-sm-row justify-content-between pt-3 pr-3 pb-0 pl-4 mb-4'>
        <div className='d-flex align-items-center mb-3 mb-md-0'>
          <Market className='mb-10' />
          <p className='mb-0 text-white font-weight-500 ml-2 text-nowrap'>
            Market statistics
          </p>
        </div>
        <MarketStatisticsFilters
          history={history}
          credentialId={id}
          markets={markets}
          setEmptyFilter={setEmptyFilter}
          loading={loading}
        />
      </MarketStatistics.Wrapper>
      {loadingPage || loading || initialLoading ? (
        <MarketStatisticsPreloader />
      ) : data?.items?.length ? (
        <>
          <CardBody className='pl-0 pt-0'>
            <MarketTable data={data.items} />
          </CardBody>
          <div className='pt-3'>
            <PaginationPanel
              activePage={data.page}
              totalItemsCount={data.total}
              onChange={page => handlePaginate(page)}
            />
          </div>
        </>
      ) : (
        <EmptyTables
          title='There are no available market statistics at the moment'
          description='It will be here once you make a trades on the exchange'
          emptyFilter={emptyFilter}
        />
      )}
    </Card>
  );
};

MarketStatistics.Wrapper = styled.div`
  svg {
    width: 17px;
    height: 13.276px;
    & path {
      stroke: var(--info);
    }
  }
`;

export default MarketStatistics;
