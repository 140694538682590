import React from "react";

const TemplateDetailsMartingale = ({ tradeSettings }) => (
  <>
    <p className='text-white font-weight-500'>Average down strategy</p>
    <div className='d-flex justify-content-between'>
      <p className='text-white-75 font-sm m-0'>Maximum trade requests</p>
      <p className='text-white font-weight-500 m-0'>
        {tradeSettings.maxTradeRequests || 0}
      </p>
    </div>
    <div className='d-flex justify-content-between'>
      <p className='text-white-75 font-sm m-0'>Price Deviation</p>
      <p className='text-white font-weight-500 m-0'>
        {tradeSettings.maxTradeRequests || 0}
      </p>
    </div>
    <div className='d-flex justify-content-between'>
      <p
        className={`text-white-75 font-sm ${tradeSettings.pivotPoint?.valid ? "mb-4" : ""}`}
      >
        Martingale Percent
      </p>
      <p className='text-white font-weight-500 m-0'>
        {`${tradeSettings.martingalePercent}%`}
      </p>
    </div>
  </>
);

export default TemplateDetailsMartingale;
