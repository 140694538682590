import createAction from "store/thunkFactory";
import {
  EDIT_CANDLE,
  RESET_CANDLE,
  SET_CANDLE_NATURE,
  SET_CANDLE_PATTERN,
  SET_CANDLE_PERCENT,
  SET_CANDLE_SIGNAL,
} from "./constants";

export const getCandlePatterns = signal =>
  createAction("CANDLE_PATTERNS", fetch => {
    return fetch.get("/app/analysis/technical/candle-patterns", { signal });
  });

export const setCandleConditions = candle => ({
  type: EDIT_CANDLE,
  payload: candle,
});

export const setCandleNature = type => ({
  type: SET_CANDLE_NATURE,
  payload: type,
});

export const setCandleSignal = signal => ({
  type: SET_CANDLE_SIGNAL,
  payload: signal,
});

export const setCandlePercent = percent => ({
  type: SET_CANDLE_PERCENT,
  payload: percent,
});

export const setCandlePattern = patternData => ({
  type: SET_CANDLE_PATTERN,
  payload: patternData,
});

export const resetCandle = () => ({
  type: RESET_CANDLE,
});
