import React from "react";
import { ReactComponent as Empty } from "assets/icons/general/empty-state-planet2.svg";

const RunningEmptyState = ({ title, description }) => {
  return (
    <div className='d-flex flex-column align-items-center p-5 text-center'>
      <Empty />
      <p className='mb-1 mt-4 pt-3 text-white font-weight-500'>{title}</p>
      <p className='mb-0 text-white-75 font-sm font-weight-300'>
        {description}
      </p>
    </div>
  );
};

export default RunningEmptyState;
