import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Loading = ({ size = 35, color = "#5bc0de", className = "" }) => (
  <div
    className={`d-flex justify-content-center align-items-center ${className}`}
  >
    <ClipLoader color={color} size={size} />{" "}
  </div>
);

export default Loading;
