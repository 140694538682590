import { useEffect, useLayoutEffect, useState } from "react";

import { sortTickers, filterUniqueByProp } from "./utils";
import exchangesConfig from "./exchangesConfig";

const useTickers = (exchange, asset, search) => {
  const [assets, setAssets] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [filteredTickers, setFilteredTickers] = useState([]);
  const [allTickers, setAllTickers] = useState([]);
  const [sort, setSort] = useState({});
  const [currentTicker, setCurrentTicker] = useState(null);
  const { getTickers } = exchangesConfig[exchange];

  const handleGetTickers = initialLoading => {
    if (initialLoading) {
      setIsLoading(true);
    } else {
      setIsRefreshing(true);
    }

    getTickers()
      .then(data => {
        if (currentTicker) setCurrentTicker(null);
        if (error) setError(null);
        setAssets(filterUniqueByProp(data, "quote"));
        setAllTickers(data);
      })
      .catch(e => setError(e))
      .finally(() =>
        initialLoading ? setIsLoading(false) : setIsRefreshing(false),
      );
  };

  useLayoutEffect(() => {
    if (markets.length) setMarkets([]);
    if (filteredTickers.length) setFilteredTickers([]);
    setAssets([]);
    handleGetTickers(true);
  }, [exchange]);

  useEffect(() => {
    if (filteredTickers.length)
      setMarkets(filteredTickers.map(({ symbol }) => symbol));
  }, [filteredTickers]);

  useEffect(() => {
    setFilteredTickers(allTickers.filter(item => item.market.quote === asset));
  }, [allTickers, asset]);

  const handleSortTickers = (sortBy, sortOrder = "asc") => {
    setFilteredTickers(prev => sortTickers(prev, sortBy, sortOrder));
  };

  useLayoutEffect(() => {
    if (allTickers.length && !currentTicker) setCurrentTicker(allTickers[0]);
  }, [allTickers, currentTicker]);

  useLayoutEffect(() => {
    if (allTickers.length && currentTicker?.close) {
      const intervalId = setInterval(() => {
        setCurrentTicker(prevTicker => {
          let randomIndex = Math.floor(Math.random() * allTickers.length);
          while (allTickers[randomIndex] === prevTicker) {
            randomIndex = Math.floor(Math.random() * allTickers.length);
          }
          return allTickers[randomIndex];
        });
      }, 4000);

      return () => clearInterval(intervalId);
    }
  }, [allTickers.length, currentTicker?.close]);

  return {
    assets,
    handleSortTickers,
    refresh: handleGetTickers,
    isLoading,
    isRefreshing,
    tickers: sortTickers(
      filteredTickers.filter(item =>
        item.market.base.toLowerCase().includes(search.toLowerCase()),
      ),
      sort.by,
      sort.order,
    ),
    setSort,
    sort,
    allTickers,
    currentTicker,
    error,
  };
};

export default useTickers;
