import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { CreateCredentialsModal } from "shared/ui";

const EmptyDashboardWidgets = ({
  Img,
  title,
  descriptionBefore,
  link,
  descriptionAfter,
  redirect,
  isNoDataToShow,
  className,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  const handleRedirect = () => {
    if (redirect) {
      history.push(redirect);
    }
    if (!redirect) {
      setIsModalOpen(true);
    }
  };

  return (
    <EmptyDashboardWidgets.Wrapper className={className}>
      {isNoDataToShow ? (
        <>
          <EmptyDashboardWidgets.Title>{title}</EmptyDashboardWidgets.Title>
          <EmptyDashboardWidgets.Description>
            {descriptionBefore}
          </EmptyDashboardWidgets.Description>
        </>
      ) : (
        <>
          {Img ? <Img /> : null}
          <EmptyDashboardWidgets.Title>{title}</EmptyDashboardWidgets.Title>
          <EmptyDashboardWidgets.Description>
            {descriptionBefore}
            <EmptyDashboardWidgets.Created onClick={() => handleRedirect()}>
              {link}
            </EmptyDashboardWidgets.Created>
            {descriptionAfter}
          </EmptyDashboardWidgets.Description>
          <CreateCredentialsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        </>
      )}
    </EmptyDashboardWidgets.Wrapper>
  );
};

EmptyDashboardWidgets.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

EmptyDashboardWidgets.Title = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff !important;
  margin-top: 40px;
`;

EmptyDashboardWidgets.Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75) !important;
  text-align: center;
  padding: 0 24px;
`;

EmptyDashboardWidgets.Created = styled.span`
  color: rgba(47, 128, 237, 1) !important;
  font-size: 16px;
  font-weight: 400;
  margin: 0 4px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: rgba(47, 128, 237, 0.8) !important;
  }
`;

export default EmptyDashboardWidgets;
