import React from "react";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";

const PivotPointMarketPreloader = () => {
  return (
    <div className='d-flex align-items-center justify-content-between mt-4 p-4'>
      <div className='d-flex align-items-center'>
        <SkeletonPreloader width={20} height={20} circle />
        <div className='d-flex flex-column ml-3'>
          <SkeletonPreloader width={121} height={16} />
          <SkeletonPreloader width={81} height={10} className='mt-2' />
        </div>
      </div>

      <div className='d-flex'>
        <div className='d-flex flex-column'>
          <SkeletonPreloader width={365} height={8} />
          <div className='d-flex align-items-center justify-content-between mt-2'>
            <SkeletonPreloader width={42} height={10} />
            <SkeletonPreloader width={19} height={10} />
            <SkeletonPreloader width={42} height={10} />
          </div>
        </div>

        <SkeletonPreloader
          width={43}
          height={26}
          borderRadius='4px'
          className='ml-4'
        />
      </div>
    </div>
  );
};

export default PivotPointMarketPreloader;
