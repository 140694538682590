import React from "react";
import { Card } from "reactstrap";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import { ReactComponent as Chart } from "assets/icons/investments/balance-statistics-chart-preload.svg";

const BalanceStatisticsChartPreloader = () => {
  return (
    <Card style={{ padding: "24px" }}>
      <div className='d-flex align-items-center mb-3'>
        <SkeletonPreloader circle={true} height={16} width={16} />
        <SkeletonPreloader height={12} width={60} className='ml-2' />
      </div>
      <Chart width='100%' />
      <div className='d-flex align-items-center justify-content-center mt-3'>
        <span style={{ borderBottom: "1px solid #535462", width: "31px" }} />
        <SkeletonPreloader height={12} width={94} className='ml-2 mr-4' />
        <span style={{ borderBottom: "1px dashed #535462", width: "31px" }} />
        <SkeletonPreloader height={12} width={94} className='ml-2 mr-4' />
        <span style={{ borderBottom: "1px solid #535462", width: "31px" }} />
        <SkeletonPreloader height={12} width={94} className='ml-2 mr-2' />
      </div>
    </Card>
  );
};

export default BalanceStatisticsChartPreloader;
