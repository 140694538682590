export const freePlan = {
  name: "Free",
  description: "Free plan",
  features: [
    "Limited automatic trading",
    "Telegram bot notifications",
    "Balance history tracking",
  ],
  prices: [
    {
      currency: "USD",
      amount: 0,
      interval: "month",
    },
    {
      currency: "USD",
      amount: 0,
      interval: "year",
    },
  ],
};
