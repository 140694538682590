import React, { useState } from "react";
import { FormGroup } from "reactstrap";

import { ExchangesSelect } from "shared/ui";

const Filters = ({ handleFilter, loading }) => {
  const [filters, setFilters] = useState();

  const handleChange = values => {
    const filter = {
      "credentials.exchange": { in: values ? values.map(e => e.value) : "" },
    };
    setFilters(filter);
    handleFilter(filter);
  };

  return (
    <FormGroup className='mb-4 w-100 mt-2'>
      <ExchangesSelect
        isClearable
        placeholder='Exchange'
        name='exchange'
        value={filters?.["credentials.exchange"]?.in}
        isMulti
        onChange={values => handleChange(values)}
        isDisabled={loading}
        width='100%'
      />
    </FormGroup>
  );
};

export default Filters;
