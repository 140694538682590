import React from "react";

import { ReactComponent as CandleConditionIcon } from "assets/icons/bot/candle-condition-icon.svg";
import { HelpTooltip } from "shared/ui";

const CandleConditionHeader = () => {
  return (
    <div className='mb-3'>
      <div className='d-flex align-items-center mb-2'>
        <CandleConditionIcon className='my-auto mr-2' />
        <p className='mb-0 font-weight-500 text-white'>Candle conditions</p>
      </div>
      <div className='d-flex'>
        <p className='mb-0 text-white-75 mr-2'>
          This feature allows deal to buy on the dumps and sell on the pumps
        </p>
        <HelpTooltip className='my-auto'>
          <span>
            You can create a maximum of 2 candle conditions, with one set for a
            buy signal and the other for a sell signal.
          </span>
        </HelpTooltip>
      </div>
    </div>
  );
};

export default CandleConditionHeader;
