import React from "react";
import styled from "styled-components";
import { times } from "lodash";

const StepsNavigation = ({ currentStep, handleSetCurrentStep }) => {
  const getStepClassName = index => {
    if (index < currentStep) return "done";
    if (index === currentStep) return "active";
    return "";
  };

  return (
    <div className='d-flex align-items-center'>
      {times(4, i => (
        <StepButton
          className={getStepClassName(i + 1)}
          key={i}
          onClick={() => handleSetCurrentStep(i + 1)}
        />
      ))}
    </div>
  );
};

const StepButton = styled.span`
  display: inline-block
  width: 64px;
  height: 6px;
  background-color: var(--dusk-steel);
  margin: 0 0.25rem;
  cursor: pointer;

  &.active {
    background-color: #FFF;
  }

  &.done {
    background-color: var(--info);
  }

  &:first-child {
    border-radius: 8px 0px 0px 8px;
  }

  &:last-child {
    border-radius: 0px 8px 8px 0px;
  }
`;

export default StepsNavigation;
