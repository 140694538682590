import React from "react";
import styled from "styled-components";

import Investments from "../wizard/Investments/Investments";
import Markets from "../wizard/Markets/Markets";
import Wizard from "../wizard/Wizard";

import { useCreateBot } from "shared/entities/bot/model/useCreateBot";
import ConfigurationWrapper from "./ConfigurationWrapper";

const CreateBot = ({ location }) => {
  const {
    isSubmit,
    setIsSubmit,
    currentStep,
    setCurrentStep,
    highestStep,
    setHighestStep,
    loading,
    setLoading,
    credentialId,
    setCredentialId,
  } = useCreateBot();

  return (
    <CreateBot.Wrapper>
      <div className='my-4'>
        <h1 className='font-weight-500 mb-2 text-white'>Create a bot</h1>
        <p className='mb-0 text-white-35 font-md'>
          Create your bot and connect it to your credentials for our system to
          start the auto-trading
        </p>
      </div>
      <Wizard
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        highestStep={highestStep}
        setHighestStep={setHighestStep}
        isSubmit={isSubmit}
        steps={[
          {
            component: Investments,
            name: "Credentials",
            finishedText: true,
            props: {
              location,
              setCredentialId,
            },
          },
          {
            component: Markets,
            name: "Market",
            finishedText: true,
            props: {
              location,
              setLoading,
              currentStep,
              credentialId,
            },
          },
          {
            component: ConfigurationWrapper,
            name: "Configuration",
            finishedText: true,
            props: {
              setIsSubmit,
              currentStep,
              loadingWizard: loading,
            },
          },
        ]}
      />
    </CreateBot.Wrapper>
  );
};

CreateBot.Wrapper = styled.div`
  .create-bot__intro {
    h3 {
      font-size: 1.5rem;
    }
  }
  padding: 0 0 0 10px;
`;

export default CreateBot;
