import * as Yup from "yup";

export const PASSWORD_FIELDS = [
  {
    name: "current",
    label: "Current password",
    placeholder: "Enter your current password",
  },
  {
    name: "password",
    label: "New password",
    placeholder: "Enter your new password",
  },
  {
    name: "verify",
    label: "Repeat new password",
    placeholder: "Repeat your new password",
  },
];

export const GENDER_FIELDS = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

export const handlePasswordSchema = () => {
  const PASSWORD_SCHEMA = Yup.object().shape({
    current: Yup.string().required("Wrong value for your current password"),
    password: Yup.string()
      .min(6, "This value is too short. It should have 6 characters or more.")
      .required(
        "This value is too short. It should have 6 characters or more.",
      ),
    verify: Yup.string()
      .min(6, "This value is too short. It should have 6 characters or more.")
      .required("This value should not be blank.")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });
  return PASSWORD_SCHEMA;
};

export const handleEmailSchema = email => {
  const EMAIL_SCHEMA = Yup.object().shape({
    email: Yup.string()
      .email(
        "Please, check you if you entered your e-mail correctly and try again",
      )
      .required(
        "Please, check you if you entered your e-mail correctly and try again",
      ),
    secret: Yup.string().when("email", {
      is: () => email,
      then: Yup.string().required(
        "Please, check you if you entered the verification code and try again",
      ),
    }),
    //
  });
  return EMAIL_SCHEMA;
};
export const handleUserSchema = () => {
  const USER_SCHEMA = Yup.object().shape({
    name: Yup.string().required("This field is required."),
    currency: Yup.string().required("This field is required."),
  });
  return USER_SCHEMA;
};

export const handleInitUserValues = data => {
  const initialValues = {
    name: data?.name || null,
    currency: data?.currency || null,
    language: data?.language || null,
    phone: data?.phone || null,
    birthday: data?.birthday?.split(" ")[0] || null,
    about: data?.about || null,
    country: data?.country || null,
    city: data?.city || null,
    gender: data?.gender || null,
  };
  return initialValues;
};

export const handleInitPasswordValues = () => {
  const initialValues = {
    current: "",
    password: "",
    verify: "",
  };
  return initialValues;
};

export const handleInitEmailValues = () => {
  const initialValues = {
    email: "",
    secret: "",
  };
  return initialValues;
};
