import React from "react";
import styled from "styled-components";
import { Card } from "reactstrap";

import ChannelHeader from "./ChannelHeader";
import StatisticsChannelBar from "./StatisticsChannelBar";
import ChannelStatisticsBody from "./ChannelStatisticsBody";
import ChannelsListPreload from "./ChannelsListPreload";
import PaginationPanel from "shared/ui/PaginationPanel";
import { formatterToNull } from "helpers/valuesFormatters";

const DashboardChannel = ({
  loading,
  index,
  channel = {},
  isDashboard,
  page,
  total,
  handlePaginate,
}) => {
  if (loading) return <ChannelsListPreload isDashboard />;

  if (isDashboard) {
    return (
      <>
        <Card className='py-4 mb-0'>
          <ChannelHeader
            name={channel?.name}
            totalSubscribers={channel?.totalSubscribers}
            owner={channel?.owner}
            logo={channel?.logos?.small}
            loading={loading}
            isDashboard
          />
          <ChannelStatisticsBody
            average={channel?.profit?.average}
            total={channel?.profit?.total}
            month={channel?.profit?.month}
          />
          <div className='d-flex justify-content-between align-items-center mt-2'>
            <p className='mt-1 font-sm font-weight-400 text-white mb-0'>
              Total signals
            </p>
            <p
              className={`mb-0 font-sm font-weight-500 ${channel?.totalSignals ? "text-white" : "text-white-35"}`}
            >
              {formatterToNull(channel?.totalSignals)}
            </p>
          </div>
          <StatisticsChannelBar
            loss={channel?.statistics?.signals?.loss}
            open={channel?.statistics?.signals?.open}
            profit={channel?.statistics?.signals?.profit}
            index={index}
            lastSignalDate={channel?.lastSignalDate}
          />
        </Card>
        <DashboardChannel.Pagination>
          <PaginationPanel
            activePage={page}
            totalItemsCount={total}
            totalPagesView={1}
            onChange={page => handlePaginate(page)}
            className='mb-0'
            isCircle
          />
        </DashboardChannel.Pagination>
      </>
    );
  }
};

DashboardChannel.Pagination = styled.div`
  ul {
    margin-bottom: 0;
  }
`;

export default DashboardChannel;
