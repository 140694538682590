import React from "react";
import styled from "styled-components";
import { Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import illustration from "assets/img/mainWizard/stay-at-home.webp";
import Button from "shared/ui/buttons/Button";
import { recordWizardCredentials } from "store/user/actions";
//Last step

const TryForFree = ({ onSetModalIsOpen }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleFinish = () => {
    dispatch(recordWizardCredentials());
    history.push("/billing");
  };

  return (
    <Wrapper>
      <SkipButton
        className='d-none d-md-block'
        onClick={() => onSetModalIsOpen(true)}
      >
        Skip this for now
      </SkipButton>
      <Col xs='12' md='6' className='p-0'>
        <h1 className='mb-4'>
          Success! Your
          <br />
          DarkBot is Ready to Trade
        </h1>
        <p className='mb-3'>
          Congratulations! Your DarkBot is now primed for trading excellence.
          Get ready to explore the world of trading with unprecedented
          efficiency and precision!
        </p>
        <p>
          Get started on your path to trading mastery, want to try start our
          Premium Trial for free today ?
        </p>
      </Col>
      <Illustration alt='Stay at home illustration' src={illustration} />
      <Col xs='12' md='3' className='p-0'>
        <Footer>
          <Button color='blue' className='w-100' onClick={handleFinish}>
            Try Premium for free
          </Button>
          <p className='text-center font-sm text-dark-35 mt-2'>
            No credit card required
          </p>
        </Footer>
      </Col>
    </Wrapper>
  );
};

const Illustration = styled.img`
  margin: 53px 0;

  @media (min-width: 768px) {
    width: 45%;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
  }
`;

const Wrapper = styled.div`
  padding: 2rem 1.5rem 1.5rem;

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  @media (min-width: 768px) {
    padding: 3.875rem 4.938rem;

    h1 {
      font-size: 2.5rem;
    }
  }
`;

const Footer = styled.div`
  @media (min-width: 768px) {
    margin-top: 4.5rem;
  }
`;

const SkipButton = styled.button`
  border: 1px solid rgba(29, 30, 42, 0.1);
  background: transparent;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--dark);
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;

  &:hover {
    background: rgba(29, 30, 42, 0.1);
  }
`;

export default TryForFree;
