import React from "react";
import styled from "styled-components";
import { Scrollbars } from "react-custom-scrollbars-with-mac-support";

const MenuList = ({
  children,
  isDark,
  getValue,
  wrapperRef,
  selectProps,
  optionHeight,
}) => {
  const [value] = getValue();
  const childArray = React.Children.toArray(children);
  const focusedOptionIndex = childArray.findIndex(
    option => option.props.value === value,
  );

  const getListHeight = () => {
    const optionsAmount = React.Children.count(children);
    if (optionsAmount >= 5) return 192;
    return optionsAmount * (optionHeight || 38);
  };

  return (
    <StyledMenuList
      className='react-select__menu-list'
      style={{ width: wrapperRef?.current?.offsetWidth || "auto" }}
    >
      <Scrollbars
        thumbMinSize={38}
        autoHeightMax={210}
        renderThumbVertical={props => (
          <ThumbVertical isDark={isDark} {...props} />
        )}
        renderTrackVertical={props => <TrackVertical {...props} />}
        autoHeight
      >
        <div
          style={{
            maxHeight: getListHeight(),
            overflow: "auto",
            contentVisibility: "auto",
            containIntrinsicSize: `${React.Children.count(children) * (optionHeight || 38)}px`,
          }}
        >
          {childArray.map((child, index) =>
            React.cloneElement(child, {
              isFocused: focusedOptionIndex === index,
              ...selectProps,
            }),
          )}
        </div>
      </Scrollbars>
    </StyledMenuList>
  );
};

const StyledMenuList = styled.div`
  padding: 0 !important;
  color: #ffffff;

  div {
    margin-right: 0 !important;
    margin-bottom: 0 !important;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    ::-webkit-scrollbar-track {
      border-radius: 6px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 6px;
    }

    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }

  & > div {
    border-radius: 6px;
  }
`;

const ThumbVertical = styled.div`
  width: 2px !important;
  background: ${props => (props.isDark ? "#D9D9D9" : "#1D1E2A")};
  opacity: 0.3;
  border-radius: 2px;
  cursor: pointer;
  margin: 0.25rem 0;
`;

const TrackVertical = styled.div`
  width: 4px !important;
  right: 2px;
  bottom: 10px;
  top: 2px;
  border-radius: 3px;
`;

export default MenuList;
