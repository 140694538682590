import React from "react";
import styled from "styled-components";

import { ReactComponent as SortUp } from "assets/icons/general/ArrowUp.svg";
import { ReactComponent as SortDown } from "assets/icons/general/ArrowDown.svg";
import { sortByOrderTable } from "helpers/sortByOrderTable";

const SortTableHead = ({
  handleSorting,
  sort,
  theadItems = [],
  isDark = true,
  ...props
}) => {
  return (
    <thead {...props}>
      <tr>
        {theadItems.map((item, index) => (
          <th key={item.name}>
            <div
              className={`d-flex align-items-center ${index !== item.length - 1 ? "mr-2" : "ml-2"} ${item.className || ""}`}
              style={{ width: "fit-content" }}
              onClick={() =>
                item.isSortable
                  ? handleSorting({
                      by: item.name,
                      order: sortByOrderTable(sort, item.name),
                    })
                  : null
              }
            >
              {item.icon || ""}
              <SortTableHead.Title isSortable={item.isSortable}>
                {item.label}
              </SortTableHead.Title>
              {item.isSortable ? (
                <div className='d-flex justify-content-between ml-2'>
                  <SortTableHead.Sort
                    sort={sort}
                    by={item.name}
                    order='ASC'
                    isDark={isDark}
                  >
                    <SortUp />
                  </SortTableHead.Sort>
                  <SortTableHead.Sort
                    sort={sort}
                    by={item.name}
                    order='DESC'
                    isDark={isDark}
                  >
                    <SortDown />
                  </SortTableHead.Sort>
                </div>
              ) : null}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

SortTableHead.Sort = styled.div`
  cursor: pointer;
  opacity: ${props =>
    props.sort && props.sort.by === props.by && props.sort.order === props.order
      ? "1"
      : ".1"};

  & svg {
    path {
      fill: ${({ isDark }) => (isDark ? "#fff" : "#1D1E2A")};
    }
    rect {
      stroke: ${({ isDark }) => (isDark ? "#fff" : "#1D1E2A")};
    }
  }
`;

SortTableHead.Title = styled.p`
  color: rgba(255, 255, 255, 0.35) !important;
  white-space: nowrap;
  margin-bottom: 0;
  cursor: ${props => (props.isSortable ? "pointer" : "default")};

  &:hover {
    color: ${props =>
      props.isSortable ? "rgba(255, 255, 255, 0.75)" : ""} !important;
  }
`;

export default SortTableHead;
