import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TELEGRAM_BOT } from "env-create-react-app";
import { useDispatch, connect } from "react-redux";
import classNames from "classnames";
import { bindActionCreators } from "redux";
import { ModalBody, Modal, FormGroup, Row, Col } from "reactstrap";

import useField from "hooks/useField";
import { connectTelegram } from "store/user/actions";
import telegramIllustration from "assets/icons/social/telegram-illustration-new.svg";
import { addSnackBar } from "store/snackbar/actions";
import { Button } from "../buttons";

const TelegramModal = ({
  modalIsOpen,
  onSetModalIsOpen,
  connectTelegram,
  updateWizard,
  wizardState,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [reqError, setReqError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [secretValue, setSecretValue] = useState({ secret: "" });
  const [fieldProps, errors] = useField(secretValue, setSecretValue);
  const dispatch = useDispatch();

  useEffect(() => {
    setReqError("");
  }, [secretValue]);

  const handleConnectTelegram = async () => {
    setSubmitted(true);
    if (!errors.secret) {
      setIsSubmitting(true);
      const res = await connectTelegram(secretValue);
      if (res?.data?.errors) {
        setReqError(res.data.message);
      } else {
        updateWizard({ ...wizardState, telegram: true });
        onSetModalIsOpen(false);
        dispatch(addSnackBar("success", res.message));
      }
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    onSetModalIsOpen(!modalIsOpen);
    setSubmitted(false);
  };

  return (
    <TelegramModal.StyledModal
      isOpen={modalIsOpen}
      toggle={() => {
        onSetModalIsOpen(!modalIsOpen);
        setReqError(null);
        setSubmitted(false);
      }}
      modalClassName='modal-black'
      size='lg'
    >
      <Row className='no-gutters'>
        <Col className='d-none d-lg-block' lg='5'>
          <TelegramModal.DarkWrapper className='d-flex align-items-center w-100 h-100'>
            <img src={telegramIllustration} alt='...' />
          </TelegramModal.DarkWrapper>
        </Col>
        <Col xs='12' lg='7'>
          <TelegramModal.ModalBody className='h-100'>
            <div className='d-flex justify-content-end'>
              <Button onClick={handleClose} cancel />
            </div>
            <TelegramModal.Heading className='text-white'>
              Connect Telegram account
            </TelegramModal.Heading>
            <div className='d-flex mb-4'>
              <TelegramModal.StepMark className='d-flex align-items-center justify-content-center mr-3'>
                1
              </TelegramModal.StepMark>
              <p className='text-white-75 font-sm'>
                To connect our bot, please message
                <br />
                <TelegramModal.Link
                  href={`https://t.me/${TELEGRAM_BOT}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-info font-weight-500 hover-underline'
                >
                  @ioDarkbot
                </TelegramModal.Link>{" "}
                on Telegram.
              </p>
            </div>
            <div className='d-flex mb-3'>
              <TelegramModal.StepMark className='d-flex align-items-center justify-content-center mr-3'>
                2
              </TelegramModal.StepMark>
              <p className='text-white-75 font-sm'>
                Type <span className='text-info font-weight-500'>/start</span>{" "}
                in the bot to receive the
                <br />
                activation code. Then just enter it below.
              </p>
            </div>
            <FormGroup
              className={classNames("", {
                "mb-3 mb-lg-5": !((errors.secret || reqError) && submitted),
                "mb-2 mb-lg-4": errors.secret && submitted && !reqError,
              })}
            >
              <input
                autoComplete='off'
                className={classNames("form-control", {
                  "has-error": (reqError || errors.secret) && submitted,
                })}
                name='secret'
                placeholder='Activation code'
                value={secretValue.secret}
                {...fieldProps}
              />
              {(errors.secret || reqError) && submitted ? (
                <label className='text-danger m-0'>
                  {errors.secret || reqError}
                </label>
              ) : null}
            </FormGroup>
            <div className='w-100 d-flex justify-content-end'>
              <Button
                loading={isSubmitting}
                onClick={handleConnectTelegram}
                color='blue'
                type='submit'
              >
                Connect
              </Button>
            </div>
          </TelegramModal.ModalBody>
        </Col>
      </Row>
    </TelegramModal.StyledModal>
  );
};

TelegramModal.StyledModal = styled(Modal)`
  @media (min-width: 992px) {
    .modal-lg {
      max-width: 720px !important;
    }
  }

  @media (max-width: 992px) {
    & .btn-blue {
      width: 100%;
    }
  }

  .modal-content,
  .modal-body {
    border-radius: 1rem !important;
  }
`;

TelegramModal.Heading = styled.h1`
  margin-bottom: 2rem;
`;

TelegramModal.Link = styled.a`
  &.text-info:focus:not(:hover) {
    color: #2f80ed !important;
  }
`;

TelegramModal.DarkWrapper = styled.div`
  background: #222435;
  padding: 65px 40px;
  border-radius: 1rem 0 0 1rem;
`;

TelegramModal.ModalBody = styled(ModalBody)`
  background: var(--dark-slate) !important;
  padding: 1rem 1.5rem 1.5rem 2rem !important;
`;

TelegramModal.StepMark = styled.div`
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #363a54;
  font-size: 1.25rem;
  line-height: 30px;
`;

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      connectTelegram,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(TelegramModal);
