import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import {
  Flex,
  Separator,
  StyledCard,
  StyledCardBody,
  StyledCardHeader,
} from "../MyCredentialBalances";
import { SideColumnPreloader } from "./SideColumnPreloader";
import { CenterColumnPreloader } from "./CenterColumnPreloader";

export const MyCredentialsBalancesPreloader = () => {
  return (
    <StyledCard className='StyledCard'>
      <StyledCardHeader className='p-0 pb-3'>
        <p className='mb-0 pt-0 text-white font-weight-500 text-nowrap'>
          <SkeletonPreloader height={16} width={79} />
        </p>
      </StyledCardHeader>
      <StyledCardBody>
        <Flex className='w-100' gap='16px' content='space-between'>
          <SideColumnPreloader />
          <Flex gap='16px'>
            <CenterColumnPreloader items='flex-end' />
            <CenterColumnPreloader items='flex-end' />
          </Flex>
        </Flex>
        <Separator />
        <Flex className='w-100' gap='16px' content='space-between'>
          <Flex gap='16px' className='one'>
            <CenterColumnPreloader />
            <CenterColumnPreloader />
          </Flex>
          <SideColumnPreloader side='left' className='two' />
        </Flex>
      </StyledCardBody>
    </StyledCard>
  );
};
