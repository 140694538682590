import React from "react";
import { Card } from "reactstrap";

import {
  FacebookShareButton,
  IdWithCopy,
  TelegramShareButton,
  TwitterShareButton,
} from "shared/ui";
import { SHARING_WIDGET } from "shared/models/user/refferalSystem";

import { ReferralLinkStyled, ReferralShareButtonsStyled } from "./index.styles";

const ReferralLinkWidget = ({ referralCode }) => {
  const referralLink = `https://app.darkbot.io?ref=${referralCode}`;

  return (
    <Card className='p-4'>
      <div className='d-flex align-items-start flex-column mb-4'>
        <h4 className='font-weight-500 text-white mb-3'>Referral link</h4>
        <p className='font-sm font-weight-400 mb-0 text-white-75'>
          You can easily copy your unique referral link and share it with your
          friends.
        </p>
      </div>
      <div className='d-flex justify-content-between mb-2'>
        <p className='font-sm font-weight-400 mb-0 text-white-75'>
          Your referral link
        </p>
        <IdWithCopy id={referralLink} displayType='simpleButton' />
      </div>
      <ReferralLinkStyled className='text-white mb-4 mt-1'>
        {referralLink}
      </ReferralLinkStyled>
      <div className='d-flex flex-column'>
        <p className='text-white'>Share in social</p>
        <ReferralShareButtonsStyled>
          <TelegramShareButton url={referralLink} text={SHARING_WIDGET.text} />

          <TwitterShareButton url={referralLink} text={SHARING_WIDGET.text} />

          <FacebookShareButton
            url={referralLink}
            text={SHARING_WIDGET.text}
            hashtags={SHARING_WIDGET.hashtags}
          />
        </ReferralShareButtonsStyled>
      </div>
    </Card>
  );
};

export default ReferralLinkWidget;
