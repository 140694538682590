import React, { useState, useEffect, useCallback } from "react";
import { bindActionCreators } from "redux";
import { DelayInput } from "react-delay-input";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { ModalBody, Modal, UncontrolledAlert, FormGroup } from "reactstrap";
import { PulseLoader } from "react-spinners";

import { fetchStrategies, loadMoreStrategies } from "store/strategies/actions";
import ChangeStrategyList from "../ChangeStrategyList";
import CustomScrollbars from "../CustomScrollbars";
import { Button } from "../buttons";

const ChangeStrategy = ({
  data: { items = [], page, total },
  loading,
  error,
  fetchStrategies,
  onSetModalIsOpen,
  modalIsOpen,
  onAttachStrategy,
  loadMoreStrategies,
  id,
}) => {
  const [selectedStrategy, setSelectedStrategy] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [name, setName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchStrategies(1, { name }, 5);
      if (res.items) {
        setHasMore(res.items.length === 5);
      }
    };
    if (modalIsOpen) {
      fetchData();
    }
  }, [fetchStrategies, setHasMore, modalIsOpen, name]);

  const handleLoadMore = useCallback(() => {
    if (!items.length || !(total - items.length)) {
      setHasMore(false);
    } else {
      loadMoreStrategies(page + 1, { name }, 5);
    }
  }, [loadMoreStrategies, setHasMore, page, items, name, total]);

  const handleSelectedStrategy = useCallback(
    strategy => {
      if (selectedStrategy.uuid === strategy.uuid) {
        setSelectedStrategy({});
      } else {
        setSelectedStrategy(strategy);
      }
    },
    [selectedStrategy, setSelectedStrategy],
  );

  const handleAttachStrategy = async () => {
    setIsSubmitting(true);
    const err = await onAttachStrategy(id, selectedStrategy.uuid);
    if (err) {
      setErrorMessage(err.message);
    } else {
      onSetModalIsOpen(false);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      toggle={() => onSetModalIsOpen(!modalIsOpen)}
      modalClassName='modal-black'
    >
      <div className='modal-header d-flex flex-column'>
        <div className='d-flex'>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={() => onSetModalIsOpen(!modalIsOpen)}
          >
            <i className='tim-icons text-white icon-simple-remove' />
          </button>
          <h3 className='modal-title'>Change strategy</h3>
        </div>
        {errorMessage && (
          <UncontrolledAlert color='danger' fade={false}>
            {errorMessage}
          </UncontrolledAlert>
        )}
        <FormGroup className='w-100 mt-4'>
          <DelayInput
            delayTimeout={700}
            minLength={1}
            type='text'
            className='form-control'
            placeholder='Name'
            value={name}
            onChange={({ target }) => setName(target.value)}
          />
        </FormGroup>
      </div>
      <ModalBody>
        <CustomScrollbars
          autoHide
          autoHideTimeout={500}
          autoHideDuration={200}
          style={{ height: "420px" }}
        >
          {modalIsOpen && (
            <InfiniteScroll
              pageStart={0}
              loadMore={handleLoadMore}
              hasMore={hasMore}
              loader={
                <div className='text-center' key={page}>
                  <PulseLoader color='#5bc0de' />
                </div>
              }
              useWindow={false}
            >
              <ChangeStrategyList
                hasMore={hasMore}
                items={items}
                loading={loading}
                error={error}
                onHandleSelectedStrategy={handleSelectedStrategy}
                selectedStrategy={selectedStrategy}
              />
            </InfiniteScroll>
          )}
        </CustomScrollbars>
        <div className='d-flex justify-content-between'>
          <Button
            className='mt-4'
            color='default'
            size='lg'
            onClick={() => onSetModalIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            className='mt-4'
            color='info'
            size='lg'
            disabled={!selectedStrategy.uuid}
            onClick={() => handleAttachStrategy}
            loading={isSubmitting}
          >
            Save
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = state => ({
  data: state.strategies.list.data,
  loading: state.strategies.list.loading,
  error: state.strategies.list.error,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchStrategies,
      loadMoreStrategies,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStrategy);
