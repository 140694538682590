import React, { useState } from "react";
import styled from "styled-components";
import { Label } from "reactstrap";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { updateAccess } from "store/credentials/actions";
import { handleResponse } from "helpers/errorsValidator";
import { addSnackBar } from "store/snackbar/actions";
import { ErrorAlert } from "shared/ui";
import { Button } from "shared/ui/buttons";

const EditSchema = Yup.object().shape({
  apiKey: Yup.string().required("This field is required."),
  secret: Yup.string().required("This field is required."),
});

const EditCredentialForm = ({ credential = {}, onSetModalIsOpen }) => {
  const [submitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        apiKey: credential.apiKey?.replaceAll(" ", "") || "",
        secret: credential.secret?.replaceAll(" ", "") || "",
      }}
      validationSchema={EditSchema}
      onSubmit={async (values, formik) => {
        formik.setSubmitting(true);
        const reg = /\n+/g;
        const res = await dispatch(
          updateAccess(
            {
              apiKey: values.apiKey?.replaceAll(" ", ""),
              secret: values.secret?.replaceAll(" ", "")?.replaceAll(reg, ""),
            },
            credential.id,
          ),
        );
        if (res?.errors) {
          formik.setErrors({ message: res.message });
          handleResponse(res, formik.setErrors);
        } else {
          dispatch(addSnackBar("success", res.message));
          formik.setStatus({ message: res.message });
          onSetModalIsOpen(false);
        }
        formik.setSubmitting(false);
      }}
    >
      {({ errors, isSubmitting, setErrors }) => (
        <Form>
          <div className='d-flex flex-column px-2'>
            <div className='mb-2'>
              <Label className='text-white-75 mb-1'>API key</Label>
              <Field
                type='text'
                name='apiKey'
                className={classnames("form-control", {
                  "has-error": submitted && (errors.apiKey || errors.message),
                })}
              />
            </div>
            <Label className='text-white-75 mb-1'>Secret</Label>
            <Field
              type='text'
              component='textarea'
              name='secret'
              className={classnames("form-control", {
                "has-error": submitted && (errors.secret || errors.message),
              })}
            />
          </div>
          <ErrorAlert
            fade={false}
            toggle={() => setErrors({})}
            isOpen={submitted && errors.message}
            className='px-2 mt-3'
          >
            {errors.message}
          </ErrorAlert>
          <div className='d-flex justify-content-between mb-2 mt-4 px-2'>
            <Button
              color='gray'
              type='button'
              onClick={() => onSetModalIsOpen(false)}
              className='font-weight-500 text-white'
            >
              Cancel
            </Button>
            <Button
              color='blue'
              disabled={Object.keys(errors).length}
              loading={isSubmitting}
              type='submit'
              className='float-right'
              onClick={() => setSubmitted(true)}
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditCredentialForm.SuccessMessage = styled.div`
  background: rgba(95, 211, 188, 0.1);
  border: 1px solid var(--success);
  border-radius: 6px;
  padding: 16px 24px;
`;

export default EditCredentialForm;
