import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import LastBacktestPreloader from "./LastBacktestPreloader";
import useRequestCancellation from "hooks/useRequestCancellation";
import { formatValue, setColorClass } from "helpers/valuesFormatters";
import { getBacktests } from "store/bots/actions";
import { selectBacktests } from "store/bots/selectors";
import { ReactComponent as BacktestIcon } from "assets/icons/general/backtest.svg";
import { ReactComponent as Ellipse } from "assets/icons/general/ellipse.svg";
import { BadgeBacktestStatus } from "shared/ui/badges";
import { Icon } from "shared/ui";

const LastBacktest = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(selectBacktests);
  const { id, bot, market, balance, status, exchange, createdAt } =
    data?.items?.[0] || {};

  const { useAbortableEffect } = useRequestCancellation();

  useAbortableEffect(
    signal => {
      dispatch(getBacktests("?limit=1&sort[createdAt]=DESC", signal));
    },
    [dispatch],
  );

  const getFormattedDate = date =>
    moment(Date.parse(date)).format("DD.MM.YY HH:mm");

  if (loading) return <LastBacktestPreloader />;

  if (!data?.items?.length) return null;

  return (
    <Card>
      <StyledCardHeader className='px-4 pt-4'>
        <div className='d-flex justify-content-between'>
          <p className='font-weight-500 text-white'>
            <BacktestIcon className='mr-2' width={24} height={24} />
            Latest Backtest
          </p>
          <Link to={`/backtests/${id}`} className='text-info'>
            View
          </Link>
        </div>
      </StyledCardHeader>
      <CardBody className='pt-0 px-4 pb-4'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex w-75'>
            <span>
              <Icon
                size='botSize'
                code={`${bot?.type}-bot`}
                type='icons'
                className='mr-2'
              />
            </span>
            <p className='text-white my-auto font-sm font-weight-500 text-truncate'>
              {bot?.name || ""}
            </p>
            <span className='d-inline-block'>
              <StyledBotEllipse status='running' className='my-auto ml-1' />
            </span>
          </div>
          <Icon type='exchanges' size='thumb' code={exchange} />
        </div>
        <hr />
        <div className='d-flex justify-content-between'>
          <p className='text-white-75 font-sm'>Status</p>
          {status ? <BadgeBacktestStatus status={status} /> : null}
        </div>
        <div className='d-flex justify-content-between'>
          <p className='text-white-75 font-sm'>Market</p>
          <p className='font-weight-500 text-white'>
            {market?.base ? `${market?.base} / ${market?.quote}` : " - "}
          </p>
        </div>
        <div className='d-flex justify-content-between'>
          <p className='text-white-75 font-sm'>Initial balance</p>
          <p className='font-weight-500 text-white'>
            {balance?.initial?.amount && balance?.initial?.asset ? (
              <>
                {balance.initial.amount}{" "}
                <span className='font-weight-300 text-white-35'>
                  {balance.initial.asset}
                </span>
              </>
            ) : (
              " - "
            )}
          </p>
        </div>
        <div className='d-flex justify-content-between'>
          <p className='text-white-75 font-sm'>Final balance</p>
          <p className='font-weight-500 text-white'>
            {balance?.final?.amount && balance?.final?.asset ? (
              <>
                {balance.final.amount}{" "}
                <span className='font-weight-300 text-white-35'>
                  {balance.final.asset}
                </span>
              </>
            ) : (
              " - "
            )}
          </p>
        </div>
        <div className='d-flex justify-content-between'>
          <p className='text-white-75 font-sm'>Percent</p>
          <p
            className={`font-weight-500 ${setColorClass(balance?.final?.percent)}`}
          >
            {balance?.final?.percent
              ? `${formatValue(balance.final.percent)}%`
              : " - "}
          </p>
        </div>
        <div className='d-flex justify-content-between'>
          <p className='text-white-75 font-sm'>Created at</p>
          <p className='font-weight-500 text-white'>
            {createdAt ? getFormattedDate(createdAt) : " - "}
          </p>
        </div>
      </CardBody>
    </Card>
  );
};

const StyledCardHeader = styled(CardHeader)`
  padding-bottom: 2rem !important;
`;

const StyledBotEllipse = styled(Ellipse)`
  width: 6px;
  height: 6px;

  & circle {
    fill: ${props =>
      props.status === "running" ? "#5FD3BC" : "rgba(29, 30, 42, 0.35)"};
  }
`;

export default LastBacktest;
