import { CardHeader } from "reactstrap";
import DealsFilters from "./DealsFilters";
import { ReactComponent as Rocket } from "assets/icons/general/rocket-outline-2.svg";

const DealHistoryHeader = ({
  loadingPage,
  loading,
  handleFilter,
  filters,
  request,
}) => {
  return (
    <CardHeader className='d-flex flex-column flex-md-row justify-content-between align-items-center p-4'>
      <div className='d-flex align-items-center mr-4 mb-3 mb-md-0'>
        <span className='d-inline-block'>
          <Rocket />
        </span>
        <p className='mb-0 text-white font-weight-500 text-nowrap ml-2'>
          Deals history
        </p>
      </div>
      <DealsFilters
        onHandleFilter={handleFilter}
        filter={request?.filter || filters}
        loadingPage={loadingPage}
        loading={loading}
      />
    </CardHeader>
  );
};

export default DealHistoryHeader;
