import { get } from "lodash";

import {
  COMPLETE_DEAL,
  RESET_DEALS,
  RESET_DEAL,
  RESET_DEAL_CHART,
  RESET_TRADE_REQUESTS,
  CANCEL_DEAL,
  FORCE_RUN_DEAL_LOADING,
  FORCE_RUN_DEAL,
  CANCEL_DEAL_LOADING,
  COMPLETE_DEAL_LOADING,
  FORCE_RUN_DEAL_ERROR,
  COMPLETE_DEAL_ERROR,
  CANCEL_DEAL_ERROR,
  RESET_CONDITIONS,
  ENABLE_BOT_MARKET,
  DISABLE_BOT_MARKET,
  CREATE_BOT_DEAL,
  UPDATE_BOT_CONFIG,
  RESTART_DEAL,
  RESTART_DEAL_ERROR,
} from "./constants";
import createAction from "store/thunkFactory";

export const fetchDeal = (id, signal) =>
  createAction("DEAL", fetch => {
    return fetch.get(`/app/deals/${id}`, { signal });
  });

export const fetchTradeRequests = (id, failed, page = 1) =>
  createAction("TRADE_REQUESTS", fetch => {
    return fetch.get(
      `/app/deals/${id}/trade-requests${failed || ""}${failed ? "&" : "?"}page=${page}`,
    );
  });

export const fetchHistoryTradeRequests = (id, filter) =>
  createAction("HISTORY_TRADE_REQUESTS", fetch => {
    return fetch.get(`/app/deals/${id}/trade-requests${filter || ""}`);
  });

export const fetchHistoryDeals = (filters, signal) =>
  createAction("DEALS_HISTORY", fetch => {
    return fetch.get(`/app/deals${filters || ""}`, { signal });
  });

export const fetchOpenNewBuy = id => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post(`/app/deals/${id}/trade-requests`);
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const fetchConditions = id =>
  createAction("CONDITIONS", fetch => {
    return fetch.get(`/app/deal/${id}/conditions`);
  });

export const fetchMarketInformation = (exchange, base, quote, signal) =>
  createAction("MARKET_INFORMATION", fetch => {
    return fetch.get(
      `/app/exchanges/${exchange}/markets/${base}-${quote}/information`,
      { signal },
    );
  });

export const fetchTradeRequestOrders = (id, filters) =>
  createAction("TRADE_REQUEST_ORDERS", fetch => {
    return fetch.get(`/app/trade-requests/${id}/orders${filters || ""}`);
  });

export const fetchTradeRequestTrades = (id, filters) =>
  createAction("TRADE_REQUEST_TRADES", fetch => {
    return fetch.get(`/app/trade-requests/${id}/trades${filters || ""}`);
  });

export const fetchTechnicalLogs = (id, filters) =>
  createAction("TECHNICAL_LOGS", fetch => {
    return fetch.get(`/app/deal/${id}/logs${filters || ""}`);
  });

export const fetchDeals = (filters, signal) =>
  createAction("DEALS", fetch => {
    return fetch.get(`/app/deals${filters || ""}`, { signal });
  });

export const getDealsMarkets = filters =>
  createAction("DEALS_MARKETS", fetch => {
    return fetch.get(`/app/deals/filters/markets${filters || ""}`);
  });

export const fetchIndicators = id =>
  createAction("INDICATORS", fetch => {
    return fetch.get(`/app/deals/${id}/indicators`);
  });

export const fetchBacktestDeals = (backtestId, filters = "") =>
  createAction("DEALS", fetch => {
    return fetch.get(`/app/backtests/${backtestId}/deals${filters}`);
  });

export const fetchDealsRunning = (filters, signal) =>
  createAction("DEALS_RUNNING", fetch => {
    return fetch.get(`/app/deals${filters || ""}`, { signal });
  });

export const fetchActiveMarkets = (id, filters) =>
  createAction("ACTIVE_MARKETS", fetch => {
    return fetch.get(`/app/bots/${id}/markets/statistics${filters || ""}`);
  });

export const enableMarket =
  (bot, coin) => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.post(`/app/bots/${bot}/coins/${coin}/enable`);
      dispatch({
        type: ENABLE_BOT_MARKET,
        payload: { coin, isExcluded: false },
      });
      return res.data;
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const disableMarket =
  (bot, coin) => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.post(`/app/bots/${bot}/coins/${coin}/disable`);
      dispatch({
        type: DISABLE_BOT_MARKET,
        payload: { coin, isExcluded: true },
      });
      return res.data;
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const updateMarketConfig =
  (bot, params) => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.post(`/app/bots/${bot}/market-config`, params);
      dispatch({ type: UPDATE_BOT_CONFIG, payload: { ...res, ...params } });
      return res.data;
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const createBotDeal =
  (id, quote, base) => async (dispatch, getState, fetch) => {
    try {
      const res = await fetch.post(
        `/app/bots/${id}/markets/${base}-${quote}/deals`,
      );
      dispatch({ type: CREATE_BOT_DEAL, payload: { res } });
      return res.data;
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const getDealChart = (id, filters = "") =>
  createAction("DEAL_CHART", fetch => {
    return fetch.get(`/app/deals/${id}/chart?${filters}`);
  });

export const getBacktestDeals = (id, query) =>
  createAction("BACKTEST_DEALS", fetch => {
    return fetch.get(`/app/backtests/${id}/deals${query}`);
  });

export const getTrades = (id, filters) =>
  createAction("TRADES", fetch => {
    return fetch.get(`/app/deals/${id}/trades${filters || ""}`);
  });

export const getOrders = (id, filters) =>
  createAction("ORDERS", fetch => {
    return fetch.get(`/app/deals/${id}/orders${filters || ""}`);
  });

export const cancelTradeRequest = id => async (dispatch, getState, fetch) => {
  try {
    const res = await fetch.post(`/app/trade-requests/${id}/cancel`);
    return res.data;
  } catch (e) {
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const completeOpenDeal =
  (id, path) => async (dispatch, getState, fetch) => {
    try {
      if (path === "running") dispatch({ type: COMPLETE_DEAL_LOADING });
      const res = await fetch.post(`/app/deals/${id}/complete`);
      dispatch({ type: COMPLETE_DEAL, payload: { item: res.data.item } });
      return res.data;
    } catch (e) {
      dispatch({ type: COMPLETE_DEAL_ERROR, payload: e.response.data.message });
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const cancelOpenDeal =
  (id, path) => async (dispatch, getState, fetch) => {
    try {
      if (path === "running") dispatch({ type: CANCEL_DEAL_LOADING });
      const res = await fetch.post(`/app/deals/${id}/cancel`);
      dispatch({ type: CANCEL_DEAL, payload: { item: res.data.item } });
      return res.data;
    } catch (e) {
      dispatch({ type: CANCEL_DEAL_ERROR, payload: e.response.data.message });
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const restartOpenDeal =
  (id, path) => async (dispatch, getState, fetch) => {
    try {
      if (path === "running") dispatch({ type: CANCEL_DEAL_LOADING });
      const res = await fetch.post(`/app/deals/${id}/restart`);
      dispatch({ type: RESTART_DEAL, payload: { item: res.data.item } });
      return res.data;
    } catch (e) {
      dispatch({ type: RESTART_DEAL_ERROR, payload: e.response.data.message });
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const forceRunDeal = (id, path) => async (dispatch, getState, fetch) => {
  try {
    if (path === "running") dispatch({ type: FORCE_RUN_DEAL_LOADING });
    const res = await fetch.post(`/app/deals/${id}/force-run`);
    dispatch({ type: FORCE_RUN_DEAL, payload: { item: res.data.item } });
    return res.data;
  } catch (e) {
    dispatch({ type: FORCE_RUN_DEAL_ERROR, payload: e.response.data.message });
    return get(e, "response.data", { errors: [], message: e.message });
  }
};

export const resetConditions = () => ({ type: RESET_CONDITIONS });

export const resetDeals = () => ({ type: RESET_DEALS });

export const resetDeal = () => ({ type: RESET_DEAL });

export const resetDealChart = () => ({ type: RESET_DEAL_CHART });

export const resetTradeRequests = () => ({ type: RESET_TRADE_REQUESTS });
