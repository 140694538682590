import React from "react";
import CriterionItem from "./CriterionItem";

const Criterias = ({ criterias, ...props }) => {
  return (
    <div {...props}>
      <p className='font-md font-weight-500 text-white'>Criterias</p>
      <div className='mt-1'>
        {criterias?.map(criterion => (
          <CriterionItem key={criterion.code} criterion={criterion} />
        ))}
      </div>
    </div>
  );
};

export default Criterias;
