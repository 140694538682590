import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { DelayInput } from "react-delay-input";
import classNames from "classnames";
import { Button, Modal } from "reactstrap";

import { search } from "store/search/actions";
import SearchResults from "./SearchResults";
import { ReactComponent as SearchIcon } from "assets/icons/general/search.svg";
import SearchPreloader from "./SearchPreloader";
import SearchNotResults from "./SearchNotResults";

const Search = () => {
  const [modalSearch, setModalSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useDispatch();

  const searchResults = useSelector(state => state.search.result);
  const loading = useSelector(state => state.search.loading);

  const handleSearch = ({ target }) => {
    setSearchQuery(target.value);
    if (target.value) dispatch(search(target.value));
  };

  const handleOpenSearch = () => setModalSearch(!modalSearch);

  return (
    <>
      <Button
        color='link'
        data-target='#searchModal'
        data-toggle='modal'
        id='search-button'
        onClick={handleOpenSearch}
        className='btn btn-reset my-auto mr-4 ml-0'
      >
        <SearchIcon />
      </Button>
      <Modal
        modalClassName='modal-search'
        isOpen={modalSearch}
        toggle={() => setModalSearch(!modalSearch)}
      >
        <div className='modal-header p-1'>
          <DelayInput
            delayTimeout={700}
            minLength={1}
            value={searchQuery}
            onChange={handleSearch}
            id='inlineFormInputGroup'
            className='form-control bg-white search-input'
            placeholder=''
            autoComplete='off'
          />
          <Search.Icon />
          <Search.Button
            aria-label='Close'
            data-dismiss='modal'
            type='button'
            onClick={() => setModalSearch(false)}
            className='d-flex justify-content-center position-relative'
          >
            <i className='tim-icons icon-simple-remove' />
          </Search.Button>
        </div>

        <Search.DropdownMenu
          className={classNames("center dropdown-menu p-4 mt-2 w-100 d-none", {
            "d-block":
              (searchResults?.length && searchQuery) ||
              (searchQuery?.length && !searchResults?.length),
          })}
        >
          {loading ? <SearchPreloader /> : null}
          {searchQuery?.length !== 0 && !searchResults?.length && !loading ? (
            <SearchNotResults />
          ) : (
            <SearchResults
              searchResults={searchResults}
              onSetModalSearch={setModalSearch}
            />
          )}
        </Search.DropdownMenu>
      </Modal>
    </>
  );
};

Search.DropdownMenu = styled.div`
  &:before {
    display: none !important;
  }
`;

Search.Icon = styled(SearchIcon)`
  position: relative;
  right: 572px;
  top: 6px;
  path {
    stroke: rgba(51, 51, 51, 1);
  }
`;

Search.Button = styled(Button)`
  background: #8789a2 !important;
  border-radius: 4px !important;
  left: 45px !important;
  top: 11px !important;
  transform: none !important;

  &.btn:hover {
    background-color: #263148 !important;
  }
`;

export default Search;
