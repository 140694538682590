export const BOTS_COUNT = {
  0: { gte: 0, lte: 0 },
  "1-3": { gte: 1, lte: 3 },
  "3-5": { gte: 3, lte: 5 },
  "5+": { gte: 5, lte: {} },
  "10+": { gte: 10, lte: {} },
};

export const PIVOT_TYPE_FIELDS = [
  { value: "traditional", label: "Traditional" },
  { value: "demark", label: "Demark" },
  { value: "classic", label: "Classic" },
  { value: "woodie", label: "Woodie" },
  { value: "camarilla", label: "Camarilla" },
  { value: "fibonacci", label: "Fibonacci" },
];

export const PIVOT_TIMEFRAME_FIELDS = [
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
  { value: "year", label: "Year" },
];
