import React from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";
import moment from "moment";

import HeaderPreLoader from "shared/ui/HeaderPreLoader";
import IdWithCopy from "shared/ui/IdWithCopy";
import { ReactComponent as Clock } from "assets/icons/general/clock.svg";
import { ReactComponent as AirDrop } from "assets/icons/signal/airdrop.svg";
import { StatusIndicator } from "shared/ui";

const SignalDetailsHeader = ({
  loading,
  id,
  status,
  createdAt,
  base,
  quote,
}) => {
  if (loading) return <HeaderPreLoader />;
  return (
    <Card className='p-4'>
      <div className='d-flex flex-column flex-md-row justify-content-between'>
        <div className='d-flex align-items-center'>
          <AirDrop />
          <div className='ml-3'>
            <h1 className='mb-0 text-white'>
              {base}{" "}
              <span className='text-white-35 font-weight-300'>/ {quote}</span>
            </h1>
            <IdWithCopy id={id} className='d-none d-md-flex' />
          </div>
        </div>
        <div className='mt-3 mt-md-0 text-left text-md-right'>
          <StatusIndicator status={status} margin='0' />
          <div className='d-flex align-items-center'>
            <Clock className='icon-stroke-gray mr-1' aria-hidden='true' />
            <p className='text-white-35 font-sm mb-0'>
              {moment(createdAt).format("DD.MM.YYYY HH:mm")}
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};

SignalDetailsHeader.propTypes = {
  loading: PropTypes.bool,
  id: PropTypes.string,
  status: PropTypes.string,
  createdAt: PropTypes.string,
  base: PropTypes.string,
  quote: PropTypes.string,
};

export default SignalDetailsHeader;
