import React from "react";
import { CardBody, Col } from "reactstrap";
import { DelayInput } from "react-delay-input";

import { getUsersReferrals } from "store/user/actions";
import { SUBSCRIPTION_TYPE, CONFIRMED_ACCOUNT, TRIAL } from "models/filters";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import SelectInput from "shared/ui/SelectInput";

const Filters = ({ referrals }) => {
  const { handleFilter, filters } = useFiltersWithRequest(
    referrals?.request,
    getUsersReferrals,
  );
  return (
    <CardBody className='mx-4 mb-4 p-0 d-flex flex-column flex-md-row justify-content-between'>
      <Col md={3} className='p-0 mb-2 mb-md-0 pr-md-3'>
        <DelayInput
          autoComplete='off'
          delayTimeout={700}
          type='text'
          name='search'
          placeholder='Name'
          className='form-control'
          value={filters?.name?.like ?? ""}
          onChange={event =>
            handleFilter({ name: { like: event.target.value } })
          }
        />
      </Col>
      <Col md={3} className='p-0 mb-2 mb-md-0 pr-md-3'>
        <SelectInput
          isClearable
          placeholder='Subscription'
          name='subscription'
          onChange={option =>
            handleFilter({
              subscriptionType:
                option?.value !== null ? { eq: option?.value } : { eq: null },
            })
          }
          options={SUBSCRIPTION_TYPE}
        />
      </Col>
      <Col md={3} className='p-0 mb-2 mb-md-0 pr-md-3'>
        <SelectInput
          isClearable
          placeholder='Confirmed'
          name='confirmed'
          onChange={option =>
            handleFilter({ confirmed: { eq: option?.value } })
          }
          options={CONFIRMED_ACCOUNT}
        />
      </Col>
      <Col md={3} className='p-0 mb-2 mb-md-0'>
        <SelectInput
          isClearable
          placeholder='Trial'
          name='trial'
          onChange={option => handleFilter({ hasTrial: { eq: option?.value } })}
          options={TRIAL}
        />
      </Col>
    </CardBody>
  );
};

export default Filters;
