import styled from "styled-components";
import { CardHeader } from "reactstrap";

export const StyledCardHeader = styled(CardHeader)`
  background-color: #2e3148 !important;
  padding: 1.5rem 2rem !important;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 0 1.5rem;
  border-bottom: 1px solid #2e3148;

  @media (min-width: 768px) {
    &.cuttingEdge {
      border: 2px solid #ff9200;
      background: rgba(153, 88, 0, 0.06);
      border-radius: 8px;
      padding: 0 1.5rem;
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 225px 260px 260px 260px;
    border-bottom: none;
  }
`;

export const ListHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid #2e3148;

  @media (max-width: 768px) {
    grid-template-columns: 260px 260px 260px 260px;
    border-bottom: none;
  }

  & > div {
    padding: 40px;
  }
`;
