import React, { useEffect, useState } from "react";
import { CardBody, Col, CardHeader } from "reactstrap";

import { ReactComponent as ReceiptText } from "assets/icons/general/receipt-text.svg";
import OrderBookPreloader from "shared/ui/OrderBook/preloaders/OrderBookPreloader";
import OrderBookTable from "./OrderBookTable";
import { StyledWrapper } from "./StyledOrderBook";
import Filters from "./Filters";
import DepthChart from "./charts/DepthChart";
import Spread from "./Spread";
import useWindowDimensions from "hooks/useWindowDimensions";
import ButtonsGroup from "../ButtonsGroup";
import { GROUP_BUTTONS } from "./models/filtersOptions";

const OrderBook = ({
  orderBookAsks = [],
  orderBookBids = [],
  spread,
  spreadPercent,
  isWebsocketOpen,
  depth,
  handleSetDepth,
  base,
  quote,
  setGroup,
  group,
  withSum,
  withSpread,
  loadingPage,
}) => {
  const [tab, setTab] = useState("orderBook");
  useEffect(() => setGroup(""), [base, setGroup]);
  const { width } = useWindowDimensions();

  if (!isWebsocketOpen || loadingPage) return <OrderBookPreloader />;
  if (!orderBookAsks.length || !orderBookBids.length) return;

  return (
    <StyledWrapper className='mb-4 mt-0'>
      <CardHeader className='d-flex flex-column flex-md-row justify-content-between font-weight-500 pt-4 px-4 pb-0'>
        <div className='d-flex flex-column'>
          <div className='d-flex mb-4'>
            <ReceiptText className='my-auto' />
            <p className='text-white ml-2 my-auto'>
              {tab === "orderBook" ? "Order book" : "Crypto Market Depth"}
            </p>
          </div>
          <div className={`d-none pt-3 ${withSpread ? "d-md-block" : ""}`}>
            <Spread spread={spread} spreadPercent={spreadPercent} />
          </div>
        </div>
        <div className='d-flex flex-column align-items-lg-end'>
          <ButtonsGroup
            group={GROUP_BUTTONS}
            current={tab}
            setCurrent={setTab}
            className='mb-2'
          />
          <Filters
            group={group}
            setGroup={setGroup}
            orderBookAsks={orderBookAsks}
            orderBookBids={orderBookBids}
            handleSetDepth={handleSetDepth}
            depth={depth}
            tab={tab}
            setTab={setTab}
          />
        </div>
      </CardHeader>
      <CardBody className='d-flex flex-column flex-lg-row no-gutters p-md-4 px-0 pt-3'>
        {tab === "orderBook" ? (
          <>
            <Col xs='12' lg='6'>
              <OrderBookTable
                orders={orderBookBids}
                type='bids'
                base={base}
                quote={quote}
                withSum={withSum}
                width={width}
              />
            </Col>
            <div className='d-block d-lg-none'>
              <Spread spread={spread} spreadPercent={spreadPercent} />
            </div>
            <Col xs='12' lg='6'>
              <OrderBookTable
                orders={orderBookAsks}
                type='asks'
                base={base}
                quote={quote}
                withSum={withSum}
                width={width}
              />
            </Col>
          </>
        ) : (
          <DepthChart
            orderBookAsks={orderBookAsks}
            orderBookBids={orderBookBids}
            depth={depth}
          />
        )}
      </CardBody>
    </StyledWrapper>
  );
};

export default React.memo(OrderBook);
