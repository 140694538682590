import React, { useState } from "react";
import { Card } from "reactstrap";

import { CustomScrollbars, EmptyStateCustom, PaginationPanel } from "shared/ui";
import TradeRequestTableHeader from "./TradeRequestTableHeader";
import TradeRequestsDetailsWrapper from "./TradeRequestsDetailsWrapper";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import {
  fetchTradeRequestOrders,
  fetchTradeRequestTrades,
} from "store/deals/actions";
import { ReactComponent as Empty } from "assets/icons/general/empty-state-planet2.svg";

const TradeRequestsDetailsTable = ({
  ordersRequests,
  tradesRequests,
  isModalOpenID,
  tradesRequestsLoading,
  ordersRequestsLoading,
  fetchData,
}) => {
  const [title, setTitle] = useState("trades");

  const requestsTable =
    title === "trades" ? tradesRequests.request : ordersRequests.request;
  const requestsFunc =
    title === "trades" ? fetchTradeRequestTrades : fetchTradeRequestOrders;

  const { handleFilter, handleSorting, handlePaginate } = useFiltersWithRequest(
    requestsTable,
    requestsFunc,
    isModalOpenID,
  );

  return (
    <Card className='mb-0 pb-0 shadow-none'>
      <TradeRequestTableHeader
        title={title}
        setTitle={setTitle}
        handleFilter={handleFilter}
        ordersRequests={ordersRequests}
        tradesRequests={tradesRequests}
        tradesRequestsLoading={tradesRequestsLoading}
        ordersRequestsLoading={ordersRequestsLoading}
        fetchData={fetchData}
      />

      <CustomScrollbars
        autoHideTimeout={500}
        autoHideDuration={500}
        autoHeight
        autoHeightMax={Number.MAX_VALUE}
        style={{ borderRadius: "0 0 8px 8px" }}
      >
        {(!ordersRequests?.items?.length &&
          title === "orders" &&
          !ordersRequestsLoading) ||
        (!tradesRequests?.items?.length &&
          title === "trades" &&
          !tradesRequestsLoading) ? (
          <Card className='mb-0'>
            <EmptyStateCustom
              Img={Empty}
              title={"No results for your search"}
              description={
                "Please adjust your filters, we have not found anything by your request."
              }
              backgroundColor={"var(--dark-slate)"}
            />
          </Card>
        ) : (
          <TradeRequestsDetailsWrapper
            title={title}
            tradesRequests={tradesRequests}
            tradesRequestsLoading={tradesRequestsLoading}
            ordersRequests={ordersRequests}
            ordersRequestsLoading={ordersRequestsLoading}
            handleSort={handleSorting}
          />
        )}
      </CustomScrollbars>
      <div
        className={
          (title === "orders" && ordersRequests.total > 5) ||
          (title === "trades" && tradesRequests.total > 5)
            ? "mt-3"
            : ""
        }
      >
        <PaginationPanel
          activePage={
            title === "orders" ? ordersRequests.page : tradesRequests.page
          }
          totalItemsCount={
            title === "orders" ? ordersRequests.total : tradesRequests.total
          }
          totalPagesView={5}
          onChange={page => handlePaginate(page)}
          isCircle
        />
      </div>
    </Card>
  );
};

export default TradeRequestsDetailsTable;
