import { useEffect, useState } from "react";

const useCalculatedSignals = (botData, filteredIndicators) => {
  const [buySignals, setBuySignals] = useState(null);
  const [sellSignals, setSellSignals] = useState(null);

  useEffect(() => {
    let minimumSellSignals = 0;
    let minimumBuySignals = 0;

    const indicators = botData?.tradeSettings?.indicators ?? [];
    const allIndicators = [...indicators, ...filteredIndicators];

    allIndicators.forEach(item => {
      if (item.type === "both") {
        minimumSellSignals++;
        minimumBuySignals++;
      }

      if (item.type === "sell") {
        minimumSellSignals++;
      }

      if (item.type === "buy") {
        minimumBuySignals++;
      }
    });
    setSellSignals(minimumSellSignals);
    setBuySignals(minimumBuySignals);
  }, [botData?.tradeSettings?.indicators, filteredIndicators]);

  return {
    buySignals,
    sellSignals,
    setSellSignals,
    setBuySignals,
  };
};
export default useCalculatedSignals;
