import React, { useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import { Col } from "reactstrap";
import { capitalize } from "lodash";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";

import { Chart as ChartJS, Legend, Tooltip, ArcElement } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({
  pieData,
  index,
  exchange,
  asset,
  setHoverCurrentBg,
  backgroundColors,
}) => {
  const chartReference = useRef();
  const chartIdx = useRef(-1);

  const onHoverText = () => {
    if (exchange) {
      return capitalize(
        exchange[(index >= 0 ? index : null) ?? chartIdx.current]?.credentials
          ?.exchange,
      );
    }
    if (asset) {
      return asset.items[index ?? chartIdx.current]?.asset;
    }
    if (pieData) {
      return pieData.labels[index ?? chartIdx.current];
    }
  };

  const OPTIONS = {
    onHover: (event, chartElement) => {
      if (chartElement.length) {
        event.native.target.style.cursor = "pointer";
        chartIdx.current = chartElement[0].index;
        setHoverCurrentBg(chartElement[0].index);
        drawInnerText(chartReference.current);
        drawInnerText2(chartReference.current);
        return;
      }
      chartIdx.current = -1;
      setHoverCurrentBg(-1);
      drawInnerText(chartReference.current);
      drawInnerText2(chartReference.current);
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    maintainAspectRatio: false,
    responsive: false,
    cutout: "70%",
    layout: {
      padding: 10,
    },
  };

  if (pieData === null) {
    return (
      <div className='d-flex justify-content-center w-100 mt-4'>
        <Col xs='1'>
          <SkeletonPreloader count={8} circle={true} height={16} width={16} />
        </Col>
        <Col xs='7'>
          <div className='flex-column ml-3'>
            <SkeletonPreloader count={8} height={14} />
          </div>
        </Col>
        <Col xs={{ size: 3, offset: 1 }}>
          <SkeletonPreloader count={8} height={14} />
        </Col>
      </div>
    );
  }

  const configData = () => {
    if (asset) {
      return pieData.datasets[0].data;
    }
    if (exchange) {
      return exchange.map(item => item.balance.btc || 0);
    }
    if (pieData) {
      return pieData.data;
    }
  };

  const configBackgroundColor = () => {
    if (exchange) {
      return exchange.map(item => backgroundColors[item.credentials.exchange]);
    }
    if (asset) {
      return pieData.datasets[0].backgroundColor;
    }
    if (pieData) {
      return pieData.backgroundColors;
    }
  };

  const configLabels = () => {
    if (exchange) {
      return exchange.map(item => item.estimated.currency);
    }
    if (asset) {
      return pieData.labels;
    }
    if (pieData) {
      return pieData.labels;
    }
  };

  const config = {
    labels: configLabels(),
    datasets: [
      {
        data: configData(),
        backgroundColor: configBackgroundColor(),
        hoverOffset: 10,
        borderWidth: 0,
      },
    ],
  };

  const renderText = () => {
    if (exchange) {
      const percent =
        exchange[(index >= 0 ? index : null) ?? chartIdx.current]?.percent;
      if (percent || percent === 0 || chartIdx.current !== -1) {
        return `${percent} %`;
      }
    }
    if (asset) {
      const percent = pieData?.datasets[0]?.data[index ?? chartIdx.current];
      if (percent || percent === 0 || chartIdx.current !== -1) {
        return `${percent} %`;
      }
    }
    if (pieData?.data) {
      const total = pieData.data.reduce((acc, val) => acc + val, 0);
      const value = pieData.data[index ?? chartIdx.current];
      if (value !== undefined && total > 0) {
        const percentage = (value / total) * 100;
        return `${percentage.toFixed(2)}%`;
      }
    }
    return "";
  };

  const renderColorText = () => {
    if (exchange) {
      return `${exchange.map(item => backgroundColors[item.credentials.exchange])[(index >= 0 ? index : null) ?? chartIdx.current]}`;
    }
    if (asset) {
      return `${pieData.datasets[0].backgroundColor[index ?? chartIdx.current]}`;
    }
    if (!exchange && !asset) {
      return "#ffffff";
    }
  };

  const drawInnerText = chart => {
    const ctx = chart.ctx;
    ctx.restore();
    const fontSize = (chart.height / 200).toFixed(2);
    ctx.font = fontSize + "em sans-serif";
    ctx.textBaseline = "middle";
    const text = onHoverText() || "";
    const textX = Math.round((chart.width - ctx.measureText(text).width) / 2);
    const textY = chart.height / 2.5 + chart.legend.height / 2.5;
    ctx.fillText(text, textX, textY);
    ctx.fillStyle = renderColorText();
    ctx.save();
  };
  const drawInnerText2 = chart => {
    const ctx = chart.ctx;
    ctx.restore();
    const fontSize = (chart.height / 114).toFixed(2);
    ctx.font = fontSize + "em sans-serif";
    ctx.textBaseline = "middle";
    const text = renderText();
    const textX = Math.round((chart.width - ctx.measureText(text).width) / 2);
    const textY = chart.height / 1.8 + chart.legend.height / 1.8;
    ctx.fillText(text, textX, textY);
    ctx.fillStyle = "#fff";
    ctx.save();
  };

  const PLUGINS = [
    {
      beforeDraw: function (chart) {
        drawInnerText(chart);
        drawInnerText2(chart);
      },
    },
  ];

  return (
    <div style={{ height: "212px" }}>
      <Doughnut
        onMouseLeave={() => setHoverCurrentBg(-1)}
        data={config}
        options={OPTIONS}
        plugins={PLUGINS}
        ref={chartReference}
        width={212}
        height={212}
      />
    </div>
  );
};

export default DoughnutChart;
