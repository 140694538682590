import React from "react";
import styled from "styled-components";

import { Icon } from "shared/ui";
import { BadgeSignal } from "shared/ui/badges";

const MarketSummaryTableRow = ({ item, handleSelectMarket, totalItems }) => {
  return (
    <MarketSummaryTableRow.TableRow
      onClick={() => handleSelectMarket(item.market.pair)}
      total={totalItems}
    >
      <td>
        <div className='d-flex align-items-center text-nowrap'>
          <Icon code={item.market.base} size='thumb' />
          <p className='mb-0 ml-2 text-white font-weight-500'>
            {item.market.base}
          </p>
          <p className='mb-0 ml-1 text-white-35 font-weight-300'>
            {`/ ${item.market.quote}`}
          </p>
        </div>
      </td>

      <td>
        <p
          className={`mb-0 font-weight-500 ${
            item.conditions === "fulfilled" ? "text-success" : "text-white-35"
          }`}
        >
          {item.conditions === "fulfilled" ? "Fulfilled" : "Not satisfied"}
        </p>
      </td>

      <td>
        <BadgeSignal signal={item?.indicators || "none"} />
      </td>

      <td>
        <BadgeSignal signal={item.pivotPoint || "none"} />
      </td>

      <td>
        <BadgeSignal signal={item.candle || "none"} />
      </td>
    </MarketSummaryTableRow.TableRow>
  );
};

MarketSummaryTableRow.TableRow = styled.tr`
  cursor: pointer;
  border-top: 2px solid rgba(46, 49, 72, 1) !important;
  box-shadow: none !important;

  &:last-child {
    border-bottom: ${({ total }) =>
      total > 10 ? "2px solid rgba(46, 49, 72, 1)" : "none"};
  }
`;

export default MarketSummaryTableRow;
