import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Card } from "reactstrap";

import ChannelSubscrBody from "./ChannelSubscrBody";
import ChannelWidgetPreload from "views/channels/preloaders/ChannelWidgetPreload";
import ChannelDefaultWidgetHeader from "../reusable/ChannelDefaultWidgetHeader";
import ChannelSubscription from "views/channels/modals/ChannelSubscription";
import { numberOrNull } from "views/channels/channelPropTypes";

const ChannelSubscrWidget = ({
  loading,
  channelId,
  maxximun,
  volume,
  accept,
  handleRefresh,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggleModal = () => setIsModalOpen(prev => !prev);

  if (loading) return <ChannelWidgetPreload count={2} isEditButton />;
  return (
    <>
      <ChannelSubscrWidget.Wrap>
        <ChannelDefaultWidgetHeader
          isEdit
          isWidget
          className='badge-info py-3 px-4'
          title='Subscription settings'
          color='blue'
          onEditButtonClick={handleToggleModal}
        />
        <ChannelSubscrBody
          maxximun={maxximun}
          volume={volume}
          accept={accept}
        />
      </ChannelSubscrWidget.Wrap>
      <ChannelSubscription
        isModalOpen={isModalOpen}
        handleToggleModal={handleToggleModal}
        channelId={channelId}
        maxximun={maxximun}
        volume={volume}
        accept={accept}
        handleRefresh={handleRefresh}
      />
    </>
  );
};

ChannelSubscrWidget.Wrap = styled(Card)`
  border: 2px solid var(--info) !important;
`;

ChannelSubscrWidget.propType = {
  loading: PropTypes.bool.isRequired,
  channelId: PropTypes.string,
  maxximun: numberOrNull,
  volume: numberOrNull,
  accept: PropTypes.bool,
  handleRefresh: PropTypes.func,
};

export default ChannelSubscrWidget;
