import React from "react";
import styled from "styled-components";

import { ReactComponent as Logo } from "assets/icons/general/Logo.svg";
import useBuySubscription from "hooks/useBuySubscription";
import { getCurrentPrice } from "../utils";
import { ListHeader } from "./StyledFeatures";
import IntervalTabs from "./IntervalTabs";
import { Button } from "shared/ui/buttons";

const FeaturesTableHeader = ({
  plans = [],
  paymentInterval,
  currentPlan,
  setPaymentInterval,
  hasTrial,
}) => {
  const { handleBuyNow } = useBuySubscription();

  const handleBuy = (plan, trial) => {
    const id = plan.prices.find(
      price => price.interval === paymentInterval,
    )?.stripe;
    handleBuyNow(id, trial);
  };

  return (
    <ListHeader className='mb-3'>
      <div
        className={`d-flex flex-column ${currentPlan ? "justify-content-center" : ""}`}
      >
        <Logo />
        <p className='text-white-35 mt-3 mb-0'>
          {currentPlan
            ? "DarkBot - advanced cryptocurrency trading bot."
            : "DarkBot is an advanced cryptocurrency trading bot that uses machine learning, trading indicators and other features to optimize profits and minimize risks for traders"}
        </p>
        {currentPlan ? (
          <div className='mt-4'>
            <IntervalTabs
              paymentInterval={paymentInterval}
              setPaymentInterval={setPaymentInterval}
              currentPlan
            />
          </div>
        ) : null}
      </div>
      <div className='text-center pr-0'>
        <div className='d-flex flex-column'>
          <PriceWrapper className='d-flex flex-column'>
            <p className='h1 mb-3 text-white text-nowrap'>Free</p>
            <p className='h1 mb-1 text-white'>$0.00</p>
            <p className='text-white opacity-35'>forever</p>
          </PriceWrapper>
          <p className='text-white mt-2 font-weight-500'>Free forever</p>
        </div>
      </div>
      {plans.map(plan => (
        <Plans className='text-center' key={plan.id}>
          <div className='d-flex flex-column align-items-center'>
            <PriceWrapper className='d-flex flex-column'>
              <p className='h1 mb-3 text-white'>{plan.name}</p>
              <div className='d-flex mx-auto'>
                <p className='h1 mb-1 text-white'>
                  {getCurrentPrice(plan, paymentInterval)}
                </p>
                {paymentInterval === "year" && plan.name !== "Free" ? (
                  <MonthPrice className='text-white-35'>
                    {plan?.prices?.[0]?.amount}
                  </MonthPrice>
                ) : null}
              </div>
              <p className='text-white opacity-35'>per month</p>
            </PriceWrapper>
            <Button
              color={plan.current || hasTrial ? "" : "blue"}
              outline
              className='d-flex align-items-center justify-content-center py-3 text-nowrap w-50'
              onClick={() => handleBuy(plan, true)}
              disabled={plan.current || hasTrial}
              style={{ opacity: 1, height: "51px" }}
              size='big'
              type='button'
            >
              {plan.current ? "Current plan" : "Try for free"}
            </Button>
          </div>
        </Plans>
      ))}
    </ListHeader>
  );
};

const PriceWrapper = styled.div`
  margin-bottom: 2.5rem;
  min-height: 120px;
`;

const Plans = styled.div`
  &:last-child {
    padding-left: 0 !important;
  }
`;

const MonthPrice = styled.span`
  font-size: 1rem;
  font-weight: 500;
  margin-left: 0.5rem;
  text-decoration: line-through;
`;

export default FeaturesTableHeader;
