import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import PropTypes from "prop-types";

import Error from "shared/ui/state/Error";
import PaginationPanel from "shared/ui/PaginationPanel";
import TradeRequestPreloader from "views/agreements/preloaders/TradeRequestPreloader";
import useFiltersWithRequest from "hooks/useFiltersWithRequest";
import TradeRequestHeader from "../ui/TradeRequestHeader";
import { pageVariants } from "../../../../models/constans/ui/tradeRequests/content";
import { addSnackBar } from "store/snackbar/actions";
import TechnicalLogsModal from "shared/ui/TradeRequests/technicalLogs/TechnicalLogsModal";
import FiltersTradeRequest from "../ui/FiltersTradeRequest";
import EmptyRequestsList from "../ui/EmptyRequestsList";
import TradeRequestCard from "../ui/TradeRequestCard";
import TradeRequestsDetailsModal from "../TradeRequestsDetailsModal";

const HistoryTradeRequestsList = ({
  id,
  actions,
  forceStarted,
  loadingPage,
  onHandleRefresh,
  actionFetchHistoryTR,
  selectData,
  pageView,
  onHandleForceStart,
  onHandleOpenNewBuy,
}) => {
  const [isModalOpenTechnicalLogs, setIsModalOpenTechnicalLogs] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpenItem, isModalOpenItem] = useState(null);
  const [isOpenNewBuyLoading, setIsOpenNewBuyLoading] = useState(false);

  const getItemsVariant = () => pageVariants(items)?.[pageView];
  const isStartOrRun = actions?.start || actions?.run;
  const dispatch = useDispatch();

  const {
    loading,
    error,
    data: { metadata, items, page, total, request },
  } = useSelector(selectData);

  const { handleFilter, filters, handlePaginate } = useFiltersWithRequest(
    request,
    actionFetchHistoryTR,
    id,
  );

  useLayoutEffect(() => {
    const queryString = qs.stringify({
      filter: {
        "tr.status": {
          eq: "completed",
        },
      },
      limit: 4,
    });
    dispatch(actionFetchHistoryTR(id, `?${queryString}`));
  }, [dispatch, id, actionFetchHistoryTR]);

  const handleOpenModal = item => {
    isModalOpenItem(item);
    setIsModalOpen(true);
  };

  const openNewBuy = async () => {
    setIsOpenNewBuyLoading(true);
    const res = await dispatch(onHandleOpenNewBuy(id));
    setIsOpenNewBuyLoading(false);
    if (res?.errors) return dispatch(addSnackBar("error", res.message));
    dispatch(addSnackBar("success", res.message));
    onHandleRefresh();
  };

  const openTechnicalLogs = () => setIsModalOpenTechnicalLogs(true);

  if (loading || loadingPage)
    return <TradeRequestPreloader count={4} className='mb-2' />;

  if (error) return <Error error={error} />;
  return (
    <>
      <TradeRequestHeader
        isHistoryTradeRequest
        title='Trade request history'
        statistics={metadata?.statistics}
        pageView={pageView}
        openNewBuy={openNewBuy}
        actions={actions}
        isOpenNewBuyLoading={isOpenNewBuyLoading}
        openTechnicalLogs={openTechnicalLogs}
      />
      <FiltersTradeRequest
        id={id}
        onHandleFilter={handleFilter}
        filter={request?.filter || filters}
      />
      {items?.length ? (
        items.map(request => {
          const variant = pageVariants(request)?.[pageView];
          return (
            <TradeRequestCard
              key={request?.id}
              id={request?.id}
              data={variant?.data}
              goal={request?.goal}
              handleOpenModal={handleOpenModal}
            />
          );
        })
      ) : (
        <EmptyRequestsList
          title={getItemsVariant()?.emptyTitle}
          beforeDescription={getItemsVariant()?.beforeDescription}
          afterDescription={getItemsVariant()?.afterDescription}
          id={id}
          canForceRun={forceStarted}
          isStartOrRun={isStartOrRun}
          onHandleRefresh={onHandleRefresh}
          onHandleForceStart={onHandleForceStart}
        />
      )}
      <PaginationPanel
        activePage={page}
        totalItemsCount={total}
        totalPagesView={4}
        onChange={handlePaginate}
      />
      {isModalOpen && (
        <TradeRequestsDetailsModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          modalOpenItem={modalOpenItem}
          isManual={modalOpenItem?.forceStarted}
        />
      )}
      {isModalOpenTechnicalLogs && (
        <TechnicalLogsModal
          isModalOpenTechnicalLogs={isModalOpenTechnicalLogs}
          setIsModalOpenTechnicalLogs={setIsModalOpenTechnicalLogs}
          dealId={id}
        />
      )}
    </>
  );
};

HistoryTradeRequestsList.propTypes = {
  id: PropTypes.string,
  forceStarted: PropTypes.bool,
  actions: PropTypes.shape({
    cancel: PropTypes.bool,
    complete: PropTypes.bool,
    start: PropTypes.bool,
  }),
};

export default HistoryTradeRequestsList;
