import React from "react";
import styled from "styled-components";

const MovingTickerEmptyState = ({ handleRefresh }) => {
  return (
    <TickerWrapper className='d-flex justify-content-between align-items-center py-2 px-3'>
      <p className='text-white font-weight-500 font-xs m-0'>No tickers found</p>
      <div className='d-flex'>
        <p className='font-xs text-info m-0'>Try again</p>
        <button className='btn btn-reset ml-2' onClick={handleRefresh}>
          <svg
            id='refreshButton'
            version='1.0'
            width='19px'
            height='19px'
            viewBox='0 0 128 128'
          >
            <g>
              <path
                fill='var(--info)'
                d='M109.25 55.5h-36l12-12a29.54 29.54 0 0 0-49.53 12H18.75A46.04 46.04 0 0 1 96.9 31.84l12.35-12.34v36zm-90.5 17h36l-12 12a29.54 29.54 0 0 0 49.53-12h16.97A46.04 46.04 0 0 1 31.1 96.16L18.74 108.5v-36z'
              />
              <animateTransform
                begin='refreshButton.click'
                attributeName='transform'
                fill='freeze'
                type='rotate'
                from='0 64 64'
                to='360 64 64'
                dur='720ms'
                repeatCount='0'
              />
            </g>
          </svg>
        </button>
      </div>
    </TickerWrapper>
  );
};

const TickerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 93%;
  background-color: #242534;
  padding-left: 0;
  box-sizing: content-box;
`;

export default MovingTickerEmptyState;
