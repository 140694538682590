import React from "react";
import styled from "styled-components";
import { Progress } from "reactstrap";
import PropTypes from "prop-types";

import {
  numberOrNull,
  stringOrNull,
} from "../../../views/channels/channelPropTypes";
import { formatRelativeDate } from "helpers/valuesFormatters";

const StatisticsChannelBar = ({
  loss,
  open,
  profit,
  index,
  lastSignalDate,
  className = "mb-1",
}) => (
  <div className={`mt-2 ${className}`}>
    {(profit === null && loss === null) || +profit + +loss === 0 ? (
      <ProgressGray className='w-100' />
    ) : (
      <ProgressMulti multi className='w100 justify-content-between'>
        <Progress
          bar
          color='danger'
          value={loss}
          max={+loss + +profit}
          className='w100'
          id={`totalLoss${index}`}
        />
        <Progress
          bar
          color='success'
          value={profit}
          max={+loss + +profit}
          className='w100'
          id={`totalLoss${index}`}
        />

        <Progress
          bar
          color='info'
          value={open}
          max={+loss + +profit}
          className='w100'
          id={`totalProfit${index}`}
        />
      </ProgressMulti>
    )}
    <div className='d-flex justify-content-between align-items-center mt-2'>
      <p className='font-sm font-weight-400 text-white-35'>
        Last submitted signal
      </p>
      <p className='mb-0 ml-1 font-weight-400 text-white-35 font-sm'>
        {formatRelativeDate(lastSignalDate)}
      </p>
    </div>
  </div>
);

const ProgressGray = styled(Progress)`
  height: 8px;
  background: var(--white-10, rgba(255, 255, 255, 0.1)) !important;
  box-shadow: none !important;
`;

const ProgressMulti = styled(Progress)`
  height: 8px;
  box-shadow: none !important;
`;

StatisticsChannelBar.propTypes = {
  loss: numberOrNull,
  open: numberOrNull,
  profit: numberOrNull,
  index: PropTypes.number,
  lastSignalDate: stringOrNull,
  className: PropTypes.string,
};

export default StatisticsChannelBar;
