import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Card, CardBody, CardHeader, CardFooter, Progress } from "reactstrap";

import { ReactComponent as PieChart } from "assets/icons/investments/pie-chart.svg";
import { ReactComponent as Crown } from "assets/icons/general/crown.svg";
import PortfolioModal from "../modals/manageAllocation/PortfolioModal";
import AllocationPreloader from "../preloaders/AllocationPreloader";
import { EditButton } from "shared/ui";

const Allocation = ({
  plannedPercent,
  realPercent,
  loading,
  subscription,
  credentialId,
  rebalancingDifference,
  rebalance,
  onHandleRefresh,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  if (loading) return <AllocationPreloader />;

  return (
    <>
      <Card className='mb-4'>
        <CardHeader className='px-4 pt-4 pb-3'>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
              <PieChart className='my-auto mr-2' />
              <p className='mb-0 text-white font-weight-500'>Allocation</p>
            </div>
            {subscription?.type === "premium" && subscription?.active ? (
              <div className='d-flex cursor-pointer'>
                <EditButton onClick={() => setModalIsOpen(true)} />
              </div>
            ) : null}
          </div>
        </CardHeader>
        <CardBody className='px-4 pt-0 pb-3'>
          {
            <>
              <div className='d-flex align-items-center justify-content-between mb-1'>
                <p className='mb-0 text-white-75 font-weight-300 font-sm'>
                  Real %
                </p>
                <p
                  className={`mb-0 font-weight-500 text-nowrap ${realPercent > 0 ? "text-white" : "text-white-35"}`}
                >
                  {`${realPercent || 0} %`}
                </p>
              </div>
              <Progress
                color={`${subscription === "premium" ? "success" : "info"}`}
                className='mb-3 shadow-none'
                value={realPercent}
                max={100}
                style={{ height: "6px" }}
              />
              {subscription?.type === "premium" && rebalance ? (
                <>
                  <div className='d-flex align-items-center justify-content-between mb-1'>
                    <p className='mb-0 text-white-75 font-weight-300 font-sm'>
                      Planned %
                    </p>
                    <p
                      className={`mb-0 font-weight-500 text-nowrap ${plannedPercent > 0 ? "text-white" : "text-white-35"}`}
                    >
                      {`${plannedPercent || 0} %`}
                    </p>
                  </div>
                  <Progress
                    color={plannedPercent >= 0 ? "success" : "danger"}
                    className='shadow-none'
                    style={{ height: "6px" }}
                    value={plannedPercent?.toString()?.replace("-", "")}
                    max={100}
                  />
                </>
              ) : null}
            </>
          }
        </CardBody>
        {subscription?.type !== "premium" ||
        (subscription?.type === "premium" && !subscription?.active) ? (
          <Allocation.CardFooter className='font-sm flex-wrap'>
            <div className='d-flex align-items-center text-nowrap'>
              <span className='d-inline-block'>
                <Crown />
              </span>
              <Link to='/billing' className='ml-1 link-gold'>
                Upgrade to premium
              </Link>
            </div>

            <p className='mb-0 ml-1 text-nowrap'>to enable auto-rebalancing</p>
          </Allocation.CardFooter>
        ) : null}
      </Card>
      {modalIsOpen && (
        <PortfolioModal
          modalIsOpen={modalIsOpen}
          onSetModalIsOpen={setModalIsOpen}
          credentialId={credentialId}
          rebalancingDifference={rebalancingDifference}
          onHandleRefresh={onHandleRefresh}
        />
      )}
    </>
  );
};

Allocation.CardFooter = styled(CardFooter)`
  display: flex;
  align-items: center;
  padding: 0 24px 24px 24px !important;

  @media (max-width: 576px) {
    white-space: normal;
  }
`;

Allocation.ButtonPreLoader = styled.div`
  background: rgba(83, 84, 98, 0.2);
  border-radius: 4px;
`;

export default Allocation;
