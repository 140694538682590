import React from "react";
import { Card } from "reactstrap";
import TradingViewWidget, { Themes } from "react-tradingview-widget";

import MarketTicker from "./ui/MarketTicker";
import { TradingWithTickerPreloader } from "shared/ui";
import { Trading } from "./index.styled";

const TradingWithTicker = ({
  ticker,
  loadingPage,
  quote,
  base,
  marketInformation = {},
  interval,
  exchange,
  isBlockVisible,
}) => {
  const { loading, data } = marketInformation;

  if (!data?.active && !loading && !isBlockVisible) return;
  if (loadingPage || !ticker || loading)
    return <TradingWithTickerPreloader withChart />;

  return (
    <Card>
      <MarketTicker
        exchange={exchange}
        ticker={ticker}
        quote={quote}
        base={base}
      />

      <Trading>
        {exchange && (
          <TradingViewWidget
            autosize
            symbol={`${exchange.toUpperCase()}:${base}${quote}`}
            hide_side_toolbar={false}
            theme={Themes.DARK}
            locale='en'
            showSymbolLogo
            interval={interval || 30}
          />
        )}
      </Trading>
    </Card>
  );
};

export default TradingWithTicker;
