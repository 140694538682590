import { get } from "lodash";
import {
  GET_NOTIFICATIONS_CODES_SUCCESS,
  GET_NOTIFICATIONS_CODES_LOADING,
  GET_NOTIFICATIONS_CODES_FAILURE,
  TOGGLE_NOTIFICATION,
  ENABLE_ALL_NOTIFICATIONS,
  DISABLE_ALL_NOTIFICATIONS,
  ENABLE_ALL_NOTIFICATIONS_LOADING,
  DISABLE_ALL_NOTIFICATIONS_LOADING,
  TOGGLE_NOTIFICATION_LOADING,
} from "./constants";

export const getNotificatinsCodes = () => async (dispatch, getState, fetch) => {
  try {
    dispatch({ type: GET_NOTIFICATIONS_CODES_LOADING });
    const res = await fetch.get("/app/messages/codes");
    dispatch({ type: GET_NOTIFICATIONS_CODES_SUCCESS, payload: res.data });
  } catch (e) {
    dispatch({ type: GET_NOTIFICATIONS_CODES_FAILURE, payload: e.message });
  }
};

export const toggleNotification =
  (code, body, language = "en") =>
  async (dispatch, getState, fetch) => {
    try {
      dispatch({ type: TOGGLE_NOTIFICATION_LOADING });
      const res = await fetch.post(`/app/messages/${code}/switch`, body);
      dispatch({
        type: TOGGLE_NOTIFICATION,
        payload: { ...res.data, lang: language },
      });
      return res.data;
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const enableAllNotification =
  (language = "en") =>
  async (dispatch, getState, fetch) => {
    try {
      dispatch({ type: ENABLE_ALL_NOTIFICATIONS_LOADING });
      const res = await fetch.patch("/app/messages/enable-all");
      dispatch({ type: ENABLE_ALL_NOTIFICATIONS, payload: { lang: language } });
      return res.data;
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };

export const disableAllNotification =
  (language = "en") =>
  async (dispatch, getState, fetch) => {
    try {
      dispatch({ type: DISABLE_ALL_NOTIFICATIONS_LOADING });
      const res = await fetch.patch("/app/messages/disable-all");
      dispatch({
        type: DISABLE_ALL_NOTIFICATIONS,
        payload: { lang: language },
      });
      return res.data;
    } catch (e) {
      return get(e, "response.data", { errors: [], message: e.message });
    }
  };
