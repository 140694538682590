import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { selectBots } from "store/bots/selectors";
import usePagination from "hooks/usePagination";
import useRequestCancellation from "hooks/useRequestCancellation";
import { getBots, resetBots } from "store/bots/actions";
import BotsListContent from "./compounds/BotsListContent";
import { PaginationPanel } from "shared/ui";
import Filters from "./compounds/Filters";
import BotsListHeader from "./compounds/BotsListHeader";
import { withRenderState } from "shared/hoc";

const BotsList = ({ location }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { handlePaginate } = usePagination("/bots", true);
  const { useAbortableEffect } = useRequestCancellation();

  const {
    error,
    loading,
    data: { items, total, page },
  } = useSelector(selectBots);

  const isLoaded = useMemo(() => typeof total === "number", [total]);
  const isFiltered = useMemo(
    () => history.location.search.includes("filter"),
    [history.location.search],
  );

  useAbortableEffect(
    signal => {
      const queryString = `${location.search}${location.search ? "&" : "?"}limit=12`;
      dispatch(getBots(queryString, signal));
      return () => dispatch(resetBots());
    },
    [location.search, dispatch],
  );

  const handleToCreateBot = () => history.push({ pathname: "/bots/create" });

  const BotsListWithRenderState = withRenderState(BotsListContent);

  useEffect(() => {
    window.analytics.page("/bots");
  }, []);

  return (
    <>
      <BotsListHeader
        isNotFiltered={!isFiltered}
        handleToCreateBot={handleToCreateBot}
      />
      <Filters items={items} loading={loading} />
      <BotsListWithRenderState
        items={items || []}
        error={error}
        onHandleClick={handleToCreateBot}
        loading={loading}
        page={page}
        total={total}
        handlePaginate={handlePaginate}
        viewPage='botsList'
        loaded={isLoaded}
        isFiltered={isFiltered}
      />
      <PaginationPanel
        activePage={page}
        totalItemsCount={total}
        totalPagesView={12}
        onChange={handlePaginate}
      />
    </>
  );
};

export default BotsList;
