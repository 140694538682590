import React from "react";
import SkeletonPreloader from "shared/ui/SkeletonPreloader";
import _ from "lodash";

const MarketTablePreloader = ({ withHeader }) => {
  return (
    <>
      {withHeader && (
        <thead>
          <tr>
            {_.times(5, e => (
              <th key={e}>
                <SkeletonPreloader width={57} height={9} />
              </th>
            ))}
          </tr>
        </thead>
      )}

      <tbody style={{ borderBottom: "1px solid #2E3148" }}>
        {_.times(4, e => (
          <tr key={e} style={{ borderTop: "1px solid #2E3148" }}>
            <td className='pl-4'>
              <div className='d-flex align-items-center'>
                <SkeletonPreloader width={24} height={24} circle />
                <SkeletonPreloader width={65} height={12} className='ml-2' />
              </div>
            </td>

            <td>
              <SkeletonPreloader width={80} height={12} />
            </td>

            <td>
              <SkeletonPreloader width={43} height={26} borderRadius='4px' />
            </td>

            <td>
              <SkeletonPreloader width={43} height={26} borderRadius='4px' />
            </td>

            <td>
              <SkeletonPreloader width={43} height={26} borderRadius='4px' />
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

export default MarketTablePreloader;
